import { GetterTree, MutationTree, ActionTree } from "vuex";
import { ScorecardApi } from "../services/api/ScorecardApi";
import storeBase from "@/store/mixins/base";
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
  isScorecardBusy: boolean = false;
  isScorecardTreeBusy: boolean = false;
  isScorecardTreeFormBusy: boolean = false;
  scorecardItems: unknown [];
  scorecardIds: unknown [];
  scorecardTreeItems: unknown [];
  scorecardTreeFormItems: unknown [];
  scorecardItem: unknown;
  scorecardId: any = null;
  currentScorecardNode: any = null;
}

// Создаем объект
const scorecardApi = new ScorecardApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

  isScorecardBusy(state): boolean {
    return state.isScorecardBusy;
  },

  isScorecardTreeBusy(state): boolean {
    return state.isScorecardTreeBusy;
  },

  isScorecardTreeFormBusy(state): boolean {
    return state.isScorecardTreeFormBusy;
  },

  getScorecardItems(state): unknown[] {
    return state.scorecardItems;
  },

  getScorecardTreeItems(state): unknown[] {
    return state.scorecardTreeItems;
  },

  getScorecardTreeFormItems(state): unknown[] {
    return state.scorecardTreeFormItems;
  },

  getScorecardIds(state): unknown[] {
    return state.scorecardIds;
  },

  getScorecardItem(state): unknown {
    return state.scorecardItem;
  },

  getScorecardId(state): any {
    return state.scorecardId;
  },

  getCurrentScorecardNode(state): any {
    return state.currentScorecardNode;
  }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

  setIsScorecardBusy(state, payload: boolean) {
    state.isScorecardBusy = payload
    if(payload){
      Loading.show()
    }else{
      Loading.hide()
    }
  },

  setIsScorecardTreeBusy(state, payload: boolean) {
    state.isScorecardTreeBusy = payload
    if(payload){
      Loading.show()
    }else{
      Loading.hide()
    }
  },

  setIsScorecardTreeFormBusy(state, payload: boolean) {
    state.isScorecardTreeFormBusy = payload
    if(payload){
      Loading.show()
    }else{
      Loading.hide()
    }
  },

  setScorecardItems(state, payload: unknown[]) {
    state.scorecardItems = payload
  },

  setScorecardTreeItems(state, payload: unknown[]) {
    state.scorecardTreeItems = payload
  },

  setScorecardTreeFormItems(state, payload: unknown[]) {
    state.scorecardTreeFormItems = payload
  },

  setScorecardIds(state, payload: unknown[]) {
    state.scorecardIds = payload
  },

  setScorecardItem(state, payload: unknown) {
    state.scorecardItem = payload
  },

  setScorecardId(state, payload: any) {
    state.scorecardId = payload
  },

  setCurrentScorecardNode(state, payload: any) {
    state.currentScorecardNode = payload
  }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

  // Устанавливаем элемент
  setScorecardItem({ commit }, data: any) {
    commit('setScorecardItem', data);
  },
  
  // Устанавливаем текущий scorecardId
  setScorecardId({ commit }, data: any) {
    commit('setScorecardId', data);
  },
  
  // Устанавливаем текущий узел дерева
  setCurrentScorecardNode({ commit }, data: any) {
    commit('setCurrentScorecardNode', data);
  },
  
  // Дерево классификатора
  getScorecardTreeItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardTreeBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      scorecardApi.getScorecardItems(filter, sort, pagination)
        .then(
          (response: any) => {
            commit('setScorecardItems', response);

            // Конвертируем данные для дерева
            const tree = storeBase.methods.convertScorecardItemsToTree(response)
            commit('setScorecardTreeItems', tree);
            commit('setIsScorecardTreeBusy', false);
            resolve(tree);
          },
          (error: any) => {
            commit('setScorecardTreeItems', []);
            commit('setIsScorecardTreeBusy', false);
            reject(error);
          }
        );
    });
  },

  // Дерево классификатора для формы
  getScorecardTreeFormItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardTreeFormBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
    const root:any = data !== undefined && data.root !== undefined ? data.root : { id: 0, idx: 1, title: 'Нет'};
    const disabled:any = data !== undefined && data.disabled !== undefined && Array.isArray(data.disabled) ? data.disabled : [];

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      scorecardApi.getScorecardItems(filter, sort, pagination)
        .then(
          (response: any) => {

            // Конвертируем данные для дерева
            const tree = storeBase.methods.convertScorecardItemsToTreeForForm(response, disabled)
            tree.unshift(root) // Добавляем узел 'Нет' для корневого класса
            commit('setScorecardTreeFormItems', tree);
            commit('setIsScorecardTreeFormBusy', false);
            resolve(tree);
          },
          (error: any) => {
            commit('setScorecardTreeFormItems', []);
            commit('setIsClassTreeFormBusy', false);
            reject(error);
          }
        );
    });
  },

  // Список элементов
  getScorecardItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      scorecardApi.getScorecardItems(filter, sort, pagination)
        .then(
          (response:any) => {
            const scorecardIds = response.map((item: any) => item.getScorecardId())
            commit('setScorecardItems', response);
            commit('setScorecardIds', scorecardIds);
            commit('setIsScorecardBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setScorecardItems', []);
            commit('setScorecardIds', []);
            commit('setIsScorecardBusy', false);
            reject(error);
          }
        );
    });
  },

  // Получить элемент
  getScorecardItem({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];

    return new Promise((resolve, reject) => {

      // Получаем элемент
      scorecardApi.getScorecardItem(filter)
        .then(
          (response:any) => {
            commit('setScorecardItem', response);
            commit('setIsScorecardBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsScorecardBusy', false);
            reject(error);
          }
        );
    });
  },

  // Создать элемент
  createScorecardItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardBusy', true);

    return new Promise((resolve, reject) => {

      // Создаем элемент
      scorecardApi.createScorecardItem(data)
        .then(
          (response) => {
            commit('setIsScorecardBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsScorecardBusy', false);
            reject(error);
          }
        );
    });
  },

  // Обновить элемент
  updateScorecardItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardBusy', true);

    return new Promise((resolve, reject) => {

      // Обновляем элемент
      scorecardApi.updateScorecardItem(data)
        .then(
          (response) => {
            commit('setIsScorecardBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsScorecardBusy', false);
            reject(error);
          }
        );
    });
  },

  // Удалить элемент
  deleteScorecardItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardBusy', true);

    return new Promise((resolve, reject) => {

      // Удаляем элемент
      scorecardApi.deleteScorecardItem(data)
        .then(
          (response) => {
            commit('setIsScorecardBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsScorecardBusy', false);
            reject(error);
          }
        );
    });
  }
};

// Создаем модуль
const ScorecardModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default ScorecardModule;
