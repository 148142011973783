<template>
  <div class="q-pa-md">
    <div class="text-h4 q-pb-md">Общие</div>
    <RPGeneralList />
  </div>
</template>

<script>
import RPGeneralList from "@/RPGeneralList.vue";

export default {
  name: "RespGeneral",
  components: {RPGeneralList},
}
</script>

<style scoped>

</style>