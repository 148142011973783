import { ScorecardAccountValueServiceClient } from '../../proto/pb/Scorecard_account_valueServiceClientPb'
import {
  ScorecardAccountValue,
  ListScorecardAccountValueRequest,
} from '../../proto/pb/scorecard_account_value_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class scorecardAccountValue {
  Actions: Array<ScorecardAccountValue>
  client: ScorecardAccountValueServiceClient
  metadata: any

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? ''
    this.client = new ScorecardAccountValueServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  getScorecardAccValList({ sso_id, project_id, date_begin, date_end }: any) {
    const request = new ListScorecardAccountValueRequest()

    // Устанавливаем параметры запроса
    request
      .setSsoId(sso_id)
      .setProjectId(project_id)
      .setDateBegin(date_begin)
      .setDateEnd(date_end)

    return this.client.listScorecardAccountValue(request, { Authorization: localStorage.getItem('access-key') } as any)
  }
}
