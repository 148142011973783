import {GetterTree, MutationTree, ActionTree} from 'vuex'
import {AccountsApi} from '../services/api/AccountsApi'
import Loading from 'quasar/src/plugins/loading/Loading.js';

class State {
    isAccountsBusy: boolean = false
    isSearchUsersBusy: boolean = false
    accountsItems: Array<any> = []
    accountItem: any
    accountGuid: any
    accountSsoId: any
    searchAccountItems: any
    exportAccountItems: any
    accountSearchString: string
    loadMoreItemToBeAdded: Object
    isExportAccountsBusy: boolean = false
}

const accountsApi = new AccountsApi()

const getters = <GetterTree<State, any>>{
    isAccountsBusy(state) {
        return state.isAccountsBusy
    },

    isSearchUsersBusy(state) {
        return state.isSearchUsersBusy
    },

    getAccountsItems(state) {
        return state.accountsItems
    },

    getAccountItem(state) {
        return state.accountItem
    },

    getAccountGuid(state) {
        return state.accountGuid
    },

    getAccountSsoId(state) {
        return state.accountSsoId
    },

    getSearchAccountItems(state) {
        return state.searchAccountItems
    },

    getExportAccountItems(state) {
        return state.exportAccountItems
    },
    getAccountSearchString(state) {
        return state.accountSearchString
    },
    isExportAccountsBusy(state) {
        return state.isExportAccountsBusy
    }
}

const mutations = <MutationTree<State>>{
    setIsAccountsBusy(state, payload: boolean) {
        state.isAccountsBusy = payload
    },

    setIsSearchUsersBusy(state, payload: boolean) {
        state.isSearchUsersBusy = payload
    },

    setAccountsItems(state, payload) {
        state.accountsItems = payload
    },

    setAccountItem(state, payload) {
        state.accountItem = payload
    },

    setAccountGuid(state, payload) {
        state.accountGuid = payload
    },

    setAccountSsoId(state, payload) {
        state.accountSsoId = payload
    },

    setSearchAccountItems(state, payload) {
        state.searchAccountItems = payload
    },

    setExportAccountItems(state, payload) {
        state.exportAccountItems = payload
    },

    setAccountSearchString(state, payload) {
        state.accountSearchString = payload
    },

    addLoadedNextSearchAccount(state, payload) {
        state.searchAccountItems.push(state.loadMoreItemToBeAdded, ...payload)
    },

    addPendingSearchItem(state, payload) {
        state.loadMoreItemToBeAdded = payload
    },

    setIsExportAccountsBusy(state, payload) {
        state.isExportAccountsBusy = payload
    }
}

const actions = <ActionTree<State, any>>{
    setAccountsItems({commit}, data: any) {
        commit('setAccountsItems', data)
    },

    setAccountItemSsoId({commit}, data: any) {
        commit('setAccountSsoId', data)
    },

    getSearchAccountItems({commit}, data: any) {
        commit('setIsSearchUsersBusy', true)

        const filter = data.filter
        const pagination = data.pagination || {page: 1, limit: 101, pages: 0}

        return new Promise((resolve, reject) => {
            accountsApi
                .getSearchAccountList(filter, pagination)
                .then((message: any) => {
                    const items: Array<any> = message.getItemsList()
                    let excessItem = undefined
                    if (items.length > 100) {
                        excessItem = items.pop()
                        commit('addPendingSearchItem', excessItem)
                    }
                    commit('setSearchAccountItems', items)
                    commit('setIsSearchUsersBusy', false)
                    if (excessItem) items.push(excessItem)
                    resolve(items)
                }),
                (error: any) => {
                    commit('setSearchAccountItems', [])
                    commit('setIsSearchUsersBusy', false)

                    reject(error)
                }
        })
    },
    loadMoreSearchAccountItems({commit}, data: any) {
        commit('setIsSearchUsersBusy', true)
        const filter = data.filter
        const pagination = data.pagination || {page: 1, limit: 101, pages: 0}
        return new Promise((resolve, reject) => {
            accountsApi
                .getSearchAccountList(filter, pagination)
                .then((message: any) => {
                    const items = message.getItemsList()
                    let excessItem = undefined

                    if (items.length > 100) {
                        excessItem = items.pop()
                        commit('addPendingSearchItem', excessItem)
                    }

                    commit('addLoadedNextSearchAccount', items)
                    commit('setIsSearchUsersBusy', false)
                    if (excessItem) items.push(excessItem)
                    resolve(items)
                }),
                (error: any) => {
                    commit('setIsSearchUsersBusy', false)

                    reject(error)
                }
        })
    },

    getAccountItem({commit}, data: any) {
        commit('setIsAccountsBusy', true)

        const filter: any =
            data !== undefined && data.filter !== undefined ? data.filter : []

        return new Promise((resolve, reject) => {
            // Получаем список элементов
            accountsApi.getAccountListItem(filter).then(
                (message: any) => {
                    const item = message.getItem()
                    const guid = item.getAccountGuid()
                    const ssoid = item.getSsoId()
                    console.log('STORE GET ACCOUNT ITEM GUID:', guid)
                    console.log('STORE GET ACCOUNT ITEM SSOID:', ssoid)
                    commit('setAccountItem', item)
                    commit('setAccountGuid', guid)
                    commit('setAccountSsoId', ssoid)
                    commit('setIsAccountsBusy', false)

                    resolve(item)
                },
                (error) => {
                    commit('setAccountItem', [])
                    commit('setAccountGuid', '')
                    commit('setIsAccountsBusy', false)
                    reject(error)
                }
            )
        })
    },

    getAccountsItems({commit}, data: any) {
        commit('setIsAccountsBusy', true)

        const filter: any =
            data !== undefined && data.filter !== undefined ? data.filter : []
        const sort: any =
            data !== undefined && data.sort !== undefined ? data.sort : {}
        const pagination: any =
            data !== undefined && data.pagination !== undefined ? data.pagination : {}

        return new Promise((resolve, reject) => {
            // Получаем список элементов
            accountsApi.getAccountsResultItems(filter, sort, pagination, data).then(
                (message: any) => {
                    const items = message.getItemsList()
                    const sort = message.getParams().getSort()
                    const pagination = message.getParams().getPagination()

                    commit('setAccountsItems', items)
                    commit('setIsAccountsBusy', false)

                    resolve({
                        items: items,
                        sort: sort,
                        pagination: pagination,
                    })
                },
                (error) => {
                    commit('setAccountsItems', [])
                    commit('setIsAccountsBusy', false)
                    reject(error)
                }
            )
        })
    },

    getAccountsItemsExport({commit}, data: any) {
        commit('setIsExportAccountsBusy', true)

        const filter: any =
            data !== undefined && data.filter !== undefined ? data.filter : []
        const sort: any =
            data !== undefined && data.sort !== undefined ? data.sort : {}
        const pagination: any =
            data !== undefined && data.pagination !== undefined ? data.pagination : {}

        return new Promise((resolve, reject) => {
            // Получаем список элементов
            accountsApi.getAccountsResultItems(filter, sort, pagination, data).then(
                (message: any) => {
                    const items = message.getItemsList()
                    const sort = message.getParams().getSort()
                    const pagination = message.getParams().getPagination()

                    commit('setExportAccountItems', items)
                    commit('setIsExportAccountsBusy', false)
                    resolve({
                        items: items,
                        sort: sort,
                        pagination: pagination,
                    })
                },
                (error) => {
                    commit('setExportAccountItems', [])
                    commit('setIsExportAccountsBusy', false)
                    reject(error)
                }
            )
        })
    },

    loadExcelAccounts({commit}, data) {
        commit('setIsAccountsBusy', true)

        return new Promise((resolve, reject) => {
            accountsApi
                .loadPersonalListFromFile(data)
                .then((msg) => {
                    commit('setIsAccountsBusy', false)

                    resolve(msg)
                })
                .catch((err) => {
                    commit('setIsAccountsBusy', false)

                    reject(err)
                })
        })
    }
}

const AccountsModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters,
}

export default AccountsModule
