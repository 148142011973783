
import store from '@/store'
import MainMixin from '@/mixins/main'
import {defineComponent, toRaw} from 'vue'

import Docxtemplater from 'docxtemplater'
import PizZip from 'pizzip'
import PizZipUtils from 'pizzip/utils/index.js'
import { saveAs } from 'file-saver'

function loadFile(url: string, callback: any) {
  PizZipUtils.getBinaryContent(url, callback);
}

import DataLoading from "@/components/DataLoading.vue"
import {Canvg} from "canvg";


const blankFileWordFormName = '/templates/blank_poll_cls_info.docx'
const fileWordFormName = 'poll_cls_info_'

export default defineComponent({
  name: 'GeneralWordFormSettings',

  mixins: [MainMixin],

  components: {
    DataLoading,
  },

  props: {
    poll: {
      type: Object as any,
    },
    classifier: {
      type: Object as any
    },
  },

  data() {
    return {
      isMakeWordForm: false,
      maxOpenRows: '10',
      pollUsersCount: 0,
      questions: [],
      answersImages: [] as any,
      openAnswersImages: [] as any,
      classifierName: '',
    }
  },

  methods: {
    async fetchChart() {
      const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;

      this.answersImages = []

      if (this.answersChartsComputed) {
        let count = 0

        for (let i in this.answersChartsComputed) {
          console.log(i)
          count++
        }

        for (let i = 0; i < count; i++) {
          let test = this.$refs['chart' + i] as any
          let svgString = test[0].chart.getSVGForExport()

          let canvas = document.createElement('canvas')
          let ctx = canvas.getContext('2d') as any
          let res = await Canvg.from(ctx, svgString)

          res.start()

          this.answersImages.push(canvas.toDataURL().replace(base64Regex, ''))
        }
      }
    },

    async fetchOpenChart() {
      const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;

      this.openAnswersImages = []

      if (this.openAnswersChartsComputed) {
        let count = 0

        for (let i in this.openAnswersChartsComputed) {
          console.log(i)
          count++
        }
        console.log('OPEN ANSWERS COMPUTED LENGTH', count, this.maxOpenRows)
        for (let i = 0; i < count; i++) {
          let test = this.$refs['open_chart' + i] as any
          let svgString = test[0].chart.getSVGForExport()

          let canvas = document.createElement('canvas')
          let ctx = canvas.getContext('2d') as any
          let res = await Canvg.from(ctx, svgString)

          res.start()

          this.openAnswersImages.push(canvas.toDataURL().replace(base64Regex, ''))
        }
      }
    },

    makeWordChanges() {
      store.dispatch('getGeneralPollChart', {
        poll_id: this.poll.id,
        classifier_id: this.classifier.id,
        max_cls_rows: this.maxOpenRows,
      })
          .then(() => {
            this.fetchChart()
            this.fetchOpenChart()
          })
          .then(() => {
            this.makeWordForm()
          })
    },

    makeWordForm() {
      console.log('WordFormSettings makeWordForm')
      console.log('poll', this.poll.id)
      const maxOpenRowsInput: any = this.$refs.WordFormSettings_maxOpenRowsInput
      if (!maxOpenRowsInput.validate()) {
        console.log('Ошибка валидации')
        return
      }
      this.isMakeWordForm = true
      console.log('maxOpenRows', this.maxOpenRows)

      store.dispatch('getGeneralPollInfoForm', {
        poll_id: this.poll.id,
        classifier_id: this.classifier.id,
        max_cls_rows: this.maxOpenRows,
      }).then(
          (resolve) => {
            console.log('getPollInfoForm resolve', resolve)
            this.pollUsersCount = resolve.usersCount.toLocaleString()
            this.classifierName = this.classifier.name
            this.questions = resolve.items.map((question: any, index: any) => {
              console.log('QUEST INDEX', index)

              return {
                question_number: question.getQuestionNumber(),
                question_stitle: question.getQuestionStitle(),
                answers: question.getAnswersList().map((answer: any) => {
                  return {
                    answer_num: answer.getAnswerNum(),
                    hasLeader: answer.getAnswerNum() == 1,
                    hasThree: answer.getAnswerNum() <= 3,
                    answer_title: answer.getAnswerTitle(),
                    answer_count: answer.getAnswerCount().toLocaleString(),
                    answer_percent: answer.getAnswerPercent()
                  }
                }),
                answers_image: this.answersImages[index],
                cls_open_answers_image:
                    this.openAnswersChartsComputed[index].series[0].data.length
                        ? this.openAnswersImages[index]
                        : undefined
                ,
                cls_open_answers_source_count: question.getOpenAnswersSourceCount().toLocaleString(),
                hasClsOpenAnswersSource: question.getOpenAnswersSourceCount() > 0,
                hasClsOpenAnswers: question.getClsOpenAnswersList().length > 0,
                class_count: question.getClsOpenAnswersList().length,
                cls_open_answers: question.getClsOpenAnswersList().map((answer: any) => {
                  return {
                    class_num: answer.getClassNum(),
                    hasThree: answer.getClassNum() <= 3,
                    class_name: answer.getClassName(),
                    class_sum: answer.getClassSum().toLocaleString(),
                    class_percent: answer.getClassPercent(),
                  }
                })
              }
            })
            this.renderForm()
          },
          (reject) => {
            console.log('getPollInfoForm reject', reject)
            this.isMakeWordForm = false
          }
      )
    },

    renderForm() {
      let ImageModule = require('docxtemplater-image-module-free')
      function base64DataURLToArrayBuffer(stringBase64: any) {
        const binaryString = window.atob(stringBase64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          const ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        return bytes.buffer;
      }

      loadFile(blankFileWordFormName, (error: string, content: any) => {
        if (error) {
          this.isMakeWordForm = false
          throw error
        }

        let images = this.answersImages

        console.log(toRaw(images))

        const opts = {
          centered: true,
          fileType: 'docx',
          getImage(tag: any) {
            return base64DataURLToArrayBuffer(tag)
          },
          getSize () {
            return [600, 300]
          }
        } as any

        let imageModule = new ImageModule(opts)

        console.log('QUESTIONS', this.questions)

        const zip = new PizZip(content);
        console.log('before try')
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          modules: [imageModule],
        });
        const date = new Date()
        const curDate = this.getFormattedDateOrigin(null)
        try {
          // render the document
          doc
              .render({
                cur_date: curDate,
                poll_title: this.poll.origin_title,
                poll_id: this.poll.id,
                poll_date_begin: this.getFormattedDateOrigin(this.poll.date_begin),
                poll_date_end: this.getFormattedDateOrigin(this.poll.date_end),
                poll_users_count: this.pollUsersCount,
                classifier: this.classifierName,
                questions: this.questions,
              });
          console.log('doc', doc)
        } catch (error) {
          console.log(error);
          this.isMakeWordForm = false
          throw error;
        }

        const out = doc.getZip().generate({
          type: 'blob',
          mimeType:
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        });
        // Output the document using Data-URI
        saveAs(out, fileWordFormName + this.poll.id + '_' + date.valueOf() + '.docx');
        this.isMakeWordForm = false
      });
    },

    closeWordForm() {
      this.$emit('hideModal')
    },

    loadData() {
      store.dispatch('getGeneralPollChart', {
        poll_id: this.poll.id,
        classifier_id: this.classifier.id,
        max_cls_rows: this.maxOpenRows,
      })
          .then(() => {
            this.fetchChart()
            this.fetchOpenChart()
          })
    },
  },

  computed: {
    answersChartsComputed() {
      const rawList = store.getters.getGeneralPollChart
      const filteredList = [] as any
      let tempObj = {} as any

      if (rawList) {
        for (let i = 0; i < rawList.length; i++) {
          filteredList.push(rawList[i])

          tempObj[i] = {
            chart: {
              type: 'bar'
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true
                }
              }
            },
            legend: {
              enabled: false
            },
            title: {
              text: 'Количество голосов'
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
              },
            },
            xAxis: {
              categories: [],
            },
            series: [{
              name:'Количество голосов',
              data:[]
            }]
          }

          rawList[i].getAnswersList().forEach((el: any) => {
            tempObj[i].series[0].data.push({
              y: toRaw(el).getAnswerCount(),
              name: toRaw(el).getAnswerTitle()
            })
            tempObj[i].xAxis.categories.push(
                toRaw(el).getAnswerTitle()
            )
          })
        }
      }

      return tempObj
    },

    openAnswersChartsComputed() {
      const rawList = store.getters.getGeneralPollChart
      const filteredList = [] as any
      let tempObj = {} as any

      if (rawList) {
        console.log('РАВ ЛИСТ', rawList)
        for (let i = 0; i < rawList.length; i++) {
          if (rawList[i].getClsOpenAnswersList().length > 0) {
            filteredList.push(rawList[i])
          }
          else {
            filteredList.push('')
          }

          tempObj[i] = {
            chart: {
              type: 'bar'
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true
                }
              }
            },
            legend: {
              enabled: false
            },
            title: {
              text: 'Количество голосов'
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
              },
            },
            xAxis: {
              categories: [],
            },
            series: [{
              name:'Количество голосов',
              data:[]
            }]
          }

          console.log('БЕЗ ИФА', rawList[i].getClsOpenAnswersList())

          if (rawList[i] !== '') {
            console.log('БЫЛО БЫЛО БЫЛО', rawList[i].getClsOpenAnswersList())
            rawList[i].getClsOpenAnswersList().forEach((el: any) => {
              console.log('take elem', el)
              tempObj[i].series[0].data.push({
                y: toRaw(el).getClassSum(),
                name: toRaw(el).getClassName()
              })
              tempObj[i].xAxis.categories.push(
                  toRaw(el).getClassName()
              )
            })
          }
        }
      }

      return tempObj
    },

    isGeneralPollChartBusy() {
      return store.getters.isGeneralPollChartBusy
    }
  },

  created() {
    // this.loadData()
  },
})
