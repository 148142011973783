import { GetterTree, MutationTree, ActionTree } from "vuex";
import { PollApi } from "../services/api/PollApi";
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
  isPollBusy: boolean = false;
  pollItems: unknown [];
  pollItem: unknown;
}

// Создаем объект
const pollApi = new PollApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

  isPollBusy(state): boolean {
    return state.isPollBusy;
  },

  getPollItems(state): unknown[] {
    return state.pollItems;
  },

  getPollItem(state): unknown {
    return state.pollItem;
  }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

  setIsPollBusy(state, payload: boolean) {
    state.isPollBusy = payload
  },

  setPollItems(state, payload: unknown[]) {
    state.pollItems = payload
  },

  setPollItem(state, payload: unknown) {
    state.pollItem = payload
  }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

  // Устанавливаем элемент
  setPollItem({ commit }, data: any) {
    commit('setPollItem', data);
  },
  
  // Устанавливаем список
  setPollItems({ commit }, data: any) {
    commit('setPollItems', data);
  },
  
  // Список элементов
  getPollItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsPollBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      pollApi.getPollItems(filter, sort, pagination)
        .then(
          (message:any) => {
            const items = message.getItemsList();
            const sort = message.getParams().getSort();
            const pagination = message.getParams().getPagination();
            commit('setPollItems', items);
            commit('setIsPollBusy', false);
            resolve({
                items: items,
                sort: sort,
                pagination: pagination
            });
          },
          (error) => {
            commit('setIsPollBusy', false);
            reject(error);
          }
        );
    });
  },

  // Получить элемент
  getPollItem({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsPollBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];

    return new Promise((resolve, reject) => {

      // Получаем элемент
      pollApi.getPollItem(filter)
        .then(
          (response:any) => {
            commit('setPollItem', response);
            commit('setIsPollBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsPollBusy', false);
            reject(error);
          }
        );
    });
  }
};

// Создаем модуль
const PollModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default PollModule;
