import AccountsModule from './accounts'
import AccountsSrcModule from './accountsSrc'
import AccountActionsModule from './actions'
import AccountEventsModule from './events'
import AccountAvailableEventsModule from "@/components/modules/accounts/store/availableEvents";
import ScorecardAccountProjectModule from './scorecardAccProj'
import ScorecardAccountValueModule from './scorecardAccVal'
import PointsModule from './accountPoints'
import AccountAdressModule from './accountAdress'
import AccountCheckModule from './accountCheck'
import SettingsModalModule from './UserLoyalty/settings'
import activeCoreModule from './UserLoyalty/activeCore'
import segmentationModule from './UserLoyalty/SegmentationMetric'
import countPointSegm from './UserLoyalty/countPointSegm'
import regDistr from './RegDistr'
import userAnswersModule from "./userAnswers";
import familyStatusModule from "@/components/modules/accounts/store/familyStatus";
import socialListModule from "@/components/modules/accounts/store/socialList";
import typesListModule from "@/components/modules/accounts/store/types";
import messageListModule from "@/components/modules/accounts/store/message";
import agReportModule from "@/components/modules/activity/store/report";


export default {
  AccountsModule,
  AccountsSrcModule,
  AccountActionsModule,
  AccountEventsModule,
  AccountAvailableEventsModule,
  ScorecardAccountProjectModule,
  ScorecardAccountValueModule,
  PointsModule,
  AccountAdressModule,
  AccountCheckModule,
  SettingsModalModule,
  activeCoreModule,
  segmentationModule,
  countPointSegm,
  regDistr,
  userAnswersModule,
  familyStatusModule,
  socialListModule,
  typesListModule,
  messageListModule,
  agReportModule,
}
