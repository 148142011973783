/* eslint-disable */
// source: scorecard_value_account.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.proto.CalculateScorecardValueAccountRequest', null, global);
goog.exportSymbol('proto.proto.CalculateScorecardValueAccountResponse', null, global);
goog.exportSymbol('proto.proto.CreateScorecardValueAccountRequest', null, global);
goog.exportSymbol('proto.proto.CreateScorecardValueAccountResponse', null, global);
goog.exportSymbol('proto.proto.DeleteScorecardValueAccountRequest', null, global);
goog.exportSymbol('proto.proto.DeleteScorecardValueAccountResponse', null, global);
goog.exportSymbol('proto.proto.ListScorecardValueAccountRequest', null, global);
goog.exportSymbol('proto.proto.ListScorecardValueAccountResponse', null, global);
goog.exportSymbol('proto.proto.ScorecardValueAccount', null, global);
goog.exportSymbol('proto.proto.ScorecardValueAccountFilter', null, global);
goog.exportSymbol('proto.proto.ScorecardValueAccountFilterItem', null, global);
goog.exportSymbol('proto.proto.ScorecardValueAccountPagination', null, global);
goog.exportSymbol('proto.proto.ScorecardValueAccountSort', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ScorecardValueAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ScorecardValueAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ScorecardValueAccount.displayName = 'proto.proto.ScorecardValueAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ScorecardValueAccountFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ScorecardValueAccountFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ScorecardValueAccountFilterItem.displayName = 'proto.proto.ScorecardValueAccountFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ScorecardValueAccountFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ScorecardValueAccountFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.ScorecardValueAccountFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ScorecardValueAccountFilter.displayName = 'proto.proto.ScorecardValueAccountFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ScorecardValueAccountSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ScorecardValueAccountSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ScorecardValueAccountSort.displayName = 'proto.proto.ScorecardValueAccountSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ScorecardValueAccountPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ScorecardValueAccountPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ScorecardValueAccountPagination.displayName = 'proto.proto.ScorecardValueAccountPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListScorecardValueAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListScorecardValueAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListScorecardValueAccountRequest.displayName = 'proto.proto.ListScorecardValueAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListScorecardValueAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListScorecardValueAccountResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListScorecardValueAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListScorecardValueAccountResponse.displayName = 'proto.proto.ListScorecardValueAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateScorecardValueAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateScorecardValueAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateScorecardValueAccountRequest.displayName = 'proto.proto.CreateScorecardValueAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateScorecardValueAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateScorecardValueAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateScorecardValueAccountResponse.displayName = 'proto.proto.CreateScorecardValueAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteScorecardValueAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeleteScorecardValueAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteScorecardValueAccountRequest.displayName = 'proto.proto.DeleteScorecardValueAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteScorecardValueAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeleteScorecardValueAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteScorecardValueAccountResponse.displayName = 'proto.proto.DeleteScorecardValueAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CalculateScorecardValueAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CalculateScorecardValueAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CalculateScorecardValueAccountRequest.displayName = 'proto.proto.CalculateScorecardValueAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CalculateScorecardValueAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CalculateScorecardValueAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CalculateScorecardValueAccountResponse.displayName = 'proto.proto.CalculateScorecardValueAccountResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ScorecardValueAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ScorecardValueAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ScorecardValueAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ScorecardValueAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    periodId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    scoreValueId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    accountTypeName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    accountTypeDescr: jspb.Message.getFieldWithDefault(msg, 6, ""),
    accountPoints: jspb.Message.getFieldWithDefault(msg, 7, 0),
    projectName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    projectShortName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    periodDateBegin: (f = msg.getPeriodDateBegin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    periodDateEnd: (f = msg.getPeriodDateEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    scorecardId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    scorecardIdx: jspb.Message.getFieldWithDefault(msg, 13, 0),
    scorecardTitle: jspb.Message.getFieldWithDefault(msg, 14, ""),
    scorecardWeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    scoreValueIdx: jspb.Message.getFieldWithDefault(msg, 16, 0),
    scoreValueTitle: jspb.Message.getFieldWithDefault(msg, 17, ""),
    scoreValuePoints: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ScorecardValueAccount}
 */
proto.proto.ScorecardValueAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ScorecardValueAccount;
  return proto.proto.ScorecardValueAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ScorecardValueAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ScorecardValueAccount}
 */
proto.proto.ScorecardValueAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountGuid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPeriodId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScoreValueId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountTypeName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountTypeDescr(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountPoints(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectShortName(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPeriodDateBegin(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPeriodDateEnd(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScorecardId(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScorecardIdx(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setScorecardTitle(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScorecardWeight(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScoreValueIdx(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoreValueTitle(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScoreValuePoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ScorecardValueAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ScorecardValueAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ScorecardValueAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ScorecardValueAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPeriodId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getScoreValueId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAccountTypeName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccountTypeDescr();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccountPoints();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProjectShortName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPeriodDateBegin();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPeriodDateEnd();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getScorecardId();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getScorecardIdx();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getScorecardTitle();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getScorecardWeight();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getScoreValueIdx();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getScoreValueTitle();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getScoreValuePoints();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
};


/**
 * optional string account_guid = 1;
 * @return {string}
 */
proto.proto.ScorecardValueAccount.prototype.getAccountGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.setAccountGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 project_id = 2;
 * @return {number}
 */
proto.proto.ScorecardValueAccount.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 period_id = 3;
 * @return {number}
 */
proto.proto.ScorecardValueAccount.prototype.getPeriodId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.setPeriodId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 score_value_id = 4;
 * @return {number}
 */
proto.proto.ScorecardValueAccount.prototype.getScoreValueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.setScoreValueId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string account_type_name = 5;
 * @return {string}
 */
proto.proto.ScorecardValueAccount.prototype.getAccountTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.setAccountTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string account_type_descr = 6;
 * @return {string}
 */
proto.proto.ScorecardValueAccount.prototype.getAccountTypeDescr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.setAccountTypeDescr = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 account_points = 7;
 * @return {number}
 */
proto.proto.ScorecardValueAccount.prototype.getAccountPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.setAccountPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string project_name = 8;
 * @return {string}
 */
proto.proto.ScorecardValueAccount.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string project_short_name = 9;
 * @return {string}
 */
proto.proto.ScorecardValueAccount.prototype.getProjectShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.setProjectShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp period_date_begin = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.ScorecardValueAccount.prototype.getPeriodDateBegin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
*/
proto.proto.ScorecardValueAccount.prototype.setPeriodDateBegin = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.clearPeriodDateBegin = function() {
  return this.setPeriodDateBegin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ScorecardValueAccount.prototype.hasPeriodDateBegin = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp period_date_end = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.ScorecardValueAccount.prototype.getPeriodDateEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
*/
proto.proto.ScorecardValueAccount.prototype.setPeriodDateEnd = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.clearPeriodDateEnd = function() {
  return this.setPeriodDateEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ScorecardValueAccount.prototype.hasPeriodDateEnd = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int64 scorecard_id = 12;
 * @return {number}
 */
proto.proto.ScorecardValueAccount.prototype.getScorecardId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.setScorecardId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 scorecard_idx = 13;
 * @return {number}
 */
proto.proto.ScorecardValueAccount.prototype.getScorecardIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.setScorecardIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string scorecard_title = 14;
 * @return {string}
 */
proto.proto.ScorecardValueAccount.prototype.getScorecardTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.setScorecardTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional float scorecard_weight = 15;
 * @return {number}
 */
proto.proto.ScorecardValueAccount.prototype.getScorecardWeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.setScorecardWeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional int64 score_value_idx = 16;
 * @return {number}
 */
proto.proto.ScorecardValueAccount.prototype.getScoreValueIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.setScoreValueIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string score_value_title = 17;
 * @return {string}
 */
proto.proto.ScorecardValueAccount.prototype.getScoreValueTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.setScoreValueTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int64 score_value_points = 18;
 * @return {number}
 */
proto.proto.ScorecardValueAccount.prototype.getScoreValuePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScorecardValueAccount} returns this
 */
proto.proto.ScorecardValueAccount.prototype.setScoreValuePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ScorecardValueAccountFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ScorecardValueAccountFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ScorecardValueAccountFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ScorecardValueAccountFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.ScorecardValueAccountFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ScorecardValueAccountFilterItem}
 */
proto.proto.ScorecardValueAccountFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ScorecardValueAccountFilterItem;
  return proto.proto.ScorecardValueAccountFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ScorecardValueAccountFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ScorecardValueAccountFilterItem}
 */
proto.proto.ScorecardValueAccountFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.ScorecardValueAccountFilter;
      reader.readMessage(value,proto.proto.ScorecardValueAccountFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ScorecardValueAccountFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ScorecardValueAccountFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ScorecardValueAccountFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ScorecardValueAccountFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.ScorecardValueAccountFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.ScorecardValueAccountFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ScorecardValueAccountFilterItem} returns this
 */
proto.proto.ScorecardValueAccountFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.ScorecardValueAccountFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ScorecardValueAccountFilterItem} returns this
 */
proto.proto.ScorecardValueAccountFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.ScorecardValueAccountFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ScorecardValueAccountFilterItem} returns this
 */
proto.proto.ScorecardValueAccountFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ScorecardValueAccountFilter or = 4;
 * @return {?proto.proto.ScorecardValueAccountFilter}
 */
proto.proto.ScorecardValueAccountFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.ScorecardValueAccountFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.ScorecardValueAccountFilter, 4));
};


/**
 * @param {?proto.proto.ScorecardValueAccountFilter|undefined} value
 * @return {!proto.proto.ScorecardValueAccountFilterItem} returns this
*/
proto.proto.ScorecardValueAccountFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ScorecardValueAccountFilterItem} returns this
 */
proto.proto.ScorecardValueAccountFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ScorecardValueAccountFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ScorecardValueAccountFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ScorecardValueAccountFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ScorecardValueAccountFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ScorecardValueAccountFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ScorecardValueAccountFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.ScorecardValueAccountFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ScorecardValueAccountFilter}
 */
proto.proto.ScorecardValueAccountFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ScorecardValueAccountFilter;
  return proto.proto.ScorecardValueAccountFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ScorecardValueAccountFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ScorecardValueAccountFilter}
 */
proto.proto.ScorecardValueAccountFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.ScorecardValueAccountFilterItem;
      reader.readMessage(value,proto.proto.ScorecardValueAccountFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ScorecardValueAccountFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ScorecardValueAccountFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ScorecardValueAccountFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ScorecardValueAccountFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.ScorecardValueAccountFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ScorecardValueAccountFilterItem items = 1;
 * @return {!Array<!proto.proto.ScorecardValueAccountFilterItem>}
 */
proto.proto.ScorecardValueAccountFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.ScorecardValueAccountFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.ScorecardValueAccountFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.ScorecardValueAccountFilterItem>} value
 * @return {!proto.proto.ScorecardValueAccountFilter} returns this
*/
proto.proto.ScorecardValueAccountFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.ScorecardValueAccountFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ScorecardValueAccountFilterItem}
 */
proto.proto.ScorecardValueAccountFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.ScorecardValueAccountFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ScorecardValueAccountFilter} returns this
 */
proto.proto.ScorecardValueAccountFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ScorecardValueAccountSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ScorecardValueAccountSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ScorecardValueAccountSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ScorecardValueAccountSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ScorecardValueAccountSort}
 */
proto.proto.ScorecardValueAccountSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ScorecardValueAccountSort;
  return proto.proto.ScorecardValueAccountSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ScorecardValueAccountSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ScorecardValueAccountSort}
 */
proto.proto.ScorecardValueAccountSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ScorecardValueAccountSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ScorecardValueAccountSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ScorecardValueAccountSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ScorecardValueAccountSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.ScorecardValueAccountSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ScorecardValueAccountSort} returns this
 */
proto.proto.ScorecardValueAccountSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.ScorecardValueAccountSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ScorecardValueAccountSort} returns this
 */
proto.proto.ScorecardValueAccountSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ScorecardValueAccountPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ScorecardValueAccountPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ScorecardValueAccountPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ScorecardValueAccountPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ScorecardValueAccountPagination}
 */
proto.proto.ScorecardValueAccountPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ScorecardValueAccountPagination;
  return proto.proto.ScorecardValueAccountPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ScorecardValueAccountPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ScorecardValueAccountPagination}
 */
proto.proto.ScorecardValueAccountPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ScorecardValueAccountPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ScorecardValueAccountPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ScorecardValueAccountPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ScorecardValueAccountPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.ScorecardValueAccountPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScorecardValueAccountPagination} returns this
 */
proto.proto.ScorecardValueAccountPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.ScorecardValueAccountPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScorecardValueAccountPagination} returns this
 */
proto.proto.ScorecardValueAccountPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.ScorecardValueAccountPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScorecardValueAccountPagination} returns this
 */
proto.proto.ScorecardValueAccountPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt = 4;
 * @return {number}
 */
proto.proto.ScorecardValueAccountPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScorecardValueAccountPagination} returns this
 */
proto.proto.ScorecardValueAccountPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListScorecardValueAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListScorecardValueAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListScorecardValueAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListScorecardValueAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.ScorecardValueAccountFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.ScorecardValueAccountSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.ScorecardValueAccountPagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListScorecardValueAccountRequest}
 */
proto.proto.ListScorecardValueAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListScorecardValueAccountRequest;
  return proto.proto.ListScorecardValueAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListScorecardValueAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListScorecardValueAccountRequest}
 */
proto.proto.ListScorecardValueAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.ScorecardValueAccountFilter;
      reader.readMessage(value,proto.proto.ScorecardValueAccountFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.ScorecardValueAccountSort;
      reader.readMessage(value,proto.proto.ScorecardValueAccountSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.ScorecardValueAccountPagination;
      reader.readMessage(value,proto.proto.ScorecardValueAccountPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListScorecardValueAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListScorecardValueAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListScorecardValueAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListScorecardValueAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.ScorecardValueAccountFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ScorecardValueAccountSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.ScorecardValueAccountPagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScorecardValueAccountFilter filter = 1;
 * @return {?proto.proto.ScorecardValueAccountFilter}
 */
proto.proto.ListScorecardValueAccountRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.ScorecardValueAccountFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.ScorecardValueAccountFilter, 1));
};


/**
 * @param {?proto.proto.ScorecardValueAccountFilter|undefined} value
 * @return {!proto.proto.ListScorecardValueAccountRequest} returns this
*/
proto.proto.ListScorecardValueAccountRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListScorecardValueAccountRequest} returns this
 */
proto.proto.ListScorecardValueAccountRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListScorecardValueAccountRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ScorecardValueAccountSort sort = 2;
 * @return {?proto.proto.ScorecardValueAccountSort}
 */
proto.proto.ListScorecardValueAccountRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.ScorecardValueAccountSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.ScorecardValueAccountSort, 2));
};


/**
 * @param {?proto.proto.ScorecardValueAccountSort|undefined} value
 * @return {!proto.proto.ListScorecardValueAccountRequest} returns this
*/
proto.proto.ListScorecardValueAccountRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListScorecardValueAccountRequest} returns this
 */
proto.proto.ListScorecardValueAccountRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListScorecardValueAccountRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ScorecardValueAccountPagination pagination = 3;
 * @return {?proto.proto.ScorecardValueAccountPagination}
 */
proto.proto.ListScorecardValueAccountRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.ScorecardValueAccountPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.ScorecardValueAccountPagination, 3));
};


/**
 * @param {?proto.proto.ScorecardValueAccountPagination|undefined} value
 * @return {!proto.proto.ListScorecardValueAccountRequest} returns this
*/
proto.proto.ListScorecardValueAccountRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListScorecardValueAccountRequest} returns this
 */
proto.proto.ListScorecardValueAccountRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListScorecardValueAccountRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListScorecardValueAccountResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListScorecardValueAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListScorecardValueAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListScorecardValueAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListScorecardValueAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.ScorecardValueAccount.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListScorecardValueAccountRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListScorecardValueAccountResponse}
 */
proto.proto.ListScorecardValueAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListScorecardValueAccountResponse;
  return proto.proto.ListScorecardValueAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListScorecardValueAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListScorecardValueAccountResponse}
 */
proto.proto.ListScorecardValueAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.ScorecardValueAccount;
      reader.readMessage(value,proto.proto.ScorecardValueAccount.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListScorecardValueAccountRequest;
      reader.readMessage(value,proto.proto.ListScorecardValueAccountRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListScorecardValueAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListScorecardValueAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListScorecardValueAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListScorecardValueAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.ScorecardValueAccount.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListScorecardValueAccountRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ScorecardValueAccount items = 1;
 * @return {!Array<!proto.proto.ScorecardValueAccount>}
 */
proto.proto.ListScorecardValueAccountResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.ScorecardValueAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.ScorecardValueAccount, 1));
};


/**
 * @param {!Array<!proto.proto.ScorecardValueAccount>} value
 * @return {!proto.proto.ListScorecardValueAccountResponse} returns this
*/
proto.proto.ListScorecardValueAccountResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.ScorecardValueAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ScorecardValueAccount}
 */
proto.proto.ListScorecardValueAccountResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.ScorecardValueAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListScorecardValueAccountResponse} returns this
 */
proto.proto.ListScorecardValueAccountResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListScorecardValueAccountRequest params = 2;
 * @return {?proto.proto.ListScorecardValueAccountRequest}
 */
proto.proto.ListScorecardValueAccountResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListScorecardValueAccountRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListScorecardValueAccountRequest, 2));
};


/**
 * @param {?proto.proto.ListScorecardValueAccountRequest|undefined} value
 * @return {!proto.proto.ListScorecardValueAccountResponse} returns this
*/
proto.proto.ListScorecardValueAccountResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListScorecardValueAccountResponse} returns this
 */
proto.proto.ListScorecardValueAccountResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListScorecardValueAccountResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateScorecardValueAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateScorecardValueAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateScorecardValueAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateScorecardValueAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    periodId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    scoreValueId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateScorecardValueAccountRequest}
 */
proto.proto.CreateScorecardValueAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateScorecardValueAccountRequest;
  return proto.proto.CreateScorecardValueAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateScorecardValueAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateScorecardValueAccountRequest}
 */
proto.proto.CreateScorecardValueAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountGuid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPeriodId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScoreValueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateScorecardValueAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateScorecardValueAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateScorecardValueAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateScorecardValueAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPeriodId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getScoreValueId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string account_guid = 1;
 * @return {string}
 */
proto.proto.CreateScorecardValueAccountRequest.prototype.getAccountGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateScorecardValueAccountRequest} returns this
 */
proto.proto.CreateScorecardValueAccountRequest.prototype.setAccountGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 project_id = 2;
 * @return {number}
 */
proto.proto.CreateScorecardValueAccountRequest.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateScorecardValueAccountRequest} returns this
 */
proto.proto.CreateScorecardValueAccountRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 period_id = 3;
 * @return {number}
 */
proto.proto.CreateScorecardValueAccountRequest.prototype.getPeriodId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateScorecardValueAccountRequest} returns this
 */
proto.proto.CreateScorecardValueAccountRequest.prototype.setPeriodId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 score_value_id = 4;
 * @return {number}
 */
proto.proto.CreateScorecardValueAccountRequest.prototype.getScoreValueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateScorecardValueAccountRequest} returns this
 */
proto.proto.CreateScorecardValueAccountRequest.prototype.setScoreValueId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateScorecardValueAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateScorecardValueAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateScorecardValueAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateScorecardValueAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountGuid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateScorecardValueAccountResponse}
 */
proto.proto.CreateScorecardValueAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateScorecardValueAccountResponse;
  return proto.proto.CreateScorecardValueAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateScorecardValueAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateScorecardValueAccountResponse}
 */
proto.proto.CreateScorecardValueAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountGuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateScorecardValueAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateScorecardValueAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateScorecardValueAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateScorecardValueAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account_guid = 1;
 * @return {string}
 */
proto.proto.CreateScorecardValueAccountResponse.prototype.getAccountGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateScorecardValueAccountResponse} returns this
 */
proto.proto.CreateScorecardValueAccountResponse.prototype.setAccountGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteScorecardValueAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteScorecardValueAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteScorecardValueAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteScorecardValueAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    periodId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    scoreValueId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteScorecardValueAccountRequest}
 */
proto.proto.DeleteScorecardValueAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteScorecardValueAccountRequest;
  return proto.proto.DeleteScorecardValueAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteScorecardValueAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteScorecardValueAccountRequest}
 */
proto.proto.DeleteScorecardValueAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountGuid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPeriodId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScoreValueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteScorecardValueAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteScorecardValueAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteScorecardValueAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteScorecardValueAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPeriodId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getScoreValueId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string account_guid = 1;
 * @return {string}
 */
proto.proto.DeleteScorecardValueAccountRequest.prototype.getAccountGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DeleteScorecardValueAccountRequest} returns this
 */
proto.proto.DeleteScorecardValueAccountRequest.prototype.setAccountGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 project_id = 2;
 * @return {number}
 */
proto.proto.DeleteScorecardValueAccountRequest.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DeleteScorecardValueAccountRequest} returns this
 */
proto.proto.DeleteScorecardValueAccountRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 period_id = 3;
 * @return {number}
 */
proto.proto.DeleteScorecardValueAccountRequest.prototype.getPeriodId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DeleteScorecardValueAccountRequest} returns this
 */
proto.proto.DeleteScorecardValueAccountRequest.prototype.setPeriodId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 score_value_id = 4;
 * @return {number}
 */
proto.proto.DeleteScorecardValueAccountRequest.prototype.getScoreValueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DeleteScorecardValueAccountRequest} returns this
 */
proto.proto.DeleteScorecardValueAccountRequest.prototype.setScoreValueId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteScorecardValueAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteScorecardValueAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteScorecardValueAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteScorecardValueAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountGuid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteScorecardValueAccountResponse}
 */
proto.proto.DeleteScorecardValueAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteScorecardValueAccountResponse;
  return proto.proto.DeleteScorecardValueAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteScorecardValueAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteScorecardValueAccountResponse}
 */
proto.proto.DeleteScorecardValueAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountGuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteScorecardValueAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteScorecardValueAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteScorecardValueAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteScorecardValueAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account_guid = 1;
 * @return {string}
 */
proto.proto.DeleteScorecardValueAccountResponse.prototype.getAccountGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DeleteScorecardValueAccountResponse} returns this
 */
proto.proto.DeleteScorecardValueAccountResponse.prototype.setAccountGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CalculateScorecardValueAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CalculateScorecardValueAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CalculateScorecardValueAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CalculateScorecardValueAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.ScorecardValueAccountFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CalculateScorecardValueAccountRequest}
 */
proto.proto.CalculateScorecardValueAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CalculateScorecardValueAccountRequest;
  return proto.proto.CalculateScorecardValueAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CalculateScorecardValueAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CalculateScorecardValueAccountRequest}
 */
proto.proto.CalculateScorecardValueAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.ScorecardValueAccountFilter;
      reader.readMessage(value,proto.proto.ScorecardValueAccountFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CalculateScorecardValueAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CalculateScorecardValueAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CalculateScorecardValueAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CalculateScorecardValueAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.ScorecardValueAccountFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScorecardValueAccountFilter filter = 1;
 * @return {?proto.proto.ScorecardValueAccountFilter}
 */
proto.proto.CalculateScorecardValueAccountRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.ScorecardValueAccountFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.ScorecardValueAccountFilter, 1));
};


/**
 * @param {?proto.proto.ScorecardValueAccountFilter|undefined} value
 * @return {!proto.proto.CalculateScorecardValueAccountRequest} returns this
*/
proto.proto.CalculateScorecardValueAccountRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CalculateScorecardValueAccountRequest} returns this
 */
proto.proto.CalculateScorecardValueAccountRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CalculateScorecardValueAccountRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CalculateScorecardValueAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CalculateScorecardValueAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CalculateScorecardValueAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CalculateScorecardValueAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CalculateScorecardValueAccountResponse}
 */
proto.proto.CalculateScorecardValueAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CalculateScorecardValueAccountResponse;
  return proto.proto.CalculateScorecardValueAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CalculateScorecardValueAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CalculateScorecardValueAccountResponse}
 */
proto.proto.CalculateScorecardValueAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CalculateScorecardValueAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CalculateScorecardValueAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CalculateScorecardValueAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CalculateScorecardValueAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.proto.CalculateScorecardValueAccountResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.CalculateScorecardValueAccountResponse} returns this
 */
proto.proto.CalculateScorecardValueAccountResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
