<template>
  <div class="q-pa-md">
    <div class="text-h4 q-pb-md">Отчеты</div>
    <ReportList />
  </div>
</template>

<script>
import ReportList from "@/components/ReportList.vue";

export default {
  name: "ReportsPage",
  components: {ReportList},
}
</script>

<style scoped>

</style>