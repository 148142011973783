/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: loyalty.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as loyalty_pb from './loyalty_pb';


export class DashboardLoyaltyServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetDashboardLoyaltyStatusChart = new grpcWeb.MethodDescriptor(
    '/proto.DashboardLoyaltyService/GetDashboardLoyaltyStatusChart',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.GetDashboardLoyaltyStatusChartRequest,
    loyalty_pb.GetDashboardLoyaltyStatusChartResponse,
    (request: loyalty_pb.GetDashboardLoyaltyStatusChartRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.GetDashboardLoyaltyStatusChartResponse.deserializeBinary
  );

  getDashboardLoyaltyStatusChart(
    request: loyalty_pb.GetDashboardLoyaltyStatusChartRequest,
    metadata: grpcWeb.Metadata | null): Promise<loyalty_pb.GetDashboardLoyaltyStatusChartResponse>;

  getDashboardLoyaltyStatusChart(
    request: loyalty_pb.GetDashboardLoyaltyStatusChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyStatusChartResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.GetDashboardLoyaltyStatusChartResponse>;

  getDashboardLoyaltyStatusChart(
    request: loyalty_pb.GetDashboardLoyaltyStatusChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyStatusChartResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardLoyaltyService/GetDashboardLoyaltyStatusChart',
        request,
        metadata || {},
        this.methodDescriptorGetDashboardLoyaltyStatusChart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardLoyaltyService/GetDashboardLoyaltyStatusChart',
    request,
    metadata || {},
    this.methodDescriptorGetDashboardLoyaltyStatusChart);
  }

  methodDescriptorListDashboardLoyaltyEventTypes = new grpcWeb.MethodDescriptor(
    '/proto.DashboardLoyaltyService/ListDashboardLoyaltyEventTypes',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.ListDashboardLoyaltyEventTypesRequest,
    loyalty_pb.ListDashboardLoyaltyEventTypesResponse,
    (request: loyalty_pb.ListDashboardLoyaltyEventTypesRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.ListDashboardLoyaltyEventTypesResponse.deserializeBinary
  );

  listDashboardLoyaltyEventTypes(
    request: loyalty_pb.ListDashboardLoyaltyEventTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<loyalty_pb.ListDashboardLoyaltyEventTypesResponse>;

  listDashboardLoyaltyEventTypes(
    request: loyalty_pb.ListDashboardLoyaltyEventTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.ListDashboardLoyaltyEventTypesResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.ListDashboardLoyaltyEventTypesResponse>;

  listDashboardLoyaltyEventTypes(
    request: loyalty_pb.ListDashboardLoyaltyEventTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.ListDashboardLoyaltyEventTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardLoyaltyService/ListDashboardLoyaltyEventTypes',
        request,
        metadata || {},
        this.methodDescriptorListDashboardLoyaltyEventTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardLoyaltyService/ListDashboardLoyaltyEventTypes',
    request,
    metadata || {},
    this.methodDescriptorListDashboardLoyaltyEventTypes);
  }

  methodDescriptorGetDashboardLoyaltyRegistrationChart = new grpcWeb.MethodDescriptor(
    '/proto.DashboardLoyaltyService/GetDashboardLoyaltyRegistrationChart',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.GetDashboardLoyaltyRegistrationChartRequest,
    loyalty_pb.GetDashboardLoyaltyRegistrationChartResponse,
    (request: loyalty_pb.GetDashboardLoyaltyRegistrationChartRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.GetDashboardLoyaltyRegistrationChartResponse.deserializeBinary
  );

  getDashboardLoyaltyRegistrationChart(
    request: loyalty_pb.GetDashboardLoyaltyRegistrationChartRequest,
    metadata: grpcWeb.Metadata | null): Promise<loyalty_pb.GetDashboardLoyaltyRegistrationChartResponse>;

  getDashboardLoyaltyRegistrationChart(
    request: loyalty_pb.GetDashboardLoyaltyRegistrationChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyRegistrationChartResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.GetDashboardLoyaltyRegistrationChartResponse>;

  getDashboardLoyaltyRegistrationChart(
    request: loyalty_pb.GetDashboardLoyaltyRegistrationChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyRegistrationChartResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardLoyaltyService/GetDashboardLoyaltyRegistrationChart',
        request,
        metadata || {},
        this.methodDescriptorGetDashboardLoyaltyRegistrationChart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardLoyaltyService/GetDashboardLoyaltyRegistrationChart',
    request,
    metadata || {},
    this.methodDescriptorGetDashboardLoyaltyRegistrationChart);
  }

  methodDescriptorListDashboardLoyaltyTransactionByProject = new grpcWeb.MethodDescriptor(
    '/proto.DashboardLoyaltyService/ListDashboardLoyaltyTransactionByProject',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.ListDashboardLoyaltyTransactionByProjectRequest,
    loyalty_pb.ListDashboardLoyaltyTransactionByProjectResponse,
    (request: loyalty_pb.ListDashboardLoyaltyTransactionByProjectRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.ListDashboardLoyaltyTransactionByProjectResponse.deserializeBinary
  );

  listDashboardLoyaltyTransactionByProject(
    request: loyalty_pb.ListDashboardLoyaltyTransactionByProjectRequest,
    metadata: grpcWeb.Metadata | null): Promise<loyalty_pb.ListDashboardLoyaltyTransactionByProjectResponse>;

  listDashboardLoyaltyTransactionByProject(
    request: loyalty_pb.ListDashboardLoyaltyTransactionByProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.ListDashboardLoyaltyTransactionByProjectResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.ListDashboardLoyaltyTransactionByProjectResponse>;

  listDashboardLoyaltyTransactionByProject(
    request: loyalty_pb.ListDashboardLoyaltyTransactionByProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.ListDashboardLoyaltyTransactionByProjectResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardLoyaltyService/ListDashboardLoyaltyTransactionByProject',
        request,
        metadata || {},
        this.methodDescriptorListDashboardLoyaltyTransactionByProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardLoyaltyService/ListDashboardLoyaltyTransactionByProject',
    request,
    metadata || {},
    this.methodDescriptorListDashboardLoyaltyTransactionByProject);
  }

  methodDescriptorGetDashboardLoyaltyTransactionsChart = new grpcWeb.MethodDescriptor(
    '/proto.DashboardLoyaltyService/GetDashboardLoyaltyTransactionsChart',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.GetDashboardLoyaltyTransactionsChartRequest,
    loyalty_pb.GetDashboardLoyaltyTransactionsChartResponse,
    (request: loyalty_pb.GetDashboardLoyaltyTransactionsChartRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.GetDashboardLoyaltyTransactionsChartResponse.deserializeBinary
  );

  getDashboardLoyaltyTransactionsChart(
    request: loyalty_pb.GetDashboardLoyaltyTransactionsChartRequest,
    metadata: grpcWeb.Metadata | null): Promise<loyalty_pb.GetDashboardLoyaltyTransactionsChartResponse>;

  getDashboardLoyaltyTransactionsChart(
    request: loyalty_pb.GetDashboardLoyaltyTransactionsChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyTransactionsChartResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.GetDashboardLoyaltyTransactionsChartResponse>;

  getDashboardLoyaltyTransactionsChart(
    request: loyalty_pb.GetDashboardLoyaltyTransactionsChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyTransactionsChartResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardLoyaltyService/GetDashboardLoyaltyTransactionsChart',
        request,
        metadata || {},
        this.methodDescriptorGetDashboardLoyaltyTransactionsChart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardLoyaltyService/GetDashboardLoyaltyTransactionsChart',
    request,
    metadata || {},
    this.methodDescriptorGetDashboardLoyaltyTransactionsChart);
  }

  methodDescriptorGetDashboardLoyaltyUniqUsersChart = new grpcWeb.MethodDescriptor(
    '/proto.DashboardLoyaltyService/GetDashboardLoyaltyUniqUsersChart',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.GetDashboardLoyaltyUniqUsersChartRequest,
    loyalty_pb.GetDashboardLoyaltyUniqUsersChartResponse,
    (request: loyalty_pb.GetDashboardLoyaltyUniqUsersChartRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.GetDashboardLoyaltyUniqUsersChartResponse.deserializeBinary
  );

  getDashboardLoyaltyUniqUsersChart(
    request: loyalty_pb.GetDashboardLoyaltyUniqUsersChartRequest,
    metadata: grpcWeb.Metadata | null): Promise<loyalty_pb.GetDashboardLoyaltyUniqUsersChartResponse>;

  getDashboardLoyaltyUniqUsersChart(
    request: loyalty_pb.GetDashboardLoyaltyUniqUsersChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyUniqUsersChartResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.GetDashboardLoyaltyUniqUsersChartResponse>;

  getDashboardLoyaltyUniqUsersChart(
    request: loyalty_pb.GetDashboardLoyaltyUniqUsersChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyUniqUsersChartResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardLoyaltyService/GetDashboardLoyaltyUniqUsersChart',
        request,
        metadata || {},
        this.methodDescriptorGetDashboardLoyaltyUniqUsersChart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardLoyaltyService/GetDashboardLoyaltyUniqUsersChart',
    request,
    metadata || {},
    this.methodDescriptorGetDashboardLoyaltyUniqUsersChart);
  }

  methodDescriptorListDashboardLoyaltyTransactionByType = new grpcWeb.MethodDescriptor(
    '/proto.DashboardLoyaltyService/ListDashboardLoyaltyTransactionByType',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.ListDashboardLoyaltyTransactionByTypeRequest,
    loyalty_pb.ListDashboardLoyaltyTransactionByTypeResponse,
    (request: loyalty_pb.ListDashboardLoyaltyTransactionByTypeRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.ListDashboardLoyaltyTransactionByTypeResponse.deserializeBinary
  );

  listDashboardLoyaltyTransactionByType(
    request: loyalty_pb.ListDashboardLoyaltyTransactionByTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<loyalty_pb.ListDashboardLoyaltyTransactionByTypeResponse>;

  listDashboardLoyaltyTransactionByType(
    request: loyalty_pb.ListDashboardLoyaltyTransactionByTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.ListDashboardLoyaltyTransactionByTypeResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.ListDashboardLoyaltyTransactionByTypeResponse>;

  listDashboardLoyaltyTransactionByType(
    request: loyalty_pb.ListDashboardLoyaltyTransactionByTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.ListDashboardLoyaltyTransactionByTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardLoyaltyService/ListDashboardLoyaltyTransactionByType',
        request,
        metadata || {},
        this.methodDescriptorListDashboardLoyaltyTransactionByType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardLoyaltyService/ListDashboardLoyaltyTransactionByType',
    request,
    metadata || {},
    this.methodDescriptorListDashboardLoyaltyTransactionByType);
  }

  methodDescriptorGetDashboardLoyaltyUserRatingChart = new grpcWeb.MethodDescriptor(
    '/proto.DashboardLoyaltyService/GetDashboardLoyaltyUserRatingChart',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.GetDashboardLoyaltyUserRatingChartRequest,
    loyalty_pb.GetDashboardLoyaltyUserRatingChartResponse,
    (request: loyalty_pb.GetDashboardLoyaltyUserRatingChartRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.GetDashboardLoyaltyUserRatingChartResponse.deserializeBinary
  );

  getDashboardLoyaltyUserRatingChart(
    request: loyalty_pb.GetDashboardLoyaltyUserRatingChartRequest,
    metadata: grpcWeb.Metadata | null): Promise<loyalty_pb.GetDashboardLoyaltyUserRatingChartResponse>;

  getDashboardLoyaltyUserRatingChart(
    request: loyalty_pb.GetDashboardLoyaltyUserRatingChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyUserRatingChartResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.GetDashboardLoyaltyUserRatingChartResponse>;

  getDashboardLoyaltyUserRatingChart(
    request: loyalty_pb.GetDashboardLoyaltyUserRatingChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyUserRatingChartResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardLoyaltyService/GetDashboardLoyaltyUserRatingChart',
        request,
        metadata || {},
        this.methodDescriptorGetDashboardLoyaltyUserRatingChart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardLoyaltyService/GetDashboardLoyaltyUserRatingChart',
    request,
    metadata || {},
    this.methodDescriptorGetDashboardLoyaltyUserRatingChart);
  }

  methodDescriptorGetDashboardLoyaltyMainValues = new grpcWeb.MethodDescriptor(
    '/proto.DashboardLoyaltyService/GetDashboardLoyaltyMainValues',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.GetDashboardLoyaltyMainValuesRequest,
    loyalty_pb.GetDashboardLoyaltyMainValuesResponse,
    (request: loyalty_pb.GetDashboardLoyaltyMainValuesRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.GetDashboardLoyaltyMainValuesResponse.deserializeBinary
  );

  getDashboardLoyaltyMainValues(
    request: loyalty_pb.GetDashboardLoyaltyMainValuesRequest,
    metadata: grpcWeb.Metadata | null): Promise<loyalty_pb.GetDashboardLoyaltyMainValuesResponse>;

  getDashboardLoyaltyMainValues(
    request: loyalty_pb.GetDashboardLoyaltyMainValuesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyMainValuesResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.GetDashboardLoyaltyMainValuesResponse>;

  getDashboardLoyaltyMainValues(
    request: loyalty_pb.GetDashboardLoyaltyMainValuesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyMainValuesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardLoyaltyService/GetDashboardLoyaltyMainValues',
        request,
        metadata || {},
        this.methodDescriptorGetDashboardLoyaltyMainValues,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardLoyaltyService/GetDashboardLoyaltyMainValues',
    request,
    metadata || {},
    this.methodDescriptorGetDashboardLoyaltyMainValues);
  }

  methodDescriptorGetDashboardLoyaltyMainDifference = new grpcWeb.MethodDescriptor(
    '/proto.DashboardLoyaltyService/GetDashboardLoyaltyMainDifference',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.GetDashboardLoyaltyMainDifferenceRequest,
    loyalty_pb.GetDashboardLoyaltyMainDifferenceResponse,
    (request: loyalty_pb.GetDashboardLoyaltyMainDifferenceRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.GetDashboardLoyaltyMainDifferenceResponse.deserializeBinary
  );

  getDashboardLoyaltyMainDifference(
    request: loyalty_pb.GetDashboardLoyaltyMainDifferenceRequest,
    metadata: grpcWeb.Metadata | null): Promise<loyalty_pb.GetDashboardLoyaltyMainDifferenceResponse>;

  getDashboardLoyaltyMainDifference(
    request: loyalty_pb.GetDashboardLoyaltyMainDifferenceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyMainDifferenceResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.GetDashboardLoyaltyMainDifferenceResponse>;

  getDashboardLoyaltyMainDifference(
    request: loyalty_pb.GetDashboardLoyaltyMainDifferenceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyMainDifferenceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardLoyaltyService/GetDashboardLoyaltyMainDifference',
        request,
        metadata || {},
        this.methodDescriptorGetDashboardLoyaltyMainDifference,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardLoyaltyService/GetDashboardLoyaltyMainDifference',
    request,
    metadata || {},
    this.methodDescriptorGetDashboardLoyaltyMainDifference);
  }

  methodDescriptorListDashboardLoyaltyRatingByProject = new grpcWeb.MethodDescriptor(
    '/proto.DashboardLoyaltyService/ListDashboardLoyaltyRatingByProject',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.ListDashboardLoyaltyRatingByProjectRequest,
    loyalty_pb.ListDashboardLoyaltyRatingByProjectResponse,
    (request: loyalty_pb.ListDashboardLoyaltyRatingByProjectRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.ListDashboardLoyaltyRatingByProjectResponse.deserializeBinary
  );

  listDashboardLoyaltyRatingByProject(
    request: loyalty_pb.ListDashboardLoyaltyRatingByProjectRequest,
    metadata: grpcWeb.Metadata | null): Promise<loyalty_pb.ListDashboardLoyaltyRatingByProjectResponse>;

  listDashboardLoyaltyRatingByProject(
    request: loyalty_pb.ListDashboardLoyaltyRatingByProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.ListDashboardLoyaltyRatingByProjectResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.ListDashboardLoyaltyRatingByProjectResponse>;

  listDashboardLoyaltyRatingByProject(
    request: loyalty_pb.ListDashboardLoyaltyRatingByProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.ListDashboardLoyaltyRatingByProjectResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardLoyaltyService/ListDashboardLoyaltyRatingByProject',
        request,
        metadata || {},
        this.methodDescriptorListDashboardLoyaltyRatingByProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardLoyaltyService/ListDashboardLoyaltyRatingByProject',
    request,
    metadata || {},
    this.methodDescriptorListDashboardLoyaltyRatingByProject);
  }

  methodDescriptorGetDashboardLoyaltyRatingChart = new grpcWeb.MethodDescriptor(
    '/proto.DashboardLoyaltyService/GetDashboardLoyaltyRatingChart',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.GetDashboardLoyaltyRatingChartRequest,
    loyalty_pb.GetDashboardLoyaltyRatingChartResponse,
    (request: loyalty_pb.GetDashboardLoyaltyRatingChartRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.GetDashboardLoyaltyRatingChartResponse.deserializeBinary
  );

  getDashboardLoyaltyRatingChart(
    request: loyalty_pb.GetDashboardLoyaltyRatingChartRequest,
    metadata: grpcWeb.Metadata | null): Promise<loyalty_pb.GetDashboardLoyaltyRatingChartResponse>;

  getDashboardLoyaltyRatingChart(
    request: loyalty_pb.GetDashboardLoyaltyRatingChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyRatingChartResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.GetDashboardLoyaltyRatingChartResponse>;

  getDashboardLoyaltyRatingChart(
    request: loyalty_pb.GetDashboardLoyaltyRatingChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyRatingChartResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardLoyaltyService/GetDashboardLoyaltyRatingChart',
        request,
        metadata || {},
        this.methodDescriptorGetDashboardLoyaltyRatingChart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardLoyaltyService/GetDashboardLoyaltyRatingChart',
    request,
    metadata || {},
    this.methodDescriptorGetDashboardLoyaltyRatingChart);
  }

  methodDescriptorGetDashboardLoyaltyAverageVotesChart = new grpcWeb.MethodDescriptor(
    '/proto.DashboardLoyaltyService/GetDashboardLoyaltyAverageVotesChart',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.GetDashboardLoyaltyAverageVotesChartRequest,
    loyalty_pb.GetDashboardLoyaltyAverageVotesChartResponse,
    (request: loyalty_pb.GetDashboardLoyaltyAverageVotesChartRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.GetDashboardLoyaltyAverageVotesChartResponse.deserializeBinary
  );

  getDashboardLoyaltyAverageVotesChart(
    request: loyalty_pb.GetDashboardLoyaltyAverageVotesChartRequest,
    metadata: grpcWeb.Metadata | null): Promise<loyalty_pb.GetDashboardLoyaltyAverageVotesChartResponse>;

  getDashboardLoyaltyAverageVotesChart(
    request: loyalty_pb.GetDashboardLoyaltyAverageVotesChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyAverageVotesChartResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.GetDashboardLoyaltyAverageVotesChartResponse>;

  getDashboardLoyaltyAverageVotesChart(
    request: loyalty_pb.GetDashboardLoyaltyAverageVotesChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyAverageVotesChartResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardLoyaltyService/GetDashboardLoyaltyAverageVotesChart',
        request,
        metadata || {},
        this.methodDescriptorGetDashboardLoyaltyAverageVotesChart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardLoyaltyService/GetDashboardLoyaltyAverageVotesChart',
    request,
    metadata || {},
    this.methodDescriptorGetDashboardLoyaltyAverageVotesChart);
  }

  methodDescriptorGetDashboardLoyaltyUniqVotesChart = new grpcWeb.MethodDescriptor(
    '/proto.DashboardLoyaltyService/GetDashboardLoyaltyUniqVotesChart',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.GetDashboardLoyaltyUniqVotesChartRequest,
    loyalty_pb.GetDashboardLoyaltyUniqVotesChartResponse,
    (request: loyalty_pb.GetDashboardLoyaltyUniqVotesChartRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.GetDashboardLoyaltyUniqVotesChartResponse.deserializeBinary
  );

  getDashboardLoyaltyUniqVotesChart(
    request: loyalty_pb.GetDashboardLoyaltyUniqVotesChartRequest,
    metadata: grpcWeb.Metadata | null): Promise<loyalty_pb.GetDashboardLoyaltyUniqVotesChartResponse>;

  getDashboardLoyaltyUniqVotesChart(
    request: loyalty_pb.GetDashboardLoyaltyUniqVotesChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyUniqVotesChartResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.GetDashboardLoyaltyUniqVotesChartResponse>;

  getDashboardLoyaltyUniqVotesChart(
    request: loyalty_pb.GetDashboardLoyaltyUniqVotesChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetDashboardLoyaltyUniqVotesChartResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardLoyaltyService/GetDashboardLoyaltyUniqVotesChart',
        request,
        metadata || {},
        this.methodDescriptorGetDashboardLoyaltyUniqVotesChart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardLoyaltyService/GetDashboardLoyaltyUniqVotesChart',
    request,
    metadata || {},
    this.methodDescriptorGetDashboardLoyaltyUniqVotesChart);
  }

}

