// source: answer_grp_form.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.proto.AnswerGrpForm', null, global);
goog.exportSymbol('proto.proto.AnswerGrpFormFilter', null, global);
goog.exportSymbol('proto.proto.AnswerGrpFormFilterItem', null, global);
goog.exportSymbol('proto.proto.AnswerGrpFormSort', null, global);
goog.exportSymbol('proto.proto.ListAnswerGrpFormRequest', null, global);
goog.exportSymbol('proto.proto.ListAnswerGrpFormResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerGrpForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpForm.displayName = 'proto.proto.AnswerGrpForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpFormFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerGrpFormFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpFormFilterItem.displayName = 'proto.proto.AnswerGrpFormFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpFormFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AnswerGrpFormFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.AnswerGrpFormFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpFormFilter.displayName = 'proto.proto.AnswerGrpFormFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpFormSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerGrpFormSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpFormSort.displayName = 'proto.proto.AnswerGrpFormSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerGrpFormRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListAnswerGrpFormRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerGrpFormRequest.displayName = 'proto.proto.ListAnswerGrpFormRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerGrpFormResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListAnswerGrpFormResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListAnswerGrpFormResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerGrpFormResponse.displayName = 'proto.proto.ListAnswerGrpFormResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpForm.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    pollGroupTitle: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pollTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    questionStitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    answerGrpText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    answerGrpSum: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpForm}
 */
proto.proto.AnswerGrpForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpForm;
  return proto.proto.AnswerGrpForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpForm}
 */
proto.proto.AnswerGrpForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPollGroupTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPollTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionStitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerGrpText(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpSum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPollGroupTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPollTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestionStitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAnswerGrpText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAnswerGrpSum();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string poll_group_title = 1;
 * @return {string}
 */
proto.proto.AnswerGrpForm.prototype.getPollGroupTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpForm} returns this
 */
proto.proto.AnswerGrpForm.prototype.setPollGroupTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string poll_title = 2;
 * @return {string}
 */
proto.proto.AnswerGrpForm.prototype.getPollTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpForm} returns this
 */
proto.proto.AnswerGrpForm.prototype.setPollTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string question_stitle = 3;
 * @return {string}
 */
proto.proto.AnswerGrpForm.prototype.getQuestionStitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpForm} returns this
 */
proto.proto.AnswerGrpForm.prototype.setQuestionStitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string answer_grp_text = 4;
 * @return {string}
 */
proto.proto.AnswerGrpForm.prototype.getAnswerGrpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpForm} returns this
 */
proto.proto.AnswerGrpForm.prototype.setAnswerGrpText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 answer_grp_sum = 5;
 * @return {number}
 */
proto.proto.AnswerGrpForm.prototype.getAnswerGrpSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpForm} returns this
 */
proto.proto.AnswerGrpForm.prototype.setAnswerGrpSum = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpFormFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpFormFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpFormFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpFormFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.AnswerGrpFormFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpFormFilterItem}
 */
proto.proto.AnswerGrpFormFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpFormFilterItem;
  return proto.proto.AnswerGrpFormFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpFormFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpFormFilterItem}
 */
proto.proto.AnswerGrpFormFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.AnswerGrpFormFilter;
      reader.readMessage(value,proto.proto.AnswerGrpFormFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpFormFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpFormFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpFormFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpFormFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.AnswerGrpFormFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.AnswerGrpFormFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpFormFilterItem} returns this
 */
proto.proto.AnswerGrpFormFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.AnswerGrpFormFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpFormFilterItem} returns this
 */
proto.proto.AnswerGrpFormFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.AnswerGrpFormFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpFormFilterItem} returns this
 */
proto.proto.AnswerGrpFormFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AnswerGrpFormFilter or = 4;
 * @return {?proto.proto.AnswerGrpFormFilter}
 */
proto.proto.AnswerGrpFormFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.AnswerGrpFormFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpFormFilter, 4));
};


/**
 * @param {?proto.proto.AnswerGrpFormFilter|undefined} value
 * @return {!proto.proto.AnswerGrpFormFilterItem} returns this
*/
proto.proto.AnswerGrpFormFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AnswerGrpFormFilterItem} returns this
 */
proto.proto.AnswerGrpFormFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AnswerGrpFormFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AnswerGrpFormFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpFormFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpFormFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpFormFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpFormFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AnswerGrpFormFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpFormFilter}
 */
proto.proto.AnswerGrpFormFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpFormFilter;
  return proto.proto.AnswerGrpFormFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpFormFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpFormFilter}
 */
proto.proto.AnswerGrpFormFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpFormFilterItem;
      reader.readMessage(value,proto.proto.AnswerGrpFormFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpFormFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpFormFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpFormFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpFormFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AnswerGrpFormFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnswerGrpFormFilterItem items = 1;
 * @return {!Array<!proto.proto.AnswerGrpFormFilterItem>}
 */
proto.proto.AnswerGrpFormFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AnswerGrpFormFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AnswerGrpFormFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.AnswerGrpFormFilterItem>} value
 * @return {!proto.proto.AnswerGrpFormFilter} returns this
*/
proto.proto.AnswerGrpFormFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AnswerGrpFormFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AnswerGrpFormFilterItem}
 */
proto.proto.AnswerGrpFormFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AnswerGrpFormFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AnswerGrpFormFilter} returns this
 */
proto.proto.AnswerGrpFormFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpFormSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpFormSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpFormSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpFormSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpFormSort}
 */
proto.proto.AnswerGrpFormSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpFormSort;
  return proto.proto.AnswerGrpFormSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpFormSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpFormSort}
 */
proto.proto.AnswerGrpFormSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpFormSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpFormSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpFormSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpFormSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.AnswerGrpFormSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpFormSort} returns this
 */
proto.proto.AnswerGrpFormSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.AnswerGrpFormSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpFormSort} returns this
 */
proto.proto.AnswerGrpFormSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerGrpFormRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerGrpFormRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerGrpFormRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpFormRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.AnswerGrpFormFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.AnswerGrpFormSort.toObject(includeInstance, f),
    questionId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerGrpFormRequest}
 */
proto.proto.ListAnswerGrpFormRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerGrpFormRequest;
  return proto.proto.ListAnswerGrpFormRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerGrpFormRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerGrpFormRequest}
 */
proto.proto.ListAnswerGrpFormRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpFormFilter;
      reader.readMessage(value,proto.proto.AnswerGrpFormFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.AnswerGrpFormSort;
      reader.readMessage(value,proto.proto.AnswerGrpFormSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuestionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerGrpFormRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerGrpFormRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerGrpFormRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpFormRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.AnswerGrpFormFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.AnswerGrpFormSort.serializeBinaryToWriter
    );
  }
  f = message.getQuestionId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional AnswerGrpFormFilter filter = 1;
 * @return {?proto.proto.AnswerGrpFormFilter}
 */
proto.proto.ListAnswerGrpFormRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.AnswerGrpFormFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpFormFilter, 1));
};


/**
 * @param {?proto.proto.AnswerGrpFormFilter|undefined} value
 * @return {!proto.proto.ListAnswerGrpFormRequest} returns this
*/
proto.proto.ListAnswerGrpFormRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpFormRequest} returns this
 */
proto.proto.ListAnswerGrpFormRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpFormRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AnswerGrpFormSort sort = 2;
 * @return {?proto.proto.AnswerGrpFormSort}
 */
proto.proto.ListAnswerGrpFormRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.AnswerGrpFormSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpFormSort, 2));
};


/**
 * @param {?proto.proto.AnswerGrpFormSort|undefined} value
 * @return {!proto.proto.ListAnswerGrpFormRequest} returns this
*/
proto.proto.ListAnswerGrpFormRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpFormRequest} returns this
 */
proto.proto.ListAnswerGrpFormRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpFormRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 question_id = 3;
 * @return {number}
 */
proto.proto.ListAnswerGrpFormRequest.prototype.getQuestionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListAnswerGrpFormRequest} returns this
 */
proto.proto.ListAnswerGrpFormRequest.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListAnswerGrpFormResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerGrpFormResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerGrpFormResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerGrpFormResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpFormResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AnswerGrpForm.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListAnswerGrpFormRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerGrpFormResponse}
 */
proto.proto.ListAnswerGrpFormResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerGrpFormResponse;
  return proto.proto.ListAnswerGrpFormResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerGrpFormResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerGrpFormResponse}
 */
proto.proto.ListAnswerGrpFormResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpForm;
      reader.readMessage(value,proto.proto.AnswerGrpForm.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListAnswerGrpFormRequest;
      reader.readMessage(value,proto.proto.ListAnswerGrpFormRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerGrpFormResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerGrpFormResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerGrpFormResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpFormResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AnswerGrpForm.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListAnswerGrpFormRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnswerGrpForm items = 1;
 * @return {!Array<!proto.proto.AnswerGrpForm>}
 */
proto.proto.ListAnswerGrpFormResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AnswerGrpForm>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AnswerGrpForm, 1));
};


/**
 * @param {!Array<!proto.proto.AnswerGrpForm>} value
 * @return {!proto.proto.ListAnswerGrpFormResponse} returns this
*/
proto.proto.ListAnswerGrpFormResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AnswerGrpForm=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AnswerGrpForm}
 */
proto.proto.ListAnswerGrpFormResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AnswerGrpForm, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListAnswerGrpFormResponse} returns this
 */
proto.proto.ListAnswerGrpFormResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListAnswerGrpFormRequest params = 2;
 * @return {?proto.proto.ListAnswerGrpFormRequest}
 */
proto.proto.ListAnswerGrpFormResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListAnswerGrpFormRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListAnswerGrpFormRequest, 2));
};


/**
 * @param {?proto.proto.ListAnswerGrpFormRequest|undefined} value
 * @return {!proto.proto.ListAnswerGrpFormResponse} returns this
*/
proto.proto.ListAnswerGrpFormResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpFormResponse} returns this
 */
proto.proto.ListAnswerGrpFormResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpFormResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
