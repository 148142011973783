
import store from '@/store'
import { defineComponent } from 'vue'
import ProjectAdd from './ProjectAdd.vue'
import ProjectEdit from './ProjectEdit.vue'
import ProjectDelete from './ProjectDelete.vue'
//import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'ProjectList',

  components: {
    ProjectAdd,
    ProjectEdit,
    ProjectDelete,
    //DataLoading
  },

  data() {
    return {
      columns: [
        {
          name: 'short_name',
          label: 'Краткое наименование',
          field: 'short_name',
          align: 'left',
        },
        {
          name: 'name',
          label: 'Полное наименование',
          field: 'name',
          align: 'left',
        },
        {
          name: 'action',
          label: 'Действие',
          field: 'id',
          align: 'center',
          headerStyle: 'width: 100px',
          style: 'width: 40px',
        },
      ] as any,
      projectPagination: {
        sortBy: 'project_name',
        descending: false,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 0,
      },
      rowsPerPageProjectOptions: [10, 25, 50, 100],
      filter: '',
      search: '',
      oldSearch: '',
      searchTimeout: setTimeout(() => {}, 500) as ReturnType<typeof setTimeout>,
      minSearchSymbol: 2,
      isActiveModal: false,
      isActiveModalAdd: false,
      isActiveModalEdit: false,
      isActiveModalDelete: false,
      selectedProjectId: null as any,
    }
  },

  computed: {
    isProjectsBusy() {
      return store.getters.getIsProjectsBusy
    },

    getProjectsItems() {
      const projectItems = store.getters.getProjectsItems
      let rows: any = []
      if (projectItems !== undefined) {
        for (let projectItem of projectItems) {
          rows.push({
            id: projectItem.getProjectId(),
            name: projectItem.getProjectName(),
            short_name: projectItem.getProjectShortName(),
          })
        }
      }
      return rows
    },

    userRole() {
      return store.getters.getUserAccess
    },
  },

  methods: {
    hideModal() {
      this.isActiveModal = false
      this.isActiveModalAdd = false
      this.isActiveModalEdit = false
      this.isActiveModalDelete = false
      this.selectedProjectId = null
    },

    addProject() {
      this.isActiveModalAdd = true
      console.log('addProject')
      this.isActiveModal = true
    },

    editProject() {
      this.isActiveModalEdit = true
      console.log('editProject')
      this.isActiveModal = true
    },

    deleteProject() {
      this.isActiveModalDelete = true
      console.log('deleteProject')
      this.isActiveModal = true
    },

    actionRow(method: any, id: any) {
      console.log('actionRow')
      console.log('method', method)
      console.log('id', id)
      this.selectedProjectId = id
      switch (method) {
        case 'edit':
          this.editProject()
          break
        case 'delete':
          this.deleteProject()
          break
        default:
          console.log('Неизвестный метод')
          console.log('method', method)
          console.log('id', id)
      }
    },

    afterAction() {
      console.log('afterAction')
      this.loadProjectItems(true)
      this.hideModal()
    },

    onRequestProject(props: any) {
      console.log('onRequestProject props', props)
      this.projectPagination = props.pagination
      this.loadProjectItems(false)
    },

    strMarkRedPlus(search: string, subject: string) {
      let escapeRegExp = function (str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
      }

      return subject.replace(
        new RegExp('(' + escapeRegExp(search) + ')', 'gi'),
        "<span style='color: red;'><b>$1</b></span>"
      )
    },

    initSearch() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      this.filter = ''
      this.search = ''
      this.oldSearch = ''
      this.searchTimeout = setTimeout(() => {}, 500)
    },

    onInputSearchControl(event: any) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      console.log('onInputSearchControl event', event)
      this.searchTimeout = setTimeout(() => {
        const searchStr: string = event.target.value

        if (searchStr.length >= this.minSearchSymbol) {
          // Устанавливаем строку поиска
          this.search = searchStr.toLowerCase()
          this.loadProjectItems(true)
        } else {
          this.clearSearch(this.oldSearch.length >= this.minSearchSymbol)
        }
        this.oldSearch = searchStr
      }, 500)
    },

    clearSearch(isReload = true) {
      this.search = ''
      if (isReload) {
        this.loadProjectItems(true)
      }
    },

    getFilters() {
      return (this.search.length > 0) ?
        [
          {
            field_name: 'project_id',
            operand: '>',
            value: 0,
            or: [
              {
                field_name: 'project_name',
                operand: 'ilike',
                value: this.search,
              },
              {
                field_name: 'project_short_name',
                operand: 'ilike',
                value: this.search,
              },
            ]
          },
        ] :
        []
    },

    loadProjectItems(isResetPagination: boolean) {
      if (isResetPagination) {
        this.projectPagination.page = 1
        this.projectPagination.rowsNumber = 0
      }
      store
        .dispatch('getProjectsItems', {
          filter: this.getFilters(),
          sort: {
            name: this.projectPagination.sortBy,
            exp: this.projectPagination.descending ? 'DESC' : 'ASC',
          },
          pagination: {
            page: this.projectPagination.page,
            limit: this.projectPagination.rowsPerPage,
            pages: 0,
            cnt: this.projectPagination.rowsNumber,
          },
        })
        .then(
          (resolve) => {
            console.log('getProjectsItems resolve', resolve)
            this.projectPagination.rowsNumber = resolve.pagination.getCnt()
          },
          (reject) => {
            console.log('getProjectsItems reject', reject)
          }
        )
    },

    loadData() {
      this.hideModal()
      this.initSearch()
      this.loadProjectItems(true)
    },
  },

  created() {
    this.loadData()
  },
})
