import IntersectionUsers from '../views/IntersectionUsers.vue'
import NumberUsers from '../views/NumberUsers.vue'
import SocialIndicators from '../views/SocialIndicators.vue'
import AudienceSegmentation from '../views/AudienceSegmentation.vue'

const routes = [
  {
    path: '/intersection-users',
    name: 'IntersectionUsers',
    component: IntersectionUsers,
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
        { text: 'Пересечения пользователей' },
      ],
    },
  },
  {
    path: '/number-users',
    name: 'NumberUsers',
    component: NumberUsers,
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
        { text: 'Количество пользователей' },
      ],
    },
  },
  {
    path: '/social-indicators',
    name: 'SocialIndicators',
    component: SocialIndicators,
    meta: {
        breadCrumb: [
          { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
          { text: 'Социально-демографические показатели' },
        ],
      },
  },
  {
    path: '/audience-segmentation',
    name: 'AudienceSegmentation',
    component: AudienceSegmentation,
    meta: {
        breadCrumb: [
          { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
          { text: 'Сегментирование аудитории' },
        ],
      },
  },
]

export default {
  routes,
}
