import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { ProjectIntersectionApi } from '../services/api/ProjectIntersectionApi'

const intersectionApi = new ProjectIntersectionApi()

class State {
    projectIntersection: any
    isProjectIntersectionBusy: boolean
    projectActivity: any
    isProjectActivityBusy: boolean
    projectRegistration: any
    isProjectRegistration: boolean
    intersectionModal: any
    isIntersectionModal: boolean
    activityModal: any
    isActivityModal: boolean
    intersectionPersonalModal: any
    isIntersectionPersonalModal: boolean
    intersectionPersonalActModal: any
    isIntersectionPersonalActModal: boolean
}

const getters = <GetterTree<State, any>>{
    getProjectIntersection(state) {
        return state.projectIntersection
    },

    isProjectIntersectionBusy(state) {
        return state.isProjectIntersectionBusy
    },

    getProjectActivity(state) {
        return state.projectActivity
    },

    isProjectActivityBusy(state) {
        return state.isProjectActivityBusy
    },

    getProjectRegistration(state) {
        return state.projectRegistration
    },

    isProjectRegistration(state) {
        return state.isProjectRegistration
    },

    getIntersectionModal(state) {
        return state.intersectionModal
    },

    isIntersectionModal(state) {
        return state.isIntersectionModal
    },

    getActivityModal(state) {
        return state.activityModal
    },

    isActivityModal(state) {
        return state.isActivityModal
    },

    getIntersectionPersonalModal(state) {
        return state.intersectionPersonalModal
    },

    isIntersectionPersonalModal(state) {
        return state.isIntersectionPersonalModal
    },

    getIntersectionPersonalActModal(state) {
        return state.intersectionPersonalActModal
    },

    isIntersectionPersonalActModal(state) {
        return state.isIntersectionPersonalActModal
    },
}

const mutations = <MutationTree<State>>{
    setProjectIntersection(state, payload) {
        state.projectIntersection = payload
    },

    setIsProjectIntersectionBusy(state, payload) {
        state.isProjectIntersectionBusy = payload
    },

    setProjectActivity(state, payload) {
        state.projectActivity = payload
    },

    setIsProjectActivityBusy(state, payload) {
        state.isProjectActivityBusy = payload
    },

    setProjectRegistration(state, payload) {
        state.projectRegistration = payload
    },

    setIsProjectRegistration(state, payload) {
        state.isProjectRegistration = payload
    },

    setIntersectionModal(state, payload) {
        state.intersectionModal = payload
    },

    setIsIntersectionModal(state, payload) {
        state.isIntersectionModal = payload
    },

    setActivityModal(state, payload) {
        state.activityModal = payload
    },

    setIsActivityModal(state, payload) {
        state.isActivityModal = payload
    },

    setIntersectionPersonalModal(state, payload) {
        state.intersectionPersonalModal = payload
    },

    setIsIntersectionPersonalModal(state, payload) {
        state.isIntersectionPersonalModal = payload
    },

    setIntersectionPersonalActModal(state, payload) {
        state.intersectionPersonalActModal = payload
    },

    setIsIntersectionPersonalActModal(state, payload) {
        state.isIntersectionPersonalActModal = payload
    },
}

const actions = <ActionTree<State, any>>{
  getIntersectionsProject({ commit }, data: any) {
    commit('setIsProjectIntersectionBusy', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []
    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}

    return new Promise((resolve, reject) => {
      intersectionApi
        .getIntersectionList(filter, sort, data)
        .then(
          (response: any) => {
            commit('setProjectIntersection', response)
            commit('setIsProjectIntersectionBusy', false)
            resolve(response)
          },
          (error) => {
            commit('setIsProjectIntersectionBusy', false)
            reject(error)
          }
        )
        .catch((err) => console.log('ERROR getIntersectionList', err))
    })
  },

  getProjectActivity({ commit }, data: any) {
    commit('setIsProjectActivityBusy', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []
    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}

    return new Promise((resolve, reject) => {
      intersectionApi.getActivityList(filter, sort, data).then(
        (response: any) => {
          commit('setProjectActivity', response)
          commit('setIsProjectActivityBusy', false)
          resolve(response)
        },
        (error) => {
          commit('setIsProjectActivityBusy', false)
          reject(error)
        }
      )
    })
  },

  getProjectRegistration({ commit }, data: any) {
    commit('setIsProjectRegistration', true)

    const filter: any =
        data !== undefined && data.filter !== undefined ? data.filter : []
    const sort: any =
        data !== undefined && data.sort !== undefined ? data.sort : {}

    return new Promise((resolve, reject) => {
      intersectionApi.getRegistrationList(filter, sort, data).then(
          (response: any) => {
            commit('setProjectRegistration', response)
            commit('setIsProjectRegistration', false)
            resolve(response)
          },
          (error) => {
            commit('setIsProjectRegistration', false)
            reject(error)
          }
      )
    })
  },

  getIntersectionModalList({ commit }, data: any) {
    commit('setIsIntersectionModal', true)

    return intersectionApi
        .getIntersectionModal(data.filter, data.pagination, data.data)
        .then((res: any) => {
          const items = res.getItemsList()
          commit('setIntersectionModal', items)
          commit('setIsIntersectionModal', false)

          console.log('INT MODAL RES', res)
          return res
        })
        .catch(() => {
          commit('setIsIntersectionModal', false)
        })
  },

    getIntersectionPersonalModalList({ commit }, data: any) {
        commit('setIsIntersectionPersonalModal', true)

        return intersectionApi
            .getPersonalIntersectionModal(data.filter, data.data)
            .then((res: any) => {
                const items = res.getItemsList()
                commit('setIntersectionPersonalModal', items)
                commit('setIsIntersectionPersonalModal', false)

                console.log('INT MODAL RES PERSONAL', res)
                return res
            })
            .catch(() => {
                commit('setIsIntersectionPersonalModal', false)
            })
    },

  getActivityModalList({ commit }, data: any) {
    commit('setIsActivityModal', true)

    return intersectionApi
        .getActivityModal(data.filter, data.pagination, data.data)
        .then((res: any) => {
          const items = res.getItemsList()
          commit('setActivityModal', items)
          commit('setIsActivityModal', false)

          console.log('ACTIVITY MODAL RES', res)
          return res
        })
        .catch(() => {
          commit('setIsActivityModal', false)
        })
  },

    getPersonalActivityModalList({ commit }, data: any) {
        commit('setIsIntersectionPersonalActModal', true)

        return intersectionApi
            .getPersonalActivityModal(data.filter, data.data)
            .then((res: any) => {
                const items = res.getItemsList()
                commit('setIntersectionPersonalActModal', items)
                commit('setIsIntersectionPersonalActModal', false)

                console.log('ACTIVITY MODAL PERSONAL RES', res)
                return res
            })
            .catch(() => {
                commit('setIsIntersectionPersonalActModal', false)
            })
    },

  getIntersectionModalExport({ commit }, data) {
    commit('setIsIntersectionModal', true)

    return intersectionApi
        .getIntersectionModal(data.filter, data.pagination, data.data)
        .then((res: any) => {
          const items = res.getItemsList()
          commit('setIntersectionModal', items)
          commit('setIsIntersectionModal', false)

          console.log('INT EXPORT RES', res)
          return res
        })
        .catch(() => {
          commit('setIsIntersectionModal', false)
        })
  },

  getActivityModalExport({ commit }, data) {
    commit('setIsActivityModal', true)

    return intersectionApi
        .getActivityModal(data.filter, data.pagination, data.data)
        .then((res: any) => {
          const items = res.getItemsList()
          commit('setActivityModal', items)
          commit('setIsActivityModal', false)

          console.log('ACT EXPORT RES', res)
          return res
        })
        .catch(() => {
          commit('setIsActivityModal', false)
        })
  },
}

const intersectionChartModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
export default intersectionChartModule
