import { GetterTree, MutationTree, ActionTree } from "vuex";
import { ScorecardValueAccountApi } from "../services/api/ScorecardValueAccountApi";
import storeBase from "@/store/mixins/base";
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
  isScorecardValueAccountBusy: boolean = false;
  scorecardValueAccountItems: unknown [];
  scorecardValueAccountMappedItems: unknown [];
  scorecardValueAccountItem: unknown;
}

// Создаем объект
const scorecardValueAccountApi = new ScorecardValueAccountApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

  isScorecardValueAccountBusy(state): boolean {
    return state.isScorecardValueAccountBusy;
  },

  getScorecardValueAccountItems(state): unknown[] {
    return state.scorecardValueAccountItems;
  },

  getScorecardValueAccountMappedItems(state): unknown[] {
    return state.scorecardValueAccountMappedItems;
  },

  getScorecardValueAccountItem(state): unknown {
    return state.scorecardValueAccountItem;
  }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

  setIsScorecardValueAccountBusy(state, payload: boolean) {
    state.isScorecardValueAccountBusy = payload
    if(payload){
      Loading.show()
    }else{
      Loading.hide()
    }
  },

  setScorecardValueAccountItems(state, payload: unknown[]) {
    state.scorecardValueAccountItems = payload
  },

  setScorecardValueAccountMappedItems(state, payload: unknown[]) {
    state.scorecardValueAccountMappedItems = payload
  },

  setScorecardValueAccountItem(state, payload: unknown) {
    state.scorecardValueAccountItem = payload
  }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

  // Устанавливаем элемент
  setScorecardValueAccountItem({ commit }, data: any) {
    commit('setScorecardValueAccountItem', data);
  },
  
  // Список элементов
  getScorecardValueAccountItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardValueAccountBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      scorecardValueAccountApi.getScorecardValueAccountItems(filter, sort, pagination)
        .then(
          (response:any) => {
            commit('setScorecardValueAccountItems', response);
            commit('setIsScorecardValueAccountBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setScorecardValueAccountItems', []);
            commit('setIsScorecardValueAccountBusy', false);
            reject(error);
          }
        );
    });
  },

  // Список элементов с маппингом
  getScorecardValueAccountMappedItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardValueAccountBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
    const header:any = data !== undefined && data.header !== undefined && Array.isArray(data.header) ? data.header : [];

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      scorecardValueAccountApi.getScorecardValueAccountItems(filter, sort, pagination)
        .then(
          (response:any) => {
            console.log('getScorecardValueAccountMappedItems before response', response)
            const mappedItems = storeBase.methods.mappedScorecardValueAccount(response, header)
            console.log('getScorecardValueAccountMappedItems mappedItems', mappedItems)
            commit('setScorecardValueAccountMappedItems', mappedItems);
            commit('setIsScorecardValueAccountBusy', false);
            resolve(mappedItems);
          },
          (error) => {
            commit('setScorecardValueAccountMappedItems', []);
            commit('setIsScorecardValueAccountBusy', false);
            reject(error);
          }
        );
    });
  },

  // Получить элемент
  getScorecardValueAccountItem({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardValueAccountBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];

    return new Promise((resolve, reject) => {

      // Получаем элемент
      scorecardValueAccountApi.getScorecardValueAccountItem(filter)
        .then(
          (response:any) => {
            commit('setScorecardValueAccountItem', response);
            commit('setIsScorecardValueAccountBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsScorecardValueAccountBusy', false);
            reject(error);
          }
        );
    });
  },

  // Создать элемент
  createScorecardValueAccountItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardValueAccountBusy', true);

    return new Promise((resolve, reject) => {

      // Создаем элемент
      scorecardValueAccountApi.createScorecardValueAccountItem(data)
        .then(
          (response) => {
            commit('setIsScorecardValueAccountBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsScorecardValueAccountBusy', false);
            reject(error);
          }
        );
    });
  },

  // Удалить элемент
  deleteScorecardValueAccountItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardValueAccountBusy', true);

    return new Promise((resolve, reject) => {

      // Удаляем элемент
      scorecardValueAccountApi.deleteScorecardValueAccountItem(data)
        .then(
          (response) => {
            commit('setIsScorecardValueAccountBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsScorecardValueAccountBusy', false);
            reject(error);
          }
        );
    });
  },

  // Расчет общего количества баллов
  calculateScorecardValueAccount({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardValueAccountBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];

    return new Promise((resolve, reject) => {

      // Удаляем элемент
      scorecardValueAccountApi.calculateScorecardValueAccount(filter)
        .then(
          (response) => {
            commit('setIsScorecardValueAccountBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsScorecardValueAccountBusy', false);
            reject(error);
          }
        );
    });
  }
};

// Создаем модуль
const ScorecardValueAccountModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default ScorecardValueAccountModule;
