import { Class } from '@/components/modules/polls/proto/pb/class_pb';
import { Words } from '@/components/modules/polls/proto/pb/words_pb';
import { Scorecard } from '@/components/modules/scoring/proto/pb/scorecard_pb';
import { ScorecardValueAccount } from '@/components/modules/scoring/proto/pb/scorecard_value_account_pb';
import {Tags} from "@/components/modules/polls/proto/pb/tags_pb";

export default {
  methods: {

    convertClassItemsToTree: function (items: Array<Class>) {
        return function (items, root: any) {
            const t = {} as any;
            items.forEach((classItem) => {
                const o = {
                    id: classItem.getClassId() as any,
                    parent_id: classItem.getClassParentId() as any,
                    idx: classItem.getClassIdx() as any,
                    name: classItem.getClassName(),
                    descr: classItem.getClassDescr(),
                    notes: classItem.getClassNotes(),
                    ratio: classItem.getClassRatio(),
                    group_name: classItem.getClassGroupName(),
                    color: classItem.getClassColor(),
                };

                if (!o.parent_id) {
                    delete o.parent_id
                }

                Object.assign(t[o.id] = t[o.id] || {}, o)
                t[o.parent_id] = t[o.parent_id] || {}
                t[o.parent_id].children = t[o.parent_id].children || []

                t[o.parent_id].children.push(t[o.id])

                t[o.parent_id].children.sort((a: any, b: any) => {
                    if (a.idx > b.idx) {
                        return 1;
                    } else if (a.idx < b.idx) {
                        return -1;
                    } else {
                        return a.name.localeCompare(b.name)
                    }
                })
            });
            return t[root] ? t[root].children : []
        }(items, undefined)
    },

    convertClassItemsToTreeForForm: function (items: Array<Class>, disabled: Array<number>) {
        return function (items, root: any) {
            const t = {} as any;
            items.forEach((classItem) => {
                const o = {
                    id: classItem.getClassId() as any,
                    parent_id: classItem.getClassParentId() as any,
                    idx: classItem.getClassIdx() as any,
                    name: classItem.getClassName(),
                    disabled: false
                };

                if (!o.parent_id) {
                    delete o.parent_id
                }

                o.disabled = -1 !== disabled.indexOf(o.id)

                Object.assign(t[o.id] = t[o.id] || {}, o)
                t[o.parent_id] = t[o.parent_id] || {}
                t[o.parent_id].children = t[o.parent_id].children || []

                t[o.parent_id].children.push(t[o.id])

                t[o.parent_id].children.sort((a: any, b: any) => {
                    if (a.idx > b.idx) {
                        return 1;
                    } else if (a.idx < b.idx) {
                        return -1;
                    } else {
                        return a.name.localeCompare(b.name)
                    }
                })
            });
            return t[root] ? t[root].children : []
        }(items, undefined)
    },

    convertWordsItemsToTree: function (items: Array<Words>) {
        return function (items, root: any) {
            const t = {} as any;
            items.forEach((wordsItem) => {
                const o = {
                    id: wordsItem.getWordId() as any,
                    parent_id: wordsItem.getWordParentId() as any,
                    text: wordsItem.getWordText(),
                    is_linked: wordsItem.getIsLinked()
                };

                if (o.id == o.parent_id) {
                    delete o.parent_id
                }

                Object.assign(t[o.id] = t[o.id] || {}, o)
                t[o.parent_id] = t[o.parent_id] || {}
                t[o.parent_id].children = t[o.parent_id].children || []

                t[o.parent_id].children.push(t[o.id])
                // Сортировка - вверху "Непривязанные слова", далее по алфавиту по убыванию
                t[o.parent_id].children.sort((a: any, b: any) => {
                    if (a.id == 0) {
                        if (b.id == 0) {
                            if (a.is_linked) {
                                return -1;
                            }
                            return 1;
                        } else {
                            return -1;
                        }
                    } else if (b.id == 0) {
                        if (a.id == 0) {
                            if (b.is_linked) {
                                return 1;
                            }
                            return -1;
                        } else {
                            return 1;
                        }                        
                    } else {
                        return a.text.localeCompare(b.text)
                    }
                })
            });
            return t[root] ? t[root].children : []
        }(items, undefined)
    },

    convertWordsItemsToTreeForForm: function (items: Array<Words>, disabled: Array<number>) {
        return function (items, root: any) {
            const t = {} as any;
            items.forEach((wordsItem) => {
                const o = {
                    id: wordsItem.getWordId() as any,
                    parent_id: wordsItem.getWordParentId() as any,
                    text: wordsItem.getWordText(),
                    is_linked: wordsItem.getIsLinked(),
                    disabled: false
                };

                o.disabled = -1 !== disabled.indexOf(o.id)

                if (o.id == o.parent_id) {
                    delete o.parent_id
                } else {
                    o.disabled = true
                }

                Object.assign(t[o.id] = t[o.id] || {}, o)
                t[o.parent_id] = t[o.parent_id] || {}
                t[o.parent_id].children = t[o.parent_id].children || []

                t[o.parent_id].children.push(t[o.id])
                // Сортировка - вверху "Нет", потом "Непривязанные слова", далее по алфавиту по убыванию
                t[o.parent_id].children.sort((a: any, b: any) => {
                    if (a.id == 0) {
                        if (b.id == 0) {
                            if (a.is_linked) {
                                return -1;
                            }
                            return 1;
                        } else {
                            return -1;
                        }
                    } else if (b.id == 0) {
                        if (a.id == 0) {
                            if (b.is_linked) {
                                return 1;
                            }
                            return -1;
                        } else {
                            return 1;
                        }                        
                    } else {
                        return a.text.localeCompare(b.text)
                    }
                })
            });
            return t[root] ? t[root].children : []
        }(items, undefined)
    },

    convertScorecardItemsToTree: function (items: Array<Scorecard>) {
        return function (items, root: any) {
            const t = {} as any;
            items.forEach((scorecardItem) => {
                const o = {
                    id: scorecardItem.getScorecardId() as any,
                    parent_id: scorecardItem.getScorecardParentId() as any,
                    idx: scorecardItem.getScorecardIdx() as any,
                    title: scorecardItem.getScorecardTitle(),
                    weight: +(scorecardItem.getScorecardWeight()).toFixed(2) // Округляем до 2-х знаков !!!
                };

                if (!o.parent_id) {
                    delete o.parent_id
                }

                Object.assign(t[o.id] = t[o.id] || {}, o)
                t[o.parent_id] = t[o.parent_id] || {}
                t[o.parent_id].children = t[o.parent_id].children || []

                t[o.parent_id].children.push(t[o.id])
            });
            return t[root] ? t[root].children : []
        }(items, undefined)
    },

    convertScorecardItemsToTreeForForm: function (items: Array<Scorecard>, disabled: Array<number>) {
        return function (items, root: any) {
            const t = {} as any;
            items.forEach((scorecardItem) => {
                const o = {
                    id: scorecardItem.getScorecardId() as any,
                    parent_id: scorecardItem.getScorecardParentId() as any,
                    idx: scorecardItem.getScorecardIdx() as any,
                    title: scorecardItem.getScorecardTitle(),
                    disabled: false
                };

                if (!o.parent_id) {
                    delete o.parent_id
                }

                o.disabled = -1 !== disabled.indexOf(o.id)

                Object.assign(t[o.id] = t[o.id] || {}, o)
                t[o.parent_id] = t[o.parent_id] || {}
                t[o.parent_id].children = t[o.parent_id].children || []

                t[o.parent_id].children.push(t[o.id])
            });
            return t[root] ? t[root].children : []
        }(items, undefined)
    },

    mappedScorecardValueAccount: function (items: Array<ScorecardValueAccount>, header: Array<number>) {
        console.log('mappedScorecardValueAccount');
        const packItems: any[] = items.map((fullItem: any) => {
            let value = fullItem.getScoreValueTitle();
            value = ('TRUE' === value) ? 'Да' : (('FALSE' === value) ? 'Нет' : value);
            return {
                guid: fullItem.getAccountGuid(),
                points: fullItem.getAccountPoints(),
                hId: fullItem.getScorecardId(),
                value: value,
            } as any;
        });
        packItems.sort((a: any, b: any) => a.guid.localeCompare(b.guid));

        const blankItem = {
            guid: '',
            points: 0
        } as any;

        for (const hId of header) {
            blankItem['field_' + hId.toString()] = ''
        }

        const uniqueItems: any[] = [];
        let curUniqueItem: any = null;
        let curGuid = '';

        packItems.forEach((pItem: any) => {
            if ((null !== curUniqueItem) && (curGuid !== pItem.guid)) {
                uniqueItems.push(JSON.parse(JSON.stringify(curUniqueItem))); // Обёртка из JSON.parse(JSON.stringify(...)) обязательна !!!
                curUniqueItem = null;
            }
            if (null === curUniqueItem) {
                curUniqueItem = Object.assign(blankItem);
                curGuid = pItem.guid;
                curUniqueItem.guid = pItem.guid;
                curUniqueItem.points = pItem.points;
                curUniqueItem['field_' + pItem.hId.toString()] = pItem.value;
            } else {
                curUniqueItem['field_' + pItem.hId.toString()] = pItem.value;
            }
        });

        if (null !== curUniqueItem) {
            uniqueItems.push(JSON.parse(JSON.stringify(curUniqueItem))); // Обёртка из JSON.parse(JSON.stringify(...)) обязательна !!!
            curUniqueItem = null;
        }

        return uniqueItems;
    },

      convertTagsToTree: function (items: Tags.AsObject[]) {
          return function (items, root: any) {
              const t = {} as any;
              items.forEach((tagItem) => {
                  const o = {
                      tagId: tagItem.tagId,
                      tagParentId: tagItem.tagParentId as any,
                      tagName: tagItem.tagName,
                      tagDescr: tagItem.tagDescr,
                      tagRatios: tagItem.metricsList,
                  };

                  if (!o.tagParentId) {
                      delete o.tagParentId
                  }

                  Object.assign(t[o.tagId] = t[o.tagId] || {}, o)
                  t[o.tagParentId] = t[o.tagParentId] || {}
                  t[o.tagParentId].children = t[o.tagParentId].children || []

                  t[o.tagParentId].children.push(t[o.tagId])

                  t[o.tagParentId].children.sort((a: any, b: any) => {
                      if (a.idx > b.idx) {
                          return 1;
                      } else if (a.idx < b.idx) {
                          return -1;
                      } else {
                          return a.tagName.localeCompare(b.tagName)
                      }
                  })
              });
              return t[root] ? t[root].children : []
          }(items, undefined)
      },

      convertQuestionsToTree: function (items: any) {
          return function (items, root: any) {
              const t = {} as any;
              items.forEach((question: any) => {
                  const o = {
                      question_id: question.getQuestionId(),
                      question_parent_id: question.getQuestionParentId(),
                      poll_id: question.getPollId(),
                      question_type_id: question.getQuestionTypeId(),
                      question_idx: question.getQuestionIdx(),
                      question_title: question.getQuestionTitle(),
                      question_stitle: question.getQuestionStitle(),
                      question_processed: question.getQuestionProcessed(),
                      answer_src_count: question.getAnswerSrcCount(),
                      answer_src_sum: question.getAnswerSrcSum(),
                      similarity: question.getSimilarity(),
                      has_childs: question.getHasChilds(),
                  };

                  if (o.question_parent_id === o.question_id) {
                      delete o.question_parent_id
                  }

                  Object.assign(t[o.question_id] = t[o.question_id] || {}, o)
                  t[o.question_parent_id] = t[o.question_parent_id] || {}
                  t[o.question_parent_id].children = t[o.question_parent_id].children || []

                  console.log(t)

                  t[o.question_parent_id].children.push(t[o.question_id])

                  t[o.question_parent_id].children.sort((a: any, b: any) => {
                      if (a.question_idx > b.question_idx) {
                          return 1;
                      } else if (a.question_idx < b.question_idx) {
                          return -1;
                      } else {
                          return a.question_stitle.localeCompare(b.question_stitle)
                      }
                  })
              });

              return t[root] ? t[root].children : []
          }(items, undefined)
      },
  }
}
