/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: user.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as user_pb from './user_pb';


export class UserServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListUser = new grpcWeb.MethodDescriptor(
    '/proto.UserService/ListUser',
    grpcWeb.MethodType.UNARY,
    user_pb.ListUserRequest,
    user_pb.ListUserResponse,
    (request: user_pb.ListUserRequest) => {
      return request.serializeBinary();
    },
    user_pb.ListUserResponse.deserializeBinary
  );

  listUser(
    request: user_pb.ListUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_pb.ListUserResponse>;

  listUser(
    request: user_pb.ListUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_pb.ListUserResponse) => void): grpcWeb.ClientReadableStream<user_pb.ListUserResponse>;

  listUser(
    request: user_pb.ListUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_pb.ListUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/ListUser',
        request,
        metadata || {},
        this.methodDescriptorListUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/ListUser',
    request,
    metadata || {},
    this.methodDescriptorListUser);
  }

  methodDescriptorCreateUser = new grpcWeb.MethodDescriptor(
    '/proto.UserService/CreateUser',
    grpcWeb.MethodType.UNARY,
    user_pb.CreateUserRequest,
    user_pb.CreateUserResponse,
    (request: user_pb.CreateUserRequest) => {
      return request.serializeBinary();
    },
    user_pb.CreateUserResponse.deserializeBinary
  );

  createUser(
    request: user_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_pb.CreateUserResponse>;

  createUser(
    request: user_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_pb.CreateUserResponse) => void): grpcWeb.ClientReadableStream<user_pb.CreateUserResponse>;

  createUser(
    request: user_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_pb.CreateUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/CreateUser',
        request,
        metadata || {},
        this.methodDescriptorCreateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/CreateUser',
    request,
    metadata || {},
    this.methodDescriptorCreateUser);
  }

  methodDescriptorUpdateUser = new grpcWeb.MethodDescriptor(
    '/proto.UserService/UpdateUser',
    grpcWeb.MethodType.UNARY,
    user_pb.UpdateUserRequest,
    user_pb.UpdateUserResponse,
    (request: user_pb.UpdateUserRequest) => {
      return request.serializeBinary();
    },
    user_pb.UpdateUserResponse.deserializeBinary
  );

  updateUser(
    request: user_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_pb.UpdateUserResponse>;

  updateUser(
    request: user_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_pb.UpdateUserResponse) => void): grpcWeb.ClientReadableStream<user_pb.UpdateUserResponse>;

  updateUser(
    request: user_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_pb.UpdateUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/UpdateUser',
        request,
        metadata || {},
        this.methodDescriptorUpdateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/UpdateUser',
    request,
    metadata || {},
    this.methodDescriptorUpdateUser);
  }

  methodDescriptorDeleteUser = new grpcWeb.MethodDescriptor(
    '/proto.UserService/DeleteUser',
    grpcWeb.MethodType.UNARY,
    user_pb.DeleteUserRequest,
    user_pb.DeleteUserResponse,
    (request: user_pb.DeleteUserRequest) => {
      return request.serializeBinary();
    },
    user_pb.DeleteUserResponse.deserializeBinary
  );

  deleteUser(
    request: user_pb.DeleteUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_pb.DeleteUserResponse>;

  deleteUser(
    request: user_pb.DeleteUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_pb.DeleteUserResponse) => void): grpcWeb.ClientReadableStream<user_pb.DeleteUserResponse>;

  deleteUser(
    request: user_pb.DeleteUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_pb.DeleteUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/DeleteUser',
        request,
        metadata || {},
        this.methodDescriptorDeleteUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/DeleteUser',
    request,
    metadata || {},
    this.methodDescriptorDeleteUser);
  }

  methodDescriptorListUserRoles = new grpcWeb.MethodDescriptor(
    '/proto.UserService/ListUserRoles',
    grpcWeb.MethodType.UNARY,
    user_pb.ListUserRolesRequest,
    user_pb.ListUserRolesResponse,
    (request: user_pb.ListUserRolesRequest) => {
      return request.serializeBinary();
    },
    user_pb.ListUserRolesResponse.deserializeBinary
  );

  listUserRoles(
    request: user_pb.ListUserRolesRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_pb.ListUserRolesResponse>;

  listUserRoles(
    request: user_pb.ListUserRolesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_pb.ListUserRolesResponse) => void): grpcWeb.ClientReadableStream<user_pb.ListUserRolesResponse>;

  listUserRoles(
    request: user_pb.ListUserRolesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_pb.ListUserRolesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/ListUserRoles',
        request,
        metadata || {},
        this.methodDescriptorListUserRoles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/ListUserRoles',
    request,
    metadata || {},
    this.methodDescriptorListUserRoles);
  }

  methodDescriptorResendRegister = new grpcWeb.MethodDescriptor(
    '/proto.UserService/ResendRegister',
    grpcWeb.MethodType.UNARY,
    user_pb.ResendRegisterRequest,
    user_pb.ResendRegisterResponse,
    (request: user_pb.ResendRegisterRequest) => {
      return request.serializeBinary();
    },
    user_pb.ResendRegisterResponse.deserializeBinary
  );

  resendRegister(
    request: user_pb.ResendRegisterRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_pb.ResendRegisterResponse>;

  resendRegister(
    request: user_pb.ResendRegisterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_pb.ResendRegisterResponse) => void): grpcWeb.ClientReadableStream<user_pb.ResendRegisterResponse>;

  resendRegister(
    request: user_pb.ResendRegisterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_pb.ResendRegisterResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/ResendRegister',
        request,
        metadata || {},
        this.methodDescriptorResendRegister,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/ResendRegister',
    request,
    metadata || {},
    this.methodDescriptorResendRegister);
  }

  methodDescriptorCheckActivatedToken = new grpcWeb.MethodDescriptor(
    '/proto.UserService/CheckActivatedToken',
    grpcWeb.MethodType.UNARY,
    user_pb.CheckActivatedTokenRequest,
    user_pb.CheckActivatedTokenResponse,
    (request: user_pb.CheckActivatedTokenRequest) => {
      return request.serializeBinary();
    },
    user_pb.CheckActivatedTokenResponse.deserializeBinary
  );

  checkActivatedToken(
    request: user_pb.CheckActivatedTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_pb.CheckActivatedTokenResponse>;

  checkActivatedToken(
    request: user_pb.CheckActivatedTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_pb.CheckActivatedTokenResponse) => void): grpcWeb.ClientReadableStream<user_pb.CheckActivatedTokenResponse>;

  checkActivatedToken(
    request: user_pb.CheckActivatedTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_pb.CheckActivatedTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/CheckActivatedToken',
        request,
        metadata || {},
        this.methodDescriptorCheckActivatedToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/CheckActivatedToken',
    request,
    metadata || {},
    this.methodDescriptorCheckActivatedToken);
  }

  methodDescriptorRegisterConfirm = new grpcWeb.MethodDescriptor(
    '/proto.UserService/RegisterConfirm',
    grpcWeb.MethodType.UNARY,
    user_pb.RegisterConfirmRequest,
    user_pb.RegisterConfirmResponse,
    (request: user_pb.RegisterConfirmRequest) => {
      return request.serializeBinary();
    },
    user_pb.RegisterConfirmResponse.deserializeBinary
  );

  registerConfirm(
    request: user_pb.RegisterConfirmRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_pb.RegisterConfirmResponse>;

  registerConfirm(
    request: user_pb.RegisterConfirmRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_pb.RegisterConfirmResponse) => void): grpcWeb.ClientReadableStream<user_pb.RegisterConfirmResponse>;

  registerConfirm(
    request: user_pb.RegisterConfirmRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_pb.RegisterConfirmResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/RegisterConfirm',
        request,
        metadata || {},
        this.methodDescriptorRegisterConfirm,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/RegisterConfirm',
    request,
    metadata || {},
    this.methodDescriptorRegisterConfirm);
  }

  methodDescriptorAccess = new grpcWeb.MethodDescriptor(
    '/proto.UserService/Access',
    grpcWeb.MethodType.UNARY,
    user_pb.AccessRequest,
    user_pb.AccessResponse,
    (request: user_pb.AccessRequest) => {
      return request.serializeBinary();
    },
    user_pb.AccessResponse.deserializeBinary
  );

  access(
    request: user_pb.AccessRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_pb.AccessResponse>;

  access(
    request: user_pb.AccessRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_pb.AccessResponse) => void): grpcWeb.ClientReadableStream<user_pb.AccessResponse>;

  access(
    request: user_pb.AccessRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_pb.AccessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/Access',
        request,
        metadata || {},
        this.methodDescriptorAccess,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/Access',
    request,
    metadata || {},
    this.methodDescriptorAccess);
  }

}

