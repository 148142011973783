import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';
import type { QNotifyOptions } from "quasar"

type AlertFn = () => void
type Alerts = {
  [key: string]: AlertFn
}
type AlertOptions = QNotifyOptions
type Chainable<T = {}> = {
  create<K extends string, V extends AlertOptions>(key: K extends keyof T ? never : K, value: V)
    : Chainable<Omit<T, K> & { [P in K]: AlertFn }>
  end(): {
    [P in keyof T]: T[P]
  }
}






/**
 * Fabric for creating a chainable alerts with methods for displaying various types of alerts.
 * Each method is named with a key that was passed to "create" method and displays an alert with options that were passed with argument.
 * @param {Object} [alerts={}] - An object for initialization of a chain.
 * @returns {Object} - A chainable object with methods for displaying alerts.
 * @example
 * const { positive, negative, info } = useAlertFabric({})
 *                                      .create('positive',{type: 'positive', message: 'Positive message'})
 *                                      .create('negative',{type: 'negative', message: 'Negative message'})
 *                                      .create('info',{type: 'info', message: 'Info message'})
 *                                      .end()
 */
export const useAlertFabric = <T extends Alerts>(alerts: T = {} as T): Chainable<T> => {
  const { notify } = useQuasar()

  return {
    create: (key, options) => {
      return useAlertFabric({
        ...alerts,
        [key]: () => { notify(options) }
      });
    },
    end: () => alerts,
  }
}

type AlertOptionsDefault = Omit<QNotifyOptions, 'message' | 'type'>
type AlertType = 'positive' | 'negative' | 'warning' | 'info' | 'ongoing' | string
export const useAlert = (defaultOptions?: AlertOptionsDefault) => {
  const { notify } = useQuasar()

  return {
    Alert: (message: string, type: AlertType, options?: AlertOptionsDefault) => {
      notify({
        message,
        type,
        ...(options ?? defaultOptions ?? {})
      })
    }
  }
}
