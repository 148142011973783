
import {defineComponent, toRaw} from 'vue'
import store from "@/store";
import RegistrationProjects from "@/components/modules/intersection/components/Registration/RegistrationProjects.vue";

export default defineComponent({
    name: "RegistrationChart",
    components: {RegistrationProjects},

    data() {
        return {
            chartOptions: {
                chart: {
                    type: 'bar'
                },
                legend: {
                  enabled: false
                },
                title: {
                    text: 'Пересечение пользователей от 100 тыс.'
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Количество пользователей',
                    },
                },
                xAxis: {
                  categories: []
                },
                series: [{
                    name:'Пересечение',
                    data:[]
                }]
            },

            chartOptions2: {
                chart: {
                    type: 'bar',
                    height: 400
                },
                legend: {
                    enabled: false
                },
                title: {
                    text: 'Пересечение пользователей до 100 тыс.'
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Количество пользователей',
                    },
                },
                xAxis: {
                    categories: []
                },
                series: [{
                    name:'Пересечение',
                    data:[]
                }]
            },

            association: {
              1: 'АГ',
              2: 'ГЗ',
              3: 'ГИ',
              4: 'ЭД',
              5: 'НГ',
              6: 'МИЛП',
              17: 'АГД'
            } as any,

            selectedNews: [] as any,
            chartListLength: 0
        }
    },

    computed: {
        registrationList() {
            const rawList = store.getters.getProjectRegistration
            const filteredList = []

            for (let i in rawList) {
                // let projectArr = JSON.parse(rawList[i].getProjectYes()).map(
                //     (item: number) => this.association[item]
                // )

                if (rawList[i].getAccountCount() >= 100000) {
                    filteredList.push(rawList[i].getAccountCount())
                }
            }

            // filteredList.sort((a, b) => b - a)

            return filteredList
        },

        categoriesList() {
            const rawList = store.getters.getProjectRegistration
            const filteredList = []

            for (let i in rawList) {
                let projectArr = JSON.parse(rawList[i].getProjectYes()).map(
                    (item: number) => this.association[item]
                )

                if (rawList[i].getAccountCount() >= 100000) {
                    filteredList.push(projectArr)
                }
            }

            // filteredList.sort((a, b) => b - a)

            return filteredList
        },

        registrationList2() {
            const rawList = store.getters.getProjectRegistration
          console.log('rawlist', rawList)
            const filteredList = []

            for (let i in rawList) {
                // let projectArr = JSON.parse(rawList[i].getProjectYes()).map(
                //     (item: number) => this.association[item]
                // )

                if (rawList[i].getAccountCount() < 100000) {
                    filteredList.push(rawList[i].getAccountCount())
                }
            }

            // filteredList.sort((a, b) => b - a)

            return filteredList
        },

        categoriesList2() {
            const rawList = store.getters.getProjectRegistration
            const filteredList = []

            for (let i in rawList) {
                let projectArr = JSON.parse(rawList[i].getProjectYes()).map(
                    (item: number) => this.association[item]
                )

                if (rawList[i].getAccountCount() < 100000) {
                    filteredList.push(projectArr)
                }
            }

            // filteredList.sort((a, b) => b - a)

            return filteredList
        },

        isRegistrationList() {
            return store.getters.isProjectRegistration
        },

        selectedProjects() {
            return store.getters.getSelectedRegistrations
        }
    },

    watch: {
        registrationList(newValue) {
            console.log('РЕГИСТР НОВЫЙ', newValue)

            this.chartOptions.series[0].data = newValue
        },

        categoriesList(newValue) {
            this.chartOptions.xAxis.categories = newValue
        },

        registrationList2(newValue) {
            console.log('РЕГИСТР НОВЫЙ', newValue)

            this.chartOptions2.series[0].data = newValue
            this.chartListLength = newValue.length
            console.log('CHECK LENGTH FROM WATCHER', newValue.length)
            newValue.length > 20 ? this.chartOptions2.chart.height = 800 : this.chartOptions2.chart.height = 400
        },

        categoriesList2(newValue) {
            this.chartOptions2.xAxis.categories = newValue
        },

        selectedProjects(newValue) {
            this.selectedNews = newValue
        },
    },

    props: {
      dateMin: String,
      dateMax: String,
      filters: [] as any,
    },

    methods: {
      applyRegistration() {
        console.log('selected alra', this.selectedNews)

          store.dispatch('getProjectRegistration', {
              filter: {},
              sort: {},
              activity: toRaw(this.filters['Активность']),
              loyalty: toRaw(this.filters['Лояльность']),
              useful: toRaw(this.filters['Полезность']),
              interest: toRaw(this.filters['Заинтересованность']),
              project_ids: this.selectedNews?.length !== 0 ? this.selectedNews : [1, 2, 3, 4, 5, 6, 17],
              date_begin: this.dateMin,
              date_end: this.dateMax,
          })
              .then((res) => {
                console.log('myres', res)
              })
      }
    }
})
