/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: class_tag.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as class_tag_pb from './class_tag_pb';


export class ClassTagServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateClassTag = new grpcWeb.MethodDescriptor(
    '/proto.ClassTagService/CreateClassTag',
    grpcWeb.MethodType.UNARY,
    class_tag_pb.CreateClassTagRequest,
    class_tag_pb.CreateClassTagResponse,
    (request: class_tag_pb.CreateClassTagRequest) => {
      return request.serializeBinary();
    },
    class_tag_pb.CreateClassTagResponse.deserializeBinary
  );

  createClassTag(
    request: class_tag_pb.CreateClassTagRequest,
    metadata: grpcWeb.Metadata | null): Promise<class_tag_pb.CreateClassTagResponse>;

  createClassTag(
    request: class_tag_pb.CreateClassTagRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: class_tag_pb.CreateClassTagResponse) => void): grpcWeb.ClientReadableStream<class_tag_pb.CreateClassTagResponse>;

  createClassTag(
    request: class_tag_pb.CreateClassTagRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: class_tag_pb.CreateClassTagResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ClassTagService/CreateClassTag',
        request,
        metadata || {},
        this.methodDescriptorCreateClassTag,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ClassTagService/CreateClassTag',
    request,
    metadata || {},
    this.methodDescriptorCreateClassTag);
  }

  methodDescriptorUpdateClassTag = new grpcWeb.MethodDescriptor(
    '/proto.ClassTagService/UpdateClassTag',
    grpcWeb.MethodType.UNARY,
    class_tag_pb.UpdateClassTagRequest,
    class_tag_pb.UpdateClassTagResponse,
    (request: class_tag_pb.UpdateClassTagRequest) => {
      return request.serializeBinary();
    },
    class_tag_pb.UpdateClassTagResponse.deserializeBinary
  );

  updateClassTag(
    request: class_tag_pb.UpdateClassTagRequest,
    metadata: grpcWeb.Metadata | null): Promise<class_tag_pb.UpdateClassTagResponse>;

  updateClassTag(
    request: class_tag_pb.UpdateClassTagRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: class_tag_pb.UpdateClassTagResponse) => void): grpcWeb.ClientReadableStream<class_tag_pb.UpdateClassTagResponse>;

  updateClassTag(
    request: class_tag_pb.UpdateClassTagRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: class_tag_pb.UpdateClassTagResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ClassTagService/UpdateClassTag',
        request,
        metadata || {},
        this.methodDescriptorUpdateClassTag,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ClassTagService/UpdateClassTag',
    request,
    metadata || {},
    this.methodDescriptorUpdateClassTag);
  }

  methodDescriptorDeleteClassTag = new grpcWeb.MethodDescriptor(
    '/proto.ClassTagService/DeleteClassTag',
    grpcWeb.MethodType.UNARY,
    class_tag_pb.DeleteClassTagRequest,
    class_tag_pb.DeleteClassTagResponse,
    (request: class_tag_pb.DeleteClassTagRequest) => {
      return request.serializeBinary();
    },
    class_tag_pb.DeleteClassTagResponse.deserializeBinary
  );

  deleteClassTag(
    request: class_tag_pb.DeleteClassTagRequest,
    metadata: grpcWeb.Metadata | null): Promise<class_tag_pb.DeleteClassTagResponse>;

  deleteClassTag(
    request: class_tag_pb.DeleteClassTagRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: class_tag_pb.DeleteClassTagResponse) => void): grpcWeb.ClientReadableStream<class_tag_pb.DeleteClassTagResponse>;

  deleteClassTag(
    request: class_tag_pb.DeleteClassTagRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: class_tag_pb.DeleteClassTagResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ClassTagService/DeleteClassTag',
        request,
        metadata || {},
        this.methodDescriptorDeleteClassTag,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ClassTagService/DeleteClassTag',
    request,
    metadata || {},
    this.methodDescriptorDeleteClassTag);
  }

  methodDescriptorListClassTag = new grpcWeb.MethodDescriptor(
    '/proto.ClassTagService/ListClassTag',
    grpcWeb.MethodType.UNARY,
    class_tag_pb.ListClassTagRequest,
    class_tag_pb.ListClassTagResponse,
    (request: class_tag_pb.ListClassTagRequest) => {
      return request.serializeBinary();
    },
    class_tag_pb.ListClassTagResponse.deserializeBinary
  );

  listClassTag(
    request: class_tag_pb.ListClassTagRequest,
    metadata: grpcWeb.Metadata | null): Promise<class_tag_pb.ListClassTagResponse>;

  listClassTag(
    request: class_tag_pb.ListClassTagRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: class_tag_pb.ListClassTagResponse) => void): grpcWeb.ClientReadableStream<class_tag_pb.ListClassTagResponse>;

  listClassTag(
    request: class_tag_pb.ListClassTagRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: class_tag_pb.ListClassTagResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ClassTagService/ListClassTag',
        request,
        metadata || {},
        this.methodDescriptorListClassTag,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ClassTagService/ListClassTag',
    request,
    metadata || {},
    this.methodDescriptorListClassTag);
  }

  methodDescriptorSaveClassTag = new grpcWeb.MethodDescriptor(
    '/proto.ClassTagService/SaveClassTag',
    grpcWeb.MethodType.UNARY,
    class_tag_pb.SaveClassTagRequest,
    class_tag_pb.SaveClassTagResponse,
    (request: class_tag_pb.SaveClassTagRequest) => {
      return request.serializeBinary();
    },
    class_tag_pb.SaveClassTagResponse.deserializeBinary
  );

  saveClassTag(
    request: class_tag_pb.SaveClassTagRequest,
    metadata: grpcWeb.Metadata | null): Promise<class_tag_pb.SaveClassTagResponse>;

  saveClassTag(
    request: class_tag_pb.SaveClassTagRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: class_tag_pb.SaveClassTagResponse) => void): grpcWeb.ClientReadableStream<class_tag_pb.SaveClassTagResponse>;

  saveClassTag(
    request: class_tag_pb.SaveClassTagRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: class_tag_pb.SaveClassTagResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ClassTagService/SaveClassTag',
        request,
        metadata || {},
        this.methodDescriptorSaveClassTag,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ClassTagService/SaveClassTag',
    request,
    metadata || {},
    this.methodDescriptorSaveClassTag);
  }

}

