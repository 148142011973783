
import store from '@/store'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'ProjectDelete',

  components: {
    DataLoading,
  },

  props: {
    projectId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      item: {
        id: 0,
        name: '',
        short_name: '',
      },
    }
  },

  computed: {
    isProjectsBusy() {
      return store.getters.getIsProjectsBusy
    },
  },

  methods: {
    actualDelete() {
      store.dispatch('deleteProjectsItem', { id: this.projectId }).then(
        (resolve) => {
          console.log('resolve', resolve)
          this.$emit('afterAction')
        },
        (reject) => {
          console.log('reject', reject)
        }
      )
    },

    closeProjectDelete() {
      this.$emit('hideModal')
    },

    loadData() {
      store
        .dispatch('getProjectsItem', {
          filter: [
            {
              field_name: 'project_id',
              operand: '=',
              value: this.projectId,
            },
          ],
        })
        .then(
          (resolve) => {
            console.log('loadData resolve', resolve)
            this.item = {
              id: resolve.getProjectId(),
              name: resolve.getProjectName(),
              short_name: resolve.getProjectShortName(),
            }
          },
          (reject) => {
            console.log('loadData reject', reject)
          }
        )
    },
  },

  created() {
    this.loadData()
  },
})
