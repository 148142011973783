
// import store from '@/store'
import { defineComponent } from 'vue'
import ActivityPercentageChart from '@/components/charts/ActivityPercentageChart.vue'
import TopActivitiesChart from '@/components/charts/TopActivitiesChart.vue'
import UsersProjectsChart from '@/components/charts/UsersProjectsChart.vue'

export default defineComponent({
  name: 'NumberUsers',

  components: {
    ActivityPercentageChart,
    TopActivitiesChart,
    UsersProjectsChart,
  },

  data() {
    return {
      // tab: 'percentage',
      // enableTopActivityChart: false,
    }
  },

  // computed: {
  //   getCurrentProject() {
  //     return store.getters.getCurrentProject
  //   },
  // },

  // watch: {
  //   getCurrentProject() {
  //     this.enableTopActivityChart = !!this.getCurrentProject
  //   },
  // },
})
