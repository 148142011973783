import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { MessageSrcApi } from '../services/api/MessageSrcApi';
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
    isMessageSrcBusy: boolean = false;
    isMessageSrcSourceBusy: boolean = false;
    isExportMessageSrcSourceItems: boolean = false;
    messageSrcItems: unknown [];
    messageSrcSourceItems: unknown [];
    exportMessageSrcSourceItems: unknown[];
    messageSrcItem: unknown;
    messageSrcSourceItem: unknown;
}

// Создаем объект
const messageSrcApi = new MessageSrcApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

    isMessageSrcBusy(state): boolean {
        return state.isMessageSrcBusy;
    },

    isMessageSrcSourceBusy(state): boolean {
        return state.isMessageSrcSourceBusy;
    },

    getMessageSrcItems(state): unknown[] {
        return state.messageSrcItems;
    },

    getMessageSrcSourceItems(state): unknown[] {
        return state.messageSrcSourceItems;
    },

    getExportMessageSrcSourceItems(state): unknown[] {
        return state.exportMessageSrcSourceItems;
    },

    getMessageSrcItem(state): unknown {
        return state.messageSrcItem;
    },

    getMessageSrcSourceItem(state): unknown {
        return state.messageSrcSourceItem;
    },

    getIsExportMessageSrcSourceItems(state): boolean {
        return state.isExportMessageSrcSourceItems;
    },
};

// Создаем мутации
const mutations = <MutationTree<State>>{

    setIsMessageSrcBusy(state, payload: boolean) {
        state.isMessageSrcBusy = payload
    },

    setIsMessageSrcSourceBusy(state, payload: boolean) {
        state.isMessageSrcSourceBusy = payload
    },

    setMessageSrcItems(state, payload: unknown[]) {
        state.messageSrcItems = payload
    },

    setMessageSrcSourceItems(state, payload: unknown[]) {
        state.messageSrcSourceItems = payload
    },

    setMessageSrcItem(state, payload: unknown) {
        state.messageSrcItem = payload
    },

    setMessageSrcSourceItem(state, payload: unknown) {
        state.messageSrcSourceItem = payload
    },

    setExportMessageSrcSourceItems(state, payload: unknown[]) {
        state.exportMessageSrcSourceItems = payload
    },

    setIsExportMessageSrcSourceItems(state, payload: boolean) {
        state.isExportMessageSrcSourceItems = payload
    }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

    // Устанавливаем элемент
    setMessageSrcItem({ commit }, data: any) {
        commit('setMessageSrcItem', data);
    },

    // Устанавливаем элемент source
    setMessageSrcSourceItem({ commit }, data: any) {
        commit('setMessageSrcSourceItem', data);
    },

    // Список элементов
    getMessageSrcItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsMessageSrcBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            messageSrcApi.getMessageSrcItems(filter, sort, pagination)
                .then(
                    (message:any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setMessageSrcItems', items);
                        commit('setIsMessageSrcBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setMessageSrcItems', []);
                        commit('setIsMessageSrcBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Список элементов для модалки
    getMessageSrcSourceItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsMessageSrcSourceBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const message_grp_id:any = data !== undefined && data.message_grp_id !== undefined ? data.message_grp_id : 0;
        const is_full:any = data !== undefined && data.is_full !== undefined ? data.is_full : false;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            messageSrcApi.getMessageSrcSourceItems(filter, sort, pagination, message_grp_id, is_full)
                .then(
                    (message:any) => {
                        console.log('getMessageSrcSourceItems message', message)
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setMessageSrcSourceItems', items);
                        commit('setIsMessageSrcSourceBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setMessageSrcSourceItems', []);
                        commit('setIsMessageSrcSourceBusy', false);
                        reject(error);
                    }
                );
        });
    },

    getPersonalMessageSrcSourceItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsMessageSrcSourceBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const message_grp_id:any = data !== undefined && data.message_grp_id !== undefined ? data.message_grp_id : 0;
        const is_full:any = data !== undefined && data.is_full !== undefined ? data.is_full : false;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            messageSrcApi.getPersonalMessageSrcSourceItems(filter, message_grp_id, is_full)
                .then(
                    (message:any) => {
                        commit('setIsMessageSrcSourceBusy', false);

                        resolve(message);
                    },
                    (error) => {
                        commit('setIsMessageSrcSourceBusy', false);

                        reject(error);
                    }
                );
        });
    },

    // Список элементов для модалки
    getExportMessageSrcSourceItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsExportMessageSrcSourceItems', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const message_grp_id:any = data !== undefined && data.message_grp_id !== undefined ? data.message_grp_id : 0;
        const is_full:any = data !== undefined && data.is_full !== undefined ? data.is_full : false;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            messageSrcApi.getMessageSrcSourceItems(filter, sort, pagination, message_grp_id, is_full)
                .then(
                    (message:any) => {
                        console.log('getExportMessageSrcSourceItems message', message)
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setExportMessageSrcSourceItems', items);
                        commit('setIsExportMessageSrcSourceItems', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setExportMessageSrcSourceItems', []);
                        commit('setIsExportMessageSrcSourceItems', false);
                        reject(error);
                    }
                );
        });
    }
};

// Создаем модуль
const MessageSrcModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default MessageSrcModule;
