import {
  WordsServiceClient
} from "../../proto/pb/WordsServiceClientPb";
import {
  Words, 
  ListWordsRequest,
  //ItemWordsRequest,
  WordsFilter,
  WordsFilterItem,
  WordsSort,
  WordsPagination,
  CreateWordsRequest,  
  UpdateWordsRequest, 
  DeleteWordsRequest
} from "../../proto/pb/words_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 1000000;

export class WordsApi {
  items: Array<Words> = [];
  client: WordsServiceClient;
  metadata: any;

  constructor() {
    const host:string = (window as any).VUE_APP_API_URL ?? '';
    this.client = new WordsServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  // Установка и получение фильтров
  createFilter(data: any){

    const filter = new WordsFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
        for (const i in data) {
            if (data[i].field_name && ('' !== data[i].field_name)) {
                const fItem = new WordsFilterItem();
                fItem.setFieldName(data[i].field_name.toString());
                fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                filter.addItems(fItem);
            }
        }
    }

    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any){

    const sort = new WordsSort();
    const name:string = data.name !== undefined ? data.name.toString() : 'word_text';
    const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any){

    const pagination = new WordsPagination();
    const page:number = data.page !== undefined ? Number(data.page) : 1;
    const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages:number = data.pages !== undefined ? Number(data.pages) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  // Список элементов
  getWordsItems(filter: any, sort: any, pagination: any){

    const request = new ListWordsRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));

    return this.client.listWords(request, this.metadata)
        .then((message) => {
          return message.getItemsList()
        })
  }
/*
  // Получение элемента
  getWordsItem(filter: any){

    const request = new ItemWordsRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));

    return new Promise((resolve, reject) => {
      this.client.itemWords(request, this.metadata, (err, message) => {
        if (err) {
          reject(err)
        } else {
          resolve(message.getItem());
        }
      });
    });
  }
*/
  // Создание элемента
  createWordsItem(data: any){

    const request = new CreateWordsRequest();
    request.setWordParentId(data.parent_id);
    request.setWordText(data.text);
    request.setIsLinked(data.is_linked)

    return this.client.createWords(request, this.metadata);
  }

  // Редактирование элемента
  updateWordsItem(data: any){

    const request = new UpdateWordsRequest();
    request.setWordId(data.id);
    request.setWordParentId(data.parent_id);
    request.setIsLinked(data.is_linked)

    return this.client.updateWords(request, this.metadata);
  }

  // Удаление элемента
  deleteWordsItem(data: any){

    const request = new DeleteWordsRequest();
    request.setWordId(data.id);

    return this.client.deleteWords(request, this.metadata);
  }
}
