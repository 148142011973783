
import { defineComponent } from "vue";
import store from "@/store";

export default defineComponent({
  name: "AddonList",

  data() {
    return {
      addonList: [
        {
          title: "Системные пользователи",
          url: "users/manage",
          access: "admin",
        },
        {
          title: "НСИ",
          url: "nsi",
          access: "admin editor user",
        },
        {
          title: "Сообщения пользователей",
          url: "cls",
          access: "admin editor user",
        },
        {
          title: "Справка",
          url: "reference",
          access: "admin editor user",
        },
        {
          title: "Отчеты",
          url: "reports",
          access: "admin editor user",
        },
        {
          title: "Присвоение тегов",
          url: "tags",
          access: "admin editor user",
        },
        {
          title: 'Конструктор запросов',
          url: 'sql-builder',
          access: 'admin'
        },
        {
          title: 'Рекомендательная система',
          url: 'recommend-system',
          access: 'admin editor user'
        },
        // {
        //   title: 'Конструктор ручных заданий',
        //   url: 'task-builder',
        //   access: 'admin'
        // },
        // {
        //     title: 'Оценка пользователей',
        //     url: 'estimation',
        // },
        // {
        //   title: "Просмотр профиля выбранного пользователя",
        //   url: "aehr",
        // },
        // {
        //   title: "Прогнозирование аудитории",
        //   url: "dky",
        // },
        // {
        //   title: "Оповещение аудитории",
        //   url: "sh",
        // },
      ],
    };
  },

  computed: {
    userRole() {
      return store.getters.getUserAccess;
    },
  },
});
