/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: message_src.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as message_src_pb from './message_src_pb';


export class MessageSrcServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListMessageSrc = new grpcWeb.MethodDescriptor(
    '/proto.MessageSrcService/ListMessageSrc',
    grpcWeb.MethodType.UNARY,
    message_src_pb.ListMessageSrcRequest,
    message_src_pb.ListMessageSrcResponse,
    (request: message_src_pb.ListMessageSrcRequest) => {
      return request.serializeBinary();
    },
    message_src_pb.ListMessageSrcResponse.deserializeBinary
  );

  listMessageSrc(
    request: message_src_pb.ListMessageSrcRequest,
    metadata: grpcWeb.Metadata | null): Promise<message_src_pb.ListMessageSrcResponse>;

  listMessageSrc(
    request: message_src_pb.ListMessageSrcRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_src_pb.ListMessageSrcResponse) => void): grpcWeb.ClientReadableStream<message_src_pb.ListMessageSrcResponse>;

  listMessageSrc(
    request: message_src_pb.ListMessageSrcRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_src_pb.ListMessageSrcResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageSrcService/ListMessageSrc',
        request,
        metadata || {},
        this.methodDescriptorListMessageSrc,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageSrcService/ListMessageSrc',
    request,
    metadata || {},
    this.methodDescriptorListMessageSrc);
  }

  methodDescriptorListMessageSrcSource = new grpcWeb.MethodDescriptor(
    '/proto.MessageSrcService/ListMessageSrcSource',
    grpcWeb.MethodType.UNARY,
    message_src_pb.ListMessageSrcSourceRequest,
    message_src_pb.ListMessageSrcSourceResponse,
    (request: message_src_pb.ListMessageSrcSourceRequest) => {
      return request.serializeBinary();
    },
    message_src_pb.ListMessageSrcSourceResponse.deserializeBinary
  );

  listMessageSrcSource(
    request: message_src_pb.ListMessageSrcSourceRequest,
    metadata: grpcWeb.Metadata | null): Promise<message_src_pb.ListMessageSrcSourceResponse>;

  listMessageSrcSource(
    request: message_src_pb.ListMessageSrcSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_src_pb.ListMessageSrcSourceResponse) => void): grpcWeb.ClientReadableStream<message_src_pb.ListMessageSrcSourceResponse>;

  listMessageSrcSource(
    request: message_src_pb.ListMessageSrcSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_src_pb.ListMessageSrcSourceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageSrcService/ListMessageSrcSource',
        request,
        metadata || {},
        this.methodDescriptorListMessageSrcSource,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageSrcService/ListMessageSrcSource',
    request,
    metadata || {},
    this.methodDescriptorListMessageSrcSource);
  }

  methodDescriptorPersonalListMessageSrcSource = new grpcWeb.MethodDescriptor(
    '/proto.MessageSrcService/PersonalListMessageSrcSource',
    grpcWeb.MethodType.UNARY,
    message_src_pb.PersonalListMessageSrcSourceRequest,
    message_src_pb.PersonalListMessageSrcSourceResponse,
    (request: message_src_pb.PersonalListMessageSrcSourceRequest) => {
      return request.serializeBinary();
    },
    message_src_pb.PersonalListMessageSrcSourceResponse.deserializeBinary
  );

  personalListMessageSrcSource(
    request: message_src_pb.PersonalListMessageSrcSourceRequest,
    metadata: grpcWeb.Metadata | null): Promise<message_src_pb.PersonalListMessageSrcSourceResponse>;

  personalListMessageSrcSource(
    request: message_src_pb.PersonalListMessageSrcSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_src_pb.PersonalListMessageSrcSourceResponse) => void): grpcWeb.ClientReadableStream<message_src_pb.PersonalListMessageSrcSourceResponse>;

  personalListMessageSrcSource(
    request: message_src_pb.PersonalListMessageSrcSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_src_pb.PersonalListMessageSrcSourceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageSrcService/PersonalListMessageSrcSource',
        request,
        metadata || {},
        this.methodDescriptorPersonalListMessageSrcSource,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageSrcService/PersonalListMessageSrcSource',
    request,
    metadata || {},
    this.methodDescriptorPersonalListMessageSrcSource);
  }

}

