import {
  ClassTagServiceClient
} from "../../proto/pb/Class_tagServiceClientPb";
import {
  ClassTag, 
  ListClassTagRequest,
  //ItemClassTagRequest,
  ClassTagFilter,
  ClassTagFilterItem,
  ClassTagSort,
  ClassTagPagination,
  CreateClassTagRequest,  
  UpdateClassTagRequest, 
  DeleteClassTagRequest, 
  SaveClassTagRequest
} from "../../proto/pb/class_tag_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 100;

export class ClassTagApi {
  items: Array<ClassTag> = [];
  client: ClassTagServiceClient;
  metadata: any;

  constructor() {
    const host:string = (window as any).VUE_APP_API_URL ?? '';
    this.client = new ClassTagServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  // Установка и получение фильтров
  createFilter(data: any){

    const filter = new ClassTagFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
        for (const i in data) {
            if (data[i].field_name && ('' !== data[i].field_name)) {
                const fItem = new ClassTagFilterItem();
                fItem.setFieldName(data[i].field_name.toString());
                fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                filter.addItems(fItem);
            }
        }
    }

    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any){

    const sort = new ClassTagSort();
    const name:string = data.name !== undefined ? data.name.toString() : 'tag_name';
    const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any){

    const pagination = new ClassTagPagination();
    const page:number = data.page !== undefined ? Number(data.page) : 1;
    const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages:number = data.pages !== undefined ? Number(data.pages) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  // Список элементов
  getClassTagItems(filter: any, sort: any, pagination: any){

    const request = new ListClassTagRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));

    return this.client.listClassTag(request, this.metadata)
        .then((message) => {
            return message.getItemsList()
        })
  }
/*
  // Получение элемента
  getClassTagItem(filter: any){

    const request = new ItemClassTagRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));

    return new Promise((resolve, reject) => {
      this.client.itemClassTag(request, this.metadata, (err, message) => {
        if (err) {
          reject(err)
        } else {
          resolve(message.getItem());
        }
      });
    });
  }
*/
  // Создание элемента
  createClassTagItem(data: any){

    const request = new CreateClassTagRequest();
    request.setClassId(data.class_id);
    request.setTagName(data.name);
    request.setTagDescr(data.descr);

    return this.client.createClassTag(request, this.metadata);
  }

  // Редактирование элемента
  updateClassTagItem(data: any){

    const request = new UpdateClassTagRequest();
    request.setClassId(data.class_id);
    request.setTagName(data.name);
    request.setTagDescr(data.descr);

    return this.client.updateClassTag(request, this.metadata);
  }

  // Удаление элемента
  deleteClassTagItem(data: any){

    const request = new DeleteClassTagRequest();
    request.setClassId(data.class_id);
    request.setTagName(data.name);

    return this.client.deleteClassTag(request, this.metadata);
  }

  // Сохранение нескольких элементов
  saveClassTag(data: any){

    const request = new SaveClassTagRequest();
    const tagItems: Array<ClassTag> = [];

    request.setClassId(data.class_id);

    if (data.tags && (data.tags.length > 0)) {
        for (const i in data.tags) {
            const tagItem = new ClassTag();
            tagItem.setClassId(data.class_id);
            tagItem.setTagName(data.tags[i].name.toString());
            tagItem.setTagDescr(data.tags[i].descr.toString());
            tagItems.push(tagItem);
        }
    }

    request.setItemsList(tagItems);

    return this.client.saveClassTag(request, this.metadata);
  }
}
