import { GetterTree, MutationTree, ActionTree } from "vuex";
import { UsersApi } from "../services/api/UsersApi";
import Loading from 'quasar/src/plugins/loading/Loading.js';;

// import { setUser } from '@sentry/vue'


// Создаем объект
const usersApi = new UsersApi();

// Создаем состояние
class State {
  isUsersListBusy: boolean = false;
  usersListItems: unknown[];
  usersListItem: unknown;
  userRoles: unknown[];
  userRole: any;
  confirm: any;
  isResend: any;
  userData: any[];
  userId: number;
}

// Создаем геттеры
const getters = <GetterTree<State, any>>{
  isUsersListBusy(state): boolean {
    return state.isUsersListBusy;
  },

  getUsersListItems(state): unknown[] {
    return state.usersListItems;
  },

  getUsersListItem(state): unknown {
    return state.usersListItem;
  },

  getUserRoles(state): unknown[] {
    return state.userRoles;
  },

  getUserAccess(state) {
    return state.userRole;
  },

  getIsResend(state) {
    return state.isResend;
  },

  getUserId(state) {
    return state.userId;
  },

  getUserData(state) {
    return state.userData;
  },
};

// Создаем мутации
const mutations = <MutationTree<State>>{
  setIsUsersListBusy(state, payload: boolean) {
    state.isUsersListBusy = payload;
    if (payload) {
      Loading.show();
    } else {
      Loading.hide();
    }
  },

  setUsersListItems(state, payload: unknown[]) {
    state.usersListItems = payload;
  },

  setUsersListItem(state, payload: unknown) {
    state.usersListItem = payload;
  },

  setUserRoles(state, payload: unknown[]) {
    state.userRoles = payload;
  },

  setUserRole(state, payload) {
    state.userRole = payload;
  },

  setIsResend(state, payload) {
    state.isResend = payload;
  },

  setUserId(state, payload) {
    state.userId = payload;
  },

  setUserData(state, payload) {
    state.userData = payload;
    
    // setUser({ username: `${payload[1]} ${payload[0][0]}. ${payload[2][0]}.` })
  },
};

// Создаем действия
const actions = <ActionTree<State, any>>{
  // Устанавливаем элемент
  setUsersListItem({ commit }, data: any) {
    commit("setUsersListItem", data);
  },

  // Список элементов
  getUsersListItems({ commit }, data: any) {
    // Устанавливаем событие загрузки
    commit("setIsUsersListBusy", true);

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination: any =
      data !== undefined && data.pagination !== undefined
        ? data.pagination
        : {};

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      usersApi.getUsersListItems(filter, sort, pagination).then(
        (message: any) => {
          const items = message.getItemsList();
          const sort = message.getParams().getSort();
          const pagination = message.getParams().getPagination();
          commit("setUsersListItems", items);
          commit("setIsUsersListBusy", false);
          resolve({
            items: items,
            sort: sort,
            pagination: pagination,
          });
        },
        (error) => {
          commit("setUsersListItems", []);
          commit("setIsUsersListBusy", false);
          reject(error);
        }
      );
    });
  },

  // Создать элемент
  createUsersItem({ commit }, data) {
    // Устанавливаем событие загрузки
    commit("setIsUsersListBusy", true);

    return new Promise((resolve, reject) => {
      // Создаем элемент
      usersApi.createUsersItem(data).then(
        (response: any) => {
          commit("setIsUsersListBusy", false);
          resolve(response);
        },
        (error) => {
          commit("setIsUsersListBusy", false);
          reject(error);
        }
      );
    });
  },

  // Редактировать элемент
  updateUsersItem({ commit }, data) {
    // Устанавливаем событие загрузки
    commit("setIsUsersListBusy", true);

    return new Promise((resolve, reject) => {
      // Обновляем элемент
      usersApi.updateUsersItem(data).then(
        (response: any) => {
          commit("setIsUsersListBusy", false);
          resolve(response);
        },
        (error) => {
          commit("setIsUsersListBusy", false);
          reject(error);
        }
      );
    });
  },

  // Удалить элемент
  deleteUsersListItem({ commit }, data) {
    // Устанавливаем событие загрузки
    commit("setIsUsersListBusy", true);

    return new Promise((resolve, reject) => {
      // Удаляем элемент
      usersApi.deleteUsersItem(data).then(
        (response: any) => {
          commit("setIsUsersListBusy", false);
          resolve(response);
        },
        (error) => {
          commit("setIsUsersListBusy", false);
          reject(error);
        }
      );
    });
  },
  // Получить элемент
  getUsersListItem({ commit }, data: any) {
    // Устанавливаем событие загрузки
    commit("setIsUsersListBusy", true);

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : [];

    return new Promise((resolve, reject) => {
      // Получаем элемент
      usersApi.getUsersListItem(filter).then(
        (response: any) => {
          commit("setUsersListItem", response);
          commit("setIsUsersListBusy", false);
          resolve(response);
        },
        (error) => {
          commit("setIsUsersListBusy", false);
          reject(error);
        }
      );
    });
  },

  // Список ролей
  getUserRoles({ commit }) {
    // Устанавливаем событие загрузки
    commit("setIsUsersListBusy", true);

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      usersApi.getListUserRoles().then(
        (message: any) => {
          const items = message.getItemsList();
          commit("setUserRoles", items);
          commit("setIsUsersListBusy", false);
          resolve(items);
        },
        (error) => {
          commit("setUserRoles", []);
          commit("setIsUsersListBusy", false);
          reject(error);
        }
      );
    });
  },

  getUserAccess({ commit }) {
    return new Promise((resolve, reject) => {
      usersApi
        .checkUserRole()
        .then((res: any) => {
          commit("setUserId", res.getUserId());
          commit("setUserRole", res.getUserRole());
          commit("setUserData", [
            res.getFirstName(),
            res.getLastName(),
            res.getMiddleName(),
          ]);
          resolve(res.getUserRole());
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  },

  confirmRegistration({ commit }, data: any) {
    return new Promise((resolve, reject) => {
      usersApi
        .setupPassword(data)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  },

  resendMail({ commit }, data: any) {
    commit("setIsResend", true);

    return new Promise((resolve, reject) => {
      usersApi
        .resendMail(data)
        .then((res: any) => {
          commit("setIsResend", false);
          resolve(res);
        })
        .catch((err: any) => {
          commit("setIsResend", false);
          reject(err);
        });
    });
  },

  checkToken({ commit }, data: any) {
    return new Promise((resolve, reject) => {
      usersApi
        .checkToken(data)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  },
};

// Создаем модуль
const UserListModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default UserListModule;
