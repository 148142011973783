
import store from '@/store'
import { defineComponent } from 'vue'
import ProjectsChoice from '@/components/ProjectsChoice.vue'
import PeriodsChoice from '@/components/PeriodsChoice.vue'
import MetricsChoice from '@/components/MetricsChoice.vue'
import AudienceSegmentationChart from '@/components/charts/AudienceSegmentationChart.vue'
import SegmentationBarChart from '@/components/charts/SegmentationBarChart.vue'

export default defineComponent({
  name: 'AudienceSegmentation',

  components: {
    ProjectsChoice,
    PeriodsChoice,
    AudienceSegmentationChart,
    MetricsChoice,
    SegmentationBarChart,
  },

  data() {
    return {
      buildChart: false,
      keyIndicators: false,
    }
  },

  methods: {
    getChartData() {
      // this.buildChart = true
      this.getFilterMetricAccounts()
    },
    getFilterMetricAccounts() {
      let projectsIds = store.getters.getCheckedProjects
      let metricsIds = store.getters.getCheckedMetrics
      let periodIds = store.getters.getCheckedPeriods

      const data = {
        projectIds: projectsIds,
        periodIds: periodIds,
        fromMetricValueIds: metricsIds.from,
        toMetricValueIds: metricsIds.to,
        key: +this.keyIndicators,
      }
      store
        .dispatch('getIntersectionMetricAccountValueItems', data)
        .then(() => console.log('RESPONSE '))
        .catch((err) => console.log('CATCH ERROR', err))
    },
  },
})
