import ClsAnswers from '../views/answers/ClsAnswers.vue'
import ClsList from '../views//cls/ClsList.vue'
import ClassView from '../views/class/ClassView.vue'
import NsiDictionary from '../views/words/NsiDictionary.vue'
import WorkingAnswers from '../views/answers/WorkingAnswers.vue'
import WorkingGeneralAnswers from "../views/answers/WorkingGeneralAnswers.vue";
import ClsMessages from "../views/answers/ClsMessages.vue";
import LoadPolls from "../views/answers/LoadPolls.vue";
import TagsList from "../views/tag/TagsList.vue";
import VkGeneralizing from "../views/vk/generalizing/views/VkGeneralizing.vue";
import SocialNetworkLoad from "../views/vk/load/SocialNetworkLoad.vue";
import VkClassification from "../views/vk/classification/views/VkClassification.vue";

const routes = [
  {
    path: '/nsi/classifiers',
    name: 'ClsList',
    component: ClsList,
    meta: {
      breadCrumb: [
        { text: 'Главная', to: {name: 'HomePage'},icon:'home' },
        { text: 'НСИ', to: {name: 'NSI'} },
        { text: 'Классификаторы' },
      ],
    },
  },
  {
    path: '/cls/ag_polls/answers',
    name: 'ClsAnswers',
    component: ClsAnswers,
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
        { text: 'Сообщения пользователей', to: {name: 'RespProcess'} },
        { text: 'Опросы АГ', to: { name: 'RespAG' } },
        { text: 'Классификация' },
      ],
    },
  },
  {
    path: '/cls/ag_polls/load_polls',
    name: 'LoadPolls',
    component: LoadPolls,
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
        { text: 'Сообщения пользователей', to: {name: 'RespProcess'} },
        { text: 'Опросы АГ', to: { name: 'RespAG' } },
        { text: 'Загрузка (обновление) данных' },
      ],
      admin: true,
      editor: true,
    },
  },
  {
    path: '/nsi/classifiers/tree/:id',
    name: 'ClassView',
    component: ClassView,
    meta: {
      breadCrumb(route: any) {
        return [
          { text: 'Главная', to: {name:'HomePage'},icon:'home' },
          { text: 'НСИ', to: {name: 'NSI'} },
          {
            text: 'Классификаторы',
            to: {
              name: 'ClsList',
            },
          },
          { text: 'Содержимое классификатора' },
        ]
      },
    },
  },
  {
    path: '/nsi/dictionary',
    name: 'NsiDictionary',
    component: NsiDictionary,
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
        { text: 'НСИ', to: {name: 'NSI'} },
        { text: 'Словарь' },
      ],
    },
  },
  {
    path: '/nsi/tags',
    name: 'TagsList',
    component: TagsList,
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon:'home' },
        { text: 'НСИ', to: {name: 'NSI'} },
        { text: 'Теги' },
      ],
    },
  },
  {
    path: '/cls/ag_polls/generalizing',
    name: 'WorkingAnswers',
    component: WorkingAnswers,
    meta: {
        breadCrumb: [
          { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
          { text: 'Сообщения пользователей', to: {name: 'RespProcess'} },
          { text: 'Опросы АГ', to: { name: 'RespAG' } },
          { text: 'Обобщение' },
        ],
      },
  },
  {
    path: '/cls/general/generalizing',
    name: 'WorkingGeneralAnswers',
    component: WorkingGeneralAnswers,
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
        { text: 'Сообщения пользователей', to: {name: 'RespProcess'} },
        { text: 'Общие', to: { name: 'RespGeneral' } },
        { text: 'Обобщение' },
      ],
    },
  },
  {
    path: '/cls/general/answers',
    name: 'ClsMessages',
    component: ClsMessages,
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
        { text: 'Сообщения пользователей', to: {name: 'RespProcess'} },
        { text: 'Общие', to: { name: 'RespGeneral' } },
        { text: 'Классификация' },
      ],
    },
  },
  {
    path: '/cls/vk_messages/generalizing',
    name: 'VkGeneralizing',
    component: VkGeneralizing,
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
        { text: 'Сообщения пользователей', to: {name: 'RespProcess'} },
        { text: 'Сообщения ВК', to: { name: 'VkMsg' } },
        { text: 'Обобщение' },
      ],
    },
  },
  {
    path: '/cls/vk_messages/load_social_networks',
    name: 'SocialNetworkLoad',
    component: SocialNetworkLoad,
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
        { text: 'Сообщения пользователей', to: {name: 'RespProcess'} },
        { text: 'Сообщения ВК', to: { name: 'VkMsg' } },
        { text: 'Загрузка (обновление) данных' },
      ],
    },
  },
  {
    path: '/cls/vk_messages/classification',
    name: 'VkClassification',
    component: VkClassification,
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
        { text: 'Сообщения пользователей', to: {name: 'RespProcess'} },
        { text: 'Сообщения ВК', to: { name: 'VkMsg' } },
        { text: 'Классификация' },
      ],
    },
  },
  {
    path: '/tags',
    name: 'TagsPage',
    component: () => import('../views/tag/TagsPage.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Присвоение тегов' },
      ],
    },
  },
]

export default {
  routes,
}
