import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { SocialNetworkMessageSrcApi } from '../services/api/SocialNetworkMessageSrcApi';

class State {
    isSocialNetworkMessageSrcBusy: boolean = false;
    isSocialNetworkMessageSrcSourceBusy: boolean = false;
    isSocialNetworkExportMessageSrcSourceItems: boolean = false;
    socialNetworkMessageSrcItems: unknown [];
    socialNetworkMessageSrcSourceItems: unknown [];
    socialNetworkExportMessageSrcSourceItems: unknown[];
    socialNetworkMessageSrcItem: unknown;
    socialNetworkMessageSrcSourceItem: unknown;
}

// Создаем объект
const socialNetworkMessageSrcApi = new SocialNetworkMessageSrcApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

    isSocialNetworkMessageSrcBusy(state): boolean {
        return state.isSocialNetworkMessageSrcBusy;
    },

    isSocialNetworkMessageSrcSourceBusy(state): boolean {
        return state.isSocialNetworkMessageSrcSourceBusy;
    },

    getSocialNetworkMessageSrcItems(state): unknown[] {
        return state.socialNetworkMessageSrcItems;
    },

    getSocialNetworkMessageSrcSourceItems(state): unknown[] {
        return state.socialNetworkMessageSrcSourceItems;
    },

    getSocialNetworkExportMessageSrcSourceItems(state): unknown[] {
        return state.socialNetworkExportMessageSrcSourceItems;
    },

    getSocialNetworkMessageSrcItem(state): unknown {
        return state.socialNetworkMessageSrcItem;
    },

    getSocialNetworkMessageSrcSourceItem(state): unknown {
        return state.socialNetworkMessageSrcSourceItem;
    },

    getIsSocialNetworkExportMessageSrcSourceItems(state): boolean {
        return state.isSocialNetworkExportMessageSrcSourceItems;
    },
};

// Создаем мутации
const mutations = <MutationTree<State>>{

    setIsSocialNetworkMessageSrcBusy(state, payload: boolean) {
        state.isSocialNetworkMessageSrcBusy = payload
    },

    setIsSocialNetworkMessageSrcSourceBusy(state, payload: boolean) {
        state.isSocialNetworkMessageSrcSourceBusy = payload
    },

    setSocialNetworkMessageSrcItems(state, payload: unknown[]) {
        state.socialNetworkMessageSrcItems = payload
    },

    setSocialNetworkMessageSrcSourceItems(state, payload: unknown[]) {
        state.socialNetworkMessageSrcSourceItems = payload
    },

    setSocialNetworkMessageSrcItem(state, payload: unknown) {
        state.socialNetworkMessageSrcItem = payload
    },

    setSocialNetworkMessageSrcSourceItem(state, payload: unknown) {
        state.socialNetworkMessageSrcSourceItem = payload
    },

    setSocialNetworkExportMessageSrcSourceItems(state, payload: unknown[]) {
        state.socialNetworkExportMessageSrcSourceItems = payload
    },

    setIsSocialNetworkExportMessageSrcSourceItems(state, payload: boolean) {
        state.isSocialNetworkExportMessageSrcSourceItems = payload
    }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

    // Устанавливаем элемент
    setSocialNetworkMessageSrcItem({ commit }, data: any) {
        commit('setSocialNetworkMessageSrcItem', data);
    },

    // Устанавливаем элемент source
    setSocialNetworkMessageSrcSourceItem({ commit }, data: any) {
        commit('setSocialNetworkMessageSrcSourceItem', data);
    },

    // Список элементов
    getSocialNetworkMessageSrcItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageSrcBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            socialNetworkMessageSrcApi.getSocialNetworkMessageSrcItems(filter, sort, pagination)
                .then(
                    (message:any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setSocialNetworkMessageSrcItems', items);
                        commit('setIsSocialNetworkMessageSrcBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setSocialNetworkMessageSrcItems', []);
                        commit('setIsSocialNetworkMessageSrcBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Список элементов для модалки
    getSocialNetworkMessageSrcSourceItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageSrcSourceBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const message_grp_id:any = data !== undefined && data.message_grp_id !== undefined ? data.message_grp_id : 0;
        const is_full:any = data !== undefined && data.is_full !== undefined ? data.is_full : false;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            socialNetworkMessageSrcApi.getSocialNetworkMessageSrcSourceItems(filter, sort, pagination, message_grp_id, is_full)
                .then(
                    (message:any) => {
                        console.log('getSocialNetworkMessageSrcSourceItems message', message)
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setSocialNetworkMessageSrcSourceItems', items);
                        commit('setIsSocialNetworkMessageSrcSourceBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setSocialNetworkMessageSrcSourceItems', []);
                        commit('setIsSocialNetworkMessageSrcSourceBusy', false);
                        reject(error);
                    }
                );
        });
    },

    getSocialNetworkPersonalMessageSrcSourceItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageSrcSourceBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const message_grp_id:any = data !== undefined && data.message_grp_id !== undefined ? data.message_grp_id : 0;
        const is_full:any = data !== undefined && data.is_full !== undefined ? data.is_full : false;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            socialNetworkMessageSrcApi.getSocialNetworkPersonalMessageSrcSourceItems(filter, message_grp_id, is_full)
                .then(
                    (message:any) => {
                        commit('setIsSocialNetworkMessageSrcSourceBusy', false);

                        resolve(message);
                    },
                    (error) => {
                        commit('setIsSocialNetworkMessageSrcSourceBusy', false);

                        reject(error);
                    }
                );
        });
    },

    // Список элементов для модалки
    getSocialNetworkExportMessageSrcSourceItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkExportMessageSrcSourceItems', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const message_grp_id:any = data !== undefined && data.message_grp_id !== undefined ? data.message_grp_id : 0;
        const is_full:any = data !== undefined && data.is_full !== undefined ? data.is_full : false;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            socialNetworkMessageSrcApi.getSocialNetworkMessageSrcSourceItems(filter, sort, pagination, message_grp_id, is_full)
                .then(
                    (message:any) => {
                        console.log('getSocialNetworkExportMessageSrcSourceItems message', message)
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setSocialNetworkExportMessageSrcSourceItems', items);
                        commit('setIsSocialNetworkExportMessageSrcSourceItems', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setSocialNetworkExportMessageSrcSourceItems', []);
                        commit('setIsSocialNetworkExportMessageSrcSourceItems', false);
                        reject(error);
                    }
                );
        });
    }
};

// Создаем модуль
const SocialNetworkMessageSrcModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default SocialNetworkMessageSrcModule;
