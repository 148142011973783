import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { style: {"margin":"10px 10px 0 10px","height":"calc(100% - 50px)","overflow":"hidden"} }
const _hoisted_2 = {
  class: "row",
  style: {"height":"100%"}
}
const _hoisted_3 = { class: "col-6" }
const _hoisted_4 = {
  class: "col-12",
  style: {"height":"calc(100% - 110px)","overflow":"hidden"}
}
const _hoisted_5 = {
  style: {"width":"calc(100% - 10px)","height":"100%","margin-right":"10px","overflow":"auto","border":"1px solid black"},
  class: "custom-tree"
}
const _hoisted_6 = { class: "row items-center" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { style: {"width":"calc(100% - 10px)","height":"100%","margin-left":"10px","overflow":"auto","border":"1px solid black"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_tree = _resolveComponent("q-tree")!
  const _component_ClassPanel = _resolveComponent("ClassPanel")!
  const _component_q_splitter = _resolveComponent("q-splitter")!
  const _component_DataLoading = _resolveComponent("DataLoading")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_ClassAdd = _resolveComponent("ClassAdd")!
  const _component_ClassEdit = _resolveComponent("ClassEdit")!
  const _component_ClassDelete = _resolveComponent("ClassDelete")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { style: {"padding":"10px 10px 0 10px","height":"100%"} }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createTextVNode("НСИ - Классификатор «" + _toDisplayString(_ctx.classifier.name) + "»", 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_toolbar, { class: "q-gutter-sm" }, {
            default: _withCtx(() => [
              ('admin editor'.includes(_ctx.userRole))
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    color: "primary",
                    disable: _ctx.isClassTreeBusy,
                    label: "Добавить",
                    icon: "mdi-plus",
                    onClick: _ctx.addClass
                  }, null, 8, ["disable", "onClick"]))
                : _createCommentVNode("", true),
              ('admin editor'.includes(_ctx.userRole))
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 1,
                    color: "primary",
                    disable: _ctx.isClassTreeBusy || !_ctx.selectedNode,
                    label: "Редактировать",
                    icon: "mdi-pencil",
                    onClick: _ctx.editClass
                  }, null, 8, ["disable", "onClick"]))
                : _createCommentVNode("", true),
              ('admin editor'.includes(_ctx.userRole))
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 2,
                    color: "primary",
                    disable: _ctx.isClassTreeBusy || !_ctx.selectedNode,
                    label: "Удалить",
                    icon: "mdi-close",
                    onClick: _ctx.deleteClass
                  }, null, 8, ["disable", "onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_q_space),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_q_input, {
                  disable: _ctx.isClassTreeBusy,
                  modelValue: _ctx.filter,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
                  filled: "",
                  onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInputSearchControl($event)))
                }, {
                  prepend: _withCtx(() => [
                    _createVNode(_component_q_icon, { name: "mdi-magnify" })
                  ]),
                  _: 1
                }, 8, ["disable", "modelValue"])
              ])
            ]),
            _: 1
          }),
          _withDirectives(_createElementVNode("div", _hoisted_4, [
            _createVNode(_component_q_splitter, {
              modelValue: _ctx.splitterSize,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.splitterSize) = $event)),
              style: {"width":"100%","height":"100%","overflow":"hidden"}
            }, {
              before: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_q_tree, {
                    ref: "classTree",
                    nodes: _ctx.getClassTreeItems,
                    "node-key": "id",
                    "label-key": "name",
                    filter: _ctx.search,
                    selected: _ctx.selectedNode,
                    "onUpdate:selected": [
                      _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedNode) = $event)),
                      _ctx.selectNode
                    ]
                  }, {
                    "default-header": _withCtx((prop) => [
                      _createElementVNode("div", _hoisted_6, [
                        (_ctx.search.length > 0)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              innerHTML: _ctx.strMarkRedPlus(_ctx.search, prop.node.name)
                            }, null, 8, _hoisted_7))
                          : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(prop.node.name), 1))
                      ])
                    ]),
                    _: 1
                  }, 8, ["nodes", "filter", "selected", "onUpdate:selected"])
                ])
              ]),
              after: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_ClassPanel)
                ])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ], 512), [
            [_vShow, !_ctx.isClassTreeBusy]
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_DataLoading)
          ], 512), [
            [_vShow, _ctx.isClassTreeBusy]
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.isActiveModal,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isActiveModal) = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        (_ctx.isActiveModalAdd)
          ? (_openBlock(), _createBlock(_component_ClassAdd, {
              key: 0,
              clsId: _ctx.classifier.id,
              onAfterAction: _ctx.afterAction,
              onHideModal: _ctx.hideModal
            }, null, 8, ["clsId", "onAfterAction", "onHideModal"]))
          : _createCommentVNode("", true),
        (_ctx.isActiveModalEdit)
          ? (_openBlock(), _createBlock(_component_ClassEdit, {
              key: 1,
              clsId: _ctx.classifier.id,
              onAfterAction: _ctx.afterAction,
              onHideModal: _ctx.hideModal
            }, null, 8, ["clsId", "onAfterAction", "onHideModal"]))
          : _createCommentVNode("", true),
        (_ctx.isActiveModalDelete)
          ? (_openBlock(), _createBlock(_component_ClassDelete, {
              key: 2,
              onAfterAction: _ctx.afterAction,
              onHideModal: _ctx.hideModal
            }, null, 8, ["onAfterAction", "onHideModal"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 512)), [
    [_vShow, !_ctx.isClassifierBusy]
  ])
}