import { GetterTree, MutationTree, ActionTree } from 'vuex'
import {MessageApi} from "@/components/modules/accounts/services/api/MessageApi";

const messageList = new MessageApi()

class State {
    isMessageList: boolean = false
    messageList: any = []
}

const getters = <GetterTree<State, any>>{
    isMessageList(state) {
        return state.isMessageList
    },

    getMessageList(state) {
        return state.messageList
    },
}

const mutations = <MutationTree<State>>{
    setIsMessageList(state, payload) {
        state.isMessageList = payload
    },

    setMessageList(state, payload) {
        state.messageList = payload
    },
}

const actions = <ActionTree<State, any>>{
    getMessageList({ commit }, filter: any) {
        commit('setIsMessageList', true)

        return new Promise((resolve, reject) => {
            // Получаем список элементов
            messageList.messageList(filter).then(
                (message: any) => {
                    const items = message.getItemsList()

                    commit('setMessageList', items)
                    commit('setIsMessageList', false)

                    resolve(items)
                },
                (error) => {
                    commit('setMessageList', [])
                    commit('setIsMessageList', false)
                    reject(error)
                }
            )
        })
    },
}

const messageListModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters,
}

export default messageListModule
