import { GetterTree, MutationTree, ActionTree } from 'vuex'
import {FamilyApi} from "@/components/modules/accounts/services/api/FamilyApi";

const familyStatus = new FamilyApi()

class State {
    isFamilyList: boolean = false
    familyList: any = []
}

const getters = <GetterTree<State, any>>{
    isFamilyList(state) {
        return state.isFamilyList
    },

    getFamilyList(state) {
        return state.familyList
    },
}

const mutations = <MutationTree<State>>{
    setIsFamilyList(state, payload) {
        state.isFamilyList = payload
    },

    setFamilyList(state, payload) {
        state.familyList = payload
    },
}

const actions = <ActionTree<State, any>>{
    getFamilyList({ commit }, filter: any) {
        commit('setIsFamilyList', true)

        return new Promise((resolve, reject) => {
            // Получаем список элементов
            familyStatus.ListFamily(filter).then(
                (message: any) => {
                    const items = message.getItemsList()

                    commit('setFamilyList', items)
                    commit('setIsFamilyList', false)

                    resolve(items)
                },
                (error) => {
                    commit('setFamilyList', [])
                    commit('setIsFamilyList', false)
                    reject(error)
                }
            )
        })
    },
}

const familyStatusModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters,
}

export default familyStatusModule
