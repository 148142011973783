import { ActivityMetricAccountValueServiceClient } from '../../../proto/pb/Metric_account_valueServiceClientPb'
import {
  ListIntersectionActivityMetricAccountValueRequest,
  DbscanListIntersectionActivityMetricAccountValueRequest,
  AccountListIntersectionActivityMetricAccountValueRequest,
  ActivityIntersectionMetricAccountValuePagination,
  DbscanAccountListIntersectionActivityMetricAccountValueRequest
} from '../../../proto/pb/metric_account_value_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class SegmentationApi {
  client: ActivityMetricAccountValueServiceClient
  metadata: any

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? ''
    this.client = new ActivityMetricAccountValueServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  createPagination(data: any) {
    const pagination = new ActivityIntersectionMetricAccountValuePagination()
    const page: number = data.page !== undefined ? Number(data.page) : 1
    const limit: number = data.limit !== undefined ? Number(data.limit) : 1000
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0

    // Устанавливаем пагинацию
    pagination.setPage(page)
    pagination.setLimit(limit)
    pagination.setPages(pages)
    pagination.setCnt(cnt)

    return pagination
  }

  getSegmentationValue(filter: any) {
    const request = new ListIntersectionActivityMetricAccountValueRequest()

    request
      .setDateFrom(filter.date_from)
      .setDateTo(filter.date_to)
      .setProjectIdsList(filter.project_ids)
      .setFromMetricValueIdsList(filter.from_ids)
      .setToMetricValueIdsList(filter.to_ids)
      .setFromMetricAlias(filter.from_name)
      .setToMetricAlias(filter.to_name)
      .setIsAdditionalParams(filter.add_param)
    console.log(
      '🚀 ~ file: SegmentationApi.ts:25 ~ SegmentationApi ~ getSegmentationValue ~ request:',
      request
    )

    return this.client.listIntersectionActivityMetricAccountValue(request, this.metadata)
  }

  getDbScanValue(filter: any) {
    const request =
      new DbscanListIntersectionActivityMetricAccountValueRequest()

    request
      .setDateFrom(filter.date_from)
      .setDateTo(filter.date_to)
      .setProjectIdsList(filter.project_ids)
      .setFromMetricValueIdsList(filter.from_ids)
      .setToMetricValueIdsList(filter.to_ids)
      .setFromMetricAlias(filter.from_name)
      .setToMetricAlias(filter.to_name)
      .setIsAdditionalParams(filter.add_param)
    console.log(
      '🚀 ~ file: SegmentationApi.ts:25 ~ SegmentationApi ~ dbScanValue ~ request:',
      request
    )

    return this.client.dbscanListIntersectionActivityMetricAccountValue(request, this.metadata)
  }

  getAccListActivityMetric(filter: any, pagination: any) {
    const req = new AccountListIntersectionActivityMetricAccountValueRequest()

    req
      .setDateFrom(filter.date_from)
      .setDateTo(filter.date_to)
      .setFromMetricAlias(filter.from_metric_alias)
      .setFromMetricValueId(filter.from_metric_value_id)
      .setIsAdditionalParams(filter.is_add_param)
      .setProjectIdsList(filter.project_ids)
      .setToMetricAlias(filter.to_metric_alias)
      .setToMetricValueId(filter.to_metric_value_id)
      .setPagination(this.createPagination(pagination))

    return this.client.accountListIntersectionActivityMetricAccountValue(req, this.metadata)
  }

  getAccListDbscanMetric(filter: any, pagination: any) {
    const req = new DbscanAccountListIntersectionActivityMetricAccountValueRequest()

    req
        .setDateFrom(filter.date_from)
        .setDateTo(filter.date_to)
        .setFromMetricAlias(filter.from_metric_alias)
        .setIsAdditionalParams(filter.is_add_param)
        .setProjectIdsList(filter.project_ids)
        .setToMetricAlias(filter.to_metric_alias)
        .setFromMetricOrder(filter.from_metric_order)
        .setToMetricOrder(filter.to_metric_order)
        .setPagination(this.createPagination(pagination))

    return this.client.dbscanAccountListIntersectionActivityMetricAccountValue(req, this.metadata)
  }
}
