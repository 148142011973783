import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { AdressApi } from '../services/api/AdressApi'
import Loading from 'quasar/src/plugins/loading/Loading.js';

const adressApi = new AdressApi()

class State {
  isAdressBusy: boolean = false
  adressList: Array<any> = []
}

const getters = <GetterTree<State, any>>{
  isAdressBusy(state): boolean {
    return state.isAdressBusy
  },

  getAdressList(state) {
    return state.adressList
  },
}

const mutations = <MutationTree<State>>{
  setIsAdressBusy(state, payload: boolean) {
    state.isAdressBusy = payload
    if (payload) {
      Loading.show()
    } else {
      Loading.hide()
    }
  },

  setAdressList(state, payload) {
    state.adressList = payload
  },
}

const actions = <ActionTree<State, any>>{
  getAdressList({ commit }, data: any) {
    commit('setIsAdressBusy', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []
    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : []

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      adressApi.getActionList(filter, pagination).then(
        (message: any) => {
          const items = message.getItemsList()

          commit('setAdressList', items)
          commit('setIsAdressBusy', false)

          resolve(items)
        },
        (error) => {
          commit('setAdressList', [])
          commit('setIsAdressBusy', false)
          reject(error)
        }
      )
    })
  },
}

const AccountAdressModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default AccountAdressModule
