import {
    QuestionServiceClient
} from "../../proto/pb/QuestionServiceClientPb";
import {
    Question,
    ListQuestionRequest,
    ListQuestionChildsRequest,
    ReduceQuestionRequest,
    SeparateQuestionRequest,
    //ItemQuestionRequest,
    QuestionFilter,
    QuestionFilterItem,
    QuestionSort,
    QuestionPagination
} from "../../proto/pb/question_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit: number = 10000;

export class QuestionApi {
    items: Array<Question> = [];
    client: QuestionServiceClient;
    metadata: any;

    constructor() {
        const host: string = (window as any).VUE_APP_API_URL ?? '';
        this.client = new QuestionServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    // Установка и получение фильтров
    createFilter(data: any) {

        const filter = new QuestionFilter();

        // Устанавливаем фильтры
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && ('' !== data[i].field_name)) {
                    const fItem = new QuestionFilterItem();
                    fItem.setFieldName(data[i].field_name.toString());
                    fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                    fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                    fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                    filter.addItems(fItem);
                }
            }
        }

        return filter;
    }

    // Установка и получение сортировки
    createSort(data: any) {

        const sort = new QuestionSort();
        const name: string = data.name !== undefined ? data.name.toString() : 'question_stitle';
        const exp: string = data.exp !== undefined ? data.exp.toString() : 'asc';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    // Установка и получение пагинации
    createPagination(data: any) {

        const pagination = new QuestionPagination();
        const page: number = data.page !== undefined ? Number(data.page) : 1;
        const limit: number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
        const pages: number = data.pages !== undefined ? Number(data.pages) : 0;
        const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0;

        // Устанавливаем пагинацию
        pagination.setPage(page);
        pagination.setLimit(limit);
        pagination.setPages(pages);

        return pagination;
    }

    // Список корневых элементов
    getQuestionItems(filter: any, sort: any, pagination: any, data: any) {

        const request = new ListQuestionRequest();

        // Устанавливаем параметры запроса
        request
            .setFilter(this.createFilter(filter))
            .setSort(this.createSort(sort))
            .setPagination(this.createPagination(pagination))
            .setPollId(data.poll_id)
            .setIsWorked(data.is_worked)

        console.log('question req', request)

        return this.client.listQuestion(request, this.metadata);
    }

    // Список дочерних элементов
    getQuestionChildItems(filter: any, sort: any, pagination: any, data: any) {

        const request = new ListQuestionChildsRequest();

        // Устанавливаем параметры запроса
        request
            .setFilter(this.createFilter(filter))
            .setSort(this.createSort(sort))
            .setPagination(this.createPagination(pagination))
            .setQuestionId(data.question_id)

        return this.client.listQuestionChilds(request, this.metadata);
    }

    // Получение элемента
    getQuestionItem(filter: any) {

        const request = new ListQuestionRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort({}));
        request.setPagination(this.createPagination({}));

        return this.client.listQuestion(request, this.metadata)
            .then((message) => {
                const itemList = message.getItemsList()
                if (itemList && (itemList.length > 0)) {
                    return itemList[0]
                }
            })
    }

    reduceQuestion(data: any) {
        const req = new ReduceQuestionRequest()
        req
            .setQuestionId(data.question_id)
            .setReducedIdsList(data.reduced_ids_list)

        return this.client.reduceQuestion(req, this.metadata)
    }

    separateQuestion(data: any) {
        const req = new SeparateQuestionRequest()
        req
            .setSeparatedIdsList(data.separated_ids_list)

        return this.client.separateQuestion(req, this.metadata)
    }
}
