
function getPointCategoryName(point: any, dimension: any) {
  let series = point.series,
    isY = dimension === 'y',
    axis = series[isY ? 'yAxis' : 'xAxis']

  return axis.categories[point[isY ? 'y' : 'x']]
}

function getActivityName(point: any, dimension: any) {
  let series = point.series,
    isY = dimension === 'y',
    axis = series[isY ? 'yAxis' : 'xAxis']

  return axis.userOptions.title.text
}

import store from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AudienceSegmentationChart',

  props: {
    buildChart: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loadChartData: false,
      chartOptions: {
        chart: {
          type: 'heatmap',
          marginTop: 40,
          marginBottom: 80,
          plotBorderWidth: 1,
        },

        title: {
          text: 'Пересечение метрик',
        },

        xAxis: {
          categories: [] as any,
          title: {
            text: '',
          },
        },

        yAxis: {
          categories: [] as any,
          title: {
            text: '',
          },
          reversed: true,
        },

        // accessibility: {
        //   point: {
        //     descriptionFormatter: function (point: any) {
        //       var ix = point.index + 1,
        //         xName = getPointCategoryName(point, 'x'),
        //         yName = getPointCategoryName(point, 'y'),
        //         val = point.value
        //       return ix + '. ' + xName + ' sales ' + yName + ', ' + val + '.'
        //     },
        //   },
        // },

        colorAxis: {
          min: 0,
          minColor: '#FFFFFF',
          maxColor: '#0000ff',
        },

        legend: { enabled: false },

        tooltip: {
          // eslint-disable-next-line
          formatter: function (this: any) {
            return (
              getPointCategoryName(this.point, 'y') +
              ' ' +
              getActivityName(this.point, 'y').toLowerCase() +
              ' и ' +
              getPointCategoryName(this.point, 'x') +
              ' ' +
              getActivityName(this.point, 'x').toLowerCase() +
              ': ' +
              this.point.value +
              ' чел.'
            )
          } as any,
        },

        series: [
          {
            name: 'Количество',
            borderWidth: 1,
            data: [
              // [0, 0, 22154],
              // [0, 1, 16521],
              // [0, 2, 7185],
              // [0, 3, 785],
              // [1, 0, 115874],
              // [1, 1, 241873],
              // [1, 2, 315241],
              // [1, 3, 1074],
              // [2, 0, 114789],
              // [2, 1, 216348],
              // [2, 2, 318542],
              // [2, 3, 847],
              // [3, 0, 14876],
              // [3, 1, 145831],
              // [3, 2, 141254],
              // [3, 3, 567],
            ] as any,
            dataLabels: {
              enabled: true,
              color: '#000000',
            },
          },
        ],
      },
    }
  },

  computed: {
    getMetricValueItems() {
      return store.getters.getMetricValueItems
    },

    getIntersectionMetricAccountValueItems() {
      return store.getters.getIntersectionMetricAccountValueItems
    },
    isIntersectionMetricAccountValueItemsBusy() {
      return store.getters.isIntersectionMetricAccountValueItemsBusy
    },
  },

  watch: {
    buildChart() {
      this.loadChartData = true
      this.loadData()
    },
    getIntersectionMetricAccountValueItems() {
      this.transformBackData()
    },
  },

  methods: {
    transformBackData() {
      this.chartOptions.series[0].data.length = 0
      let arr = this.getIntersectionMetricAccountValueItems
      let obj = {} as any
      let chosenXMetricsAll = [] as any
      let chosenYMetricsAll = [] as any

      for (let item in arr) {
        obj[
          `${arr[item].getFromMetricValueId()}-${arr[
            item
          ].getToMetricValueId()}`
        ] = arr[item].getCnt()
        chosenXMetricsAll.push(arr[item].getFromMetricValueId())
        chosenYMetricsAll.push(arr[item].getToMetricValueId())
      }

      let chosenXMetrics = chosenXMetricsAll.filter(
        (el: any, ind: any) => ind === chosenXMetricsAll.indexOf(el)
      )

      let chosenYMetrics = chosenYMetricsAll.filter(
        (el: any, ind: any) => ind === chosenYMetricsAll.indexOf(el)
      )

      const metricValueItems = this.getMetricValueItems
      let firstMetricId = metricValueItems[0].getMetricId()

      this.chartOptions.xAxis.title.text = metricValueItems[0].getMetricName()
      this.chartOptions.yAxis.title.text = metricValueItems[4].getMetricName()

      let firstMetric = [] as any
      let secondMetric = [] as any
      let xAxisCategories = [] as any
      let yAxisCategories = [] as any

      for (let metricItem of metricValueItems) {
        if (metricItem.getMetricId() === firstMetricId) {
          if (chosenXMetrics.includes(metricItem.getMetricValueIdx())) {
            firstMetric.push(metricItem.getMetricValueId())
            xAxisCategories.push(metricItem.getMetricValueName())
          }
        } else {
          if (secondMetric.length < 4) {
            if (chosenYMetrics.includes(metricItem.getMetricValueIdx())) {
              secondMetric.push(metricItem.getMetricValueId())
              yAxisCategories.push(metricItem.getMetricValueName())
            }
          }
        }
      }

      this.chartOptions.yAxis.categories = yAxisCategories
      this.chartOptions.xAxis.categories = xAxisCategories.reverse()

      firstMetric = firstMetric.reverse()
      // secondMetric = secondMetric.reverse()

      for (let i in firstMetric) {
        for (let j in secondMetric) {
          this.chartOptions.series[0].data.push([
            Number(i),
            Number(j),
            obj[`${firstMetric[i]}-${secondMetric[j]}`],
          ])
        }
      }
    },

    getFilters() {
      return []
    },

    loadMetricValueItems() {
      store
        .dispatch('getMetricValueItems', {
          filter: this.getFilters(),
        })
        .then(
          () => {
            // console.log('getMetricValueItems resolve', resolve)
          },
          (reject) => {
            console.log('getMetricValueItems reject', reject)
          }
        )
    },

    // loadIntersectionMetricValueItems() {
    //   return store
    //     .dispatch('getIntersectionMetricAccountValueItems', {
    //       projectIds: [1],
    //       periodIds: [1],
    //       fromMetricValueIds: [1, 2, 3, 4],
    //       toMetricValueIds: [5, 6, 7, 8],
    //     })
    //     .then(
    //       (resolve) => {
    //         console.log(
    //           'getIntersectionMetricAccountValueItems resolve',
    //           resolve
    //         )
    //         this.transformBackData()
    //       },
    //       (reject) => {
    //         console.log('getIntersectionMetricAccountValueItems reject', reject)
    //       }
    //     )
    // },

    loadData() {
      this.loadMetricValueItems()
    },
  },

  created() {
    this.loadData()
  },
})
