// source: poll_info_form.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.proto.PollInfoFormAggrAnswer', null, global);
goog.exportSymbol('proto.proto.PollInfoFormAnswer', null, global);
goog.exportSymbol('proto.proto.PollInfoFormClsOpenAnswer', null, global);
goog.exportSymbol('proto.proto.PollInfoFormClsQuestion', null, global);
goog.exportSymbol('proto.proto.PollInfoFormClsRequest', null, global);
goog.exportSymbol('proto.proto.PollInfoFormClsResponse', null, global);
goog.exportSymbol('proto.proto.PollInfoFormOpenAnswer', null, global);
goog.exportSymbol('proto.proto.PollInfoFormQuestion', null, global);
goog.exportSymbol('proto.proto.PollInfoFormRequest', null, global);
goog.exportSymbol('proto.proto.PollInfoFormResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollInfoFormAggrAnswer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PollInfoFormAggrAnswer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollInfoFormAggrAnswer.displayName = 'proto.proto.PollInfoFormAggrAnswer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollInfoFormAnswer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PollInfoFormAnswer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollInfoFormAnswer.displayName = 'proto.proto.PollInfoFormAnswer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollInfoFormOpenAnswer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PollInfoFormOpenAnswer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollInfoFormOpenAnswer.displayName = 'proto.proto.PollInfoFormOpenAnswer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollInfoFormQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.PollInfoFormQuestion.repeatedFields_, null);
};
goog.inherits(proto.proto.PollInfoFormQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollInfoFormQuestion.displayName = 'proto.proto.PollInfoFormQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollInfoFormClsOpenAnswer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PollInfoFormClsOpenAnswer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollInfoFormClsOpenAnswer.displayName = 'proto.proto.PollInfoFormClsOpenAnswer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollInfoFormClsQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.PollInfoFormClsQuestion.repeatedFields_, null);
};
goog.inherits(proto.proto.PollInfoFormClsQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollInfoFormClsQuestion.displayName = 'proto.proto.PollInfoFormClsQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollInfoFormRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PollInfoFormRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollInfoFormRequest.displayName = 'proto.proto.PollInfoFormRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollInfoFormResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.PollInfoFormResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.PollInfoFormResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollInfoFormResponse.displayName = 'proto.proto.PollInfoFormResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollInfoFormClsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PollInfoFormClsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollInfoFormClsRequest.displayName = 'proto.proto.PollInfoFormClsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollInfoFormClsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.PollInfoFormClsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.PollInfoFormClsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollInfoFormClsResponse.displayName = 'proto.proto.PollInfoFormClsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollInfoFormAggrAnswer.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollInfoFormAggrAnswer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollInfoFormAggrAnswer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormAggrAnswer.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    questionStitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    answerTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    answerCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    answerPercent: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollInfoFormAggrAnswer}
 */
proto.proto.PollInfoFormAggrAnswer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollInfoFormAggrAnswer;
  return proto.proto.PollInfoFormAggrAnswer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollInfoFormAggrAnswer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollInfoFormAggrAnswer}
 */
proto.proto.PollInfoFormAggrAnswer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuestionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionStitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerTitle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerCount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollInfoFormAggrAnswer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollInfoFormAggrAnswer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollInfoFormAggrAnswer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormAggrAnswer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getQuestionStitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAnswerTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAnswerCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAnswerPercent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 question_id = 1;
 * @return {number}
 */
proto.proto.PollInfoFormAggrAnswer.prototype.getQuestionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormAggrAnswer} returns this
 */
proto.proto.PollInfoFormAggrAnswer.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string question_stitle = 2;
 * @return {string}
 */
proto.proto.PollInfoFormAggrAnswer.prototype.getQuestionStitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollInfoFormAggrAnswer} returns this
 */
proto.proto.PollInfoFormAggrAnswer.prototype.setQuestionStitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string answer_title = 3;
 * @return {string}
 */
proto.proto.PollInfoFormAggrAnswer.prototype.getAnswerTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollInfoFormAggrAnswer} returns this
 */
proto.proto.PollInfoFormAggrAnswer.prototype.setAnswerTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 answer_count = 4;
 * @return {number}
 */
proto.proto.PollInfoFormAggrAnswer.prototype.getAnswerCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormAggrAnswer} returns this
 */
proto.proto.PollInfoFormAggrAnswer.prototype.setAnswerCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string answer_percent = 5;
 * @return {string}
 */
proto.proto.PollInfoFormAggrAnswer.prototype.getAnswerPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollInfoFormAggrAnswer} returns this
 */
proto.proto.PollInfoFormAggrAnswer.prototype.setAnswerPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollInfoFormAnswer.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollInfoFormAnswer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollInfoFormAnswer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormAnswer.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    answerTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    answerCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    answerPercent: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollInfoFormAnswer}
 */
proto.proto.PollInfoFormAnswer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollInfoFormAnswer;
  return proto.proto.PollInfoFormAnswer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollInfoFormAnswer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollInfoFormAnswer}
 */
proto.proto.PollInfoFormAnswer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerCount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollInfoFormAnswer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollInfoFormAnswer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollInfoFormAnswer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormAnswer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAnswerTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAnswerCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAnswerPercent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 answer_num = 1;
 * @return {number}
 */
proto.proto.PollInfoFormAnswer.prototype.getAnswerNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormAnswer} returns this
 */
proto.proto.PollInfoFormAnswer.prototype.setAnswerNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string answer_title = 2;
 * @return {string}
 */
proto.proto.PollInfoFormAnswer.prototype.getAnswerTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollInfoFormAnswer} returns this
 */
proto.proto.PollInfoFormAnswer.prototype.setAnswerTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 answer_count = 3;
 * @return {number}
 */
proto.proto.PollInfoFormAnswer.prototype.getAnswerCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormAnswer} returns this
 */
proto.proto.PollInfoFormAnswer.prototype.setAnswerCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string answer_percent = 4;
 * @return {string}
 */
proto.proto.PollInfoFormAnswer.prototype.getAnswerPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollInfoFormAnswer} returns this
 */
proto.proto.PollInfoFormAnswer.prototype.setAnswerPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollInfoFormOpenAnswer.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollInfoFormOpenAnswer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollInfoFormOpenAnswer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormOpenAnswer.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    answerText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    answerSum: jspb.Message.getFieldWithDefault(msg, 3, 0),
    answerPercent: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollInfoFormOpenAnswer}
 */
proto.proto.PollInfoFormOpenAnswer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollInfoFormOpenAnswer;
  return proto.proto.PollInfoFormOpenAnswer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollInfoFormOpenAnswer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollInfoFormOpenAnswer}
 */
proto.proto.PollInfoFormOpenAnswer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerText(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerSum(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollInfoFormOpenAnswer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollInfoFormOpenAnswer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollInfoFormOpenAnswer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormOpenAnswer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAnswerText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAnswerSum();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAnswerPercent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 answer_num = 1;
 * @return {number}
 */
proto.proto.PollInfoFormOpenAnswer.prototype.getAnswerNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormOpenAnswer} returns this
 */
proto.proto.PollInfoFormOpenAnswer.prototype.setAnswerNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string answer_text = 2;
 * @return {string}
 */
proto.proto.PollInfoFormOpenAnswer.prototype.getAnswerText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollInfoFormOpenAnswer} returns this
 */
proto.proto.PollInfoFormOpenAnswer.prototype.setAnswerText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 answer_sum = 3;
 * @return {number}
 */
proto.proto.PollInfoFormOpenAnswer.prototype.getAnswerSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormOpenAnswer} returns this
 */
proto.proto.PollInfoFormOpenAnswer.prototype.setAnswerSum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string answer_percent = 4;
 * @return {string}
 */
proto.proto.PollInfoFormOpenAnswer.prototype.getAnswerPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollInfoFormOpenAnswer} returns this
 */
proto.proto.PollInfoFormOpenAnswer.prototype.setAnswerPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.PollInfoFormQuestion.repeatedFields_ = [3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollInfoFormQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollInfoFormQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollInfoFormQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    questionStitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    proto.proto.PollInfoFormAnswer.toObject, includeInstance),
    openAnswersSourceCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    openAnswersList: jspb.Message.toObjectList(msg.getOpenAnswersList(),
    proto.proto.PollInfoFormOpenAnswer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollInfoFormQuestion}
 */
proto.proto.PollInfoFormQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollInfoFormQuestion;
  return proto.proto.PollInfoFormQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollInfoFormQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollInfoFormQuestion}
 */
proto.proto.PollInfoFormQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuestionNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionStitle(value);
      break;
    case 3:
      var value = new proto.proto.PollInfoFormAnswer;
      reader.readMessage(value,proto.proto.PollInfoFormAnswer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpenAnswersSourceCount(value);
      break;
    case 5:
      var value = new proto.proto.PollInfoFormOpenAnswer;
      reader.readMessage(value,proto.proto.PollInfoFormOpenAnswer.deserializeBinaryFromReader);
      msg.addOpenAnswers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollInfoFormQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollInfoFormQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollInfoFormQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionNumber();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getQuestionStitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.proto.PollInfoFormAnswer.serializeBinaryToWriter
    );
  }
  f = message.getOpenAnswersSourceCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOpenAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.proto.PollInfoFormOpenAnswer.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 question_number = 1;
 * @return {number}
 */
proto.proto.PollInfoFormQuestion.prototype.getQuestionNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormQuestion} returns this
 */
proto.proto.PollInfoFormQuestion.prototype.setQuestionNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string question_stitle = 2;
 * @return {string}
 */
proto.proto.PollInfoFormQuestion.prototype.getQuestionStitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollInfoFormQuestion} returns this
 */
proto.proto.PollInfoFormQuestion.prototype.setQuestionStitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated PollInfoFormAnswer answers = 3;
 * @return {!Array<!proto.proto.PollInfoFormAnswer>}
 */
proto.proto.PollInfoFormQuestion.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.proto.PollInfoFormAnswer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PollInfoFormAnswer, 3));
};


/**
 * @param {!Array<!proto.proto.PollInfoFormAnswer>} value
 * @return {!proto.proto.PollInfoFormQuestion} returns this
*/
proto.proto.PollInfoFormQuestion.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.proto.PollInfoFormAnswer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PollInfoFormAnswer}
 */
proto.proto.PollInfoFormQuestion.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.proto.PollInfoFormAnswer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PollInfoFormQuestion} returns this
 */
proto.proto.PollInfoFormQuestion.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};


/**
 * optional int64 open_answers_source_count = 4;
 * @return {number}
 */
proto.proto.PollInfoFormQuestion.prototype.getOpenAnswersSourceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormQuestion} returns this
 */
proto.proto.PollInfoFormQuestion.prototype.setOpenAnswersSourceCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated PollInfoFormOpenAnswer open_answers = 5;
 * @return {!Array<!proto.proto.PollInfoFormOpenAnswer>}
 */
proto.proto.PollInfoFormQuestion.prototype.getOpenAnswersList = function() {
  return /** @type{!Array<!proto.proto.PollInfoFormOpenAnswer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PollInfoFormOpenAnswer, 5));
};


/**
 * @param {!Array<!proto.proto.PollInfoFormOpenAnswer>} value
 * @return {!proto.proto.PollInfoFormQuestion} returns this
*/
proto.proto.PollInfoFormQuestion.prototype.setOpenAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.proto.PollInfoFormOpenAnswer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PollInfoFormOpenAnswer}
 */
proto.proto.PollInfoFormQuestion.prototype.addOpenAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.proto.PollInfoFormOpenAnswer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PollInfoFormQuestion} returns this
 */
proto.proto.PollInfoFormQuestion.prototype.clearOpenAnswersList = function() {
  return this.setOpenAnswersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollInfoFormClsOpenAnswer.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollInfoFormClsOpenAnswer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollInfoFormClsOpenAnswer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormClsOpenAnswer.toObject = function(includeInstance, msg) {
  var f, obj = {
    classNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    className: jspb.Message.getFieldWithDefault(msg, 2, ""),
    classSum: jspb.Message.getFieldWithDefault(msg, 3, 0),
    classPercent: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollInfoFormClsOpenAnswer}
 */
proto.proto.PollInfoFormClsOpenAnswer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollInfoFormClsOpenAnswer;
  return proto.proto.PollInfoFormClsOpenAnswer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollInfoFormClsOpenAnswer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollInfoFormClsOpenAnswer}
 */
proto.proto.PollInfoFormClsOpenAnswer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassSum(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollInfoFormClsOpenAnswer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollInfoFormClsOpenAnswer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollInfoFormClsOpenAnswer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormClsOpenAnswer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClassNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getClassName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClassSum();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getClassPercent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 class_num = 1;
 * @return {number}
 */
proto.proto.PollInfoFormClsOpenAnswer.prototype.getClassNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormClsOpenAnswer} returns this
 */
proto.proto.PollInfoFormClsOpenAnswer.prototype.setClassNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string class_name = 2;
 * @return {string}
 */
proto.proto.PollInfoFormClsOpenAnswer.prototype.getClassName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollInfoFormClsOpenAnswer} returns this
 */
proto.proto.PollInfoFormClsOpenAnswer.prototype.setClassName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 class_sum = 3;
 * @return {number}
 */
proto.proto.PollInfoFormClsOpenAnswer.prototype.getClassSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormClsOpenAnswer} returns this
 */
proto.proto.PollInfoFormClsOpenAnswer.prototype.setClassSum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string class_percent = 4;
 * @return {string}
 */
proto.proto.PollInfoFormClsOpenAnswer.prototype.getClassPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollInfoFormClsOpenAnswer} returns this
 */
proto.proto.PollInfoFormClsOpenAnswer.prototype.setClassPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.PollInfoFormClsQuestion.repeatedFields_ = [3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollInfoFormClsQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollInfoFormClsQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollInfoFormClsQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormClsQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    questionStitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    proto.proto.PollInfoFormAnswer.toObject, includeInstance),
    openAnswersSourceCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    clsOpenAnswersList: jspb.Message.toObjectList(msg.getClsOpenAnswersList(),
    proto.proto.PollInfoFormClsOpenAnswer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollInfoFormClsQuestion}
 */
proto.proto.PollInfoFormClsQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollInfoFormClsQuestion;
  return proto.proto.PollInfoFormClsQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollInfoFormClsQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollInfoFormClsQuestion}
 */
proto.proto.PollInfoFormClsQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuestionNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionStitle(value);
      break;
    case 3:
      var value = new proto.proto.PollInfoFormAnswer;
      reader.readMessage(value,proto.proto.PollInfoFormAnswer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpenAnswersSourceCount(value);
      break;
    case 5:
      var value = new proto.proto.PollInfoFormClsOpenAnswer;
      reader.readMessage(value,proto.proto.PollInfoFormClsOpenAnswer.deserializeBinaryFromReader);
      msg.addClsOpenAnswers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollInfoFormClsQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollInfoFormClsQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollInfoFormClsQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormClsQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionNumber();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getQuestionStitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.proto.PollInfoFormAnswer.serializeBinaryToWriter
    );
  }
  f = message.getOpenAnswersSourceCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getClsOpenAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.proto.PollInfoFormClsOpenAnswer.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 question_number = 1;
 * @return {number}
 */
proto.proto.PollInfoFormClsQuestion.prototype.getQuestionNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormClsQuestion} returns this
 */
proto.proto.PollInfoFormClsQuestion.prototype.setQuestionNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string question_stitle = 2;
 * @return {string}
 */
proto.proto.PollInfoFormClsQuestion.prototype.getQuestionStitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollInfoFormClsQuestion} returns this
 */
proto.proto.PollInfoFormClsQuestion.prototype.setQuestionStitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated PollInfoFormAnswer answers = 3;
 * @return {!Array<!proto.proto.PollInfoFormAnswer>}
 */
proto.proto.PollInfoFormClsQuestion.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.proto.PollInfoFormAnswer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PollInfoFormAnswer, 3));
};


/**
 * @param {!Array<!proto.proto.PollInfoFormAnswer>} value
 * @return {!proto.proto.PollInfoFormClsQuestion} returns this
*/
proto.proto.PollInfoFormClsQuestion.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.proto.PollInfoFormAnswer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PollInfoFormAnswer}
 */
proto.proto.PollInfoFormClsQuestion.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.proto.PollInfoFormAnswer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PollInfoFormClsQuestion} returns this
 */
proto.proto.PollInfoFormClsQuestion.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};


/**
 * optional int64 open_answers_source_count = 4;
 * @return {number}
 */
proto.proto.PollInfoFormClsQuestion.prototype.getOpenAnswersSourceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormClsQuestion} returns this
 */
proto.proto.PollInfoFormClsQuestion.prototype.setOpenAnswersSourceCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated PollInfoFormClsOpenAnswer cls_open_answers = 5;
 * @return {!Array<!proto.proto.PollInfoFormClsOpenAnswer>}
 */
proto.proto.PollInfoFormClsQuestion.prototype.getClsOpenAnswersList = function() {
  return /** @type{!Array<!proto.proto.PollInfoFormClsOpenAnswer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PollInfoFormClsOpenAnswer, 5));
};


/**
 * @param {!Array<!proto.proto.PollInfoFormClsOpenAnswer>} value
 * @return {!proto.proto.PollInfoFormClsQuestion} returns this
*/
proto.proto.PollInfoFormClsQuestion.prototype.setClsOpenAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.proto.PollInfoFormClsOpenAnswer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PollInfoFormClsOpenAnswer}
 */
proto.proto.PollInfoFormClsQuestion.prototype.addClsOpenAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.proto.PollInfoFormClsOpenAnswer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PollInfoFormClsQuestion} returns this
 */
proto.proto.PollInfoFormClsQuestion.prototype.clearClsOpenAnswersList = function() {
  return this.setClsOpenAnswersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollInfoFormRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollInfoFormRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollInfoFormRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pollId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxOpenRows: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollInfoFormRequest}
 */
proto.proto.PollInfoFormRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollInfoFormRequest;
  return proto.proto.PollInfoFormRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollInfoFormRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollInfoFormRequest}
 */
proto.proto.PollInfoFormRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPollId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxOpenRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollInfoFormRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollInfoFormRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollInfoFormRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPollId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMaxOpenRows();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 poll_id = 1;
 * @return {number}
 */
proto.proto.PollInfoFormRequest.prototype.getPollId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormRequest} returns this
 */
proto.proto.PollInfoFormRequest.prototype.setPollId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 max_open_rows = 2;
 * @return {number}
 */
proto.proto.PollInfoFormRequest.prototype.getMaxOpenRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormRequest} returns this
 */
proto.proto.PollInfoFormRequest.prototype.setMaxOpenRows = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.PollInfoFormResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollInfoFormResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollInfoFormResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollInfoFormResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.PollInfoFormQuestion.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.PollInfoFormRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollInfoFormResponse}
 */
proto.proto.PollInfoFormResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollInfoFormResponse;
  return proto.proto.PollInfoFormResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollInfoFormResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollInfoFormResponse}
 */
proto.proto.PollInfoFormResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsersCount(value);
      break;
    case 2:
      var value = new proto.proto.PollInfoFormQuestion;
      reader.readMessage(value,proto.proto.PollInfoFormQuestion.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = new proto.proto.PollInfoFormRequest;
      reader.readMessage(value,proto.proto.PollInfoFormRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollInfoFormResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollInfoFormResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollInfoFormResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.PollInfoFormQuestion.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.PollInfoFormRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 users_count = 1;
 * @return {number}
 */
proto.proto.PollInfoFormResponse.prototype.getUsersCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormResponse} returns this
 */
proto.proto.PollInfoFormResponse.prototype.setUsersCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated PollInfoFormQuestion items = 2;
 * @return {!Array<!proto.proto.PollInfoFormQuestion>}
 */
proto.proto.PollInfoFormResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.PollInfoFormQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PollInfoFormQuestion, 2));
};


/**
 * @param {!Array<!proto.proto.PollInfoFormQuestion>} value
 * @return {!proto.proto.PollInfoFormResponse} returns this
*/
proto.proto.PollInfoFormResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.PollInfoFormQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PollInfoFormQuestion}
 */
proto.proto.PollInfoFormResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.PollInfoFormQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PollInfoFormResponse} returns this
 */
proto.proto.PollInfoFormResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional PollInfoFormRequest params = 3;
 * @return {?proto.proto.PollInfoFormRequest}
 */
proto.proto.PollInfoFormResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.PollInfoFormRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.PollInfoFormRequest, 3));
};


/**
 * @param {?proto.proto.PollInfoFormRequest|undefined} value
 * @return {!proto.proto.PollInfoFormResponse} returns this
*/
proto.proto.PollInfoFormResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PollInfoFormResponse} returns this
 */
proto.proto.PollInfoFormResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PollInfoFormResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollInfoFormClsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollInfoFormClsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollInfoFormClsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormClsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    classifierId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pollId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maxClsRows: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollInfoFormClsRequest}
 */
proto.proto.PollInfoFormClsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollInfoFormClsRequest;
  return proto.proto.PollInfoFormClsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollInfoFormClsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollInfoFormClsRequest}
 */
proto.proto.PollInfoFormClsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassifierId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPollId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxClsRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollInfoFormClsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollInfoFormClsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollInfoFormClsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormClsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClassifierId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPollId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMaxClsRows();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 classifier_id = 1;
 * @return {number}
 */
proto.proto.PollInfoFormClsRequest.prototype.getClassifierId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormClsRequest} returns this
 */
proto.proto.PollInfoFormClsRequest.prototype.setClassifierId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 poll_id = 2;
 * @return {number}
 */
proto.proto.PollInfoFormClsRequest.prototype.getPollId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormClsRequest} returns this
 */
proto.proto.PollInfoFormClsRequest.prototype.setPollId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 max_cls_rows = 3;
 * @return {number}
 */
proto.proto.PollInfoFormClsRequest.prototype.getMaxClsRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormClsRequest} returns this
 */
proto.proto.PollInfoFormClsRequest.prototype.setMaxClsRows = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.PollInfoFormClsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollInfoFormClsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollInfoFormClsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollInfoFormClsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormClsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.PollInfoFormClsQuestion.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.PollInfoFormClsRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollInfoFormClsResponse}
 */
proto.proto.PollInfoFormClsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollInfoFormClsResponse;
  return proto.proto.PollInfoFormClsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollInfoFormClsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollInfoFormClsResponse}
 */
proto.proto.PollInfoFormClsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsersCount(value);
      break;
    case 2:
      var value = new proto.proto.PollInfoFormClsQuestion;
      reader.readMessage(value,proto.proto.PollInfoFormClsQuestion.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = new proto.proto.PollInfoFormClsRequest;
      reader.readMessage(value,proto.proto.PollInfoFormClsRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollInfoFormClsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollInfoFormClsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollInfoFormClsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollInfoFormClsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.PollInfoFormClsQuestion.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.PollInfoFormClsRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 users_count = 1;
 * @return {number}
 */
proto.proto.PollInfoFormClsResponse.prototype.getUsersCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollInfoFormClsResponse} returns this
 */
proto.proto.PollInfoFormClsResponse.prototype.setUsersCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated PollInfoFormClsQuestion items = 2;
 * @return {!Array<!proto.proto.PollInfoFormClsQuestion>}
 */
proto.proto.PollInfoFormClsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.PollInfoFormClsQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PollInfoFormClsQuestion, 2));
};


/**
 * @param {!Array<!proto.proto.PollInfoFormClsQuestion>} value
 * @return {!proto.proto.PollInfoFormClsResponse} returns this
*/
proto.proto.PollInfoFormClsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.PollInfoFormClsQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PollInfoFormClsQuestion}
 */
proto.proto.PollInfoFormClsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.PollInfoFormClsQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PollInfoFormClsResponse} returns this
 */
proto.proto.PollInfoFormClsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional PollInfoFormClsRequest params = 3;
 * @return {?proto.proto.PollInfoFormClsRequest}
 */
proto.proto.PollInfoFormClsResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.PollInfoFormClsRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.PollInfoFormClsRequest, 3));
};


/**
 * @param {?proto.proto.PollInfoFormClsRequest|undefined} value
 * @return {!proto.proto.PollInfoFormClsResponse} returns this
*/
proto.proto.PollInfoFormClsResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PollInfoFormClsResponse} returns this
 */
proto.proto.PollInfoFormClsResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PollInfoFormClsResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
