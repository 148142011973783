import { GetterTree, MutationTree, ActionTree } from "vuex";
import { ClassApi } from "../services/api/ClassApi";
import storeBase from "@/store/mixins/base";
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
  isClassBusy: boolean = false;
  isClassTreeBusy: boolean = false;
  isClassTreeFormBusy: boolean = false;
  classItems: unknown [];
  classTreeItems: unknown [];
  classTreeFormItems: unknown [];
  classItem: unknown;
  classId: any = null;
  currentNode: any = null;
}

// Создаем объект
const classApi = new ClassApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

  isClassBusy(state): boolean {
    return state.isClassBusy;
  },

  isClassTreeBusy(state): boolean {
    return state.isClassTreeBusy;
  },

  isClassTreeFormBusy(state): boolean {
    return state.isClassTreeFormBusy;
  },

  getClassItems(state): unknown[] {
    return state.classItems;
  },

  getClassTreeItems(state): unknown[] {
    return state.classTreeItems;
  },

  getClassTreeFormItems(state): unknown[] {
    return state.classTreeFormItems;
  },

  getClassItem(state): unknown {
    return state.classItem;
  },

  getClassId(state): any {
    return state.classId;
  },

  getCurrentNode(state): any {
    return state.currentNode;
  }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

  setIsClassBusy(state, payload: boolean) {
    state.isClassBusy = payload
  },

  setIsClassTreeBusy(state, payload: boolean) {
    state.isClassTreeBusy = payload
  },

  setIsClassTreeFormBusy(state, payload: boolean) {
    state.isClassTreeFormBusy = payload
  },

  setClassItems(state, payload: unknown[]) {
    state.classItems = payload
  },

  setClassTreeItems(state, payload: unknown[]) {
    state.classTreeItems = payload
  },

  setClassTreeFormItems(state, payload: unknown[]) {
    state.classTreeFormItems = payload
  },

  setClassItem(state, payload: unknown) {
    state.classItem = payload
  },

  setClassId(state, payload: any) {
    state.classId = payload
  },

  setCurrentNode(state, payload: any) {
    state.currentNode = payload
  }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

  // Устанавливаем элемент
  setClassItem({ commit }, data: any) {
    commit('setClassItem', data);
  },
  
  // Устанавливаем текущий classId
  setClassId({ commit }, data: any) {
    commit('setClassId', data);
  },
  
  // Устанавливаем текущий узел дерева
  setCurrentNode({ commit }, data: any) {
    commit('setCurrentNode', data);
  },
  
  // Дерево классификатора
  getClassTreeItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsClassTreeBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      classApi.getClassItems(filter, sort, pagination)
        .then(
          (response: any) => {
            commit('setClassItems', response);

            // Конвертируем данные для дерева
            const tree = storeBase.methods.convertClassItemsToTree(response)
            commit('setClassTreeItems', tree);
            commit('setIsClassTreeBusy', false);
            resolve(tree);
          },
          (error: any) => {
            commit('setClassTreeItems', []);
            commit('setIsClassTreeBusy', false);
            reject(error);
          }
        );
    });
  },

  // Дерево классификатора для формы
  getClassTreeFormItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsClassTreeFormBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
    const root:any = data !== undefined && data.root !== undefined ? data.root : { id: 0, idx: 1, name: 'Нет'};
    const disabled:any = data !== undefined && data.disabled !== undefined && Array.isArray(data.disabled) ? data.disabled : [];

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      classApi.getClassItems(filter, sort, pagination)
        .then(
          (response: any) => {

            // Конвертируем данные для дерева
            const tree = storeBase.methods.convertClassItemsToTreeForForm(response, disabled)
            tree.unshift(root) // Добавляем узел 'Нет' для корневого класса
            commit('setClassTreeFormItems', tree);
            commit('setIsClassTreeFormBusy', false);
            resolve(tree);
          },
          (error: any) => {
            commit('setClassTreeFormItems', []);
            commit('setIsClassTreeFormBusy', false);
            reject(error);
          }
        );
    });
  },

  // Список элементов
  getClassItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsClassBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      classApi.getClassItems(filter, sort, pagination)
        .then(
          (response: any) => {
            commit('setClassItems', response);
            commit('setIsClassBusy', false);
            resolve(response);
          },
          (error: any) => {
            commit('setClassItems', []);
            commit('setIsClassBusy', false);
            reject(error);
          }
        );
    });
  },
/*
  // Получить элемент
  getClassItem({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsClassBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];

    return new Promise((resolve, reject) => {

      // Получаем элемент
      classApi.getClassItem(filter)
        .then(
          (response:any) => {
            commit('setClassItem', response);
            commit('setIsClassBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsClassBusy', false);
            reject(error);
          }
        );
    });
  },
*/
  // Создать элемент
  createClassItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsClassBusy', true);

    return new Promise((resolve, reject) => {

      // Создаем элемент
      classApi.createClassItem(data)
        .then(
          (response) => {
            commit('setIsClassBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsClassBusy', false);
            reject(error);
          }
        );
    });
  },

  // Обновить элемент
  updateClassItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsClassBusy', true);

    return new Promise((resolve, reject) => {

      // Обновляем элемент
      classApi.updateClassItem(data)
        .then(
          (response) => {
            commit('setIsClassBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsClassBusy', false);
            reject(error);
          }
        );
    });
  },

  // Удалить элемент
  deleteClassItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsClassBusy', true);

    return new Promise((resolve, reject) => {

      // Удаляем элемент
      classApi.deleteClassItem(data)
        .then(
          (response) => {
            commit('setIsClassBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsClassBusy', false);
            reject(error);
          }
        );
    });
  }
};

// Создаем модуль
const ClassModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default ClassModule;
