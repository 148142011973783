import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { AccountsSrcApi } from '../services/api/AccountsSrcApi'
import Loading from 'quasar/src/plugins/loading/Loading.js';

class State {
  isAccountsSrcBusy: boolean = false
  accountsSrcItems: Array<any> = []
  accountSrcItem: any = []
  accountSex: String
}

const accountsSrcApi = new AccountsSrcApi()

const getters = <GetterTree<State, any>>{
  isAccountsSrcBusy(state): boolean {
    return state.isAccountsSrcBusy
  },

  getAccountsSrcItems(state) {
    return state.accountsSrcItems
  },

  getAccountSrcItem(state) {
    return state.accountSrcItem
  },

  getAccountSex(state) {
    return state.accountSex
  },
}

const mutations = <MutationTree<State>>{
  setIsAccountsSrcBusy(state, payload: boolean) {
    state.isAccountsSrcBusy = payload
    if (payload) {
      Loading.show()
    } else {
      Loading.hide()
    }
  },

  setAccountsSrcItems(state, payload) {
    state.accountsSrcItems = payload
  },

  setAccountSrcItem(state, payload) {
    state.accountSrcItem = payload
  },

  setAccountSex(state, payload) {
    state.accountSex = payload
  },
}

const actions = <ActionTree<State, any>>{
  getAccountSrcItem({ commit }, data: any) {
    commit('setIsAccountsSrcBusy', true)
    console.log('ACCOUNT SRC ITEM FILTER', data)

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      accountsSrcApi.getAccountsSrcItem(data).then(
        (message: any) => {
          const item = message.getItem()

          commit('setAccountSrcItem', item)
          commit('setIsAccountsSrcBusy', false)

          resolve(item)
        },
        (error) => {
          commit('setAccountSrcItem', [])
          commit('setIsAccountsSrcBusy', false)
          reject(error)
        }
      )
    })
  },

  getAccountsSrcItems({ commit }, data: any) {
    const { sso_id = '' }: any = data

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      accountsSrcApi.getAccountsSrcListItems(sso_id).then(
        (message: any) => {
          const items = message.getItemsList()

          commit('setAccountsSrcItems', items)

          resolve({
            items,
          })
        },
        (error) => {
          commit('setAccountsSrcItems', [])
          reject(error)
        }
      )
    })
  },
}

const AccountsSrcModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default AccountsSrcModule
