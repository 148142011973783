import {
    MessageGrpClsServiceClient
} from "../../proto/pb/Message_grp_clsServiceClientPb";
import {
    MessageGrpClsIn,
    MessageGrpClsOut,
    ListMessageGrpClsInRequest,
    ListMessageGrpClsOutRequest,
    //ItemMessageGrpClsRequest,
    MessageGrpClsFilter,
    MessageGrpClsFilterItem,
    MessageGrpClsSort,
    MessageGrpClsPagination
} from "../../proto/pb/message_grp_cls_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 100;

export class MessageGrpClsApi {
    itemsIn: Array<MessageGrpClsIn> = [];
    itemsOut: Array<MessageGrpClsOut> = [];
    client: MessageGrpClsServiceClient;
    metadata: any;

    constructor() {
        const host:string = (window as any).VUE_APP_API_URL ?? '';
        this.client = new MessageGrpClsServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    // Установка и получение фильтров
    createFilter(data: any){

        const filter = new MessageGrpClsFilter();

        // Устанавливаем фильтры
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && ('' !== data[i].field_name)) {
                    const fItem = new MessageGrpClsFilterItem();
                    fItem.setFieldName(data[i].field_name.toString());
                    fItem.setOperand(data[i].operand !== undefined ? data[i].operand : '=');
                    fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                    fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                    filter.addItems(fItem);
                }
            }
        }

        return filter;
    }

    // Установка и получение сортировки
    createSort(data: any){

        const sort = new MessageGrpClsSort();
        const name:string = data.name !== undefined ? data.name.toString() : 'ag.message_grp_text';
        const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    // Установка и получение пагинации
    createPagination(data: any){

        const pagination = new MessageGrpClsPagination();
        const page:number = data.page !== undefined ? Number(data.page) : 1;
        const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
        const pages:number = data.pages !== undefined ? Number(data.pages) : 0;
        const cnt:number = data.cnt !== undefined ? Number(data.cnt) : 0;

        // Устанавливаем пагинацию
        pagination.setPage(page);
        pagination.setLimit(limit);
        pagination.setPages(pages);

        return pagination;
    }

    // Список элементов "Привязанные"
    getMessageGrpClsInItems(filter: any, sort: any, pagination: any, class_id: number) {

        const request = new ListMessageGrpClsInRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));
        request.setClassId(class_id);

        return this.client.listMessageGrpClsIn(request, this.metadata);
    }

    // Список элементов "Непривязанные"
    getMessageGrpClsOutItems(filter: any, sort: any, pagination: any, class_id: number, not_classifier_only: boolean) {
        const request = new ListMessageGrpClsOutRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));
        request.setClassId(class_id);
        request.setNotClassifierOnly(not_classifier_only);

        return this.client.listMessageGrpClsOut(request, this.metadata);
    }
}
