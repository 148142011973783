import { GetterTree, MutationTree, ActionTree } from "vuex";
import { SocialNetworkMessageClassApi } from "../services/api/SocialNetworkMessageClassApi";

class State {
    isSocialNetworkMessageClassBusy: boolean = false;
    socialNetworkMessageClassItems: unknown [];
    socialNetworkMessageClassItem: unknown;
}

// Создаем объект
const socialNetworkMessageClassApi = new SocialNetworkMessageClassApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

    isSocialNetworkMessageClassBusy(state): boolean {
        return state.isSocialNetworkMessageClassBusy;
    },

    getSocialNetworkMessageClassItems(state): unknown[] {
        return state.socialNetworkMessageClassItems;
    },

    getSocialNetworkMessageClassItem(state): unknown {
        return state.socialNetworkMessageClassItem;
    }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

    setIsSocialNetworkMessageClassBusy(state, payload: boolean) {
        state.isSocialNetworkMessageClassBusy = payload
    },

    setSocialNetworkMessageClassItems(state, payload: unknown[]) {
        state.socialNetworkMessageClassItems = payload
    },

    setSocialNetworkMessageClassItem(state, payload: unknown) {
        state.socialNetworkMessageClassItem = payload
    }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

    // Устанавливаем элемент
    setSocialNetworkMessageClassItem({ commit }, data: any) {
        commit('setSocialNetworkMessageClassItem', data);
    },

    // Список элементов (привязанные ответы)
    getSocialNetworkMessageClassItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageClassBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            socialNetworkMessageClassApi.getSocialNetworkMessageClassItems(filter, sort, pagination)
                .then(
                    (response:any) => {
                        commit('setSocialNetworkMessageClassItems', response);
                        commit('setIsSocialNetworkMessageClassBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setSocialNetworkMessageClassItems', []);
                        commit('setIsSocialNetworkMessageClassBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Создать элемент
    createSocialNetworkMessageClassItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageClassBusy', true);

        return new Promise((resolve, reject) => {

            // Создаем элемент
            socialNetworkMessageClassApi.createSocialNetworkMessageClassItem(data)
                .then(
                    (response) => {
                        commit('setIsSocialNetworkMessageClassBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsSocialNetworkMessageClassBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Обновить элемент
    updateSocialNetworkMessageClassItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageClassBusy', true);

        return new Promise((resolve, reject) => {

            // Обновляем элемент
            socialNetworkMessageClassApi.updateSocialNetworkMessageClassItem(data)
                .then(
                    (response) => {
                        commit('setIsSocialNetworkMessageClassBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsSocialNetworkMessageClassBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Удалить элемент
    deleteSocialNetworkMessageClassItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageClassBusy', true);

        return new Promise((resolve, reject) => {

            // Удаляем элемент
            socialNetworkMessageClassApi.deleteSocialNetworkMessageClassItem(data)
                .then(
                    (response) => {
                        commit('setIsSocialNetworkMessageClassBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsSocialNetworkMessageClassBusy', false);
                        reject(error);
                    }
                );
        });
    },

    saveSocialNetworkMessageClass({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageClassBusy', true);

        return new Promise((resolve, reject) => {

            // Сохраняем несколько элементов
            socialNetworkMessageClassApi.saveSocialNetworkMessageClass(data)
                .then(
                    (response:any) => {
                        commit('setIsSocialNetworkMessageClassBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsSocialNetworkMessageClassBusy', false);
                        reject(error);
                    }
                );
        });
    },

    removeSocialNetworkMessageClass({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageClassBusy', true);

        return new Promise((resolve, reject) => {

            // Сохраняем несколько элементов
            socialNetworkMessageClassApi.removeSocialNetworkMessageClass(data)
                .then(
                    (response:any) => {
                        commit('setIsSocialNetworkMessageClassBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsSocialNetworkMessageClassBusy', false);
                        reject(error);
                    }
                );
        });
    }
};

// Создаем модуль
const SocialNetworkMessageClassModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default SocialNetworkMessageClassModule;
