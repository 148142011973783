
import { defineComponent } from 'vue'
import ProjectsChoice from '@/components/ProjectsChoice.vue'
import GenderAgeStructureChart from '@/components/charts/GenderAgeStructureChart.vue'
import AutoAvailabilityChart from '@/components/charts/AutoAvailabilityChart.vue'
import PresenceAnimalsChart from '@/components/charts/PresenceAnimalsChart.vue'
import LevelEducationChart from '@/components/charts/LevelEducationChart.vue'
import MaritalStatusChart from '@/components/charts/MaritalStatusChart.vue'

export default defineComponent({
  name: 'SocialIndicators',

  components: {
    ProjectsChoice,
    GenderAgeStructureChart,
    AutoAvailabilityChart,
    PresenceAnimalsChart,
    LevelEducationChart,
    MaritalStatusChart,
  },

  data() {
    return {}
  },
})
