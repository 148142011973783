
import { defineComponent } from 'vue'
import ChaptersListVue from '../components/ChaptersList.vue'
import AddonList from "@/components/AddonList.vue";

export default defineComponent({
  name: 'HomePage',

  components: {
    AddonList,
    ChaptersListVue,
  },

  data() {
    return {}
  },
})
