import { DashboardActivListServiceClient } from '../../proto/pb/Activ_listServiceClientPb'
import {
    ListDashboardActivListRequest,
    DashboardActivListFilter,
    DashboardActivListFilterItem,
    DashboardActivListSort,
    DashboardActivListPagination
} from '../../proto/pb/activ_list_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class ActiveListApi {
    client: DashboardActivListServiceClient
    metadata: any

    constructor() {
        const host: string = (window as any).VUE_APP_API_URL ?? ''
        this.client = new DashboardActivListServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    createFilter(data: any) {
        const filter = new DashboardActivListFilter()
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && '' !== data[i].field_name) {
                    const fItem = new DashboardActivListFilterItem()
                    fItem.setFieldName(data[i].field_name.toString())
                    fItem.setOperand(
                        data[i].operand !== undefined ? data[i].operand : '='
                    )
                    fItem.setValue(
                        data[i].value !== undefined ? data[i].value.toString() : ''
                    )
                    fItem.setOr(this.createFilter(data[i].or)) // Рекурсия !!!
                    filter.addItems(fItem)
                }
            }
        }
        return filter
    }

    createSort(data: any) {
        const sort = new DashboardActivListSort()
        const name: string =
            data.name !== undefined ? data.name.toString() : ''
        const exp: string = data.exp !== undefined ? data.exp.toString() : 'asc'

        // Устанавливаем параметры сортировки
        sort.setName(name)
        sort.setExp(exp)

        return sort
    }

    createPagination(data: any) {
        const pagination = new DashboardActivListPagination()
        const page: number = data.page !== undefined ? Number(data.page) : 1
        const limit: number =
            data.limit !== undefined ? Number(data.limit) : 1000
        const pages: number = data.pages !== undefined ? Number(data.pages) : 0
        const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0

        // Устанавливаем пагинацию
        pagination.setPage(page)
        pagination.setLimit(limit)
        pagination.setPages(pages).setCnt(cnt)

        return pagination
    }

    getActiveList(filter: any, sort: any, pagination: any, data: any) {
        const request = new ListDashboardActivListRequest()

        request
            .setFilter(this.createFilter(filter))
            .setSort(this.createSort(sort))
            .setPagination(this.createPagination(pagination))
            .setProjectIdsList(data.project_ids)

        return this.client.listDashboardActivList(request, this.metadata)
    }
}