import { GetterTree, MutationTree, ActionTree } from "vuex";
import { ScorecardValueApi } from "../services/api/ScorecardValueApi";
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
  isScorecardValueBusy: boolean = false;
  scorecardValueItems: unknown [];
  scorecardValueItem: unknown;
}

// Создаем объект
const scorecardValueApi = new ScorecardValueApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

  isScorecardValueBusy(state): boolean {
    return state.isScorecardValueBusy;
  },

  getScorecardValueItems(state): unknown[] {
    return state.scorecardValueItems;
  },

  getScorecardValueItem(state): unknown {
    return state.scorecardValueItem;
  }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

  setIsScorecardValueBusy(state, payload: boolean) {
    state.isScorecardValueBusy = payload
    if(payload){
      Loading.show()
    }else{
      Loading.hide()
    }
  },

  setScorecardValueItems(state, payload: unknown[]) {
    state.scorecardValueItems = payload
  },

  setScorecardValueItem(state, payload: unknown) {
    state.scorecardValueItem = payload
  }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

  // Устанавливаем элемент
  setScorecardValueItem({ commit }, data: any) {
    commit('setScorecardValueItem', data);
  },
  
  // Список элементов
  getScorecardValueItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardValueBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      scorecardValueApi.getScorecardValueItems(filter, sort, pagination)
        .then(
          (response:any) => {
            commit('setScorecardValueItems', response);
            commit('setIsScorecardValueBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setScorecardValueItems', []);
            commit('setIsScorecardValueBusy', false);
            reject(error);
          }
        );
    });
  },

  // Получить элемент
  getScorecardValueItem({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardValueBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];

    return new Promise((resolve, reject) => {

      // Получаем элемент
      scorecardValueApi.getScorecardValueItem(filter)
        .then(
          (response:any) => {
            commit('setScorecardValueItem', response);
            commit('setIsScorecardValueBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsScorecardValueBusy', false);
            reject(error);
          }
        );
    });
  },

  // Создать элемент
  createScorecardValueItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardValueBusy', true);

    return new Promise((resolve, reject) => {

      // Создаем элемент
      scorecardValueApi.createScorecardValueItem(data)
        .then(
          (response) => {
            commit('setIsScorecardValueBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsScorecardValueBusy', false);
            reject(error);
          }
        );
    });
  },

  // Обновить элемент
  updateScorecardValueItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardValueBusy', true);

    return new Promise((resolve, reject) => {

      // Обновляем элемент
      scorecardValueApi.updateScorecardValueItem(data)
        .then(
          (response) => {
            commit('setIsScorecardValueBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsScorecardValueBusy', false);
            reject(error);
          }
        );
    });
  },

  // Удалить элемент
  deleteScorecardValueItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsScorecardValueBusy', true);

    return new Promise((resolve, reject) => {

      // Удаляем элемент
      scorecardValueApi.deleteScorecardValueItem(data)
        .then(
          (response) => {
            commit('setIsScorecardValueBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsScorecardValueBusy', false);
            reject(error);
          }
        );
    });
  }
};

// Создаем модуль
const ScorecardValueModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default ScorecardValueModule;
