
import store from '@/store'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'ClsAdd',

  components: {
    DataLoading,
  },

  data() {
    return {
      item: {
        name: '',
        descr: '',
        notes: '',
      },
    }
  },

  computed: {
    isClassifierBusy() {
      return store.getters.getIsClassifierBusy
    },
  },

  methods: {
    saveCls() {
      if (!this.item.name) {
        console.log('Наименование классификатора не введено')
        return false
      }

      let data = {
        name: this.item.name,
        descr: this.item.descr,
        notes: this.item.notes,
      }

      store.dispatch('createClassifierItem', data).then(
        (resolve) => {
          console.log('resolve', resolve)
          this.$emit('afterAction')
        },
        (reject) => {
          console.log('reject', reject)
        }
      )
      return true
    },

    closeClsAdd() {
      this.$emit('hideModal')
    },

    loadData() {
      this.item = {
        name: '',
        descr: '',
        notes: '',
      }
    },
  },

  created() {
    this.loadData()
  },
})
