import {
    SocialNetworkMessageGrpServiceClient
} from "../../proto/pb/Social_network_message_grpServiceClientPb";
import {
    SocialNetworkMessageGrp,
    SocialNetworkListMessageGrpRequest,
    SocialNetworkListMessageGrpWithFindMessageRequest,
    SocialNetworkListMessageGrpChildsRequest,
    SocialNetworkMessageGrpFilter,
    SocialNetworkMessageGrpFilterItem,
    SocialNetworkMessageGrpSort,
    SocialNetworkMessageGrpPagination,
    SocialNetworkCreateMessageGrpRequest,
    SocialNetworkUpdateMessageGrpRequest,
    SocialNetworkUpdateTextMessageGrpRequest,
    SocialNetworkDeleteMessageGrpRequest,
    SocialNetworkReduceMessageGrpRequest,
    SocialNetworkSplitMessageGrpRequest,
    SocialNetworkSeparateMessageGrpRequest,
} from "../../proto/pb/social_network_message_grp_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 1000;

export class SocialNetworkMessageGrpApi {
    items: Array<SocialNetworkMessageGrp> = [];
    client: SocialNetworkMessageGrpServiceClient;
    metadata: any;

    constructor() {
        const host:string = (window as any).VUE_APP_API_URL ?? '';
        this.client = new SocialNetworkMessageGrpServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    // Установка и получение фильтров
    createFilter(data: any){

        const filter = new SocialNetworkMessageGrpFilter();

        // Устанавливаем фильтры
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && ('' !== data[i].field_name)) {
                    const fItem = new SocialNetworkMessageGrpFilterItem();
                    fItem.setFieldName(data[i].field_name.toString());
                    fItem.setOperand(data[i].operand !== undefined ? data[i].operand : '=');
                    fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                    fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                    filter.addItems(fItem);
                }
            }
        }

        return filter;
    }

    // Установка и получение сортировки
    createSort(data: any){

        const sort = new SocialNetworkMessageGrpSort();
        const name:string = data.name !== undefined ? data.name.toString() : 'ag.answer_grp_text';
        const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    // Установка и получение пагинации
    createPagination(data: any){

        const pagination = new SocialNetworkMessageGrpPagination();
        const page:number = data.page !== undefined ? Number(data.page) : 1;
        const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
        const pages:number = data.pages !== undefined ? Number(data.pages) : 0;
        const cnt:number = data.cnt !== undefined ? Number(data.cnt) : 0;

        // Устанавливаем пагинацию
        pagination.setPage(page);
        pagination.setLimit(limit);
        pagination.setPages(pages);

        return pagination;
    }

    // Список элементов (результирующая таблица, вместе с реальным hasChilds)
    getSocialNetworkMessageGrpResultItems(filter: any, sort: any, pagination: any, question_id: number){

        const request = new SocialNetworkListMessageGrpRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));
        request.setIsWorked(false);

        return this.client.socialNetworkListMessageGrp(request, this.metadata);
    }

    // Список элементов (результирующая таблица, вместе с реальным hasChilds)
    // с поиском страницы для заданного ответа
    getSocialNetworkMessageGrpWithFindAnswerResultItems(filter: any, sort: any, pagination: number, question_id: number, find_id: number){

        const request = new SocialNetworkListMessageGrpWithFindMessageRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));
        request.setMessageGrpId(find_id);

        return this.client.socialNetworkListMessageGrpWithFindMessage(request, this.metadata);
    }

    // Список дочерних элементов (часть результирующей таблицы, с hasChilds = false)
    getSocialNetworkMessageGrpChildsItems(filter: any, sort: any, pagination: any, parent_id: number){

        const request = new SocialNetworkListMessageGrpChildsRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));
        request.setMessageGrpId(parent_id);

        return this.client.socialNetworkListMessageGrpChilds(request, this.metadata);
    }

    // Список элементов (таблица для обработки, с hasChilds = false)
    getSocialNetworkMessageGrpWorkedItems(filter: any, sort: any, pagination: any, question_id: number){

        const request = new SocialNetworkListMessageGrpRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));
        request.setIsWorked(true);

        return this.client.socialNetworkListMessageGrp(request, this.metadata);
    }

    // Создание элемента
    createSocialNetworkMessageGrpItem(data: any){

        const request = new SocialNetworkCreateMessageGrpRequest();
        request.setMessageGrpParentId(data.parent_id);
        request.setMessageId(data.answer_id);
        request.setMessageGrpText(data.text);
        request.setMessageGrpCount(data.count);
        request.setMessageGrpProcessed(data.processed);

        return this.client.socialNetworkCreateMessageGrp(request, this.metadata);
    }

    // Редактирование элемента
    updateSocialNetworkMessageGrpItem(data: any){

        const request = new SocialNetworkUpdateMessageGrpRequest();
        request.setMessageGrpId(data.id);
        request.setMessageGrpParentId(data.parent_id);
        request.setMessageId(data.answer_id);
        request.setMessageGrpText(data.text);
        request.setMessageGrpCount(data.count);
        request.setMessageGrpProcessed(data.processed);

        return this.client.socialNetworkUpdateMessageGrp(request, this.metadata);
    }

    // Редактирование текста элемента
    updateSocialNetworkTextMessageGrpItem(data: any){

        const request = new SocialNetworkUpdateTextMessageGrpRequest();
        request.setMessageGrpId(data.id);
        request.setMessageGrpText(data.text);

        return this.client.socialNetworkUpdateTextMessageGrp(request, this.metadata);
    }

    // Удаление элемента
    deleteSocialNetworkMessageGrpItem(data: any){

        const request = new SocialNetworkDeleteMessageGrpRequest();
        request.setMessageGrpId(data.id);

        return this.client.socialNetworkDeleteMessageGrp(request, this.metadata);
    }

    // Сведение ответов к одному (reduce)
    reduceSocialNetworkMessageGrpItems(data: any){

        const request = new SocialNetworkReduceMessageGrpRequest();
        request.setMessageGrpId(data.id);
        request.setReducedIdsList(data.reduced_ids);

        return this.client.socialNetworkReduceMessageGrp(request, this.metadata);
    }

    // Разбиение ответов по разделителю (split)
    splitSocialNetworkMessageGrpItems(data: any){

        const request = new SocialNetworkSplitMessageGrpRequest();
        request.setSplitString(data.split_string);
        request.setSplittedIdsList(data.splitted_ids);

        return this.client.socialNetworkSplitMessageGrp(request, this.metadata);
    }

    separateSocialNetworkMessageGrpItems(data: any) {
        const request = new SocialNetworkSeparateMessageGrpRequest()

        request
            .setSeparatedIdsList(data.separated_ids_list)

        console.log('SEPARATE MESSAGE REQ', request)

        return this.client.socialNetworkSeparateMessageGrp(request, this.metadata)
    }
}
