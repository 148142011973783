import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { PointsApi } from '../../services/api/PointsApi'

class State {
  countPointSegm: any
  isCountPointSegmBusy: Boolean
}

const pointsApi = new PointsApi()

const getters = <GetterTree<State, any>>{
  getCountPointSegm(state) {
    return state.countPointSegm
  },

  isCountPointSegmBusy(state) {
    return state.isCountPointSegmBusy
  },
}

const mutations = <MutationTree<State>>{
  setCountPointSegm(state, payload) {
    state.countPointSegm = payload
  },

  setIsCountPointSegmBusy(state, payload) {
    state.isCountPointSegmBusy = payload
  },
}

const actions = <ActionTree<State, any>>{
  getCountPointSegm({ commit }, filter) {
    console.log(
      '🚀 ~ file: countPointSegm.ts:33 ~ getCountPointSegm ~ filter:',
      filter
    )
    commit('setIsCountPointSegmBusy', true)

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      pointsApi.getCountPointSegmentation(filter).then(
        (message: any) => {
          const item = message

          commit('setCountPointSegm', item)
          commit('setIsCountPointSegmBusy', false)

          resolve(item)
        },
        (error) => {
          commit('setIsCountPointSegmBusy', false)

          reject(error)
        }
      )
    })
  },
}

const countPointSegm = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default countPointSegm
