import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tree = _resolveComponent("q-tree")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    class: "q-pa-md q-mb-md column",
    style: {"max-height":"300px","overflow":"auto"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_tree, {
        ref: "treeRef",
        class: "col-12 col-sm-6",
        nodes: _ctx.getMetricItems,
        ticked: _ctx.ticked,
        "onUpdate:ticked": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ticked) = $event)),
          _ctx.updateTicked
        ],
        expanded: _ctx.expanded,
        "onUpdate:expanded": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.expanded) = $event)),
        "node-key": "key",
        "tick-strategy": "leaf"
      }, null, 8, ["nodes", "ticked", "expanded", "onUpdate:ticked"])
    ]),
    _: 1
  }))
}