/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: message_grp_cls.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as message_grp_cls_pb from './message_grp_cls_pb';


export class MessageGrpClsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListMessageGrpClsIn = new grpcWeb.MethodDescriptor(
    '/proto.MessageGrpClsService/ListMessageGrpClsIn',
    grpcWeb.MethodType.UNARY,
    message_grp_cls_pb.ListMessageGrpClsInRequest,
    message_grp_cls_pb.ListMessageGrpClsInResponse,
    (request: message_grp_cls_pb.ListMessageGrpClsInRequest) => {
      return request.serializeBinary();
    },
    message_grp_cls_pb.ListMessageGrpClsInResponse.deserializeBinary
  );

  listMessageGrpClsIn(
    request: message_grp_cls_pb.ListMessageGrpClsInRequest,
    metadata: grpcWeb.Metadata | null): Promise<message_grp_cls_pb.ListMessageGrpClsInResponse>;

  listMessageGrpClsIn(
    request: message_grp_cls_pb.ListMessageGrpClsInRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_grp_cls_pb.ListMessageGrpClsInResponse) => void): grpcWeb.ClientReadableStream<message_grp_cls_pb.ListMessageGrpClsInResponse>;

  listMessageGrpClsIn(
    request: message_grp_cls_pb.ListMessageGrpClsInRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_grp_cls_pb.ListMessageGrpClsInResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageGrpClsService/ListMessageGrpClsIn',
        request,
        metadata || {},
        this.methodDescriptorListMessageGrpClsIn,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageGrpClsService/ListMessageGrpClsIn',
    request,
    metadata || {},
    this.methodDescriptorListMessageGrpClsIn);
  }

  methodDescriptorListMessageGrpClsOut = new grpcWeb.MethodDescriptor(
    '/proto.MessageGrpClsService/ListMessageGrpClsOut',
    grpcWeb.MethodType.UNARY,
    message_grp_cls_pb.ListMessageGrpClsOutRequest,
    message_grp_cls_pb.ListMessageGrpClsOutResponse,
    (request: message_grp_cls_pb.ListMessageGrpClsOutRequest) => {
      return request.serializeBinary();
    },
    message_grp_cls_pb.ListMessageGrpClsOutResponse.deserializeBinary
  );

  listMessageGrpClsOut(
    request: message_grp_cls_pb.ListMessageGrpClsOutRequest,
    metadata: grpcWeb.Metadata | null): Promise<message_grp_cls_pb.ListMessageGrpClsOutResponse>;

  listMessageGrpClsOut(
    request: message_grp_cls_pb.ListMessageGrpClsOutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_grp_cls_pb.ListMessageGrpClsOutResponse) => void): grpcWeb.ClientReadableStream<message_grp_cls_pb.ListMessageGrpClsOutResponse>;

  listMessageGrpClsOut(
    request: message_grp_cls_pb.ListMessageGrpClsOutRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_grp_cls_pb.ListMessageGrpClsOutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageGrpClsService/ListMessageGrpClsOut',
        request,
        metadata || {},
        this.methodDescriptorListMessageGrpClsOut,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageGrpClsService/ListMessageGrpClsOut',
    request,
    metadata || {},
    this.methodDescriptorListMessageGrpClsOut);
  }

}

