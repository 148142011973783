import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { ActivityValueApi } from '../../services/api/UserLoyalty/ActivityValueApi'

class State {
  activeCoreValues: any
  isActiveCoreBusy: Boolean
}

const activityApi = new ActivityValueApi()

const getters = <GetterTree<State, any>>{
  getActiveCoreValues(state) {
    return state.activeCoreValues
  },

  isActiveCoreBusy(state) {
    return state.isActiveCoreBusy
  },
}

const mutations = <MutationTree<State>>{
  setActiveCoreValue(state, payload) {
    state.activeCoreValues = payload
  },

  setIsActiveCoreBusy(state, payload) {
    state.isActiveCoreBusy = payload
  },
}

const actions = <ActionTree<State, any>>{
  getActiveCore({ commit }, filter) {
    console.log('🚀 ~ file: activeCore.ts:33 ~ getActiveCore ~ filter:', filter)
    commit('setIsActiveCoreBusy', true)

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      activityApi.getActiveCoreValue(filter).then(
        (message: any) => {
          const item = message.getItem()

          commit('setActiveCoreValue', item)
          commit('setIsActiveCoreBusy', false)

          resolve(item)
        },
        (error) => {
          commit('setIsActiveCoreBusy', false)

          reject(error)
        }
      )
    })
  },
}

const activeCoreModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default activeCoreModule
