<template>
  <div class="q-pa-md">
    <div class="text-h4 q-pb-md">Опросы АГ</div>
    <RPList />
  </div>
</template>

<script>
import RPList from "@/components/RPList.vue";

export default {
  name: "RespAG",
  components: {RPList},
}
</script>

<style scoped>

</style>