// source: answer_grp_cls_form.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.proto.AnswerGrpClsFormFilter', null, global);
goog.exportSymbol('proto.proto.AnswerGrpClsFormFilterItem', null, global);
goog.exportSymbol('proto.proto.AnswerGrpClsPollForm', null, global);
goog.exportSymbol('proto.proto.AnswerGrpClsQuestionForm', null, global);
goog.exportSymbol('proto.proto.ListAnswerGrpClsPollFormRequest', null, global);
goog.exportSymbol('proto.proto.ListAnswerGrpClsPollFormResponse', null, global);
goog.exportSymbol('proto.proto.ListAnswerGrpClsQuestionFormRequest', null, global);
goog.exportSymbol('proto.proto.ListAnswerGrpClsQuestionFormResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpClsQuestionForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerGrpClsQuestionForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpClsQuestionForm.displayName = 'proto.proto.AnswerGrpClsQuestionForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpClsPollForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerGrpClsPollForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpClsPollForm.displayName = 'proto.proto.AnswerGrpClsPollForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpClsFormFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerGrpClsFormFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpClsFormFilterItem.displayName = 'proto.proto.AnswerGrpClsFormFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpClsFormFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AnswerGrpClsFormFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.AnswerGrpClsFormFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpClsFormFilter.displayName = 'proto.proto.AnswerGrpClsFormFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerGrpClsQuestionFormRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListAnswerGrpClsQuestionFormRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerGrpClsQuestionFormRequest.displayName = 'proto.proto.ListAnswerGrpClsQuestionFormRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerGrpClsQuestionFormResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListAnswerGrpClsQuestionFormResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListAnswerGrpClsQuestionFormResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerGrpClsQuestionFormResponse.displayName = 'proto.proto.ListAnswerGrpClsQuestionFormResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerGrpClsPollFormRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListAnswerGrpClsPollFormRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerGrpClsPollFormRequest.displayName = 'proto.proto.ListAnswerGrpClsPollFormRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerGrpClsPollFormResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListAnswerGrpClsPollFormResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListAnswerGrpClsPollFormResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerGrpClsPollFormResponse.displayName = 'proto.proto.ListAnswerGrpClsPollFormResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpClsQuestionForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpClsQuestionForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsQuestionForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    classifierName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pollGroupTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pollTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    questionStitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    classAnswerGrpSum: jspb.Message.getFieldWithDefault(msg, 5, 0),
    classAnswerGrpCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    className: jspb.Message.getFieldWithDefault(msg, 7, ""),
    classDescr: jspb.Message.getFieldWithDefault(msg, 8, ""),
    classNotes: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpClsQuestionForm}
 */
proto.proto.AnswerGrpClsQuestionForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpClsQuestionForm;
  return proto.proto.AnswerGrpClsQuestionForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpClsQuestionForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpClsQuestionForm}
 */
proto.proto.AnswerGrpClsQuestionForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassifierName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPollGroupTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPollTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionStitle(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassAnswerGrpSum(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassAnswerGrpCount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassDescr(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpClsQuestionForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpClsQuestionForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsQuestionForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClassifierName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPollGroupTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPollTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuestionStitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClassAnswerGrpSum();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getClassAnswerGrpCount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getClassName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getClassDescr();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getClassNotes();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string classifier_name = 1;
 * @return {string}
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.getClassifierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsQuestionForm} returns this
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.setClassifierName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string poll_group_title = 2;
 * @return {string}
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.getPollGroupTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsQuestionForm} returns this
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.setPollGroupTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string poll_title = 3;
 * @return {string}
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.getPollTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsQuestionForm} returns this
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.setPollTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string question_stitle = 4;
 * @return {string}
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.getQuestionStitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsQuestionForm} returns this
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.setQuestionStitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 class_answer_grp_sum = 5;
 * @return {number}
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.getClassAnswerGrpSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsQuestionForm} returns this
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.setClassAnswerGrpSum = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 class_answer_grp_count = 6;
 * @return {number}
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.getClassAnswerGrpCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsQuestionForm} returns this
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.setClassAnswerGrpCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string class_name = 7;
 * @return {string}
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.getClassName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsQuestionForm} returns this
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.setClassName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string class_descr = 8;
 * @return {string}
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.getClassDescr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsQuestionForm} returns this
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.setClassDescr = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string class_notes = 9;
 * @return {string}
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.getClassNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsQuestionForm} returns this
 */
proto.proto.AnswerGrpClsQuestionForm.prototype.setClassNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpClsPollForm.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpClsPollForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpClsPollForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsPollForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    classifierName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pollGroupTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pollTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    classAnswerGrpSum: jspb.Message.getFieldWithDefault(msg, 4, 0),
    classAnswerGrpCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    className: jspb.Message.getFieldWithDefault(msg, 6, ""),
    classDescr: jspb.Message.getFieldWithDefault(msg, 7, ""),
    classNotes: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpClsPollForm}
 */
proto.proto.AnswerGrpClsPollForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpClsPollForm;
  return proto.proto.AnswerGrpClsPollForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpClsPollForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpClsPollForm}
 */
proto.proto.AnswerGrpClsPollForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassifierName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPollGroupTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPollTitle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassAnswerGrpSum(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassAnswerGrpCount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassDescr(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpClsPollForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpClsPollForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpClsPollForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsPollForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClassifierName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPollGroupTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPollTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClassAnswerGrpSum();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getClassAnswerGrpCount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getClassName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getClassDescr();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getClassNotes();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string classifier_name = 1;
 * @return {string}
 */
proto.proto.AnswerGrpClsPollForm.prototype.getClassifierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsPollForm} returns this
 */
proto.proto.AnswerGrpClsPollForm.prototype.setClassifierName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string poll_group_title = 2;
 * @return {string}
 */
proto.proto.AnswerGrpClsPollForm.prototype.getPollGroupTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsPollForm} returns this
 */
proto.proto.AnswerGrpClsPollForm.prototype.setPollGroupTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string poll_title = 3;
 * @return {string}
 */
proto.proto.AnswerGrpClsPollForm.prototype.getPollTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsPollForm} returns this
 */
proto.proto.AnswerGrpClsPollForm.prototype.setPollTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 class_answer_grp_sum = 4;
 * @return {number}
 */
proto.proto.AnswerGrpClsPollForm.prototype.getClassAnswerGrpSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsPollForm} returns this
 */
proto.proto.AnswerGrpClsPollForm.prototype.setClassAnswerGrpSum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 class_answer_grp_count = 5;
 * @return {number}
 */
proto.proto.AnswerGrpClsPollForm.prototype.getClassAnswerGrpCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsPollForm} returns this
 */
proto.proto.AnswerGrpClsPollForm.prototype.setClassAnswerGrpCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string class_name = 6;
 * @return {string}
 */
proto.proto.AnswerGrpClsPollForm.prototype.getClassName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsPollForm} returns this
 */
proto.proto.AnswerGrpClsPollForm.prototype.setClassName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string class_descr = 7;
 * @return {string}
 */
proto.proto.AnswerGrpClsPollForm.prototype.getClassDescr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsPollForm} returns this
 */
proto.proto.AnswerGrpClsPollForm.prototype.setClassDescr = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string class_notes = 8;
 * @return {string}
 */
proto.proto.AnswerGrpClsPollForm.prototype.getClassNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsPollForm} returns this
 */
proto.proto.AnswerGrpClsPollForm.prototype.setClassNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpClsFormFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpClsFormFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpClsFormFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsFormFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.AnswerGrpClsFormFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpClsFormFilterItem}
 */
proto.proto.AnswerGrpClsFormFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpClsFormFilterItem;
  return proto.proto.AnswerGrpClsFormFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpClsFormFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpClsFormFilterItem}
 */
proto.proto.AnswerGrpClsFormFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.AnswerGrpClsFormFilter;
      reader.readMessage(value,proto.proto.AnswerGrpClsFormFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpClsFormFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpClsFormFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpClsFormFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsFormFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.AnswerGrpClsFormFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.AnswerGrpClsFormFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsFormFilterItem} returns this
 */
proto.proto.AnswerGrpClsFormFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.AnswerGrpClsFormFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsFormFilterItem} returns this
 */
proto.proto.AnswerGrpClsFormFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.AnswerGrpClsFormFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsFormFilterItem} returns this
 */
proto.proto.AnswerGrpClsFormFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AnswerGrpClsFormFilter or = 4;
 * @return {?proto.proto.AnswerGrpClsFormFilter}
 */
proto.proto.AnswerGrpClsFormFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.AnswerGrpClsFormFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpClsFormFilter, 4));
};


/**
 * @param {?proto.proto.AnswerGrpClsFormFilter|undefined} value
 * @return {!proto.proto.AnswerGrpClsFormFilterItem} returns this
*/
proto.proto.AnswerGrpClsFormFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AnswerGrpClsFormFilterItem} returns this
 */
proto.proto.AnswerGrpClsFormFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AnswerGrpClsFormFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AnswerGrpClsFormFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpClsFormFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpClsFormFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpClsFormFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsFormFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AnswerGrpClsFormFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpClsFormFilter}
 */
proto.proto.AnswerGrpClsFormFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpClsFormFilter;
  return proto.proto.AnswerGrpClsFormFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpClsFormFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpClsFormFilter}
 */
proto.proto.AnswerGrpClsFormFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpClsFormFilterItem;
      reader.readMessage(value,proto.proto.AnswerGrpClsFormFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpClsFormFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpClsFormFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpClsFormFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsFormFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AnswerGrpClsFormFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnswerGrpClsFormFilterItem items = 1;
 * @return {!Array<!proto.proto.AnswerGrpClsFormFilterItem>}
 */
proto.proto.AnswerGrpClsFormFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AnswerGrpClsFormFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AnswerGrpClsFormFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.AnswerGrpClsFormFilterItem>} value
 * @return {!proto.proto.AnswerGrpClsFormFilter} returns this
*/
proto.proto.AnswerGrpClsFormFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AnswerGrpClsFormFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AnswerGrpClsFormFilterItem}
 */
proto.proto.AnswerGrpClsFormFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AnswerGrpClsFormFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AnswerGrpClsFormFilter} returns this
 */
proto.proto.AnswerGrpClsFormFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerGrpClsQuestionFormRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerGrpClsQuestionFormRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerGrpClsQuestionFormRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpClsQuestionFormRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.AnswerGrpClsFormFilter.toObject(includeInstance, f),
    classifierId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    questionId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerGrpClsQuestionFormRequest}
 */
proto.proto.ListAnswerGrpClsQuestionFormRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerGrpClsQuestionFormRequest;
  return proto.proto.ListAnswerGrpClsQuestionFormRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerGrpClsQuestionFormRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerGrpClsQuestionFormRequest}
 */
proto.proto.ListAnswerGrpClsQuestionFormRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpClsFormFilter;
      reader.readMessage(value,proto.proto.AnswerGrpClsFormFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassifierId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuestionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerGrpClsQuestionFormRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerGrpClsQuestionFormRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerGrpClsQuestionFormRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpClsQuestionFormRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.AnswerGrpClsFormFilter.serializeBinaryToWriter
    );
  }
  f = message.getClassifierId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getQuestionId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional AnswerGrpClsFormFilter filter = 1;
 * @return {?proto.proto.AnswerGrpClsFormFilter}
 */
proto.proto.ListAnswerGrpClsQuestionFormRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.AnswerGrpClsFormFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpClsFormFilter, 1));
};


/**
 * @param {?proto.proto.AnswerGrpClsFormFilter|undefined} value
 * @return {!proto.proto.ListAnswerGrpClsQuestionFormRequest} returns this
*/
proto.proto.ListAnswerGrpClsQuestionFormRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpClsQuestionFormRequest} returns this
 */
proto.proto.ListAnswerGrpClsQuestionFormRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpClsQuestionFormRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 classifier_id = 2;
 * @return {number}
 */
proto.proto.ListAnswerGrpClsQuestionFormRequest.prototype.getClassifierId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListAnswerGrpClsQuestionFormRequest} returns this
 */
proto.proto.ListAnswerGrpClsQuestionFormRequest.prototype.setClassifierId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 question_id = 3;
 * @return {number}
 */
proto.proto.ListAnswerGrpClsQuestionFormRequest.prototype.getQuestionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListAnswerGrpClsQuestionFormRequest} returns this
 */
proto.proto.ListAnswerGrpClsQuestionFormRequest.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListAnswerGrpClsQuestionFormResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerGrpClsQuestionFormResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerGrpClsQuestionFormResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerGrpClsQuestionFormResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpClsQuestionFormResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AnswerGrpClsQuestionForm.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListAnswerGrpClsQuestionFormRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerGrpClsQuestionFormResponse}
 */
proto.proto.ListAnswerGrpClsQuestionFormResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerGrpClsQuestionFormResponse;
  return proto.proto.ListAnswerGrpClsQuestionFormResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerGrpClsQuestionFormResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerGrpClsQuestionFormResponse}
 */
proto.proto.ListAnswerGrpClsQuestionFormResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpClsQuestionForm;
      reader.readMessage(value,proto.proto.AnswerGrpClsQuestionForm.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListAnswerGrpClsQuestionFormRequest;
      reader.readMessage(value,proto.proto.ListAnswerGrpClsQuestionFormRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerGrpClsQuestionFormResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerGrpClsQuestionFormResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerGrpClsQuestionFormResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpClsQuestionFormResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AnswerGrpClsQuestionForm.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListAnswerGrpClsQuestionFormRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnswerGrpClsQuestionForm items = 1;
 * @return {!Array<!proto.proto.AnswerGrpClsQuestionForm>}
 */
proto.proto.ListAnswerGrpClsQuestionFormResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AnswerGrpClsQuestionForm>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AnswerGrpClsQuestionForm, 1));
};


/**
 * @param {!Array<!proto.proto.AnswerGrpClsQuestionForm>} value
 * @return {!proto.proto.ListAnswerGrpClsQuestionFormResponse} returns this
*/
proto.proto.ListAnswerGrpClsQuestionFormResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AnswerGrpClsQuestionForm=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AnswerGrpClsQuestionForm}
 */
proto.proto.ListAnswerGrpClsQuestionFormResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AnswerGrpClsQuestionForm, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListAnswerGrpClsQuestionFormResponse} returns this
 */
proto.proto.ListAnswerGrpClsQuestionFormResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListAnswerGrpClsQuestionFormRequest params = 2;
 * @return {?proto.proto.ListAnswerGrpClsQuestionFormRequest}
 */
proto.proto.ListAnswerGrpClsQuestionFormResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListAnswerGrpClsQuestionFormRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListAnswerGrpClsQuestionFormRequest, 2));
};


/**
 * @param {?proto.proto.ListAnswerGrpClsQuestionFormRequest|undefined} value
 * @return {!proto.proto.ListAnswerGrpClsQuestionFormResponse} returns this
*/
proto.proto.ListAnswerGrpClsQuestionFormResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpClsQuestionFormResponse} returns this
 */
proto.proto.ListAnswerGrpClsQuestionFormResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpClsQuestionFormResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerGrpClsPollFormRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerGrpClsPollFormRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerGrpClsPollFormRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpClsPollFormRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.AnswerGrpClsFormFilter.toObject(includeInstance, f),
    classifierId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pollId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerGrpClsPollFormRequest}
 */
proto.proto.ListAnswerGrpClsPollFormRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerGrpClsPollFormRequest;
  return proto.proto.ListAnswerGrpClsPollFormRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerGrpClsPollFormRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerGrpClsPollFormRequest}
 */
proto.proto.ListAnswerGrpClsPollFormRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpClsFormFilter;
      reader.readMessage(value,proto.proto.AnswerGrpClsFormFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassifierId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPollId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerGrpClsPollFormRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerGrpClsPollFormRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerGrpClsPollFormRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpClsPollFormRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.AnswerGrpClsFormFilter.serializeBinaryToWriter
    );
  }
  f = message.getClassifierId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPollId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional AnswerGrpClsFormFilter filter = 1;
 * @return {?proto.proto.AnswerGrpClsFormFilter}
 */
proto.proto.ListAnswerGrpClsPollFormRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.AnswerGrpClsFormFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpClsFormFilter, 1));
};


/**
 * @param {?proto.proto.AnswerGrpClsFormFilter|undefined} value
 * @return {!proto.proto.ListAnswerGrpClsPollFormRequest} returns this
*/
proto.proto.ListAnswerGrpClsPollFormRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpClsPollFormRequest} returns this
 */
proto.proto.ListAnswerGrpClsPollFormRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpClsPollFormRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 classifier_id = 2;
 * @return {number}
 */
proto.proto.ListAnswerGrpClsPollFormRequest.prototype.getClassifierId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListAnswerGrpClsPollFormRequest} returns this
 */
proto.proto.ListAnswerGrpClsPollFormRequest.prototype.setClassifierId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 poll_id = 3;
 * @return {number}
 */
proto.proto.ListAnswerGrpClsPollFormRequest.prototype.getPollId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListAnswerGrpClsPollFormRequest} returns this
 */
proto.proto.ListAnswerGrpClsPollFormRequest.prototype.setPollId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListAnswerGrpClsPollFormResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerGrpClsPollFormResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerGrpClsPollFormResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerGrpClsPollFormResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpClsPollFormResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AnswerGrpClsPollForm.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListAnswerGrpClsPollFormRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerGrpClsPollFormResponse}
 */
proto.proto.ListAnswerGrpClsPollFormResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerGrpClsPollFormResponse;
  return proto.proto.ListAnswerGrpClsPollFormResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerGrpClsPollFormResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerGrpClsPollFormResponse}
 */
proto.proto.ListAnswerGrpClsPollFormResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpClsPollForm;
      reader.readMessage(value,proto.proto.AnswerGrpClsPollForm.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListAnswerGrpClsPollFormRequest;
      reader.readMessage(value,proto.proto.ListAnswerGrpClsPollFormRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerGrpClsPollFormResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerGrpClsPollFormResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerGrpClsPollFormResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpClsPollFormResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AnswerGrpClsPollForm.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListAnswerGrpClsPollFormRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnswerGrpClsPollForm items = 1;
 * @return {!Array<!proto.proto.AnswerGrpClsPollForm>}
 */
proto.proto.ListAnswerGrpClsPollFormResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AnswerGrpClsPollForm>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AnswerGrpClsPollForm, 1));
};


/**
 * @param {!Array<!proto.proto.AnswerGrpClsPollForm>} value
 * @return {!proto.proto.ListAnswerGrpClsPollFormResponse} returns this
*/
proto.proto.ListAnswerGrpClsPollFormResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AnswerGrpClsPollForm=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AnswerGrpClsPollForm}
 */
proto.proto.ListAnswerGrpClsPollFormResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AnswerGrpClsPollForm, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListAnswerGrpClsPollFormResponse} returns this
 */
proto.proto.ListAnswerGrpClsPollFormResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListAnswerGrpClsPollFormRequest params = 2;
 * @return {?proto.proto.ListAnswerGrpClsPollFormRequest}
 */
proto.proto.ListAnswerGrpClsPollFormResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListAnswerGrpClsPollFormRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListAnswerGrpClsPollFormRequest, 2));
};


/**
 * @param {?proto.proto.ListAnswerGrpClsPollFormRequest|undefined} value
 * @return {!proto.proto.ListAnswerGrpClsPollFormResponse} returns this
*/
proto.proto.ListAnswerGrpClsPollFormResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpClsPollFormResponse} returns this
 */
proto.proto.ListAnswerGrpClsPollFormResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpClsPollFormResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
