import SqlQueryBuilder from '../views/sql-builder/SqlQueryBuilder.vue'

const routes = [
    {
        path: '/sql-builder',
        name: 'SqlQueryBuilder',
        component: SqlQueryBuilder,
        meta: {
            breadCrumb: [
              { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
              { text: 'Конструктор запросов' },
            ]
          },
    },
]

export default {
    routes
}