import { GetterTree, MutationTree, ActionTree } from "vuex";
import { PollGroupApi } from "../services/api/PollGroupApi";
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
  isPollGroupBusy: boolean = false;
  pollGroupItems: unknown [];
  pollGroupItem: unknown;
}

// Создаем объект
const pollGroupApi = new PollGroupApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

  isPollGroupBusy(state): boolean {
    return state.isPollGroupBusy;
  },

  getPollGroupItems(state): unknown[] {
    return state.pollGroupItems;
  },

  getPollGroupItem(state): unknown {
    return state.pollGroupItem;
  }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

  setIsPollGroupBusy(state, payload: boolean) {
    state.isPollGroupBusy = payload
  },

  setPollGroupItems(state, payload: unknown[]) {
    state.pollGroupItems = payload
  },

  setPollGroupItem(state, payload: unknown) {
    state.pollGroupItem = payload
  }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

  // Устанавливаем элемент
  setPollGroupItem({ commit }, data: any) {
    commit('setPollGroupItem', data);
  },
  
  // Устанавливаем список
  setPollGroupItems({ commit }, data: any) {
    commit('setPollGroupItems', data);
  },
  
  // Список элементов
  getPollGroupItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsPollGroupBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      pollGroupApi.getPollGroupItems(filter, sort, pagination)
        .then(
          (message:any) => {
            const items = message.getItemsList();
            const sort = message.getParams().getSort();
            const pagination = message.getParams().getPagination();
            commit('setPollGroupItems', items);
            commit('setIsPollGroupBusy', false);
            resolve({
                items: items,
                sort: sort,
                pagination: pagination
            });
          },
          (error) => {
            commit('setIsPollGroupBusy', false);
            reject(error);
          }
        );
    });
  },

  // Получить элемент
  getPollGroupItem({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsPollGroupBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];

    return new Promise((resolve, reject) => {

      // Получаем элемент
      pollGroupApi.getPollGroupItem(filter)
        .then(
          (response:any) => {
            commit('setPollGroupItem', response);
            commit('setIsPollGroupBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsPollGroupBusy', false);
            reject(error);
          }
        );
    });
  },

  // Создать элемент
  createPollGroupItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsPollGroupBusy', true);

    return new Promise((resolve, reject) => {

      // Создаем элемент
      pollGroupApi.createPollGroupItem(data)
        .then(
          (response) => {
            commit('setIsPollGroupBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsPollGroupBusy', false);
            reject(error);
          }
        );
    });
  },

  // Обновить элемент
  updatePollGroupItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsPollGroupBusy', true);

    return new Promise((resolve, reject) => {

      // Обновляем элемент
      pollGroupApi.updatePollGroupItem(data)
        .then(
          (response) => {
            commit('setIsPollGroupBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsPollGroupBusy', false);
            reject(error);
          }
        );
    });
  },

  // Удалить элемент
  deletePollGroupItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsPollGroupBusy', true);

    return new Promise((resolve, reject) => {

      // Удаляем элемент
      pollGroupApi.deletePollGroupItem(data)
        .then(
          (response) => {
            commit('setIsPollGroupBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsPollGroupBusy', false);
            reject(error);
          }
        );
    });
  }
};

// Создаем модуль
const PollGroupModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default PollGroupModule;
