import script from "./QuizTable.vue?vue&type=script&setup=true&lang=ts"
export * from "./QuizTable.vue?vue&type=script&setup=true&lang=ts"

import "./QuizTable.vue?vue&type=style&index=0&id=77248eca&lang=scss"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QBtn,QSelect,QTable,QTd});
