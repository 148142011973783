import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { MetricAccountValueApi } from '../services/api/MetricAccountValueApi'
import Loading from 'quasar/src/plugins/loading/Loading.js';

interface metricsAccount {
  projectIds: Array<number>
  periodIds: Array<number>
  fromMetricValueIds: Array<number>
  toMetricValueIds: Array<number>
  key: number
}
class State {
  metricAccountValueItems: unknown[]
  intersectionMetricAccountValueItems: unknown[]
  isIntersectionMetricAccountValueItemsBusy: Boolean
}

// Создаем объект
const metricAccountValueApi = new MetricAccountValueApi()

// Создаем геттеры
const getters = <GetterTree<State, any>>{
  getMetricAccountValueItems(state): unknown[] {
    return state.metricAccountValueItems
  },

  getIntersectionMetricAccountValueItems(state): unknown[] {
    return state.intersectionMetricAccountValueItems
  },

  getIntersectionMetricAccountValueItemsBusy(state): Boolean {
    return state.isIntersectionMetricAccountValueItemsBusy
  },
}

// Создаем мутации
const mutations = <MutationTree<State>>{
  setMetricAccountValueItems(state, payload: unknown[]) {
    state.metricAccountValueItems = payload
  },

  setIntersectionMetricAccountValueItems(state, payload: unknown[]) {
    state.intersectionMetricAccountValueItems = payload
  },

  setIsIntersectionMetricAccountValueItemsBusy(state, payload) {
    state.isIntersectionMetricAccountValueItemsBusy = payload

    if (payload) {
      Loading.show({
        message: 'Идет загрузка данных...',
      })
    } else {
      Loading.hide()
    }
  },
}

// Создаем действия
const actions = <ActionTree<State, any>>{
  // Список элементов
  getMetricAccountValueItems({ commit }, data: any) {
    commit('setIsProjectInfoBusy', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []
    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}
    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : {}

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      metricAccountValueApi
        .getMetricAccountValueItems(filter, sort, pagination)
        .then(
          (response: any) => {
            commit('setMetricAccountValueItems', response)
            commit('setIsProjectInfoBusy', false)
            resolve(response)
          },
          (error) => {
            commit('setIsProjectInfoBusy', false)
            reject(error)
          }
        )
    })
  },

  getIntersectionMetricAccountValueItems({ commit }, data: metricsAccount) {
    commit('setIsIntersectionMetricAccountValueItemsBusy', true)

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      metricAccountValueApi.getIntersectionMetricAccountValueItems(data).then(
        (response: any) => {
          commit('setIntersectionMetricAccountValueItems', response)
          commit('setIsIntersectionMetricAccountValueItemsBusy', false)
          resolve(response)
        },
        (error) => {
          commit('setIsIntersectionMetricAccountValueItemsBusy', false)
          reject(error)
        }
      )
    })
  },
}

// Создаем модуль
const MetricAccountValueModule: any = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default MetricAccountValueModule
