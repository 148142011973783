import { DashboardLoyaltyServiceClient } from '../../proto/pb/LoyaltyServiceClientPb'
import {
    GetDashboardLoyaltyStatusChartRequest,
    ListDashboardLoyaltyEventTypesRequest,
    GetDashboardLoyaltyRegistrationChartRequest,
    ListDashboardLoyaltyTransactionByProjectRequest,
    GetDashboardLoyaltyTransactionsChartRequest,
    GetDashboardLoyaltyUniqUsersChartRequest,
    ListDashboardLoyaltyTransactionByTypeRequest,
    GetDashboardLoyaltyUserRatingChartRequest,
    GetDashboardLoyaltyMainValuesRequest,
    GetDashboardLoyaltyMainDifferenceRequest,
    ListDashboardLoyaltyRatingByProjectRequest,
    GetDashboardLoyaltyRatingChartRequest,
    GetDashboardLoyaltyAverageVotesChartRequest,
    GetDashboardLoyaltyUniqVotesChartRequest,
} from '../../proto/pb/loyalty_pb'
import { RefreshTokenInterceptor } from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class LoyaltyIntersectionApi {
    client: DashboardLoyaltyServiceClient
    metadata: any

    constructor() {
        const host: string = (window as any).VUE_APP_API_URL ?? ''
        this.client = new DashboardLoyaltyServiceClient(
            host,
            null,
            { unaryInterceptors: [new RefreshTokenInterceptor()] }
        )
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    getDashboardMainValue() {
        const req = new GetDashboardLoyaltyMainValuesRequest()

        return this.client.getDashboardLoyaltyMainValues(req, this.metadata)
    }

    getDashboardMainDifference() {
        const req = new GetDashboardLoyaltyMainDifferenceRequest()

        return this.client.getDashboardLoyaltyMainDifference(req, this.metadata)
    }

    getStatusChart() {
        const req = new GetDashboardLoyaltyStatusChartRequest()

        return this.client.getDashboardLoyaltyStatusChart(req, this.metadata)
    }

    getUniqUsersChart(data: GetDashboardLoyaltyUniqUsersChartRequest.AsObject) {
        const req = new GetDashboardLoyaltyUniqUsersChartRequest()
        req
            .setProjectIdList(data.projectIdList)
            .setEventTypeIdList(data.eventTypeIdList)
            .setInterval(data.interval)
            .setDateBegin(data.dateBegin)
            .setDateEnd(data.dateEnd)

        return this.client.getDashboardLoyaltyUniqUsersChart(req, this.metadata)
    }

    getDynamicJoinChart(data: GetDashboardLoyaltyRegistrationChartRequest.AsObject) {
        const req = new GetDashboardLoyaltyRegistrationChartRequest()
        req
            .setInterval(data.interval)
            .setDateFrom(data.dateFrom)
            .setDateTo(data.dateTo)

        return this.client.getDashboardLoyaltyRegistrationChart(req, this.metadata)
    }

    getTransactionTable(data: ListDashboardLoyaltyTransactionByProjectRequest.AsObject) {
        const req = new ListDashboardLoyaltyTransactionByProjectRequest()
        req
            .setProjectIdList(data.projectIdList)
            .setEventTypeIdList(data.eventTypeIdList)
            .setDateBegin(data.dateBegin)
            .setDateEnd(data.dateEnd)

        return this.client.listDashboardLoyaltyTransactionByProject(req, this.metadata)
    }

    getTransactionChart(data: GetDashboardLoyaltyTransactionsChartRequest.AsObject) {
        const req = new GetDashboardLoyaltyTransactionsChartRequest()
        req
            .setProjectIdList(data.projectIdList)
            .setEventTypeIdList(data.eventTypeIdList)
            .setInterval(data.interval)
            .setDateBegin(data.dateBegin)
            .setDateEnd(data.dateEnd)

        return this.client.getDashboardLoyaltyTransactionsChart(req, this.metadata)
    }

    getTopTable(data: ListDashboardLoyaltyTransactionByTypeRequest.AsObject) {
        const req = new ListDashboardLoyaltyTransactionByTypeRequest()
        req
            .setProjectIdList(data.projectIdList)
            .setEventTypeIdList(data.eventTypeIdList)
            .setDateBegin(data.dateBegin)
            .setDateEnd(data.dateEnd)

        return this.client.listDashboardLoyaltyTransactionByType(req, this.metadata)
    }

    getUsersRatingChart(data: GetDashboardLoyaltyUserRatingChartRequest.AsObject) {
        const req = new GetDashboardLoyaltyUserRatingChartRequest()
        req
            .setProjectIdList(data.projectIdList)
            .setEventTypeIdList(data.eventTypeIdList)
            .setInterval(data.interval)
            .setDateBegin(data.dateBegin)
            .setDateEnd(data.dateEnd)

        return this.client.getDashboardLoyaltyUserRatingChart(req, this.metadata)
    }

    getListEmissia(data: ListDashboardLoyaltyRatingByProjectRequest.AsObject) {
        const req = new ListDashboardLoyaltyRatingByProjectRequest()
        req
            .setProjectIdList(data.projectIdList)
            .setEventTypeIdList(data.eventTypeIdList)
            .setDateBegin(data.dateBegin)
            .setDateEnd(data.dateEnd)

        return this.client.listDashboardLoyaltyRatingByProject(req, this.metadata)
    }

    getRatingEmissia(data: GetDashboardLoyaltyRatingChartRequest.AsObject) {
        const req = new GetDashboardLoyaltyRatingChartRequest()
        req
            .setProjectIdList(data.projectIdList)
            .setEventTypeIdList(data.eventTypeIdList)
            .setInterval(data.interval)
            .setDateBegin(data.dateBegin)
            .setDateEnd(data.dateEnd)

        return this.client.getDashboardLoyaltyRatingChart(req, this.metadata)
    }

    getAverageVotesChart(data: GetDashboardLoyaltyAverageVotesChartRequest.AsObject) {
        const req = new GetDashboardLoyaltyAverageVotesChartRequest()
        req
            .setDateBegin(data.dateBegin)
            .setDateEnd(data.dateEnd)

        return this.client.getDashboardLoyaltyAverageVotesChart(req, this.metadata)
    }

    getUniqVotesChart(data: GetDashboardLoyaltyUniqVotesChartRequest.AsObject) {
        const req = new GetDashboardLoyaltyUniqVotesChartRequest()
        req
            .setInterval(data.interval)
            .setDateBegin(data.dateBegin)
            .setDateEnd(data.dateEnd)

        return this.client.getDashboardLoyaltyUniqVotesChart(req, this.metadata)
    }

    getLoyaltyActivities(data: ListDashboardLoyaltyEventTypesRequest.AsObject) {
        const req = new ListDashboardLoyaltyEventTypesRequest()
        req
            .setProjectIdList(data.projectIdList)

        return this.client.listDashboardLoyaltyEventTypes(req, this.metadata)
    }
}