
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RPGeneralList',

  data() {
    return {
      addonList: [
        {
          title: 'Обобщение',
          url: '/cls/general/generalizing',
        },
        {
          title: 'Классификация',
          url: '/cls/general/answers',
        },

        // {
        //   title: "Просмотр профиля выбранного пользователя",
        //   url: "aehr",
        // },
        // {
        //   title: "Прогнозирование аудитории",
        //   url: "dky",
        // },
        // {
        //   title: "Оповещение аудитории",
        //   url: "sh",
        // },
      ],
    }
  },
})
