import { DashboardCommonServiceClient } from '../../proto/pb/CommonServiceClientPb'
import {
    GetDashboardCommonMinMaxDateRequest,
} from '../../proto/pb/common_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class DateMinMax {
    client: DashboardCommonServiceClient
    metadata: any

    constructor() {
        const host: string = (window as any).VUE_APP_API_URL ?? ''
        this.client = new DashboardCommonServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    getDateMinMax() {
        const request = new GetDashboardCommonMinMaxDateRequest()

        return this.client.getDashboardCommonMinMaxDate(request, this.metadata)
    }
}