import { GetterTree, MutationTree, ActionTree } from "vuex";
import {DateMinMax} from "@/components/modules/intersection/services/api/DateMinMax";

const dateMinMax = new DateMinMax()

class State {
    dateMinMax: any
}

const getters = <GetterTree<State, any>> {
    getMinMaxDate(state) {
        return state.dateMinMax
    },
}

const mutations = <MutationTree<State>> {
    setMinMaxDate(state, payload) {
        state.dateMinMax = payload
    },
}

const actions = <ActionTree<State, any>> {
    getMinMaxDate({commit}) {
        return new Promise((resolve, reject) => {
            dateMinMax.getDateMinMax().then(
                (message: any) => {
                    const items = message.getItem()
                    console.log('ENWTAJASDFDAS ITEMS', items)

                    commit('setMinMaxDate', items)
                    resolve(items)
                },

                (error: any) => {
                    commit('setMinMaxDate', [])
                    reject(error)
                    console.log('ERROR ', error)
                }
            )
        })
    }
}

const dateMinMaxModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters,
}
export default dateMinMaxModule