
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CheckList',

  data() {
    return {
      addonList: [
        {
          title: 'Общие',
          url: '/cls/general',
        },
        {
          title: 'Опросы АГ',
          url: '/cls/ag_polls',
        },
        {
          title: 'Сообщения ВК',
          url: '/cls/vk_messages',
        }

        // {
        //   title: "Просмотр профиля выбранного пользователя",
        //   url: "aehr",
        // },
        // {
        //   title: "Прогнозирование аудитории",
        //   url: "dky",
        // },
        // {
        //   title: "Оповещение аудитории",
        //   url: "sh",
        // },
      ],
    }
  },
})
