import { ActivityMetricAccountValueServicePromiseClient } from '../../proto/pb/metric_account_value_grpc_web_pb'

import {
  ActivityMetricAccountValue,
  ActivityMetricAccountValueFilter,
  ActivityMetricAccountValueFilterItem,
  ActivityMetricAccountValuePagination,
  ActivityMetricAccountValueSort,
  ListActivityMetricAccountValueRequest,
  ListIntersectionActivityMetricAccountValueRequest,
} from '../../proto/pb/metric_account_value_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit: number = 100

export class MetricAccountValueApi {
  items: Array<ActivityMetricAccountValue> = []
  client: ActivityMetricAccountValueServicePromiseClient
  metadata: any

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? ''
    this.client = new ActivityMetricAccountValueServicePromiseClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    };
  }

  // Установка и получение фильтров
  createFilter(data: any) {
    const filter = new ActivityMetricAccountValueFilter()
    // Устанавливаем фильтры
    if (data && data.length) {
      for (const i in data) {
        if (data[i].field_name && '' !== data[i].field_name) {
          const fItem = new ActivityMetricAccountValueFilterItem()
          fItem.setFieldName(data[i].field_name.toString())
          fItem.setOperand(
            data[i].operand !== undefined ? data[i].operand.toString() : '='
          )
          fItem.setValue(
            data[i].value !== undefined ? data[i].value.toString() : ''
          )
          fItem.setOr(this.createFilter(data[i].or)) // Рекурсия !!!
          filter.addItems(fItem)
        }
      }
    }

    return filter
  }

  // Установка и получение сортировки
  createSort(data: any) {
    const sort = new ActivityMetricAccountValueSort()
    const name: string =
      data.name !== undefined ? data.name.toString() : 'metric_value_id'
    const exp: string = data.exp !== undefined ? data.exp.toString() : 'asc'

    // Устанавливаем параметры сортировки
    sort.setName(name)
    sort.setExp(exp)

    return sort
  }

  // Установка и получение пагинации
  createPagination(data: any) {
    const pagination = new ActivityMetricAccountValuePagination()
    const page: number = data.page !== undefined ? Number(data.page) : 1
    const limit: number =
      data.limit !== undefined ? Number(data.limit) : defaultLimit
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0

    // Устанавливаем пагинацию
    pagination.setPage(page)
    pagination.setLimit(limit)
    pagination.setPages(pages)

    return pagination
  }

  // Список элементов
  getMetricAccountValueItems(filter: any, sort: any, pagination: any) {
    const request = new ListActivityMetricAccountValueRequest()

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter))
    request.setSort(this.createSort(sort))
    request.setPagination(this.createPagination(pagination))

    return this.client.listActivityMetricAccountValue(request, this.metadata)
        .then((message) => {
          return message.getItemsList()
        })
  }

  getIntersectionMetricAccountValueItems(data: any) {
    const request = new ListIntersectionActivityMetricAccountValueRequest()
    console.log('SEGMENTATION API', data)
    request.setProjectIdsList(data.projectIds)
    request.setPeriodIdsList(data.periodIds)
    request.setFromMetricValueIdsList(data.fromMetricValueIds)
    request.setToMetricValueIdsList(data.toMetricValueIds)
    request.setIsKey(data.key)

    return this.client.listIntersectionActivityMetricAccountValue(request, this.metadata)
        .then((message) => {
          return message.getItemsList()
        })
  }
}
