import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { MessageGrpClsApi } from '../services/api/MessageGrpClsApi';
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from '@/mixins/main';

class State {
    isMessageGrpClsBusy: boolean = false;
    isMessageGrpClsInClassBusy: boolean = false;
    isMessageGrpClsOutClassBusy: boolean = false;
    messageGrpClsInClassItems: unknown [];
    messageGrpClsOutClassItems: unknown [];
    messageGrpClsItem: unknown;
}

// Создаем объект
const messageGrpClsApi = new MessageGrpClsApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

    isMessageGrpClsBusy(state): boolean {
        return state.isMessageGrpClsBusy;
    },

    isMessageGrpClsInClassBusy(state): boolean {
        return state.isMessageGrpClsInClassBusy;
    },

    isMessageGrpClsOutClassBusy(state): boolean {
        return state.isMessageGrpClsOutClassBusy;
    },

    getMessageGrpClsInClassItems(state): unknown[] {
        return state.messageGrpClsInClassItems;
    },

    getMessageGrpClsOutClassItems(state): unknown[] {
        return state.messageGrpClsOutClassItems;
    },

    getMessageGrpClsItem(state): unknown {
        return state.messageGrpClsItem;
    }
};

// Создаем мутации
const mutations = <MutationTree<State>>{
    setIsMessageGrpClsBusy(state, payload: boolean) {
        state.isMessageGrpClsBusy = payload
    },

    setIsMessageGrpClsInClassBusy(state, payload: boolean) {
        state.isMessageGrpClsInClassBusy = payload
    },

    setIsMessageGrpClsOutClassBusy(state, payload: boolean) {
        state.isMessageGrpClsOutClassBusy = payload
    },

    setMessageGrpClsInClassItems(state, payload: unknown[]) {
        state.messageGrpClsInClassItems = payload
    },

    setMessageGrpClsOutClassItems(state, payload: unknown[]) {
        state.messageGrpClsOutClassItems = payload
    },

    setMessageGrpClsItem(state, payload: unknown) {
        state.messageGrpClsItem = payload
    }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

    // Устанавливаем элемент
    setMessageGrpClsItem({ commit }, data: any) {
        commit('setMessageGrpClsItem', data);
    },

    // Список элементов (привязанные ответы)
    getMessageGrpClsInClassItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsMessageGrpClsBusy', true);
        commit('setIsMessageGrpClsInClassBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const class_id:number = data !== undefined && data.class_id !== undefined ? Number(data.class_id) : 0;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            messageGrpClsApi.getMessageGrpClsInItems(filter, sort, pagination, class_id)
                .then(
                    (message:any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setMessageGrpClsInClassItems', items);
                        commit('setIsMessageGrpClsBusy', false);
                        commit('setIsMessageGrpClsInClassBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setMessageGrpClsInClassItems', []);
                        commit('setIsMessageGrpClsBusy', false);
                        commit('setIsMessageGrpClsInClassBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Список элементов (непривязанные ответы)
    getMessageGrpClsOutClassItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsMessageGrpClsBusy', true);
        commit('setIsMessageGrpClsOutClassBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const class_id:number = data !== undefined && data.class_id !== undefined ? Number(data.class_id) : 0;
        const not_classifier_only:boolean = data !== undefined && data.not_classifier_only !== undefined ? data.not_classifier_only : true;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            messageGrpClsApi.getMessageGrpClsOutItems(filter, sort, pagination, class_id, not_classifier_only)
                .then(
                    (message:any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setMessageGrpClsOutClassItems', items);
                        commit('setIsMessageGrpClsBusy', false);
                        commit('setIsMessageGrpClsOutClassBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setMessageGrpClsOutClassItems', []);
                        commit('setIsMessageGrpClsBusy', false);
                        commit('setIsMessageGrpClsOutClassBusy', false);
                        reject(error);
                    }
                );
        });
    }
};

// Создаем модуль
const MessageGrpClsModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default MessageGrpClsModule;
