import { ScorecardAccountProjectServiceClient } from '../../proto/pb/Scorecard_account_projectServiceClientPb'
import {
  ScorecardAccountProject,
  ListScorecardAccountProjectRequest,
} from '../../proto/pb/scorecard_account_project_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class scorecardAccountProject {
  Actions: Array<ScorecardAccountProject>
  client: ScorecardAccountProjectServiceClient
  metadata: any

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? ''
    this.client = new ScorecardAccountProjectServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  getScorecardAccPrjList({ sso_id, project_ids, date_begin, date_end }: any) {
    const request = new ListScorecardAccountProjectRequest()

    // Устанавливаем параметры запроса
    request
      .setSsoId(sso_id)
      .setProjectIdsList(project_ids)
      .setDateBegin(date_begin)
      .setDateEnd(date_end)

    return this.client.listScorecardAccountProject(request, { Authorization: localStorage.getItem('access-key') } as any)
  }
}
