
import store from '@/store'
import { defineComponent, toRaw } from 'vue'

export default defineComponent({
  name: 'TableChilds',

  props: {
    rows: {},
    columns: {},
    isAnswerGrpResultBusy: {},
    workingAnswerPanelProps: {},
    kluch: {},
    pagination: {},
    childRowId: {},
  },

  data() {
    return {
      key: this.kluch,
      refRows: this.rows,
      rowsPerPageResultOptions: [10, 25, 50, 100],
      selectedRows: [],
      workingAnswerPanel: this.workingAnswerPanelProps,
      childTable: null as any,
      resultPagination: {
        sortBy: 'answer_grp_count',
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 0,
      },

    }
  },

  methods: {
    returnWorkingPanel() {
      return this.workingAnswerPanel
    },

    onRequestResult(request: any) {
      this.resultPagination = request.pagination
      this.loadChildItems()
    },

    onRowShowAnswerSource(row: any, isFull: boolean) {
      let rowNew = JSON.parse(JSON.stringify(row))
      console.log('rowNew', rowNew)
      rowNew.sum = rowNew.count
      this.$emit('showSource', rowNew, isFull)
    },

    loadChildItems() {
      store
          .dispatch('getAnswerGrpChildsItems', {
            sort: {
              name: this.resultPagination.sortBy,
              exp: this.resultPagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.resultPagination.page,
              limit: this.resultPagination.rowsPerPage,
              pages: 0,
              cnt: this.resultPagination.rowsNumber,
            },
            parent_id: this.childRowId,
          })
          .then((resp) => {
            console.log('RESPONSE CHILD', resp)
            this.resultPagination.rowsNumber = resp.pagination.getCnt()
            let test = store.getters.getAnswerGrpChildsItems
            let rows: any = []
            if (test !== undefined) {
              for (let answerItem of resp.items) {
                rows.push({
                  id: answerItem.getAnswerGrpId(),
                  text: answerItem.getAnswerGrpText(),
                  count: answerItem.getAnswerGrpCount(),
                  has_childs: answerItem.getHasChilds(),
                })
              }

              this.childTable = rows
            } else {
              console.log('GETTER EMPTY')
            }
          })
    },

    showChildTable (prop: any) {
      if (!prop.expand) {
        prop.expand = true
      } else {
        prop.expand = false
      }
    },

    separateChild() {
      let reducedIds = this.selectedRows.map((item: any) => item.id)

      store
          .dispatch('separateAnswerGrpItems', {
            separated_ids_list: reducedIds
          })
          .then(() => {
            this.$emit('afterSeparate')
          })
    },

    checkSelectedChild() {
      this.$emit('checkChild', this.selectedRows)
    },
  },

  created() {
    if (this.childRowId) {
      this.loadChildItems()
    }
  }
})
