import {
  AnswerSrcServiceClient
} from "../../proto/pb/Answer_srcServiceClientPb";
import {
  AnswerSrc, 
  AnswerSrcSource, 
  ListAnswerSrcRequest,
  ListAnswerSrcSourceRequest,
  //ItemAnswerSrcRequest,
  //ItemAnswerSrcSourceRequest,
  AnswerSrcFilter,
  AnswerSrcFilterItem,
  AnswerSrcSort,
  AnswerSrcPagination,
  ListAnswerSrcInClassRequest,
  PersonalListAnswerSrcSourceRequest,
} from "../../proto/pb/answer_src_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 1000;

export class AnswerSrcApi {
  items: Array<AnswerSrc> = [];
  sitems: Array<AnswerSrcSource> = [];
  client: AnswerSrcServiceClient;
  metadata: any;

  constructor() {
    const host:string = (window as any).VUE_APP_API_URL ?? '';
    this.client = new AnswerSrcServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  // Установка и получение фильтров
  createFilter(data: any){

    const filter = new AnswerSrcFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
        for (const i in data) {
            if (data[i].field_name && ('' !== data[i].field_name)) {
                const fItem = new AnswerSrcFilterItem();
                fItem.setFieldName(data[i].field_name.toString());
                fItem.setOperand(data[i].operand !== undefined ? data[i].operand : '=');
                fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                filter.addItems(fItem);
            }
        }
    }

    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any){

    const sort = new AnswerSrcSort();
    const name:string = data.name !== undefined ? data.name.toString() : 'asrc.answer_src_text';
    const exp:string = data.exp !== undefined ? data.exp.toString() : 'ASC';

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any){

    const pagination = new AnswerSrcPagination();
    const page:number = data.page !== undefined ? Number(data.page) : 1;
    const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages:number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt:number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  // Список элементов
  getAnswerSrcItems(filter: any, sort: any, pagination: any){

    const request = new ListAnswerSrcRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));

    return this.client.listAnswerSrc(request, this.metadata);
  }

  // Список элементов для модалки
  getAnswerSrcSourceItems(filter: any, sort: any, pagination: any, answer_grp_id: number, is_full: boolean){

    const request = new ListAnswerSrcSourceRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));
    request.setAnswerGrpId(answer_grp_id);
    request.setHasFull(is_full);

    return this.client.listAnswerSrcSource(request, this.metadata);
  }

  getPersonalAnswerSrcSourceItems(filter: any, answer_grp_id: number, is_full: boolean){

    const request = new PersonalListAnswerSrcSourceRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setAnswerGrpId(answer_grp_id);
    request.setHasFull(is_full);

    return this.client.personalListAnswerSrcSource(request, this.metadata);
  }

  getAnswerSrcInClass(filter: any, sort: any, pagination: any, class_name: string) {
    const req = new ListAnswerSrcInClassRequest()

    req
        .setFilter(this.createFilter(filter))
        .setSort(this.createSort(sort))
        .setPagination(this.createPagination(pagination))
        .setClassName(class_name)

    return this.client.listAnswerSrcInClass(req, this.metadata)
  }

  getExportAnswerSrcInClass(filter: any, sort: any, pagination: any, class_name: string) {
    const req = new ListAnswerSrcInClassRequest()

    req
        .setFilter(this.createFilter(filter))
        .setSort(this.createSort(sort))
        .setPagination(this.createPagination(pagination))
        .setClassName(class_name)

    return this.client.listAnswerSrcInClass(req, this.metadata)
  }
}
