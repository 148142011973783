
// import store from '@/store'
import { defineComponent } from 'vue'
import IntersectionUsersChart from '@/components/charts/IntersectionUsersChart.vue'
import ProjectsChoice from '@/components/ProjectsChoice.vue'

export default defineComponent({
  name: 'IntersectionUsers',

  components: {
    IntersectionUsersChart,
    ProjectsChoice,
  },

  data() {
    return {}
  },
})
