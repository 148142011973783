
import store from '@/store'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'ClsEdit',

  components: {
    DataLoading,
  },

  props: {
    clsId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      item: {
        id: 0,
        name: '',
        descr: '',
        notes: '',
      },
    }
  },

  computed: {
    isClassifierBusy() {
      return store.getters.getIsClassifierBusy
    },
  },

  methods: {
    saveCls() {
      if (!this.item.name) {
        console.log('Наименование классификатора не введено')
        return false
      }

      let data = {
        id: this.item.id,
        name: this.item.name,
        descr: this.item.descr,
        notes: this.item.notes,
      }

      store.dispatch('updateClassifierItem', data).then(
        (resolve) => {
          console.log('resolve', resolve)
          this.$emit('afterAction')
        },
        (reject) => {
          console.log('reject', reject)
        }
      )
      return true
    },

    closeClsEdit() {
      this.$emit('hideModal')
    },

    loadData() {
      store
        .dispatch('getClassifierItem', {
          filter: [
            {
              field_name: 'classifier_id',
              operand: '=',
              value: this.clsId,
            },
          ],
        })
        .then(
          (resolve) => {
            console.log('loadData resolve', resolve)
            this.item = {
              id: resolve.getClassifierId(),
              name: resolve.getClassifierName(),
              descr: resolve.getClassifierDescr(),
              notes: resolve.getClassifierNotes(),
            }
          },
          (reject) => {
            console.log('loadData reject', reject)
          }
        )
    },
  },

  created() {
    this.loadData()
  },
})
