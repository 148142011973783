import {
    AuthServiceClient
} from "../../proto/pb/AuthServiceClientPb";

import {
    LoginRequest,
    RefreshRequest,
    LoginResponse,
    RefreshResponse
} from "../../proto/pb/auth_pb";

export class AuthApi {
  client: AuthServiceClient
  metadata: any;
  accessToken = undefined as string | undefined
  refreshToken = undefined as string | undefined
  refreshRequest: Promise<RefreshResponse> | undefined

  constructor() {
    const host:string = (window as any).VUE_APP_API_URL ?? ''
    this.client = new AuthServiceClient(host, null, null)
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    };
  }

  // Проверка имени и пароля системного пользователя
  checkUserCreds(data: any): Promise<LoginResponse>{
    const request = new LoginRequest()

    request
        .setUsername(data.login)
        .setPassword(data.passw)

    return this.client.login(request, this.metadata)
  }

  checkRefreshToken(data: any): Promise<RefreshResponse> {
    const request = new RefreshRequest() as any
      console.log(data.refreshToken)

    request
        .setRefreshToken(data.refreshToken)

      console.log('REFRESH REQ', request)

    this.refreshRequest = this.client.refresh(request, this.metadata)
        .then((message) => {
          localStorage.setItem('refresh-key', message.getRefreshToken())
          localStorage.setItem('access-key', message.getAccessToken())

            console.log('REFRESH SUCCESS', message)

          return message
        })
        .catch((err) => {
          return err
        })
        .finally(() => {
            this.refreshRequest = undefined
        })

    return this.refreshRequest
  }
}