import {
    SocialNetworkMessageClassServiceClient
} from "../../proto/pb/Social_network_message_classServiceClientPb";
import {
    SocialNetworkMessageClass,
    SocialNetworkListMessageClassRequest,
    SocialNetworkMessageClassFilter,
    SocialNetworkMessageClassFilterItem,
    SocialNetworkMessageClassSort,
    SocialNetworkMessageClassPagination,
    SocialNetworkCreateMessageClassRequest,
    SocialNetworkUpdateMessageClassRequest,
    SocialNetworkDeleteMessageClassRequest,
    SocialNetworkSaveMessageClassRequest,
    SocialNetworkRemoveMessageClassRequest
} from "../../proto/pb/social_network_message_class_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 1000;

export class SocialNetworkMessageClassApi {
    items: Array<SocialNetworkMessageClass> = [];
    client: SocialNetworkMessageClassServiceClient;
    metadata: any;

    constructor() {
        const host:string = (window as any).VUE_APP_API_URL ?? '';
        this.client = new SocialNetworkMessageClassServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    // Установка и получение фильтров
    createFilter(data: any){

        const filter = new SocialNetworkMessageClassFilter();

        // Устанавливаем фильтры
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && ('' !== data[i].field_name)) {
                    const fItem = new SocialNetworkMessageClassFilterItem();
                    fItem.setFieldName(data[i].field_name.toString());
                    fItem.setOperand(data[i].operand !== undefined ? data[i].operand : '=');
                    fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                    fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                    filter.addItems(fItem);
                }
            }
        }

        return filter;
    }

    // Установка и получение сортировки
    createSort(data: any){

        const sort = new SocialNetworkMessageClassSort();
        const name:string = data.name !== undefined ? data.name.toString() : 'message_grp_id';
        const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    // Установка и получение пагинации
    createPagination(data: any){

        const pagination = new SocialNetworkMessageClassPagination();
        const page:number = data.page !== undefined ? Number(data.page) : 1;
        const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
        const pages:number = data.pages !== undefined ? Number(data.pages) : 0;

        // Устанавливаем пагинацию
        pagination.setPage(page);
        pagination.setLimit(limit);
        pagination.setPages(pages);

        return pagination;
    }

    // Список элементов
    getSocialNetworkMessageClassItems(filter: any, sort: any, pagination: any){

        const request = new SocialNetworkListMessageClassRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));

        return this.client.socialNetworkListMessageClass(request, this.metadata)
            .then((message) => {
                return message.getItemsList()
            })
    }

    // Создание элемента
    createSocialNetworkMessageClassItem(data: any){

        const request = new SocialNetworkCreateMessageClassRequest();
        request.setClassId(data.class_id);
        request.setMessageGrpId(data.message_grp_id);
        request.setNotes(data.Notes);

        return this.client.socialNetworkCreateMessageClass(request, this.metadata);
    }

    // Редактирование элемента
    updateSocialNetworkMessageClassItem(data: any){

        const request = new SocialNetworkUpdateMessageClassRequest();
        request.setClassId(data.class_id);
        request.setMessageGrpId(data.message_grp_id);
        request.setNotes(data.Notes);

        return this.client.socialNetworkUpdateMessageClass(request, this.metadata);
    }

    // Удаление элемента
    deleteSocialNetworkMessageClassItem(data: any){

        const request = new SocialNetworkDeleteMessageClassRequest();
        request.setClassId(data.class_id);

        return this.client.socialNetworkDeleteMessageClass(request, this.metadata);
    }

    // Сохранение нескольких элементов
    saveSocialNetworkMessageClass(data: any){

        const request = new SocialNetworkSaveMessageClassRequest();
        const aItems: Array<SocialNetworkMessageClass> = [];

        request.setClassId(data.class_id);

        if (data.items && (data.items.length > 0)) {
            for (const i in data.items) {
                const aItem = new SocialNetworkMessageClass();
                aItem.setClassId(data.class_id);
                aItem.setMessageGrpId(data.items[i].message_grp_id);
                aItem.setNotes(data.items[i].notes.toString());
                aItems.push(aItem);
            }
        }

        request.setItemsList(aItems);

        return this.client.socialNetworkSaveMessageClass(request, this.metadata);
    }

    // Удаление нескольких элементов
    removeSocialNetworkMessageClass(data: any){

        const request = new SocialNetworkRemoveMessageClassRequest();
        const aItems: Array<SocialNetworkMessageClass> = [];

        request.setClassId(data.class_id);

        if (data.items && (data.items.length > 0)) {
            for (const i in data.items) {
                const aItem = new SocialNetworkMessageClass();
                aItem.setClassId(data.class_id);
                aItem.setMessageGrpId(data.items[i].message_grp_id);
                aItem.setNotes(data.items[i].notes.toString());
                aItems.push(aItem);
            }
        }

        request.setItemsList(aItems);

        return this.client.socialNetworkRemoveMessageClass(request, this.metadata);
    }
}
