<template>
    <div class="q-pa-md">
        <div class="text-h4 q-pb-md">Сообщения пользователей</div>
        <CheckList />
    </div>
</template>

<script>
import CheckList from "@/components/CheckList.vue";

export default {
    name: "RespProcess",
    components: {CheckList},
}
</script>

<style scoped>

</style>