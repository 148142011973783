import { GetterTree, MutationTree, ActionTree } from "vuex";
import { PollInfoFormApi } from "../services/api/PollInfoFormApi";
import Loading from 'quasar/src/plugins/loading/Loading.js';

class State {
  pollInfo: any
  isPollChartBusy: boolean = false
  isPollInfoFormBusy: boolean = false;
  generalPollInfo: any
  isGeneralPollChartBusy: boolean = false
  isGeneralPollInfoFormBusy: boolean = false;
}

// Создаем объект
const pollInfoFormApi = new PollInfoFormApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{
  getPollChart(state) {
      return state.pollInfo
  },

  isPollChartBusy(state): boolean {
      return state.isPollChartBusy
  },

  isPollInfoFormBusy(state): boolean {
    return state.isPollInfoFormBusy;
  },

  getGeneralPollChart(state) {
      return state.generalPollInfo
  },

  isGeneralPollChartBusy(state): boolean {
      return state.isGeneralPollChartBusy
  },

  isGeneralPollInfoFormBusy(state): boolean {
      return state.isGeneralPollInfoFormBusy;
  }
};

// Создаем мутации
const mutations = <MutationTree<State>>{
  setPollChart(state, payload) {
      state.pollInfo = payload
  },

  setIsPollChartBusy(state, payload: boolean) {
      state.isPollChartBusy = payload
  },

  setIsPollInfoFormBusy(state, payload: boolean) {
    state.isPollInfoFormBusy = payload
  },

  setGeneralPollChart(state, payload) {
      state.generalPollInfo = payload
  },

  setIsGeneralPollChartBusy(state, payload: boolean) {
      state.isGeneralPollChartBusy = payload
  },

  setIsGeneralPollInfoFormBusy(state, payload: boolean) {
      state.isGeneralPollInfoFormBusy = payload
  },
};

// Создаем действия
const actions = <ActionTree<State, any>>{
    getPollChart({ commit }, data) {
        commit('setIsPollChartBusy', true)

        return new Promise((resolve, reject) => {
            pollInfoFormApi.getPollChart(data).then(
                (message: any) => {
                    const items = message.getItemsList()

                    commit('setIsPollChartBusy', false)
                    commit('setPollChart', items)
                    resolve(items)
                },

                (error: any) => {
                    commit('setPollChart', [])
                    reject(error)
                    console.log('ERROR ', error)
                }
            )
        })
    },

    getPollInfoForm({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsPollInfoFormBusy', true);

        const poll_id:any = data !== undefined && data.poll_id !== undefined ? data.poll_id : 0;
        const max_open_rows:any = data !== undefined && data.max_open_rows !== undefined ? data.max_open_rows : 10;

        return new Promise((resolve, reject) => {

            // Получаем данные
            pollInfoFormApi.getPollInfoForm(poll_id, max_open_rows)
                .then(
                    (message:any) => {
                        const usersCount = message.getUsersCount();
                        const items = message.getItemsList();
                        commit('setIsPollInfoFormBusy', false);
                        resolve({
                            usersCount: usersCount,
                            items: items,
                        });
                    },
                    (error) => {
                        commit('setIsPollInfoFormBusy', false);
                        reject(error);
                    }
                );
        });
    },

    getGeneralPollChart({ commit }, data) {
        commit('setIsGeneralPollChartBusy', true)

        return new Promise((resolve, reject) => {
            pollInfoFormApi.getGeneralPollChart(data).then(
                (message: any) => {
                    const items = message.getItemsList()

                    commit('setIsGeneralPollChartBusy', false)
                    commit('setGeneralPollChart', items)
                    resolve(items)
                },

                (error: any) => {
                    commit('setGeneralPollChart', [])
                    reject(error)
                    console.log('ERROR ', error)
                }
            )
        })
    },

    getGeneralPollInfoForm({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsGeneralPollInfoFormBusy', true);

        const poll_id:any = data !== undefined && data.poll_id !== undefined ? data.poll_id : 0;
        const classifier_id:any = data !== undefined && data.classifier_id !== undefined ? data.classifier_id : 0;
        const max_cls_rows:any = data !== undefined && data.max_cls_rows !== undefined ? data.max_cls_rows : 10;

        return new Promise((resolve, reject) => {

            // Получаем данные
            pollInfoFormApi.getGeneralPollInfoForm(poll_id, classifier_id, max_cls_rows)
                .then(
                    (message:any) => {
                        const usersCount = message.getUsersCount();
                        const items = message.getItemsList();
                        commit('setIsGeneralPollInfoFormBusy', false);
                        resolve({
                            usersCount: usersCount,
                            items: items,
                        });
                    },
                    (error) => {
                        commit('setIsGeneralPollInfoFormBusy', false);
                        reject(error);
                    }
                );
        });
    }
};

// Создаем модуль
const PollInfoFormModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default PollInfoFormModule;
