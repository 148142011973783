import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-h5" }
const _hoisted_2 = { class: "text-weight-bold text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1024px","max-width":"80vw","max-height":"80vh"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createTextVNode("Вы действительно хотите удалить тэг - "),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.item.name), 1),
            _createTextVNode(" ?")
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      _createVNode(_component_q_card_actions, { align: "right" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_btn, {
            color: "primary",
            onClick: _ctx.actualDelete
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Удалить ")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_q_btn, {
            color: "primary",
            onClick: _ctx.closeTagDelete
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Отмена ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}