// source: social_network_message_grp_cls.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.proto.SocialNetworkListMessageGrpClsInRequest', null, global);
goog.exportSymbol('proto.proto.SocialNetworkListMessageGrpClsInResponse', null, global);
goog.exportSymbol('proto.proto.SocialNetworkListMessageGrpClsOutRequest', null, global);
goog.exportSymbol('proto.proto.SocialNetworkListMessageGrpClsOutResponse', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageGrpClsFilter', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageGrpClsFilterItem', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageGrpClsIn', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageGrpClsOut', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageGrpClsPagination', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageGrpClsSort', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageGrpClsIn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkMessageGrpClsIn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageGrpClsIn.displayName = 'proto.proto.SocialNetworkMessageGrpClsIn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageGrpClsOut = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkMessageGrpClsOut, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageGrpClsOut.displayName = 'proto.proto.SocialNetworkMessageGrpClsOut';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageGrpClsFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkMessageGrpClsFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageGrpClsFilterItem.displayName = 'proto.proto.SocialNetworkMessageGrpClsFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageGrpClsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SocialNetworkMessageGrpClsFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.SocialNetworkMessageGrpClsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageGrpClsFilter.displayName = 'proto.proto.SocialNetworkMessageGrpClsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageGrpClsSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkMessageGrpClsSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageGrpClsSort.displayName = 'proto.proto.SocialNetworkMessageGrpClsSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageGrpClsPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkMessageGrpClsPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageGrpClsPagination.displayName = 'proto.proto.SocialNetworkMessageGrpClsPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkListMessageGrpClsInRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkListMessageGrpClsInRequest.displayName = 'proto.proto.SocialNetworkListMessageGrpClsInRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkListMessageGrpClsInResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SocialNetworkListMessageGrpClsInResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.SocialNetworkListMessageGrpClsInResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkListMessageGrpClsInResponse.displayName = 'proto.proto.SocialNetworkListMessageGrpClsInResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkListMessageGrpClsOutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkListMessageGrpClsOutRequest.displayName = 'proto.proto.SocialNetworkListMessageGrpClsOutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkListMessageGrpClsOutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SocialNetworkListMessageGrpClsOutResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.SocialNetworkListMessageGrpClsOutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkListMessageGrpClsOutResponse.displayName = 'proto.proto.SocialNetworkListMessageGrpClsOutResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageGrpClsIn.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageGrpClsIn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageGrpClsIn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpClsIn.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    messageGrpText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    messageGrpCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    messageGrpSum: jspb.Message.getFieldWithDefault(msg, 4, 0),
    classId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    notes: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageGrpClsIn}
 */
proto.proto.SocialNetworkMessageGrpClsIn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageGrpClsIn;
  return proto.proto.SocialNetworkMessageGrpClsIn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageGrpClsIn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageGrpClsIn}
 */
proto.proto.SocialNetworkMessageGrpClsIn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageGrpText(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpSum(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageGrpClsIn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageGrpClsIn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageGrpClsIn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpClsIn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMessageGrpText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessageGrpCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMessageGrpSum();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getClassId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 message_grp_id = 1;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrpClsIn.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsIn} returns this
 */
proto.proto.SocialNetworkMessageGrpClsIn.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message_grp_text = 2;
 * @return {string}
 */
proto.proto.SocialNetworkMessageGrpClsIn.prototype.getMessageGrpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsIn} returns this
 */
proto.proto.SocialNetworkMessageGrpClsIn.prototype.setMessageGrpText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 message_grp_count = 3;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrpClsIn.prototype.getMessageGrpCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsIn} returns this
 */
proto.proto.SocialNetworkMessageGrpClsIn.prototype.setMessageGrpCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 message_grp_sum = 4;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrpClsIn.prototype.getMessageGrpSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsIn} returns this
 */
proto.proto.SocialNetworkMessageGrpClsIn.prototype.setMessageGrpSum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 class_id = 5;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrpClsIn.prototype.getClassId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsIn} returns this
 */
proto.proto.SocialNetworkMessageGrpClsIn.prototype.setClassId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string notes = 6;
 * @return {string}
 */
proto.proto.SocialNetworkMessageGrpClsIn.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsIn} returns this
 */
proto.proto.SocialNetworkMessageGrpClsIn.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageGrpClsOut.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageGrpClsOut.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageGrpClsOut} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpClsOut.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    messageGrpText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    messageGrpCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    messageGrpSum: jspb.Message.getFieldWithDefault(msg, 4, 0),
    hasClasses: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageGrpClsOut}
 */
proto.proto.SocialNetworkMessageGrpClsOut.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageGrpClsOut;
  return proto.proto.SocialNetworkMessageGrpClsOut.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageGrpClsOut} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageGrpClsOut}
 */
proto.proto.SocialNetworkMessageGrpClsOut.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageGrpText(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpSum(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasClasses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageGrpClsOut.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageGrpClsOut.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageGrpClsOut} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpClsOut.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMessageGrpText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessageGrpCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMessageGrpSum();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getHasClasses();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 message_grp_id = 1;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrpClsOut.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsOut} returns this
 */
proto.proto.SocialNetworkMessageGrpClsOut.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message_grp_text = 2;
 * @return {string}
 */
proto.proto.SocialNetworkMessageGrpClsOut.prototype.getMessageGrpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsOut} returns this
 */
proto.proto.SocialNetworkMessageGrpClsOut.prototype.setMessageGrpText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 message_grp_count = 3;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrpClsOut.prototype.getMessageGrpCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsOut} returns this
 */
proto.proto.SocialNetworkMessageGrpClsOut.prototype.setMessageGrpCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 message_grp_sum = 4;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrpClsOut.prototype.getMessageGrpSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsOut} returns this
 */
proto.proto.SocialNetworkMessageGrpClsOut.prototype.setMessageGrpSum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool has_classes = 5;
 * @return {boolean}
 */
proto.proto.SocialNetworkMessageGrpClsOut.prototype.getHasClasses = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsOut} returns this
 */
proto.proto.SocialNetworkMessageGrpClsOut.prototype.setHasClasses = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageGrpClsFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageGrpClsFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageGrpClsFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpClsFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.SocialNetworkMessageGrpClsFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageGrpClsFilterItem}
 */
proto.proto.SocialNetworkMessageGrpClsFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageGrpClsFilterItem;
  return proto.proto.SocialNetworkMessageGrpClsFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageGrpClsFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageGrpClsFilterItem}
 */
proto.proto.SocialNetworkMessageGrpClsFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.SocialNetworkMessageGrpClsFilter;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpClsFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageGrpClsFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageGrpClsFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageGrpClsFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpClsFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.SocialNetworkMessageGrpClsFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.SocialNetworkMessageGrpClsFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsFilterItem} returns this
 */
proto.proto.SocialNetworkMessageGrpClsFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.SocialNetworkMessageGrpClsFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsFilterItem} returns this
 */
proto.proto.SocialNetworkMessageGrpClsFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.SocialNetworkMessageGrpClsFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsFilterItem} returns this
 */
proto.proto.SocialNetworkMessageGrpClsFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SocialNetworkMessageGrpClsFilter or = 4;
 * @return {?proto.proto.SocialNetworkMessageGrpClsFilter}
 */
proto.proto.SocialNetworkMessageGrpClsFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpClsFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpClsFilter, 4));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpClsFilter|undefined} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsFilterItem} returns this
*/
proto.proto.SocialNetworkMessageGrpClsFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkMessageGrpClsFilterItem} returns this
 */
proto.proto.SocialNetworkMessageGrpClsFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkMessageGrpClsFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SocialNetworkMessageGrpClsFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageGrpClsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageGrpClsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageGrpClsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpClsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.SocialNetworkMessageGrpClsFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageGrpClsFilter}
 */
proto.proto.SocialNetworkMessageGrpClsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageGrpClsFilter;
  return proto.proto.SocialNetworkMessageGrpClsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageGrpClsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageGrpClsFilter}
 */
proto.proto.SocialNetworkMessageGrpClsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageGrpClsFilterItem;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpClsFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageGrpClsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageGrpClsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageGrpClsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpClsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageGrpClsFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SocialNetworkMessageGrpClsFilterItem items = 1;
 * @return {!Array<!proto.proto.SocialNetworkMessageGrpClsFilterItem>}
 */
proto.proto.SocialNetworkMessageGrpClsFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.SocialNetworkMessageGrpClsFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.SocialNetworkMessageGrpClsFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.SocialNetworkMessageGrpClsFilterItem>} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsFilter} returns this
*/
proto.proto.SocialNetworkMessageGrpClsFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.SocialNetworkMessageGrpClsFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.SocialNetworkMessageGrpClsFilterItem}
 */
proto.proto.SocialNetworkMessageGrpClsFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.SocialNetworkMessageGrpClsFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SocialNetworkMessageGrpClsFilter} returns this
 */
proto.proto.SocialNetworkMessageGrpClsFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageGrpClsSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageGrpClsSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageGrpClsSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpClsSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageGrpClsSort}
 */
proto.proto.SocialNetworkMessageGrpClsSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageGrpClsSort;
  return proto.proto.SocialNetworkMessageGrpClsSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageGrpClsSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageGrpClsSort}
 */
proto.proto.SocialNetworkMessageGrpClsSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageGrpClsSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageGrpClsSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageGrpClsSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpClsSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.SocialNetworkMessageGrpClsSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsSort} returns this
 */
proto.proto.SocialNetworkMessageGrpClsSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.SocialNetworkMessageGrpClsSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsSort} returns this
 */
proto.proto.SocialNetworkMessageGrpClsSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageGrpClsPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageGrpClsPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageGrpClsPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpClsPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageGrpClsPagination}
 */
proto.proto.SocialNetworkMessageGrpClsPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageGrpClsPagination;
  return proto.proto.SocialNetworkMessageGrpClsPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageGrpClsPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageGrpClsPagination}
 */
proto.proto.SocialNetworkMessageGrpClsPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageGrpClsPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageGrpClsPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageGrpClsPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpClsPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrpClsPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsPagination} returns this
 */
proto.proto.SocialNetworkMessageGrpClsPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrpClsPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsPagination} returns this
 */
proto.proto.SocialNetworkMessageGrpClsPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrpClsPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsPagination} returns this
 */
proto.proto.SocialNetworkMessageGrpClsPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt = 4;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrpClsPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrpClsPagination} returns this
 */
proto.proto.SocialNetworkMessageGrpClsPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkListMessageGrpClsInRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkListMessageGrpClsInRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.SocialNetworkMessageGrpClsFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.SocialNetworkMessageGrpClsSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.SocialNetworkMessageGrpClsPagination.toObject(includeInstance, f),
    classId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsInRequest}
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkListMessageGrpClsInRequest;
  return proto.proto.SocialNetworkListMessageGrpClsInRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkListMessageGrpClsInRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsInRequest}
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageGrpClsFilter;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpClsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.SocialNetworkMessageGrpClsSort;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpClsSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.SocialNetworkMessageGrpClsPagination;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpClsPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkListMessageGrpClsInRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkListMessageGrpClsInRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageGrpClsFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.SocialNetworkMessageGrpClsSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.SocialNetworkMessageGrpClsPagination.serializeBinaryToWriter
    );
  }
  f = message.getClassId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional SocialNetworkMessageGrpClsFilter filter = 1;
 * @return {?proto.proto.SocialNetworkMessageGrpClsFilter}
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpClsFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpClsFilter, 1));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpClsFilter|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpClsInRequest} returns this
*/
proto.proto.SocialNetworkListMessageGrpClsInRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsInRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SocialNetworkMessageGrpClsSort sort = 2;
 * @return {?proto.proto.SocialNetworkMessageGrpClsSort}
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpClsSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpClsSort, 2));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpClsSort|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpClsInRequest} returns this
*/
proto.proto.SocialNetworkListMessageGrpClsInRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsInRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SocialNetworkMessageGrpClsPagination pagination = 3;
 * @return {?proto.proto.SocialNetworkMessageGrpClsPagination}
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpClsPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpClsPagination, 3));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpClsPagination|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpClsInRequest} returns this
*/
proto.proto.SocialNetworkListMessageGrpClsInRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsInRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 class_id = 4;
 * @return {number}
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.prototype.getClassId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkListMessageGrpClsInRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpClsInRequest.prototype.setClassId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SocialNetworkListMessageGrpClsInResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkListMessageGrpClsInResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkListMessageGrpClsInResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkListMessageGrpClsInResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpClsInResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.SocialNetworkMessageGrpClsIn.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.SocialNetworkListMessageGrpClsInRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsInResponse}
 */
proto.proto.SocialNetworkListMessageGrpClsInResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkListMessageGrpClsInResponse;
  return proto.proto.SocialNetworkListMessageGrpClsInResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkListMessageGrpClsInResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsInResponse}
 */
proto.proto.SocialNetworkListMessageGrpClsInResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageGrpClsIn;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpClsIn.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.SocialNetworkListMessageGrpClsInRequest;
      reader.readMessage(value,proto.proto.SocialNetworkListMessageGrpClsInRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkListMessageGrpClsInResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkListMessageGrpClsInResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkListMessageGrpClsInResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpClsInResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageGrpClsIn.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.SocialNetworkListMessageGrpClsInRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SocialNetworkMessageGrpClsIn items = 1;
 * @return {!Array<!proto.proto.SocialNetworkMessageGrpClsIn>}
 */
proto.proto.SocialNetworkListMessageGrpClsInResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.SocialNetworkMessageGrpClsIn>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.SocialNetworkMessageGrpClsIn, 1));
};


/**
 * @param {!Array<!proto.proto.SocialNetworkMessageGrpClsIn>} value
 * @return {!proto.proto.SocialNetworkListMessageGrpClsInResponse} returns this
*/
proto.proto.SocialNetworkListMessageGrpClsInResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.SocialNetworkMessageGrpClsIn=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.SocialNetworkMessageGrpClsIn}
 */
proto.proto.SocialNetworkListMessageGrpClsInResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.SocialNetworkMessageGrpClsIn, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsInResponse} returns this
 */
proto.proto.SocialNetworkListMessageGrpClsInResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional SocialNetworkListMessageGrpClsInRequest params = 2;
 * @return {?proto.proto.SocialNetworkListMessageGrpClsInRequest}
 */
proto.proto.SocialNetworkListMessageGrpClsInResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.SocialNetworkListMessageGrpClsInRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkListMessageGrpClsInRequest, 2));
};


/**
 * @param {?proto.proto.SocialNetworkListMessageGrpClsInRequest|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpClsInResponse} returns this
*/
proto.proto.SocialNetworkListMessageGrpClsInResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsInResponse} returns this
 */
proto.proto.SocialNetworkListMessageGrpClsInResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpClsInResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkListMessageGrpClsOutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkListMessageGrpClsOutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.SocialNetworkMessageGrpClsFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.SocialNetworkMessageGrpClsSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.SocialNetworkMessageGrpClsPagination.toObject(includeInstance, f),
    classId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    notClassifierOnly: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsOutRequest}
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkListMessageGrpClsOutRequest;
  return proto.proto.SocialNetworkListMessageGrpClsOutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkListMessageGrpClsOutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsOutRequest}
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageGrpClsFilter;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpClsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.SocialNetworkMessageGrpClsSort;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpClsSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.SocialNetworkMessageGrpClsPagination;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpClsPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotClassifierOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkListMessageGrpClsOutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkListMessageGrpClsOutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageGrpClsFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.SocialNetworkMessageGrpClsSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.SocialNetworkMessageGrpClsPagination.serializeBinaryToWriter
    );
  }
  f = message.getClassId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNotClassifierOnly();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional SocialNetworkMessageGrpClsFilter filter = 1;
 * @return {?proto.proto.SocialNetworkMessageGrpClsFilter}
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpClsFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpClsFilter, 1));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpClsFilter|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpClsOutRequest} returns this
*/
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsOutRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SocialNetworkMessageGrpClsSort sort = 2;
 * @return {?proto.proto.SocialNetworkMessageGrpClsSort}
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpClsSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpClsSort, 2));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpClsSort|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpClsOutRequest} returns this
*/
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsOutRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SocialNetworkMessageGrpClsPagination pagination = 3;
 * @return {?proto.proto.SocialNetworkMessageGrpClsPagination}
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpClsPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpClsPagination, 3));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpClsPagination|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpClsOutRequest} returns this
*/
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsOutRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 class_id = 4;
 * @return {number}
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.getClassId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkListMessageGrpClsOutRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.setClassId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool not_classifier_only = 5;
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.getNotClassifierOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.SocialNetworkListMessageGrpClsOutRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpClsOutRequest.prototype.setNotClassifierOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SocialNetworkListMessageGrpClsOutResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkListMessageGrpClsOutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkListMessageGrpClsOutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkListMessageGrpClsOutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpClsOutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.SocialNetworkMessageGrpClsOut.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.SocialNetworkListMessageGrpClsOutRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsOutResponse}
 */
proto.proto.SocialNetworkListMessageGrpClsOutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkListMessageGrpClsOutResponse;
  return proto.proto.SocialNetworkListMessageGrpClsOutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkListMessageGrpClsOutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsOutResponse}
 */
proto.proto.SocialNetworkListMessageGrpClsOutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageGrpClsOut;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpClsOut.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.SocialNetworkListMessageGrpClsOutRequest;
      reader.readMessage(value,proto.proto.SocialNetworkListMessageGrpClsOutRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkListMessageGrpClsOutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkListMessageGrpClsOutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkListMessageGrpClsOutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpClsOutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageGrpClsOut.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.SocialNetworkListMessageGrpClsOutRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SocialNetworkMessageGrpClsOut items = 1;
 * @return {!Array<!proto.proto.SocialNetworkMessageGrpClsOut>}
 */
proto.proto.SocialNetworkListMessageGrpClsOutResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.SocialNetworkMessageGrpClsOut>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.SocialNetworkMessageGrpClsOut, 1));
};


/**
 * @param {!Array<!proto.proto.SocialNetworkMessageGrpClsOut>} value
 * @return {!proto.proto.SocialNetworkListMessageGrpClsOutResponse} returns this
*/
proto.proto.SocialNetworkListMessageGrpClsOutResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.SocialNetworkMessageGrpClsOut=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.SocialNetworkMessageGrpClsOut}
 */
proto.proto.SocialNetworkListMessageGrpClsOutResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.SocialNetworkMessageGrpClsOut, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsOutResponse} returns this
 */
proto.proto.SocialNetworkListMessageGrpClsOutResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional SocialNetworkListMessageGrpClsOutRequest params = 2;
 * @return {?proto.proto.SocialNetworkListMessageGrpClsOutRequest}
 */
proto.proto.SocialNetworkListMessageGrpClsOutResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.SocialNetworkListMessageGrpClsOutRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkListMessageGrpClsOutRequest, 2));
};


/**
 * @param {?proto.proto.SocialNetworkListMessageGrpClsOutRequest|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpClsOutResponse} returns this
*/
proto.proto.SocialNetworkListMessageGrpClsOutResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpClsOutResponse} returns this
 */
proto.proto.SocialNetworkListMessageGrpClsOutResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpClsOutResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
