import { GetterTree, MutationTree, ActionTree } from "vuex";
import { SocialNetworkLoadApi } from "../services/api/SocialNetworkLoadApi";

const socialNetworkLoadApi = new SocialNetworkLoadApi()

class State {
    isListSocialNetworkLoadBusy: boolean = false;
    listSocialNetworkLoadItems: any;
    loadSocialNetwork: any
    loadStatus: any
    listPagination: any
    stream: any
    loadButton: boolean
}

const getters = <GetterTree<State, any>> {
    isListSocialNetworkLoadBusy(state): boolean {
        return state.isListSocialNetworkLoadBusy;
    },

    getListSocialNetworkLoadItems(state): unknown[] {
        return state.listSocialNetworkLoadItems;
    },

    getLoadSocialNetworkStatus(state) {
        return state.loadStatus
    },

    getListSocialNetworkPagination(state) {
        return state.listPagination
    },

    getSocialNetworkStream(state) {
        return state.stream
    },

    getLoadSocialNetworkButton(state) {
        return state.loadButton
    },
}

const mutations = <MutationTree<State>> {
    setIsListSocialNetworkLoadBusy(state, payload) {
        state.isListSocialNetworkLoadBusy = payload
    },

    setListSocialNetworkLoadItems(state, payload) {
        state.listSocialNetworkLoadItems = payload
    },

    setLoadStatus(state, payload) {
        state.loadStatus = payload
    },

    setListPagination(state, payload) {
        state.listPagination = payload
    },

    setStream(state, payload) {
        state.stream = payload
    },

    setLoadButton(state, payload) {
        state.loadButton = payload
    },
}

const actions = <ActionTree<State, any>> {
    getListSocialNetworkLoadItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsListSocialNetworkLoadBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {
            socialNetworkLoadApi.getSocialNetworkLoadItems(filter, sort, pagination)
                .then((msg: any) => {
                    const items = msg.getItemsList()
                    const sort = msg.getParams().getSort()
                    const pagination = msg.getParams().getPagination()
                    commit('setListSocialNetworkLoadItems', items)
                    commit('setIsListSocialNetworkLoadBusy', false)
                    resolve({
                        items: items,
                        sort: sort,
                        pagination: pagination
                    })
                })
                .catch((err) => {
                    commit('setIsListSocialNetworkLoadBusy', false)
                    reject(err)
                })
        })

        // const stream = loadPolls.getPollLoadItems(filter, sort, pagination)
        // let items = {} as any
        //
        // commit('setStream', stream)
        //
        // stream
        //     .on('data', (response: any) => {
        //         console.log('LOAD POLLS RESP', response)
        //         items = {
        //             items: response.getItemsList(),
        //             sort: response.getParams().getSort(),
        //             pagination: response.getParams().getPagination()
        //         }
        //
        //         commit('setIsListPollLoadBusy', false)
        //         commit('setListPollLoadItems', items.items)
        //         commit('setListPagination', items.pagination)
        //
        //         console.log('datadatadata')
        //     })
        //     .on('end', () => {
        //         commit('setListPollLoadItems', items.items)
        //         commit('setListPagination', items.pagination)
        //         commit('setIsListPollLoadBusy', false)
        //
        //         console.log('endendend')
        //
        //         // Notify.create({
        //         //     type: 'positive',
        //         //     message: 'Опрос успешно загружен'
        //         // })
        //     })
    },

    loadSocialNetwork({ commit }, data: any) {
        const social_network_id: any = data !== undefined && data.social_network_id !== undefined ? data.social_network_id : 0;

        console.log('SocialNetwork', social_network_id, data)

        return new Promise((resolve, reject) => {
            socialNetworkLoadApi.getSocialNetworkLoad(social_network_id)
                .then((msg) => {
                    console.log('SocialNetwork LOAD MSG', msg)
                    resolve(msg)
                })
                .catch((err) => {
                    console.log('SocialNetwork LOAD ERR', err)
                    reject(err)
                })
        })
    },

    checkLoadSocialNetworkStatus({ commit }, data: any) {
        return new Promise((resolve, reject) => {
            socialNetworkLoadApi.checkSocialNetworkLoad()
                .then((msg) => {
                    console.log('SocialNetwork LOAD STATUS MSG', msg)
                    commit('setLoadStatus', msg)
                    resolve(msg)
                })
                .catch((err) => {
                    console.log('SocialNetwork LOAD STATUS ERR', err)
                    reject(err)
                })
        })
    },
}

const LoadSocialNetworkModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default LoadSocialNetworkModule