import { UpdateInfoServicePromiseClient } from '../../proto/pb/update_info_grpc_web_pb'
import {
    ListUpdateInfoRequest
} from '../../proto/pb/update_info_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class UpdateInfoApi {
    client: UpdateInfoServicePromiseClient
    metadata: any

    constructor() {
        const host: string = (window as any).VUE_APP_API_URL ?? ''
        this.client = new UpdateInfoServicePromiseClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    getUpdateInfo() {
        const req = new ListUpdateInfoRequest()

        return this.client.listUpdateInfo(req, this.metadata)
    }
}