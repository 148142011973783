import { DashboardProjectIntersectionServiceClient } from '../../proto/pb/Project_intersectionServiceClientPb'
import {
  ListDashboardProjectIntersectionRequest,
  ListDashboardProjectIntersectionActivityRequest,
  ListDashboardProjectIntersectionRegistrationRequest,
  AccountListDashboardProjectIntersectionRequest,
  AccountListDashboardProjectIntersectionActivityRequest,
  PersonalAccountListDashboardProjectIntersectionRequest,
  PersonalAccountListDashboardProjectIntersectionActivityRequest,
  DashboardProjectIntersectionFilter,
  DashboardProjectIntersectionFilterItem,
  DashboardProjectIntersectionSort,
  DashboardProjectIntersectionPagination,
} from '../../proto/pb/project_intersection_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class ProjectIntersectionApi {
  client: DashboardProjectIntersectionServiceClient
  metadata: any

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? ''
    this.client = new DashboardProjectIntersectionServiceClient(
      host,
      null,
        {unaryInterceptors: [new RefreshTokenInterceptor()]}
    )
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  createSort(data: any) {
    const sort = new DashboardProjectIntersectionSort()
    const name: string =
      data.name !== undefined ? data.name.toString() : ''
    const exp: string = data.exp !== undefined ? data.exp.toString() : 'asc'

    // Устанавливаем параметры сортировки
    sort.setName(name)
    sort.setExp(exp)

    return sort
  }

  createFilter(data: any) {
    const filter = new DashboardProjectIntersectionFilter()
    if (data && data.length) {
      for (const i in data) {
        if (data[i].field_name && '' !== data[i].field_name) {
          const fItem = new DashboardProjectIntersectionFilterItem()
          fItem.setFieldName(data[i].field_name.toString())
          fItem.setOperand(
            data[i].operand !== undefined ? data[i].operand : '='
          )
          fItem.setValue(
            data[i].value !== undefined ? data[i].value.toString() : ''
          )
          fItem.setOr(this.createFilter(data[i].or)) // Рекурсия !!!
          filter.addItems(fItem)
        }
      }
    }
    return filter
  }

  createPagination(data: any) {
    const pagination = new DashboardProjectIntersectionPagination()
    const page: number = data.page !== undefined ? Number(data.page) : 1
    const limit: number =
        data.limit !== undefined ? Number(data.limit) : 1000
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0

    // Устанавливаем пагинацию
    pagination.setPage(page)
    pagination.setLimit(limit)
    pagination.setPages(pages).setCnt(cnt)

    return pagination
  }

  getIntersectionList(filter: any, sort: any, data: any) {
    const request = new ListDashboardProjectIntersectionRequest()

    request
      .setFilter(this.createFilter(filter))
      .setSort(this.createSort(sort))
      .setProjectIdsList(data.project_ids)
      .setActivityIdsList(data.activity)
      .setLoyaltyIdsList(data.loyalty)
      .setUsefulnessIdsList(data.useful)
      .setInterestIdsList(data.interest)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)

    console.log('ПЕРЕСЕЧЕНИЕ запрос', request)

    return this.client.listDashboardProjectIntersection(request, this.metadata)
        .then((message) => {
            return message.getItemsList()
        })
  }

  getActivityList(filter: any, sort: any, data: any) {
    const request = new ListDashboardProjectIntersectionActivityRequest()

    request
      .setFilter(this.createFilter(filter))
      .setSort(this.createSort(sort))
      .setProjectIdsList(data.project_ids)
      .setActivityIdsList(data.activity)
      .setLoyaltyIdsList(data.loyalty)
      .setUsefulnessIdsList(data.useful)
      .setInterestIdsList(data.interest)
      .setValue(data.value)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)

    console.log('АКТИВНОЕ ЯДРО запрос', request)

    return this.client.listDashboardProjectIntersectionActivity(request, this.metadata)
        .then((message) => {
            return message.getItemsList()
        })
  }

  getRegistrationList(filter: any, sort: any, data: any) {
    const request = new ListDashboardProjectIntersectionRegistrationRequest()

    request
        .setFilter(this.createFilter(filter))
        .setSort(this.createSort(sort))
        .setProjectIdsList(data.project_ids)
        .setActivityIdsList(data.activity)
        .setLoyaltyIdsList(data.loyalty)
        .setUsefulnessIdsList(data.useful)
        .setInterestIdsList(data.interest)
        .setDateBegin(data.date_begin)
        .setDateEnd(data.date_end)

    console.log('ЯДРО РЕГИСТРАЦИЙ запрос', data)

    return this.client.listDashboardProjectIntersectionRegistration(request, this.metadata)
        .then((message) => {
          console.log('chgecl', message.toObject())
            return message.getItemsList()
        })
  }

  getIntersectionModal(filter: any, pagination: any, data: any) {
    const request = new AccountListDashboardProjectIntersectionRequest()

    request
        .setFilter(this.createFilter(filter))
        .setPagination(this.createPagination(pagination))
        .setActivityIdsList(data.activity)
        .setLoyaltyIdsList(data.loyalty)
        .setUsefulnessIdsList(data.useful)
        .setInterestIdsList(data.interest)
        .setProjectYesList(data.project_yes)
        .setDateBegin(data.date_begin)
        .setDateEnd(data.date_end)

    console.log('ПЕРЕСЕЧЕНИЕ МОДАЛКА запрос', request)

    return this.client.accountListDashboardProjectIntersection(request, this.metadata)
  }

  getPersonalIntersectionModal(filter: any, data: any) {
    const request = new PersonalAccountListDashboardProjectIntersectionRequest()

    request
        .setFilter(this.createFilter(filter))
        .setActivityIdsList(data.activity)
        .setLoyaltyIdsList(data.loyalty)
        .setUsefulnessIdsList(data.useful)
        .setInterestIdsList(data.interest)
        .setProjectYesList(data.project_yes)
        .setDateBegin(data.date_begin)
        .setDateEnd(data.date_end)

    console.log('ПЕРЕСЕЧЕНИЕ МОДАЛКА ПЕРСОНАЛЬНАЯ запрос', request)

    return this.client.personalAccountListDashboardProjectIntersection(request, this.metadata)
  }

  getActivityModal(filter: any, pagination: any, data: any) {
    const request = new AccountListDashboardProjectIntersectionActivityRequest()

    request
        .setFilter(this.createFilter(filter))
        .setPagination(this.createPagination(pagination))
        .setActivityIdsList(data.activity)
        .setLoyaltyIdsList(data.loyalty)
        .setUsefulnessIdsList(data.useful)
        .setInterestIdsList(data.interest)
        .setProjectYesList(data.project_yes)
        .setDateBegin(data.date_begin)
        .setDateEnd(data.date_end)
        .setProjectIdsList(data.project_ids)
        .setValue(data.value)

    console.log('АКТИВНОЕ ЯДРО МОДАЛКА запрос', request)

    return this.client.accountListDashboardProjectIntersectionActivity(request, this.metadata)
  }

  getPersonalActivityModal(filter: any, data: any) {
    const request = new PersonalAccountListDashboardProjectIntersectionActivityRequest()

    request
        .setFilter(this.createFilter(filter))
        .setActivityIdsList(data.activity)
        .setLoyaltyIdsList(data.loyalty)
        .setUsefulnessIdsList(data.useful)
        .setInterestIdsList(data.interest)
        .setProjectYesList(data.project_yes)
        .setDateBegin(data.date_begin)
        .setDateEnd(data.date_end)
        .setProjectIdsList(data.project_ids)
        .setValue(data.value)

    console.log('АКТИВНОЕ ЯДРО МОДАЛКА ПЕРСОНАЛЬНАЯ запрос', request)

    return this.client.personalAccountListDashboardProjectIntersectionActivity(request, this.metadata)
  }
}
