/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: metric_account_value.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as metric_account_value_pb from './metric_account_value_pb';


export class ActivityMetricAccountValueServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListIntersectionActivityMetricAccountValue = new grpcWeb.MethodDescriptor(
    '/proto.ActivityMetricAccountValueService/ListIntersectionActivityMetricAccountValue',
    grpcWeb.MethodType.UNARY,
    metric_account_value_pb.ListIntersectionActivityMetricAccountValueRequest,
    metric_account_value_pb.ListIntersectionActivityMetricAccountValueResponse,
    (request: metric_account_value_pb.ListIntersectionActivityMetricAccountValueRequest) => {
      return request.serializeBinary();
    },
    metric_account_value_pb.ListIntersectionActivityMetricAccountValueResponse.deserializeBinary
  );

  listIntersectionActivityMetricAccountValue(
    request: metric_account_value_pb.ListIntersectionActivityMetricAccountValueRequest,
    metadata: grpcWeb.Metadata | null): Promise<metric_account_value_pb.ListIntersectionActivityMetricAccountValueResponse>;

  listIntersectionActivityMetricAccountValue(
    request: metric_account_value_pb.ListIntersectionActivityMetricAccountValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metric_account_value_pb.ListIntersectionActivityMetricAccountValueResponse) => void): grpcWeb.ClientReadableStream<metric_account_value_pb.ListIntersectionActivityMetricAccountValueResponse>;

  listIntersectionActivityMetricAccountValue(
    request: metric_account_value_pb.ListIntersectionActivityMetricAccountValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metric_account_value_pb.ListIntersectionActivityMetricAccountValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityMetricAccountValueService/ListIntersectionActivityMetricAccountValue',
        request,
        metadata || {},
        this.methodDescriptorListIntersectionActivityMetricAccountValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityMetricAccountValueService/ListIntersectionActivityMetricAccountValue',
    request,
    metadata || {},
    this.methodDescriptorListIntersectionActivityMetricAccountValue);
  }

  methodDescriptorDbscanListIntersectionActivityMetricAccountValue = new grpcWeb.MethodDescriptor(
    '/proto.ActivityMetricAccountValueService/DbscanListIntersectionActivityMetricAccountValue',
    grpcWeb.MethodType.UNARY,
    metric_account_value_pb.DbscanListIntersectionActivityMetricAccountValueRequest,
    metric_account_value_pb.DbscanListIntersectionActivityMetricAccountValueResponse,
    (request: metric_account_value_pb.DbscanListIntersectionActivityMetricAccountValueRequest) => {
      return request.serializeBinary();
    },
    metric_account_value_pb.DbscanListIntersectionActivityMetricAccountValueResponse.deserializeBinary
  );

  dbscanListIntersectionActivityMetricAccountValue(
    request: metric_account_value_pb.DbscanListIntersectionActivityMetricAccountValueRequest,
    metadata: grpcWeb.Metadata | null): Promise<metric_account_value_pb.DbscanListIntersectionActivityMetricAccountValueResponse>;

  dbscanListIntersectionActivityMetricAccountValue(
    request: metric_account_value_pb.DbscanListIntersectionActivityMetricAccountValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metric_account_value_pb.DbscanListIntersectionActivityMetricAccountValueResponse) => void): grpcWeb.ClientReadableStream<metric_account_value_pb.DbscanListIntersectionActivityMetricAccountValueResponse>;

  dbscanListIntersectionActivityMetricAccountValue(
    request: metric_account_value_pb.DbscanListIntersectionActivityMetricAccountValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metric_account_value_pb.DbscanListIntersectionActivityMetricAccountValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityMetricAccountValueService/DbscanListIntersectionActivityMetricAccountValue',
        request,
        metadata || {},
        this.methodDescriptorDbscanListIntersectionActivityMetricAccountValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityMetricAccountValueService/DbscanListIntersectionActivityMetricAccountValue',
    request,
    metadata || {},
    this.methodDescriptorDbscanListIntersectionActivityMetricAccountValue);
  }

  methodDescriptorAccountListIntersectionActivityMetricAccountValue = new grpcWeb.MethodDescriptor(
    '/proto.ActivityMetricAccountValueService/AccountListIntersectionActivityMetricAccountValue',
    grpcWeb.MethodType.UNARY,
    metric_account_value_pb.AccountListIntersectionActivityMetricAccountValueRequest,
    metric_account_value_pb.AccountListIntersectionActivityMetricAccountValueResponse,
    (request: metric_account_value_pb.AccountListIntersectionActivityMetricAccountValueRequest) => {
      return request.serializeBinary();
    },
    metric_account_value_pb.AccountListIntersectionActivityMetricAccountValueResponse.deserializeBinary
  );

  accountListIntersectionActivityMetricAccountValue(
    request: metric_account_value_pb.AccountListIntersectionActivityMetricAccountValueRequest,
    metadata: grpcWeb.Metadata | null): Promise<metric_account_value_pb.AccountListIntersectionActivityMetricAccountValueResponse>;

  accountListIntersectionActivityMetricAccountValue(
    request: metric_account_value_pb.AccountListIntersectionActivityMetricAccountValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metric_account_value_pb.AccountListIntersectionActivityMetricAccountValueResponse) => void): grpcWeb.ClientReadableStream<metric_account_value_pb.AccountListIntersectionActivityMetricAccountValueResponse>;

  accountListIntersectionActivityMetricAccountValue(
    request: metric_account_value_pb.AccountListIntersectionActivityMetricAccountValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metric_account_value_pb.AccountListIntersectionActivityMetricAccountValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityMetricAccountValueService/AccountListIntersectionActivityMetricAccountValue',
        request,
        metadata || {},
        this.methodDescriptorAccountListIntersectionActivityMetricAccountValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityMetricAccountValueService/AccountListIntersectionActivityMetricAccountValue',
    request,
    metadata || {},
    this.methodDescriptorAccountListIntersectionActivityMetricAccountValue);
  }

  methodDescriptorDbscanAccountListIntersectionActivityMetricAccountValue = new grpcWeb.MethodDescriptor(
    '/proto.ActivityMetricAccountValueService/DbscanAccountListIntersectionActivityMetricAccountValue',
    grpcWeb.MethodType.UNARY,
    metric_account_value_pb.DbscanAccountListIntersectionActivityMetricAccountValueRequest,
    metric_account_value_pb.DbscanAccountListIntersectionActivityMetricAccountValueResponse,
    (request: metric_account_value_pb.DbscanAccountListIntersectionActivityMetricAccountValueRequest) => {
      return request.serializeBinary();
    },
    metric_account_value_pb.DbscanAccountListIntersectionActivityMetricAccountValueResponse.deserializeBinary
  );

  dbscanAccountListIntersectionActivityMetricAccountValue(
    request: metric_account_value_pb.DbscanAccountListIntersectionActivityMetricAccountValueRequest,
    metadata: grpcWeb.Metadata | null): Promise<metric_account_value_pb.DbscanAccountListIntersectionActivityMetricAccountValueResponse>;

  dbscanAccountListIntersectionActivityMetricAccountValue(
    request: metric_account_value_pb.DbscanAccountListIntersectionActivityMetricAccountValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metric_account_value_pb.DbscanAccountListIntersectionActivityMetricAccountValueResponse) => void): grpcWeb.ClientReadableStream<metric_account_value_pb.DbscanAccountListIntersectionActivityMetricAccountValueResponse>;

  dbscanAccountListIntersectionActivityMetricAccountValue(
    request: metric_account_value_pb.DbscanAccountListIntersectionActivityMetricAccountValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metric_account_value_pb.DbscanAccountListIntersectionActivityMetricAccountValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityMetricAccountValueService/DbscanAccountListIntersectionActivityMetricAccountValue',
        request,
        metadata || {},
        this.methodDescriptorDbscanAccountListIntersectionActivityMetricAccountValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityMetricAccountValueService/DbscanAccountListIntersectionActivityMetricAccountValue',
    request,
    metadata || {},
    this.methodDescriptorDbscanAccountListIntersectionActivityMetricAccountValue);
  }

  methodDescriptorPersonalAccountListIntersectionActivityMetricAccountValue = new grpcWeb.MethodDescriptor(
    '/proto.ActivityMetricAccountValueService/PersonalAccountListIntersectionActivityMetricAccountValue',
    grpcWeb.MethodType.UNARY,
    metric_account_value_pb.PersonalAccountListIntersectionActivityMetricAccountValueRequest,
    metric_account_value_pb.PersonalAccountListIntersectionActivityMetricAccountValueResponse,
    (request: metric_account_value_pb.PersonalAccountListIntersectionActivityMetricAccountValueRequest) => {
      return request.serializeBinary();
    },
    metric_account_value_pb.PersonalAccountListIntersectionActivityMetricAccountValueResponse.deserializeBinary
  );

  personalAccountListIntersectionActivityMetricAccountValue(
    request: metric_account_value_pb.PersonalAccountListIntersectionActivityMetricAccountValueRequest,
    metadata: grpcWeb.Metadata | null): Promise<metric_account_value_pb.PersonalAccountListIntersectionActivityMetricAccountValueResponse>;

  personalAccountListIntersectionActivityMetricAccountValue(
    request: metric_account_value_pb.PersonalAccountListIntersectionActivityMetricAccountValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metric_account_value_pb.PersonalAccountListIntersectionActivityMetricAccountValueResponse) => void): grpcWeb.ClientReadableStream<metric_account_value_pb.PersonalAccountListIntersectionActivityMetricAccountValueResponse>;

  personalAccountListIntersectionActivityMetricAccountValue(
    request: metric_account_value_pb.PersonalAccountListIntersectionActivityMetricAccountValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metric_account_value_pb.PersonalAccountListIntersectionActivityMetricAccountValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityMetricAccountValueService/PersonalAccountListIntersectionActivityMetricAccountValue',
        request,
        metadata || {},
        this.methodDescriptorPersonalAccountListIntersectionActivityMetricAccountValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityMetricAccountValueService/PersonalAccountListIntersectionActivityMetricAccountValue',
    request,
    metadata || {},
    this.methodDescriptorPersonalAccountListIntersectionActivityMetricAccountValue);
  }

}

