import {
  AnswerGrpServiceClient
} from "../../proto/pb/Answer_grpServiceClientPb";
import {
  AnswerGrp, 
  ListAnswerGrpRequest,
  //ItemAnswerGrpRequest,
  ListAnswerGrpWithFindAnswerRequest,
  ListAnswerGrpChildsRequest,
  AnswerGrpFilter,
  AnswerGrpFilterItem,
  AnswerGrpSort,
  AnswerGrpPagination,
  CreateAnswerGrpRequest,  
  UpdateAnswerGrpRequest, 
  UpdateTextAnswerGrpRequest, 
  DeleteAnswerGrpRequest,
  ReduceAnswerGrpRequest,
  SplitAnswerGrpRequest,
  SeparateAnswerGrpRequest,
} from "../../proto/pb/answer_grp_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 1000;

export class AnswerGrpApi {
  items: Array<AnswerGrp> = [];
  client: AnswerGrpServiceClient;
  metadata: any;

  constructor() {
    const host:string = (window as any).VUE_APP_API_URL ?? '';
    this.client = new AnswerGrpServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  // Установка и получение фильтров
  createFilter(data: any){

    const filter = new AnswerGrpFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
        for (const i in data) {
            if (data[i].field_name && ('' !== data[i].field_name)) {
                const fItem = new AnswerGrpFilterItem();
                fItem.setFieldName(data[i].field_name.toString());
                fItem.setOperand(data[i].operand !== undefined ? data[i].operand : '=');
                fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                filter.addItems(fItem);
            }
        }
    }

    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any){

    const sort = new AnswerGrpSort();
    const name:string = data.name !== undefined ? data.name.toString() : 'ag.answer_grp_text';
    const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any){

    const pagination = new AnswerGrpPagination();
    const page:number = data.page !== undefined ? Number(data.page) : 1;
    const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages:number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt:number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  // Список элементов (результирующая таблица, вместе с реальным hasChilds)
  getAnswerGrpResultItems(filter: any, sort: any, pagination: any, question_id: number){

    const request = new ListAnswerGrpRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));
    request.setQuestionId(question_id);
    request.setIsWorked(false);

    console.log('getAnswerGrpResultItems request', request)

    return this.client.listAnswerGrp(request, this.metadata)
  }

  // Список элементов (результирующая таблица, вместе с реальным hasChilds)
  // с поиском страницы для заданного ответа
  getAnswerGrpWithFindAnswerResultItems(filter: any, sort: any, pagination: number, question_id: number, find_id: number){

    const request = new ListAnswerGrpWithFindAnswerRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));
    request.setQuestionId(question_id);
    request.setAnswerGrpId(find_id);

    return this.client.listAnswerGrpWithFindAnswer(request, this.metadata)
  }

  // Список дочерних элементов (часть результирующей таблицы, с hasChilds = false)
  getAnswerGrpChildsItems(filter: any, sort: any, pagination: any, parent_id: number){

    const request = new ListAnswerGrpChildsRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));
    request.setAnswerGrpId(parent_id);

    return this.client.listAnswerGrpChilds(request, this.metadata)
  }

  // Список элементов (таблица для обработки, с hasChilds = false)
  getAnswerGrpWorkedItems(filter: any, sort: any, pagination: any, question_id: number){

    const request = new ListAnswerGrpRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter))
    request.setSort(this.createSort(sort))
    request.setPagination(this.createPagination(pagination))
    request.setQuestionId(question_id)
    request.setIsWorked(true)

    return this.client.listAnswerGrp(request, this.metadata)
  }

  // Создание элемента
  createAnswerGrpItem(data: any){

    const request = new CreateAnswerGrpRequest();
    request.setAnswerGrpParentId(data.parent_id);
    request.setAnswerId(data.answer_id);
    request.setAnswerGrpText(data.text);
    request.setAnswerGrpCount(data.count);
    request.setAnswerGrpProcessed(data.processed);

    return this.client.createAnswerGrp(request, this.metadata);
  }

  // Редактирование элемента
  updateAnswerGrpItem(data: any){

    const request = new UpdateAnswerGrpRequest();
    request.setAnswerGrpId(data.id);
    request.setAnswerGrpParentId(data.parent_id);
    request.setAnswerId(data.answer_id);
    request.setAnswerGrpText(data.text);
    request.setAnswerGrpCount(data.count);
    request.setAnswerGrpProcessed(data.processed);

    return this.client.updateAnswerGrp(request, this.metadata)
  }

  // Редактирование текста элемента
  updateTextAnswerGrpItem(data: any){

    const request = new UpdateTextAnswerGrpRequest();
    request.setAnswerGrpId(data.id);
    request.setAnswerGrpText(data.text);

    return this.client.updateTextAnswerGrp(request, this.metadata);
  }

  // Удаление элемента
  deleteAnswerGrpItem(data: any){

    const request = new DeleteAnswerGrpRequest();
    request.setAnswerGrpId(data.id);

    return this.client.deleteAnswerGrp(request, this.metadata);
  }

  // Сведение ответов к одному (reduce)
  reduceAnswerGrpItems(data: any){

    const request = new ReduceAnswerGrpRequest();
    request.setAnswerGrpId(data.id);
    request.setReducedIdsList(data.reduced_ids);

    return this.client.reduceAnswerGrp(request, this.metadata);
  }

  // Разбиение ответов по разделителю (split)
  splitAnswerGrpItems(data: any){

    const request = new SplitAnswerGrpRequest();
    request.setSplitString(data.split_string);
    request.setSplittedIdsList(data.splitted_ids);

    return this.client.splitAnswerGrp(request, this.metadata);
  }

  separateAnswerGrpItems(data: any) {
    const request = new SeparateAnswerGrpRequest()

    request
        .setSeparatedIdsList(data.separated_ids_list)

    console.log('SEPARATE ANSWERS REQ', request)

    return this.client.separateAnswerGrp(request, this.metadata)
  }
}
