import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { ActionsApi } from '../services/api/ActionsApi'
import Loading from 'quasar/src/plugins/loading/Loading.js';

class State {
  isActionsBusy: boolean = false
  actionsList: Array<any> = []
  allActionsList: Array<String> = []
}

const actionsApi = new ActionsApi()

const getters = <GetterTree<State, any>>{
  isActionsBusy(state): boolean {
    return state.isActionsBusy
  },

  getActionsList(state) {
    return state.actionsList
  },

  getAllActionsList(state) {
    return state.allActionsList
  },
}

const mutations = <MutationTree<State>>{
  setIsActionsBusy(state, payload: boolean) {
    state.isActionsBusy = payload
    if (payload) {
      Loading.show()
    } else {
      Loading.hide()
    }
  },

  setActionsList(state, payload) {
    state.actionsList = payload
  },

  setAllActionsList(state, payload) {
    state.allActionsList = payload
  },
}

const actions = <ActionTree<State, any>>{
  getActionsList({ commit }, data: any) {
    commit('setIsActionsBusy', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      actionsApi.getActionList(filter).then(
        (message: any) => {
          const items = message.getItemsList()

          commit('setActionsList', items)
          commit('setIsActionsBusy', false)

          resolve(items)
        },
        (error) => {
          commit('setActionsList', [])
          commit('setIsActionsBusy', false)
          reject(error)
        }
      )
    })
  },

  setAllActionsList({ commit }, data: any) {
    commit('setAllActionsList', data)

    return data
  },

  getActionsPointsList({ commit }, data: any) {
    commit('setIsActionsBusy', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      actionsApi.getActionPointList(filter).then(
        (message: any) => {
          const items = message.getItemsList()

          commit('setActionsList', items)
          commit('setIsActionsBusy', false)

          resolve(items)
        },
        (error) => {
          commit('setActionsList', [])
          commit('setIsActionsBusy', false)
          reject(error)
        }
      )
    })
  },
}

const AccountActionsModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default AccountActionsModule
