import { FamilyStatusServiceClient } from '../../proto/pb/Family_statusServiceClientPb'
import {
    FamilyStatusFilter,
    FamilyStatusFilterItem,
    FamilyStatusPagination,
    FamilyStatusSort,
    ListFamilyStatusRequest,
} from '../../proto/pb/family_status_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

const defaultLimit: number = 1000

export class FamilyApi {
    client: FamilyStatusServiceClient
    metadata: any

    constructor() {
        const host: string = (window as any).VUE_APP_API_URL ?? ''
        this.client = new FamilyStatusServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    createFilter(data: any, newFilter: any, newFilterItem: any) {
        const filter = new newFilter()
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && '' !== data[i].field_name) {
                    const fItem = new newFilterItem()
                    fItem.setFieldName(data[i].field_name.toString())
                    fItem.setOperand(
                        data[i].operand !== undefined ? data[i].operand : '='
                    )
                    fItem.setValue(
                        data[i].value !== undefined ? data[i].value.toString() : ''
                    )
                    fItem.setOr(this.createFilter(data[i].or, newFilter, newFilterItem)) // Рекурсия !!!
                    filter.addItems(fItem)
                }
            }
        }
        return filter
    }

    createSort(data: any, sortType: any) {
        const sort = new sortType()
        const name: string = data.name !== undefined ? data.name.toString() : ''
        const exp: string = data.exp !== undefined ? data.exp.toString() : ''

        // Устанавливаем параметры сортировки
        sort.setName(name)
        sort.setExp(exp)

        return sort
    }

    createPagination(data: any, paginationType: any) {
        const pagination = new paginationType()
        const page: number = data.page !== undefined ? Number(data.page) : 1
        const limit: number =
            data.limit !== undefined ? Number(data.limit) : defaultLimit
        const pages: number = data.pages !== undefined ? Number(data.pages) : 0
        const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0

        // Устанавливаем пагинацию
        pagination.setPage(page)
        pagination.setLimit(limit)
        pagination.setPages(pages)
        pagination.setCnt(cnt)

        return pagination
    }

    ListFamily(filter: any) {
        const request = new ListFamilyStatusRequest()
        request
            .setFilter(this.createFilter(filter, FamilyStatusFilter, FamilyStatusFilterItem))
            .setSort(this.createSort({}, FamilyStatusSort))
            .setPagination(this.createPagination({}, FamilyStatusPagination))

        return this.client.listFamilyStatus(request, { Authorization: localStorage.getItem('access-key') } as any)
    }
}
