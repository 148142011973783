import { DashboardActivityCountServiceClient } from '../../proto/pb/Activity_countServiceClientPb'
import {
    GetDashboardActivityCountRequest,
    ListDashboardActivityCountDetailsRequest,
    ListDashboardActivityCountDetailsIntervalRequest,
    DashboardActivityCountFilter,
    DashboardActivityCountFilterItem,
    DashboardActivityCountSort,
    DashboardActivityCountPagination,
} from '../../proto/pb/activity_count_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class ActiveCountApi {
    client: DashboardActivityCountServiceClient
    metadata: any

    constructor() {
        const host: string = (window as any).VUE_APP_API_URL ?? ''
        this.client = new DashboardActivityCountServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    createFilter(data: any) {
        const filter = new DashboardActivityCountFilter()
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && '' !== data[i].field_name) {
                    const fItem = new DashboardActivityCountFilterItem()
                    fItem.setFieldName(data[i].field_name.toString())
                    fItem.setOperand(
                        data[i].operand !== undefined ? data[i].operand : '='
                    )
                    fItem.setValue(
                        data[i].value !== undefined ? data[i].value.toString() : ''
                    )
                    fItem.setOr(this.createFilter(data[i].or)) // Рекурсия !!!
                    filter.addItems(fItem)
                }
            }
        }
        return filter
    }

    createSort(data: any) {
        const sort = new DashboardActivityCountSort()
        const name: string =
            data.name !== undefined ? data.name.toString() : ''
        const exp: string = data.exp !== undefined ? data.exp.toString() : 'asc'

        // Устанавливаем параметры сортировки
        sort.setName(name)
        sort.setExp(exp)

        return sort
    }

    createPagination(data: any) {
        const pagination = new DashboardActivityCountPagination()
        const page: number = data.page !== undefined ? Number(data.page) : 1
        const limit: number =
            data.limit !== undefined ? Number(data.limit) : 1000
        const pages: number = data.pages !== undefined ? Number(data.pages) : 0
        const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0

        // Устанавливаем пагинацию
        pagination.setPage(page)
        pagination.setLimit(limit)
        pagination.setPages(pages).setCnt(cnt)

        return pagination
    }

    getActiveCount(filter: any, data: any) {
        const request = new GetDashboardActivityCountRequest()

        request
            .setFilter(this.createFilter(filter))
            .setActivIdsList(data.activ_ids)
            .setActivityIdsList(data.activity)
            .setLoyaltyIdsList(data.loyalty)
            .setUsefulnessIdsList(data.useful)
            .setInterestIdsList(data.interest)
            .setAllActiv(data.all_active)
            .setDateBegin(data.date_begin)
            .setDateEnd(data.date_end)

        console.log('REQ', request)

        return this.client.getDashboardActivityCount(request, this.metadata)
    }

    getActiveTable(filter: any, sort: any, data: any) {
        const request = new ListDashboardActivityCountDetailsRequest()

        request
            .setFilter(this.createFilter(filter))
            .setSort(this.createSort(sort))
            .setActivIdsList(data.activ_ids)
            .setActivityIdsList(data.activity)
            .setLoyaltyIdsList(data.loyalty)
            .setUsefulnessIdsList(data.useful)
            .setInterestIdsList(data.interest)
            .setAllActiv(data.all_active)
            .setDateBegin(data.date_begin)
            .setDateEnd(data.date_end)

        console.log('WE', request)

        return this.client.listDashboardActivityCountDetails(request, this.metadata)
    }

    getActiveChart(filter: any, sort: any, pagination: any, data: any) {
        const request = new ListDashboardActivityCountDetailsIntervalRequest()

        request
            .setFilter(this.createFilter(filter))
            .setSort(this.createSort(sort))
            .setPagination(this.createPagination(pagination))
            .setActivIdsList(data.activ_ids)
            .setAllActiv(data.all_active)
            .setActivityIdsList(data.activity)
            .setLoyaltyIdsList(data.loyalty)
            .setUsefulnessIdsList(data.useful)
            .setInterestIdsList(data.interest)
            .setTypeInterval(data.interval)
            .setDateBegin(data.date_begin)
            .setDateEnd(data.date_end)

        console.log('ACTIVE CHART REQUEST', request)

        return this.client.listDashboardActivityCountDetailsInterval(request, this.metadata)
    }
}