
import store from '@/store'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'ScorecardPanel',

  components: {
    DataLoading,
  },

  data() {
    return {
      splitterSize: 40,
      columns: [
        {
          name: 'title',
          label: 'Наименование',
          field: 'title',
          align: 'left',
        },
        {
          name: 'points',
          label: 'Балл',
          field: 'points',
          align: 'center',
        },
        {
          name: 'check_rule',
          label: 'Правила проверки',
          field: 'check_rule',
          align: 'left',
        },
      ] as any,
    }
  },

  computed: {
    isScorecardBusy() {
      return store.getters.getIsScorecardBusy
    },

    isScorecardValueBusy() {
      return store.getters.getIsScorecardValueBusy
    },

    isSelectedScorecardNode() {
      return null !== store.getters.getCurrentScorecardNode
    },

    node() {
      let currentScorecardNode = store.getters.getCurrentScorecardNode
      return currentScorecardNode
        ? currentScorecardNode
        : {
            id: 0,
            parent_id: 0,
            idx: 0,
            title: '',
            weight: 0.0,
            parentName: '',
          }
    },

    getScorecardValueItems() {
      const valueItems = store.getters.getScorecardValueItems
      let values = valueItems ?
          valueItems.map((item: any) => {
            return {
              id: item.getScoreValueId(),
              idx: item.getScoreValueIdx(),
              title: item.getScoreValueTitle(),
              points: item.getScoreValuePoints(),
              check_rule: item.getScoreValueCheckRule(),
            }
          }) :
          []
      values.sort((a: any, b: any) => a.idx - b.idx)
      return values
    },
  },

  methods: {
    loadData() {},
  },

  created() {
    this.loadData()
  },
})
