/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: social_network_message_grp.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as social_network_message_grp_pb from './social_network_message_grp_pb';


export class SocialNetworkMessageGrpServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSocialNetworkCreateMessageGrp = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageGrpService/SocialNetworkCreateMessageGrp',
    grpcWeb.MethodType.UNARY,
    social_network_message_grp_pb.SocialNetworkCreateMessageGrpRequest,
    social_network_message_grp_pb.SocialNetworkCreateMessageGrpResponse,
    (request: social_network_message_grp_pb.SocialNetworkCreateMessageGrpRequest) => {
      return request.serializeBinary();
    },
    social_network_message_grp_pb.SocialNetworkCreateMessageGrpResponse.deserializeBinary
  );

  socialNetworkCreateMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkCreateMessageGrpRequest,
    metadata: grpcWeb.Metadata | null): Promise<social_network_message_grp_pb.SocialNetworkCreateMessageGrpResponse>;

  socialNetworkCreateMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkCreateMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkCreateMessageGrpResponse) => void): grpcWeb.ClientReadableStream<social_network_message_grp_pb.SocialNetworkCreateMessageGrpResponse>;

  socialNetworkCreateMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkCreateMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkCreateMessageGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageGrpService/SocialNetworkCreateMessageGrp',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkCreateMessageGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageGrpService/SocialNetworkCreateMessageGrp',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkCreateMessageGrp);
  }

  methodDescriptorSocialNetworkUpdateMessageGrp = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageGrpService/SocialNetworkUpdateMessageGrp',
    grpcWeb.MethodType.UNARY,
    social_network_message_grp_pb.SocialNetworkUpdateMessageGrpRequest,
    social_network_message_grp_pb.SocialNetworkUpdateMessageGrpResponse,
    (request: social_network_message_grp_pb.SocialNetworkUpdateMessageGrpRequest) => {
      return request.serializeBinary();
    },
    social_network_message_grp_pb.SocialNetworkUpdateMessageGrpResponse.deserializeBinary
  );

  socialNetworkUpdateMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkUpdateMessageGrpRequest,
    metadata: grpcWeb.Metadata | null): Promise<social_network_message_grp_pb.SocialNetworkUpdateMessageGrpResponse>;

  socialNetworkUpdateMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkUpdateMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkUpdateMessageGrpResponse) => void): grpcWeb.ClientReadableStream<social_network_message_grp_pb.SocialNetworkUpdateMessageGrpResponse>;

  socialNetworkUpdateMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkUpdateMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkUpdateMessageGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageGrpService/SocialNetworkUpdateMessageGrp',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkUpdateMessageGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageGrpService/SocialNetworkUpdateMessageGrp',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkUpdateMessageGrp);
  }

  methodDescriptorSocialNetworkUpdateTextMessageGrp = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageGrpService/SocialNetworkUpdateTextMessageGrp',
    grpcWeb.MethodType.UNARY,
    social_network_message_grp_pb.SocialNetworkUpdateTextMessageGrpRequest,
    social_network_message_grp_pb.SocialNetworkUpdateTextMessageGrpResponse,
    (request: social_network_message_grp_pb.SocialNetworkUpdateTextMessageGrpRequest) => {
      return request.serializeBinary();
    },
    social_network_message_grp_pb.SocialNetworkUpdateTextMessageGrpResponse.deserializeBinary
  );

  socialNetworkUpdateTextMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkUpdateTextMessageGrpRequest,
    metadata: grpcWeb.Metadata | null): Promise<social_network_message_grp_pb.SocialNetworkUpdateTextMessageGrpResponse>;

  socialNetworkUpdateTextMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkUpdateTextMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkUpdateTextMessageGrpResponse) => void): grpcWeb.ClientReadableStream<social_network_message_grp_pb.SocialNetworkUpdateTextMessageGrpResponse>;

  socialNetworkUpdateTextMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkUpdateTextMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkUpdateTextMessageGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageGrpService/SocialNetworkUpdateTextMessageGrp',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkUpdateTextMessageGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageGrpService/SocialNetworkUpdateTextMessageGrp',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkUpdateTextMessageGrp);
  }

  methodDescriptorSocialNetworkDeleteMessageGrp = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageGrpService/SocialNetworkDeleteMessageGrp',
    grpcWeb.MethodType.UNARY,
    social_network_message_grp_pb.SocialNetworkDeleteMessageGrpRequest,
    social_network_message_grp_pb.SocialNetworkDeleteMessageGrpResponse,
    (request: social_network_message_grp_pb.SocialNetworkDeleteMessageGrpRequest) => {
      return request.serializeBinary();
    },
    social_network_message_grp_pb.SocialNetworkDeleteMessageGrpResponse.deserializeBinary
  );

  socialNetworkDeleteMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkDeleteMessageGrpRequest,
    metadata: grpcWeb.Metadata | null): Promise<social_network_message_grp_pb.SocialNetworkDeleteMessageGrpResponse>;

  socialNetworkDeleteMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkDeleteMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkDeleteMessageGrpResponse) => void): grpcWeb.ClientReadableStream<social_network_message_grp_pb.SocialNetworkDeleteMessageGrpResponse>;

  socialNetworkDeleteMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkDeleteMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkDeleteMessageGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageGrpService/SocialNetworkDeleteMessageGrp',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkDeleteMessageGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageGrpService/SocialNetworkDeleteMessageGrp',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkDeleteMessageGrp);
  }

  methodDescriptorSocialNetworkListMessageGrp = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageGrpService/SocialNetworkListMessageGrp',
    grpcWeb.MethodType.UNARY,
    social_network_message_grp_pb.SocialNetworkListMessageGrpRequest,
    social_network_message_grp_pb.SocialNetworkListMessageGrpResponse,
    (request: social_network_message_grp_pb.SocialNetworkListMessageGrpRequest) => {
      return request.serializeBinary();
    },
    social_network_message_grp_pb.SocialNetworkListMessageGrpResponse.deserializeBinary
  );

  socialNetworkListMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkListMessageGrpRequest,
    metadata: grpcWeb.Metadata | null): Promise<social_network_message_grp_pb.SocialNetworkListMessageGrpResponse>;

  socialNetworkListMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkListMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkListMessageGrpResponse) => void): grpcWeb.ClientReadableStream<social_network_message_grp_pb.SocialNetworkListMessageGrpResponse>;

  socialNetworkListMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkListMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkListMessageGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageGrpService/SocialNetworkListMessageGrp',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkListMessageGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageGrpService/SocialNetworkListMessageGrp',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkListMessageGrp);
  }

  methodDescriptorSocialNetworkListMessageGrpWithFindMessage = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageGrpService/SocialNetworkListMessageGrpWithFindMessage',
    grpcWeb.MethodType.UNARY,
    social_network_message_grp_pb.SocialNetworkListMessageGrpWithFindMessageRequest,
    social_network_message_grp_pb.SocialNetworkListMessageGrpWithFindMessageResponse,
    (request: social_network_message_grp_pb.SocialNetworkListMessageGrpWithFindMessageRequest) => {
      return request.serializeBinary();
    },
    social_network_message_grp_pb.SocialNetworkListMessageGrpWithFindMessageResponse.deserializeBinary
  );

  socialNetworkListMessageGrpWithFindMessage(
    request: social_network_message_grp_pb.SocialNetworkListMessageGrpWithFindMessageRequest,
    metadata: grpcWeb.Metadata | null): Promise<social_network_message_grp_pb.SocialNetworkListMessageGrpWithFindMessageResponse>;

  socialNetworkListMessageGrpWithFindMessage(
    request: social_network_message_grp_pb.SocialNetworkListMessageGrpWithFindMessageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkListMessageGrpWithFindMessageResponse) => void): grpcWeb.ClientReadableStream<social_network_message_grp_pb.SocialNetworkListMessageGrpWithFindMessageResponse>;

  socialNetworkListMessageGrpWithFindMessage(
    request: social_network_message_grp_pb.SocialNetworkListMessageGrpWithFindMessageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkListMessageGrpWithFindMessageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageGrpService/SocialNetworkListMessageGrpWithFindMessage',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkListMessageGrpWithFindMessage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageGrpService/SocialNetworkListMessageGrpWithFindMessage',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkListMessageGrpWithFindMessage);
  }

  methodDescriptorSocialNetworkListMessageGrpChilds = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageGrpService/SocialNetworkListMessageGrpChilds',
    grpcWeb.MethodType.UNARY,
    social_network_message_grp_pb.SocialNetworkListMessageGrpChildsRequest,
    social_network_message_grp_pb.SocialNetworkListMessageGrpChildsResponse,
    (request: social_network_message_grp_pb.SocialNetworkListMessageGrpChildsRequest) => {
      return request.serializeBinary();
    },
    social_network_message_grp_pb.SocialNetworkListMessageGrpChildsResponse.deserializeBinary
  );

  socialNetworkListMessageGrpChilds(
    request: social_network_message_grp_pb.SocialNetworkListMessageGrpChildsRequest,
    metadata: grpcWeb.Metadata | null): Promise<social_network_message_grp_pb.SocialNetworkListMessageGrpChildsResponse>;

  socialNetworkListMessageGrpChilds(
    request: social_network_message_grp_pb.SocialNetworkListMessageGrpChildsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkListMessageGrpChildsResponse) => void): grpcWeb.ClientReadableStream<social_network_message_grp_pb.SocialNetworkListMessageGrpChildsResponse>;

  socialNetworkListMessageGrpChilds(
    request: social_network_message_grp_pb.SocialNetworkListMessageGrpChildsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkListMessageGrpChildsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageGrpService/SocialNetworkListMessageGrpChilds',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkListMessageGrpChilds,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageGrpService/SocialNetworkListMessageGrpChilds',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkListMessageGrpChilds);
  }

  methodDescriptorSocialNetworkReduceMessageGrp = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageGrpService/SocialNetworkReduceMessageGrp',
    grpcWeb.MethodType.UNARY,
    social_network_message_grp_pb.SocialNetworkReduceMessageGrpRequest,
    social_network_message_grp_pb.SocialNetworkReduceMessageGrpResponse,
    (request: social_network_message_grp_pb.SocialNetworkReduceMessageGrpRequest) => {
      return request.serializeBinary();
    },
    social_network_message_grp_pb.SocialNetworkReduceMessageGrpResponse.deserializeBinary
  );

  socialNetworkReduceMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkReduceMessageGrpRequest,
    metadata: grpcWeb.Metadata | null): Promise<social_network_message_grp_pb.SocialNetworkReduceMessageGrpResponse>;

  socialNetworkReduceMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkReduceMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkReduceMessageGrpResponse) => void): grpcWeb.ClientReadableStream<social_network_message_grp_pb.SocialNetworkReduceMessageGrpResponse>;

  socialNetworkReduceMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkReduceMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkReduceMessageGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageGrpService/SocialNetworkReduceMessageGrp',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkReduceMessageGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageGrpService/SocialNetworkReduceMessageGrp',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkReduceMessageGrp);
  }

  methodDescriptorSocialNetworkSplitMessageGrp = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageGrpService/SocialNetworkSplitMessageGrp',
    grpcWeb.MethodType.UNARY,
    social_network_message_grp_pb.SocialNetworkSplitMessageGrpRequest,
    social_network_message_grp_pb.SocialNetworkSplitMessageGrpResponse,
    (request: social_network_message_grp_pb.SocialNetworkSplitMessageGrpRequest) => {
      return request.serializeBinary();
    },
    social_network_message_grp_pb.SocialNetworkSplitMessageGrpResponse.deserializeBinary
  );

  socialNetworkSplitMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkSplitMessageGrpRequest,
    metadata: grpcWeb.Metadata | null): Promise<social_network_message_grp_pb.SocialNetworkSplitMessageGrpResponse>;

  socialNetworkSplitMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkSplitMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkSplitMessageGrpResponse) => void): grpcWeb.ClientReadableStream<social_network_message_grp_pb.SocialNetworkSplitMessageGrpResponse>;

  socialNetworkSplitMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkSplitMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkSplitMessageGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageGrpService/SocialNetworkSplitMessageGrp',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkSplitMessageGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageGrpService/SocialNetworkSplitMessageGrp',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkSplitMessageGrp);
  }

  methodDescriptorSocialNetworkSeparateMessageGrp = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageGrpService/SocialNetworkSeparateMessageGrp',
    grpcWeb.MethodType.UNARY,
    social_network_message_grp_pb.SocialNetworkSeparateMessageGrpRequest,
    social_network_message_grp_pb.SocialNetworkSeparateMessageGrpResponse,
    (request: social_network_message_grp_pb.SocialNetworkSeparateMessageGrpRequest) => {
      return request.serializeBinary();
    },
    social_network_message_grp_pb.SocialNetworkSeparateMessageGrpResponse.deserializeBinary
  );

  socialNetworkSeparateMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkSeparateMessageGrpRequest,
    metadata: grpcWeb.Metadata | null): Promise<social_network_message_grp_pb.SocialNetworkSeparateMessageGrpResponse>;

  socialNetworkSeparateMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkSeparateMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkSeparateMessageGrpResponse) => void): grpcWeb.ClientReadableStream<social_network_message_grp_pb.SocialNetworkSeparateMessageGrpResponse>;

  socialNetworkSeparateMessageGrp(
    request: social_network_message_grp_pb.SocialNetworkSeparateMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_grp_pb.SocialNetworkSeparateMessageGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageGrpService/SocialNetworkSeparateMessageGrp',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkSeparateMessageGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageGrpService/SocialNetworkSeparateMessageGrp',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkSeparateMessageGrp);
  }

}

