import script from "./MetricSelect.vue?vue&type=script&setup=true&lang=ts"
export * from "./MetricSelect.vue?vue&type=script&setup=true&lang=ts"

import "./MetricSelect.vue?vue&type=style&index=0&id=3fc7e9dc&lang=scss"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon});
