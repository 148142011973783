import { SocialNetworkLoadServiceClient } from "../../proto/pb/Social_network_loadServiceClientPb"
import {
    SocialNetworkLoadFilter,
    SocialNetworkLoadFilterItem,
    SocialNetworkLoadSort,
    SocialNetworkLoadPagination,
    ListSocialNetworkLoadRequest,
    LoadSocialNetworkLoadRequest,
    CheckSocialNetworkLoadRequest,
} from "../../proto/pb/social_network_load_pb"
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

const defaultLimit: number = 1000;

export class SocialNetworkLoadApi {
    client: SocialNetworkLoadServiceClient
    metadata: any

    constructor() {
        const host: string = (window as any).VUE_APP_API_URL ?? ''
        this.client = new SocialNetworkLoadServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    createFilter(data: any){

        const filter = new SocialNetworkLoadFilter();

        // Устанавливаем фильтры
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && ('' !== data[i].field_name)) {
                    const fItem = new SocialNetworkLoadFilterItem();
                    fItem.setFieldName(data[i].field_name.toString());
                    fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                    fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                    fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                    filter.addItems(fItem);
                }
            }
        }

        return filter;
    }

    createSort(data: any){

        const sort = new SocialNetworkLoadSort();
        const name:string = data.name !== undefined ? data.name.toString() : '';
        const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    createPagination(data: any){

        const pagination = new SocialNetworkLoadPagination();
        const page:number = data.page !== undefined ? Number(data.page) : 1;
        const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
        const pages:number = data.pages !== undefined ? Number(data.pages) : 0;
        const cnt:number = data.cnt !== undefined ? Number(data.cnt) : 0;

        // Устанавливаем пагинацию
        pagination.setPage(page);
        pagination.setLimit(limit);
        pagination.setPages(pages);

        return pagination;
    }

    getSocialNetworkLoadItems(filter: any, sort: any, pagination: any){
        const request = new ListSocialNetworkLoadRequest();

        // Устанавливаем параметры запроса
        request
            .setFilter(this.createFilter(filter))
            .setSort(this.createSort(sort))
            .setPagination(this.createPagination(pagination))

        console.log('LOAD POLL ITEMS REQ', request)

        // return this.client.listPollLoad(request, this.metadata)

        return this.client.listSocialNetworkLoad(request, this.metadata)
    }

    getSocialNetworkLoad(social_network_id: any) {
        const request = new LoadSocialNetworkLoadRequest()

        request
            .setSocialNetworkId(social_network_id)

        console.log('SocialNetwork LOAD REQ',request)

        return this.client.loadSocialNetworkLoad(request, this.metadata)
    }

    checkSocialNetworkLoad() {
        const request = new CheckSocialNetworkLoadRequest()

        return this.client.checkSocialNetworkLoad(request, this.metadata)
    }
}

