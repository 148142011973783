import { GetterTree, MutationTree, ActionTree } from 'vuex'
import {SocialApi} from "@/components/modules/accounts/services/api/SocialApi";

const socialList = new SocialApi()

class State {
    isSocialList: boolean = false
    socialList: any = []
}

const getters = <GetterTree<State, any>>{
    isSocialList(state) {
        return state.isSocialList
    },

    getSocialList(state) {
        return state.socialList
    },
}

const mutations = <MutationTree<State>>{
    setIsSocialList(state, payload) {
        state.isSocialList = payload
    },

    setSocialList(state, payload) {
        state.socialList = payload
    },
}

const actions = <ActionTree<State, any>>{
    getSocialList({ commit }, filter: any) {
        commit('setIsSocialList', true)

        return new Promise((resolve, reject) => {
            // Получаем список элементов
            socialList.ListSocial(filter).then(
                (message: any) => {
                    const items = message.getItemsList()

                    commit('setSocialList', items)
                    commit('setIsSocialList', false)

                    resolve(items)
                },
                (error) => {
                    commit('setSocialList', [])
                    commit('setIsSocialList', false)
                    reject(error)
                }
            )
        })
    },
}

const socialListModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters,
}

export default socialListModule
