import {
  PollInfoFormServiceClient
} from "../../proto/pb/Poll_info_formServiceClientPb";
import {
  PollInfoFormRequest,
  PollInfoFormClsRequest,
} from "../../proto/pb/poll_info_form_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class PollInfoFormApi {
  client: PollInfoFormServiceClient;
  metadata: any;

  constructor() {
    const host:string = (window as any).VUE_APP_API_URL ?? '';
    this.client = new PollInfoFormServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  // Получение информации по опросу (для выходной формы)
  getPollInfoForm(poll_id: number, max_open_rows: number){
    const request = new PollInfoFormRequest();

    // Устанавливаем параметры запроса
    request.setPollId(poll_id);
    request.setMaxOpenRows(max_open_rows);

    return this.client.pollInfoForm(request, this.metadata);
  }

  getGeneralPollInfoForm(poll_id: number, classifier_id: number, max_cls_rows: number){
    const request = new PollInfoFormClsRequest();

    // Устанавливаем параметры запроса
    request.setPollId(poll_id);
    request.setClassifierId(classifier_id)
    request.setMaxClsRows(max_cls_rows);

    return this.client.pollInfoFormCls(request, this.metadata);
  }

  getPollChart(data: any) {
    const request = new PollInfoFormRequest()

    request
        .setPollId(data.poll_id)
        .setMaxOpenRows(data.max_open_rows)

    return this.client.pollInfoForm(request, this.metadata)
  }

  getGeneralPollChart(data: any) {
    const request = new PollInfoFormClsRequest()

    request
        .setPollId(data.poll_id)
        .setClassifierId(data.classifier_id)
        .setMaxClsRows(data.max_cls_rows)

    return this.client.pollInfoFormCls(request, this.metadata)
  }
}
