import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { IntersectionUsersApi } from '@/components/modules/intersection/services/api/IntersectionUsersApi'

const intersectionUsersApi = new IntersectionUsersApi()

class State {
  intersectionUsers: any
  isIntersectionUsersBusy: boolean
  intersectionListUsers: any
  isIntersectionListUsersBusy: boolean
  intersectionTable: any
  isIntersectionTableBusy: boolean
  exportTable: any
  isExportTable: boolean = false
  personalList: any
  isPersonalList: boolean = false
}

const getters = <GetterTree<State, any>>{
  getIntersectionUsers(state) {
    return state.intersectionUsers
  },

  isIntersectionUsersBusy(state) {
    return state.isIntersectionUsersBusy
  },

  getIntersectionUsersList(state) {
    return state.intersectionListUsers
  },

  isIntersectionUsersListBusy(state) {
    return state.isIntersectionListUsersBusy
  },

  getIntersectionTable(state) {
    return state.intersectionTable
  },

  isIntersectionTableBusy(state) {
    return state.isIntersectionTableBusy
  },

  getExportTable(state) {
    return state.exportTable
  },

  isExportTable(state) {
    return state.isExportTable
  },

  getPersonalProjectInfoList(state) {
    return state.personalList
  },

  isPersonalProjectInfoList(state) {
    return state.isPersonalList
  },
}

const mutations = <MutationTree<State>>{
  setIntersectionUsers(state, payload) {
    state.intersectionUsers = payload
  },

  setIsIntersectionUsersBusy(state, payload) {
    state.isIntersectionUsersBusy = payload
  },

  setIntersectionListUsers(state, payload) {
    state.intersectionListUsers = payload
  },

  setIsIntersectionListUsersBusy(state, payload) {
    state.isIntersectionListUsersBusy = payload
  },

  setIntersectionTable(state, payload) {
    state.intersectionTable = payload
  },

  setIsIntersectionTable(state, payload) {
    state.isIntersectionTableBusy = payload
  },

  setExportTable(state, payload) {
    state.exportTable = payload
  },

  setIsExportTable(state, payload) {
    state.isExportTable = payload
  },

  setPersonalProjectInfoList(state, payload) {
    state.personalList = payload
  },

  setIsPersonalProjectInfoList(state, payload) {
    state.isPersonalList = payload
  },
}

const actions = <ActionTree<State, any>>{
  getCountUsersList({ commit }, data) {
    commit('setIsIntersectionUsersBusy', true)

    return new Promise((resolve, reject) => {
      intersectionUsersApi.getDashboardAllCountRequest(data).then(
        (message: any) => {
          const items = message.getAccountCount()

          commit('setIntersectionUsers', items)
          commit('setIsIntersectionUsersBusy', false)
          resolve(items)
        },

        (error: any) => {
          commit('setIntersectionUsers', [])
          commit('setIsIntersectionUsersBusy', false)
          reject(error)
          console.log('ERROR ', error)
        }
      )
    })
  },

  getListIntersectionUsers({ commit }, data) {
    commit('setIsIntersectionListUsersBusy', true)
    const date: any =
      data !== undefined && data.date !== undefined ? data.date : {}

    return new Promise((resolve, reject) => {
      intersectionUsersApi
        .getListDashboardProjectInfoRequest(date)
        .then(
          (message: any) => {
            const items = message.getItemsList()

            commit('setIntersectionListUsers', items)
            commit('setIsIntersectionListUsersBusy', false)
            resolve(items)
          },

          (error: any) => {
            commit('setIntersectionListUsers', [])
            commit('setIsIntersectionListUsersBusy', false)
            console.log('ERROR ', error)
            reject(error)
          }
        )
    })
  },

  getIntersectionTable({ commit }, data) {
    commit('setIsIntersectionTable', true)

    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : {}

    return new Promise((resolve, reject) => {
      intersectionUsersApi
        .getAccountFlagRequest(pagination, data)
        .then(
          (message: any) => {
            const items = message.getItemsList()
            const params = message.getParams()

            commit('setIntersectionTable', items)
            commit('setIsIntersectionTable', false)
            resolve({ items, params })
          },

          (error: any) => {
            commit('setIntersectionTable', [])
            commit('setIsIntersectionTable', false)
            reject(error)
            console.log('ERROR ', error)
          }
        )
    })
  },

  getExportTable({ commit }, data) {
    commit('setIsExportTable', true)

    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : {}

    return new Promise((resolve, reject) => {
      intersectionUsersApi.getExportTable(pagination, data).then(
        (message: any) => {
          const items = message.getItemsList()
          const params = message.getParams()

          commit('setExportTable', items)
          commit('setIsExportTable', false)
          resolve({ items, params })
        },

        (error: any) => {
          commit('setExportTable', [])
          commit('setIsExportTable', false)
          reject(error)
          console.log('ERROR ', error)
        }
      )
    })
  },

  getPersonalProjectInfo({ commit }, data) {
    commit('setIsPersonalProjectInfoList', true)
    const date: any =
        data !== undefined && data.date !== undefined ? data.date : {}

    return new Promise((resolve, reject) => {
      intersectionUsersApi
          .getPersonalProjectInfo(data)
          .then(
              (message: any) => {
                const items = message

                commit('setPersonalProjectInfoList', items)
                commit('setIsPersonalProjectInfoList', false)
                resolve(items)
              },

              (error: any) => {
                commit('setPersonalProjectInfoList', [])
                commit('setIsPersonalProjectInfoList', false)
                console.log('ERROR ', error)
                reject(error)
              }
          )
    })
  },
}

const intersectionRegModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
export default intersectionRegModule
