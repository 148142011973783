// source: interval.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.proto.ChartInterval', null, global);
/**
 * @enum {number}
 */
proto.proto.ChartInterval = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
  QUARTER: 3,
  YEAR: 4
};

goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
