
import store from '@/store'
import MainMixin from '@/mixins/main'
import {defineComponent, toRaw} from 'vue'

import Docxtemplater from 'docxtemplater'
import PizZip from 'pizzip'
import PizZipUtils from 'pizzip/utils/index.js'
import { saveAs } from 'file-saver'

function loadFile(url: string, callback: any) {
    PizZipUtils.getBinaryContent(url, callback);
}

import DataLoading from "@/components/DataLoading.vue"
import {Canvg} from "canvg";
import date from 'quasar/src/utils/date/date.js';;


const blankFileWordFormName = '/templates/blank_poll_info.docx'
const fileWordFormName = 'poll_info_'

export default defineComponent({
  name: 'WordFormSettings',

  mixins: [MainMixin],

  components: {
    DataLoading,
  },

  props: {
    poll: {
      type: Object as any,
    },
  },

  data() {
    return {
      isMakeWordForm: false,
      maxOpenRows: '10',
      pollUsersCount: 0,
      questions: [],
      answersImages: [] as any,
      openAnswersImages: [] as any,
    }
  },

  methods: {
    async fetchChart() {
      const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;

      this.answersImages = []

      if (this.answersChartsComputed) {
        let count = 0

        for (let i in this.answersChartsComputed) {
          console.log(i)
          count++
        }

        for (let i = 0; i < count; i++) {
          let test = this.$refs['chart' + i] as any
          let svgString = test[0].chart.getSVGForExport()

          let canvas = document.createElement('canvas')
          let ctx = canvas.getContext('2d') as any
          let res = await Canvg.from(ctx, svgString)

          res.start()

          this.answersImages.push(canvas.toDataURL().replace(base64Regex, ''))
        }
      }
    },

    async fetchOpenChart() {
      const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;

      this.openAnswersImages = []

      if (this.openAnswersChartsComputed) {
        let count = 0

        for (let i in this.openAnswersChartsComputed) {
          console.log(i)
          count++
        }
        console.log('OPEN ANSWERS COMPUTED LENGTH', count, this.maxOpenRows)
        for (let i = 0; i < count; i++) {
          let test = this.$refs['open_chart' + i] as any
          let svgString = test[0].chart.getSVGForExport()

          let canvas = document.createElement('canvas')
          let ctx = canvas.getContext('2d') as any
          let res = await Canvg.from(ctx, svgString)

          res.start()

          this.openAnswersImages.push(canvas.toDataURL().replace(base64Regex, ''))
        }
      }
    },

    makeWordChanges() {
      store.dispatch('getPollChart', {
        poll_id: this.poll.id,
        max_open_rows: this.maxOpenRows,
      })
          .then(() => {
            this.fetchChart()
            this.fetchOpenChart()
          })
          .then(() => {
            this.makeWordForm()
          })
    },

    makeWordForm() {
      console.log('WordFormSettings makeWordForm')
      console.log('poll', this.poll.id)
      const maxOpenRowsInput: any = this.$refs.WordFormSettings_maxOpenRowsInput
      if (!maxOpenRowsInput.validate()) {
        console.log('Ошибка валидации')
        return
      }
      this.isMakeWordForm = true
      console.log('maxOpenRows', this.maxOpenRows)

      store.dispatch('getPollInfoForm', {
        poll_id: this.poll.id,
        max_open_rows: this.maxOpenRows,
      }).then(
        (resolve) => {
          console.log('getPollInfoForm resolve', resolve)
          this.pollUsersCount = resolve.usersCount.toLocaleString()
          this.questions = resolve.items.map((question: any, index: any) => {
            console.log('QUEST INDEX', index)

            return {
              question_number: question.getQuestionNumber(),
              question_stitle: question.getQuestionStitle(),
              hasLength: question.getAnswersList().length > 1,
              answers: question.getAnswersList().map((answer: any) => {
                return {
                  answer_num: answer.getAnswerNum(),
                  hasLeader: answer.getAnswerNum() == 1,
                  hasThree: answer.getAnswerNum() <= 3,
                  answer_title: answer.getAnswerTitle(),
                  answer_count: answer.getAnswerCount().toLocaleString(),
                  answer_percent: answer.getAnswerPercent()
                }
              }),
              answers_image: this.answersImages[index],
              open_answers_image:
                  this.openAnswersChartsComputed[index].series[0].data.length
                  ? this.openAnswersImages[index]
                  : undefined
              ,
              open_answers_source_count: question.getOpenAnswersSourceCount(),
              hasOpenAnswersSource: question.getOpenAnswersSourceCount() > 0,
              hasOpenAnswers: question.getOpenAnswersList().length > 0,
              open_answers: question.getOpenAnswersList().map((answer: any) => {
                return {
                  answer_num: answer.getAnswerNum(),
                  hasThree: answer.getAnswerNum() <= 3,
                  answer_text: answer.getAnswerText(),
                  answer_sum: answer.getAnswerSum(),
                  answer_percent: answer.getAnswerPercent()
                }
              })
            }
          })
          this.renderForm()
        },
        (reject) => {
          console.log('getPollInfoForm reject', reject)
          this.isMakeWordForm = false
        }
      )
    },

    renderForm() {
      let ImageModule = require('docxtemplater-image-module-free')
      function base64DataURLToArrayBuffer(stringBase64: any) {
        const binaryString = window.atob(stringBase64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          const ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        return bytes.buffer;
      }

      loadFile(blankFileWordFormName, (error: string, content: any) => {
        if (error) {
          this.isMakeWordForm = false
          throw error
        }

        let images = this.answersImages

        console.log(toRaw(images))

        const opts = {
          centered: true,
          fileType: 'docx',
          getImage(tag: any) {
            return base64DataURLToArrayBuffer(tag)
          },
            getSize () {
            return [600, 300]
          }
        } as any

        let imageModule = new ImageModule(opts)

        console.log('QUESTIONS', this.questions)

        const zip = new PizZip(content);
        console.log('before try')
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          modules: [imageModule],
        });
        const dateCustom = new Date()
        try {
          // render the document
          doc
              .render({
                cur_date: date.formatDate(this.poll.date_update * 1000, 'DD.MM.YYYY'),
                poll_title: this.poll.origin_title,
                poll_id: this.poll.id,
                poll_date_begin: this.getFormattedDateOrigin(this.poll.date_begin),
                poll_date_end: this.getFormattedDateOrigin(this.poll.date_end),
                poll_users_count: this.pollUsersCount,
                questions: this.questions,
              });
          console.log('doc', doc)
        } catch (error) {
          console.log(error);
          this.isMakeWordForm = false
          throw error;
        }

        const out = doc.getZip().generate({
          type: 'blob',
          mimeType:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        });
        // Output the document using Data-URI
        saveAs(out, fileWordFormName + this.poll.id + '_' + dateCustom.valueOf() + '.docx');
        this.isMakeWordForm = false
      });
    },

    closeWordForm() {
      this.$emit('hideModal')
    },

    loadData() {
      store.dispatch('getPollChart', {
        poll_id: this.poll.id,
        max_open_rows: this.maxOpenRows,
      })
          .then(() => {
            this.fetchChart()
            this.fetchOpenChart()
          })
    },
  },

  computed: {
    answersChartsComputed() {
      const rawList = store.getters.getPollChart
      const filteredList = [] as any
      let tempObj = {} as any

      if (rawList) {
        for (let i = 0; i < rawList.length; i++) {
          filteredList.push(rawList[i])

          tempObj[i] = {
            chart: {
              type: 'bar'
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true
                }
              }
            },
            legend: {
              enabled: false
            },
            title: {
              text: 'Количество голосов'
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
              },
            },
            xAxis: {
              categories: [],
            },
            series: [{
              name:'Количество голосов',
              data:[]
            }]
          }

          rawList[i].getAnswersList().forEach((el: any) => {
            tempObj[i].series[0].data.push({
              y: toRaw(el).getAnswerCount(),
              name: toRaw(el).getAnswerTitle()
            })
            tempObj[i].xAxis.categories.push(
                toRaw(el).getAnswerTitle()
            )
          })
        }
      }

      return tempObj
    },

    openAnswersChartsComputed() {
      const rawList = store.getters.getPollChart
      const filteredList = [] as any
      let tempObj = {} as any

      if (rawList) {
        for (let i = 0; i < rawList.length; i++) {
          if (rawList[i].getOpenAnswersList().length > 0) {
            filteredList.push(rawList[i])
          }
          else {
            filteredList.push('')
          }

          tempObj[i] = {
            chart: {
              type: 'bar'
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true
                }
              }
            },
            legend: {
              enabled: false
            },
            title: {
              text: 'Количество голосов'
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
              },
            },
            xAxis: {
              categories: [],
            },
            series: [{
              name:'Количество голосов',
              data:[]
            }]
          }

          if (rawList[i] !== '') {
            rawList[i].getOpenAnswersList().forEach((el: any) => {
              console.log('take elem', el)
              tempObj[i].series[0].data.push({
                y: toRaw(el).getAnswerSum(),
                name: toRaw(el).getAnswerText()
              })
              tempObj[i].xAxis.categories.push(
                  toRaw(el).getAnswerText()
              )
            })
          }
        }
      }

      return tempObj
    },

    isPollChartBusy() {
      return store.getters.isPollChartBusy
    }
  },

  created() {
    // this.loadData()
  },
})
