import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

export default {
  methods: {
    // Конвертация массива байтов в Base64
    arrayBufferToBase64(buffer: any) {
      let binary = ''
      const bytes = new Uint8Array(buffer)
      const len = bytes.byteLength
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
      }
      return window.btoa(binary)
    },

    // Форматирование даты по-умолчанию
    getFormattedDate(date: any) {
      const datetime = date ? new Date(date) : new Date()
      return (
        datetime.getFullYear() +
        '-' +
        ((datetime.getMonth() + 1).toString().length == 1 ? '0' : '') +
        (datetime.getMonth() + 1) +
        '-' +
        (datetime.getDate().toString().length == 1 ? '0' : '') +
        datetime.getDate()
      )
    },

    // Форматирование даты и времени по-умолчанию
    getFormattedDateTime(date: any) {
      const datetime = date ? new Date(date) : new Date()
      return (
        datetime.getFullYear() +
        '-' +
        ((datetime.getMonth() + 1).toString().length == 1 ? '0' : '') +
        (datetime.getMonth() + 1) +
        '-' +
        (datetime.getDate().toString().length == 1 ? '0' : '') +
        datetime.getDate() +
        ' ' +
        (datetime.getHours().toString().length == 1 ? '0' : '') +
        datetime.getHours() +
        ':' +
        (datetime.getMinutes().toString().length == 1 ? '0' : '') +
        datetime.getMinutes() +
        ':' +
        (datetime.getSeconds().toString().length == 1 ? '0' : '') +
        datetime.getSeconds()
      )
    },

    // Форматирование даты и времени по-умолчанию
    // (с подчеркиванием между датой и временем)
    // Для имен файлов !!! (вместо ":" используется "-")
    getFormattedDateTimeUnderline(date: any) {
      const datetime = date ? new Date(date) : new Date()
      return (
        datetime.getFullYear() +
        '-' +
        ((datetime.getMonth() + 1).toString().length == 1 ? '0' : '') +
        (datetime.getMonth() + 1) +
        '-' +
        (datetime.getDate().toString().length == 1 ? '0' : '') +
        datetime.getDate() +
        '_' +
        (datetime.getHours().toString().length == 1 ? '0' : '') +
        datetime.getHours() +
        '-' +
        (datetime.getMinutes().toString().length == 1 ? '0' : '') +
        datetime.getMinutes() +
        '-' +
        (datetime.getSeconds().toString().length == 1 ? '0' : '') +
        datetime.getSeconds()
      )
    },

    // Форматирование даты
    getFormattedDateOrigin(date: any) {
      const datetime = date ? new Date(date) : new Date()
      return (
        (datetime.getDate().toString().length == 1 ? '0' : '') +
        datetime.getDate() +
        '.' +
        ((datetime.getMonth() + 1).toString().length == 1 ? '0' : '') +
        (datetime.getMonth() + 1) +
        '.' +
        datetime.getFullYear()
      )
    },

    // Форматирование даты и времени
    getFormattedDateTimeOrigin(date: any) {
      const datetime = date ? new Date(date) : new Date()
      return (
        (datetime.getHours().toString().length == 1 ? '0' : '') +
        datetime.getHours() +
        ':' +
        (datetime.getMinutes().toString().length == 1 ? '0' : '') +
        datetime.getMinutes() +
        ' ' +
        (datetime.getDate().toString().length == 1 ? '0' : '') +
        datetime.getDate() +
        '.' +
        ((datetime.getMonth() + 1).toString().length == 1 ? '0' : '') +
        (datetime.getMonth() + 1) +
        '.' +
        datetime.getFullYear()
      )
    },

    // Форматирование даты и времени
    // (с подчеркиванием между датой и временем)
    // Для имен файлов !!! (вместо ":" используется "-")
    getFormattedDateTimeUnderlineOrigin(date: any) {
      const datetime = date ? new Date(date) : new Date()
      return (
        (datetime.getHours().toString().length == 1 ? '0' : '') +
        datetime.getHours() +
        '-' +
        (datetime.getMinutes().toString().length == 1 ? '0' : '') +
        datetime.getMinutes() +
        '-' +
        (datetime.getSeconds().toString().length == 1 ? '0' : '') +
        datetime.getSeconds() +
        '_' +
        (datetime.getDate().toString().length == 1 ? '0' : '') +
        datetime.getDate() +
        '-' +
        ((datetime.getMonth() + 1).toString().length == 1 ? '0' : '') +
        (datetime.getMonth() + 1) +
        '-' +
        datetime.getFullYear()
      )
    },

    // Преобразует строку типа "HH:mm dd.MM.yyyy" в dateTime ISO format
    getISODateTimeFormatString(stringDateTime: string) {
      console.log(stringDateTime)
      let result = ''
      if (stringDateTime) {
        const dateTimeArr = stringDateTime.split(' ')
        if (dateTimeArr.length === 2) {
          const dateString = dateTimeArr[1].split('.').join('-')
          result = new Date(dateTimeArr[0] + ' ' + dateString).toISOString()
        } else {
          console.log(
            'Метод getISODateTimeFomtString получил дату в ошибочном формате'
          )
        }
      }
      return result
    },

    // Форматирование даты из таймстампа
    getFormattedDateOriginFromTimestamp(timestamp: number) {
      const date = new Date(1000 * timestamp)
      return (
        (date.getDate().toString().length == 1 ? '0' : '') +
        date.getDate() +
        '.' +
        ((date.getMonth() + 1).toString().length == 1 ? '0' : '') +
        (date.getMonth() + 1) +
        '.' +
        date.getFullYear()
      )
    },

    // Форматирование даты и времени из таймстампа
    getFormattedDateTimeOriginFromTimestamp(timestamp: number) {
      const datetime = new Date(1000 * timestamp)
      return (
        (datetime.getHours().toString().length == 1 ? '0' : '') +
        datetime.getHours() +
        ':' +
        (datetime.getMinutes().toString().length == 1 ? '0' : '') +
        datetime.getMinutes() +
        ' ' +
        (datetime.getDate().toString().length == 1 ? '0' : '') +
        datetime.getDate() +
        '.' +
        ((datetime.getMonth() + 1).toString().length == 1 ? '0' : '') +
        (datetime.getMonth() + 1) +
        '.' +
        datetime.getFullYear()
      )
    },

    getFormattedDateOriginFromTimestampToString(timestamp: number) {
      if (isNaN(timestamp)) return
      const date = new Date(timestamp * 1000)
        .toLocaleDateString('ru', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })
        .slice(0, -3)
      return date
    },
    // Форматирование даты из таймстампа
    getFormattedDateFromTimestamp(timestamp: number) {
      const date = new Date(1000 * timestamp)
      return (
        date.getFullYear() +
        '-' +
        ((date.getMonth() + 1).toString().length == 1 ? '0' : '') +
        (date.getMonth() + 1) +
        '-' +
        (date.getDate().toString().length == 1 ? '0' : '') +
        date.getDate()
      )
    },

    // Форматирование даты в таймстамп
    getFormattedDateOriginToTimestamp(dateString: any) {
      const date = dateString ? new Date(dateString) : new Date()
      return Timestamp.fromDate(new Date(date))
    },
  },
}
