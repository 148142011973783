
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DataLoading',

  props: {
    msgLoading: {
      type: String,
      default: 'Загрузка',
    },
  },

  data() {
    return {}
  },
})
