import { GetterTree, MutationTree, ActionTree } from 'vuex'
import {LoyaltyIntersectionApi} from "@/components/modules/intersection/services/api/LoyaltyIntersectionApi";

const loyaltyIntersection = new LoyaltyIntersectionApi()

class State {
    mainValues: any
    isMainValues: boolean
    mainDifference: any
    isMainDifference: boolean
    statusChart: any
    isStatusChart: boolean
    uniqUsersChart: any
    isUniqUsersChart: boolean
    dynamicJoinChart: any
    isDynamicJoinChart: boolean
    transactionTable: any
    isTransactionTable: boolean
    transactionChart: any
    isTransactionChart: boolean
    topTable: any
    isTopTable: boolean
    usersRatingChart: any
    isUsersRatingChart: boolean
    listEmissia: any
    isListEmissia: boolean
    ratingEmissia: any
    isRatingEmissia: boolean
    averageVotes: any
    isAverageVotes: boolean
    uniqVotes: any
    isUniqVotes: boolean
    loyaltyActivities: any
    isLoyaltyActivities: boolean
}

const getters = <GetterTree<State, any>>{
    getMainValues(state) {
        return state.mainValues
    },

    isMainValues(state) {
        return state.isMainValues
    },

    getMainDifference(state) {
        return state.mainDifference
    },

    isMainDifference(state) {
        return state.isMainDifference
    },

    getStatusChart(state) {
        return state.statusChart
    },

    isStatusChart(state) {
        return state.isStatusChart
    },

    getUniqUsersChart(state) {
        return state.uniqUsersChart
    },

    isUniqUsersChart(state) {
        return state.isUniqUsersChart
    },

    getDynamicJoinChart(state) {
        return state.dynamicJoinChart
    },

    isDynamicJoinChart(state) {
        return state.isDynamicJoinChart
    },

    getTransactionTable(state) {
        return state.transactionTable
    },

    isTransactionTable(state) {
        return state.isTransactionTable
    },

    getTransactionChart(state) {
        return state.transactionChart
    },

    isTransactionChart(state) {
        return state.isTransactionChart
    },

    getTopTable(state) {
        return state.topTable
    },

    isTopTable(state) {
        return state.isTopTable
    },

    getUsersRatingChart(state) {
        return state.usersRatingChart
    },

    isUsersRatingChart(state) {
        return state.isUsersRatingChart
    },

    getListEmissia(state) {
        return state.listEmissia
    },

    isListEmissia(state) {
        return state.isListEmissia
    },

    getRatingEmissia(state) {
        return state.ratingEmissia
    },

    isRatingEmissia(state) {
        return state.isRatingEmissia
    },

    getAverageVotes(state) {
        return state.averageVotes
    },

    isAverageVotes(state) {
        return state.isAverageVotes
    },

    getUniqVotes(state) {
        return state.uniqVotes
    },

    isUniqVotes(state) {
        return state.isUniqVotes
    },

    getLoyaltyActivities(state) {
        return state.loyaltyActivities
    },

    isLoyaltyActivites(state) {
        return state.isLoyaltyActivities
    },
}

const mutations = <MutationTree<State>>{
    setMainValues(state, payload) {
        state.mainValues = payload
    },

    setIsMainValues(state, payload) {
        state.isMainValues = payload
    },

    setMainDifference(state, payload) {
        state.mainDifference = payload
    },

    setIsMainDifference(state, payload) {
        state.isMainDifference = payload
    },

    setStatusChart(state, payload) {
        state.statusChart = payload
    },

    setIsStatusChart(state, payload) {
        state.isStatusChart = payload
    },

    setUniqUsersChart(state, payload) {
        state.uniqUsersChart = payload
    },

    setIsUniqUsersChart(state, payload) {
        state.isUniqUsersChart = payload
    },

    setDynamicJoinChart(state, payload) {
        state.dynamicJoinChart = payload
    },

    setIsDynamicJoinChart(state, payload) {
        state.isDynamicJoinChart = payload
    },

    setTransactionTable(state, payload) {
        state.transactionTable = payload
    },

    setIsTransactionTable(state, payload) {
        state.isTransactionTable = payload
    },

    setTransactionChart(state, payload) {
        state.transactionChart = payload
    },

    setIsTransactionChart(state, payload) {
        state.isTransactionChart = payload
    },

    setTopTable(state, payload) {
        state.topTable = payload
    },

    setIsTopTable(state, payload) {
        state.isTopTable = payload
    },

    setUsersRatingChart(state, payload) {
        state.usersRatingChart = payload
    },

    setIsUsersRatingChart(state, payload) {
        state.isUsersRatingChart = payload
    },

    setListEmissia(state, payload) {
        state.listEmissia = payload
    },

    setIsListEmissia(state, payload) {
        state.isListEmissia = payload
    },

    setRatingEmissia(state, payload) {
        state.ratingEmissia = payload
    },

    setIsRatingEmissia(state, payload) {
        state.isRatingEmissia = payload
    },

    setAverageVotes(state, payload) {
        state.averageVotes = payload
    },

    setIsAverageVotes(state, payload) {
        state.isAverageVotes = payload
    },

    setUniqVotes(state, payload) {
        state.uniqVotes = payload
    },

    setIsUniqVotes(state, payload) {
        state.isUniqVotes = payload
    },

    setLoyaltyActivities(state, payload) {
        state.loyaltyActivities = payload
    },

    setIsLoyaltyActivities(state, payload) {
        state.isLoyaltyActivities = payload
    },
}

const actions = <ActionTree<State, any>>{
    getMainValues({ commit }, data: any) {
        commit('setIsMainValues', true)

        return new Promise((resolve, reject) => {
            loyaltyIntersection
                .getDashboardMainValue()
                .then(res => {
                    const items = Object.fromEntries(res.getValuesMap().toObject())

                    commit('setMainValues', items)
                    commit('setIsMainValues', false)

                    resolve(items)
                })
                .catch(err => {
                    commit('setMainValues', [])
                    commit('setIsMainValues', false)

                    reject(err)
                })
        })
    },

    getMainDifference({ commit }, data: any) {
        commit('setIsMainDifference', true)

        return new Promise((resolve, reject) => {
            loyaltyIntersection
                .getDashboardMainDifference()
                .then(res => {
                    const items = res.toObject().itemsList

                    commit('setMainDifference', items)
                    commit('setIsMainDifference', false)

                    resolve(items)
                })
                .catch(err => {
                    commit('setMainDifference', [])
                    commit('setIsMainDifference', false)

                    reject(err)
                })
        })
    },

    getStatusChart({ commit }, data: any) {
        commit('setIsStatusChart', true)

        return new Promise((resolve, reject) => {
            loyaltyIntersection
                .getStatusChart()
                .then(res => {
                    const items = res.toObject()

                    commit('setStatusChart', items)
                    commit('setIsStatusChart', false)

                    resolve(items)
                })
                .catch(err => {
                    commit('setStatusChart', [])
                    commit('setIsStatusChart', false)

                    reject(err)
                })
        })
    },

    getUniqUsersChart({ commit }, data: any) {
        commit('setIsUniqUsersChart', true)

        return new Promise((resolve, reject) => {
            loyaltyIntersection
                .getUniqUsersChart(data)
                .then(res => {
                    const items = res.toObject().itemsList

                    commit('setUniqUsersChart', items)
                    commit('setIsUniqUsersChart', false)

                    resolve(items)
                })
                .catch(err => {
                    commit('setUniqUsersChart', [])
                    commit('setIsUniqUsersChart', false)

                    reject(err)
                })
        })
    },

    getDynamicJoinChart({ commit }, data: any) {
        commit('setIsDynamicJoinChart', true)

        return new Promise((resolve, reject) => {
            loyaltyIntersection
                .getDynamicJoinChart(data)
                .then(res => {
                    const items = res.toObject().itemsList[0].pointsList

                    commit('setDynamicJoinChart', items)
                    commit('setIsDynamicJoinChart', false)

                    resolve(items)
                })
                .catch(err => {
                    commit('setDynamicJoinChart', [])
                    commit('setIsDynamicJoinChart', false)

                    reject(err)
                })
        })
    },

    getTransactionTable({ commit }, data: any) {
        commit('setIsTransactionTable', true)

        return new Promise((resolve, reject) => {
            loyaltyIntersection
                .getTransactionTable(data)
                .then(res => {
                    const items = res.toObject().itemsList

                    commit('setTransactionTable', items)
                    commit('setIsTransactionTable', false)

                    resolve(items)
                })
                .catch(err => {
                    commit('setTransactionTable', [])
                    commit('setIsTransactionTable', false)

                    reject(err)
                })
        })
    },

    getTransactionChart({ commit }, data: any) {
        commit('setIsTransactionChart', true)

        return new Promise((resolve, reject) => {
            loyaltyIntersection
                .getTransactionChart(data)
                .then(res => {
                    const items = res.toObject().itemsList

                    commit('setTransactionChart', items)
                    commit('setIsTransactionChart', false)

                    resolve(items)
                })
                .catch(err => {
                    commit('setTransactionChart', [])
                    commit('setIsTransactionChart', false)

                    reject(err)
                })
        })
    },

    getTopTable({ commit }, data: any) {
        commit('setIsTopTable', true)

        return new Promise((resolve, reject) => {
            loyaltyIntersection
                .getTopTable(data)
                .then(res => {
                    const items = res.toObject().itemsList

                    commit('setTopTable', items)
                    commit('setIsTopTable', false)

                    resolve(items)
                })
                .catch(err => {
                    commit('setTopTable', [])
                    commit('setIsTopTable', false)

                    reject(err)
                })
        })
    },

    getUsersRatingChart({ commit }, data: any) {
        commit('setIsUsersRatingChart', true)

        return new Promise((resolve, reject) => {
            loyaltyIntersection
                .getUsersRatingChart(data)
                .then(res => {
                    const items = res.toObject().itemsList

                    commit('setUsersRatingChart', items)
                    commit('setIsUsersRatingChart', false)

                    resolve(items)
                })
                .catch(err => {
                    commit('setUsersRatingChart', [])
                    commit('setIsUsersRatingChart', false)

                    reject(err)
                })
        })
    },

    getListEmissiaChart({ commit }, data: any) {
        commit('setIsListEmissia', true)

        return new Promise((resolve, reject) => {
            loyaltyIntersection
                .getListEmissia(data)
                .then(res => {
                    const items = res.toObject().itemsList

                    commit('setListEmissia', items)
                    commit('setIsListEmissia', false)

                    resolve(items)
                })
                .catch(err => {
                    commit('setListEmissia', [])
                    commit('setIsListEmissia', false)

                    reject(err)
                })
        })
    },

    getRatingEmissiaChart({ commit }, data: any) {
        commit('setIsRatingEmissia', true)

        return new Promise((resolve, reject) => {
            loyaltyIntersection
                .getRatingEmissia(data)
                .then(res => {
                    const items = res.toObject().itemsList

                    commit('setRatingEmissia', items)
                    commit('setIsRatingEmissia', false)

                    resolve(items)
                })
                .catch(err => {
                    commit('setRatingEmissia', [])
                    commit('setIsRatingEmissia', false)

                    reject(err)
                })
        })
    },

    getAverageVotes({ commit }, data: any) {
        commit('setIsAverageVotes', true)

        return new Promise((resolve, reject) => {
            loyaltyIntersection
                .getAverageVotesChart(data)
                .then(res => {
                    const items = res.toObject().itemsList[0].pointsList

                    commit('setAverageVotes', items)
                    commit('setIsAverageVotes', false)

                    resolve(items)
                })
                .catch(err => {
                    commit('setAverageVotes', [])
                    commit('setIsAverageVotes', false)

                    reject(err)
                })
        })
    },

    getUniqVotes({ commit }, data: any) {
        commit('setIsUniqVotes', true)

        return new Promise((resolve, reject) => {
            loyaltyIntersection
                .getUniqVotesChart(data)
                .then(res => {
                    const items = res.toObject().itemsList[0].pointsList

                    commit('setUniqVotes', items)
                    commit('setIsUniqVotes', false)

                    resolve(items)
                })
                .catch(err => {
                    commit('setUniqVotes', [])
                    commit('setIsUniqVotes', false)

                    reject(err)
                })
        })
    },

    getLoyaltyActivities({ commit }, data: any) {
        commit('setIsLoyaltyActivities', true)

        return new Promise((resolve, reject) => {
            loyaltyIntersection
                .getLoyaltyActivities(data)
                .then(res => {
                    const items = res.toObject().itemsList

                    commit('setLoyaltyActivities', items)
                    commit('setIsLoyaltyActivities', false)

                    resolve(items)
                })
                .catch(err => {
                    commit('setLoyaltyActivities', [])
                    commit('setIsLoyaltyActivities', false)

                    reject(err)
                })
        })
    },
}

const loyaltyIntersectionModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters,
}

export default loyaltyIntersectionModule