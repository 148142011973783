import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { ActiveAccountApi } from '@/components/modules/intersection/services/api/ActiveAccountApi'

const activeAccountApi = new ActiveAccountApi()

class State {
  activeAccount: any
  isActiveAccount: boolean
  uniqAccount: any
  isUniqAccount: boolean
  exportAll: any
  isExportAll: boolean
  exportUniq: any
  isExportUniq: boolean
  personalUniqAccount: any
  isPersonalUniqAccount: boolean
}

const getters = <GetterTree<State, any>>{
  getActiveAccount(state) {
    return state.activeAccount
  },

  isActiveAccount(state) {
    return state.isActiveAccount
  },

  getUniqAccount(state) {
    return state.uniqAccount
  },

  isUniqAccount(state) {
    return state.isUniqAccount
  },

  getExportAll(state) {
    return state.exportAll
  },

  isExportAll(state) {
    return state.isExportAll
  },

  getExportUniq(state) {
    return state.exportUniq
  },

  isExportUniq(state) {
    return state.isExportUniq
  },

  getPersonalUniqAccount(state) {
    return state.personalUniqAccount
  },

  isPersonalUniqAccount(state) {
    return state.isPersonalUniqAccount
  },
}

const mutations = <MutationTree<State>>{
  setActiveAccount(state, payload) {
    state.activeAccount = payload
  },

  setIsActiveAccount(state, payload) {
    state.isActiveAccount = payload
  },

  setUniqAccount(state, payload) {
    state.uniqAccount = payload
  },

  setIsUniqAccount(state, payload) {
    state.isUniqAccount = payload
  },

  setExportAll(state, payload) {
    state.exportAll = payload
  },

  setIsExportAll(state, payload) {
    state.isExportAll = payload
  },

  setExportUniq(state, payload) {
    state.exportUniq = payload
  },

  setIsExportUniq(state, payload) {
    state.isExportUniq = payload
  },

  setPersonalUniqAccount(state, payload) {
    state.personalUniqAccount = payload
  },

  setIsPersonalUniqAccount(state, payload) {
    state.isPersonalUniqAccount = payload
  },
}

const actions = <ActionTree<State, any>>{
  getActiveAccount({ commit }, data) {
    commit('setIsActiveAccount', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : {}

    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}

    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : {}

    return new Promise((resolve, reject) => {
      activeAccountApi.getActiveAccounts(filter, sort, pagination, data).then(
        (message: any) => {
          const items = message.getItemsList()
          const params = message.getParams()

          commit('setActiveAccount', items)
          commit('setIsActiveAccount', false)
          resolve({ items, params })
        },

        (error: any) => {
          commit('setActiveAccount', [])
          commit('setIsActiveAccount', false)
          reject(error)
          console.log('ERROR ', error)
        }
      )
    })
  },

  getUniqAccount({ commit }, data) {
    commit('setIsUniqAccount', true)
    console.log('data uniq', data)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : {}

    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}

    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : {}

    return new Promise((resolve, reject) => {
      activeAccountApi.getUniqAccounts(filter, sort, pagination, data).then(
        (message: any) => {
          const items = message.getItemsList()
          const params = message.getParams()

          commit('setUniqAccount', items)
          commit('setIsUniqAccount', false)
          resolve({ items, params })
        },

        (error: any) => {
          commit('setUniqAccount', [])
          commit('setIsUniqAccount', false)
          reject(error)
        }
      )
    })
  },

  getPersonalUniqAccount({ commit }, data) {
    commit('setIsPersonalUniqAccount', true)
    console.log('data uniq', data)

    const filter: any =
        data !== undefined && data.filter !== undefined ? data.filter : {}

    return new Promise((resolve, reject) => {
      activeAccountApi.getPersonalUniqAccounts(filter, data).then(
          (message: any) => {
            resolve(message)
          },

          (error: any) => {
            reject(error)
          }
      )
    })
  },

  getExportAll({ commit }, data) {
    commit('setIsExportAll', true)

    const filter: any =
        data !== undefined && data.filter !== undefined ? data.filter : {}

    const sort: any =
        data !== undefined && data.sort !== undefined ? data.sort : {}

    const pagination: any =
        data !== undefined && data.pagination !== undefined ? data.pagination : {}

    return new Promise((resolve, reject) => {
      activeAccountApi.getExportAll(filter, sort, pagination, data).then(
          (message: any) => {
            const items = message.getItemsList()
            const params = message.getParams()

            commit('setExportAll', items)
            commit('setIsExportAll', false)
            resolve({ items, params })
          },

          (error: any) => {
            commit('setExportAll', [])
            commit('setIsExportAll', false)
            reject(error)
            console.log('ERROR ', error)
          }
      )
    })
  },

  getExportUniq({ commit }, data) {
    commit('setIsExportUniq', true)

    const filter: any =
        data !== undefined && data.filter !== undefined ? data.filter : {}

    const sort: any =
        data !== undefined && data.sort !== undefined ? data.sort : {}

    const pagination: any =
        data !== undefined && data.pagination !== undefined ? data.pagination : {}

    return new Promise((resolve, reject) => {
      activeAccountApi.getExportUniq(filter, sort, pagination, data).then(
          (message: any) => {
            const items = message.getItemsList()
            const params = message.getParams()

            commit('setExportUniq', items)
            commit('setIsExportUniq', false)
            resolve({ items, params })
          },

          (error: any) => {
            commit('setExportUniq', [])
            commit('setIsExportUniq', false)
            reject(error)
          }
      )
    })
  },
}

const activeAccountModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
export default activeAccountModule
