import { ReportServiceClient } from '../../proto/pb/ReportServiceClientPb'
import {
    AGReportRequest,
    AGReportGoroutineRequest,
    AGReportGoroutineMaxRequest,
    GIReportGoroutineMaxRequest,
    GZReportGoroutineMaxRequest,
    EDReportGoroutineMaxRequest,
    AGChildReportGoroutineMaxRequest,
    NGReportGoroutineMaxRequest,
} from '../../proto/pb/report_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class ReportApi {
    client: ReportServiceClient
    metadata: any

    constructor() {
        const host: string = (window as any).VUE_APP_API_URL ?? ''
        this.client = new ReportServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    getAgReportList(data: any) {
        const req = new AGReportRequest()
        req
            .setStartDate(data.startDate)
            .setEndDate(data.endDate)

        console.log('AG REPORT REEQ', req)

        return this.client.getAGReport(req, this.metadata)
    }

    getAgFasterReportList(data: any) {
        const req = new AGReportGoroutineRequest()
        req
            .setStartDate(data.startDate)
            .setEndDate(data.endDate)

        console.log('AG REPORT REEQ', req)

        return this.client.getAGReportGoroutine(req, this.metadata)
    }
    getAgFasterMaxReportList(data: any) {
        const req = new AGReportGoroutineMaxRequest()
        req
            .setStartDate(data.startDate)
            .setEndDate(data.endDate)

        return new Promise((resolve, reject) => {
            this.client.getAGReportGoroutineMax(req, this.metadata).then(
                (res) => {
                    const item = Object.fromEntries(res.getValuesMap().toObject())
                    resolve(item)
                },
                (err) => {
                    reject(err)
                })
        })
    }

    getGiFasterMaxReportList(data: any) {
        const req = new GIReportGoroutineMaxRequest()
        req
            .setStartDate(data.startDate)
            .setEndDate(data.endDate)

        return new Promise((resolve, reject) => {
            this.client.getGIReportGoroutineMax(req, this.metadata).then(
                (res) => {
                    const item = Object.fromEntries(res.getValuesMap().toObject())
                    resolve(item)
                },
                (err) => {
                    reject(err)
                })
        })
    }

    getGzFasterMaxReportList(data: any) {
        const req = new GZReportGoroutineMaxRequest()
        req
            .setStartDate(data.startDate)
            .setEndDate(data.endDate)

        return new Promise((resolve, reject) => {
            this.client.getGZReportGoroutineMax(req, this.metadata).then(
                (res) => {
                    const item = Object.fromEntries(res.getValuesMap().toObject())
                    resolve(item)
                },
                (err) => {
                    reject(err)
                })
        })
    }

    getEdFasterMaxReportList(data: any) {
        const req = new EDReportGoroutineMaxRequest()
        req
            .setStartDate(data.startDate)
            .setEndDate(data.endDate)

        return new Promise((resolve, reject) => {
            this.client.getEDReportGoroutineMax(req, this.metadata).then(
                (res) => {
                    const item = Object.fromEntries(res.getValuesMap().toObject())
                    resolve(item)
                },
                (err) => {
                    reject(err)
                })
        })
    }

    getAGChildMaxReportList(data: AGChildReportGoroutineMaxRequest.AsObject) {
        const req = new AGChildReportGoroutineMaxRequest()
        req
            .setStartDate(data.startDate)
            .setEndDate(data.endDate)

        return this.client.getAGChildReportGoroutineMax(req, this.metadata)
    }

    getNgFasterMaxReportList(data: any) {
        const req = new NGReportGoroutineMaxRequest()
        req
            .setStartDate(data.startDate)
            .setEndDate(data.endDate)

        return new Promise((resolve, reject) => {
            this.client.getNGReportGoroutineMax(req, this.metadata).then(
                (res) => {
                    const item = Object.fromEntries(res.getValuesMap().toObject())
                    resolve(item)
                },
                (err) => {
                    reject(err)
                })
        })
    }
}
