
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InitDataExport',

  data() {
    return {}
  },
})
