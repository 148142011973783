
import store from '@/store'
import { defineComponent } from 'vue'
import TagAdd from '../tag/TagAdd.vue'
import TagEdit from '../tag/TagEdit.vue'
import TagDelete from '../tag/TagDelete.vue'
import DataLoading from '@/components/DataLoading.vue'
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;

const parentRootName = 'Нет (это корневой класс)'

export default defineComponent({
  name: 'ClassEdit',

  components: {
    TagAdd,
    TagEdit,
    TagDelete,
    DataLoading,
  },

  props: {
    clsId: {
      type: Number,
      default: 0,
    },

    currTab: {
      type: String,
      default: 'main'
    },
  },

  data() {
    return {
      currentTab: this.currTab,
      item: {
        id: 0,
        parent_id: 0,
        parentName: parentRootName,
        idx: 1,
        name: '',
        descr: '',
        notes: '',
        ratio: 0,
        group_name: '',
        color: '',
      },
      columns: [
        {
          name: 'name',
          label: 'Наименование',
          field: 'name',
          align: 'left',
        },
        {
          name: 'desrc',
          label: 'Описание',
          field: 'descr',
          align: 'left',
        },
        {
          name: 'action',
          label: 'Действие',
          field: 'id',
          align: 'center',
          headerStyle: 'width: 100px',
          style: 'width: 40px',
        },
      ] as any,
      tags: [] as any,
      isActiveModal: false,
      isActiveModalTree: false,
      isActiveModalAdd: false,
      isActiveModalEdit: false,
      isActiveModalDelete: false,
      selectedTag: null as any,
      selectedNode: null as any,
      selectedName: '',
      colorPopup: false,
      $q: useQuasar(),
    }
  },

  computed: {
    isClassBusy() {
      return store.getters.getIsClassBusy
    },

    isClassTagBusy() {
      return store.getters.getIsClassTagBusy
    },

    isClassTreeFormBusy() {
      return store.getters.getIsClassTreeFormBusy
    },

    getClassTreeFormItems() {
      let nodes = store.getters.getClassTreeFormItems
      return nodes ? nodes : []
    },
  },

  methods: {
    hideModal() {
      this.isActiveModal = false
      this.isActiveModalTree = false
      this.isActiveModalAdd = false
      this.isActiveModalEdit = false
      this.isActiveModalDelete = false
      this.selectedTag = null
    },

    changeParent() {
      console.log('changeParent')
      this.isActiveModalTree = true
      this.isActiveModal = true

      store
        .dispatch('getClassTreeFormItems', {
          filter: [
            {
              field_name: 'classifier_id',
              operand: '=',
              value: this.clsId,
            },
          ],
          root: {
            id: 0,
            idx: 1,
            name: parentRootName,
          },
          disabled: [this.item.id],
        })
        .then(
          (resolve) => {
            console.log('getClassTreeFormItems resolve', resolve)
            this.setTreeNode(this.item.parent_id)
          },
          (reject) => {
            console.log('getClassTreeFormItems reject', reject)
          }
        )
    },

    selectNode() {
      if (this.selectedNode !== null) {
        const tree: any = this.$refs.classParentTree
        let curNode: any =
          tree !== undefined
            ? JSON.parse(JSON.stringify(tree.getNodeByKey(this.selectedNode)))
            : null
        if (curNode !== null) {
          this.selectedName = curNode.name
        } else {
          this.selectedName = ''
        }
      } else {
        this.selectedName = ''
      }
    },

    setTreeNode(setNode: any) {
      if (setNode !== null) {
        console.log('ClassEdit setTreeNode setNode', setNode)
        this.selectedNode = setNode
        const tree: any = this.$refs.classParentTree
        let curNode: any =
          tree !== undefined
            ? JSON.parse(JSON.stringify(tree.getNodeByKey(this.selectedNode)))
            : null
        if (curNode !== null) {
          this.selectedName = curNode.name
          while (curNode && curNode.parent_id !== undefined) {
            curNode = JSON.parse(
              JSON.stringify(tree.getNodeByKey(curNode.parent_id))
            )
            if (curNode) {
              tree.setExpanded(curNode.id, true)
            }
          }
        }
      }
    },

    selectParent() {
      if (this.selectedNode !== null) {
        const tree: any = this.$refs.classParentTree
        let curNode: any =
          tree !== undefined
            ? JSON.parse(JSON.stringify(tree.getNodeByKey(this.selectedNode)))
            : null
        if (curNode) {
          this.item.parent_id = curNode.id
          this.item.parentName = curNode.name
          this.hideModal()
        }
      }
    },

    addTag() {
      console.log('addTag')
      this.isActiveModalAdd = true
      this.isActiveModal = true
    },

    editTag(index: any) {
      console.log('editTag index', index)
      this.selectedTag = {
        index: index,
        name: this.tags[index].name,
        descr: this.tags[index].descr,
      }
      console.log('this.selectedTag', this.selectedTag)
      this.isActiveModalEdit = true
      this.isActiveModal = true
    },

    deleteTag(index: any) {
      console.log('deleteTag index', index)
      this.selectedTag = {
        index: index,
        name: this.tags[index].name,
        descr: this.tags[index].descr,
      }
      console.log('this.selectedTag', this.selectedTag)
      this.isActiveModalDelete = true
      this.isActiveModal = true
    },

    afterAdd(tagItem: any) {
      console.log('afterAdd tagItem', tagItem)
      // Проверка и вставка в массив tags
      if (this.checkTag(tagItem.name)) {
        // Вставляем в начало
        this.tags.unshift({
          name: tagItem.name.trim(),
          descr: tagItem.descr.trim(),
        })
        this.hideModal()
      }
    },

    afterEdit(tagItem: any) {
      console.log('afterEdit tagItem', tagItem)
      // Обновление поля descr по индексу в массиве tags
      if (this.checkTag(tagItem.name)) {
        this.tags[tagItem.index].name = tagItem.name.trim()
        this.tags[tagItem.index].descr = tagItem.descr.trim()
        this.hideModal()
      }
    },

    actualDelete(index: any) {
      console.log('actualDelete index', index)
      // Удаление по индексу в массиве tags
      this.tags.splice(index, 1)
      this.hideModal()
    },

    // Проверка добавляемого тэга на уникальность
    checkTag(name: string) {
      if (name === '') {
        console.log('Тэг пустой')
        return false
      }
      const tagNames = this.tags.map((tagItem: any) => tagItem.name)
      for (const tName of tagNames) {
        if (tName === name) {
          this.$q.notify({
            color: 'red-5',
            textColor: 'white',
            icon: 'error',
            message: 'Тэг с таким названием уже существует.'
          })

          return false
        }
      }
      return true
    },

    saveClass() {
      const groupRef = this.$refs.editGroupName as any
      const colorRef = this.$refs.editColor as any
      const nameRef = this.$refs.editClassName as any

      groupRef.validate()
      colorRef.validate()
      nameRef.validate()

      if (groupRef.hasError || colorRef.hasError || nameRef.hasError) {
        if (this.currentTab === 'tags') {
          this.currentTab = 'main'
        }
      } else {
        let data = {
          id: this.item.id,
          cls_id: this.clsId,
          parent_id: this.item.parent_id,
          idx: this.item.idx,
          name: this.item.name,
          descr: this.item.descr,
          notes: this.item.notes,
          ratio: this.item.ratio,
          group_name: this.item.group_name,
          color: this.item.color,
        }
        console.log('saveClass data', data)
        store.dispatch('updateClassItem', data).then(
            (resolve) => {
              console.log('resolve', resolve)
              const classId = resolve.getClassId()
              // Сохранение тэгов
              store
                  .dispatch('saveClassTag', {
                    class_id: classId,
                    tags: this.tags,
                  })
                  .then(
                      (resolve) => {
                        this.$emit('afterAction', resolve.getClassId())
                      },
                      (reject) => {
                        console.log('reject', reject)
                      }
                  )
            },
            (reject) => {
              console.log('reject', reject)
            }
        )
        return true
      }
    },

    closeClassEdit() {
      this.$emit('hideModal')
    },

    loadData() {
      this.hideModal()
      let currentNode = store.getters.getCurrentNode
      if (currentNode) {
        console.log('CURR NODE', currentNode)

        this.item = {
          id: currentNode.id,
          parent_id: currentNode.parent_id,
          parentName:
            currentNode.parent_id !== 0
              ? currentNode.parentName
              : parentRootName,
          idx: currentNode.idx,
          name: currentNode.name,
          descr: currentNode.descr,
          notes: currentNode.notes,
          ratio: currentNode.ratio,
          group_name: currentNode.group_name,
          color: currentNode.color,
        }
        // Далее формируем таблицу тэгов
        const classTagItems = store.getters.getClassTagItems
        if (classTagItems !== undefined) {
          for (let tItem of classTagItems) {
            this.tags.push({
              name: tItem.getTagName(),
              descr: tItem.getTagDescr(),
            })
          }
        }
      } else {
        this.closeClassEdit()
      }
    },
  },

  created() {
    this.loadData()
  },
})
