import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "doc-container" }
const _hoisted_2 = { class: "row q-col-gutter-xl" }
const _hoisted_3 = { class: "text-h6 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chapterItems, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "col-3"
        }, [
          _createVNode(_component_q_card, { class: "chapter-card" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, { class: "q-card__chapters" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_q_separator),
              _createVNode(_component_q_card_actions, { vertical: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    to: item.url,
                    label: "В раздел",
                    color: "primary",
                    class: "q-mx-xl q-my-sm text-weight-bold"
                  }, null, 8, ["to"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ]))
      }), 128))
    ])
  ]))
}