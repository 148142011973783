import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { ProjectsApi } from '../services/api/ProjectsApi'
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
  isProjectsBusy: boolean = false
  projectsItems: unknown[]
  projectsItem: unknown
  selectedProjects: Array<Number>
  selectedRegistrationProjects: Array<Number>
}

// Создаем объект
const projectsApi = new ProjectsApi()

// Создаем геттеры
const getters = <GetterTree<State, any>>{
  isProjectsBusy(state): boolean {
    return state.isProjectsBusy
  },

  getProjectsItems(state): unknown[] {
    return state.projectsItems
  },

  getProjectsItem(state): unknown {
    return state.projectsItem
  },

  getSelectedProjectItems(state) {
    return state.selectedProjects
  },

  getSelectedRegistrations(state) {
    return state.selectedRegistrationProjects
  }
}

// Создаем мутации
const mutations = <MutationTree<State>>{
  setIsProjectsBusy(state, payload: boolean) {
    state.isProjectsBusy = payload
    if (payload) {
      Loading.show()
    } else {
      Loading.hide()
    }
  },

  setProjectsItems(state, payload: unknown[]) {
    state.projectsItems = payload
  },

  setProjectsItem(state, payload: unknown) {
    state.projectsItem = payload
  },

  setSelectedProjectItems(state, payload: any) {
    state.selectedProjects = payload
  },

  setSelectedRegistration(state, payload) {
    state.selectedRegistrationProjects = payload
  }
}

// Создаем действия
const actions = <ActionTree<State, any>>{
  // Устанавливаем элемент
  setProjectsItem({ commit }, data: any) {
    commit('setProjectsItem', data)
  },

  setSelectedProjectsItems({ commit }, data: any) {
    commit('setSelectedProjectItems', data)
  },

  setSelectedRegistration({ commit }, data: any) {
    commit('setSelectedRegistration', data)
  },

  // Список элементов
  getProjectsItems({ commit }, data: any) {
    // Устанавливаем событие загрузки
    commit('setIsProjectsBusy', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []
    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}
    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : {}

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      projectsApi.getProjectsItems(filter, sort, pagination).then(
        (message: any) => {
          const items = message.getItemsList()
          const sort = message.getParams().getSort()
          const pagination = message.getParams().getPagination()
          commit('setProjectsItems', items)
          commit('setIsProjectsBusy', false)
          resolve({
            items: items,
            sort: sort,
            pagination: pagination,
          })
        },
        (error) => {
          commit('setProjectsItems', [])
          commit('setIsProjectsBusy', false)
          reject(error)
        }
      )
    })
  },

  // Получить элемент
  getProjectsItem({ commit }, data: any) {
    // Устанавливаем событие загрузки
    commit('setIsProjectsBusy', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []

    return new Promise((resolve, reject) => {
      // Получаем элемент
      projectsApi.getProjectsItem(filter).then(
        (response: any) => {
          commit('setProjectsItem', response)
          commit('setIsProjectsBusy', false)
          resolve(response)
        },
        (error) => {
          commit('setIsProjectsBusy', false)
          reject(error)
        }
      )
    })
  },

  // Создать элемент
  createProjectsItem({ commit }, data) {
    // Устанавливаем событие загрузки
    commit('setIsProjectsBusy', true)

    return new Promise((resolve, reject) => {
      // Создаем элемент
      projectsApi.createProjectsItem(data).then(
        (response) => {
          commit('setIsProjectsBusy', false)
          resolve(response)
        },
        (error) => {
          commit('setIsProjectsBusy', false)
          reject(error)
        }
      )
    })
  },

  // Обновить элемент
  updateProjectsItem({ commit }, data) {
    // Устанавливаем событие загрузки
    commit('setIsProjectsBusy', true)

    return new Promise((resolve, reject) => {
      // Обновляем элемент
      projectsApi.updateProjectsItem(data).then(
        (response) => {
          commit('setIsProjectsBusy', false)
          resolve(response)
        },
        (error) => {
          commit('setIsProjectsBusy', false)
          reject(error)
        }
      )
    })
  },

  // Удалить элемент
  deleteProjectsItem({ commit }, data) {
    // Устанавливаем событие загрузки
    commit('setIsProjectsBusy', true)

    return new Promise((resolve, reject) => {
      // Удаляем элемент
      projectsApi.deleteProjectsItem(data).then(
        (response) => {
          commit('setIsProjectsBusy', false)
          resolve(response)
        },
        (error) => {
          commit('setIsProjectsBusy', false)
          reject(error)
        }
      )
    })
  },
}

// Создаем модуль
const ProjectsModule: any = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default ProjectsModule
