import InitDataAnalysis from '@/components/modules/cluster/views/idata/InitDataAnalysis.vue'
import InitDataImport from '@/components/modules/cluster/views/idata/InitDataImport.vue'
import InitDataExport from '@/components/modules/cluster/views/idata/InitDataExport.vue'
import ClsUsers from '@/components/modules/cluster/views/cls/ClsUsers.vue'
import ClsClustering from '@/components/modules/cluster/views/cls/ClsClustering.vue'

const routes = [
    {
        path: '/idata/analysis',
        name: 'InitDataAnalysis',
        component: InitDataAnalysis,
        meta: {
            breadCrumb: [
              { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
              { text: 'Анализ исходных данных' },
            ],
          },
    },
    {
        path: '/idata/import',
        name: 'InitDataImport',
        component: InitDataImport,
        meta: {
            breadCrumb: [
              { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
              { text: 'Импорт исходных данных' },
            ],
          },
    },
    {
        path: '/idata/export',
        name: 'InitDataExport',
        component: InitDataExport,
        meta: {
            breadCrumb: [
              { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
              { text: 'Экспорт исходных данных' },
            ],
          },
    },
    {
        path: '/cls/users',
        name: 'ClsUsers',
        component: ClsUsers,
        meta: {
            breadCrumb: [
              { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
              { text: 'Классификация пользователей' },
            ],
          },
    },
    {
        path: '/cls/clustering',
        name: 'ClsClustering',
        component: ClsClustering,
        meta: {
            breadCrumb: [
              { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
              { text: 'Сообщения пользователей', to: {name: 'RespProcess'} },
              { text: 'Классификация кластеризация' },
            ],
          },
    },
]

export default {
    routes
}