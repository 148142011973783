import { GetterTree, MutationTree, ActionTree } from "vuex";
import { ClassifierApi } from "../services/api/ClassifierApi";
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
  isClassifierBusy: boolean = false;
  classifierItems: unknown [];
  classifierItem: unknown;
}

// Создаем объект
const classifierApi = new ClassifierApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

  isClassifierBusy(state): boolean {
    return state.isClassifierBusy;
  },

  getClassifierItems(state): unknown[] {
    return state.classifierItems;
  },

  getClassifierItem(state): unknown {
    return state.classifierItem;
  }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

  setIsClassifierBusy(state, payload: boolean) {
    state.isClassifierBusy = payload
  },

  setClassifierItems(state, payload: unknown[]) {
    state.classifierItems = payload
  },

  setClassifierItem(state, payload: unknown) {
    state.classifierItem = payload
  }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

  // Устанавливаем элемент
  setClassifierItem({ commit }, data: any) {
    commit('setClassifierItem', data);
  },
  
  // Список элементов
  getClassifierItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsClassifierBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      classifierApi.getClassifierItems(filter, sort, pagination)
        .then(
          (message:any) => {
            const items = message.getItemsList();
            const sort = message.getParams().getSort();
            const pagination = message.getParams().getPagination();
            commit('setClassifierItems', items);
            commit('setIsClassifierBusy', false);
            resolve({
                items: items,
                sort: sort,
                pagination: pagination
            });
          },
          (error) => {
            commit('setClassifierItems', []);
            commit('setIsClassifierBusy', false);
            reject(error);
          }
        );
    });
  },

  // Получить элемент
  getClassifierItem({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsClassifierBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];

    return new Promise((resolve, reject) => {

      // Получаем элемент
      classifierApi.getClassifierItem(filter)
        .then(
          (response:any) => {
            commit('setClassifierItem', response);
            commit('setIsClassifierBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsClassifierBusy', false);
            reject(error);
          }
        );
    });
  },

  // Создать элемент
  createClassifierItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsClassifierBusy', true);

    return new Promise((resolve, reject) => {

      // Создаем элемент
      classifierApi.createClassifierItem(data)
        .then(
          (response) => {
            commit('setIsClassifierBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsClassifierBusy', false);
            reject(error);
          }
        );
    });
  },

  // Обновить элемент
  updateClassifierItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsClassifierBusy', true);

    return new Promise((resolve, reject) => {

      // Обновляем элемент
      classifierApi.updateClassifierItem(data)
        .then(
          (response) => {
            commit('setIsClassifierBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsClassifierBusy', false);
            reject(error);
          }
        );
    });
  },

  // Удалить элемент
  deleteClassifierItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsClassifierBusy', true);

    return new Promise((resolve, reject) => {

      // Удаляем элемент
      classifierApi.deleteClassifierItem(data)
        .then(
          (response) => {
            commit('setIsClassifierBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsClassifierBusy', false);
            reject(error);
          }
        );
    });
  }
};

// Создаем модуль
const ClassifierModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default ClassifierModule;
