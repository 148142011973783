import { DashboardActivityAccountServiceClient } from '../../proto/pb/Activity_accountServiceClientPb'
import {
    ListDashboardActivityAccountRequest,
    ListDashboardActivityUniqAccountRequest,
    PersonalListDashboardActivityUniqAccountRequest,
    DashboardActivityAccountFilter,
    DashboardActivityAccountFilterItem,
    DashboardActivityAccountSort,
    DashboardActivityAccountPagination,
} from '../../proto/pb/activity_account_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class ActiveAccountApi {
    client: DashboardActivityAccountServiceClient
    metadata: any

    constructor() {
        const host: string = (window as any).VUE_APP_API_URL ?? ''
        this.client = new DashboardActivityAccountServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    createFilter(data: any) {
        const filter = new DashboardActivityAccountFilter()
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && '' !== data[i].field_name) {
                    const fItem = new DashboardActivityAccountFilterItem()
                    fItem.setFieldName(data[i].field_name.toString())
                    fItem.setOperand(
                        data[i].operand !== undefined ? data[i].operand : '='
                    )
                    fItem.setValue(
                        data[i].value !== undefined ? data[i].value.toString() : ''
                    )
                    fItem.setOr(this.createFilter(data[i].or)) // Рекурсия !!!
                    filter.addItems(fItem)
                }
            }
        }
        return filter
    }

    createSort(data: any) {
        const sort = new DashboardActivityAccountSort()
        const name: string =
            data.name !== undefined ? data.name.toString() : ''
        const exp: string = data.exp !== undefined ? data.exp.toString() : 'asc'

        // Устанавливаем параметры сортировки
        sort.setName(name)
        sort.setExp(exp)

        return sort
    }

    createPagination(data: any) {
        const pagination = new DashboardActivityAccountPagination()
        const page: number = data.page !== undefined ? Number(data.page) : 1
        const limit: number =
            data.limit !== undefined ? Number(data.limit) : 10000
        const pages: number = data.pages !== undefined ? Number(data.pages) : 0
        const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0

        // Устанавливаем пагинацию
        pagination.setPage(page)
        pagination.setLimit(limit)
        pagination.setPages(pages).setCnt(cnt)

        return pagination
    }

    getActiveAccounts(filter: any, sort: any, pagination: any, data: any) {
        const request = new ListDashboardActivityAccountRequest()

        request
            .setFilter(this.createFilter(filter))
            .setSort(this.createSort(sort))
            .setPagination(this.createPagination(pagination))
            .setActivIdsList(data.activ_ids)
            .setAllActiv(data.all_active)
            .setActivityIdsList(data.activity)
            .setLoyaltyIdsList(data.loyalty)
            .setUsefulnessIdsList(data.useful)
            .setInterestIdsList(data.interest)
            .setDateBegin(data.date_begin)
            .setDateEnd(data.date_end)

        console.log('REQ ACTIVE ACCOUNTS', data)

        return this.client.listDashboardActivityAccount(request, this.metadata)
    }

    getUniqAccounts(filter: any, sort: any, pagination: any, data: any) {
        const request = new ListDashboardActivityUniqAccountRequest()

        request
            .setFilter(this.createFilter(filter))
            .setSort(this.createSort(sort))
            .setPagination(this.createPagination(pagination))
            .setActivIdsList(data.activ_ids)
            .setActivityIdsList(data.activity)
            .setLoyaltyIdsList(data.loyalty)
            .setUsefulnessIdsList(data.useful)
            .setInterestIdsList(data.interest)
            .setAllActiv(data.all_active)
            .setDateBegin(data.date_begin)
            .setDateEnd(data.date_end)

        console.log('REQ ACTIVE ACCOUNTS', request)

        return this.client.listDashboardActivityUniqAccount(request, this.metadata)
    }

    getPersonalUniqAccounts(filter: any, data: any) {
        const request = new PersonalListDashboardActivityUniqAccountRequest()

        request
            .setFilter(this.createFilter(filter))
            .setActivIdsList(data.activ_ids)
            .setActivityIdsList(data.activity)
            .setLoyaltyIdsList(data.loyalty)
            .setUsefulnessIdsList(data.useful)
            .setInterestIdsList(data.interest)
            .setAllActiv(data.all_active)
            .setDateBegin(data.date_begin)
            .setDateEnd(data.date_end)

        console.log('REQ ACTIVE ACCOUNTS PERSONAL', request)

        return this.client.personalListDashboardActivityUniqAccount(request, this.metadata)
    }

    getExportAll(filter: any, sort: any, pagination: any, data: any) {
        const request = new ListDashboardActivityAccountRequest()

        request
            .setFilter(this.createFilter(filter))
            .setSort(this.createSort(sort))
            .setPagination(this.createPagination(pagination))
            .setActivIdsList(data.activ_ids)
            .setAllActiv(data.all_active)
            .setActivityIdsList(data.activity)
            .setLoyaltyIdsList(data.loyalty)
            .setUsefulnessIdsList(data.useful)
            .setInterestIdsList(data.interest)
            .setDateBegin(data.date_begin)
            .setDateEnd(data.date_end)

        console.log('EXPORT ALL REQ', request)

        return this.client.listDashboardActivityAccount(request, this.metadata)
    }

    getExportUniq(filter: any, sort: any, pagination: any, data: any) {
        const request = new ListDashboardActivityUniqAccountRequest()

        request
            .setFilter(this.createFilter(filter))
            .setSort(this.createSort(sort))
            .setPagination(this.createPagination(pagination))
            .setActivIdsList(data.activ_ids)
            .setActivityIdsList(data.activity)
            .setLoyaltyIdsList(data.loyalty)
            .setUsefulnessIdsList(data.useful)
            .setInterestIdsList(data.interest)
            .setAllActiv(data.all_active)
            .setDateBegin(data.date_begin)
            .setDateEnd(data.date_end)

        console.log('REQ ACTIVE ACCOUNTS', request)

        return this.client.listDashboardActivityUniqAccount(request, this.metadata)
    }
}