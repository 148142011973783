
import store from '@/store'
import { defineComponent } from 'vue'
import ClassPanel from '../class/ClassPanel.vue'
import ClassAdd from '../class/ClassAdd.vue'
import ClassEdit from '../class/ClassEdit.vue'
import ClassDelete from '../class/ClassDelete.vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'ClassView',

  components: {
    ClassPanel,
    ClassAdd,
    ClassEdit,
    ClassDelete,
    DataLoading,
  },

  data() {
    return {
      classifier: {
        id: 0,
        name: '',
        descr: '',
        notes: '',
        delst: false,
      },
      filter: '',
      search: '',
      oldSearch: '',
      searchTimeout: setTimeout(() => {}, 500) as ReturnType<typeof setTimeout>,
      minSearchSymbol: 3,
      isActiveModal: false,
      isActiveModalAdd: false,
      isActiveModalEdit: false,
      isActiveModalDelete: false,
      splitterSize: 40,
      selectedNode: null as any,
    }
  },

  computed: {
    isClassifierBusy() {
      return store.getters.getIsClassifierBusy
    },

    isClassBusy() {
      return store.getters.getIsClassBusy
    },

    isClassTreeBusy() {
      return store.getters.getIsClassBusy
    },

    getClassTreeItems() {
      let nodes = store.getters.getClassTreeItems
      return nodes ? nodes : []
    },

    userRole() {
      return store.getters.getUserAccess
    },
  },

  methods: {
    hideModal() {
      this.isActiveModal = false
      this.isActiveModalAdd = false
      this.isActiveModalEdit = false
      this.isActiveModalDelete = false
    },

    addClass() {
      this.isActiveModalAdd = true
      console.log('addClass')
      this.isActiveModal = true
    },

    editClass() {
      this.isActiveModalEdit = true
      console.log('editClass')
      this.isActiveModal = true
    },

    deleteClass() {
      this.isActiveModalDelete = true
      console.log('deleteClass')
      this.isActiveModal = true
    },

    afterAction(setNode: any) {
      console.log('afterAction')
      this.reopenedTree(setNode, true) // true - перегружаем дерево с сервера
      this.hideModal()
    },

    selectNode() {
      let currentNode: any = null
      console.log('selectNode this.selectedNode', this.selectedNode)
      if (this.selectedNode) {
        const tree: any = this.$refs.classTree
        console.log('REF TREE', tree)
        const nodeData: any =
          tree !== undefined
            ? JSON.parse(JSON.stringify(tree.getNodeByKey(this.selectedNode)))
            : null
        console.log('selectNode nodeData', nodeData)
        if (nodeData) {
          const parentNode: any = nodeData.parent_id
            ? JSON.parse(JSON.stringify(tree.getNodeByKey(nodeData.parent_id)))
            : null
          currentNode = {
            id: nodeData.id,
            cls_id: this.classifier.id,
            parent_id: nodeData.parent_id ? nodeData.parent_id : 0,
            idx: nodeData.idx,
            name: nodeData.name,
            group_name: nodeData.group_name,
            color: nodeData.color,
            descr: nodeData.descr,
            notes: nodeData.notes,
            ratio: nodeData.ratio,
            hasChilds:
              nodeData.children !== undefined && nodeData.children.length > 0,
            parentName: parentNode ? parentNode.name : '',
          }
        }
      }
      // Устанавливаем текущий узел
      store.dispatch('setCurrentNode', currentNode).then(() => {
        if (currentNode) {
          // Подгрузка тэгов для класса
          store
            .dispatch('getClassTagItems', {
              filter: [
                {
                  field_name: 'class_id',
                  operand: '=',
                  value: currentNode.id,
                },
              ],
            })
            .then(
              (resolve) => {
                console.log('resolve', resolve)
              },
              (reject) => {
                console.log('reject', reject)
              }
            )
        }
      })
    },

    // Перегружаем дерево классификатора
    reopenedTree(setNode: any, isReloadTree: any) {
      if (isReloadTree) {
        this.loadClassTreeItems(setNode)
      } else {
        this.setTreeNode(setNode)
      }
    },

    setTreeNode(setNode: any) {
      if (setNode) {
        console.log('setTreeNode setNode', setNode)
        this.selectedNode = setNode
        this.selectNode()
        const tree: any = this.$refs.classTree
        let curNode: any =
          tree !== undefined
            ? JSON.parse(JSON.stringify(tree.getNodeByKey(this.selectedNode)))
            : null
        while (curNode && curNode.parent_id !== undefined) {
          curNode = JSON.parse(
            JSON.stringify(tree.getNodeByKey(curNode.parent_id))
          )
          if (curNode) {
            tree.setExpanded(curNode.id, true)
          }
        }
      }
    },

    strMarkRedPlus(search: string, subject: string) {
      let escapeRegExp = function (str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
      }

      return subject.replace(
        new RegExp('(' + escapeRegExp(search) + ')', 'gi'),
        "<span style='color: red;'><b>$1</b></span>"
      )
    },

    initSearch() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      this.filter = ''
      this.search = ''
      this.oldSearch = ''
      this.searchTimeout = setTimeout(() => {}, 500)
    },

    onInputSearchControl(event: any) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      console.log('onInputSearchControl event', event)
      this.searchTimeout = setTimeout(() => {
        const searchStr: string = event.target.value

        if (searchStr.length >= this.minSearchSymbol) {
          // Устанавливаем строку поиска
          this.search = searchStr.toLowerCase()
          //this.loadClassTreeItems(null) // Временно убран серверный поиск !!!
        } else {
          this.clearSearch(this.oldSearch.length >= this.minSearchSymbol)
        }
        this.oldSearch = searchStr
      }, 500)
    },

    clearSearch(isReload = true) {
      this.search = ''
      if (isReload) {
        //this.loadClassTreeItems(null)// Временно убран серверный поиск !!!
      }
    },

    getFilters() {
      return [
        {
          field_name: 'classifier_id',
          operand: '=',
          value: this.classifier.id,
        } /*, { // Временно убран серверный поиск !!!
                    field_name: 'class_name',
                    operand: 'ilike',
                    value: this.search
                }*/,
      ]
    },

    loadClassTreeItems(setNode: any) {
      store.dispatch('setCurrentNode', null)
      store
        .dispatch('getClassTreeItems', {
          filter: this.getFilters(),
        })
        .then(
          (resolve) => {
            console.log('getClassTreeItems resolve', resolve)
            this.setTreeNode(setNode)
          },
          (reject) => {
            console.log('getClassTreeItems reject', reject)
          }
        )
    },

    loadData() {
      const clsId = this.$route.params.id

      store
        .dispatch('getClassifierItem', {
          filter: [
            {
              field_name: 'classifier_id',
              operand: '=',
              value: clsId,
            },
          ],
        })
        .then(
          (resolve) => {
            console.log('getClassifierItem resolve', resolve)
            this.classifier = {
              id: resolve.getClassifierId(),
              name: resolve.getClassifierName(),
              descr: resolve.getClassifierDescr(),
              notes: resolve.getClassifierNotes(),
              delst: resolve.getDelst(),
            }
            this.initSearch()
            this.loadClassTreeItems(null)
          },
          (reject) => {
            console.log('getClassifierItem reject', reject)
          }
        )
    },
  },

  created() {
    this.loadData()
  },
})
