/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: scorecard_value.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.proto = require('./scorecard_value_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ScorecardValueServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ScorecardValueServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateScorecardValueRequest,
 *   !proto.proto.CreateScorecardValueResponse>}
 */
const methodDescriptor_ScorecardValueService_CreateScorecardValue = new grpc.web.MethodDescriptor(
  '/proto.ScorecardValueService/CreateScorecardValue',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateScorecardValueRequest,
  proto.proto.CreateScorecardValueResponse,
  /**
   * @param {!proto.proto.CreateScorecardValueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreateScorecardValueResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateScorecardValueRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CreateScorecardValueResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreateScorecardValueResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ScorecardValueServiceClient.prototype.createScorecardValue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ScorecardValueService/CreateScorecardValue',
      request,
      metadata || {},
      methodDescriptor_ScorecardValueService_CreateScorecardValue,
      callback);
};


/**
 * @param {!proto.proto.CreateScorecardValueRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreateScorecardValueResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ScorecardValueServicePromiseClient.prototype.createScorecardValue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ScorecardValueService/CreateScorecardValue',
      request,
      metadata || {},
      methodDescriptor_ScorecardValueService_CreateScorecardValue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateScorecardValueRequest,
 *   !proto.proto.UpdateScorecardValueResponse>}
 */
const methodDescriptor_ScorecardValueService_UpdateScorecardValue = new grpc.web.MethodDescriptor(
  '/proto.ScorecardValueService/UpdateScorecardValue',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateScorecardValueRequest,
  proto.proto.UpdateScorecardValueResponse,
  /**
   * @param {!proto.proto.UpdateScorecardValueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpdateScorecardValueResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateScorecardValueRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpdateScorecardValueResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpdateScorecardValueResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ScorecardValueServiceClient.prototype.updateScorecardValue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ScorecardValueService/UpdateScorecardValue',
      request,
      metadata || {},
      methodDescriptor_ScorecardValueService_UpdateScorecardValue,
      callback);
};


/**
 * @param {!proto.proto.UpdateScorecardValueRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpdateScorecardValueResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ScorecardValueServicePromiseClient.prototype.updateScorecardValue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ScorecardValueService/UpdateScorecardValue',
      request,
      metadata || {},
      methodDescriptor_ScorecardValueService_UpdateScorecardValue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteScorecardValueRequest,
 *   !proto.proto.DeleteScorecardValueResponse>}
 */
const methodDescriptor_ScorecardValueService_DeleteScorecardValue = new grpc.web.MethodDescriptor(
  '/proto.ScorecardValueService/DeleteScorecardValue',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteScorecardValueRequest,
  proto.proto.DeleteScorecardValueResponse,
  /**
   * @param {!proto.proto.DeleteScorecardValueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.DeleteScorecardValueResponse.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteScorecardValueRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.DeleteScorecardValueResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.DeleteScorecardValueResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ScorecardValueServiceClient.prototype.deleteScorecardValue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ScorecardValueService/DeleteScorecardValue',
      request,
      metadata || {},
      methodDescriptor_ScorecardValueService_DeleteScorecardValue,
      callback);
};


/**
 * @param {!proto.proto.DeleteScorecardValueRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.DeleteScorecardValueResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ScorecardValueServicePromiseClient.prototype.deleteScorecardValue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ScorecardValueService/DeleteScorecardValue',
      request,
      metadata || {},
      methodDescriptor_ScorecardValueService_DeleteScorecardValue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListScorecardValueRequest,
 *   !proto.proto.ListScorecardValueResponse>}
 */
const methodDescriptor_ScorecardValueService_ListScorecardValue = new grpc.web.MethodDescriptor(
  '/proto.ScorecardValueService/ListScorecardValue',
  grpc.web.MethodType.UNARY,
  proto.proto.ListScorecardValueRequest,
  proto.proto.ListScorecardValueResponse,
  /**
   * @param {!proto.proto.ListScorecardValueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListScorecardValueResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListScorecardValueRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListScorecardValueResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListScorecardValueResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ScorecardValueServiceClient.prototype.listScorecardValue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ScorecardValueService/ListScorecardValue',
      request,
      metadata || {},
      methodDescriptor_ScorecardValueService_ListScorecardValue,
      callback);
};


/**
 * @param {!proto.proto.ListScorecardValueRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListScorecardValueResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ScorecardValueServicePromiseClient.prototype.listScorecardValue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ScorecardValueService/ListScorecardValue',
      request,
      metadata || {},
      methodDescriptor_ScorecardValueService_ListScorecardValue);
};


module.exports = proto.proto;

