import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, { class: "q-ma-md q-pa-md" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_avatar, {
        size: "100px",
        "font-size": "52px",
        color: "teal",
        "text-color": "white",
        icon: "account_circle"
      })
    ]),
    _: 1
  }))
}