import { PointServiceClient } from '../../proto/pb/PointServiceClientPb'
import {
  PointSort,
  CountPointRequest,
  ListPointEarnedRequest,
  ListPointSpentRequest,
  PointPagination,
  ListPointEarnedSheduleRequest,
  ListPointSpentSheduleRequest,
  ListPointBalanceSheduleRequest,
  CountPointSegmentRequest,
} from '../../proto/pb/point_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

const defaultLimit: number = 1000
export class PointsApi {
  client: PointServiceClient
  metadata: any

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? ''
    this.client = new PointServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  createSort(data: any) {
    const sort = new PointSort()
    const name: string =
      data.name !== undefined ? data.name.toString() : 'point_date'
    const exp: string = data.exp !== undefined ? data.exp.toString() : 'desc'

    // Устанавливаем параметры сортировки
    sort.setName(name)
    sort.setExp(exp)

    return sort
  }

  createPagination(data: any) {
    const pagination = new PointPagination()
    const page: number = data.page !== undefined ? Number(data.page) : 1
    const limit: number =
      data.limit !== undefined ? Number(data.limit) : defaultLimit
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0

    // Устанавливаем пагинацию
    pagination.setPage(page)
    pagination.setLimit(limit)
    pagination.setPages(pages).setCnt(cnt)

    return pagination
  }

  getEarnedPointsList(data: any, pagination: any, sort: any) {
    const request = new ListPointEarnedRequest()

    request
      .setSsoId(data.sso_id)
      .setProjectIdsList(data.project_ids)
      .setActionNamesList(data.action_names)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)
      .setSort(this.createSort(sort))
      .setPointType(data.point_type)

    request.setPagination(this.createPagination(pagination))

    console.log('EARNED POINTS REQ', request)

    return this.client.listPointEarned(request, { Authorization: localStorage.getItem('access-key') } as any)
  }

  getSpentPointsList(data: any, pagination: any, sort: any) {
    const request = new ListPointSpentRequest()

    request
      .setSsoId(data.sso_id)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)
      .setSort(this.createSort(sort))
      .setPointType(data.point_type)

    request.setPagination(this.createPagination(pagination))

    return this.client.listPointSpent(request, { Authorization: localStorage.getItem('access-key') } as any)
  }

  getEarnedChartList(data: any) {
    const request = new ListPointEarnedSheduleRequest()

    request
      .setSsoId(data.sso_id)
      .setProjectIdsList(data.project_ids)
      .setActionNamesList(data.action_names)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)
      .setMode(data.mode || 'month')

    return this.client.listPointEarnedShedule(request, { Authorization: localStorage.getItem('access-key') } as any)
  }

  getSpentChartList(data: any) {
    const request = new ListPointSpentSheduleRequest()

    request
      .setSsoId(data.sso_id)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)
      .setMode(data.mode || 'month')

    return this.client.listPointSpentShedule(request, { Authorization: localStorage.getItem('access-key') } as any)
  }

  getCountPoints(data: any) {
    const request = new CountPointRequest()

    request
      .setSsoId(data.sso_id)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)
      .setProjectIdsList(data.project_ids || [])
      .setActionNamesList(data.action_names || [])

    return this.client.countPoint(request, { Authorization: localStorage.getItem('access-key') } as any)
  }

  getListPointBalance(data: any) {
    const request = new ListPointBalanceSheduleRequest()

    request
      .setSsoId(data.sso_id)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)
      .setMode(data.mode || 'month')
    console.log(
      '🚀 ~ file: PointsApi.ts:184 ~ PointsApi ~ getListPointBalance ~ request:',
      request
    )

    return this.client.listPointBalanceShedule(request, { Authorization: localStorage.getItem('access-key') } as any)
  }

  getCountPointSegmentation(data: any) {
    const request = new CountPointSegmentRequest()

    request
      .setDateFrom(data.date_from)
      .setDateTo(data.date_to)
      .setPercent(data.percent)
      .setValue(data.value)
      .setProjectIdsList(data.project_ids || [])
      .setFromMetricAlias(data.from_name)
      .setToMetricAlias(data.to_name)
      .setIsAdditionalParams(data.add_param)
      .setFromMetricValueIdsList(data.from_ids)
      .setToMetricValueIdsList(data.to_ids)
    console.log(
      '🚀 ~ file: PointsApi.ts:216 ~ PointsApi ~ getCountPointSegmentation ~ request:',
      request
    )

    return this.client.countPointSegment(request, { Authorization: localStorage.getItem('access-key') } as any)
  }
}
