import { DistrictServiceClient } from '../../proto/pb/DistrictServiceClientPb'
import { RegionServiceClient } from '../../proto/pb/RegionServiceClientPb'
import {
  DistrictFilter,
  DistrictFilterItem,
  DistrictPagination,
  DistrictSort,
  ListDistrictRequest,
} from '../../proto/pb/district_pb'
import {
  RegionFilter,
  RegionFilterItem,
  RegionPagination,
  RegionSort,
  ListRegionRequest,
} from '../../proto/pb/region_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

const defaultLimit: number = 1000

export class DistrictRegionApi {
  clientDistrict: DistrictServiceClient
  clientRegion: RegionServiceClient
  metadata: any

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? ''
    this.clientDistrict = new DistrictServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
    this.clientRegion = new RegionServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  createFilter(data: any, newFilter: any, newFilterItem: any) {
    const filter = new newFilter()
    if (data && data.length) {
      for (const i in data) {
        if (data[i].field_name && '' !== data[i].field_name) {
          const fItem = new newFilterItem()
          fItem.setFieldName(data[i].field_name.toString())
          fItem.setOperand(
            data[i].operand !== undefined ? data[i].operand : '='
          )
          fItem.setValue(
            data[i].value !== undefined ? data[i].value.toString() : ''
          )
          fItem.setOr(this.createFilter(data[i].or, newFilter, newFilterItem)) // Рекурсия !!!
          filter.addItems(fItem)
        }
      }
    }
    return filter
  }

  createSort(data: any, sortType: any) {
    const sort = new sortType()
    const name: string = data.name !== undefined ? data.name.toString() : ''
    const exp: string = data.exp !== undefined ? data.exp.toString() : ''

    // Устанавливаем параметры сортировки
    sort.setName(name)
    sort.setExp(exp)

    return sort
  }

  createPagination(data: any, paginationType: any) {
    const pagination = new paginationType()
    const page: number = data.page !== undefined ? Number(data.page) : 1
    const limit: number =
      data.limit !== undefined ? Number(data.limit) : defaultLimit
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0

    // Устанавливаем пагинацию
    pagination.setPage(page)
    pagination.setLimit(limit)
    pagination.setPages(pages)
    pagination.setCnt(cnt)

    return pagination
  }

  ListDistrict(filter: any) {
    const request = new ListDistrictRequest()
    request
      .setFilter(this.createFilter(filter, DistrictFilter, DistrictFilterItem))
      .setSort(this.createSort({}, DistrictSort))
      .setPagination(this.createPagination({}, DistrictPagination))

    return this.clientDistrict.listDistrict(request, { Authorization: localStorage.getItem('access-key') } as any)
  }

  ListRegion(filter: any) {
    const request = new ListRegionRequest()
    request
      .setFilter(this.createFilter(filter, RegionFilter, RegionFilterItem))
      .setSort(this.createSort({}, RegionSort))
      .setPagination(this.createPagination({}, RegionPagination))

    return this.clientRegion.listRegion(request, { Authorization: localStorage.getItem('access-key') } as any)
  }
}
