import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { AnswerSrcApi } from '../services/api/AnswerSrcApi';
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
  isAnswerSrcBusy: boolean = false;
  isAnswerSrcSourceBusy: boolean = false;
  isExportAnswerSrcSourceItems: boolean = false;
  answerSrcItems: unknown [];
  answerSrcSourceItems: unknown [];
  exportAnswerSrcSourceItems: unknown[];
  answerSrcItem: unknown;
  answerSrcSourceItem: unknown;
  answerInClassItems: unknown [];
  isAnswerInClassItems: boolean = false;
  answerExportInClassItems: unknown [];
  isAnswerExportInClassItems: boolean = false;
}

// Создаем объект
const answerSrcApi = new AnswerSrcApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{
  isAnswerSrcBusy(state): boolean {
    return state.isAnswerSrcBusy;
  },

  isAnswerSrcSourceBusy(state): boolean {
    return state.isAnswerSrcSourceBusy;
  },

  isExportAnswerSrcSourceItems(state): boolean {
      return state.isExportAnswerSrcSourceItems;
  },

  getAnswerSrcItems(state): unknown[] {
    return state.answerSrcItems;
  },

  getAnswerSrcSourceItems(state): unknown[] {
    return state.answerSrcSourceItems;
  },

  getExportAnswerSrcSourceItems(state): unknown[] {
    return state.exportAnswerSrcSourceItems;
  },

  getAnswerSrcItem(state): unknown {
    return state.answerSrcItem;
  },

  getAnswerSrcSourceItem(state): unknown {
    return state.answerSrcSourceItem;
  },

  getAnswerInClassItems(state): unknown[] {
      return state.answerInClassItems
  },

  isAnswerInClassItems(state): boolean {
      return state.isAnswerInClassItems
  },

    getAnswerExportInClassItems(state): unknown[] {
        return state.answerExportInClassItems
    },

    isAnswerExportInClassItems(state): boolean {
        return state.isAnswerExportInClassItems
    }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

  setIsAnswerSrcBusy(state, payload: boolean) {
    state.isAnswerSrcBusy = payload
  },

  setIsAnswerSrcSourceBusy(state, payload: boolean) {
    state.isAnswerSrcSourceBusy = payload
  },

  setIsExportAnswerSrcSourceItems(state, payload: boolean) {
    state.isExportAnswerSrcSourceItems = payload;
  },

  setAnswerSrcItems(state, payload: unknown[]) {
    state.answerSrcItems = payload
  },

  setAnswerSrcSourceItems(state, payload: unknown[]) {
    state.answerSrcSourceItems = payload
  },

  setAnswerSrcItem(state, payload: unknown) {
    state.answerSrcItem = payload
  },

  setAnswerSrcSourceItem(state, payload: unknown) {
    state.answerSrcSourceItem = payload
  },

  setExportAnswerSrcSourceItems(state, payload: unknown[]){
    state.exportAnswerSrcSourceItems = payload;
  },

  setAnswerInClassItems(state, payload: unknown[]) {
      state.answerInClassItems = payload
  },

  setIsAnswerInClassItems(state, payload: boolean) {
      state.isAnswerInClassItems = payload
  },

    setAnswerExportInClassItems(state, payload: unknown[]) {
        state.answerExportInClassItems = payload
    },

    setIsAnswerExportInClassItems(state, payload: boolean) {
        state.isAnswerExportInClassItems = payload
    }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

  // Устанавливаем элемент
  setAnswerSrcItem({ commit }, data: any) {
    commit('setAnswerSrcItem', data);
  },
  
  // Устанавливаем элемент source
  setAnswerSrcSourceItem({ commit }, data: any) {
    commit('setAnswerSrcSourceItem', data);
  },

    // Список элементов
  getAnswerSrcItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerSrcBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      answerSrcApi.getAnswerSrcItems(filter, sort, pagination)
        .then(
          (message:any) => {
            const items = message.getItemsList();
            const sort = message.getParams().getSort();
            const pagination = message.getParams().getPagination();
            commit('setAnswerSrcItems', items);
            commit('setIsAnswerSrcBusy', false);
            resolve({
                items: items,
                sort: sort,
                pagination: pagination
            });
          },
          (error) => {
            commit('setAnswerSrcItems', []);
            commit('setIsAnswerSrcBusy', false);
            reject(error);
          }
        );
    });
  },

    // Список элементов для модалки
  getAnswerSrcSourceItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerSrcSourceBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
    const answer_grp_id:any = data !== undefined && data.answer_grp_id !== undefined ? data.answer_grp_id : 0;
    const is_full:any = data !== undefined && data.is_full !== undefined ? data.is_full : false;

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      answerSrcApi.getAnswerSrcSourceItems(filter, sort, pagination, answer_grp_id, is_full)
        .then(
          (message:any) => {
               console.log('getAnswerSrcSourceItems message', message)
            const items = message.getItemsList();
            const sort = message.getParams().getSort();
            const pagination = message.getParams().getPagination();
            commit('setAnswerSrcSourceItems', items);
            commit('setIsAnswerSrcSourceBusy', false);
            resolve({
                items: items,
                sort: sort,
                pagination: pagination
            });
          },
          (error) => {
            commit('setAnswerSrcSourceItems', []);
            commit('setIsAnswerSrcSourceBusy', false);
            reject(error);
          }
        );
    });
  },

    getPersonalAnswerSrcSourceItems({ commit }, data: any) {
        // Устанавливаем событие загрузки
        commit('setIsAnswerSrcSourceBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const answer_grp_id:any = data !== undefined && data.answer_grp_id !== undefined ? data.answer_grp_id : 0;
        const is_full:any = data !== undefined && data.is_full !== undefined ? data.is_full : false;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            answerSrcApi.getPersonalAnswerSrcSourceItems(filter, answer_grp_id, is_full)
                .then(
                    (message:any) => {
                        commit('setIsAnswerSrcSourceBusy', false);

                        resolve(message);
                    },
                    (error) => {
                        commit('setIsAnswerSrcSourceBusy', false);

                        reject(error);
                    }
                );
        });
    },

    getExportAnswerSrcSourceItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsExportAnswerSrcSourceItems', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const answer_grp_id:any = data !== undefined && data.answer_grp_id !== undefined ? data.answer_grp_id : 0;
        const is_full:any = data !== undefined && data.is_full !== undefined ? data.is_full : false;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            answerSrcApi.getAnswerSrcSourceItems(filter, sort, pagination, answer_grp_id, is_full)
                .then(
                    (message:any) => {
                        console.log('getExportAnswerSrcSourceItems message', message)
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setExportAnswerSrcSourceItems', items);
                        commit('setIsExportAnswerSrcSourceItems', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setExportAnswerSrcSourceItems', []);
                        commit('setIsExportAnswerSrcSourceItems', false);
                        reject(error);
                    }
                );
        });
    },

    getAnswerInClassItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsAnswerInClassItems', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const class_name:any = data !== undefined && data.class_name !== undefined ? data.class_name : '';

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            answerSrcApi.getAnswerSrcInClass(filter, sort, pagination, class_name)
                .then(
                    (message:any) => {
                        console.log('getExportAnswerSrcSourceItems message', message)
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setAnswerInClassItems', items);
                        commit('setIsAnswerInClassItems', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setAnswerInClassItems', []);
                        commit('setIsAnswerInClassItems', false);
                        reject(error);
                    }
                );
        });
    },

    getAnswerExportInClassItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsAnswerExportInClassItems', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const class_name:any = data !== undefined && data.class_name !== undefined ? data.class_name : '';

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            answerSrcApi.getExportAnswerSrcInClass(filter, sort, pagination, class_name)
                .then(
                    (message:any) => {
                        console.log('getExportAnswerSrcSourceItems message', message)
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setAnswerExportInClassItems', items);
                        commit('setIsAnswerExportInClassItems', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setAnswerExportInClassItems', []);
                        commit('setIsAnswerExportInClassItems', false);
                        reject(error);
                    }
                );
        });
    }
};

// Создаем модуль
const AnswerSrcModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default AnswerSrcModule;
