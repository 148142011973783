import UsersList from '../views/users/UsersList.vue'
import store from "@/store";

const routes = [
    {
        path: '/users/manage',
        name: 'UsersList',
        component: UsersList,
        meta: {
            breadCrumb: [
              { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
              { text: 'Системные пользователи' },
            ],
            admin: true,
            editor: false
          },
    },
]

export default {
    routes
}