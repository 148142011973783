import QTable from 'quasar/src/components/table/QTable.js';
import { ref } from "vue"

type RequiredNotNull<T> = {
  [P in keyof T]-?: NonNullable<T[P]>
}

export type Pagination = RequiredNotNull<NonNullable<QTable['pagination']>>

export type RequestParam = Parameters<NonNullable<QTable['onRequest']>>['0']
/**
 * The `pagination` object includes the following properties:
 * - `sortBy`: The column to sort by.
 * - `descending`: Boolean indicating if the sorting is descending.
 * - `rowsPerPage`: Number of rows per page.
 * - `rowsNumber`: Total number of rows.
 * - `page`: Current page number.
 *
 * The `onTableRequest` function can be used as the `onRequest` prop for a table component.
 * It wraps your custom function, updating the `pagination` object with new values
 * when the user interacts with the table.
 *
 * ### Example Usage
*
* 1. Using as a link:
 * @example
 * ```typescript
 * const { onTableRequest, pagination } = usePagination();
 * const load = onTableRequest(loadData);
 *
 * <q-table
 *  ...
 *  @request="load"
 * />
 * ```
 *
 * 2. Using as an anonymous function with a passed argument:
 * @example
 * ```typescript
 * const { onTableRequest, pagination } = usePagination();
 *
 * const onRequest = onTableRequest((props) => loadData(props, 'argument'))
 * ```
 *
 *  @example
 * ```typescript
 * const { onTableRequest, pagination } = usePagination();
 *
 * const onRequest = onTableRequest((props) => loadData());
 * ```
 *
 * @param {Pagination} [options] - Initial pagination options. If not provided, default values will be used.
 * @returns {{pagination: Ref<Pagination>, onTableRequest: <T extends (args: RequestParam) => unknown>(fn: T) => QTable['onRequest']}}
 */
export const usePagination = (options?: Pagination) => {

  const pagination = ref<Pagination>(
    {
      sortBy:options?.sortBy ?? '',
      descending:options?.descending ?? false,
      rowsPerPage:options?.rowsPerPage ?? 10,
      rowsNumber:options?.rowsNumber ?? 0,
      page:options?.page ?? 1
    }
  )

  const onTableRequest = <T extends (args?:RequestParam) => unknown >(fn:T) => {

    const request:QTable['onRequest'] = (props) => {
      pagination.value  = {...props.pagination,rowsNumber:pagination.value.rowsNumber}
      fn(props)
    }

    return request
  }

  //TODO: Add watcher for page property and add it in query param to url for preserving page on reload
  //TODO: Add onmount to initialize page from url query param

  return {
    pagination,
    onTableRequest
  }
}
