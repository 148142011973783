import {
  ScorecardServicePromiseClient
} from '../../proto/pb/scorecard_grpc_web_pb';
import {
  Scorecard, 
  ListScorecardRequest,
  //ItemScorecardRequest,
  ScorecardFilter,
  ScorecardFilterItem,
  ScorecardSort,
  ScorecardPagination,
  CreateScorecardRequest,  
  UpdateScorecardRequest, 
  DeleteScorecardRequest
} from '../../proto/pb/scorecard_pb';
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 10000;

export class ScorecardApi {
  items: Array<Scorecard> = [];
  client: ScorecardServicePromiseClient;
  metadata: any;

  constructor() {
    const host:string = (window as any).VUE_APP_API_URL ?? '';
    this.client = new ScorecardServicePromiseClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  // Установка и получение фильтров
  createFilter(data: any){

    const filter = new ScorecardFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
        for (const i in data) {
            if (data[i].field_name && ('' !== data[i].field_name)) {
                const fItem = new ScorecardFilterItem();
                fItem.setFieldName(data[i].field_name.toString());
                fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                filter.addItems(fItem);
            }
        }
    }

    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any){

    const sort = new ScorecardSort();
    const name:string = data.name !== undefined ? data.name.toString() : 'scorecard_idx';
    const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any){

    const pagination = new ScorecardPagination();
    const page:number = data.page !== undefined ? Number(data.page) : 1;
    const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages:number = data.pages !== undefined ? Number(data.pages) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  // Список элементов
  getScorecardItems(filter: any, sort: any, pagination: any){

    const request = new ListScorecardRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));

    return this.client.listScorecard(request, this.metadata)
        .then((message) => {
            return message.getItemsList()
        })
  }

  // Получение элемента
  getScorecardItem(filter: any){

    const request = new ListScorecardRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort({}));
    request.setPagination(this.createPagination({}));

    return this.client.listScorecard(request, this.metadata)
        .then((message) => {
            const itemList = message.getItemsList()

            if (itemList && (itemList.length > 0)) {
                return itemList[0]
            }
        })
  }

  // Создание элемента
  createScorecardItem(data: any){

    const request = new CreateScorecardRequest();
    request.setScorecardParentId(data.parent_id);
    request.setScorecardIdx(data.idx);
    request.setScorecardTitle(data.title);
    request.setScorecardWeight(data.weight);

    return this.client.createScorecard(request, this.metadata);
  }

  // Редактирование элемента
  updateScorecardItem(data: any){

    const request = new UpdateScorecardRequest();
    request.setScorecardId(data.id);
    request.setScorecardParentId(data.parent_id);
    request.setScorecardIdx(data.idx);
    request.setScorecardTitle(data.title);
    request.setScorecardWeight(data.weight);

    return this.client.updateScorecard(request, this.metadata);
  }

  // Удаление элемента
  deleteScorecardItem(data: any){

    const request = new DeleteScorecardRequest();
    request.setScorecardId(data.id);

    return this.client.deleteScorecard(request, this.metadata);
  }
}
