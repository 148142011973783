import { GetterTree, MutationTree, ActionTree } from "vuex";
import { WordsApi } from "../services/api/WordsApi";
import Loading from 'quasar/src/plugins/loading/Loading.js';
import storeBase from "@/store/mixins/base";
//import MainMixin from "@/mixins/main";

class State {
  isWordsBusy: boolean = false;
  isWordsTreeBusy: boolean = false;
  isWordsTreeFormBusy: boolean = false;
  wordsItems: unknown [];
  wordsTreeItems: unknown [];
  wordsTreeFormItems: unknown [];
  wordsItem: unknown;
  wordId: any = null;
  currentWordsNode: any = null;
}

// Создаем объект
const wordsApi = new WordsApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

  isWordsBusy(state): boolean {
    return state.isWordsBusy;
  },

  isWordsTreeBusy(state): boolean {
    return state.isWordsTreeBusy;
  },

  isWordsTreeFormBusy(state): boolean {
    return state.isWordsTreeFormBusy;
  },

  getWordsItems(state): unknown[] {
    return state.wordsItems;
  },

  getWordsTreeItems(state): unknown[] {
    return state.wordsTreeItems;
  },

  getWordsTreeFormItems(state): unknown[] {
    return state.wordsTreeFormItems;
  },

  getWordsItem(state): unknown {
    return state.wordsItem;
  },

  getWordId(state): any {
    return state.wordId;
  },

  getCurrentWordsNode(state): any {
    return state.currentWordsNode;
  }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

  setIsWordsBusy(state, payload: boolean) {
    state.isWordsBusy = payload
  },

  setIsWordsTreeBusy(state, payload: boolean) {
    state.isWordsTreeBusy = payload
  },

  setIsWordsTreeFormBusy(state, payload: boolean) {
    state.isWordsTreeFormBusy = payload
  },

  setWordsItems(state, payload: unknown[]) {
    state.wordsItems = payload
  },

  setWordsTreeItems(state, payload: unknown[]) {
    state.wordsTreeItems = payload
  },

  setWordsTreeFormItems(state, payload: unknown[]) {
    state.wordsTreeFormItems = payload
  },

  setWordsItem(state, payload: unknown) {
    state.wordsItem = payload
  },

  setWordId(state, payload: any) {
    state.wordId = payload
  },

  setCurrentWordsNode(state, payload: any) {
    state.currentWordsNode = payload
  }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

  // Устанавливаем элемент
  setWordsItem({ commit }, data: any) {
    commit('setWordsItem', data);
  },
  
  // Устанавливаем текущий wordId
  setWordId({ commit }, data: any) {
    commit('setWordId', data);
  },
  
  // Устанавливаем текущий узел дерева
  setCurrentWordsNode({ commit }, data: any) {
    commit('setCurrentWordsNode', data);
  },
  
  // Дерево классификатора
  getWordsTreeItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsWordsTreeBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      wordsApi.getWordsItems(filter, sort, pagination)
        .then(
          (response: any) => {
            commit('setWordsItems', response);

            // Конвертируем данные для дерева
            const tree = storeBase.methods.convertWordsItemsToTree(response)
            commit('setWordsTreeItems', tree);
            commit('setIsWordsTreeBusy', false);
            resolve(tree);
          },
          (error: any) => {
            commit('setWordsTreeItems', []);
            commit('setIsWordsTreeBusy', false);
            reject(error);
          }
        );
    });
  },

  // Дерево классификатора для формы
  getWordsTreeFormItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsWordsTreeFormBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
    const root:any = data !== undefined && data.root !== undefined ? data.root : { id: -1, text: 'Нет', is_linked: true };
    const disabled:any = data !== undefined && data.disabled !== undefined && Array.isArray(data.disabled) ? data.disabled : [];

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      wordsApi.getWordsItems(filter, sort, pagination)
        .then(
          (response: any) => {

            // Конвертируем данные для дерева
            const tree = storeBase.methods.convertWordsItemsToTreeForForm(response, disabled)
            tree.unshift(root) // Добавляем узел 'Нет' для корневого класса
            commit('setWordsTreeFormItems', tree);
            commit('setIsWordsTreeFormBusy', false);
            resolve(tree);
          },
          (error: any) => {
            commit('setWordsTreeFormItems', []);
            commit('setIsWordsTreeFormBusy', false);
            reject(error);
          }
        );
    });
  },

  // Список элементов
  getClassItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsWordsBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      wordsApi.getWordsItems(filter, sort, pagination)
        .then(
          (response: any) => {
            commit('setWordsItems', response);
            commit('setIsWordsBusy', false);
            resolve(response);
          },
          (error: any) => {
            commit('setWordsItems', []);
            commit('setIsWordsBusy', false);
            reject(error);
          }
        );
    });
  },
/*
  // Получить элемент
  getWordsItem({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsWordsBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];

    return new Promise((resolve, reject) => {

      // Получаем элемент
      wordsApi.getWordsItem(filter)
        .then(
          (response:any) => {
            commit('setWordsItem', response);
            commit('setIsWordsBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsWordsBusy', false);
            reject(error);
          }
        );
    });
  },
*/
  // Создать элемент
  createWordsItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsWordsBusy', true);

    return new Promise((resolve, reject) => {

      // Создаем элемент
      wordsApi.createWordsItem(data)
        .then(
          (response) => {
            commit('setIsWordsBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsWordsBusy', false);
            reject(error);
          }
        );
    });
  },

  // Обновить элемент
  updateWordsItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsWordsBusy', true);

    return new Promise((resolve, reject) => {

      // Обновляем элемент
      wordsApi.updateWordsItem(data)
        .then(
          (response) => {
            commit('setIsWordsBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsWordsBusy', false);
            reject(error);
          }
        );
    });
  },

  // Удалить элемент
  deleteWordsItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsWordsBusy', true);

    return new Promise((resolve, reject) => {

      // Удаляем элемент
      wordsApi.deleteWordsItem(data)
        .then(
          (response) => {
            commit('setIsWordsBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsWordsBusy', false);
            reject(error);
          }
        );
    });
  }
};

// Создаем модуль
const WordsModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default WordsModule;
