import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { TypesApi } from "@/components/modules/accounts/services/api/TypesApi";

const typesList = new TypesApi()

class State {
    isTypesList: boolean = false
    typesList: any = []
}

const getters = <GetterTree<State, any>>{
    isTypesList(state) {
        return state.isTypesList
    },

    getTypesList(state) {
        return state.typesList
    },
}

const mutations = <MutationTree<State>>{
    setIsTypesList(state, payload) {
        state.isTypesList = payload
    },

    setTypesList(state, payload) {
        state.typesList = payload
    },
}

const actions = <ActionTree<State, any>>{
    getTypesList({ commit }, filter: any) {
        commit('setIsTypesList', true)

        return new Promise((resolve, reject) => {
            // Получаем список элементов
            typesList.TypesList(filter).then(
                (message: any) => {
                    const items = message.getItemsList()

                    commit('setTypesList', items)
                    commit('setIsTypesList', false)

                    resolve(items)
                },
                (error) => {
                    commit('setTypesList', [])
                    commit('setIsTypesList', false)
                    reject(error)
                }
            )
        })
    },
}

const typesListModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters,
}

export default typesListModule
