import { AccountSrcServiceClient } from '../../proto/pb/Accounts_srcServiceClientPb'
import {
  AccountSrc,
  ListAccountSrcRequest,
  ItemAccountSrcRequest,
} from '../../proto/pb/accounts_src_pb'
import {AuthApi} from "@/components/modules/users/services/api/AuthApi";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

const defaultLimit: number = 100

export class AccountsSrcApi {
  AccountSrc: Array<AccountSrc>
  client: AccountSrcServiceClient
  metadata: any

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? ''
    this.client = new AccountSrcServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  getAccountsSrcListItems(sso_id: string) {
    const request = new ListAccountSrcRequest()

    // Устанавливаем параметры запроса
    request.setSsoId(sso_id)

    return this.client.listAccountSrc(request, { Authorization: localStorage.getItem('access-key') } as any)
  }

  getAccountsSrcItem(filter: any) {
    const request = new ItemAccountSrcRequest()

    // Устанавливаем параметры запроса
    request.setProjectId(filter.project_id).setSsoId(filter.sso_id)

    return this.client.itemAccountSrc(request, { Authorization: localStorage.getItem('access-key') } as any)
  }
}
