
// import store from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UserProfile',

  data() {
    return {}
  },
})
