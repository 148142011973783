
import store from '@/store'
import {defineComponent, ref} from 'vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'MessagePanel',

  components: {
    DataLoading,
  },

  data() {
    return {
      splitterSizeHeader: 50,
      splitterSize: 50,
      notClassifierOnly: { label: 'к классификатору', value: true },
      notClassifierOnlyOptions: [
        { label: 'к классификатору', value: true },
        { label: 'к классу', value: false },
      ],
      columnsInClass: [
        {
          name: 'text',
          field: 'text',
          label: 'Сообщение',
          align: 'left',
        },
        {
          name: 'sum',
          field: 'sum',
          label: 'Количество голосов',
          align: 'center',
        },
      ],
      columnsOutClass: [
        {
          name: 'text',
          field: 'text',
          label: 'Сообщение',
          align: 'left',
        },
        {
          name: 'sum',
          field: 'sum',
          label: 'Количество голосов',
          align: 'center',
        },
      ],
      inFilter: [
        {
          field_name: 'ag.message_grp_id',
          operand: '>',
          value: 0 as any,
          or: [] as any,
        },
      ],
      outFilter: [
        {
          field_name: 'ag.message_grp_id',
          operand: '>',
          value: 0 as any,
          or: [] as any,
        },
      ],
      inPagination: {
        sortBy: 'message_grp_sum',
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 0,
      },
      outPagination: {
        sortBy: 'message_grp_sum',
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 0,
      },
      rowsPerPageMessageOptions: [10, 25, 50, 100],
      findType: null as any,
      findTypeOptions: [
        { label: 'Поиск по тэгам', value: 'tags' },
        { label: 'Поиск по семантике', value: 'similar', disable: true },
        { label: 'Простой поиск', value: 'single' },
      ],
      markedFind: [] as string[],
      selectedInClass: [],
      selectedOutClass: [] as any,
      search: '',
      searchEnter: '',
      similarity: '0.6' as any,
      selectedTags: [] as any,
    }
  },

  computed: {
    isPollGroupPresent() {
      const grpItems = store.getters.getPollGroupItems
      return grpItems && grpItems.length
    },

    isPollPresent() {
      const pItems = store.getters.getPollItems
      return pItems && pItems.length
    },

    isQuestionPresent() {
      const qItems = store.getters.getQuestionItems
      return qItems && qItems.length
    },

    getQuestionId() {
      let currentQuestion = store.getters.getQuestionItem
      return currentQuestion ? currentQuestion.id : 0
    },

    isClassTagBusy() {
      return store.getters.isClassTagBusy
    },

    isSelectedNode() {
      return null !== store.getters.getCurrentNode
    },

    node() {
      let currentNode = store.getters.getCurrentNode
      return currentNode
          ? currentNode
          : {
            id: 0,
            cls_id: 0,
            parent_id: 0,
            idx: 0,
            name: '',
            descr: '',
            notes: '',
            hasChilds: false,
            parentName: '',
          }
    },

    classTagItems() {
      const tagItems = store.getters.getClassTagItems

      return tagItems
          ? tagItems.map((item: any) => {
            return {
              tagName: item.getTagName(),
              tagDescr: item.getTagDescr(),
            }
          })
          : []
    },

    classTagNameItems() {
      const tagItems = store.getters.getClassTagItems
      let filteredList = [] as any

      if (tagItems) {
        tagItems.map((item: any) => {
          filteredList.push(
            [[item.getTagName()], true]
          )
        })
      }

      return filteredList
    },

    isMessageClassBusy() {
      return store.getters.isMessageClassBusy
    },

    isMessageGrpClsInClassBusy() {
      return store.getters.isMessageGrpClsInClassBusy
    },

    getMessageGrpClsInClassItems() {
      const messageItems = store.getters.getMessageGrpClsInClassItems
      let rows: any = []
      if (messageItems !== undefined) {
        for (let messageItem of messageItems) {
          rows.push({
            id: messageItem.getMessageGrpId(),
            text: messageItem.getMessageGrpText(),
            count: messageItem.getMessageGrpCount(),
            sum: messageItem.getMessageGrpSum(),
            notes: messageItem.getNotes(),
          })
        }
      }
      return rows
    },

    isMessageGrpClsOutClassBusy() {
      return store.getters.isMessageGrpClsOutClassBusy
    },

    getMessageGrpClsOutClassItems() {
      const messageItems = store.getters.getMessageGrpClsOutClassItems
      let rows: any = []
      if (messageItems !== undefined) {
        for (let messageItem of messageItems) {
          rows.push({
            id: messageItem.getMessageGrpId(),
            text: messageItem.getMessageGrpText(),
            count: messageItem.getMessageGrpCount(),
            sum: messageItem.getMessageGrpSum(),
            has_classes: messageItem.getHasClasses(),
          })
        }
      }
      return rows
    },

    userRole() {
      return store.getters.getUserAccess
    }
  },

  watch: {
    classTagNameItems: {
      handler(newValue) {
        this.selectedTags = Object.fromEntries(newValue)
      },

      immediate: true
    }
  },

  methods: {
    onRowShowMessageSource(row: any, isFull: boolean) {
      this.$emit('showSource', row, isFull)
    },

    onRequestIn(props: any) {
      console.log('onRequestIn props', props)
      this.inPagination = props.pagination
      this.loadInMessages(false)
    },

    onRequestOut(props: any) {
      console.log('onRequestOut props', props)
      this.outPagination = props.pagination
      this.loadOutMessages(false)
    },

    onUpdateSelectedIn(event: any) {
      console.log('onUpdateSelectedIn event', event)
      const selectedInClass = JSON.parse(JSON.stringify(this.selectedInClass))
      this.findTypeOptions[1].disable = !(
          selectedInClass && selectedInClass.length > 0
      )
    },

    changeFindType(event: any) {
      console.log('changeFindType event', event)
      this.findMessages()
    },

    changeNotClassifierOnly(event: any) {
      console.log('changeNotClassifierOnlyevent event', event)
      this.findMessages()
    },

    findMessages() {
      if (!this.isSelectedNode) {
        return false
      }

      let extFilter: any[] = []
      this.markedFind = []

      switch (this.findType.value) {
        case 'single': {
          if (this.search.length) {
            console.log('tut')
            extFilter.push({
              field_name: 'ag.message_grp_text',
              operand: 'ilike',
              value: this.search,
            })
            this.markedFind.push(this.search)
          }
          break
        }
        case 'tags': {
          const selectedTagItems = Object.keys(this.selectedTags).filter(el => this.selectedTags[el] === true)

          let filterTag = selectedTagItems.map((item: any) => {
            return {
              field_name: 'ag.message_grp_text',
              operand: 'ilike',
              value: item,
            }
          })
          extFilter = filterTag ? filterTag : []
          this.markedFind = selectedTagItems
          break
        }
        case 'similar': {
          console.log('findMessages - similar')
          const selectedInClass = JSON.parse(
              JSON.stringify(this.selectedInClass)
          )
          if (!selectedInClass.length) {
            console.log(
                'findMessages - не выбрано ни одного ответа для сравнения!'
            )
            return false
          }

          let similarity = parseFloat(this.similarity),
              filterSimilar = selectedInClass.map((item: any) => {
                return {
                  field_name: 'ag.message_grp_text',
                  operand: 'similar_' + similarity.toFixed(2),
                  value: item.text,
                }
              })
          extFilter = filterSimilar ? filterSimilar : []
          break
        }
        default: {
          console.log(
              'findMessages - неизвестный тип поиска this.findType',
              this.findType.value
          )
          this.markedFind = []
          return false
        }
      }

      // Подгрузка непривязанных ответов учитывая поиск
      console.log('out filter', extFilter)
      this.outFilter[0].or = extFilter
      this.loadOutMessages(true) // true - переключаем на первую страницу

      return true
    },

    findJoinMessages() {
      let extFilter: any[] = []
      this.markedFind = []

      if (this.searchEnter.length) {
        extFilter.push({
          field_name: 'ag.message_grp_text',
          operand: 'ilike',
          value: this.searchEnter,
        })

        this.markedFind.push(this.searchEnter)
      }

      this.inFilter[0].or = extFilter
      this.loadInMessages(true) // true - переключаем на первую страницу

      return true
    },

    strMarkRedPlus(search: string, subject: string) {
      let escapeRegExp = function (str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
      }

      return subject.replace(
          new RegExp('(' + escapeRegExp(search) + ')', 'gi'),
          "<span style='color: red;'><b>$1</b></span>"
      )
    },

    markedAllTags(text: string) {
      const classTagItems = JSON.parse(JSON.stringify(this.classTagItems))
      const tags = classTagItems.map((item: any) => item.tagName)
      tags.sort((a: string, b: string) => b.length - a.length)

      tags.forEach((item: string) => (text = this.strMarkRedPlus(item, text)))

      return text
    },

    markedAll(text: string) {
      const marked = JSON.parse(JSON.stringify(this.markedFind))

      if (marked && marked.length) {
        marked.sort((a: string, b: string) => b.length - a.length)
        marked.forEach(
            (item: string) => (text = this.strMarkRedPlus(item, text))
        )
      }

      return text
    },

    getSelectedAdded() {
      const selectedOutClass = JSON.parse(JSON.stringify(this.selectedOutClass))
      return selectedOutClass.map((item: any) => item.id)
    },

    addMessages() {
      console.log('addMessages')
      let addedItems = this.getSelectedAdded()
      if (addedItems.length > 0) {
        console.log('addMessages addedItems', addedItems)
        // Добавление ответов в текущий класс
        store
            .dispatch('saveMessageClass', {
              class_id: this.node.id,
              items: addedItems.map((grp_id: any) => {
                return {
                  class_id: this.node.id,
                  message_grp_id: grp_id,
                  notes: 'Добавлено вручную',
                }
              }),
            })
            .then(
                (resolve) => {
                  console.log('resolve', resolve)
                  this.reloadLists()
                },
                (reject) => {
                  console.log('reject', reject)
                }
            )
      }
    },

    getSelectedRemoved() {
      const selectedInClass = JSON.parse(JSON.stringify(this.selectedInClass))
      return selectedInClass.map((item: any) => item.id)
    },

    removeMessages() {
      console.log('removeMessages')
      let removedItems = this.getSelectedRemoved()
      if (removedItems.length) {
        console.log('removeMessages removedItems', removedItems)
        // Удаление ответов из текущего класса
        store
            .dispatch('removeMessageClass', {
              class_id: this.node.id,
              items: removedItems.map((grp_id: any) => {
                return {
                  class_id: this.node.id,
                  message_grp_id: grp_id,
                  notes: '',
                }
              }),
            })
            .then(
                (resolve) => {
                  console.log('resolve', resolve)
                  this.reloadLists()
                },
                (reject) => {
                  console.log('reject', reject)
                }
            )
      }
    },

    reloadLists() {
      this.selectedInClass = []
      this.selectedOutClass = []

      // Подгрузка привязанных ответов
      this.loadInMessages(true) // true - переключаем на первую страницу
      // Подгрузка непривязанных ответов
      this.loadOutMessages(true) // true - переключаем на первую страницу
    },

    // Подгрузка привязанных ответов
    loadInMessages(isResetPagination: boolean) {
      if (isResetPagination) {
        this.inPagination.page = 1
        this.inPagination.rowsNumber = 0
      }
      const inFilter = JSON.parse(JSON.stringify(this.inFilter))
      console.log('loadInMessages inFilter', inFilter)
      store
          .dispatch('getMessageGrpClsInClassItems', {
            filter: inFilter,
            sort: {
              name: this.inPagination.sortBy,
              exp: this.inPagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.inPagination.page,
              limit: this.inPagination.rowsPerPage,
              pages: 0,
              cnt: this.inPagination.rowsNumber,
            },
            class_id: this.node.id,
          })
          .then(
              (resolve) => {
                console.log('getMessageGrpClsInClassItems resolve', resolve)
                this.inPagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getMessageGrpClsInClassItems reject', reject)
              }
          )
    },

    // Подгрузка непривязанных ответов
    loadOutMessages(isResetPagination: boolean) {
      if (isResetPagination) {
        this.outPagination.page = 1
        this.outPagination.rowsNumber = 0
      }

      const selectedTags = Object.keys(this.selectedTags).filter(el => this.selectedTags[el] === true)

      if (this.findType.value === 'tags') {
        this.outFilter[0].or = selectedTags.map(el => {
          return {
            field_name: 'ag.message_grp_text',
            operand: 'ilike',
            value: el,
          }
        })
        this.markedFind = selectedTags
      }

      const outFilter = JSON.parse(JSON.stringify(this.outFilter))
      console.log('loadOutMessages outFilter', outFilter)
      store
          .dispatch('getMessageGrpClsOutClassItems', {
            filter: this.outFilter,
            sort: {
              name: this.outPagination.sortBy,
              exp: this.outPagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.outPagination.page,
              limit: this.outPagination.rowsPerPage,
              pages: 0,
              cnt: this.outPagination.rowsNumber,
            },
            class_id: this.node.id,
            not_classifier_only: this.notClassifierOnly.value,
          })
          .then(
              (resolve) => {
                console.log('getMessageGrpClsOutClassItems resolve', resolve)
                this.outPagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getMessageGrpClsOutClassItems reject', reject)
              }
          )
    },

    editClass() {
      this.$emit('editClass')
    },

    hasSelected(props: any) {
      const filter = this.selectedOutClass
      const row = ref(props)

      if (filter.indexOf(row.value) == -1) {
        filter.push(row.value)
      } else {
        filter.splice(filter.indexOf(row.value), 1)
      }

      this.selectedOutClass = filter
    },

    loadData(isLoadMessages: boolean) {
      this.selectedInClass = []
      this.selectedOutClass = []
      this.markedFind = []
      this.search = ''
      this.searchEnter = ''
      this.similarity = '0.6'
      this.findType = this.findTypeOptions[0]
      this.findTypeOptions[1].disable = true
      /*
                let fTimeout: ReturnType<typeof setTimeout>
                fTimeout = setTimeout(() => {
                    clearTimeout(fTimeout)
                }, 500)
                */
      if (isLoadMessages) {
        // Подгрузка привязанных ответов
        this.loadInMessages(true)
        // Подгрузка непривязанных ответов
        this.outFilter[0].or = []
        this.loadOutMessages(true)
      }
    },
  },

  created() {
    this.loadData(false)
  },
})
