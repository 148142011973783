import { ChangePasswordServiceClient } from '../../proto/pb/Change_passwordServiceClientPb'
import {
    ChangePasswordRequest,
    ChangePasswordConfirmRequest,
} from '../../proto/pb/change_password_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class ChangePasswordApi {
    client: ChangePasswordServiceClient
    metadata: any

    constructor() {
        const host:string = (window as any).VUE_APP_API_URL ?? '';
        this.client = new ChangePasswordServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    changePassword(data: ChangePasswordRequest.AsObject) {
        const request = new ChangePasswordRequest()

        request.setEmail(data.email)

        return this.client.changePassword(request, {})
    }

    changePasswordConfirm(data: ChangePasswordConfirmRequest.AsObject) {
        const request = new ChangePasswordConfirmRequest()

        request
            .setPassword(data.password)
            .setPasswordConfirmation(data.passwordConfirmation)
            .setToken(data.token)

        return this.client.changePasswordConfirm(request, {})
    }
}