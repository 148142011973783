
import store from '@/store'
import MainMixin from '@/mixins/main'
import * as XLSX from 'xlsx'
import {defineComponent} from 'vue'
import WorkingAnswerPanel from './WorkingAnswerPanel.vue'
import TableChilds from './TableChilds.vue'
import TableAnswerSource from './TableAnswerSource.vue'
import WordFormSettings from './WordFormSettings.vue';
import date from 'quasar/src/utils/date/date.js';

export default defineComponent({
  name: 'WorkingAnswers',

  mixins: [MainMixin],

  components: {
    WorkingAnswerPanel,
    TableChilds,
    TableAnswerSource,
    WordFormSettings,
    //TableRecursive,
    //DataLoading,
  },

  data() {
    return {
      isActiveModal: false,
      isActiveModalWordForm: false,
      isActiveModalSource: false,
      currentCountAnswer: null as any,
      isFullCount: false,
      pollGroup: {
        id: 0,
        title: '',
        count: 0,
      },
      pollGroupOptions: [] as any[],
      pollGroupVOptions: [] as any[],
      poll: {
        id: 0,
        group_id: 0,
        title: '',
        origin_title: '',
        date_begin: '',
        date_end: '',
        notes: '',
        target: '',
        points: 0,
        total_answers: 0,
        open_answers: 0,
        loaded_answers: 0,
        loading_flag: false,
        date_update: '',
        user_count: 0,
      },
      pollOptions: [] as any[],
      pollVOptions: [] as any[],
      question: {
        id: 0,
        poll_id: 0,
        type_id: 2,
        idx: 0,
        title: '',
        stitle: '',
        count: 0,
      },
      questionOptions: [] as any[],
      questionVOptions: [] as any[],
      splitterSize: 40,
      columnsResult: [
        {
          name: 'text',
          field: 'text',
          label: 'Ответ',
          align: 'left',
        },
        {
          name: 'sum',
          field: 'sum',
          label: 'Количество голосов',
          align: 'center',
        },
      ] as any,
      columnsChild: [
        {
          name: 'text',
          field: 'text',
          label: 'Ответ',
          align: 'left',
        },
        {
          name: 'count',
          field: 'count',
          label: 'Количество голосов',
          align: 'center',
        },
      ] as any,
      formFilter: [],
      resultFilter: [
        {
          field_name: 'ag.answer_grp_id',
          operand: '>',
          value: 0 as any,
          or: [] as any,
        },
      ],
      filterProcessed: {
        field_name: 'ag.answer_grp_processed',
        operand: '=',
        value: false as any,
        or: [] as any,
      },
      resultPagination: {
        sortBy: 'answer_grp_sum',
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 0,
      },
      rowsPerPageResultOptions: [10, 25, 50, 100],
      search: '',
      processedSign: null as any,
      processedOptions: [
        { id: 0, title: 'Все ответы' },
        { id: 1, title: 'Только обработанные' },
        { id: 2, title: 'Только необработанные' },
      ],
      selectedResult: [] as any[],
      expandedResult: [] as any[],
      markedFind: [] as string[],
      refChildTables: [] as any[],
      enjoyCount: '',
      groupInput: '',
      isDropdownOpen: false,
      pollGroupNodes: [] as any,
      pollGroupTickedNodes: [] as any,
      getTableRefVar: [] as any
    }
  },

  computed: {
    date() {
      return date
    },
    isPollGroupPresent() {
      const grpItems = store.getters.getPollGroupItems
      return grpItems && grpItems.length
    },

    isPollPresent() {
      const pItems = store.getters.getPollItems
      return pItems && pItems.length
    },

    isQuestionPresent() {
      const qItems = store.getters.getQuestionItems
      return qItems && qItems.length
    },

    isPollGroupBusy() {
      return store.getters.getIsPollGroupBusy
    },

    isPollBusy() {
      return store.getters.getIsPollBusy
    },

    isQuestionBusy() {
      return store.getters.isQuestionBusy
    },

    isAnswerGrpBusy() {
      return store.getters.isAnswerGrpBusy
    },

    isAnswerGrpResultBusy() {
      return store.getters.isAnswerGrpResultBusy
    },

    isAnswerGrpWorkedBusy() {
      return store.getters.isAnswerGrpWorkedBusy
    },

    getAnswerGrpResultItems() {
      const answerItems = store.getters.getAnswerGrpResultItems
      let rows: any = []
      if (answerItems !== undefined) {
        for (let answerItem of answerItems) {
          rows.push({
            id: answerItem.getAnswerGrpId(),
            text: answerItem.getAnswerGrpText(),
            count: answerItem.getAnswerGrpCount(),
            processed: answerItem.getAnswerGrpProcessed(),
            sum: answerItem.getAnswerGrpSum(),
            has_childs: answerItem.getHasChilds(),
          })
        }
      }
      return rows
    },

    userRole() {
      return store.getters.getUserAccess
    }
  },

  methods: {
    makeExcelForm() {
      console.log('makeExcelForm')
      store
          .dispatch('getAnswerGrpFormItems', {
            filter: this.formFilter,
            sort: {
              name: 'answer_grp_sum',
              exp: 'DESC',
            },
            question_id: this.question.id,
          })
          .then(
              (resolve) => {
                console.log('getAnswerGrpFormItems resolve', resolve)

                if (resolve && resolve.length) {
                  // Маппинг данных
                  const data = resolve.map((item: any) => {
                    return {
                      'Группа(категория) опросов': item.getPollGroupTitle(),
                      'Опрос': item.getPollTitle(),
                      'Вопрос': item.getQuestionStitle(),
                      'Количество голосов': item.getAnswerGrpSum(),
                      'Ответы': item.getAnswerGrpText(),
                    }
                  })

                  const ws = XLSX.utils.json_to_sheet(data)

                  // Подгоняем ширину столбцов под контент
                  const colLengths = Object.keys(data[0]).map((k) => k.toString().length)
                  for (const d of data) {
                    Object.values(d).forEach((element: any, index) => {
                      const length = element.toString().length
                      if (colLengths[index] < length) {
                        colLengths[index] = length
                      }
                    })
                  }
                  ws["!cols"] = colLengths.map((l) => {
                    return {
                      wch: l,
                    }
                  })

                  const wb = XLSX.utils.book_new()
                  XLSX.utils.book_append_sheet(wb, ws, 'Обобщенные ответы')
                  XLSX.writeFile(wb, 'answers_' + this.getFormattedDateTimeUnderline(null) + '.xlsx')
                } else {
                  console.log('Нет данных для вывода в файл!')
                }
              },
              (reject) => {
                console.log('getAnswerGrpFormItems reject', reject)
              }
          )
    },

    makeWordForm() {
      this.hideModal()
      console.log('makeWordForm')
      this.isActiveModalWordForm = true
      this.isActiveModal = true
    },

    onSelectionResult(details: any) {
      console.log('onSelectionResult details', details)
    },

    openChildTable(prop: any) {
      console.log('openChildTable prop', prop)

      setTimeout(() => {
        let refStr = 'childTableRow_' + prop.row.id,
            childTableRow = this.$refs[refStr] as any
        if (childTableRow) {
          childTableRow.$el.classList.remove('selected')
        }
      }, 300)

      if (this.expandedResult[0] === prop.row.id) {
        this.expandedResult = []
        this.getTableRefVar = []
      } else {
        this.getTableRefVar = []
        this.expandedResult = [prop.row.id]
      }
    },

    returnRef() {
      return this.$refs.workingAnswerPanel
    },

    popupHidePollGroup(evt: any) {
      console.log('popupHidePollGroup evt', evt)
      const pollGroupSelect: any = this.$refs.pollGroupSelect
      const modelValue: any = JSON.parse(
          JSON.stringify(pollGroupSelect.modelValue)
      )
      this.pollGroupVOptions = this.pollGroupOptions
      if (this.pollGroup.id == modelValue.id) {
        this.changeListPollGroup(modelValue)
      }
    },

    filterPollGroup(inputValue: string, doneFn: Function) {
      doneFn(
          () => {
            if (inputValue === '') {
              this.pollGroupVOptions = this.pollGroupOptions
            } else {
              const needle = inputValue.toLowerCase()
              const options = JSON.parse(
                  JSON.stringify(
                      this.pollGroupOptions.filter(
                          (v: any) => v.title.toLowerCase().indexOf(needle) > -1
                      )
                  )
              )
              if (options && options.length) {
                this.pollGroupVOptions = options.map((opt: any) => {
                  return {
                    id: opt.id,
                    title: this.strMarkRedPlus(inputValue, opt.title),
                    count: opt.count,
                  }
                })
              } else {
                this.pollGroupVOptions = []
              }
            }
          },
          (ref: any) => {
            if (
                inputValue !== '' &&
                ref.options.length > 0 &&
                ref.getOptionIndex() === -1
            ) {
              ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
              ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
            }
          }
      )
    },

    abortFilterPollGroup() {
      console.log(
          'abortFilterPollGroup - что-то пошло не так при поиске в pollGroup'
      )
    },

    popupHidePoll(evt: any) {
      console.log('popupHidePoll evt', evt)
      const pollSelect: any = this.$refs.pollSelect
      const modelValue: any = JSON.parse(JSON.stringify(pollSelect.modelValue))
      this.pollVOptions = this.pollOptions
      if (this.poll.id == modelValue.id) {
        this.changeListPoll(modelValue)
      }
    },

    filterPoll(inputValue: string, doneFn: Function) {
      doneFn(
          () => {
            if (inputValue === '') {
              this.pollVOptions = this.pollOptions
            } else {
              const needle = inputValue.toLowerCase()
              const options = JSON.parse(
                  JSON.stringify(
                      this.pollOptions.filter(
                          (v: any) => v.title.toLowerCase().indexOf(needle) > -1
                      )
                  )
              )
              if (options && options.length) {
                this.pollVOptions = options.map((opt: any) => {
                  return {
                    id: opt.id,
                    group_id: opt.group_id,
                    title: this.strMarkRedPlus(inputValue, opt.title),
                    origin_title: opt.origin_title,
                    date_begin: opt.date_begin,
                    date_end: opt.date_end,
                    notes: opt.notes,
                    target: opt.target,
                    points: opt.points,
                  }
                })
              } else {
                this.pollVOptions = []
              }
            }
          },
          (ref: any) => {
            if (
                inputValue !== '' &&
                ref.options.length > 0 &&
                ref.getOptionIndex() === -1
            ) {
              ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
              ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
            }
          }
      )
    },

    abortFilterPoll() {
      console.log('abortFilterPoll - что-то пошло не так при поиске в poll')
    },

    popupHideQuestion(evt: any) {
      console.log('popupHideQuestion evt', evt)
      const questionSelect: any = this.$refs.questionSelect
      const modelValue: any = JSON.parse(
          JSON.stringify(questionSelect.modelValue)
      )
      this.questionVOptions = this.questionOptions
      if (this.question.id == modelValue.id) {
        this.changeListQuestion(modelValue)
      }
    },

    filterQuestion(inputValue: string, doneFn: Function) {
      doneFn(
          () => {
            if (inputValue === '') {
              this.questionVOptions = this.questionOptions
            } else {
              const needle = inputValue.toLowerCase()
              const options = JSON.parse(
                  JSON.stringify(
                      this.questionOptions.filter(
                          (v: any) => v.stitle.toLowerCase().indexOf(needle) > -1
                      )
                  )
              )
              if (options && options.length) {
                this.questionVOptions = options.map((opt: any) => {
                  return {
                    id: opt.id,
                    poll_id: opt.poll_id,
                    type_id: opt.type_id,
                    idx: opt.idx,
                    title: opt.title,
                    stitle: this.strMarkRedPlus(inputValue, opt.stitle),
                    count: opt.count,
                  }
                })
              } else {
                this.questionVOptions = []
              }
            }
          },
          (ref: any) => {
            if (
                inputValue !== '' &&
                ref.options.length > 0 &&
                ref.getOptionIndex() === -1
            ) {
              ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
              ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
            }
          }
      )
    },

    abortFilterQuestion() {
      console.log(
          'abortFilterQuestion - что-то пошло не так при поиске в question'
      )
    },

    changeProcessedSign(event: any) {
      console.log('changeProcessedSign event', event)
      const processed = JSON.parse(JSON.stringify(event))
      console.log('processed', processed)
      switch (processed.id) {
        case 0:
          if (this.resultFilter.length > 1) {
            this.resultFilter.pop()
          }

          this.findAnswersResult()
          break
        case 1:
          if (this.resultFilter.length < 2) {
            this.resultFilter.push(this.filterProcessed)
          }
          this.resultFilter[1].value = true

          this.findAnswersResult()
          break
        case 2:
          if (this.resultFilter.length < 2) {
            this.resultFilter.push(this.filterProcessed)
          }
          this.resultFilter[1].value = false

          this.findAnswersResult()
          break
        default:
          console.log('Неизвестный признак обработки ответа: ', processed)

          this.findAnswersResult()
          break
      }
      console.log('this.resultFilter', this.resultFilter)
    },

    afterAction(setAnswer: any) {
      console.log('afterAction setAnswer', setAnswer)
      this.search = ''
      this.clearSelectedAnswerResult()
      this.expandedResult = [] // Скрываем все "раскрытые" ответы
      if (this.processedSign.id > 1) {
        this.processedSign = this.processedOptions[0]
        this.changeProcessedSign(this.processedSign)
      }

      this.resultPagination.page = 1
      this.resultPagination.rowsNumber = 0

      console.log('filter', this.resultFilter)
      store
          .dispatch('getAnswerGrpWithFindAnswerResultItems', {
            filter: this.resultFilter,
            sort: {
              name: this.resultPagination.sortBy,
              exp: this.resultPagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.resultPagination.page,
              limit: this.resultPagination.rowsPerPage,
              pages: 0,
              cnt: 0,
            },
            question_id: this.question.id,
            find_id: setAnswer,
          })
          .then(
              (resolve) => {
                console.log(
                    'getAnswerGrpWithFindAnswerResultItems resolve',
                    resolve
                )
                this.resultPagination.page = resolve.pagination.getPage()
                this.resultPagination.rowsNumber = resolve.pagination.getCnt()
                const answerItems = store.getters.getAnswerGrpResultItems
                if (answerItems !== undefined) {
                  for (let answerItem of answerItems) {
                    if (setAnswer == answerItem.getAnswerGrpId()) {
                      this.selectedResult.push({
                        id: answerItem.getAnswerGrpId(),
                        text: answerItem.getAnswerGrpText(),
                        count: answerItem.getAnswerGrpCount(),
                        processed: answerItem.getAnswerGrpProcessed(),
                        sum: answerItem.getAnswerGrpSum(),
                        has_childs: answerItem.getHasChilds(),
                      })
                      this.onRowClickAnswerResult(this.selectedResult[0], false) // false - не ресетить similarity
                      break
                    }
                  }
                }
              },
              (reject) => {
                console.log('getAnswerGrpWithFindAnswerResultItems reject', reject)
              }
          )
    },

    clearSelectedAnswerResult() {
      store.dispatch('setCurrentAnswerResult', null).then(() => {
        this.selectedResult = [] // Очищаем селект при поиске или смене фильтров
      })
    },

    onRowClickAnswerResult(row: any, isResetSimilarity: boolean) {
      console.log('onRowClickAnswerResult row', row)
      store.dispatch('setCurrentAnswerResult', row).then(() => {
        this.selectedResult = [row]
        setTimeout(() => {
          let refStr = 'childTableRow_' + row.id,
              childTableRow = this.$refs[refStr] as any
          if (childTableRow) {
            childTableRow.$el.classList.remove('selected')
          }
        }, 300)
        const workingAnswerPanel: any = this.$refs.workingAnswerPanel
        if (workingAnswerPanel) {
          workingAnswerPanel.loadData(true, isResetSimilarity) // true - загружать ответы
        }
      })
    },

    hideModal() {
      this.isActiveModal = false
      this.isActiveModalWordForm = false
      this.isActiveModalSource = false
      this.currentCountAnswer = null
      this.isFullCount = false
    },

    onRowShowAnswerSource(row: any, isFull: boolean) {
      this.hideModal()
      console.log('onRowShowAnswerSource row', row)
      console.log('isFull', isFull)
      this.currentCountAnswer = Object.assign(row)
      this.isFullCount = isFull
      this.isActiveModalSource = true
      this.isActiveModal = true
    },

    onRequestResult(props: any) {
      console.log('onRequestResult props', props)
      this.resultPagination = props.pagination
      this.loadAnswerResultItems(false)
    },

    changeListPollGroup(event: any) {
      console.log('changeListPollGroup event', event)
      if (!event) {
        const pollGroupSelect: any = this.$refs.pollGroupSelect
        if (pollGroupSelect && this.pollGroup) {
          pollGroupSelect.updateInputValue(this.pollGroup.title)
        }
      } else {
        const title: string = event.title
        if (
            title.indexOf("<span style='color: red;'>") > -1 &&
            title.indexOf('</b></span>') > -1
        ) {
          //this.pollGroupVOptions = this.pollGroupOptions
          this.pollGroup = this.pollGroupOptions.find(
              (opt: any) => event.id == opt.id
          )
        } else {
          this.pollGroup = {
            id: event.id,
            title: event.title,
            count: event.count,
          }
        }
        this.loadPollItems()
      }
    },

    changeListPoll(event: any) {
      console.log('changeListPoll event', event)
      console.log('TABLE CHILD REF ON UPD POLLS', this.$refs.tableChildRef)
      if (!event) {
        const pollSelect: any = this.$refs.pollSelect
        console.log('this.pollGroup', this.pollGroup)
        if (pollSelect && this.poll) {
          pollSelect.updateInputValue(this.poll.title)
        }
      } else {
        const title: string = event.title
        if (
            title.indexOf("<span style='color: red;'>") > -1 &&
            title.indexOf('</b></span>') > -1
        ) {
          //this.pollVOptions = this.pollOptions
          this.poll = this.pollOptions.find((opt: any) => event.id == opt.id)
        } else {
          this.poll = {
            id: event.id,
            group_id: event.group_id,
            title: event.title,
            origin_title: event.origin_title,
            date_begin: event.date_begin,
            date_end: event.date_end,
            notes: event.notes,
            target: event.target,
            points: event.points,
            total_answers: event.total_answers,
            open_answers: event.open_answers,
            loaded_answers: event.loaded_answers,
            loading_flag: event.loading_flag,
            date_update: event.date_update,
            user_count: event.user_count,
          }
        }
        this.loadQuestionItems()
      }
    },

    changeListQuestion(event: any) {
      console.log('changeListQuestion event', event)
      if (!event) {
        const questionSelect: any = this.$refs.questionSelect
        if (questionSelect && this.question) {
          questionSelect.updateInputValue(this.question.stitle)
        }
      } else {
        const stitle: string = event.stitle
        if (
            stitle.indexOf("<span style='color: red;'>") > -1 &&
            stitle.indexOf('</b></span>') > -1
        ) {
          //this.questionVOptions = this.questionOptions
          this.question = this.questionOptions.find(
              (opt: any) => event.id == opt.id
          )
        } else {
          this.question = {
            id: event.id,
            poll_id: event.poll_id,
            type_id: event.type_id,
            idx: event.idx,
            title: event.title,
            stitle: event.stitle,
            count: event.count,
          }
        }
        store.dispatch('setQuestionItem', this.question).then(() => {
          this.loadAnswerResultItems(true) // true - переключаем на первую страницу
        })
      }
    },

    findAnswersResult() {
      let extFilter: any[] = []
      this.markedFind = []
      if (this.search.length) {
        extFilter.push({
          field_name: 'ag.answer_grp_text',
          operand: 'ilike',
          value: this.search,
        })
        this.markedFind.push(this.search)
      }

      // Подгрузка ответов учитывая поиск
      this.resultFilter[0].or = extFilter
      console.log('this.resultFilter', this.resultFilter)
      this.loadAnswerResultItems(true) // true - переключаем на первую страницу
    },

    strMarkRedPlus(search: string, subject: string) {
      let escapeRegExp = function (str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
      }

      return subject.replace(
          new RegExp('(' + escapeRegExp(search) + ')', 'gi'),
          "<span style='color: red;'><b>$1</b></span>"
      )
    },

    markedAll(text: string) {
      const marked = this.markedFind

      if (marked && marked.length) {
        marked.sort((a: string, b: string) => b.length - a.length)
        marked.forEach(
            (item: string) => (text = this.strMarkRedPlus(item, text))
        )
      }

      return text
    },

    // Подгрузка ответов в результирующую таблицу
    loadAnswerResultItems(isResetPagination: boolean) {
      if (isResetPagination) {
        this.clearSelectedAnswerResult() // Очищаем селект при поиске или смене фильтров
        this.resultPagination.page = 1
        this.resultPagination.rowsNumber = 0
      }
      console.log('loadAnswerResultItems filter', this.resultFilter)
      store
          .dispatch('getAnswerGrpResultItems', {
            filter: this.resultFilter,
            sort: {
              name: this.resultPagination.sortBy,
              exp: this.resultPagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.resultPagination.page,
              limit: this.resultPagination.rowsPerPage,
              pages: 0,
              cnt: this.resultPagination.rowsNumber,
            },
            question_id: this.question.id,
          })
          .then(
              (resolve) => {
                console.log('getAnswerGrpResultItems resolve', resolve)
                this.resultPagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getAnswerGrpResultItems reject', reject)
              }
          )
    },

    clearQuestionItems() {
      this.question = {
        id: 0,
        poll_id: 0,
        type_id: 2,
        idx: 0,
        title: '',
        stitle: '',
        count: 0,
      }
      store.dispatch('setQuestionItems', [])
      this.questionOptions = []
      this.questionVOptions = []
    },

    loadQuestionItems() {
      store
          .dispatch('getQuestionItems', {
            poll_id: this.poll.id
          })
          .then(
              (resolve: any) => {
                console.log('getQuestionItems resolve', resolve)
                if (resolve && resolve.items && resolve.items.length) {
                  this.questionOptions = []
                  for (let qItem of resolve.items) {
                    this.questionOptions.push({
                      id: qItem.getQuestionId(),
                      poll_id: qItem.getPollId(),
                      type_id: qItem.getQuestionTypeId(),
                      idx: qItem.getQuestionIdx(),
                      title: qItem.getQuestionTitle(),
                      stitle: '(' + qItem.getAnswerSrcSum()  + ') ' + qItem.getQuestionStitle(),
                      count: qItem.getAnswerSrcCount,
                    })
                  }
                  this.questionVOptions = this.questionOptions
                  this.changeListQuestion(this.questionVOptions[0])
                } else {
                  this.clearQuestionItems()
                }
              },
              (reject) => {
                console.log('getQuestionItems reject', reject)
                this.clearQuestionItems()
              }
          )
    },

    clearPollItems() {
      this.clearQuestionItems()
      this.poll = {
        id: 0,
        group_id: 0,
        title: '',
        origin_title: '',
        date_begin: '',
        date_end: '',
        notes: '',
        target: '',
        points: 0,
        total_answers: 0,
        open_answers: 0,
        loaded_answers: 0,
        loading_flag: false,
        date_update: '',
        user_count: 0,
      }
      store.dispatch('setPollItems', [])
      this.pollOptions = []
      this.pollVOptions = []
    },

    loadPollItems() {
      this.groupInput = 'Выбрано групп (' + this.pollGroupTickedNodes.length + ')'

      let tempFilter = {
        field_name: 'p.poll_group_id',
        operand: '>',
        value: 0,
        or: [],
      } as any

      this.pollGroupTickedNodes.forEach((el: any) => {
        tempFilter.or.push({
          field_name: 'p.poll_group_id',
          operand: '=',
          value: el,
        })
      })

      console.log('TEMP FILTER', tempFilter)

      store
          .dispatch('getPollItems', {
            filter: [tempFilter],
            sort: {
              name: 'p.poll_id',
              exp: 'asc'
            }
          })
          .then((resolve: any) => {
            console.log('getPollItems resolve', resolve)
            if (resolve && resolve.items && resolve.items.length) {
              this.pollOptions = []
              for (let pollItem of resolve.items) {
                if (!pollItem.getPollLoadingFlag()) {
                  this.pollOptions.push({
                    id: pollItem.getPollId(),
                    group_id: pollItem.getPollGroupId(),
                    title: pollItem.getPollId() + ' | ' + pollItem.getPollTitle(),
                    origin_title: pollItem.getPollTitle(),
                    date_begin: this.getFormattedDateFromTimestamp(
                        pollItem.getPollDateBegin().getSeconds()
                    ),
                    date_end: this.getFormattedDateFromTimestamp(
                        pollItem.getPollDateEnd().getSeconds()
                    ),
                    notes: pollItem.getPollNotes(),
                    target: pollItem.getPollTarget(),
                    points: pollItem.getPollPoints(),
                    total_answers: pollItem.getPollTotalAnswers(),
                    open_answers: pollItem.getPollOpenAnswers(),
                    loaded_answers: pollItem.getPollLoadedAnswers(),
                    loading_flag: pollItem.getPollLoadingFlag(),
                    date_update: pollItem.getPollDateUpdate(),
                    user_count: pollItem.getPollUserCount(),
                  })
                }
              }
              this.pollVOptions = this.pollOptions
              this.changeListPoll(this.pollVOptions[0])
            } else {
              this.clearPollItems()
            }
          })
    },

    clearPollGroupItems() {
      this.clearPollItems()
      this.pollGroup = {
        id: 0,
        title: '',
        count: 0,
      }
      store.dispatch('setPollGroupItems', [])
      this.pollGroupOptions = []
      this.pollGroupVOptions = []
    },

    loadPollGroupItems() {
      store.dispatch('getPollGroupItems').then(
          (resolve: any) => {
            console.log('getPollGroupItems resolve', resolve)
            if (resolve && resolve.items && resolve.items.length) {
              let nodes = [
                {
                  label: 'Все группы опросов',
                  children: []
                }
              ] as any

              this.pollGroupOptions = []
              this.pollGroupVOptions = []
              for (let grpItem of resolve.items) {
                nodes[0].children.push({
                  label: '(' + grpItem.getPollCount() + ') ' + grpItem.getPollGroupTitle(),
                  value: grpItem.getPollGroupId()
                })

                this.pollGroupTickedNodes.push(grpItem.getPollGroupId())

                this.pollGroupOptions.push({
                  id: grpItem.getPollGroupId(),
                  title: '(' + grpItem.getPollCount() + ') ' + grpItem.getPollGroupTitle(),
                  count: grpItem.getPollCount(),
                })
              }
              this.pollGroupVOptions = this.pollGroupOptions
              this.changeListPollGroup(this.pollGroupVOptions[0])
              this.pollGroupNodes = nodes
            } else {
              this.clearPollGroupItems()
            }
          },
          (reject) => {
            console.log('getPollGroupItems reject', reject)
            this.clearPollGroupItems()
          }
      )
    },

    separateTableChild() {
      let childRef = this.$refs.tableChildRef as any
      childRef.separateChild()
    },

    afterSeparate() {
      this.afterAction(this.expandedResult[0])
      this.getTableRefVar = []
    },

    checkChildLength(childLength: any) {
      this.getTableRefVar = childLength
    },

    loadData() {
      this.hideModal()
      this.processedSign = this.processedOptions[0]
      this.loadPollGroupItems()
    },

    clickOutside(e: any) {
      const elem = document.querySelector('.project-select-tree') as any
      return this.isDropdownOpen = elem.contains(e.target) && this.isDropdownOpen
    },

    clickChecker() {
      this.isDropdownOpen = !this.isDropdownOpen
      document.addEventListener('click', this.clickOutside)
    },
  },

  created() {
    console.log('WorkingAnswers')
    this.loadData()
  },
})
