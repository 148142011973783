import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { AnswerGrpClsApi } from '../services/api/AnswerGrpClsApi';
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from '@/mixins/main';

class State {
  isAnswerGrpClsBusy: boolean = false;
  isAnswerGrpClsInClassBusy: boolean = false;
  isAnswerGrpClsOutClassBusy: boolean = false;
  answerGrpClsInClassItems: unknown [];
  answerGrpClsOutClassItems: unknown [];
  answerGrpClsItem: unknown;
}

// Создаем объект
const answerGrpClsApi = new AnswerGrpClsApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

  isAnswerGrpClsBusy(state): boolean {
    return state.isAnswerGrpClsBusy;
  },

  isAnswerGrpClsInClassBusy(state): boolean {
    return state.isAnswerGrpClsInClassBusy;
  },

  isAnswerGrpClsOutClassBusy(state): boolean {
    return state.isAnswerGrpClsOutClassBusy;
  },

  getAnswerGrpClsInClassItems(state): unknown[] {
    return state.answerGrpClsInClassItems;
  },

  getAnswerGrpClsOutClassItems(state): unknown[] {
    return state.answerGrpClsOutClassItems;
  },

  getAnswerGrpClsItem(state): unknown {
    return state.answerGrpClsItem;
  }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

  setIsAnswerGrpClsBusy(state, payload: boolean) {
    state.isAnswerGrpClsBusy = payload
  },

  setIsAnswerGrpClsInClassBusy(state, payload: boolean) {
    state.isAnswerGrpClsInClassBusy = payload
  },

  setIsAnswerGrpClsOutClassBusy(state, payload: boolean) {
    state.isAnswerGrpClsOutClassBusy = payload
  },

  setAnswerGrpClsInClassItems(state, payload: unknown[]) {
    state.answerGrpClsInClassItems = payload
  },

  setAnswerGrpClsOutClassItems(state, payload: unknown[]) {
    state.answerGrpClsOutClassItems = payload
  },

  setAnswerGrpClsItem(state, payload: unknown) {
    state.answerGrpClsItem = payload
  }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

  // Устанавливаем элемент
  setAnswerGrpClsItem({ commit }, data: any) {
    commit('setAnswerGrpClsItem', data);
  },
  
  // Список элементов (привязанные ответы)
  getAnswerGrpClsInClassItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerGrpClsBusy', true);
    commit('setIsAnswerGrpClsInClassBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
    const question_id:any = data !== undefined && data.question_id !== undefined ? data.question_id : 0;
    const class_id:number = data !== undefined && data.class_id !== undefined ? Number(data.class_id) : 0;

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      answerGrpClsApi.getAnswerGrpClsInItems(filter, sort, pagination, question_id, class_id)
        .then(
          (message:any) => {
            const items = message.getItemsList();
            const sort = message.getParams().getSort();
            const pagination = message.getParams().getPagination();
            commit('setAnswerGrpClsInClassItems', items);
            commit('setIsAnswerGrpClsBusy', false);
            commit('setIsAnswerGrpClsInClassBusy', false);
            resolve({
                items: items,
                sort: sort,
                pagination: pagination
            });
          },
          (error) => {
            commit('setAnswerGrpClsInClassItems', []);
            commit('setIsAnswerGrpClsBusy', false);
            commit('setIsAnswerGrpClsInClassBusy', false);
            reject(error);
          }
        );
    });
  },

  // Список элементов (непривязанные ответы)
  getAnswerGrpClsOutClassItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerGrpClsBusy', true);
    commit('setIsAnswerGrpClsOutClassBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
    const question_id:any = data !== undefined && data.question_id !== undefined ? data.question_id : 0;
    const class_id:number = data !== undefined && data.class_id !== undefined ? Number(data.class_id) : 0;
    const not_classifier_only:boolean = data !== undefined && data.not_classifier_only !== undefined ? data.not_classifier_only : true;

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      answerGrpClsApi.getAnswerGrpClsOutItems(filter, sort, pagination, question_id, class_id, not_classifier_only)
        .then(
          (message:any) => {
            const items = message.getItemsList();
            const sort = message.getParams().getSort();
            const pagination = message.getParams().getPagination();
            commit('setAnswerGrpClsOutClassItems', items);
            commit('setIsAnswerGrpClsBusy', false);
            commit('setIsAnswerGrpClsOutClassBusy', false);
            resolve({
                items: items,
                sort: sort,
                pagination: pagination
            });
          },
          (error) => {
            commit('setAnswerGrpClsOutClassItems', []);
            commit('setIsAnswerGrpClsBusy', false);
            commit('setIsAnswerGrpClsOutClassBusy', false);
            reject(error);
          }
        );
    });
  }
};

// Создаем модуль
const AnswerGrpClsModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default AnswerGrpClsModule;
