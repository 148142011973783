
import store from '@/store'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'ProjectAdd',

  components: {
    DataLoading,
  },

  data() {
    return {
      item: {
        name: '',
        short_name: '',
      },
    }
  },

  computed: {
    isProjectsBusy() {
      return store.getters.getIsProjectsBusy
    },
  },

  methods: {
    saveProject() {
      if (!this.item.short_name) {
        console.log('Краткое наименование проекта не введено')
        return false
      }

      let data = {
        name: this.item.name,
        short_name: this.item.short_name,
      }

      store.dispatch('createProjectsItem', data).then(
        (resolve) => {
          console.log('resolve', resolve)
          this.$emit('afterAction')
        },
        (reject) => {
          console.log('reject', reject)
        }
      )
      return true
    },

    closeProjectAdd() {
      this.$emit('hideModal')
    },

    loadData() {
      this.item = {
        name: '',
        short_name: '',
      }
    },
  },

  created() {
    this.loadData()
  },
})
