
import store from '@/store'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'MessageFormSingle',

  components: {
    DataLoading,
  },

  props: {
    message: Object as any,
  },

  data() {
    return {
      item: {
        id: 0,
        text: '',
        delimiter: '',
      },
    }
  },

  computed: {
    isMessageGrpBusy() {
      return store.getters.isMessageGrpBusy
    },

    isMessageGrpWorkedBusy() {
      return store.getters.isMessageGrpWorkedBusy
    },
  },

  methods: {
    splitMessage() {
      if (!this.item.text) {
        console.log('Текст ответа не введен')
        return false
      }

      if (!this.item.delimiter) {
        console.log('Строка(символ) для разбивки не введен')
        return false
      }

      if (this.item.text.indexOf(this.item.delimiter) === -1) {
        console.log('Строка(символ) для разбивки отсутствует в тексте ответа')
        return false
      }

      if (this.item.text != this.message.text) {
        let data = {
          id: this.item.id,
          text: this.item.text,
        }

        store.dispatch('updateTextMessageGrpItem', data).then(
            (resolve) => {
              console.log('updateTextMessageGrpItem resolve', resolve)
              this.actualSplitMessage()
            },
            (reject) => {
              console.log('updateTextMessageGrpItem reject', reject)
            }
        )
      } else {
        this.actualSplitMessage()
      }
      return true
    },

    actualSplitMessage() {
      let splitData = {
        split_string: this.item.delimiter,
        splitted_ids: [this.item.id],
      }
      store.dispatch('splitMessageGrpItems', splitData).then(
          (resolve) => {
            console.log('splitMessageGrpItems resolve', resolve)
            this.$emit('afterActionSplitSingle')
          },
          (reject) => {
            console.log('splitMessageGrpItems reject', reject)
          }
      )
    },

    closeSplitFormSingle() {
      this.$emit('hideModal')
    },

    loadData() {
      this.item = {
        id: this.message.id,
        text: this.message.text,
        delimiter: '#'
      }
    },
  },

  created() {
    this.loadData()
  },
})
