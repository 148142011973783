/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: words.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as words_pb from './words_pb';


export class WordsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateWords = new grpcWeb.MethodDescriptor(
    '/proto.WordsService/CreateWords',
    grpcWeb.MethodType.UNARY,
    words_pb.CreateWordsRequest,
    words_pb.CreateWordsResponse,
    (request: words_pb.CreateWordsRequest) => {
      return request.serializeBinary();
    },
    words_pb.CreateWordsResponse.deserializeBinary
  );

  createWords(
    request: words_pb.CreateWordsRequest,
    metadata: grpcWeb.Metadata | null): Promise<words_pb.CreateWordsResponse>;

  createWords(
    request: words_pb.CreateWordsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: words_pb.CreateWordsResponse) => void): grpcWeb.ClientReadableStream<words_pb.CreateWordsResponse>;

  createWords(
    request: words_pb.CreateWordsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: words_pb.CreateWordsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.WordsService/CreateWords',
        request,
        metadata || {},
        this.methodDescriptorCreateWords,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.WordsService/CreateWords',
    request,
    metadata || {},
    this.methodDescriptorCreateWords);
  }

  methodDescriptorUpdateWords = new grpcWeb.MethodDescriptor(
    '/proto.WordsService/UpdateWords',
    grpcWeb.MethodType.UNARY,
    words_pb.UpdateWordsRequest,
    words_pb.UpdateWordsResponse,
    (request: words_pb.UpdateWordsRequest) => {
      return request.serializeBinary();
    },
    words_pb.UpdateWordsResponse.deserializeBinary
  );

  updateWords(
    request: words_pb.UpdateWordsRequest,
    metadata: grpcWeb.Metadata | null): Promise<words_pb.UpdateWordsResponse>;

  updateWords(
    request: words_pb.UpdateWordsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: words_pb.UpdateWordsResponse) => void): grpcWeb.ClientReadableStream<words_pb.UpdateWordsResponse>;

  updateWords(
    request: words_pb.UpdateWordsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: words_pb.UpdateWordsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.WordsService/UpdateWords',
        request,
        metadata || {},
        this.methodDescriptorUpdateWords,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.WordsService/UpdateWords',
    request,
    metadata || {},
    this.methodDescriptorUpdateWords);
  }

  methodDescriptorDeleteWords = new grpcWeb.MethodDescriptor(
    '/proto.WordsService/DeleteWords',
    grpcWeb.MethodType.UNARY,
    words_pb.DeleteWordsRequest,
    words_pb.DeleteWordsResponse,
    (request: words_pb.DeleteWordsRequest) => {
      return request.serializeBinary();
    },
    words_pb.DeleteWordsResponse.deserializeBinary
  );

  deleteWords(
    request: words_pb.DeleteWordsRequest,
    metadata: grpcWeb.Metadata | null): Promise<words_pb.DeleteWordsResponse>;

  deleteWords(
    request: words_pb.DeleteWordsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: words_pb.DeleteWordsResponse) => void): grpcWeb.ClientReadableStream<words_pb.DeleteWordsResponse>;

  deleteWords(
    request: words_pb.DeleteWordsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: words_pb.DeleteWordsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.WordsService/DeleteWords',
        request,
        metadata || {},
        this.methodDescriptorDeleteWords,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.WordsService/DeleteWords',
    request,
    metadata || {},
    this.methodDescriptorDeleteWords);
  }

  methodDescriptorListWords = new grpcWeb.MethodDescriptor(
    '/proto.WordsService/ListWords',
    grpcWeb.MethodType.UNARY,
    words_pb.ListWordsRequest,
    words_pb.ListWordsResponse,
    (request: words_pb.ListWordsRequest) => {
      return request.serializeBinary();
    },
    words_pb.ListWordsResponse.deserializeBinary
  );

  listWords(
    request: words_pb.ListWordsRequest,
    metadata: grpcWeb.Metadata | null): Promise<words_pb.ListWordsResponse>;

  listWords(
    request: words_pb.ListWordsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: words_pb.ListWordsResponse) => void): grpcWeb.ClientReadableStream<words_pb.ListWordsResponse>;

  listWords(
    request: words_pb.ListWordsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: words_pb.ListWordsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.WordsService/ListWords',
        request,
        metadata || {},
        this.methodDescriptorListWords,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.WordsService/ListWords',
    request,
    metadata || {},
    this.methodDescriptorListWords);
  }

}

