import { GetterTree, MutationTree, ActionTree } from "vuex";
import { ActiveListApi } from "@/components/modules/intersection/services/api/ActiveListApi";

const activeListApi = new ActiveListApi()

class State {
    activeList: any
    selectedActive: any
}

const getters = <GetterTree<State, any>> {
    getActiveList(state) {
        return state.activeList
    },

    getSelectedActive(state) {
        return state.selectedActive
    },
}

const mutations = <MutationTree<State>> {
    setActiveList(state, payload) {
        state.activeList = payload
    },

    setSelectedActive(state, payload) {
        state.selectedActive = payload
    },
}

const actions = <ActionTree<State, any>> {
    getActiveList({ commit }, data) {
        const filter: any =
            data !== undefined && data.filter !== undefined ? data.filter : {}

        const sort: any =
            data !== undefined && data.sort !== undefined ? data.sort : {}

        const pagination: any =
            data !== undefined && data.pagination !== undefined ? data.pagination : {}

        return new Promise((resolve, reject) => {
            activeListApi.getActiveList(filter, sort, pagination, data).then(
                (message: any) => {
                    const items = message.getItemsList()

                    commit('setActiveList', items)

                    console.log('activ items', items)
                    resolve(items)
                },

                (error: any) => {
                    commit('setActiveList', [])
                    reject(error)
                    console.log('ERROR ', error)
                }
            )
        })
    },

    setSelectedActive({ commit }, data) {
        commit('setSelectedActive', data)
    }
}

const activeListModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters,
}
export default activeListModule