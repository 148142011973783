<template>
  <q-card class="q-pa-xl">
    <!-- <div v-show="!enableTopActivityChart" class="text-h6 text-center">
      Для анализа показателей выберите проект в диаграмме
      <span class="text-bold">"Количество пользователей проекта"</span>
    </div> -->
    <highcharts
      v-show="enableTopActivityChart"
      class="hc"
      :options="chartOptions"
      ref="chart"
    ></highcharts>
  </q-card>
</template>

<script>
import store from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SegmentationBarChart',
  data() {
    return {
      enableTopActivityChart: true,
      chartOptions: {
        chart: {
          type: 'bar',
        },
        title: {
          text: 'Пересечение метрик',
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          visible: false,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [],
      },
    }
  },
  watch: {
    getIntersectionMetricAccountValueItems() {
      this.transformBackData()
    },
  },

  computed: {
    getMetricValueItems() {
      return store.getters.getMetricValueItems
    },
    getIntersectionMetricAccountValueItems() {
      return store.getters.getIntersectionMetricAccountValueItems
    },
  },

  methods: {
    transformBackData() {
      this.chartOptions.series.length = 0
      this.chartOptions.xAxis.categories.length = 0
      let arr = this.getIntersectionMetricAccountValueItems
      let obj = {}
      let chosenXMetricsAll = []
      let chosenYMetricsAll = []

      for (let item in arr) {
        if (
          !Object.prototype.hasOwnProperty.call(
            obj,
            arr[item].getFromMetricValueId()
          )
        ) {
          obj[`${arr[item].getFromMetricValueId()}`] = []
          obj[`${arr[item].getFromMetricValueId()}`].push(arr[item].getCnt())
        } else {
          obj[`${arr[item].getFromMetricValueId()}`].push(arr[item].getCnt())
        }
        chosenXMetricsAll.push(arr[item].getFromMetricValueId())
        chosenYMetricsAll.push(arr[item].getToMetricValueId())
      }

      let chosenXMetrics = chosenXMetricsAll.filter(
        (el, ind) => ind === chosenXMetricsAll.indexOf(el)
      )

      let chosenYMetrics = chosenYMetricsAll.filter(
        (el, ind) => ind === chosenYMetricsAll.indexOf(el)
      )

      const metricValueItems = this.getMetricValueItems
      let firstMetricId = metricValueItems[0].getMetricId()
      let secondMetricId = metricValueItems[4].getMetricId()

      for (let metricItem of metricValueItems) {
        if (
          metricItem.getMetricId() === firstMetricId &&
          chosenXMetrics.includes(metricItem.getMetricValueIdx())
        ) {
          this.chartOptions.series.push({
            name: metricItem.getMetricValueName(),
            metricId: metricItem.getMetricValueId(),
          })
        } else if (
          metricItem.getMetricId() === secondMetricId &&
          chosenYMetrics.includes(metricItem.getMetricValueIdx())
        ) {
          this.chartOptions.xAxis.categories.push(
            metricItem.getMetricValueName()
          )
        }
      }

      let series = this.chartOptions.series
      for (let item in obj) {
        for (let s in series) {
          if (series[s].metricId === Number(item)) {
            series[s].data = obj[item]
          }
        }
      }
    },

    getFilters() {
      return []
    },

    loadMetricValueItems() {
      store
        .dispatch('getMetricValueItems', {
          filter: this.getFilters(),
        })
        .then(
          (resolve) => {
            console.log('getMetricValueItems resolve', resolve)
          },
          (reject) => {
            console.log('getMetricValueItems reject', reject)
          }
        )
    },

    /*loadIntersectionMetricValueItems() {
      store
        .dispatch('getIntersectionMetricAccountValueItems', {
          projectIds: [1],
          periodIds: [1],
          fromMetricValueIds: [1, 2, 3, 4],
          toMetricValueIds: [5, 6, 7, 8],
        })
        .then(
          () => {
            // console.log(
            //   'getIntersectionMetricAccountValueItems resolve',
            //   resolve
            // )
            this.transformBackData()
          },
          (reject) => {
            console.log('getIntersectionMetricAccountValueItems reject', reject)
          }
        )
    },

    loadData() {
      this.loadIntersectionMetricValueItems()
    },*/
  },

  created() {
    //this.loadData()
  },
})
</script>
