import {
  ClassServiceClient
} from "../../proto/pb/ClassServiceClientPb";
import {
  Class, 
  ListClassRequest,
  //ItemClassRequest,
  ClassFilter,
  ClassFilterItem,
  ClassSort,
  ClassPagination,
  CreateClassRequest,  
  UpdateClassRequest, 
  DeleteClassRequest
} from "../../proto/pb/class_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 100000;

export class ClassApi {
  items: Array<Class> = [];
  client: ClassServiceClient;
  metadata: any;

  constructor() {
    const host:string = (window as any).VUE_APP_API_URL ?? '';
    this.client = new ClassServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  // Установка и получение фильтров
  createFilter(data: any){

    const filter = new ClassFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
        for (const i in data) {
            if (data[i].field_name && ('' !== data[i].field_name)) {
                const fItem = new ClassFilterItem();
                fItem.setFieldName(data[i].field_name.toString());
                fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                filter.addItems(fItem);
            }
        }
    }

    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any){

    const sort = new ClassSort();
    const name:string = data.name !== undefined ? data.name.toString() : 'class_name';
    const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any){

    const pagination = new ClassPagination();
    const page:number = data.page !== undefined ? Number(data.page) : 1;
    const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages:number = data.pages !== undefined ? Number(data.pages) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  // Список элементов
  getClassItems(filter: any, sort: any, pagination: any){

    const request = new ListClassRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));

    return this.client.listClass(request, this.metadata)
        .then((message) => {
          return message.getItemsList()
        })
  }
/*
  // Получение элемента
  getClassItem(filter: any){

    const request = new ItemClassRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));

    return new Promise((resolve, reject) => {
      this.client.itemClass(request, this.metadata, (err, message) => {
        if (err) {
          reject(err)
        } else {
          resolve(message.getItem());
        }
      });
    });
  }
*/
  // Создание элемента
  createClassItem(data: any){

    const request = new CreateClassRequest();
    request.setClassifierId(data.cls_id);
    request.setClassParentId(data.parent_id);
    request.setClassIdx(data.idx);
    request.setClassName(data.name);
    request.setClassDescr(data.descr);
    request.setClassNotes(data.notes);
    request.setClassRatio(data.ratio)
    request.setClassGroupName(data.group_name)
    request.setClassColor(data.color)

    return this.client.createClass(request, this.metadata);
  }

  // Редактирование элемента
  updateClassItem(data: any){

    const request = new UpdateClassRequest();
    request.setClassId(data.id);
    request.setClassifierId(data.cls_id);
    request.setClassParentId(data.parent_id);
    request.setClassIdx(data.idx);
    request.setClassName(data.name);
    request.setClassDescr(data.descr);
    request.setClassNotes(data.notes);
    request.setClassRatio(data.ratio)
    request.setClassGroupName(data.group_name)
    request.setClassColor(data.color)

    return this.client.updateClass(request, this.metadata);
  }

  // Удаление элемента
  deleteClassItem(data: any){

    const request = new DeleteClassRequest();
    request.setClassId(data.id);

    return this.client.deleteClass(request, this.metadata);
  }
}
