import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { LoyaltyApi } from '../services/api/LoyaltyApi';
import Loading from 'quasar/src/plugins/loading/Loading.js';

const loyaltyApi = new LoyaltyApi()

class State {
    loyaltyUsersList: any
    isLoyaltyUsersList: any
    loyaltyUserInfo: any
    isLoyaltyUserInfo: any
    loyaltyProjectList: any
    loyaltyTransactionsList: any
    exportLoyaltyUsersList: any
    isExportLoyaltyUsersList: any
    lastAchievements: any
    isLastAchievements: any
    allAchievements: any
    isAllAchievements: any
    selfActions: any
    isSelfActions: boolean
}

const getters = <GetterTree<State, any>> {
    getLoyaltyUsersList(state) {
        return state.loyaltyUsersList
    },

    getIsLoyaltyUsersList(state) {
        return state.isLoyaltyUsersList
    },

    getLoyaltyUserInfo(state) {
        return state.loyaltyUserInfo
    },

    getIsLoyaltyUserInfo(state) {
        return state.isLoyaltyUserInfo
    },

    getLoyaltyProjectList(state) {
        return state.loyaltyProjectList
    },

    getLoyaltyTransactionsList(state) {
        return state.loyaltyTransactionsList
    },

    getExportLoyaltyUsersList(state) {
        return state.exportLoyaltyUsersList
    },

    getIsExportLoyaltyUsersList(state) {
        return state.isExportLoyaltyUsersList
    },

    getLastAchievements(state) {
        return state.lastAchievements
    },

    getIsLastAchievements(state) {
        return state.isLastAchievements
    },

    getAllAchievements(state) {
        return state.allAchievements
    },

    getIsAllAchievements(state) {
        return state.isAllAchievements
    },

    getSelfActions(state) {
        return state.selfActions
    },

    isSelfActions(state) {
        return state.isSelfActions
    },
}

const mutations = <MutationTree<State>> {
    setLoyaltyUsersList(state, payload) {
        state.loyaltyUsersList = payload
    },

    setIsLoyaltyUsersList(state, payload) {
        state.isLoyaltyUsersList = payload
    },

    setLoyaltyUserInfo(state, payload) {
        state.loyaltyUserInfo = payload
    },

    setIsLoyaltyUserInfo(state, payload) {
        state.isLoyaltyUserInfo = payload
    },

    setLoyaltyProjectList(state, payload) {
        state.loyaltyProjectList = payload
    },

    setLoyaltyTransactionsList(state, payload) {
        state.loyaltyTransactionsList = payload
    },

    sexExportLoyaltyUsersList(state, payload) {
        state.exportLoyaltyUsersList = payload
    },

    setIsExportLoyaltyUsersList(state, payload) {
        state.isExportLoyaltyUsersList = payload
    },

    setLastAchievements(state, payload) {
        state.lastAchievements = payload
    },

    setIsLastAchievements(state, payload) {
        state.isLastAchievements = payload
    },

    setAllAchievements(state, payload) {
        state.allAchievements = payload
    },

    setIsAllAchievements(state, payload) {
        state.isAllAchievements = payload
    },

    setSelfActions(state, payload) {
        state.selfActions = payload
    },

    setIsSelfActions(state, payload) {
        state.isSelfActions = payload
    },
}

const actions = <ActionTree<State, any>> {
    getLoyaltyUsersList({ commit }, data: any) {
        commit('setIsLoyaltyUsersList', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            loyaltyApi.getListUsersLoyalty(filter, sort, pagination, data)
                .then(
                    (message:any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setLoyaltyUsersList', items);
                        commit('setIsLoyaltyUsersList', false);
                        console.log('LOYALTY LIST RES', items)
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setLoyaltyUsersList', []);
                        commit('setIsLoyaltyUsersList', false);
                        reject(error);
                    }
                );
        });
    },

    getExportLoyaltyUsersList({ commit }, data: any) {
        commit('setIsExportLoyaltyUsersList', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            loyaltyApi.getExportListUsersLoyalty(filter, sort, pagination, data)
                .then(
                    (message:any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('sexExportLoyaltyUsersList', items);
                        commit('setIsExportLoyaltyUsersList', false);
                        console.log('LOYALTY LIST RES', items)
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('sexExportLoyaltyUsersList', []);
                        commit('setIsExportLoyaltyUsersList', false);
                        reject(error);
                    }
                );
        });
    },

    getLoyaltyUserInfo({ commit }, data: any) {
        commit('setIsLoyaltyUserInfo', true);

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            loyaltyApi.getUserInfoLoyalty(data)
                .then(
                    (message:any) => {
                        commit('setLoyaltyUserInfo', message);
                        commit('setIsLoyaltyUserInfo', false);
                        console.log('Loyalty User Info Resolve', message)
                        resolve(message);
                    },
                    (error) => {
                        commit('setLoyaltyUserInfo', []);
                        commit('setIsLoyaltyUserInfo', false);
                        reject(error);
                    }
                );
        });
    },

    getLoyaltyProjectList({ commit }, data: any) {
        return new Promise((resolve, reject) => {

            // Получаем список элементов
            loyaltyApi.getProjectListLoyalty(data)
                .then(
                    (message:any) => {
                        const items = message.getProjectList()
                        commit('setLoyaltyProjectList', items);
                        console.log('PROJECT ITEMS LOYALTY', items)
                        resolve(items);
                    },
                    (error) => {
                        commit('setLoyaltyProjectList', []);
                        reject(error);
                    }
                );
        });
    },

    getLoyaltyTransactionsList({ commit }, data: any) {
        return new Promise((resolve, reject) => {

            // Получаем список элементов
            loyaltyApi.getTransactionListLoyalty(data)
                .then(
                    (message:any) => {
                        const items = message.getTypeTransList()
                        commit('setLoyaltyTransactionsList', items);
                        console.log('TRANSACTIONS ITEMS LOYALTY', items)
                        resolve(items);
                    },
                    (error) => {
                        commit('setLoyaltyTransactionsList', []);
                        reject(error);
                    }
                );
        });
    },

    getLastAchievements({ commit }, data: any) {
        commit('setIsLastAchievements', true);

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            loyaltyApi.getLastAchievementsLoyalty(data)
                .then(
                    (message:any) => {
                        const items = message.getAchievementsList();

                        commit('setLastAchievements', items);
                        commit('setIsLastAchievements', false);
                        console.log('LAST ACHIEVEMENTS RES', items)
                        resolve(items);
                    },
                    (error) => {
                        commit('setLastAchievements', []);
                        commit('setIsLastAchievements', false);
                        reject(error);
                    }
                );
        });
    },

    getAllAchievements({ commit }, data: any) {
        commit('setIsAllAchievements', true);

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            loyaltyApi.getAllAchievementsLoyalty(data)
                .then(
                    (message:any) => {
                        const items = message.getAchievementsList();

                        commit('setAllAchievements', items);
                        commit('setIsAllAchievements', false);
                        console.log('LAST ACHIEVEMENTS RES', items)
                        resolve(items);
                    },
                    (error) => {
                        commit('setAllAchievements', []);
                        commit('setIsAllAchievements', false);
                        reject(error);
                    }
                );
        });
    },

    getSelfActions({ commit }, data: any) {
        commit('setIsSelfActions', true);

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            loyaltyApi.getPLStats(data)
                .then(
                    (message:any) => {
                        const items = message.toObject().item;

                        commit('setSelfActions', items);
                        commit('setIsSelfActions', false);
                        console.log('SELF ACTIONS RES', items)
                        resolve(items);
                    },
                    (error) => {
                        commit('setSelfActions', []);
                        commit('setIsSelfActions', false);
                        reject(error);
                    }
                );
        });
    },
}

const loyaltyModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default loyaltyModule