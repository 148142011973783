/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: social_network_message_src.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as social_network_message_src_pb from './social_network_message_src_pb';


export class SocialNetworkMessageSrcServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSocialNetworkListMessageSrc = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageSrcService/SocialNetworkListMessageSrc',
    grpcWeb.MethodType.UNARY,
    social_network_message_src_pb.SocialNetworkListMessageSrcRequest,
    social_network_message_src_pb.SocialNetworkListMessageSrcResponse,
    (request: social_network_message_src_pb.SocialNetworkListMessageSrcRequest) => {
      return request.serializeBinary();
    },
    social_network_message_src_pb.SocialNetworkListMessageSrcResponse.deserializeBinary
  );

  socialNetworkListMessageSrc(
    request: social_network_message_src_pb.SocialNetworkListMessageSrcRequest,
    metadata: grpcWeb.Metadata | null): Promise<social_network_message_src_pb.SocialNetworkListMessageSrcResponse>;

  socialNetworkListMessageSrc(
    request: social_network_message_src_pb.SocialNetworkListMessageSrcRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_src_pb.SocialNetworkListMessageSrcResponse) => void): grpcWeb.ClientReadableStream<social_network_message_src_pb.SocialNetworkListMessageSrcResponse>;

  socialNetworkListMessageSrc(
    request: social_network_message_src_pb.SocialNetworkListMessageSrcRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_src_pb.SocialNetworkListMessageSrcResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageSrcService/SocialNetworkListMessageSrc',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkListMessageSrc,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageSrcService/SocialNetworkListMessageSrc',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkListMessageSrc);
  }

  methodDescriptorSocialNetworkListMessageSrcSource = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageSrcService/SocialNetworkListMessageSrcSource',
    grpcWeb.MethodType.UNARY,
    social_network_message_src_pb.SocialNetworkListMessageSrcSourceRequest,
    social_network_message_src_pb.SocialNetworkListMessageSrcSourceResponse,
    (request: social_network_message_src_pb.SocialNetworkListMessageSrcSourceRequest) => {
      return request.serializeBinary();
    },
    social_network_message_src_pb.SocialNetworkListMessageSrcSourceResponse.deserializeBinary
  );

  socialNetworkListMessageSrcSource(
    request: social_network_message_src_pb.SocialNetworkListMessageSrcSourceRequest,
    metadata: grpcWeb.Metadata | null): Promise<social_network_message_src_pb.SocialNetworkListMessageSrcSourceResponse>;

  socialNetworkListMessageSrcSource(
    request: social_network_message_src_pb.SocialNetworkListMessageSrcSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_src_pb.SocialNetworkListMessageSrcSourceResponse) => void): grpcWeb.ClientReadableStream<social_network_message_src_pb.SocialNetworkListMessageSrcSourceResponse>;

  socialNetworkListMessageSrcSource(
    request: social_network_message_src_pb.SocialNetworkListMessageSrcSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_src_pb.SocialNetworkListMessageSrcSourceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageSrcService/SocialNetworkListMessageSrcSource',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkListMessageSrcSource,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageSrcService/SocialNetworkListMessageSrcSource',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkListMessageSrcSource);
  }

  methodDescriptorSocialNetworkPersonalListMessageSrcSource = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageSrcService/SocialNetworkPersonalListMessageSrcSource',
    grpcWeb.MethodType.UNARY,
    social_network_message_src_pb.SocialNetworkPersonalListMessageSrcSourceRequest,
    social_network_message_src_pb.SocialNetworkPersonalListMessageSrcSourceResponse,
    (request: social_network_message_src_pb.SocialNetworkPersonalListMessageSrcSourceRequest) => {
      return request.serializeBinary();
    },
    social_network_message_src_pb.SocialNetworkPersonalListMessageSrcSourceResponse.deserializeBinary
  );

  socialNetworkPersonalListMessageSrcSource(
    request: social_network_message_src_pb.SocialNetworkPersonalListMessageSrcSourceRequest,
    metadata: grpcWeb.Metadata | null): Promise<social_network_message_src_pb.SocialNetworkPersonalListMessageSrcSourceResponse>;

  socialNetworkPersonalListMessageSrcSource(
    request: social_network_message_src_pb.SocialNetworkPersonalListMessageSrcSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_src_pb.SocialNetworkPersonalListMessageSrcSourceResponse) => void): grpcWeb.ClientReadableStream<social_network_message_src_pb.SocialNetworkPersonalListMessageSrcSourceResponse>;

  socialNetworkPersonalListMessageSrcSource(
    request: social_network_message_src_pb.SocialNetworkPersonalListMessageSrcSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_src_pb.SocialNetworkPersonalListMessageSrcSourceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageSrcService/SocialNetworkPersonalListMessageSrcSource',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkPersonalListMessageSrcSource,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageSrcService/SocialNetworkPersonalListMessageSrcSource',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkPersonalListMessageSrcSource);
  }

}

