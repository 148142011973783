import { AddressServiceClient } from '../../proto/pb/AddressServiceClientPb'
import {
  ListAddressRequest,
  AddressPagination,
} from '../../proto/pb/address_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

const defaultLimit: number = 1000

export class AdressApi {
  client: AddressServiceClient
  metadata: any

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? ''
    this.client = new AddressServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }
  createPagination(data: any) {
    const pagination = new AddressPagination()
    const page: number = data.page !== undefined ? Number(data.page) : 1
    const limit: number =
      data.limit !== undefined ? Number(data.limit) : defaultLimit
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0

    // Устанавливаем пагинацию
    pagination.setPage(page)
    pagination.setLimit(limit)
    pagination.setPages(pages)

    return pagination
  }

  getActionList({ sso_id }: any, pagination: any) {
    const request = new ListAddressRequest()
    console.log('ADRESS API', sso_id, pagination)
    // Устанавливаем параметры запроса
    request.setPagination(this.createPagination(pagination)).setSsoId(sso_id)

    return this.client.listAddress(request, { Authorization: localStorage.getItem('access-key') } as any)
  }
}
