import { GetterTree, MutationTree, ActionTree } from 'vuex'
import {AvailableEventsApi} from "@/components/modules/accounts/services/api/AvailableEventsApi";

class State {
    isEventsBusy: boolean = false
    eventsList: Array<any> = []
    selectedEventsList: Array<string> = []
    date: {
        dateFrom: any
        dateTo: any
    }
    selectedProjectsForEvent: any
    eventCnt: number
    eventListPagination: any
    isExportEventsBusy: boolean = false
    projectCount: any
    isProjectCount: boolean = false
    availableFilter: any
}

const availableEventsApi = new AvailableEventsApi()

const getters = <GetterTree<State, any>>{
    isAvailableEventsBusy(state): boolean {
        return state.isEventsBusy
    },

    getAvailableEventsList(state) {
        return state.eventsList
    },

    getSelectedAvailableEvents(state) {
        return state.selectedEventsList
    },

    getDateForAvailableEventList(state) {
        return state.date
    },

    getSelectedProjectsForAvailableEvent(state) {
        return state.selectedProjectsForEvent
    },

    getAvailableEventListCnt(state) {
        return state.eventCnt
    },

    getAvailableEventListPagination(state) {
        return state.eventListPagination
    },

    isExportAvailableEventsBusy(state) {
        return state.isExportEventsBusy
    },

    getAvailableProjectCount(state) {
        return state.projectCount
    },

    isAvailableProjectCount(state) {
        return state.isProjectCount
    },

    getAvailableFilter(state) {
        return state.availableFilter
    },
}

const mutations = <MutationTree<State>>{
    setIsAvailableEventsBusy(state, payload: boolean) {
        state.isEventsBusy = payload
        // if (payload) {
        //   Loading.show()
        // } else {
        //   Loading.hide()
        // }
    },

    setAvailableEventsList(state, payload) {
        state.eventsList = payload
    },

    setSelectedAvailableEvents(state, payload) {
        state.selectedEventsList = payload
    },

    setDateForAvailableEvent(state, payload) {
        state.date = payload
    },

    setSelectedProjectsForAvailableEvent(state, payload) {
        state.selectedProjectsForEvent = payload
    },

    setAvailableEventListCnt(state, payload) {
        state.eventCnt = payload
    },

    setPaginationForAvailableEventList(state, payload) {
        state.eventListPagination = payload
    },

    setIsExportAvailableEventsBusy(state, payload) {
        state.isExportEventsBusy = payload
    },

    setAvailableProjectCount(state, payload) {
        state.projectCount = payload
    },

    setIsAvailableProjectCount(state, payload) {
        state.isProjectCount = payload
    },

    setAvailableFilter(state, payload) {
        state.availableFilter = payload
    },
}

const actions = <ActionTree<State, any>>{
    setProjectsForAvailableEvent({ commit }, data) {
        commit('setSelectedProjectsForAvailableEvent', data)
    },

    setDateForAvailableEvent({ commit }, data) {
        commit('setDateForAvailableEvent', data)
    },

    getAvailableEventsList({ commit }, data: any) {
        console.log(
            '🚀 ~ file: events.ts:77 ~ getEventsList ~ getEventsList:',
            data
        )

        commit('setIsAvailableEventsBusy', true)

        const filter: any =
            data !== undefined && data.filter !== undefined ? data.filter : []
        const pagination: any =
            data !== undefined && data.pagination !== undefined ? data.pagination : {}
        const sort: any =
            data !== undefined && data.sort !== undefined ? data.sort : {}

        return new Promise((resolve, reject) => {
            // Получаем список элементов
            availableEventsApi.getAvailableEventsList(filter, pagination, sort).then(
                (message: any) => {
                    const items = message.getItemsList()
                    const pagination = message.getParams().getPagination()

                    commit('setAvailableEventListCnt', pagination.getCnt())
                    commit('setAvailableEventsList', items)
                    commit('setIsAvailableEventsBusy', false)

                    resolve({ items, pagination })
                },
                (error) => {
                    commit('setAvailableEventsList', [])
                    commit('setIsAvailableEventsBusy', false)
                    reject(error)
                }
            )
        })
    },

    getAvailableEventsListForExport({ commit }, data: any) {
        commit('setIsExportAvailableEventsBusy', true)

        const filter: any =
            data !== undefined && data.filter !== undefined ? data.filter : []
        const pagination: any =
            data !== undefined && data.pagination !== undefined ? data.pagination : {}
        const sort: any =
            data !== undefined && data.sort !== undefined ? data.sort : {}

        return new Promise((resolve, reject) => {
            // Получаем список элементов
            availableEventsApi.getAvailableEventsList(filter, pagination, sort).then(
                (message: any) => {
                    const items = message.getItemsList()

                    commit('setIsExportAvailableEventsBusy', false)

                    resolve(items)
                },
                (error) => {
                    commit('setIsExportAvailableEventsBusy', false)
                    reject(error)
                }
            )
        })
    },

    setSelectedAvailableEvents({ commit }, data: Array<String>) {
        commit('setSelectedAvailableEvents', data)
    },

    getAvailableEventsCounts({ commit }, data) {
        const filter: any =
            data !== undefined && data.filter !== undefined ? data.filter : []
        const pagination: any =
            data !== undefined && data.pagination !== undefined ? data.pagination : {}
        const sort: any =
            data !== undefined && data.sort !== undefined ? data.sort : {}

        return new Promise((resolve, reject) => {
            availableEventsApi.getAvailableEventsCount(filter, pagination, sort).then(
                (message: any) => {
                    console.log(
                        '🚀 ~ file: events.ts:193 ~ returnnewPromise ~ message:',
                        message
                    )
                    const cnt = message.getCnt()

                    resolve(cnt)
                },
                (error) => {
                    reject(error)
                }
            )
        })
    },

    getAvailableProjectCount({ commit }, data) {
        commit('setIsAvailableProjectCount', true)

        return new Promise((resolve, reject) => {
            availableEventsApi.getAvailableEventsProjects(data).then(
                (message: any) => {
                    console.log(
                        '🚀 ~ file: events.ts:193 ~ returnnewPromise ~ message:',
                        message
                    )
                    const cnt = message.toObject().itemsList

                    commit('setIsAvailableProjectCount', false)
                    commit('setAvailableProjectCount', cnt)

                    resolve(cnt)
                },
                (error) => {
                    commit('setIsAvailableProjectCount', false)

                    reject(error)
                }
            )
        })
    },

    getAvailableFilter({ commit }, data) {
        return new Promise((resolve, reject) => {
            availableEventsApi.getAvailableEventsFilter(data).then(
                (message: any) => {
                    console.log(
                        '🚀 ~ file: events.ts:193 ~ returnnewPromise ~ message:',
                        message
                    )
                    const items = message.toObject().itemsList

                    commit('setAvailableFilter', items)

                    resolve(items)
                },
                (error) => {
                    reject(error)
                }
            )
        })
    },
}

const AccountAvailableEventsModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters,
}

export default AccountAvailableEventsModule
