import AssessSetModel from '../views/set-model/AssessSetModel.vue'
import UserList from '../views/users/UserList.vue'

const routes = [
    {
        path: '/reference/set-model',
        name: 'AssessSetModel',
        component: AssessSetModel,
        meta: {
            breadCrumb: [
              { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
              { text: 'Справка', to: {name: 'ReferencePage'} },
              { text: 'Справочник параметров настройки модели' },
            ],
          },
    },
    {
        path: '/estimation/analysis',
        name: 'UserList',
        component: UserList,
        meta: {
            breadCrumb: [
              { text: 'Главная', to: { name: 'HomePage' },icon:'home' },
              { text: 'Оценка пользователей', to: {name: 'Estimation'} },
              { text: 'Оценка' }
            ],
          },
    },
]

export default {
    routes
}