import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { SocialNetworkMessageGrpClsApi } from '../services/api/SocialNetworkMessageGrpClsApi';


class State {
    isSocialNetworkMessageGrpClsBusy: boolean = false;
    isSocialNetworkMessageGrpClsInClassBusy: boolean = false;
    isSocialNetworkMessageGrpClsOutClassBusy: boolean = false;
    socialNetworkMessageGrpClsInClassItems: unknown [];
    socialNetworkMessageGrpClsOutClassItems: unknown [];
    socialNetworkMessageGrpClsItem: unknown;
}

// Создаем объект
const socialNetworkMessageGrpClsApi = new SocialNetworkMessageGrpClsApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

    isSocialNetworkMessageGrpClsBusy(state): boolean {
        return state.isSocialNetworkMessageGrpClsBusy;
    },

    isSocialNetworkMessageGrpClsInClassBusy(state): boolean {
        return state.isSocialNetworkMessageGrpClsInClassBusy;
    },

    isSocialNetworkMessageGrpClsOutClassBusy(state): boolean {
        return state.isSocialNetworkMessageGrpClsOutClassBusy;
    },

    getSocialNetworkMessageGrpClsInClassItems(state): unknown[] {
        return state.socialNetworkMessageGrpClsInClassItems;
    },

    getSocialNetworkMessageGrpClsOutClassItems(state): unknown[] {
        return state.socialNetworkMessageGrpClsOutClassItems;
    },

    getSocialNetworkMessageGrpClsItem(state): unknown {
        return state.socialNetworkMessageGrpClsItem;
    }
};

// Создаем мутации
const mutations = <MutationTree<State>>{
    setIsSocialNetworkMessageGrpClsBusy(state, payload: boolean) {
        state.isSocialNetworkMessageGrpClsBusy = payload
    },

    setIsSocialNetworkMessageGrpClsInClassBusy(state, payload: boolean) {
        state.isSocialNetworkMessageGrpClsInClassBusy = payload
    },

    setIsSocialNetworkMessageGrpClsOutClassBusy(state, payload: boolean) {
        state.isSocialNetworkMessageGrpClsOutClassBusy = payload
    },

    setSocialNetworkMessageGrpClsInClassItems(state, payload: unknown[]) {
        state.socialNetworkMessageGrpClsInClassItems = payload
    },

    setSocialNetworkMessageGrpClsOutClassItems(state, payload: unknown[]) {
        state.socialNetworkMessageGrpClsOutClassItems = payload
    },

    setSocialNetworkMessageGrpClsItem(state, payload: unknown) {
        state.socialNetworkMessageGrpClsItem = payload
    }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

    // Устанавливаем элемент
    setSocialNetworkMessageGrpClsItem({ commit }, data: any) {
        commit('setSocialNetworkMessageGrpClsItem', data);
    },

    // Список элементов (привязанные ответы)
    getSocialNetworkMessageGrpClsInClassItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageGrpClsBusy', true);
        commit('setIsSocialNetworkMessageGrpClsInClassBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const class_id:number = data !== undefined && data.class_id !== undefined ? Number(data.class_id) : 0;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            socialNetworkMessageGrpClsApi.getSocialNetworkMessageGrpClsInItems(filter, sort, pagination, class_id)
                .then(
                    (message:any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setSocialNetworkMessageGrpClsInClassItems', items);
                        commit('setIsSocialNetworkMessageGrpClsBusy', false);
                        commit('setIsSocialNetworkMessageGrpClsInClassBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setSocialNetworkMessageGrpClsInClassItems', []);
                        commit('setIsSocialNetworkMessageGrpClsBusy', false);
                        commit('setIsSocialNetworkMessageGrpClsInClassBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Список элементов (непривязанные ответы)
    getSocialNetworkMessageGrpClsOutClassItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageGrpClsBusy', true);
        commit('setIsSocialNetworkMessageGrpClsOutClassBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const class_id:number = data !== undefined && data.class_id !== undefined ? Number(data.class_id) : 0;
        const not_classifier_only:boolean = data !== undefined && data.not_classifier_only !== undefined ? data.not_classifier_only : true;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            socialNetworkMessageGrpClsApi.getSocialNetworkMessageGrpClsOutItems(filter, sort, pagination, class_id, not_classifier_only)
                .then(
                    (message:any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setSocialNetworkMessageGrpClsOutClassItems', items);
                        commit('setIsSocialNetworkMessageGrpClsBusy', false);
                        commit('setIsSocialNetworkMessageGrpClsOutClassBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setSocialNetworkMessageGrpClsOutClassItems', []);
                        commit('setIsSocialNetworkMessageGrpClsBusy', false);
                        commit('setIsSocialNetworkMessageGrpClsOutClassBusy', false);
                        reject(error);
                    }
                );
        });
    }
};

// Создаем модуль
const SocialNetworkMessageGrpClsModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default SocialNetworkMessageGrpClsModule;
