/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: scorecard_value_account.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.proto = require('./scorecard_value_account_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ScorecardValueAccountServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ScorecardValueAccountServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateScorecardValueAccountRequest,
 *   !proto.proto.CreateScorecardValueAccountResponse>}
 */
const methodDescriptor_ScorecardValueAccountService_CreateScorecardValueAccount = new grpc.web.MethodDescriptor(
  '/proto.ScorecardValueAccountService/CreateScorecardValueAccount',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateScorecardValueAccountRequest,
  proto.proto.CreateScorecardValueAccountResponse,
  /**
   * @param {!proto.proto.CreateScorecardValueAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreateScorecardValueAccountResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateScorecardValueAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CreateScorecardValueAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreateScorecardValueAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ScorecardValueAccountServiceClient.prototype.createScorecardValueAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ScorecardValueAccountService/CreateScorecardValueAccount',
      request,
      metadata || {},
      methodDescriptor_ScorecardValueAccountService_CreateScorecardValueAccount,
      callback);
};


/**
 * @param {!proto.proto.CreateScorecardValueAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreateScorecardValueAccountResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ScorecardValueAccountServicePromiseClient.prototype.createScorecardValueAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ScorecardValueAccountService/CreateScorecardValueAccount',
      request,
      metadata || {},
      methodDescriptor_ScorecardValueAccountService_CreateScorecardValueAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteScorecardValueAccountRequest,
 *   !proto.proto.DeleteScorecardValueAccountResponse>}
 */
const methodDescriptor_ScorecardValueAccountService_DeleteScorecardValueAccount = new grpc.web.MethodDescriptor(
  '/proto.ScorecardValueAccountService/DeleteScorecardValueAccount',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteScorecardValueAccountRequest,
  proto.proto.DeleteScorecardValueAccountResponse,
  /**
   * @param {!proto.proto.DeleteScorecardValueAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.DeleteScorecardValueAccountResponse.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteScorecardValueAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.DeleteScorecardValueAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.DeleteScorecardValueAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ScorecardValueAccountServiceClient.prototype.deleteScorecardValueAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ScorecardValueAccountService/DeleteScorecardValueAccount',
      request,
      metadata || {},
      methodDescriptor_ScorecardValueAccountService_DeleteScorecardValueAccount,
      callback);
};


/**
 * @param {!proto.proto.DeleteScorecardValueAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.DeleteScorecardValueAccountResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ScorecardValueAccountServicePromiseClient.prototype.deleteScorecardValueAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ScorecardValueAccountService/DeleteScorecardValueAccount',
      request,
      metadata || {},
      methodDescriptor_ScorecardValueAccountService_DeleteScorecardValueAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListScorecardValueAccountRequest,
 *   !proto.proto.ListScorecardValueAccountResponse>}
 */
const methodDescriptor_ScorecardValueAccountService_ListScorecardValueAccount = new grpc.web.MethodDescriptor(
  '/proto.ScorecardValueAccountService/ListScorecardValueAccount',
  grpc.web.MethodType.UNARY,
  proto.proto.ListScorecardValueAccountRequest,
  proto.proto.ListScorecardValueAccountResponse,
  /**
   * @param {!proto.proto.ListScorecardValueAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListScorecardValueAccountResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListScorecardValueAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListScorecardValueAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListScorecardValueAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ScorecardValueAccountServiceClient.prototype.listScorecardValueAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ScorecardValueAccountService/ListScorecardValueAccount',
      request,
      metadata || {},
      methodDescriptor_ScorecardValueAccountService_ListScorecardValueAccount,
      callback);
};


/**
 * @param {!proto.proto.ListScorecardValueAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListScorecardValueAccountResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ScorecardValueAccountServicePromiseClient.prototype.listScorecardValueAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ScorecardValueAccountService/ListScorecardValueAccount',
      request,
      metadata || {},
      methodDescriptor_ScorecardValueAccountService_ListScorecardValueAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CalculateScorecardValueAccountRequest,
 *   !proto.proto.CalculateScorecardValueAccountResponse>}
 */
const methodDescriptor_ScorecardValueAccountService_CalculateScorecardValueAccount = new grpc.web.MethodDescriptor(
  '/proto.ScorecardValueAccountService/CalculateScorecardValueAccount',
  grpc.web.MethodType.UNARY,
  proto.proto.CalculateScorecardValueAccountRequest,
  proto.proto.CalculateScorecardValueAccountResponse,
  /**
   * @param {!proto.proto.CalculateScorecardValueAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CalculateScorecardValueAccountResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CalculateScorecardValueAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CalculateScorecardValueAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CalculateScorecardValueAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ScorecardValueAccountServiceClient.prototype.calculateScorecardValueAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ScorecardValueAccountService/CalculateScorecardValueAccount',
      request,
      metadata || {},
      methodDescriptor_ScorecardValueAccountService_CalculateScorecardValueAccount,
      callback);
};


/**
 * @param {!proto.proto.CalculateScorecardValueAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CalculateScorecardValueAccountResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ScorecardValueAccountServicePromiseClient.prototype.calculateScorecardValueAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ScorecardValueAccountService/CalculateScorecardValueAccount',
      request,
      metadata || {},
      methodDescriptor_ScorecardValueAccountService_CalculateScorecardValueAccount);
};


module.exports = proto.proto;

