/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: scorecard.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.proto = require('./scorecard_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ScorecardServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ScorecardServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateScorecardRequest,
 *   !proto.proto.CreateScorecardResponse>}
 */
const methodDescriptor_ScorecardService_CreateScorecard = new grpc.web.MethodDescriptor(
  '/proto.ScorecardService/CreateScorecard',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateScorecardRequest,
  proto.proto.CreateScorecardResponse,
  /**
   * @param {!proto.proto.CreateScorecardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreateScorecardResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateScorecardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CreateScorecardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreateScorecardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ScorecardServiceClient.prototype.createScorecard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ScorecardService/CreateScorecard',
      request,
      metadata || {},
      methodDescriptor_ScorecardService_CreateScorecard,
      callback);
};


/**
 * @param {!proto.proto.CreateScorecardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreateScorecardResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ScorecardServicePromiseClient.prototype.createScorecard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ScorecardService/CreateScorecard',
      request,
      metadata || {},
      methodDescriptor_ScorecardService_CreateScorecard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateScorecardRequest,
 *   !proto.proto.UpdateScorecardResponse>}
 */
const methodDescriptor_ScorecardService_UpdateScorecard = new grpc.web.MethodDescriptor(
  '/proto.ScorecardService/UpdateScorecard',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateScorecardRequest,
  proto.proto.UpdateScorecardResponse,
  /**
   * @param {!proto.proto.UpdateScorecardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpdateScorecardResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateScorecardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpdateScorecardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpdateScorecardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ScorecardServiceClient.prototype.updateScorecard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ScorecardService/UpdateScorecard',
      request,
      metadata || {},
      methodDescriptor_ScorecardService_UpdateScorecard,
      callback);
};


/**
 * @param {!proto.proto.UpdateScorecardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpdateScorecardResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ScorecardServicePromiseClient.prototype.updateScorecard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ScorecardService/UpdateScorecard',
      request,
      metadata || {},
      methodDescriptor_ScorecardService_UpdateScorecard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteScorecardRequest,
 *   !proto.proto.DeleteScorecardResponse>}
 */
const methodDescriptor_ScorecardService_DeleteScorecard = new grpc.web.MethodDescriptor(
  '/proto.ScorecardService/DeleteScorecard',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteScorecardRequest,
  proto.proto.DeleteScorecardResponse,
  /**
   * @param {!proto.proto.DeleteScorecardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.DeleteScorecardResponse.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteScorecardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.DeleteScorecardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.DeleteScorecardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ScorecardServiceClient.prototype.deleteScorecard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ScorecardService/DeleteScorecard',
      request,
      metadata || {},
      methodDescriptor_ScorecardService_DeleteScorecard,
      callback);
};


/**
 * @param {!proto.proto.DeleteScorecardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.DeleteScorecardResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ScorecardServicePromiseClient.prototype.deleteScorecard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ScorecardService/DeleteScorecard',
      request,
      metadata || {},
      methodDescriptor_ScorecardService_DeleteScorecard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListScorecardRequest,
 *   !proto.proto.ListScorecardResponse>}
 */
const methodDescriptor_ScorecardService_ListScorecard = new grpc.web.MethodDescriptor(
  '/proto.ScorecardService/ListScorecard',
  grpc.web.MethodType.UNARY,
  proto.proto.ListScorecardRequest,
  proto.proto.ListScorecardResponse,
  /**
   * @param {!proto.proto.ListScorecardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListScorecardResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListScorecardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListScorecardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListScorecardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ScorecardServiceClient.prototype.listScorecard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ScorecardService/ListScorecard',
      request,
      metadata || {},
      methodDescriptor_ScorecardService_ListScorecard,
      callback);
};


/**
 * @param {!proto.proto.ListScorecardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListScorecardResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ScorecardServicePromiseClient.prototype.listScorecard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ScorecardService/ListScorecard',
      request,
      metadata || {},
      methodDescriptor_ScorecardService_ListScorecard);
};


module.exports = proto.proto;

