
import store from '@/store'
import { defineComponent } from 'vue'
import ValueAdd from '../values/ValueAdd.vue'
import ValueEdit from '../values/ValueEdit.vue'
import ValueDelete from '../values/ValueDelete.vue'
import DataLoading from '@/components/DataLoading.vue'

const parentRootName = 'Нет (это корневой показатель)'

export default defineComponent({
  name: 'ScorecardAdd',

  components: {
    ValueAdd,
    ValueEdit,
    ValueDelete,
    DataLoading,
  },

  data() {
    return {
      currentTab: 'main',
      item: {
        parent_id: 0,
        parentName: parentRootName,
        idx: 1,
        title: '',
        weight: 0.0,
      },
      columns: [
        {
          name: 'title',
          label: 'Наименование',
          field: 'title',
          align: 'left',
        },
        {
          name: 'points',
          label: 'Баллы',
          field: 'points',
          align: 'center',
        },
        {
          name: 'check_rule',
          label: 'Правила проверки',
          field: 'check_rule',
          align: 'left',
        },
        {
          name: 'action',
          label: 'Действие',
          field: 'id',
          align: 'center',
          headerStyle: 'width: 100px',
          style: 'width: 40px',
        },
      ] as any,
      values: [] as any,
      isActiveModal: false,
      isActiveModalTree: false,
      isActiveModalAdd: false,
      isActiveModalEdit: false,
      isActiveModalDelete: false,
      selectedValue: null as any,
      selectedNode: null as any,
      selectedName: '',
    }
  },

  computed: {
    isScorecardBusy() {
      return store.getters.getIsScorecardBusy
    },

    isScorecardValueBusy() {
      return store.getters.getIsScorecardValueBusy
    },

    isScorecardTreeFormBusy() {
      return store.getters.getIsScorecardTreeFormBusy
    },

    getScorecardTreeFormItems() {
      let nodes = store.getters.getScorecardTreeFormItems
      return nodes ? nodes : []
    },
  },

  methods: {
    hideModal() {
      this.isActiveModal = false
      this.isActiveModalTree = false
      this.isActiveModalAdd = false
      this.isActiveModalEdit = false
      this.isActiveModalDelete = false
      this.selectedValue = null
    },

    changeParent() {
      console.log('changeParent')
      this.isActiveModalTree = true
      this.isActiveModal = true

      store
        .dispatch('getScorecardTreeFormItems', {
          root: {
            id: 0,
            idx: 1,
            title: parentRootName,
          },
          disabled: [],
        })
        .then(
          (resolve) => {
            console.log('getScorecardTreeFormItems resolve', resolve)
            this.setTreeNode(this.item.parent_id)
          },
          (reject) => {
            console.log('getScorecardTreeFormItems reject', reject)
          }
        )
    },

    selectNode() {
      if (this.selectedNode !== null) {
        const tree: any = this.$refs.scorecardParentTree
        let curNode: any =
          tree !== undefined
            ? JSON.parse(JSON.stringify(tree.getNodeByKey(this.selectedNode)))
            : null
        if (curNode !== null) {
          this.selectedName = curNode.title
        } else {
          this.selectedName = ''
        }
      } else {
        this.selectedName = ''
      }
    },

    setTreeNode(setNode: any) {
      if (setNode !== null) {
        console.log('ScorecardAdd setTreeNode setNode', setNode)
        this.selectedNode = setNode
        const tree: any = this.$refs.scorecardParentTree
        let curNode: any =
          tree !== undefined
            ? JSON.parse(JSON.stringify(tree.getNodeByKey(this.selectedNode)))
            : null
        if (curNode !== null) {
          this.selectedName = curNode.title
          while (curNode && curNode.parent_id !== undefined) {
            curNode = JSON.parse(
              JSON.stringify(tree.getNodeByKey(curNode.parent_id))
            )
            if (curNode) {
              tree.setExpanded(curNode.id, true)
            }
          }
        }
      }
    },

    selectParent() {
      if (this.selectedNode !== null) {
        const tree: any = this.$refs.scorecardParentTree
        let curNode: any =
          tree !== undefined
            ? JSON.parse(JSON.stringify(tree.getNodeByKey(this.selectedNode)))
            : null
        if (curNode) {
          this.item.parent_id = curNode.id
          this.item.parentName = curNode.title
          this.hideModal()
        }
      }
    },

    addValue() {
      console.log('addValue')
      this.isActiveModalAdd = true
      this.isActiveModal = true
    },

    editValue(index: any) {
      console.log('editValue index', index)
      this.selectedValue = {
        index: index,
        id: this.values[index].id,
        idx: this.values[index].idx,
        title: this.values[index].title,
        points: this.values[index].points,
        check_rule: this.values[index].check_rule,
      }
      console.log('this.selectedValue', this.selectedValue)
      this.isActiveModalEdit = true
      this.isActiveModal = true
    },

    deleteValue(index: any) {
      console.log('deleteValue index', index)
      this.selectedValue = {
        index: index,
        id: this.values[index].id,
        idx: this.values[index].idx,
        title: this.values[index].title,
        points: this.values[index].points,
        check_rule: this.values[index].check_rule,
      }
      console.log('this.selectedValue', this.selectedValue)
      this.isActiveModalDelete = true
      this.isActiveModal = true
    },

    afterAdd(valueItem: any) {
      console.log('afterAdd valueItem', valueItem)
      // Вставляем в начало
      this.values.unshift({
        id: 0,
        idx: valueItem.idx,
        title: valueItem.title.trim(),
        points: valueItem.points,
        check_rule: valueItem.check_rule.trim(),
      })
      this.hideModal()
    },

    afterEdit(valueItem: any) {
      console.log('afterEdit valueItem', valueItem)
      this.values[valueItem.index].idx = valueItem.idx
      this.values[valueItem.index].title = valueItem.title.trim()
      this.values[valueItem.index].points = valueItem.points
      this.values[valueItem.index].check_rule = valueItem.check_rule.trim()
      this.hideModal()
    },

    actualDelete(index: any) {
      console.log('actualDelete index', index)
      // Удаление по индексу в массиве values
      this.values.splice(index, 1)
      this.hideModal()
    },

    saveScorecard() {
      if (!this.item.title) {
        console.log('Наименование показателя не введено')
        return false
      }

      let data = {
        parent_id: this.item.parent_id,
        idx: this.item.idx,
        title: this.item.title,
        weight: this.item.weight,
      }
      console.log('saveScorecard data', data)
      store.dispatch('createScorecardItem', data).then(
        (resolve) => {
          console.log('resolve', resolve)
          const scorecardId = resolve.getScorecardId()
          if (this.values.length) {
            // Сохранение значений
            // Потом переделать на вставку "пачкой" !!!
            this.values.forEach((val: any) => {
              store
                .dispatch('createScorecardValueItem', {
                  scorecard_id: scorecardId,
                  idx: val.idx,
                  title: val.title,
                  points: val.points,
                  check_rule: val.check_rule,
                })
                .then(
                  (resolve) => {
                    console.log('resolve', resolve)
                  },
                  (reject) => {
                    console.log('reject', reject)
                  }
                )
            })
            // Убрать после переделки на вставку "пачкой" !!! -----------
            let fTimeout: ReturnType<typeof setTimeout>
            fTimeout = setTimeout(() => {
              this.$emit('afterAction', scorecardId)
              clearTimeout(fTimeout)
            }, 1000)
            // Убрать до сюда -------------------------------------------
          } else {
            this.$emit('afterAction', scorecardId)
          }
        },
        (reject) => {
          console.log('reject', reject)
        }
      )
      return true
    },

    closeScorecardAdd() {
      this.$emit('hideModal')
    },

    loadData() {
      this.isActiveModal = false
      let currentScorecardNode = store.getters.getCurrentScorecardNode
      if (currentScorecardNode) {
        this.item = {
          parent_id: currentScorecardNode.id,
          parentName: currentScorecardNode.title,
          idx: 1,
          title: '',
          weight: 0.0,
        }
      } else {
        this.item = {
          parent_id: 0,
          parentName: parentRootName,
          idx: 1,
          title: '',
          weight: 0.0,
        }
      }
    },
  },

  created() {
    this.loadData()
  },
})
