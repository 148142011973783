
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ValueAdd',

  data() {
    return {
      item: {
        id: 0,
        title: '',
        idx: 1,
        points: 0,
        check_rule: '',
      },
    }
  },

  methods: {
    saveValue() {
      if (!this.item.title) {
        console.log('Наименование значения не введено')
        return false
      }

      this.$emit('afterAdd', this.item)
    },

    closeValueAdd() {
      this.$emit('hideModal')
    },

    loadData() {
      this.item = {
        id: 0,
        title: '',
        idx: 1,
        points: 0,
        check_rule: '',
      }
    },
  },

  created() {
    this.loadData()
  },
})
