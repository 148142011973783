import { GetterTree, MutationTree, ActionTree } from "vuex";
import { PeriodApi } from "../services/api/PeriodApi";
// import Loading from 'quasar/src/plugins/loading/Loading.js';

class State {
    periodItems: unknown [];
}

// Создаем объект
const periodApi = new PeriodApi();


// Создаем геттеры
const getters = <GetterTree<State, any>>{
  
    getPeriodItems(state): unknown[] {
      return state.periodItems;
    },
};

// Создаем мутации
const mutations = <MutationTree<State>>{
  
    setPeriodItems(state, payload: unknown[]) {
      state.periodItems = payload
    },
};

// Создаем действия
const actions = <ActionTree<State, any>>{
  
    // Список элементов
    getPeriodItems({ commit }, data: any) {
      
      commit('setIsProjectInfoBusy',true)

      const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
      const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
      const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
  
      return new Promise((resolve, reject) => {

        // Получаем список элементов
        periodApi.getPeriodItems(filter, sort, pagination)
          .then(
            (response:any) => {
              commit('setPeriodItems', response);
              commit('setIsProjectInfoBusy',false)
              resolve(response);
            },
            (error) => {
              commit('setIsProjectInfoBusy',false)
              reject(error);
            }
          );
      });
    },
};

// Создаем модуль
const PeriodModule:any = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
  };
  
export default PeriodModule;