
import store from "@/store"
import { defineComponent } from "vue"
import DataLoading from "@/components/DataLoading.vue"

export default defineComponent({
  name: "WordDelete",

  components: {
    DataLoading,
  },

  data() {
    return {
      item: {
        id: 0,
        parent_id: 0,
        text: "",
        is_linked: false,
        hasChilds: false,
      },
    }
  },

  computed: {
    isWordsBusy() {
      return store.getters.getIsWordsBusy
    },
  },

  methods: {
    actualDelete() {
      store.dispatch("deleteWordsItem", { id: this.item.id }).then(
        (resolve) => {
          console.log("resolve", resolve)
          store.dispatch("setCurrentWordsNode", null) // Очищаем текущий узел
          this.$emit(
            "afterAction",
            (0 !== this.item.parent_id) || !this.item.is_linked ? this.item.parent_id : null
          )
        },
        (reject) => {
          console.log("reject", reject)
        }
      )
    },

    closeWordDelete() {
      this.$emit("hideModal")
    },

    loadData() {
      let currentNode = store.getters.getCurrentWordsNode
      if (currentNode) {
        this.item = {
          id: currentNode.id,
          parent_id: currentNode.parent_id,
          text: currentNode.text,
          is_linked: currentNode.is_linked,
          hasChilds: currentNode.hasChilds,
        }
      } else {
        this.closeWordDelete()
      }
    },
  },

  created() {
    this.loadData()
  },
})
