/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: activity_account.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as activity_account_pb from './activity_account_pb';


export class DashboardActivityAccountServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListDashboardActivityAccount = new grpcWeb.MethodDescriptor(
    '/proto.DashboardActivityAccountService/ListDashboardActivityAccount',
    grpcWeb.MethodType.UNARY,
    activity_account_pb.ListDashboardActivityAccountRequest,
    activity_account_pb.ListDashboardActivityAccountResponse,
    (request: activity_account_pb.ListDashboardActivityAccountRequest) => {
      return request.serializeBinary();
    },
    activity_account_pb.ListDashboardActivityAccountResponse.deserializeBinary
  );

  listDashboardActivityAccount(
    request: activity_account_pb.ListDashboardActivityAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<activity_account_pb.ListDashboardActivityAccountResponse>;

  listDashboardActivityAccount(
    request: activity_account_pb.ListDashboardActivityAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: activity_account_pb.ListDashboardActivityAccountResponse) => void): grpcWeb.ClientReadableStream<activity_account_pb.ListDashboardActivityAccountResponse>;

  listDashboardActivityAccount(
    request: activity_account_pb.ListDashboardActivityAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: activity_account_pb.ListDashboardActivityAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardActivityAccountService/ListDashboardActivityAccount',
        request,
        metadata || {},
        this.methodDescriptorListDashboardActivityAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardActivityAccountService/ListDashboardActivityAccount',
    request,
    metadata || {},
    this.methodDescriptorListDashboardActivityAccount);
  }

  methodDescriptorListDashboardActivityUniqAccount = new grpcWeb.MethodDescriptor(
    '/proto.DashboardActivityAccountService/ListDashboardActivityUniqAccount',
    grpcWeb.MethodType.UNARY,
    activity_account_pb.ListDashboardActivityUniqAccountRequest,
    activity_account_pb.ListDashboardActivityUniqAccountResponse,
    (request: activity_account_pb.ListDashboardActivityUniqAccountRequest) => {
      return request.serializeBinary();
    },
    activity_account_pb.ListDashboardActivityUniqAccountResponse.deserializeBinary
  );

  listDashboardActivityUniqAccount(
    request: activity_account_pb.ListDashboardActivityUniqAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<activity_account_pb.ListDashboardActivityUniqAccountResponse>;

  listDashboardActivityUniqAccount(
    request: activity_account_pb.ListDashboardActivityUniqAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: activity_account_pb.ListDashboardActivityUniqAccountResponse) => void): grpcWeb.ClientReadableStream<activity_account_pb.ListDashboardActivityUniqAccountResponse>;

  listDashboardActivityUniqAccount(
    request: activity_account_pb.ListDashboardActivityUniqAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: activity_account_pb.ListDashboardActivityUniqAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardActivityAccountService/ListDashboardActivityUniqAccount',
        request,
        metadata || {},
        this.methodDescriptorListDashboardActivityUniqAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardActivityAccountService/ListDashboardActivityUniqAccount',
    request,
    metadata || {},
    this.methodDescriptorListDashboardActivityUniqAccount);
  }

  methodDescriptorPersonalListDashboardActivityUniqAccount = new grpcWeb.MethodDescriptor(
    '/proto.DashboardActivityAccountService/PersonalListDashboardActivityUniqAccount',
    grpcWeb.MethodType.UNARY,
    activity_account_pb.PersonalListDashboardActivityUniqAccountRequest,
    activity_account_pb.PersonalListDashboardActivityUniqAccountResponse,
    (request: activity_account_pb.PersonalListDashboardActivityUniqAccountRequest) => {
      return request.serializeBinary();
    },
    activity_account_pb.PersonalListDashboardActivityUniqAccountResponse.deserializeBinary
  );

  personalListDashboardActivityUniqAccount(
    request: activity_account_pb.PersonalListDashboardActivityUniqAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<activity_account_pb.PersonalListDashboardActivityUniqAccountResponse>;

  personalListDashboardActivityUniqAccount(
    request: activity_account_pb.PersonalListDashboardActivityUniqAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: activity_account_pb.PersonalListDashboardActivityUniqAccountResponse) => void): grpcWeb.ClientReadableStream<activity_account_pb.PersonalListDashboardActivityUniqAccountResponse>;

  personalListDashboardActivityUniqAccount(
    request: activity_account_pb.PersonalListDashboardActivityUniqAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: activity_account_pb.PersonalListDashboardActivityUniqAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardActivityAccountService/PersonalListDashboardActivityUniqAccount',
        request,
        metadata || {},
        this.methodDescriptorPersonalListDashboardActivityUniqAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardActivityAccountService/PersonalListDashboardActivityUniqAccount',
    request,
    metadata || {},
    this.methodDescriptorPersonalListDashboardActivityUniqAccount);
  }

}

