import { GetterTree, MutationTree, ActionTree } from 'vuex'
import {UpdateInfoApi} from "@/components/modules/projects/services/api/UpdateInfoApi";

const updateInfo = new UpdateInfoApi()

class State {
    infoList: any
    isInfoList: boolean = false
}

const getters = <GetterTree<State, any>> {
    getInfoList(state) {
        return state.infoList
    },

    isInfoList(state) {
        return state.isInfoList
    },
}

const mutations = <MutationTree<State>> {
    setInfoList(state, payload) {
        state.infoList = payload
    },

    setIsInfoList(state, payload) {
        state.isInfoList = payload
    },
}

const actions = <ActionTree<State, any>> {
    getUpdateInfoList({ commit }) {
        commit('setIsInfoList', true)

        return new Promise((resolve, reject) => {
            updateInfo
                .getUpdateInfo()
                .then((res) => {
                    const items = res?.getItemsList?.() || []

                    commit('setIsInfoList', false)
                    commit('setInfoList', items)

                    resolve(items)
                })
                .catch((err) => {
                    commit('setIsInfoList', false)
                    commit('setInfoList', [])

                    reject(err)
                })
        })
    }
}

const updateInfoModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters,
}

export default updateInfoModule
