import { RouteRecordRaw } from 'vue-router'

const routes:RouteRecordRaw[] = [
    {
        path: '/task-builder',
        name: 'ManualTaskBuilder',
        component: () => import('../view/ManualBuilderView.vue'),
        meta: {
            breadCrumb: [
              { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
              { text:'Рекомендательная система', to: { name: 'RecSystem' } },
              { text: 'Конструктор ручных заданий' },
            ]
          },
    },
]

export default {
    routes
}
