import script from "./ClsAnswers.vue?vue&type=script&setup=true&lang=ts"
export * from "./ClsAnswers.vue?vue&type=script&setup=true&lang=ts"

import "./ClsAnswers.vue?vue&type=style&index=0&id=44acf654&lang=scss"

const __exports__ = script;

export default __exports__
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';import ClosePopup from 'quasar/src/directives/close-popup/ClosePopup.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCardSection,QSelect,QItem,QItemSection,QTooltip,QInput,QIcon,QTree,QInnerLoading,QSeparator,QBtn,QMenu,QList,QDialog});qInstall(script, 'directives', {ClosePopup});
