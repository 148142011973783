import { DashboardMetricServiceClient } from '../../proto/pb/MetricServiceClientPb'
import {
  ListDashboardMetricWithItemsRequest,
  DashboardMetricFilter,
  DashboardMetricFilterItem,
  DashboardMetricSort,
  DashboardMetricPagination,
} from '../../proto/pb/metric_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class MetricApi {
  client: DashboardMetricServiceClient
  metadata: any

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? ''
    this.client = new DashboardMetricServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  createFilter(data: any) {
    const filter = new DashboardMetricFilter()
    if (data && data.length) {
      for (const i in data) {
        if (data[i].field_name && '' !== data[i].field_name) {
          const fItem = new DashboardMetricFilterItem()
          fItem.setFieldName(data[i].field_name.toString())
          fItem.setOperand(
            data[i].operand !== undefined ? data[i].operand : '='
          )
          fItem.setValue(
            data[i].value !== undefined ? data[i].value.toString() : ''
          )
          fItem.setOr(this.createFilter(data[i].or)) // Рекурсия !!!
          filter.addItems(fItem)
        }
      }
    }
    return filter
  }

  createSort(data: any) {
    const sort = new DashboardMetricSort()
    const name: string = data.name !== undefined ? data.name.toString() : ''
    const exp: string = data.exp !== undefined ? data.exp.toString() : 'asc'

    // Устанавливаем параметры сортировки
    sort.setName(name)
    sort.setExp(exp)

    return sort
  }

  createPagination(data: any) {
    const pagination = new DashboardMetricPagination()
    const page: number = data.page !== undefined ? Number(data.page) : 1
    const limit: number = data.limit !== undefined ? Number(data.limit) : 1000
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0

    // Устанавливаем пагинацию
    pagination.setPage(page)
    pagination.setLimit(limit)
    pagination.setPages(pages).setCnt(cnt)

    return pagination
  }

  getMetric(filter: any, sort: any, pagination: any) {
    const request = new ListDashboardMetricWithItemsRequest()

    request
      .setFilter(this.createFilter(filter))
      .setSort(this.createSort(sort))
      .setPagination(this.createPagination(pagination))

    return this.client.listDashboardMetricWithItems(request, this.metadata)
  }
}
