import {
  AnswerClassServiceClient
} from "../../proto/pb/Answer_classServiceClientPb";
import {
  AnswerClass, 
  ListAnswerClassRequest,
  //ItemAnswerClassRequest,
  AnswerClassFilter,
  AnswerClassFilterItem,
  AnswerClassSort,
  AnswerClassPagination,
  CreateAnswerClassRequest,  
  UpdateAnswerClassRequest, 
  DeleteAnswerClassRequest,
  SaveAnswerClassRequest,
  RemoveAnswerClassRequest
} from "../../proto/pb/answer_class_pb";
import {AuthApi} from "@/components/modules/users/services/api/AuthApi";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 1000;

export class AnswerClassApi {
  items: Array<AnswerClass> = [];
  client: AnswerClassServiceClient;
  metadata: any

  constructor() {
    const host:string = (window as any).VUE_APP_API_URL ?? '';
    this.client = new AnswerClassServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  // Установка и получение фильтров
  createFilter(data: any){

    const filter = new AnswerClassFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
        for (const i in data) {
            if (data[i].field_name && ('' !== data[i].field_name)) {
                const fItem = new AnswerClassFilterItem();
                fItem.setFieldName(data[i].field_name.toString());
                fItem.setOperand(data[i].operand !== undefined ? data[i].operand : '=');
                fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                filter.addItems(fItem);
            }
        }
    }

    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any){

    const sort = new AnswerClassSort();
    const name:string = data.name !== undefined ? data.name.toString() : 'answer_grp_id';
    const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any){

    const pagination = new AnswerClassPagination();
    const page:number = data.page !== undefined ? Number(data.page) : 1;
    const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages:number = data.pages !== undefined ? Number(data.pages) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  // Список элементов
  getAnswerClassItems(filter: any, sort: any, pagination: any){

    const request = new ListAnswerClassRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));

    return this.client.listAnswerClass(request, this.metadata)
        .then((message) => {
            return message.getItemsList()
        })
  }

  // Создание элемента
  createAnswerClassItem(data: any){

    const request = new CreateAnswerClassRequest();
    request.setClassId(data.class_id);
    request.setAnswerGrpId(data.answer_grp_id);
    request.setNotes(data.Notes);

    return this.client.createAnswerClass(request, this.metadata)
  }

  // Редактирование элемента
  updateAnswerClassItem(data: any){

    const request = new UpdateAnswerClassRequest();
    request.setClassId(data.class_id);
    request.setAnswerGrpId(data.answer_grp_id);
    request.setNotes(data.Notes);

    return this.client.updateAnswerClass(request, this.metadata)
  }

  // Удаление элемента
  deleteAnswerClassItem(data: any){

    const request = new DeleteAnswerClassRequest();
    request.setClassId(data.class_id);

    return this.client.deleteAnswerClass(request, this.metadata)
  }

  // Сохранение нескольких элементов
  saveAnswerClass(data: any){

    const request = new SaveAnswerClassRequest();
    const aItems: Array<AnswerClass> = [];

    request.setClassId(data.class_id);

    if (data.items && (data.items.length > 0)) {
        for (const i in data.items) {
            const aItem = new AnswerClass();
            aItem.setClassId(data.class_id);
            aItem.setAnswerGrpId(data.items[i].answer_grp_id);
            aItem.setNotes(data.items[i].notes.toString());
            aItems.push(aItem);
        }
    }

    request.setItemsList(aItems);

    return this.client.saveAnswerClass(request, this.metadata);
  }

  // Удаление нескольких элементов
  removeAnswerClass(data: any){

    const request = new RemoveAnswerClassRequest();
    const aItems: Array<AnswerClass> = [];

    request.setClassId(data.class_id);

    if (data.items && (data.items.length > 0)) {
        for (const i in data.items) {
            const aItem = new AnswerClass();
            aItem.setClassId(data.class_id);
            aItem.setAnswerGrpId(data.items[i].answer_grp_id);
            aItem.setNotes(data.items[i].notes.toString());
            aItems.push(aItem);
        }
    }

    request.setItemsList(aItems);

    return this.client.removeAnswerClass(request, this.metadata)
  }
}
