import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row q-pa-md overflow-auto" }
const _hoisted_2 = { class: "col-12 q-pb-lg" }
const _hoisted_3 = { class: "row q-pb-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectsChoice = _resolveComponent("ProjectsChoice")!
  const _component_IntersectionUsersChart = _resolveComponent("IntersectionUsersChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ProjectsChoice, {
          "for-intersections": "",
          inline: "",
          "projects-limit": 3
        }),
        _createVNode(_component_IntersectionUsersChart)
      ])
    ])
  ]))
}