<template>
  <div v-if="getProjectsInfoItems" style="width: 100%; position: relative">
    <highcharts class="hc" :options="chartOptions" ref="chart" />
    <q-inner-loading
        :showing="isIntersectionListBusy"
        color="grey"
    />
  </div>
</template>

<script setup>
import store from '@/store'
import {computed, defineProps, onMounted, ref, toRaw, watch} from 'vue'

const props = defineProps({
  disableClick: Boolean,
  peace: Number,
  dateMin: String,
  dateMax: String,
  filters: Array,
})

const filtersNew = ref([])
const chartOptions = ref({
  chart: {
    type: 'column',
  },
  title: {
    text: 'Количество регистраций в проектах',
  },
  legend: { enabled: false },
  xAxis: {
    categories: [],
    crosshair: false,
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Количество пользователей',
    },
  },
  tooltip: {
    headerFormat:
        `<span style="font-size:10px">{point.key}</span><table>`,
    pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
    footerFormat: '</table>',
    shared: true,
    useHTML: true,
  },
  series: [
    {
      name: 'Количество',
      colorByPoint: true,
      data: [],
      dataLabels: {
        enabled: true,
        formatter: function () {
          return this.y.toLocaleString() + ' (' + this.point.proc + '%)'
        }
      },
    },
  ],
})

const chartColors = {
  'Активный гражданин': '#057b6c',
  'Миллион призов': '#744cc4',
  'Наш город': '#e344a4',
  'Электронный дом': '#e17833',
  'Город идей': '#3494ef',
  'Город заданий': '#dab61b'
}

const getProjectsInfoItems = computed(() => {
  const items = store.getters.getIntersectionUsersList
  const filteredList = []
  const catList = []

  if (items !== undefined) {
    items.sort((a, b) => b.getAccountCount() - a.getAccountCount())
    for (let projectItem of items) {
      filteredList.push({
        name: projectItem.getProjectName(),
        y: projectItem.getAccountCount(),
        color: chartColors[projectItem.getProjectName()] || '',
        proc: Math.round((projectItem.getAccountCount() / props.peace) * 100)
      })
      catList.push(projectItem.getProjectName())
    }
  }

  return {filteredList, catList}
})

const isIntersectionListBusy = computed(() => store.getters.isIntersectionUsersListBusy)

watch(getProjectsInfoItems, newValue =>  {
  chartOptions.value.series[0].data = newValue.filteredList
  chartOptions.value.xAxis.categories = newValue.catList
})

watch(props.peace, function (newValue) {
  return newValue
})

watch(props.filters, newValue => filtersNew.value = newValue)

const loadProjectInfoItems = () => {
  store
      .dispatch('getListIntersectionUsers', {
        date: {
          activity: toRaw(props.filters['Активность']),
          loyalty: toRaw(props.filters['Лояльность']),
          useful: toRaw(props.filters['Полезность']),
          interest: toRaw(props.filters['Заинтересованность']),
          date_begin: props.dateMin,
          date_end: props.dateMax,
        }
      })
      .then(
          (resolve) => {
            console.log('getProjectInfoItems resolve', resolve)
          },
          (reject) => {
            console.log('getProjectInfoItems reject', reject)
          }
      )
}

onMounted(() => {
  loadProjectInfoItems()
})
</script>
