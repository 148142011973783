import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { AnswerGrpApi } from '../services/api/AnswerGrpApi';
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
  isAnswerGrpBusy: boolean = false;
  isAnswerGrpResultBusy: boolean = false;
  isAnswerGrpChildsBusy: boolean = false;
  isAnswerGrpWorkedBusy: boolean = false;
  answerGrpResultItems: unknown [];
  answerGrpChildsItems: unknown [];
  answerGrpWorkedItems: unknown [];
  answerGrpItem: unknown;
  answerGrpWorkedItem: unknown;
  currentAnswerResult: any = null;
  separateBusy: boolean = false
}

// Создаем объект
const answerGrpApi = new AnswerGrpApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

  isAnswerGrpBusy(state): boolean {
    return state.isAnswerGrpBusy;
  },

  isAnswerGrpResultBusy(state): boolean {
    return state.isAnswerGrpResultBusy;
  },

  isAnswerGrpChildsBusy(state): boolean {
    return state.isAnswerGrpChildsBusy;
  },

  isAnswerGrpWorkedBusy(state): boolean {
    return state.isAnswerGrpWorkedBusy;
  },

  getAnswerGrpResultItems(state): unknown[] {
    return state.answerGrpResultItems;
  },

  getAnswerGrpChildsItems(state): unknown[] {
    return state.answerGrpChildsItems;
  },

  getAnswerGrpWorkedItems(state): unknown[] {
    return state.answerGrpWorkedItems;
  },

  getAnswerGrpItem(state): unknown {
    return state.answerGrpItem;
  },

  getAnswerGrpWorkedItem(state): unknown {
    return state.answerGrpWorkedItem;
  },

  getCurrentAnswerResult(state): any {
    return state.currentAnswerResult;
  },

  isSeparateBusy(state) {
    return state.separateBusy
  },
};

// Создаем мутации
const mutations = <MutationTree<State>>{

  setIsAnswerGrpBusy(state, payload: boolean) {
    state.isAnswerGrpBusy = payload
  },

  setIsAnswerGrpResultBusy(state, payload: boolean) {
    state.isAnswerGrpResultBusy = payload
  },

  setIsAnswerGrpChildsBusy(state, payload: boolean) {
    state.isAnswerGrpChildsBusy = payload
  },

  setIsAnswerGrpWorkedBusy(state, payload: boolean) {
    state.isAnswerGrpWorkedBusy = payload
  },

  setAnswerGrpResultItems(state, payload: unknown[]) {
    state.answerGrpResultItems = payload
  },

  setAnswerGrpChildsItems(state, payload: unknown[]) {
    state.answerGrpChildsItems = payload
  },

  setAnswerGrpWorkedItems(state, payload: unknown[]) {
    state.answerGrpWorkedItems = payload
  },

  setAnswerGrpItem(state, payload: unknown) {
    state.answerGrpItem = payload
  },

  setAnswerGrpWorkedItem(state, payload: unknown) {
    state.answerGrpWorkedItem = payload
  },

  setCurrentAnswerResult(state, payload: any) {
    state.currentAnswerResult = payload
  },

  setIsSeparateBusy(state, payload) {
    state.separateBusy = payload
  },
};

// Создаем действия
const actions = <ActionTree<State, any>>{

  // Устанавливаем элемент
  setAnswerGrpItem({ commit }, data: any) {
    commit('setAnswerGrpItem', data);
  },
  
  // Устанавливаем элемент рабочий
  setAnswerGrpWorkedItem({ commit }, data: any) {
    commit('setAnswerGrpWorkedItem', data);
  },
  
  // Устанавливаем текущий ответ в левой панели
  setCurrentAnswerResult({ commit }, data: any) {
    commit('setCurrentAnswerResult', data);
  },
    // Список элементов (результирующая таблица, вместе с реальным hasChilds)
  getAnswerGrpResultItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerGrpBusy', true);
    commit('setIsAnswerGrpResultBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
    const question_id:any = data !== undefined && data.question_id !== undefined ? data.question_id : 0;

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      answerGrpApi.getAnswerGrpResultItems(filter, sort, pagination, question_id)
        .then(
          (message:any) => {
            const items = message.getItemsList();
            const sort = message.getParams().getSort();
            const pagination = message.getParams().getPagination();
            commit('setAnswerGrpResultItems', items);
            commit('setIsAnswerGrpBusy', false);
            commit('setIsAnswerGrpResultBusy', false);
            resolve({
                items: items,
                sort: sort,
                pagination: pagination
            });
          },
          (error) => {
            commit('setAnswerGrpResultItems', []);
            commit('setIsAnswerGrpBusy', false);
            commit('setIsAnswerGrpResultBusy', false);
            reject(error);
          }
        );
    });
  },

    // Список элементов (результирующая таблица, вместе с реальным hasChilds)
    // с поиском страницы для заданного ответа
  getAnswerGrpWithFindAnswerResultItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerGrpBusy', true);
    commit('setIsAnswerGrpResultBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
    const question_id:any = data !== undefined && data.question_id !== undefined ? data.question_id : 0;
    const find_id:any = data !== undefined && data.find_id !== undefined ? data.find_id : 0;

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      answerGrpApi.getAnswerGrpWithFindAnswerResultItems(filter, sort, pagination, question_id, find_id)
        .then(
          (message:any) => {
            const items = message.getItemsList();
            const sort = message.getParams().getSort();
            const pagination = message.getParams().getPagination();
            commit('setAnswerGrpResultItems', items);
            commit('setIsAnswerGrpBusy', false);
            commit('setIsAnswerGrpResultBusy', false);
            resolve({
                items: items,
                sort: sort,
                pagination: pagination
            });
          },
          (error) => {
            commit('setAnswerGrpResultItems', []);
            commit('setIsAnswerGrpBusy', false);
            commit('setIsAnswerGrpResultBusy', false);
            reject(error);
          }
        );
    });
  },

  // Список дочерних элементов (часть результирующей таблицы, с hasChilds = false)
  getAnswerGrpChildsItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerGrpBusy', true);
    commit('setIsAnswerGrpChildsBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
    const parent_id:any = data !== undefined && data.parent_id !== undefined ? data.parent_id : 0;

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      answerGrpApi.getAnswerGrpChildsItems(filter, sort, pagination, parent_id)
        .then(
          (message:any) => {
            const items = message.getItemsList();
            const sort = message.getParams().getSort();
            const pagination = message.getParams().getPagination();
            commit('setAnswerGrpChildsItems', items);
            commit('setIsAnswerGrpBusy', false);
            commit('setIsAnswerGrpChildsBusy', false);
            resolve({
                items: items,
                sort: sort,
                pagination: pagination
            });
          },
          (error) => {
            commit('setAnswerGrpChildsItems', []);
            commit('setIsAnswerGrpBusy', false);
            commit('setIsAnswerGrpChildsBusy', false);
            reject(error);
          }
        );
    });
  },

  // Список элементов (таблица для обработки, с hasChilds = false)
  getAnswerGrpWorkedItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerGrpBusy', true);
    commit('setIsAnswerGrpWorkedBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
    const question_id:any = data !== undefined && data.question_id !== undefined ? data.question_id : 0;

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      answerGrpApi.getAnswerGrpWorkedItems(filter, sort, pagination, question_id)
        .then(
          (message:any) => {
            const items = message.getItemsList();
            const sort = message.getParams().getSort();
            const pagination = message.getParams().getPagination();
            commit('setAnswerGrpWorkedItems', items);
            commit('setIsAnswerGrpBusy', false);
            commit('setIsAnswerGrpWorkedBusy', false);
            resolve({
                items: items,
                sort: sort,
                pagination: pagination
            });
          },
          (error) => {
            commit('setAnswerGrpWorkedItems', []);
            commit('setIsAnswerGrpBusy', false);
            commit('setIsAnswerGrpWorkedBusy', false);
            reject(error);
          }
        );
    });
  },

  // Создать элемент
  createAnswerGrpItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerGrpBusy', true);

    return new Promise((resolve, reject) => {

      // Создаем элемент
      answerGrpApi.createAnswerGrpItem(data)
        .then(
          (response) => {
            commit('setIsAnswerGrpBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsAnswerGrpBusy', false);
            reject(error);
          }
        );
    });
  },

  // Обновить элемент
  updateAnswerGrpItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerGrpBusy', true);

    return new Promise((resolve, reject) => {

      // Обновляем элемент
      answerGrpApi.updateAnswerGrpItem(data)
        .then(
          (response) => {
            commit('setIsAnswerGrpBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsAnswerGrpBusy', false);
            reject(error);
          }
        );
    });
  },

  // Обновить текст элемента
  updateTextAnswerGrpItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerGrpBusy', true);

    return new Promise((resolve, reject) => {

      // Обновляем элемент
      answerGrpApi.updateTextAnswerGrpItem(data)
        .then(
          (response) => {
            commit('setIsAnswerGrpBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsAnswerGrpBusy', false);
            reject(error);
          }
        );
    });
  },

  // Удалить элемент
  deleteAnswerGrpItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerGrpBusy', true);

    return new Promise((resolve, reject) => {

      // Удаляем элемент
      answerGrpApi.deleteAnswerGrpItem(data)
        .then(
          (response) => {
            commit('setIsAnswerGrpBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsAnswerGrpBusy', false);
            reject(error);
          }
        );
    });
  },

  // Сведение ответов к одному (reduce)
  reduceAnswerGrpItems({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerGrpBusy', true);
    commit('setIsAnswerGrpWorkedBusy', true);

    return new Promise((resolve, reject) => {

      // Создаем элемент
      answerGrpApi.reduceAnswerGrpItems(data)
        .then(
          (response) => {
            commit('setIsAnswerGrpBusy', false);
            commit('setIsAnswerGrpWorkedBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsAnswerGrpBusy', false);
            commit('setIsAnswerGrpWorkedBusy', false);
            reject(error);
          }
        );
    });
  },

  // Разбиение ответов по разделителю (split)
  splitAnswerGrpItems({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerGrpBusy', true);
    commit('setIsAnswerGrpWorkedBusy', true);

    return new Promise((resolve, reject) => {

      // Создаем элемент
      answerGrpApi.splitAnswerGrpItems(data)
        .then(
          (response) => {
            commit('setIsAnswerGrpBusy', false);
            commit('setIsAnswerGrpWorkedBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsAnswerGrpBusy', false);
            commit('setIsAnswerGrpWorkedBusy', false);
            reject(error);
          }
        );
    });
  },

    separateAnswerGrpItems({ commit }, data) {

        commit('setIsSeparateBusy', true);

        return new Promise((resolve, reject) => {

            // Создаем элемент
            answerGrpApi.separateAnswerGrpItems(data)
                .then(
                    (response) => {
                        commit('setIsSeparateBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsSeparateBusy', false);
                        console.log('SEPARATE ANSWERS ERROR', error)
                        reject(error);
                    }
                );
        });
    }
};

// Создаем модуль
const AnswerGrpModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default AnswerGrpModule;
