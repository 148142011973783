import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { MetricValueApi } from '../services/api/MetricValueApi'
// import Loading from 'quasar/src/plugins/loading/Loading.js';

class State {
  metricValueItems: unknown[]
  metricAssociation: Object
}

// Создаем объект
const metricValueApi = new MetricValueApi()

// Создаем геттеры
const getters = <GetterTree<State, any>>{
  getMetricValueItems(state): unknown[] {
    return state.metricValueItems
  },

  getMetricValuesAssociation(state) {
    return state.metricAssociation
  },
}

// Создаем мутации
const mutations = <MutationTree<State>>{
  setMetricValueItems(state, payload: unknown[]) {
    state.metricValueItems = payload
  },

  setMetricAssociation(state, payload) {
    state.metricAssociation = payload
  },
}

// Создаем действия
const actions = <ActionTree<State, any>>{
  // Список элементов
  getMetricValueItems({ commit }, data: any) {
    commit('setIsProjectInfoBusy', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []
    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}
    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : {}

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      metricValueApi.getMetricValueItems(filter, sort, pagination).then(
        (response: any) => {
          commit('setMetricValueItems', response)
          const items = response
          const tempObj: any = {}
          for (const i of items) {
            tempObj[i.getMetricValueId()] = i.getMetricValueName()
          }
          commit('setMetricAssociation', tempObj)
          commit('setIsProjectInfoBusy', false)
          resolve(response)
        },
        (error) => {
          commit('setIsProjectInfoBusy', false)
          reject(error)
        }
      )
    })
  },
}

// Создаем модуль
const MetricValueModule: any = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default MetricValueModule
