
import store from '@/store'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'SplitFormSingle',

  components: {
    DataLoading,
  },

  props: {
    answer: Object as any,
  },

  data() {
    return {
      item: {
        id: 0,
        text: '',
        delimiter: '',
      },
    }
  },

  computed: {
    isAnswerGrpBusy() {
      return store.getters.isAnswerGrpBusy
    },

    isAnswerGrpWorkedBusy() {
      return store.getters.isAnswerGrpWorkedBusy
    },
  },

  methods: {
    splitAnswer() {
      if (!this.item.text) {
        console.log('Текст ответа не введен')
        return false
      }

      if (!this.item.delimiter) {
        console.log('Строка(символ) для разбивки не введен')
        return false
      }

      if (this.item.text.indexOf(this.item.delimiter) === -1) {
        console.log('Строка(символ) для разбивки отсутствует в тексте ответа')
        return false
      }

      if (this.item.text != this.answer.text) {
        let data = {
          id: this.item.id,
          text: this.item.text,
        }

        store.dispatch('updateTextAnswerGrpItem', data).then(
          (resolve) => {
            console.log('updateTextAnswerGrpItem resolve', resolve)
            this.actualSplitAnswer()
          },
          (reject) => {
            console.log('updateTextAnswerGrpItem reject', reject)
          }
        )
      } else {
        this.actualSplitAnswer()
      }
      return true
    },

    actualSplitAnswer() {
      let splitData = {
        split_string: this.item.delimiter,
        splitted_ids: [this.item.id],
      }
      store.dispatch('splitAnswerGrpItems', splitData).then(
        (resolve) => {
          console.log('splitAnswerGrpItems resolve', resolve)
          this.$emit('afterActionSplitSingle')
        },
        (reject) => {
          console.log('splitAnswerGrpItems reject', reject)
        }
      )
    },

    closeSplitFormSingle() {
      this.$emit('hideModal')
    },

    loadData() {
      this.item = {
        id: this.answer.id,
        text: this.answer.text,
        delimiter: '#'
      }
    },
  },

  created() {
    this.loadData()
  },
})
