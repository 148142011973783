import intersectionRegModule from './intersectionRegistration'
import dateMinMaxModule from './dateMinMax'
import dynamicRegModule from './dynamicRegistration'
import intersectionChartModule from './projectIntersection'
import metricModule from "@/components/modules/intersection/store/metric";
import activeListModule from "@/components/modules/intersection/store/activeList";
import activeCountModule from "@/components/modules/intersection/store/activeCount";
import activeAccountModule from "@/components/modules/intersection/store/activeAccount";
import loyaltyModule from "@/components/modules/activity/store/loyalty";
import dashboardPointModule from "@/components/modules/intersection/store/point";
import loyaltyIntersectionModule from "@/components/modules/intersection/store/loyalty-intersection";

export default {
    intersectionRegModule,
    dateMinMaxModule,
    dynamicRegModule,
    intersectionChartModule,
    metricModule,
    activeListModule,
    activeCountModule,
    activeAccountModule,
    loyaltyModule,
    dashboardPointModule,
    loyaltyIntersectionModule,
}