import {
  AnswerGrpClsFormServiceClient
} from "../../proto/pb/Answer_grp_cls_formServiceClientPb";
import {
  AnswerGrpClsQuestionForm, 
  AnswerGrpClsPollForm, 
  ListAnswerGrpClsQuestionFormRequest,
  ListAnswerGrpClsPollFormRequest,
  AnswerGrpClsFormFilter,
  AnswerGrpClsFormFilterItem
} from "../../proto/pb/answer_grp_cls_form_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class AnswerGrpClsFormApi {
  qitems: Array<AnswerGrpClsQuestionForm> = [];
  pitems: Array<AnswerGrpClsPollForm> = [];
  client: AnswerGrpClsFormServiceClient;
  metadata: any;

  constructor() {
    const host:string = (window as any).VUE_APP_API_URL ?? '';
    this.client = new AnswerGrpClsFormServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  // Установка и получение фильтров
  createFilter(data: any){

    const filter = new AnswerGrpClsFormFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
        for (const i in data) {
            if (data[i].field_name && ('' !== data[i].field_name)) {
                const fItem = new AnswerGrpClsFormFilterItem();
                fItem.setFieldName(data[i].field_name.toString());
                fItem.setOperand(data[i].operand !== undefined ? data[i].operand : '=');
                fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                filter.addItems(fItem);
            }
        }
    }

    return filter;
  }

  // Список ответов для выходной формы (для текущего вопроса)
  getAnswerGrpClsQuestionFormItems(filter: any, classifier_id: number, question_id: number){

    const request = new ListAnswerGrpClsQuestionFormRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setClassifierId(classifier_id);
    request.setQuestionId(question_id);

    return this.client.listAnswerGrpClsQuestionForm(request, this.metadata)
        .then((message) => {
            return message.getItemsList()
        })
  }

  // Список ответов для выходной формы (для опроса полностью)
  getAnswerGrpClsPollFormItems(filter: any, classifier_id: number, poll_id: number){

    const request = new ListAnswerGrpClsPollFormRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setClassifierId(classifier_id);
    request.setPollId(poll_id);

    return this.client.listAnswerGrpClsPollForm(request, this.metadata)
        .then((message) => {
            return message.getItemsList()
        })
  }
}
