import { GetterTree, MutationTree, ActionTree } from "vuex";
import { ProjectInfoApi } from "../services/api/ProjectInfoApi";
// import Loading from 'quasar/src/plugins/loading/Loading.js';

class State {
    projectInfoItems: unknown [];
    isProjectInfoBusy:Boolean = false;
}


// Создаем объект
const projectInfoApi = new ProjectInfoApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{
  
    getProjectInfoItems(state): unknown[] {
      return state.projectInfoItems;
    },
    isProjectInfoBusy(state){
      return state.isProjectInfoBusy
    },
};

// Создаем мутации
const mutations = <MutationTree<State>>{
  
    setProjectInfoItems(state, payload: unknown[]) {
      state.projectInfoItems = payload
    },
    setIsProjectInfoBusy(state,payload){
      state.isProjectInfoBusy = payload
      // if(payload){
      //   Loading.show()
      // }else{
      //   Loading.hide()
      // }
    }
};

// Создаем действия
const actions = <ActionTree<State, any>>{
    
  
    // Список элементов
    getProjectInfoItems({ commit }, data: any) {
      
      commit('setIsProjectInfoBusy',true)

      const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
      const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
      const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
  
      return new Promise((resolve, reject) => {

        // Получаем список элементов
        projectInfoApi.getProjectInfoItems(filter, sort, pagination)
          .then(
            (response:any) => {
              commit('setProjectInfoItems', response);
              commit('setIsProjectInfoBusy',false)
              resolve(response);
            },
            (error) => {
              commit('setIsProjectInfoBusy',false)
              reject(error);
            }
          );
      });
    },
};

// Создаем модуль
const ProjectInfoModule:any = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
  };
  
export default ProjectInfoModule;