import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { PointsApi } from '../services/api/PointsApi'
import Loading from 'quasar/src/plugins/loading/Loading.js';

const pointsApi = new PointsApi()

class State {
  earnedChartPagination: any
  spentChartPagination: any
  earnedPointsList: any = []
  spentPointsList: any = []
  isPointsBusy: boolean
  isCountPointsBusy: boolean
  accountsPoints: { earned: number; spent: number; current: number }
  isEarnedPointsBusy: boolean
  isSpentPointsBusy: boolean
  earnedChartList: boolean
  spentChartList: boolean
  isEarnedChartBusy: boolean
  isSpentChartBusy: boolean
  exportEarnedPointsList: any
  exportSpentPointsList: any
  isExportEarnedBusy: boolean
  isExportSpentBusy: boolean
  listPointBalance: any
  isListPointBalanceBusy: boolean
}

const getters = <GetterTree<State, any>>{
  getPointsEarnedList(state) {
    return state.earnedPointsList
  },

  getPointsSpentList(state) {
    return state.spentPointsList
  },

  getAccountPoints(state) {
    return state.accountsPoints
  },

  isPointsBusy(state) {
    return state.isPointsBusy
  },

  isCountPointsBusy(state) {
    return state.isPointsBusy
  },

  isEarnedPointsBusy(state) {
    return state.isEarnedPointsBusy
  },

  isSpentPointsBusy(state) {
    return state.isSpentPointsBusy
  },

  getEarnedChartList(state) {
    return state.earnedChartList
  },

  getSpentChartList(state) {
    return state.spentChartList
  },

  isEarnedChartBusy(state) {
    return state.isEarnedChartBusy
  },

  isSpentChartBusy(state) {
    return state.isSpentChartBusy
  },

  getExportEarnedPointsList(state) {
    return state.exportEarnedPointsList
  },

  getExportSpentPointsList(state) {
    return state.exportSpentPointsList
  },

  isExportEarnedBusy(state) {
    return state.isExportEarnedBusy
  },

  isExportSpentBusy(state) {
    return state.isExportSpentBusy
  },

  getListPointBalance(state) {
    return state.listPointBalance
  },

  isListPointBalanceBusy(state) {
    return state.isListPointBalanceBusy
  },

  getEarnedChartPagination(state) {
    return state.earnedChartPagination
  },

  getSpentChartPagination(state) {
    return state.spentChartPagination
  },
}

const mutations = <MutationTree<State>>{
  setEarnedChartPagination(state, payload) {
    state.earnedChartPagination = payload
  },

  setSpentChartPagination(state, payload) {
    state.spentChartPagination = payload
  },

  setEarnedPointsList(state, payload) {
    state.earnedPointsList = payload
  },

  setSpentPointsList(state, payload) {
    state.spentPointsList = payload
  },

  setIsPointBusy(state, payload) {
    state.isPointsBusy = payload
  },

  setIsCountPointsBusy(state, payload) {
    state.isPointsBusy = payload
  },

  setPointsSpentListItems(state, payload) {
    state.spentPointsList = payload
  },

  setAccountPoints(state, payload) {
    state.accountsPoints = payload
  },

  setIsEarnedPointsBusy(state, payload) {
    state.isEarnedPointsBusy = payload
  },

  setIsSpentPointsBusy(state, payload) {
    state.isSpentPointsBusy = payload
  },

  setEarnedChartList(state, payload) {
    state.earnedChartList = payload
  },

  setSpentChartList(state, payload) {
    state.spentChartList = payload
  },

  setIsEarnedChartBusy(state, payload) {
    state.isEarnedChartBusy = payload
  },

  setIsSpentChartBusy(state, payload) {
    state.isSpentChartBusy = payload
  },

  setExportEarnedPointsList(state, payload) {
    state.exportEarnedPointsList = payload
  },

  setExportSpentPointsList(state, payload) {
    state.exportSpentPointsList = payload
  },

  setIsExportEarnedBusy(state, payload) {
    state.isExportEarnedBusy = payload
  },

  setIsExportSpentBusy(state, payload) {
    state.isExportSpentBusy = payload
  },

  setListPointBalance(state, payload) {
    state.listPointBalance = payload
  },

  setIsListPointBalanceBusy(state, payload) {
    state.isListPointBalanceBusy = payload
  },
}

const actions = <ActionTree<State, any>>{
  getPointsEarnedList({ commit }, data) {
    commit('setIsEarnedPointsBusy', true)
    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : {}

    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : {}

    return new Promise((resolve, reject) => {
      pointsApi.getEarnedPointsList(filter, pagination, sort).then(
        (message: any) => {
          const items = message.getItemsList()
          const params = message.getParams().getPagination().getCnt()

          commit('setEarnedPointsList', items)
          commit('setIsEarnedPointsBusy', false)
          resolve({
            items,
            params,
          })
        },

        (error: any) => {
          commit('setEarnedPointsList', [])
          commit('setIsEarnedPointsBusy', false)
          reject(error)
          console.log('ERROR ', error)
        }
      )
    })
  },

  getPointsSpentList({ commit }, data) {
    commit('setIsSpentPointsBusy', true)
    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : {}

    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : {}

    return new Promise((resolve, reject) => {
      pointsApi.getSpentPointsList(filter, pagination, sort).then(
        (message: any) => {
          const items = message.getItemsList()
          const params = message.getParams().getPagination().getCnt()

          commit('setIsSpentPointsBusy', false)
          commit('setSpentPointsList', items)
          resolve({ items, params })
        },

        (error: any) => {
          commit('setIsSpentPointsBusy', false)
          commit('setSpentPointsList', [])
          reject(error)
          console.log('ERROR ', error)
        }
      )
    })
  },

  getCountPoints({ commit }, data) {
    commit('setIsCountPointsBusy', true)

    return new Promise((resolve, reject) => {
      pointsApi.getCountPoints(data).then(
        (message: any) => {
          const earned = message.getCntEarned()
          const spent = message.getCntSpent()
          const current = message.getCntCurrent()
          commit('setAccountPoints', { earned, spent, current })
          commit('setIsCountPointsBusy', false)
          resolve(message)
        },

        (error: any) => {
          commit('setAccountPoints', {})
          commit('setIsCountPointsBusy', false)
          reject(error)
        }
      )
    })
  },

  getEarnedChartList({ commit }, data) {
    commit('setIsEarnedChartBusy', true)
    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : {}

    return new Promise((resolve, reject) => {
      pointsApi.getEarnedChartList(filter).then(
        (message: any) => {
          const items = message.getItemsList()

          commit('setEarnedChartList', items)
          commit('setIsEarnedChartBusy', false)
          resolve(items)
        },

        (error: any) => {
          commit('setEarnedChartList', [])
          commit('setIsEarnedChartBusy', false)
          reject(error)
          console.log('ERROR ', error)
        }
      )
    })
  },

  getSpentChartList({ commit }, data) {
    commit('setIsSpentChartBusy', true)
    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : {}

    return new Promise((resolve, reject) => {
      pointsApi.getSpentChartList(filter).then(
        (message: any) => {
          const items = message.getItemsList()

          commit('setIsSpentChartBusy', false)
          commit('setSpentChartList', items)
          resolve(items)
        },

        (error: any) => {
          commit('setIsSpentChartBusy', false)
          commit('setSpentChartList', [])
          reject(error)
          console.log('ERROR ', error)
        }
      )
    })
  },

  getExportEarned({ commit }, data) {
    commit('setIsExportEarnedBusy', true)

    console.log('EXPORT EARNED', data)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []
    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}
    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : {}

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      pointsApi.getEarnedPointsList(filter, pagination, sort).then(
        (message: any) => {
          const items = message.getItemsList()
          const sort = message.getParams().getSort()
          const pagination = message.getParams().getPagination()

          commit('setExportEarnedPointsList', items)
          commit('setIsExportEarnedBusy', false)

          resolve({
            items: items,
            sort: sort,
            pagination: pagination,
          })
        },
        (error) => {
          commit('setExportEarnedPointsList', [])
          commit('setIsExportEarnedBusy', false)
          reject(error)
        }
      )
    })
  },

  getExportSpent({ commit }, data) {
    commit('setIsExportSpentBusy', true)

    console.log('EXPORT SPENT', data)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []
    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}
    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : {}

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      pointsApi.getSpentPointsList(filter, pagination, sort).then(
        (message: any) => {
          const items = message.getItemsList()
          const sort = message.getParams().getSort()
          const pagination = message.getParams().getPagination()

          commit('setExportSpentPointsList', items)
          commit('setIsExportSpentBusy', false)

          resolve({
            items: items,
            sort: sort,
            pagination: pagination,
          })
        },
        (error) => {
          commit('setExportSpentPointsList', [])
          commit('setIsExportSpentBusy', false)
          reject(error)
        }
      )
    })
  },

  getListPointBalance({ commit }, data) {
    commit('setIsListPointBalanceBusy', true)

    console.log('EXPORT SPENT', data)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      pointsApi.getListPointBalance(filter).then(
        (message: any) => {
          const items = message.getItemsList()

          commit('setListPointBalance', items)
          commit('setIsListPointBalanceBusy', false)

          resolve(items)
        },
        (error) => {
          commit('setListPointBalance', [])
          commit('setIsListPointBalanceBusy', false)
          reject(error)
        }
      )
    })
  },

  setSpentPointsListItems({ commit }, data) {
    commit('setPointsSpentListItems', data)
  },
}

const PointsModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default PointsModule
