import {
  AnswerGrpFormServiceClient
} from "../../proto/pb/Answer_grp_formServiceClientPb";
import {
  AnswerGrpForm, 
  ListAnswerGrpFormRequest,
  AnswerGrpFormFilter,
  AnswerGrpFormFilterItem,
  AnswerGrpFormSort
} from "../../proto/pb/answer_grp_form_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class AnswerGrpFormApi {
  items: Array<AnswerGrpForm> = [];
  client: AnswerGrpFormServiceClient;
  metadata: any;

  constructor() {
    const host:string = (window as any).VUE_APP_API_URL ?? '';
    this.client = new AnswerGrpFormServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  // Установка и получение фильтров
  createFilter(data: any){

    const filter = new AnswerGrpFormFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
        for (const i in data) {
            if (data[i].field_name && ('' !== data[i].field_name)) {
                const fItem = new AnswerGrpFormFilterItem();
                fItem.setFieldName(data[i].field_name.toString());
                fItem.setOperand(data[i].operand !== undefined ? data[i].operand : '=');
                fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                filter.addItems(fItem);
            }
        }
    }

    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any){

    const sort = new AnswerGrpFormSort();
    const name:string = data.name !== undefined ? data.name.toString() : 'answer_grp_text';
    const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  // Список ответов для выходной формы
  getAnswerGrpFormItems(filter: any, sort: any, question_id: number){

    const request = new ListAnswerGrpFormRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setQuestionId(question_id);

    return this.client.listAnswerGrpForm(request, this.metadata)
        .then((message) => {
            message.getItemsList()
        })
  }
}
