import { GetterTree, MutationTree, ActionTree } from "vuex";
import { ProjectIntersectionApi } from "../services/api/ProjectIntersectionApi";
import Loading from 'quasar/src/plugins/loading/Loading.js';

class State {
    projectIntersections: unknown [];
    isProjectIntersectionsBusy: Boolean = false;
}

// Создаем объект
const projectIntersectionApi = new ProjectIntersectionApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{
  
    getProjectIntersections(state): unknown[] {
      return state.projectIntersections;
    },
    isProjectIntersectionsBusy(state){
      return state.isProjectIntersectionsBusy
    }
};

// Создаем мутации
const mutations = <MutationTree<State>>{
  
    setProjectIntersections(state, payload: unknown[]) {
      state.projectIntersections = payload
    },
    setIsProjectIntersectionsBusy(state, payload: boolean) {
      state.isProjectIntersectionsBusy = payload
      if(payload){
        Loading.show()
      }else{
        Loading.hide()
      }
    
    },
};

// Создаем действия
const actions = <ActionTree<State, any>>{
    
    // Список элементов
    getProjectIntersections({ commit }, data: any) {
      commit('setIsProjectIntersectionsBusy', true);
  
      const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
      const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
      const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
  
      return new Promise((resolve, reject) => {
  
        // Получаем список элементов
        projectIntersectionApi.getProjectIntersections(filter, sort, pagination)
          .then(
            (response:any) => {
              commit('setProjectIntersections', response);
              commit('setIsProjectIntersectionsBusy', false);
              resolve(response);
            },
            (error) => {
              commit('setIsProjectIntersectionsBusy', false);
              reject(error);
            }
          );
      });
    },
};

// Создаем модуль
const ProjectIntersectionModule:any = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
  };
  
export default ProjectIntersectionModule;