import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { SegmentationApi } from '../../services/api/UserLoyalty/SegmentationApi'

class State {
  segmentationData: any
  isSegmentationBusy: Boolean
  dbData: any
  isDbData: Boolean
  isAccountListBusy: Boolean
  accountList: any
  dbscanList: any
  isDbscanList: Boolean
  isAccountExportListBusy: boolean = false
  isDbscanExportList: boolean = false
}

const segmentationApi = new SegmentationApi()

const getters = <GetterTree<State, any>>{
  getSegmentationData(state) {
    return state.segmentationData
  },

  getIsSegmentationBusy(state) {
    return state.isSegmentationBusy
  },

  getDbData(state) {
    return state.dbData
  },

  getIsDbData(state) {
    return state.isDbData
  },

  getIsAccountListBusy(state) {
    return state.isAccountListBusy
  },

  getAccountListMetrics(state) {
    return state.accountList
  },

  getDbscanList(state) {
    return state.dbscanList
  },

  getIsDbscanList(state) {
    return state.isDbscanList
  },

  isAccountExportListBusy(state) {
    return state.isAccountExportListBusy
  },

  isDbscanExportList(state) {
    return state.isDbscanExportList
  },
}

const mutations = <MutationTree<State>>{
  setSegmentationDate(state, payload) {
    state.segmentationData = payload
  },

  setIsSegmentationBusy(state, payload) {
    state.isSegmentationBusy = payload
  },

  setDbData(state, payload) {
    state.dbData = payload
  },

  setIsDbData(state, payload) {
    state.isDbData = payload
  },

  setIsAccountListBusty(state, payload: boolean) {
    state.isAccountListBusy = payload
  },

  setAccountList(state, payload) {
    state.accountList = payload
  },

  setDbscanList(state, payload) {
    state.dbscanList = payload
  },

  setIsDbscanList(state, payload) {
    state.isDbscanList = payload
  },

  setIsAccountExportListBusy(state, payload) {
    state.isAccountExportListBusy = payload
  },

  setIsDbscanExportList(state, payload) {
    state.isDbscanExportList = payload
  },
}

const actions = <ActionTree<State, any>>{
  getSegmentationData({ commit }, filter) {
    console.log(
      '🚀 ~ file: SegmentationMetric.ts:32 ~ getSegmentApi ~ filter:',
      filter
    )
    commit('setIsSegmentationBusy', true)

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      segmentationApi.getSegmentationValue(filter).then(
        (message: any) => {
          const item = message.getItemsList()

          console.log('itemssadas', item)

          commit('setSegmentationDate', item)
          commit('setIsSegmentationBusy', false)

          resolve(item)
        },
        (error) => {
          commit('setIsSegmentationBusy', false)

          reject(error)
        }
      )
    })
  },

  getDbData({ commit }, filter) {
    console.log(
      '🚀 ~ file: SegmentationMetric.ts:32 ~ getDbScanData ~ filter:',
      filter
    )
    commit('setIsDbData', true)

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      segmentationApi.getDbScanValue(filter).then(
        (message: any) => {
          const item = message.getItemsList()

          commit('setDbData', item)
          commit('setIsDbData', false)

          resolve(item)
        },
        (error) => {
          commit('setIsDbData', false)

          reject(error)
        }
      )
    })
  },

  getAccountListValues({ commit }, data) {
    commit('setIsAccountListBusty', true)

    return segmentationApi
      .getAccListActivityMetric(data.filter, data.pagination)
      .then((res: any) => {
        const items = res.getItemsList()
        commit('setAccountList', items)
        commit('setIsAccountListBusty', false)
        return res
      })
      .catch(() => {
        commit('setIsAccountListBusty', false)
      })
  },

  getAccountListValuesExport({ commit }, data) {
    commit('setIsAccountExportListBusy', true)

    return segmentationApi
      .getAccListActivityMetric(data.filter, data.pagination)
      .then((res: any) => {
        commit('setIsAccountExportListBusy', false)
        return res
      })
      .catch((err) => {
        commit('setIsAccountExportListBusy', false)
        return err
      })
  },

  getDbscanList({ commit }, data) {
    commit('setIsDbscanList', true)

    return segmentationApi
        .getAccListDbscanMetric(data.filter, data.pagination)
        .then((res: any) => {
          const items = res.getItemsList()
          commit('setDbscanList', items)
          commit('setIsDbscanList', false)

          console.log('DBSCAN RES', res)
          return res
        })
        .catch(() => {
          commit('setIsDbscanList', false)
        })
  },

  getDbscanExport({ commit }, data) {
    commit('setIsDbscanExportList', true)

    return segmentationApi
        .getAccListDbscanMetric(data.filter, data.pagination)
        .then((res: any) => {
          const items = res.getItemsList()
          commit('setDbscanList', items)
          commit('setIsDbscanExportList', false)

          console.log('DBSCAN RES', res)
          return res
        })
        .catch(() => {
          commit('setIsDbscanExportList', false)
        })
  }
}

const segmentationModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default segmentationModule
