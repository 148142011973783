import { DashboardProjectRegistrationServiceClient } from '../../proto/pb/Project_registrationServiceClientPb'
import {
    ListDashboardProjectRegistrationRequest,
    DashboardProjectRegistrationSort,
    DashboardProjectRegistrationPagination
} from '../../proto/pb/project_registration_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class DynamicApi {
    client: DashboardProjectRegistrationServiceClient
    metadata: any

    constructor() {
        const host: string = (window as any).VUE_APP_API_URL ?? ''
        this.client = new DashboardProjectRegistrationServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    createSort(data: any) {
        const sort = new DashboardProjectRegistrationSort()
        const name: string =
            data.name !== undefined ? data.name.toString() : 'project_id'
        const exp: string = data.exp !== undefined ? data.exp.toString() : 'asc'

        // Устанавливаем параметры сортировки
        sort.setName(name)
        sort.setExp(exp)

        return sort
    }

    createPagination(data: any) {
        const pagination = new DashboardProjectRegistrationPagination()
        const page: number = data.page !== undefined ? Number(data.page) : 1
        const limit: number =
            data.limit !== undefined ? Number(data.limit) : 100000
        const pages: number = data.pages !== undefined ? Number(data.pages) : 0
        const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0

        // Устанавливаем пагинацию
        pagination.setPage(page)
        pagination.setLimit(limit)
        pagination.setPages(pages).setCnt(cnt)

        return pagination
    }

    getDynamicChart(filter: any) {
        const request = new ListDashboardProjectRegistrationRequest()

        request
            .setSort(this.createSort({}))
            .setPagination(this.createPagination({}))
            .setProjectIdsList(filter.project_ids)
            .setTypeInterval(filter.type_interval)
            .setDateBegin(filter.date_begin)
            .setDateEnd(filter.date_end)
            .setActivityIdsList(filter.activity)
            .setLoyaltyIdsList(filter.loyalty)
            .setUsefulnessIdsList(filter.useful)
            .setInterestIdsList(filter.interest)
        console.log('1111111111111',request)

        return this.client.listDashboardProjectRegistration(request, this.metadata)
    }
}