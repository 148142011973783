import { GetterTree, MutationTree, ActionTree } from "vuex";
import { MessageClassApi } from "../services/api/MessageClassApi";
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
    isMessageClassBusy: boolean = false;
    messageClassItems: unknown [];
    messageClassItem: unknown;
}

// Создаем объект
const messageClassApi = new MessageClassApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

    isMessageClassBusy(state): boolean {
        return state.isMessageClassBusy;
    },

    getMessageClassItems(state): unknown[] {
        return state.messageClassItems;
    },

    getMessageClassItem(state): unknown {
        return state.messageClassItem;
    }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

    setIsMessageClassBusy(state, payload: boolean) {
        state.isMessageClassBusy = payload
    },

    setMessageClassItems(state, payload: unknown[]) {
        state.messageClassItems = payload
    },

    setMessageClassItem(state, payload: unknown) {
        state.messageClassItem = payload
    }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

    // Устанавливаем элемент
    setMessageClassItem({ commit }, data: any) {
        commit('setMessageClassItem', data);
    },

    // Список элементов (привязанные ответы)
    getMessageClassItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsMessageClassBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            messageClassApi.getMessageClassItems(filter, sort, pagination)
                .then(
                    (response:any) => {
                        commit('setMessageClassItems', response);
                        commit('setIsMessageClassBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setMessageClassItems', []);
                        commit('setIsMessageClassBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Создать элемент
    createMessageClassItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsMessageClassBusy', true);

        return new Promise((resolve, reject) => {

            // Создаем элемент
            messageClassApi.createMessageClassItem(data)
                .then(
                    (response) => {
                        commit('setIsMessageClassBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsMessageClassBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Обновить элемент
    updateMessageClassItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsMessageClassBusy', true);

        return new Promise((resolve, reject) => {

            // Обновляем элемент
            messageClassApi.updateMessageClassItem(data)
                .then(
                    (response) => {
                        commit('setIsMessageClassBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsMessageClassBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Удалить элемент
    deleteMessageClassItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsMessageClassBusy', true);

        return new Promise((resolve, reject) => {

            // Удаляем элемент
            messageClassApi.deleteMessageClassItem(data)
                .then(
                    (response) => {
                        commit('setIsMessageClassBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsMessageClassBusy', false);
                        reject(error);
                    }
                );
        });
    },

    saveMessageClass({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsMessageClassBusy', true);

        return new Promise((resolve, reject) => {

            // Сохраняем несколько элементов
            messageClassApi.saveMessageClass(data)
                .then(
                    (response:any) => {
                        commit('setIsMessageClassBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsMessageClassBusy', false);
                        reject(error);
                    }
                );
        });
    },

    removeMessageClass({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsMessageClassBusy', true);

        return new Promise((resolve, reject) => {

            // Сохраняем несколько элементов
            messageClassApi.removeMessageClass(data)
                .then(
                    (response:any) => {
                        commit('setIsMessageClassBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsMessageClassBusy', false);
                        reject(error);
                    }
                );
        });
    }
};

// Создаем модуль
const MessageClassModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default MessageClassModule;
