/* eslint-disable */
// source: project_intersection.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.proto.DashboardProjectIntersection', null, global);
goog.exportSymbol('proto.proto.DashboardProjectIntersectionFilter', null, global);
goog.exportSymbol('proto.proto.DashboardProjectIntersectionFilterItem', null, global);
goog.exportSymbol('proto.proto.DashboardProjectIntersectionPagination', null, global);
goog.exportSymbol('proto.proto.DashboardProjectIntersectionSort', null, global);
goog.exportSymbol('proto.proto.ListDashboardProjectIntersectionRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardProjectIntersectionResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardProjectIntersection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardProjectIntersection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardProjectIntersection.displayName = 'proto.proto.DashboardProjectIntersection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardProjectIntersectionFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardProjectIntersectionFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardProjectIntersectionFilterItem.displayName = 'proto.proto.DashboardProjectIntersectionFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardProjectIntersectionFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DashboardProjectIntersectionFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.DashboardProjectIntersectionFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardProjectIntersectionFilter.displayName = 'proto.proto.DashboardProjectIntersectionFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardProjectIntersectionSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardProjectIntersectionSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardProjectIntersectionSort.displayName = 'proto.proto.DashboardProjectIntersectionSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardProjectIntersectionPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardProjectIntersectionPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardProjectIntersectionPagination.displayName = 'proto.proto.DashboardProjectIntersectionPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardProjectIntersectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListDashboardProjectIntersectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardProjectIntersectionRequest.displayName = 'proto.proto.ListDashboardProjectIntersectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardProjectIntersectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardProjectIntersectionResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardProjectIntersectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardProjectIntersectionResponse.displayName = 'proto.proto.ListDashboardProjectIntersectionResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardProjectIntersection.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardProjectIntersection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardProjectIntersection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersection.toObject = function(includeInstance, msg) {
  var f, obj = {
    cnt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cntLife: jspb.Message.getFieldWithDefault(msg, 2, 0),
    intersection: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardProjectIntersection}
 */
proto.proto.DashboardProjectIntersection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardProjectIntersection;
  return proto.proto.DashboardProjectIntersection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardProjectIntersection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardProjectIntersection}
 */
proto.proto.DashboardProjectIntersection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCntLife(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntersection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardProjectIntersection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardProjectIntersection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardProjectIntersection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCntLife();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getIntersection();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 cnt = 1;
 * @return {number}
 */
proto.proto.DashboardProjectIntersection.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectIntersection} returns this
 */
proto.proto.DashboardProjectIntersection.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 cnt_life = 2;
 * @return {number}
 */
proto.proto.DashboardProjectIntersection.prototype.getCntLife = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectIntersection} returns this
 */
proto.proto.DashboardProjectIntersection.prototype.setCntLife = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string intersection = 3;
 * @return {string}
 */
proto.proto.DashboardProjectIntersection.prototype.getIntersection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectIntersection} returns this
 */
proto.proto.DashboardProjectIntersection.prototype.setIntersection = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardProjectIntersectionFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardProjectIntersectionFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersectionFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.DashboardProjectIntersectionFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardProjectIntersectionFilterItem}
 */
proto.proto.DashboardProjectIntersectionFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardProjectIntersectionFilterItem;
  return proto.proto.DashboardProjectIntersectionFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardProjectIntersectionFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardProjectIntersectionFilterItem}
 */
proto.proto.DashboardProjectIntersectionFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.DashboardProjectIntersectionFilter;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardProjectIntersectionFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardProjectIntersectionFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersectionFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.DashboardProjectIntersectionFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectIntersectionFilterItem} returns this
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectIntersectionFilterItem} returns this
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectIntersectionFilterItem} returns this
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DashboardProjectIntersectionFilter or = 4;
 * @return {?proto.proto.DashboardProjectIntersectionFilter}
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionFilter, 4));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionFilter|undefined} value
 * @return {!proto.proto.DashboardProjectIntersectionFilterItem} returns this
*/
proto.proto.DashboardProjectIntersectionFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DashboardProjectIntersectionFilterItem} returns this
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DashboardProjectIntersectionFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardProjectIntersectionFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardProjectIntersectionFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardProjectIntersectionFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersectionFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardProjectIntersectionFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardProjectIntersectionFilter}
 */
proto.proto.DashboardProjectIntersectionFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardProjectIntersectionFilter;
  return proto.proto.DashboardProjectIntersectionFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardProjectIntersectionFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardProjectIntersectionFilter}
 */
proto.proto.DashboardProjectIntersectionFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectIntersectionFilterItem;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardProjectIntersectionFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardProjectIntersectionFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardProjectIntersectionFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersectionFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardProjectIntersectionFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardProjectIntersectionFilterItem items = 1;
 * @return {!Array<!proto.proto.DashboardProjectIntersectionFilterItem>}
 */
proto.proto.DashboardProjectIntersectionFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardProjectIntersectionFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardProjectIntersectionFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardProjectIntersectionFilterItem>} value
 * @return {!proto.proto.DashboardProjectIntersectionFilter} returns this
*/
proto.proto.DashboardProjectIntersectionFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardProjectIntersectionFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardProjectIntersectionFilterItem}
 */
proto.proto.DashboardProjectIntersectionFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardProjectIntersectionFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DashboardProjectIntersectionFilter} returns this
 */
proto.proto.DashboardProjectIntersectionFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardProjectIntersectionSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardProjectIntersectionSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardProjectIntersectionSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersectionSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardProjectIntersectionSort}
 */
proto.proto.DashboardProjectIntersectionSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardProjectIntersectionSort;
  return proto.proto.DashboardProjectIntersectionSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardProjectIntersectionSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardProjectIntersectionSort}
 */
proto.proto.DashboardProjectIntersectionSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardProjectIntersectionSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardProjectIntersectionSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardProjectIntersectionSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersectionSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.DashboardProjectIntersectionSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectIntersectionSort} returns this
 */
proto.proto.DashboardProjectIntersectionSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.DashboardProjectIntersectionSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectIntersectionSort} returns this
 */
proto.proto.DashboardProjectIntersectionSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardProjectIntersectionPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardProjectIntersectionPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersectionPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardProjectIntersectionPagination}
 */
proto.proto.DashboardProjectIntersectionPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardProjectIntersectionPagination;
  return proto.proto.DashboardProjectIntersectionPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardProjectIntersectionPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardProjectIntersectionPagination}
 */
proto.proto.DashboardProjectIntersectionPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardProjectIntersectionPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardProjectIntersectionPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersectionPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectIntersectionPagination} returns this
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectIntersectionPagination} returns this
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectIntersectionPagination} returns this
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardProjectIntersectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardProjectIntersectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectIntersectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.DashboardProjectIntersectionFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.DashboardProjectIntersectionSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.DashboardProjectIntersectionPagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest}
 */
proto.proto.ListDashboardProjectIntersectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardProjectIntersectionRequest;
  return proto.proto.ListDashboardProjectIntersectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardProjectIntersectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest}
 */
proto.proto.ListDashboardProjectIntersectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectIntersectionFilter;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.DashboardProjectIntersectionSort;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.DashboardProjectIntersectionPagination;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardProjectIntersectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardProjectIntersectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectIntersectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardProjectIntersectionFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.DashboardProjectIntersectionSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.DashboardProjectIntersectionPagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional DashboardProjectIntersectionFilter filter = 1;
 * @return {?proto.proto.DashboardProjectIntersectionFilter}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionFilter, 1));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionFilter|undefined} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
*/
proto.proto.ListDashboardProjectIntersectionRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DashboardProjectIntersectionSort sort = 2;
 * @return {?proto.proto.DashboardProjectIntersectionSort}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionSort, 2));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionSort|undefined} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
*/
proto.proto.ListDashboardProjectIntersectionRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DashboardProjectIntersectionPagination pagination = 3;
 * @return {?proto.proto.DashboardProjectIntersectionPagination}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionPagination, 3));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionPagination|undefined} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
*/
proto.proto.ListDashboardProjectIntersectionRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardProjectIntersectionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardProjectIntersectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardProjectIntersectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardProjectIntersectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectIntersectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardProjectIntersection.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListDashboardProjectIntersectionRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardProjectIntersectionResponse}
 */
proto.proto.ListDashboardProjectIntersectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardProjectIntersectionResponse;
  return proto.proto.ListDashboardProjectIntersectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardProjectIntersectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardProjectIntersectionResponse}
 */
proto.proto.ListDashboardProjectIntersectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectIntersection;
      reader.readMessage(value,proto.proto.DashboardProjectIntersection.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListDashboardProjectIntersectionRequest;
      reader.readMessage(value,proto.proto.ListDashboardProjectIntersectionRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardProjectIntersectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardProjectIntersectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardProjectIntersectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectIntersectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardProjectIntersection.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListDashboardProjectIntersectionRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardProjectIntersection items = 1;
 * @return {!Array<!proto.proto.DashboardProjectIntersection>}
 */
proto.proto.ListDashboardProjectIntersectionResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardProjectIntersection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardProjectIntersection, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardProjectIntersection>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionResponse} returns this
*/
proto.proto.ListDashboardProjectIntersectionResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardProjectIntersection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardProjectIntersection}
 */
proto.proto.ListDashboardProjectIntersectionResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardProjectIntersection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionResponse} returns this
 */
proto.proto.ListDashboardProjectIntersectionResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListDashboardProjectIntersectionRequest params = 2;
 * @return {?proto.proto.ListDashboardProjectIntersectionRequest}
 */
proto.proto.ListDashboardProjectIntersectionResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListDashboardProjectIntersectionRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListDashboardProjectIntersectionRequest, 2));
};


/**
 * @param {?proto.proto.ListDashboardProjectIntersectionRequest|undefined} value
 * @return {!proto.proto.ListDashboardProjectIntersectionResponse} returns this
*/
proto.proto.ListDashboardProjectIntersectionResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectIntersectionResponse} returns this
 */
proto.proto.ListDashboardProjectIntersectionResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectIntersectionResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
