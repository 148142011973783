
import store from '@/store'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'UserInfo',

  components: {
    DataLoading,
  },

  props: {
    userGuid: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      item: {
        guid: '',
        points: 0,
      } as any,
    }
  },

  computed: {
    isUserBusy() {
      const userBusy =
        store.getters.isScorecardBusy ||
        store.getters.isScorecardValueAccountBusy
      return userBusy
    },

    getHeaderIds() {
      const headerIds = store.getters.getScorecardIds
      return headerIds ? headerIds : []
    },
  },

  methods: {
    getFieldIndex(hId: any) {
      return 'field_' + hId.toString()
    },

    calculateUserPoints() {
      console.log('calculateUserPoints')
      store
        .dispatch('calculateScorecardValueAccount', {
          filter: [
            {
              field_name: 'sva.account_guid',
              operand: '=',
              value: this.userGuid,
            },
          ],
        })
        .then(
          (resolve) => {
            console.log('calculateUserPoints resolve', resolve)
            this.loadUserItems()
          },
          (reject) => {
            console.log('calculateUserPoints reject', reject)
          }
        )
    },

    closeUserInfo() {
      this.$emit('hideModal')
    },

    loadItem() {
      const blankItem = {
        guid: '',
        points: 0,
      } as any
      const headerIds = store.getters.getScorecardIds
      for (const hId of headerIds) {
        blankItem['field_' + hId.toString()] = {
          label: '',
          value: '',
        } as any
      }

      this.item = Object.assign(blankItem)
      console.log('loadItem 1 this.item', this.item)

      const userItems = store.getters.getScorecardValueAccountMappedItems
      const headerItems = store.getters.getScorecardItems

      let findedItem: any = null
      for (let item of userItems) {
        if (this.userGuid === item.guid) {
          findedItem = item
          break
        }
      }

      if (null !== findedItem) {
        this.item.guid = findedItem.guid
        this.item.points = findedItem.points
        if (headerItems !== undefined) {
          for (let headerItem of headerItems) {
            const title = headerItem.getScorecardTitle()
            const id = headerItem.getScorecardId()
            this.item['field_' + id.toString()].label = title
            this.item['field_' + id.toString()].value =
              findedItem['field_' + id.toString()]
          }
        }
      }
      console.log('loadItem 2 this.item', this.item)
    },

    getFilters() {
      return []
    },

    loadUserItems() {
      const headerIds = store.getters.getScorecardIds
      store
        .dispatch('getScorecardValueAccountMappedItems', {
          filter: this.getFilters(),
          header: headerIds,
        })
        .then(
          (resolve) => {
            console.log('getScorecardValueAccountMappedItems resolve', resolve)
            this.loadItem()
          },
          (reject) => {
            console.log('getScorecardValueAccountMappedItems reject', reject)
          }
        )
    },

    loadData() {
      this.loadItem()
    },
  },

  created() {
    this.loadData()
  },
})
