import { AvailableEventServiceClient } from '../../proto/pb/Available_eventsServiceClientPb'
import {
    AvailableEvent,
    AvailableEventSort,
    ListAvailableEventRequest,
    AvailableEventPagination,
    ListAvailableEventProjectRequest,
    ListAvailableActionRequest,
} from '../../proto/pb/available_events_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

const defaultLimit: number = 1000

export class AvailableEventsApi {
    Actions: Array<AvailableEvent>
    client: AvailableEventServiceClient
    metadata: any

    constructor() {
        const host: string = (window as any).VUE_APP_API_URL ?? ''
        this.client = new AvailableEventServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    createSort(data: any) {
        const sort = new AvailableEventSort()
        const name: string =
            data.name !== undefined ? data.name.toString() : 'event_project_name'
        const exp: string = data.exp !== undefined ? data.exp.toString() : 'desc'

        // Устанавливаем параметры сортировки
        sort.setName(name)
        sort.setExp(exp)

        return sort
    }

    createPagination(data: any) {
        const pagination = new AvailableEventPagination()
        const page: number = data.page !== undefined ? Number(data.page) : 1
        const limit: number =
            data.limit !== undefined ? Number(data.limit) : defaultLimit
        const pages: number = data.pages !== undefined ? Number(data.pages) : 0
        const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0

        // Устанавливаем пагинацию
        pagination.setPage(page)
        pagination.setLimit(limit)
        pagination.setPages(pages)
        pagination.setCnt(cnt)

        return pagination
    }

    getAvailableEventsList(filter: any, pagination: any, sort: any = {}) {
        const request = new ListAvailableEventRequest()
        console.log('EVENT REQ ', filter, pagination)
        // Устанавливаем параметры запроса
        request
            .setActionNamesList(filter.action_names)
            .setDateBegin(filter.date_begin)
            .setDateEnd(filter.date_end)
            .setProjectIdsList(filter.project_ids)
            .setSsoId(filter.sso_id)
            .setPagination(this.createPagination(pagination))
            .setSort(this.createSort(sort))

        return this.client.listAvailableEvent(request, { Authorization: localStorage.getItem('access-key') } as any)
    }

    getAvailableEventsCount(filter: any, pagination: any, sort: any = {}) {
        const request = new ListAvailableEventRequest()
        // Устанавливаем параметры запроса
        request
            .setActionNamesList(filter.action_names)
            .setDateBegin(filter.date_begin)
            .setDateEnd(filter.date_end)
            .setProjectIdsList(filter.project_ids)
            .setSsoId(filter.sso_id)
            .setPagination(this.createPagination(pagination))
            .setSort(this.createSort(sort))

        return this.client.countAvailableEvent(request, { Authorization: localStorage.getItem('access-key') } as any)
    }

    getAvailableEventsProjects(data: ListAvailableEventProjectRequest.AsObject) {
        const request = new ListAvailableEventProjectRequest()
        request
            .setSsoId(data.ssoId)
            .setProjectIdsList(data.projectIdsList)
            .setDateBegin(data.dateBegin)
            .setDateEnd(data.dateEnd)

        return this.client.listAvailableEventProject(request, { Authorization: localStorage.getItem('access-key') } as any)
    }

    getAvailableEventsFilter(data: ListAvailableActionRequest.AsObject) {
        const request = new ListAvailableActionRequest()
        request
            .setProjectIdsList(data.projectIdsList)

        return this.client.listAvailableAction(request, { Authorization: localStorage.getItem('access-key') } as any)
    }
}
