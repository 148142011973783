import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, { class: "q-pa-xl q-mb-md" }, {
    default: _withCtx(() => [
      _createVNode(_component_highcharts, {
        class: "hc",
        options: _ctx.chartOptions,
        ref: "chart"
      }, null, 8, ["options"])
    ]),
    _: 1
  }))
}