import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "q-pa-md col-12 items-start q-gutter-md",
  style: {"height":"calc(100% - 140px)"}
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_DataLoading = _resolveComponent("DataLoading")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1024px","max-width":"80vw","height":"640px","max-height":"80vh"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5" }, {
                default: _withCtx(() => [
                  _createTextVNode("Новый системный пользователь")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      (!_ctx.isUsersListBusy)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_card, { style: {"height":"100%","overflow":"hidden"} }, {
              default: _withCtx(() => [
                _createVNode(_component_q_form, { style: {"height":"100%","overflow":"auto"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_card_section, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                          modelValue: _ctx.item.last_name,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.last_name) = $event)),
                          label: "Фамилия",
                          filled: "",
                          "lazy-rules": "",
                          rules: [(val) => (val && val.length > 0) || 'Поле должно быть заполнено'],
                          ref: "surnameRef",
                          onKeydown: _ctx.checkSpace
                        }, null, 8, ["modelValue", "rules", "onKeydown"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_card_section, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                          modelValue: _ctx.item.first_name,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.first_name) = $event)),
                          label: "Имя",
                          filled: "",
                          "lazy-rules": "",
                          rules: [(val) => (val && val.length > 0) || 'Поле должно быть заполнено'],
                          ref: "firstnameRef",
                          onKeydown: _ctx.checkSpace
                        }, null, 8, ["modelValue", "rules", "onKeydown"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_card_section, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                          modelValue: _ctx.item.middle_name,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.middle_name) = $event)),
                          label: "Отчество",
                          filled: "",
                          onKeydown: _ctx.checkSpace
                        }, null, 8, ["modelValue", "onKeydown"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_card_section, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                          modelValue: _ctx.item.email,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.email) = $event)),
                          label: "E-mail",
                          type: "email",
                          filled: "",
                          "lazy-rules": "",
                          rules: [(val) => (val && val.length > 0) || 'Поле должно быть заполнено', (val) => (val.includes('@')) || 'Введите корректный Email-адрес'],
                          ref: "emailAuthRef",
                          onKeydown: _ctx.checkSpace
                        }, null, 8, ["modelValue", "rules", "onKeydown"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_card_section, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_select, {
                          modelValue: _ctx.item.user_role,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.item.user_role) = $event)),
                          filled: "",
                          "input-debounce": "300",
                          options: _ctx.getUserRoles,
                          "option-value": "role",
                          "option-label": "role_name",
                          "options-dense": "",
                          "options-html": "",
                          label: "Роль в системе",
                          loading: _ctx.isUsersListBusy
                        }, null, 8, ["modelValue", "options", "loading"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_card_section, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                          modelValue: _ctx.item.username,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.item.username) = $event)),
                          label: "Логин",
                          filled: "",
                          "lazy-rules": "",
                          rules: [(val) => (val && val.length > 0) || 'Поле должно быть заполнено'],
                          ref: "loginAuthRef",
                          onKeydown: _ctx.checkSpace
                        }, null, 8, ["modelValue", "rules", "onKeydown"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_q_separator),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  color: "primary",
                  onClick: _ctx.saveUser
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Создать ")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_q_btn, {
                  color: "primary",
                  onClick: _ctx.closeUserAdd
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Отмена ")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_DataLoading, { msgLoading: "Сохранение" })
          ]))
    ]),
    _: 1
  }))
}