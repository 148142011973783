import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { MetricApi } from '@/components/modules/intersection/services/api/MetricApi'

const metricApi = new MetricApi()

class State {
  metricList: any
  isMetricBusy: boolean
  metricChecked: any = {}
}

const getters = <GetterTree<State, any>>{
  getMetricList(state) {
    return state.metricList
  },

  isMetricBusy(state) {
    return state.isMetricBusy
  },

  getMetricChecked(state) {
    return state.metricChecked
  },
}

const mutations = <MutationTree<State>>{
  setMetricList(state, payload) {
    state.metricList = payload
  },

  setIsMetricBusy(state, payload) {
    state.isMetricBusy = payload
  },

  setMetricChecked(state, payload) {
    const metricState = state.metricChecked

    if (!payload[1]) {
      delete metricState[payload[0]]
      state.metricChecked = metricState
    } else {
      metricState[payload[0]] = payload[1]
      state.metricChecked = metricState
    }
  },

  clearMetricChecked(state, payload) {
    state.metricChecked = payload
  },
}

const actions = <ActionTree<State, any>>{
  getMetricList({ commit }, data) {
    commit('setIsMetricBusy', true)
    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : {}

    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}

    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : {}

    return new Promise((resolve, reject) => {
      metricApi.getMetric(filter, sort, pagination).then(
        (message: any) => {
          const items = message.getItemsList()

          commit('setMetricList', items)
          commit('setIsMetricBusy', false)
          resolve(items)
        },

        (error: any) => {
          commit('setMetricList', [])
          commit('setIsMetricBusy', false)
          reject(error)
          console.log('ERROR ', error)
        }
      )
    })
  },

  setCheckedMetric({ commit }, data) {
    commit('setMetricChecked', data)
  },

  clearMetricChecked({ commit }, data) {
    commit('clearMetricChecked', data)
  }
}

const metricModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
export default metricModule
