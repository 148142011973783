import answer_class from '@/components/modules/polls/store/answer_class'
import answer_grp from '@/components/modules/polls/store/answer_grp'
import answer_grp_cls from '@/components/modules/polls/store/answer_grp_cls'
import answer_src from '@/components/modules/polls/store/answer_src'
import class_polls from '@/components/modules/polls/store/class'
import classifier from '@/components/modules/polls/store/classifier'
import class_tag from '@/components/modules/polls/store/class_tag'
import words from '@/components/modules/polls/store/words'
import poll_group from '@/components/modules/polls/store/poll_group'
import poll from '@/components/modules/polls/store/poll'
import question from '@/components/modules/polls/store/question'
import answer from '@/components/modules/polls/store/answer'
import answer_grp_form from '@/components/modules/polls/store/answer_grp_form'
import answer_grp_cls_form from '@/components/modules/polls/store/answer_grp_cls_form'
import poll_info_form from '@/components/modules/polls/store/poll_info_form'
import message_grp from "@/components/modules/polls/store/message_grp";
import message_src from "@/components/modules/polls/store/message_src";
import message_grp_cls from "@/components/modules/polls/store/message_grp_cls";
import message_class from "@/components/modules/polls/store/message_class";
import load_polls from "@/components/modules/polls/store/load_polls";
import social_network_message_class from "@/components/modules/polls/store/social_network_message_class";
import social_network_message_grp from "@/components/modules/polls/store/social_network_message_grp";
import social_network_message_grp_cls from "@/components/modules/polls/store/social_network_message_grp_cls";
import social_network_message_src from "@/components/modules/polls/store/social_network_message_src";
import social_network_load from "@/components/modules/polls/store/social_network_load";

export default {
    answer_class,
    answer_grp,
    answer_grp_cls,
    answer_src,
    class_polls,
    classifier,
    class_tag,
    words,
    poll_group,
    poll,
    question,
    answer,
    answer_grp_form,
    answer_grp_cls_form,
    poll_info_form,
    message_grp,
    message_src,
    message_grp_cls,
    message_class,
    load_polls,
    social_network_message_class,
    social_network_message_grp,
    social_network_message_grp_cls,
    social_network_message_src,
    social_network_load,
}
