import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { MessageGrpApi } from '../services/api/MessageGrpApi';
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
    isMessageGrpBusy: boolean = false;
    isMessageGrpResultBusy: boolean = false;
    isMessageGrpChildsBusy: boolean = false;
    isMessageGrpWorkedBusy: boolean = false;
    messageGrpResultItems: unknown [];
    messageGrpChildsItems: unknown [];
    messageGrpWorkedItems: unknown [];
    messageGrpItem: unknown;
    messageGrpWorkedItem: unknown;
    currentMessageResult: any = null;
}

// Создаем объект
const messageGrpApi = new MessageGrpApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

    isMessageGrpBusy(state): boolean {
        return state.isMessageGrpBusy;
    },

    isMessageGrpResultBusy(state): boolean {
        return state.isMessageGrpResultBusy;
    },

    isMessageGrpChildsBusy(state): boolean {
        return state.isMessageGrpChildsBusy;
    },

    isMessageGrpWorkedBusy(state): boolean {
        return state.isMessageGrpWorkedBusy;
    },

    getMessageGrpResultItems(state): unknown[] {
        return state.messageGrpResultItems;
    },

    getMessageGrpChildsItems(state): unknown[] {
        return state.messageGrpChildsItems;
    },

    getMessageGrpWorkedItems(state): unknown[] {
        return state.messageGrpWorkedItems;
    },

    getMessageGrpItem(state): unknown {
        return state.messageGrpItem;
    },

    getMessageGrpWorkedItem(state): unknown {
        return state.messageGrpWorkedItem;
    },

    getCurrentMessageResult(state): any {
        return state.currentMessageResult;
    }
};

// Создаем мутации
const mutations = <MutationTree<State>>{
    setIsMessageGrpBusy(state, payload: boolean) {
        state.isMessageGrpBusy = payload
    },

    setIsMessageGrpResultBusy(state, payload: boolean) {
        state.isMessageGrpResultBusy = payload
    },

    setIsMessageGrpChildsBusy(state, payload: boolean) {
        state.isMessageGrpChildsBusy = payload
    },

    setIsMessageGrpWorkedBusy(state, payload: boolean) {
        state.isMessageGrpWorkedBusy = payload
    },

    setMessageGrpResultItems(state, payload: unknown[]) {
        state.messageGrpResultItems = payload
    },

    setMessageGrpChildsItems(state, payload: unknown[]) {
        state.messageGrpChildsItems = payload
    },

    setMessageGrpWorkedItems(state, payload: unknown[]) {
        state.messageGrpWorkedItems = payload
    },

    setMessageGrpItem(state, payload: unknown) {
        state.messageGrpItem = payload
    },

    setMessageGrpWorkedItem(state, payload: unknown) {
        state.messageGrpWorkedItem = payload
    },

    setCurrentMessageResult(state, payload: any) {
        state.currentMessageResult = payload
    }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

    // Устанавливаем элемент
    setMessageGrpItem({ commit }, data: any) {
        commit('setMessageGrpItem', data);
    },

    // Устанавливаем элемент рабочий
    setMessageGrpWorkedItem({ commit }, data: any) {
        commit('setMessageGrpWorkedItem', data);
    },

    // Устанавливаем текущий ответ в левой панели
    setCurrentMessageResult({ commit }, data: any) {
        commit('setCurrentMessageResult', data);
    },
    // Список элементов (результирующая таблица, вместе с реальным hasChilds)
    getMessageGrpResultItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsMessageGrpBusy', true);
        commit('setIsMessageGrpResultBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const question_id:any = data !== undefined && data.question_id !== undefined ? data.question_id : 0;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            messageGrpApi.getMessageGrpResultItems(filter, sort, pagination, question_id)
                .then(
                    (message:any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setMessageGrpResultItems', items);
                        commit('setIsMessageGrpBusy', false);
                        commit('setIsMessageGrpResultBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setMessageGrpResultItems', []);
                        commit('setIsMessageGrpBusy', false);
                        commit('setIsMessageGrpResultBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Список элементов (результирующая таблица, вместе с реальным hasChilds)
    // с поиском страницы для заданного ответа
    getMessageGrpWithFindAnswerResultItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsMessageGrpBusy', true);
        commit('setIsMessageGrpResultBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const question_id:any = data !== undefined && data.question_id !== undefined ? data.question_id : 0;
        const find_id:any = data !== undefined && data.find_id !== undefined ? data.find_id : 0;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            messageGrpApi.getMessageGrpWithFindAnswerResultItems(filter, sort, pagination, question_id, find_id)
                .then(
                    (message:any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setMessageGrpResultItems', items);
                        commit('setIsMessageGrpBusy', false);
                        commit('setIsMessageGrpResultBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setMessageGrpResultItems', []);
                        commit('setIsMessageGrpBusy', false);
                        commit('setIsMessageGrpResultBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Список дочерних элементов (часть результирующей таблицы, с hasChilds = false)
    getMessageGrpChildsItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsMessageGrpBusy', true);
        commit('setIsMessageGrpChildsBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const parent_id:any = data !== undefined && data.parent_id !== undefined ? data.parent_id : 0;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            messageGrpApi.getMessageGrpChildsItems(filter, sort, pagination, parent_id)
                .then(
                    (message:any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setMessageGrpChildsItems', items);
                        commit('setIsMessageGrpBusy', false);
                        commit('setIsMessageGrpChildsBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setMessageGrpChildsItems', []);
                        commit('setIsMessageGrpBusy', false);
                        commit('setIsMessageGrpChildsBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Список элементов (таблица для обработки, с hasChilds = false)
    getMessageGrpWorkedItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsMessageGrpBusy', true);
        commit('setIsMessageGrpWorkedBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const question_id:any = data !== undefined && data.question_id !== undefined ? data.question_id : 0;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            messageGrpApi.getMessageGrpWorkedItems(filter, sort, pagination, question_id)
                .then(
                    (message:any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setMessageGrpWorkedItems', items);
                        commit('setIsMessageGrpBusy', false);
                        commit('setIsMessageGrpWorkedBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setMessageGrpWorkedItems', []);
                        commit('setIsMessageGrpBusy', false);
                        commit('setIsMessageGrpWorkedBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Создать элемент
    createMessageGrpItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsMessageGrpBusy', true);

        return new Promise((resolve, reject) => {

            // Создаем элемент
            messageGrpApi.createMessageGrpItem(data)
                .then(
                    (response) => {
                        commit('setIsMessageGrpBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsMessageGrpBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Обновить элемент
    updateMessageGrpItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsMessageGrpBusy', true);

        return new Promise((resolve, reject) => {

            // Обновляем элемент
            messageGrpApi.updateMessageGrpItem(data)
                .then(
                    (response) => {
                        commit('setIsMessageGrpBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsMessageGrpBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Обновить текст элемента
    updateTextMessageGrpItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsMessageGrpBusy', true);

        return new Promise((resolve, reject) => {

            // Обновляем элемент
            messageGrpApi.updateTextMessageGrpItem(data)
                .then(
                    (response) => {
                        commit('setIsMessageGrpBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsMessageGrpBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Удалить элемент
    deleteMessageGrpItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsMessageGrpBusy', true);

        return new Promise((resolve, reject) => {

            // Удаляем элемент
            messageGrpApi.deleteMessageGrpItem(data)
                .then(
                    (response) => {
                        commit('setIsMessageGrpBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsMessageGrpBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Сведение ответов к одному (reduce)
    reduceMessageGrpItems({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsMessageGrpBusy', true);
        commit('setIsMessageGrpWorkedBusy', true);

        return new Promise((resolve, reject) => {

            // Создаем элемент
            messageGrpApi.reduceMessageGrpItems(data)
                .then(
                    (response) => {
                        commit('setIsMessageGrpBusy', false);
                        commit('setIsMessageGrpWorkedBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsMessageGrpBusy', false);
                        commit('setIsMessageGrpWorkedBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Разбиение ответов по разделителю (split)
    splitMessageGrpItems({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsMessageGrpBusy', true);
        commit('setIsMessageGrpWorkedBusy', true);

        return new Promise((resolve, reject) => {

            // Создаем элемент
            messageGrpApi.splitMessageGrpItems(data)
                .then(
                    (response) => {
                        commit('setIsMessageGrpBusy', false);
                        commit('setIsMessageGrpWorkedBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsMessageGrpBusy', false);
                        commit('setIsMessageGrpWorkedBusy', false);
                        reject(error);
                    }
                );
        });
    },

    separateMessageGrpItems({ commit }, data) {

        return new Promise((resolve, reject) => {

            // Создаем элемент
            messageGrpApi.separateMessageGrpItems(data)
                .then(
                    (response) => {
                        resolve(response);
                    },
                    (error) => {
                        console.log('SEPARATE ANSWERS ERROR', error)
                        reject(error);
                    }
                );
        });
    }
};

// Создаем модуль
const MessageGrpModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default MessageGrpModule;
