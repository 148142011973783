import {
  PollGroupServiceClient
} from "../../proto/pb/Poll_groupServiceClientPb";
import {
  PollGroup, 
  ListPollGroupRequest,
  //ItemPollGroupRequest,
  PollGroupFilter,
  PollGroupFilterItem,
  PollGroupSort,
  PollGroupPagination,
  CreatePollGroupRequest,  
  UpdatePollGroupRequest, 
  DeletePollGroupRequest
} from "../../proto/pb/poll_group_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 100;

export class PollGroupApi {
  items: Array<PollGroup> = [];
  client: PollGroupServiceClient;
  metadata: any;

  constructor() {
    const host:string = (window as any).VUE_APP_API_URL ?? '';
    this.client = new PollGroupServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  // Установка и получение фильтров
  createFilter(data: any){

    const filter = new PollGroupFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
        for (const i in data) {
            if (data[i].field_name && ('' !== data[i].field_name)) {
                const fItem = new PollGroupFilterItem();
                fItem.setFieldName(data[i].field_name.toString());
                fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                filter.addItems(fItem);
            }
        }
    }

    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any){

    const sort = new PollGroupSort();
    const name:string = data.name !== undefined ? data.name.toString() : 'poll_group_title';
    const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any){

    const pagination = new PollGroupPagination();
    const page:number = data.page !== undefined ? Number(data.page) : 1;
    const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages:number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt:number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  // Список элементов
  getPollGroupItems(filter: any, sort: any, pagination: any){

    const request = new ListPollGroupRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));

    return this.client.listPollGroup(request, this.metadata);
  }

  // Получение элемента
  getPollGroupItem(filter: any){

    const request = new ListPollGroupRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort({}));
    request.setPagination(this.createPagination({}));

    return this.client.listPollGroup(request, this.metadata)
        .then((message) => {
            const itemList = message.getItemsList()

            if (itemList && (itemList.length > 0)) {
                return itemList[0]
            }
        })
  }

  // Создание элемента
  createPollGroupItem(data: any){

    const request = new CreatePollGroupRequest();
    request.setPollGroupTitle(data.title);

    return this.client.createPollGroup(request, this.metadata);
  }

  // Редактирование элемента
  updatePollGroupItem(data: any){

    const request = new UpdatePollGroupRequest();
    request.setPollGroupId(data.id);
    request.setPollGroupTitle(data.title);

    return this.client.updatePollGroup(request, this.metadata);
  }

  // Удаление элемента
  deletePollGroupItem(data: any){

    const request = new DeletePollGroupRequest();
    request.setPollGroupId(data.id);

    return this.client.deletePollGroup(request, this.metadata);
  }
}
