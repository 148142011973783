
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'NSIList',

  data() {
    return {
      addonList: [
        {
          title: 'Классификаторы',
          url: 'nsi/classifiers',
        },
        // {
        //     title: 'Справочник проектов',
        //     url: 'nsi/project-directory',
        // },
        {
          title: 'Теги',
          url: 'nsi/tags',
        },
        {
          title: 'Словарь',
          url: 'nsi/dictionary',
        },

        // {
        //   title: "Просмотр профиля выбранного пользователя",
        //   url: "aehr",
        // },
        // {
        //   title: "Прогнозирование аудитории",
        //   url: "dky",
        // },
        // {
        //   title: "Оповещение аудитории",
        //   url: "sh",
        // },
      ],
    }
  },
})
