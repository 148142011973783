const routes = [
  {
    path: '/core-active-users',
    name: 'CoreActiveUsers',
    component: () => import('../views/CoreActiveUsers.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Ядро активных пользователей' },
      ],
    },
  },
  {
    path: '/reports/report',
    name: 'ReportPage',
    component: () => import('../views/ReportPage.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Отчеты', to: { name: 'ReportsPage' } },
        { text: 'Отчет по проекту'}
      ],
    },
  },
]

export default {
  routes,
}
