/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v5.27.1
// source: qbuilder.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as qbuilder_pb from './qbuilder_pb';


export class QbuilderServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateQbuilder = new grpcWeb.MethodDescriptor(
    '/proto.QbuilderService/CreateQbuilder',
    grpcWeb.MethodType.UNARY,
    qbuilder_pb.CreateQbuilderRequest,
    qbuilder_pb.CreateQbuilderResponse,
    (request: qbuilder_pb.CreateQbuilderRequest) => {
      return request.serializeBinary();
    },
    qbuilder_pb.CreateQbuilderResponse.deserializeBinary
  );

  createQbuilder(
    request: qbuilder_pb.CreateQbuilderRequest,
    metadata: grpcWeb.Metadata | null): Promise<qbuilder_pb.CreateQbuilderResponse>;

  createQbuilder(
    request: qbuilder_pb.CreateQbuilderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: qbuilder_pb.CreateQbuilderResponse) => void): grpcWeb.ClientReadableStream<qbuilder_pb.CreateQbuilderResponse>;

  createQbuilder(
    request: qbuilder_pb.CreateQbuilderRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: qbuilder_pb.CreateQbuilderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.QbuilderService/CreateQbuilder',
        request,
        metadata || {},
        this.methodDescriptorCreateQbuilder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.QbuilderService/CreateQbuilder',
    request,
    metadata || {},
    this.methodDescriptorCreateQbuilder);
  }

  methodDescriptorUpdateQbuilder = new grpcWeb.MethodDescriptor(
    '/proto.QbuilderService/UpdateQbuilder',
    grpcWeb.MethodType.UNARY,
    qbuilder_pb.UpdateQbuilderRequest,
    qbuilder_pb.UpdateQbuilderResponse,
    (request: qbuilder_pb.UpdateQbuilderRequest) => {
      return request.serializeBinary();
    },
    qbuilder_pb.UpdateQbuilderResponse.deserializeBinary
  );

  updateQbuilder(
    request: qbuilder_pb.UpdateQbuilderRequest,
    metadata: grpcWeb.Metadata | null): Promise<qbuilder_pb.UpdateQbuilderResponse>;

  updateQbuilder(
    request: qbuilder_pb.UpdateQbuilderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: qbuilder_pb.UpdateQbuilderResponse) => void): grpcWeb.ClientReadableStream<qbuilder_pb.UpdateQbuilderResponse>;

  updateQbuilder(
    request: qbuilder_pb.UpdateQbuilderRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: qbuilder_pb.UpdateQbuilderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.QbuilderService/UpdateQbuilder',
        request,
        metadata || {},
        this.methodDescriptorUpdateQbuilder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.QbuilderService/UpdateQbuilder',
    request,
    metadata || {},
    this.methodDescriptorUpdateQbuilder);
  }

  methodDescriptorDeleteQbuilder = new grpcWeb.MethodDescriptor(
    '/proto.QbuilderService/DeleteQbuilder',
    grpcWeb.MethodType.UNARY,
    qbuilder_pb.DeleteQbuilderRequest,
    qbuilder_pb.DeleteQbuilderResponse,
    (request: qbuilder_pb.DeleteQbuilderRequest) => {
      return request.serializeBinary();
    },
    qbuilder_pb.DeleteQbuilderResponse.deserializeBinary
  );

  deleteQbuilder(
    request: qbuilder_pb.DeleteQbuilderRequest,
    metadata: grpcWeb.Metadata | null): Promise<qbuilder_pb.DeleteQbuilderResponse>;

  deleteQbuilder(
    request: qbuilder_pb.DeleteQbuilderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: qbuilder_pb.DeleteQbuilderResponse) => void): grpcWeb.ClientReadableStream<qbuilder_pb.DeleteQbuilderResponse>;

  deleteQbuilder(
    request: qbuilder_pb.DeleteQbuilderRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: qbuilder_pb.DeleteQbuilderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.QbuilderService/DeleteQbuilder',
        request,
        metadata || {},
        this.methodDescriptorDeleteQbuilder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.QbuilderService/DeleteQbuilder',
    request,
    metadata || {},
    this.methodDescriptorDeleteQbuilder);
  }

  methodDescriptorExecuteQbuilder = new grpcWeb.MethodDescriptor(
    '/proto.QbuilderService/ExecuteQbuilder',
    grpcWeb.MethodType.UNARY,
    qbuilder_pb.ExecuteQbuilderRequest,
    qbuilder_pb.ExecuteQbuilderResponse,
    (request: qbuilder_pb.ExecuteQbuilderRequest) => {
      return request.serializeBinary();
    },
    qbuilder_pb.ExecuteQbuilderResponse.deserializeBinary
  );

  executeQbuilder(
    request: qbuilder_pb.ExecuteQbuilderRequest,
    metadata: grpcWeb.Metadata | null): Promise<qbuilder_pb.ExecuteQbuilderResponse>;

  executeQbuilder(
    request: qbuilder_pb.ExecuteQbuilderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: qbuilder_pb.ExecuteQbuilderResponse) => void): grpcWeb.ClientReadableStream<qbuilder_pb.ExecuteQbuilderResponse>;

  executeQbuilder(
    request: qbuilder_pb.ExecuteQbuilderRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: qbuilder_pb.ExecuteQbuilderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.QbuilderService/ExecuteQbuilder',
        request,
        metadata || {},
        this.methodDescriptorExecuteQbuilder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.QbuilderService/ExecuteQbuilder',
    request,
    metadata || {},
    this.methodDescriptorExecuteQbuilder);
  }

  methodDescriptorListQbuilder = new grpcWeb.MethodDescriptor(
    '/proto.QbuilderService/ListQbuilder',
    grpcWeb.MethodType.UNARY,
    qbuilder_pb.ListQbuilderRequest,
    qbuilder_pb.ListQbuilderResponse,
    (request: qbuilder_pb.ListQbuilderRequest) => {
      return request.serializeBinary();
    },
    qbuilder_pb.ListQbuilderResponse.deserializeBinary
  );

  listQbuilder(
    request: qbuilder_pb.ListQbuilderRequest,
    metadata: grpcWeb.Metadata | null): Promise<qbuilder_pb.ListQbuilderResponse>;

  listQbuilder(
    request: qbuilder_pb.ListQbuilderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: qbuilder_pb.ListQbuilderResponse) => void): grpcWeb.ClientReadableStream<qbuilder_pb.ListQbuilderResponse>;

  listQbuilder(
    request: qbuilder_pb.ListQbuilderRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: qbuilder_pb.ListQbuilderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.QbuilderService/ListQbuilder',
        request,
        metadata || {},
        this.methodDescriptorListQbuilder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.QbuilderService/ListQbuilder',
    request,
    metadata || {},
    this.methodDescriptorListQbuilder);
  }

  methodDescriptorExportExecuteQbuilder = new grpcWeb.MethodDescriptor(
    '/proto.QbuilderService/ExportExecuteQbuilder',
    grpcWeb.MethodType.UNARY,
    qbuilder_pb.ExecuteQbuilderRequest,
    qbuilder_pb.ExportExecuteQbuilderResponse,
    (request: qbuilder_pb.ExecuteQbuilderRequest) => {
      return request.serializeBinary();
    },
    qbuilder_pb.ExportExecuteQbuilderResponse.deserializeBinary
  );

  exportExecuteQbuilder(
    request: qbuilder_pb.ExecuteQbuilderRequest,
    metadata: grpcWeb.Metadata | null): Promise<qbuilder_pb.ExportExecuteQbuilderResponse>;

  exportExecuteQbuilder(
    request: qbuilder_pb.ExecuteQbuilderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: qbuilder_pb.ExportExecuteQbuilderResponse) => void): grpcWeb.ClientReadableStream<qbuilder_pb.ExportExecuteQbuilderResponse>;

  exportExecuteQbuilder(
    request: qbuilder_pb.ExecuteQbuilderRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: qbuilder_pb.ExportExecuteQbuilderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.QbuilderService/ExportExecuteQbuilder',
        request,
        metadata || {},
        this.methodDescriptorExportExecuteQbuilder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.QbuilderService/ExportExecuteQbuilder',
    request,
    metadata || {},
    this.methodDescriptorExportExecuteQbuilder);
  }

  methodDescriptorGetSqlQbuilder = new grpcWeb.MethodDescriptor(
    '/proto.QbuilderService/GetSqlQbuilder',
    grpcWeb.MethodType.UNARY,
    qbuilder_pb.GetSqlRequest,
    qbuilder_pb.GetSqlResponse,
    (request: qbuilder_pb.GetSqlRequest) => {
      return request.serializeBinary();
    },
    qbuilder_pb.GetSqlResponse.deserializeBinary
  );

  getSqlQbuilder(
    request: qbuilder_pb.GetSqlRequest,
    metadata: grpcWeb.Metadata | null): Promise<qbuilder_pb.GetSqlResponse>;

  getSqlQbuilder(
    request: qbuilder_pb.GetSqlRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: qbuilder_pb.GetSqlResponse) => void): grpcWeb.ClientReadableStream<qbuilder_pb.GetSqlResponse>;

  getSqlQbuilder(
    request: qbuilder_pb.GetSqlRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: qbuilder_pb.GetSqlResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.QbuilderService/GetSqlQbuilder',
        request,
        metadata || {},
        this.methodDescriptorGetSqlQbuilder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.QbuilderService/GetSqlQbuilder',
    request,
    metadata || {},
    this.methodDescriptorGetSqlQbuilder);
  }

}

