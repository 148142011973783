import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { ActiveCountApi } from '@/components/modules/intersection/services/api/ActiveCountApi'

const activeCountApi = new ActiveCountApi()

class State {
  activeCount: any
  isActiveCount: boolean
  activeTable: any
  isActiveTable: boolean
  activeChart: any
  isActiveChart: boolean
  exportCount: any
}

const getters = <GetterTree<State, any>>{
  getActiveCount(state) {
    return state.activeCount
  },

  isActiveCount(state) {
    return state.isActiveCount
  },

  getActiveTable(state) {
    return state.activeTable
  },

  isActiveTable(state) {
    return state.isActiveTable
  },

  getActiveChart(state) {
    return state.activeChart
  },

  isActiveChart(state) {
    return state.isActiveChart
  },

  getExportCount(state) {
    return state.exportCount
  },
}

const mutations = <MutationTree<State>>{
  setActiveCount(state, payload) {
    state.activeCount = payload
  },

  setIsActiveCount(state, payload) {
    state.isActiveCount = payload
  },

  setActiveTable(state, payload) {
    state.activeTable = payload
  },

  setIsActiveTable(state, payload) {
    state.isActiveTable = payload
  },

  setActiveChart(state, payload) {
    state.activeChart = payload
  },

  setIsActiveChart(state, payload) {
    state.isActiveChart = payload
  },

  setExportCount(state, payload) {
    state.exportCount = payload
  },
}

const actions = <ActionTree<State, any>>{
  getActiveCount({ commit }, data) {
    commit('setIsActiveCount', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : {}

    return new Promise((resolve, reject) => {
      activeCountApi.getActiveCount(filter, data).then(
        (message: any) => {
          const items = message.getItem()

          commit('setActiveCount', items)
          commit('setIsActiveCount', false)
          resolve(items)
        },

        (error: any) => {
          commit('setActiveCount', [])
          commit('setIsActiveCount', false)
          reject(error)
          console.log('ERROR ', error)
        }
      )
    })
  },

  getActiveTable({ commit }, data) {
    commit('setIsActiveTable', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : {}

    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}

    return new Promise((resolve, reject) => {
      activeCountApi.getActiveTable(filter, sort, data).then(
        (message: any) => {
          const items = message.getItemsList()

          commit('setActiveTable', items)
          commit('setIsActiveTable', false)
          resolve(items)
        },

        (error: any) => {
          commit('setActiveTable', [])
          commit('setIsActiveTable', false)
          reject(error)
          console.log('ERROR ', error)
        }
      )
    })
  },

  getActiveChart({ commit }, data) {
    commit('setIsActiveChart', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : {}

    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}

    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : {}

    return new Promise((resolve, reject) => {
      activeCountApi.getActiveChart(filter, sort, pagination, data).then(
        (message: any) => {
          const items = message.getItemsList()

          commit('setActiveChart', items)
          commit('setIsActiveChart', false)
          resolve(items)
        },

        (error: any) => {
          commit('setActiveChart', [])
          commit('setIsActiveChart', false)
          reject(error)
          console.log('ERROR ', error)
        }
      )
    })
  },

  getExportCount({ commit }, data) {
    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : {}

    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}

    return new Promise((resolve, reject) => {
      activeCountApi.getActiveTable(filter, sort, data).then(
        (message: any) => {
          const items = message.getItemsList()
          const params = message.getParams()

          commit('setExportCount', items)
          resolve({ items, params })
        },

        (error: any) => {
          commit('setExportCount', [])
          reject(error)
          console.log('ERROR ', error)
        }
      )
    })
  },
}

const activeCountModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
export default activeCountModule
