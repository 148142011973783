// source: message_src.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.proto.ListMessageSrcRequest', null, global);
goog.exportSymbol('proto.proto.ListMessageSrcResponse', null, global);
goog.exportSymbol('proto.proto.ListMessageSrcSourceRequest', null, global);
goog.exportSymbol('proto.proto.ListMessageSrcSourceResponse', null, global);
goog.exportSymbol('proto.proto.MessageSrc', null, global);
goog.exportSymbol('proto.proto.MessageSrcFilter', null, global);
goog.exportSymbol('proto.proto.MessageSrcFilterItem', null, global);
goog.exportSymbol('proto.proto.MessageSrcPagination', null, global);
goog.exportSymbol('proto.proto.MessageSrcSort', null, global);
goog.exportSymbol('proto.proto.MessageSrcSource', null, global);
goog.exportSymbol('proto.proto.PersonalListMessageSrcSourceRequest', null, global);
goog.exportSymbol('proto.proto.PersonalListMessageSrcSourceResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MessageSrc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MessageSrc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MessageSrc.displayName = 'proto.proto.MessageSrc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MessageSrcSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MessageSrcSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MessageSrcSource.displayName = 'proto.proto.MessageSrcSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MessageSrcFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MessageSrcFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MessageSrcFilterItem.displayName = 'proto.proto.MessageSrcFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MessageSrcFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.MessageSrcFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.MessageSrcFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MessageSrcFilter.displayName = 'proto.proto.MessageSrcFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MessageSrcSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MessageSrcSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MessageSrcSort.displayName = 'proto.proto.MessageSrcSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MessageSrcPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MessageSrcPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MessageSrcPagination.displayName = 'proto.proto.MessageSrcPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListMessageSrcRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListMessageSrcRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListMessageSrcRequest.displayName = 'proto.proto.ListMessageSrcRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListMessageSrcResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListMessageSrcResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListMessageSrcResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListMessageSrcResponse.displayName = 'proto.proto.ListMessageSrcResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListMessageSrcSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListMessageSrcSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListMessageSrcSourceRequest.displayName = 'proto.proto.ListMessageSrcSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListMessageSrcSourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListMessageSrcSourceResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListMessageSrcSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListMessageSrcSourceResponse.displayName = 'proto.proto.ListMessageSrcSourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PersonalListMessageSrcSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PersonalListMessageSrcSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PersonalListMessageSrcSourceRequest.displayName = 'proto.proto.PersonalListMessageSrcSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PersonalListMessageSrcSourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PersonalListMessageSrcSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PersonalListMessageSrcSourceResponse.displayName = 'proto.proto.PersonalListMessageSrcSourceResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MessageSrc.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MessageSrc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MessageSrc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageSrc.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    messageTypeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    accountGuid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    messageSrcText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    messageSrcTime: (f = msg.getMessageSrcTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    project: jspb.Message.getFieldWithDefault(msg, 7, ""),
    messageType: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MessageSrc}
 */
proto.proto.MessageSrc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MessageSrc;
  return proto.proto.MessageSrc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MessageSrc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MessageSrc}
 */
proto.proto.MessageSrc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSourceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageTypeId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountGuid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageSrcText(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMessageSrcTime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProject(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MessageSrc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MessageSrc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MessageSrc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageSrc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMessageTypeId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAccountGuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMessageSrcText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMessageSrcTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMessageType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 source_id = 1;
 * @return {number}
 */
proto.proto.MessageSrc.prototype.getSourceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageSrc} returns this
 */
proto.proto.MessageSrc.prototype.setSourceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 project_id = 2;
 * @return {number}
 */
proto.proto.MessageSrc.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageSrc} returns this
 */
proto.proto.MessageSrc.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 message_type_id = 3;
 * @return {number}
 */
proto.proto.MessageSrc.prototype.getMessageTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageSrc} returns this
 */
proto.proto.MessageSrc.prototype.setMessageTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string account_guid = 4;
 * @return {string}
 */
proto.proto.MessageSrc.prototype.getAccountGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageSrc} returns this
 */
proto.proto.MessageSrc.prototype.setAccountGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string message_src_text = 5;
 * @return {string}
 */
proto.proto.MessageSrc.prototype.getMessageSrcText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageSrc} returns this
 */
proto.proto.MessageSrc.prototype.setMessageSrcText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp message_src_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.MessageSrc.prototype.getMessageSrcTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.MessageSrc} returns this
*/
proto.proto.MessageSrc.prototype.setMessageSrcTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MessageSrc} returns this
 */
proto.proto.MessageSrc.prototype.clearMessageSrcTime = function() {
  return this.setMessageSrcTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MessageSrc.prototype.hasMessageSrcTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string project = 7;
 * @return {string}
 */
proto.proto.MessageSrc.prototype.getProject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageSrc} returns this
 */
proto.proto.MessageSrc.prototype.setProject = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string message_type = 8;
 * @return {string}
 */
proto.proto.MessageSrc.prototype.getMessageType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageSrc} returns this
 */
proto.proto.MessageSrc.prototype.setMessageType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MessageSrcSource.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MessageSrcSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MessageSrcSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageSrcSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    messageTypeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    accountGuid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ssoId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    messageSrcText: jspb.Message.getFieldWithDefault(msg, 6, ""),
    messageSrcTime: (f = msg.getMessageSrcTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    project: jspb.Message.getFieldWithDefault(msg, 8, ""),
    messageType: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MessageSrcSource}
 */
proto.proto.MessageSrcSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MessageSrcSource;
  return proto.proto.MessageSrcSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MessageSrcSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MessageSrcSource}
 */
proto.proto.MessageSrcSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSourceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageTypeId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountGuid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageSrcText(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMessageSrcTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProject(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MessageSrcSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MessageSrcSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MessageSrcSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageSrcSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMessageTypeId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAccountGuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMessageSrcText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMessageSrcTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMessageType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int64 source_id = 1;
 * @return {number}
 */
proto.proto.MessageSrcSource.prototype.getSourceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageSrcSource} returns this
 */
proto.proto.MessageSrcSource.prototype.setSourceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 project_id = 2;
 * @return {number}
 */
proto.proto.MessageSrcSource.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageSrcSource} returns this
 */
proto.proto.MessageSrcSource.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 message_type_id = 3;
 * @return {number}
 */
proto.proto.MessageSrcSource.prototype.getMessageTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageSrcSource} returns this
 */
proto.proto.MessageSrcSource.prototype.setMessageTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string account_guid = 4;
 * @return {string}
 */
proto.proto.MessageSrcSource.prototype.getAccountGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageSrcSource} returns this
 */
proto.proto.MessageSrcSource.prototype.setAccountGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string sso_id = 5;
 * @return {string}
 */
proto.proto.MessageSrcSource.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageSrcSource} returns this
 */
proto.proto.MessageSrcSource.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string message_src_text = 6;
 * @return {string}
 */
proto.proto.MessageSrcSource.prototype.getMessageSrcText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageSrcSource} returns this
 */
proto.proto.MessageSrcSource.prototype.setMessageSrcText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp message_src_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.MessageSrcSource.prototype.getMessageSrcTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.MessageSrcSource} returns this
*/
proto.proto.MessageSrcSource.prototype.setMessageSrcTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MessageSrcSource} returns this
 */
proto.proto.MessageSrcSource.prototype.clearMessageSrcTime = function() {
  return this.setMessageSrcTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MessageSrcSource.prototype.hasMessageSrcTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string project = 8;
 * @return {string}
 */
proto.proto.MessageSrcSource.prototype.getProject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageSrcSource} returns this
 */
proto.proto.MessageSrcSource.prototype.setProject = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string message_type = 9;
 * @return {string}
 */
proto.proto.MessageSrcSource.prototype.getMessageType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageSrcSource} returns this
 */
proto.proto.MessageSrcSource.prototype.setMessageType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MessageSrcFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MessageSrcFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MessageSrcFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageSrcFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.MessageSrcFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MessageSrcFilterItem}
 */
proto.proto.MessageSrcFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MessageSrcFilterItem;
  return proto.proto.MessageSrcFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MessageSrcFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MessageSrcFilterItem}
 */
proto.proto.MessageSrcFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.MessageSrcFilter;
      reader.readMessage(value,proto.proto.MessageSrcFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MessageSrcFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MessageSrcFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MessageSrcFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageSrcFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.MessageSrcFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.MessageSrcFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageSrcFilterItem} returns this
 */
proto.proto.MessageSrcFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.MessageSrcFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageSrcFilterItem} returns this
 */
proto.proto.MessageSrcFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.MessageSrcFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageSrcFilterItem} returns this
 */
proto.proto.MessageSrcFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MessageSrcFilter or = 4;
 * @return {?proto.proto.MessageSrcFilter}
 */
proto.proto.MessageSrcFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.MessageSrcFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.MessageSrcFilter, 4));
};


/**
 * @param {?proto.proto.MessageSrcFilter|undefined} value
 * @return {!proto.proto.MessageSrcFilterItem} returns this
*/
proto.proto.MessageSrcFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MessageSrcFilterItem} returns this
 */
proto.proto.MessageSrcFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MessageSrcFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.MessageSrcFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MessageSrcFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MessageSrcFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MessageSrcFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageSrcFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.MessageSrcFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MessageSrcFilter}
 */
proto.proto.MessageSrcFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MessageSrcFilter;
  return proto.proto.MessageSrcFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MessageSrcFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MessageSrcFilter}
 */
proto.proto.MessageSrcFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.MessageSrcFilterItem;
      reader.readMessage(value,proto.proto.MessageSrcFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MessageSrcFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MessageSrcFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MessageSrcFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageSrcFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.MessageSrcFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MessageSrcFilterItem items = 1;
 * @return {!Array<!proto.proto.MessageSrcFilterItem>}
 */
proto.proto.MessageSrcFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.MessageSrcFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.MessageSrcFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.MessageSrcFilterItem>} value
 * @return {!proto.proto.MessageSrcFilter} returns this
*/
proto.proto.MessageSrcFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.MessageSrcFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.MessageSrcFilterItem}
 */
proto.proto.MessageSrcFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.MessageSrcFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.MessageSrcFilter} returns this
 */
proto.proto.MessageSrcFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MessageSrcSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MessageSrcSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MessageSrcSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageSrcSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MessageSrcSort}
 */
proto.proto.MessageSrcSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MessageSrcSort;
  return proto.proto.MessageSrcSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MessageSrcSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MessageSrcSort}
 */
proto.proto.MessageSrcSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MessageSrcSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MessageSrcSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MessageSrcSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageSrcSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.MessageSrcSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageSrcSort} returns this
 */
proto.proto.MessageSrcSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.MessageSrcSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageSrcSort} returns this
 */
proto.proto.MessageSrcSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MessageSrcPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MessageSrcPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MessageSrcPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageSrcPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MessageSrcPagination}
 */
proto.proto.MessageSrcPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MessageSrcPagination;
  return proto.proto.MessageSrcPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MessageSrcPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MessageSrcPagination}
 */
proto.proto.MessageSrcPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MessageSrcPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MessageSrcPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MessageSrcPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageSrcPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.MessageSrcPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageSrcPagination} returns this
 */
proto.proto.MessageSrcPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.MessageSrcPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageSrcPagination} returns this
 */
proto.proto.MessageSrcPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.MessageSrcPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageSrcPagination} returns this
 */
proto.proto.MessageSrcPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt = 4;
 * @return {number}
 */
proto.proto.MessageSrcPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageSrcPagination} returns this
 */
proto.proto.MessageSrcPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListMessageSrcRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListMessageSrcRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListMessageSrcRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMessageSrcRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.MessageSrcFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.MessageSrcSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.MessageSrcPagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListMessageSrcRequest}
 */
proto.proto.ListMessageSrcRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListMessageSrcRequest;
  return proto.proto.ListMessageSrcRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListMessageSrcRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListMessageSrcRequest}
 */
proto.proto.ListMessageSrcRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.MessageSrcFilter;
      reader.readMessage(value,proto.proto.MessageSrcFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.MessageSrcSort;
      reader.readMessage(value,proto.proto.MessageSrcSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.MessageSrcPagination;
      reader.readMessage(value,proto.proto.MessageSrcPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListMessageSrcRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListMessageSrcRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListMessageSrcRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMessageSrcRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.MessageSrcFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.MessageSrcSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.MessageSrcPagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional MessageSrcFilter filter = 1;
 * @return {?proto.proto.MessageSrcFilter}
 */
proto.proto.ListMessageSrcRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.MessageSrcFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.MessageSrcFilter, 1));
};


/**
 * @param {?proto.proto.MessageSrcFilter|undefined} value
 * @return {!proto.proto.ListMessageSrcRequest} returns this
*/
proto.proto.ListMessageSrcRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMessageSrcRequest} returns this
 */
proto.proto.ListMessageSrcRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMessageSrcRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MessageSrcSort sort = 2;
 * @return {?proto.proto.MessageSrcSort}
 */
proto.proto.ListMessageSrcRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.MessageSrcSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.MessageSrcSort, 2));
};


/**
 * @param {?proto.proto.MessageSrcSort|undefined} value
 * @return {!proto.proto.ListMessageSrcRequest} returns this
*/
proto.proto.ListMessageSrcRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMessageSrcRequest} returns this
 */
proto.proto.ListMessageSrcRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMessageSrcRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MessageSrcPagination pagination = 3;
 * @return {?proto.proto.MessageSrcPagination}
 */
proto.proto.ListMessageSrcRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.MessageSrcPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.MessageSrcPagination, 3));
};


/**
 * @param {?proto.proto.MessageSrcPagination|undefined} value
 * @return {!proto.proto.ListMessageSrcRequest} returns this
*/
proto.proto.ListMessageSrcRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMessageSrcRequest} returns this
 */
proto.proto.ListMessageSrcRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMessageSrcRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListMessageSrcResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListMessageSrcResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListMessageSrcResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListMessageSrcResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMessageSrcResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.MessageSrc.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListMessageSrcRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListMessageSrcResponse}
 */
proto.proto.ListMessageSrcResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListMessageSrcResponse;
  return proto.proto.ListMessageSrcResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListMessageSrcResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListMessageSrcResponse}
 */
proto.proto.ListMessageSrcResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.MessageSrc;
      reader.readMessage(value,proto.proto.MessageSrc.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListMessageSrcRequest;
      reader.readMessage(value,proto.proto.ListMessageSrcRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListMessageSrcResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListMessageSrcResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListMessageSrcResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMessageSrcResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.MessageSrc.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListMessageSrcRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MessageSrc items = 1;
 * @return {!Array<!proto.proto.MessageSrc>}
 */
proto.proto.ListMessageSrcResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.MessageSrc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.MessageSrc, 1));
};


/**
 * @param {!Array<!proto.proto.MessageSrc>} value
 * @return {!proto.proto.ListMessageSrcResponse} returns this
*/
proto.proto.ListMessageSrcResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.MessageSrc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.MessageSrc}
 */
proto.proto.ListMessageSrcResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.MessageSrc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListMessageSrcResponse} returns this
 */
proto.proto.ListMessageSrcResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListMessageSrcRequest params = 2;
 * @return {?proto.proto.ListMessageSrcRequest}
 */
proto.proto.ListMessageSrcResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListMessageSrcRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListMessageSrcRequest, 2));
};


/**
 * @param {?proto.proto.ListMessageSrcRequest|undefined} value
 * @return {!proto.proto.ListMessageSrcResponse} returns this
*/
proto.proto.ListMessageSrcResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMessageSrcResponse} returns this
 */
proto.proto.ListMessageSrcResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMessageSrcResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListMessageSrcSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListMessageSrcSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListMessageSrcSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMessageSrcSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.MessageSrcFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.MessageSrcSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.MessageSrcPagination.toObject(includeInstance, f),
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    hasFull: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListMessageSrcSourceRequest}
 */
proto.proto.ListMessageSrcSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListMessageSrcSourceRequest;
  return proto.proto.ListMessageSrcSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListMessageSrcSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListMessageSrcSourceRequest}
 */
proto.proto.ListMessageSrcSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.MessageSrcFilter;
      reader.readMessage(value,proto.proto.MessageSrcFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.MessageSrcSort;
      reader.readMessage(value,proto.proto.MessageSrcSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.MessageSrcPagination;
      reader.readMessage(value,proto.proto.MessageSrcPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasFull(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListMessageSrcSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListMessageSrcSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListMessageSrcSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMessageSrcSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.MessageSrcFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.MessageSrcSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.MessageSrcPagination.serializeBinaryToWriter
    );
  }
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getHasFull();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional MessageSrcFilter filter = 1;
 * @return {?proto.proto.MessageSrcFilter}
 */
proto.proto.ListMessageSrcSourceRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.MessageSrcFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.MessageSrcFilter, 1));
};


/**
 * @param {?proto.proto.MessageSrcFilter|undefined} value
 * @return {!proto.proto.ListMessageSrcSourceRequest} returns this
*/
proto.proto.ListMessageSrcSourceRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMessageSrcSourceRequest} returns this
 */
proto.proto.ListMessageSrcSourceRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMessageSrcSourceRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MessageSrcSort sort = 2;
 * @return {?proto.proto.MessageSrcSort}
 */
proto.proto.ListMessageSrcSourceRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.MessageSrcSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.MessageSrcSort, 2));
};


/**
 * @param {?proto.proto.MessageSrcSort|undefined} value
 * @return {!proto.proto.ListMessageSrcSourceRequest} returns this
*/
proto.proto.ListMessageSrcSourceRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMessageSrcSourceRequest} returns this
 */
proto.proto.ListMessageSrcSourceRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMessageSrcSourceRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MessageSrcPagination pagination = 3;
 * @return {?proto.proto.MessageSrcPagination}
 */
proto.proto.ListMessageSrcSourceRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.MessageSrcPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.MessageSrcPagination, 3));
};


/**
 * @param {?proto.proto.MessageSrcPagination|undefined} value
 * @return {!proto.proto.ListMessageSrcSourceRequest} returns this
*/
proto.proto.ListMessageSrcSourceRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMessageSrcSourceRequest} returns this
 */
proto.proto.ListMessageSrcSourceRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMessageSrcSourceRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 message_grp_id = 4;
 * @return {number}
 */
proto.proto.ListMessageSrcSourceRequest.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListMessageSrcSourceRequest} returns this
 */
proto.proto.ListMessageSrcSourceRequest.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool has_full = 5;
 * @return {boolean}
 */
proto.proto.ListMessageSrcSourceRequest.prototype.getHasFull = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ListMessageSrcSourceRequest} returns this
 */
proto.proto.ListMessageSrcSourceRequest.prototype.setHasFull = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListMessageSrcSourceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListMessageSrcSourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListMessageSrcSourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListMessageSrcSourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMessageSrcSourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.MessageSrcSource.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListMessageSrcSourceRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListMessageSrcSourceResponse}
 */
proto.proto.ListMessageSrcSourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListMessageSrcSourceResponse;
  return proto.proto.ListMessageSrcSourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListMessageSrcSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListMessageSrcSourceResponse}
 */
proto.proto.ListMessageSrcSourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.MessageSrcSource;
      reader.readMessage(value,proto.proto.MessageSrcSource.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListMessageSrcSourceRequest;
      reader.readMessage(value,proto.proto.ListMessageSrcSourceRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListMessageSrcSourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListMessageSrcSourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListMessageSrcSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMessageSrcSourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.MessageSrcSource.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListMessageSrcSourceRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MessageSrcSource items = 1;
 * @return {!Array<!proto.proto.MessageSrcSource>}
 */
proto.proto.ListMessageSrcSourceResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.MessageSrcSource>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.MessageSrcSource, 1));
};


/**
 * @param {!Array<!proto.proto.MessageSrcSource>} value
 * @return {!proto.proto.ListMessageSrcSourceResponse} returns this
*/
proto.proto.ListMessageSrcSourceResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.MessageSrcSource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.MessageSrcSource}
 */
proto.proto.ListMessageSrcSourceResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.MessageSrcSource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListMessageSrcSourceResponse} returns this
 */
proto.proto.ListMessageSrcSourceResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListMessageSrcSourceRequest params = 2;
 * @return {?proto.proto.ListMessageSrcSourceRequest}
 */
proto.proto.ListMessageSrcSourceResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListMessageSrcSourceRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListMessageSrcSourceRequest, 2));
};


/**
 * @param {?proto.proto.ListMessageSrcSourceRequest|undefined} value
 * @return {!proto.proto.ListMessageSrcSourceResponse} returns this
*/
proto.proto.ListMessageSrcSourceResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMessageSrcSourceResponse} returns this
 */
proto.proto.ListMessageSrcSourceResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMessageSrcSourceResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PersonalListMessageSrcSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PersonalListMessageSrcSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PersonalListMessageSrcSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListMessageSrcSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.MessageSrcFilter.toObject(includeInstance, f),
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hasFull: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PersonalListMessageSrcSourceRequest}
 */
proto.proto.PersonalListMessageSrcSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PersonalListMessageSrcSourceRequest;
  return proto.proto.PersonalListMessageSrcSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PersonalListMessageSrcSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PersonalListMessageSrcSourceRequest}
 */
proto.proto.PersonalListMessageSrcSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.MessageSrcFilter;
      reader.readMessage(value,proto.proto.MessageSrcFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasFull(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PersonalListMessageSrcSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PersonalListMessageSrcSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PersonalListMessageSrcSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListMessageSrcSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.MessageSrcFilter.serializeBinaryToWriter
    );
  }
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getHasFull();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional MessageSrcFilter filter = 1;
 * @return {?proto.proto.MessageSrcFilter}
 */
proto.proto.PersonalListMessageSrcSourceRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.MessageSrcFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.MessageSrcFilter, 1));
};


/**
 * @param {?proto.proto.MessageSrcFilter|undefined} value
 * @return {!proto.proto.PersonalListMessageSrcSourceRequest} returns this
*/
proto.proto.PersonalListMessageSrcSourceRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PersonalListMessageSrcSourceRequest} returns this
 */
proto.proto.PersonalListMessageSrcSourceRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PersonalListMessageSrcSourceRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 message_grp_id = 2;
 * @return {number}
 */
proto.proto.PersonalListMessageSrcSourceRequest.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PersonalListMessageSrcSourceRequest} returns this
 */
proto.proto.PersonalListMessageSrcSourceRequest.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool has_full = 3;
 * @return {boolean}
 */
proto.proto.PersonalListMessageSrcSourceRequest.prototype.getHasFull = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.PersonalListMessageSrcSourceRequest} returns this
 */
proto.proto.PersonalListMessageSrcSourceRequest.prototype.setHasFull = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PersonalListMessageSrcSourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PersonalListMessageSrcSourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PersonalListMessageSrcSourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListMessageSrcSourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PersonalListMessageSrcSourceResponse}
 */
proto.proto.PersonalListMessageSrcSourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PersonalListMessageSrcSourceResponse;
  return proto.proto.PersonalListMessageSrcSourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PersonalListMessageSrcSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PersonalListMessageSrcSourceResponse}
 */
proto.proto.PersonalListMessageSrcSourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PersonalListMessageSrcSourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PersonalListMessageSrcSourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PersonalListMessageSrcSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListMessageSrcSourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.proto.PersonalListMessageSrcSourceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.PersonalListMessageSrcSourceResponse} returns this
 */
proto.proto.PersonalListMessageSrcSourceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
