import { AccountCheckServiceClient } from '../../proto/pb/Accounts_checkServiceClientPb'
import { ItemAccountCheckRequest } from '../../proto/pb/accounts_check_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class AccountCheckApi {
  client: AccountCheckServiceClient
  metadata: any

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? ''
    this.client = new AccountCheckServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  getAccountsCheckList(sso_id: string) {
    const request = new ItemAccountCheckRequest()
    // Устанавливаем параметры запроса
    request.setSsoId(sso_id)

    return this.client.accountCheckItem(request, { Authorization: localStorage.getItem('access-key') } as any)
  }
}
