import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { AccountCheckApi } from '../services/api/AccountsCheckApi'

const accCheckApi = new AccountCheckApi()

class State {
  isAccountCheckBusy: boolean = false
  accountCheckList: Array<any> = []
}

const getters = <GetterTree<State, any>>{
  isAccountCheckBusy(state): boolean {
    return state.isAccountCheckBusy
  },

  getAccountCheckList(state) {
    return state.accountCheckList
  },
}

const mutations = <MutationTree<State>>{
  setIsCheckListBusy(state, payload: boolean) {
    state.isAccountCheckBusy = payload
  },

  setAccountCheckList(state, payload) {
    state.accountCheckList = payload
  },
}

const actions = <ActionTree<State, any>>{
  getCheckList({ commit }, sso: any) {
    commit('setIsCheckListBusy', true)

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      accCheckApi.getAccountsCheckList(sso).then(
        (message: any) => {
          const items = message.getItem()
          //   console.log(
          //     '🚀 ~ file: accountCheck.ts:40 ~ returnnewPromise ~ items:',
          //     items
          //   )

          commit('setAccountCheckList', items)
          commit('setIsCheckListBusy', false)

          resolve(items)
        },
        (error) => {
          commit('setAccountCheckList', [])
          commit('setIsCheckListBusy', false)
          reject(error)
        }
      )
    })
  },
}

const AccountCheckModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default AccountCheckModule
