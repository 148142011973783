import { ActivityLoyaltyServiceClient } from '../../proto/pb/LoyaltyServiceClientPb'

import {
    ActivityLoyaltyFilter,
    ActivityLoyaltyFilterItem,
    ActivityLoyaltySort,
    ActivityLoyaltyPagination,
    ListUserLoyaltyRequest,
    UserInfoLoyaltyRequest,
    ListFilterProjectLoyaltyRequest,
    ListFilterTransactionLoyaltyRequest,
    LastAchievementsLoyaltyRequest,
    AllAchievementsLoyaltyRequest,
    GetAggTaskListStatRequest,
} from '../../proto/pb/loyalty_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

const defaultLimit: number = 1000

export class LoyaltyApi {
    client: ActivityLoyaltyServiceClient
    metadata: any

    constructor() {
        const host: string = (window as any).VUE_APP_API_URL ?? ''
        this.client = new ActivityLoyaltyServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    createFilter(data: any) {

        const filter = new ActivityLoyaltyFilter();
        // Устанавливаем фильтры
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && ('' !== data[i].field_name)) {
                    const fItem = new ActivityLoyaltyFilterItem();
                    fItem.setFieldName(data[i].field_name.toString());
                    fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                    fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                    fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                    filter.addItems(fItem);
                }
            }
        }

        return filter;
    }

    createSort(data: any){

        const sort = new ActivityLoyaltySort();
        const name:string = data.name !== undefined ? data.name.toString() : 'activ_datetime';
        const exp:string = data.exp !== undefined ? data.exp.toString() : 'desc';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    createPagination(data: any){

        const pagination = new ActivityLoyaltyPagination();
        const page:number = data.page !== undefined ? Number(data.page) : 1;
        const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
        const pages:number = data.pages !== undefined ? Number(data.pages) : 0;

        // Устанавливаем пагинацию
        pagination.setPage(page);
        pagination.setLimit(limit);
        pagination.setPages(pages);

        return pagination;
    }

    getListUsersLoyalty(filter: any, sort: any, pagination: any, data: any) {
        const request = new ListUserLoyaltyRequest()

        request
            .setFilter(this.createFilter(filter))
            .setSort(this.createSort(sort))
            .setPagination(this.createPagination(pagination))
            .setSsoId(data.sso_id)

        console.log('LIST USERS LOYALTY REQ', request)

        return this.client.listUserLoyalty(request, this.metadata)
    }

    getExportListUsersLoyalty(filter: any, sort: any, pagination: any, data: any) {
        const request = new ListUserLoyaltyRequest()

        request
            .setFilter(this.createFilter(filter))
            .setSort(this.createSort(sort))
            .setPagination(this.createPagination(pagination))
            .setSsoId(data.sso_id)

        console.log('LIST EXPORT USERS LOYALTY REQ', request)

        return this.client.listUserLoyalty(request, this.metadata)
    }

    getUserInfoLoyalty(data: any) {
        const request = new UserInfoLoyaltyRequest()

        request
            .setSsoId(data.sso_id)

        console.log('Loyalty User Info Request', request)

        return this.client.userInfoLoyalty(request, this.metadata)
    }

    getProjectListLoyalty(data: any) {
        const request = new ListFilterProjectLoyaltyRequest()

        request
            .setSsoId(data.sso_id)

        console.log('LIST PROJECTS LOYALTY REQ', request)

        return this.client.listFilterProjectLoyalty(request, this.metadata)
    }

    getTransactionListLoyalty(data: any) {
        const request = new ListFilterTransactionLoyaltyRequest()

        request
            .setSsoId(data.sso_id)

        console.log('LIST TRANSACTIONS LOYALTY REQ', request)

        return this.client.listFilterTransactionLoyalty(request, this.metadata)
    }

    getLastAchievementsLoyalty(data: any) {
        const request = new LastAchievementsLoyaltyRequest()

        request
            .setSsoId(data.sso_id)

        console.log('LAST ACHIEVEMENTS REQ', request)

        return this.client.lastAchievementsLoyalty(request, this.metadata)
    }

    getAllAchievementsLoyalty(data: any) {
        const request = new AllAchievementsLoyaltyRequest()

        request
            .setSsoId(data.sso_id)

        console.log('ALL ACHIEVEMENTS REQ', request)

        return this.client.allAchievementsLoyalty(request, this.metadata)
    }

    getPLStats(data: GetAggTaskListStatRequest.AsObject) {
        const request = new GetAggTaskListStatRequest()
        request
            .setPeriodBegin(data.periodBegin)
            .setPeriodEnd(data.periodEnd)

        return this.client.getAggTaskListStat(request, this.metadata)
    }
}