import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { AnswerGrpFormApi } from '../services/api/AnswerGrpFormApi';
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
  isAnswerGrpFormBusy: boolean = false;
  answerGrpFormItems: unknown [];
}

// Создаем объект
const answerGrpFormApi = new AnswerGrpFormApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

  isAnswerGrpFormBusy(state): boolean {
    return state.isAnswerGrpFormBusy;
  },

  getAnswerGrpFormItems(state): unknown[] {
    return state.answerGrpFormItems;
  }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

  setIsAnswerGrpFormBusy(state, payload: boolean) {
    state.isAnswerGrpFormBusy = payload
  },

  setAnswerGrpFormItems(state, payload: unknown[]) {
    state.answerGrpFormItems = payload
  }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

    // Список ответов для выходной формы
  getAnswerGrpFormItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerGrpFormBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const question_id:any = data !== undefined && data.question_id !== undefined ? data.question_id : 0;

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      answerGrpFormApi.getAnswerGrpFormItems(filter, sort, question_id)
        .then(
          (response:any) => {
            commit('setAnswerGrpFormItems', response);
            commit('setIsAnswerGrpFormBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setAnswerGrpFormItems', []);
            commit('setIsAnswerGrpFormBusy', false);
            reject(error);
          }
        );
    });
  }
};

// Создаем модуль
const AnswerGrpFormModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default AnswerGrpFormModule;
