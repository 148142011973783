import {GetterTree, MutationTree, ActionTree} from "vuex";
import {QuestionApi} from "../services/api/QuestionApi";
import Loading from 'quasar/src/plugins/loading/Loading.js';

//import MainMixin from "@/mixins/main";

class State {
    isQuestionBusy: boolean = false;
    questionItems: unknown [];
    isQuestionForSearchBusy: boolean = false;
    questionForSearchItems: unknown [];
    isQuestionChildBusy: boolean = false;
    questionChildItems: unknown [];
    questionItem: unknown;
    currentQuestion: any = null;
}

// Создаем объект
const questionApi = new QuestionApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{
    isQuestionBusy(state): boolean {
        return state.isQuestionBusy;
    },

    getQuestionItems(state): unknown[] {
        return state.questionItems;
    },

    isQuestionForSearchBusy(state): boolean {
        return state.isQuestionForSearchBusy;
    },

    getQuestionForSearchItems(state): unknown[] {
        return state.questionForSearchItems;
    },

    isQuestionChildBusy(state): boolean {
        return state.isQuestionChildBusy;
    },

    getQuestionChildItems(state): unknown[] {
        return state.questionChildItems;
    },

    getQuestionItem(state): unknown {
        return state.questionItem;
    },

    getCurrentQuestion(state) {
        return state.currentQuestion
    },
};

// Создаем мутации
const mutations = <MutationTree<State>>{
    setIsQuestionBusy(state, payload: boolean) {
        state.isQuestionBusy = payload
    },

    setQuestionItems(state, payload: unknown[]) {
        state.questionItems = payload
    },

    setIsQuestionForSearchBusy(state, payload: boolean) {
        state.isQuestionForSearchBusy = payload
    },

    setQuestionForSearchItems(state, payload: unknown[]) {
        state.questionForSearchItems = payload
    },

    setIsQuestionChildBusy(state, payload: boolean) {
        state.isQuestionChildBusy = payload
    },

    setQuestionChildItems(state, payload: unknown[]) {
        state.questionChildItems = payload
    },

    setQuestionItem(state, payload: unknown) {
        state.questionItem = payload
    },

    setCurrentQuestion(state, payload) {
        state.currentQuestion = payload
    },
};

// Создаем действия
const actions = <ActionTree<State, any>>{
    // Устанавливаем элемент
    setQuestionItem({commit}, data: any) {
        commit('setQuestionItem', data);
    },

    // Устанавливаем список
    setQuestionItems({commit}, data: any) {
        commit('setQuestionItems', data);
    },

    setCurrentQuestion({commit}, data: any) {
        commit('setCurrentQuestion', data)
    },

    // Список элементов
    getQuestionItems({commit}, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsQuestionBusy', true);

        const filter: any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort: any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination: any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            questionApi.getQuestionItems(filter, sort, pagination, data)
                .then(
                    (message: any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setQuestionItems', items);
                        commit('setIsQuestionBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setIsQuestionBusy', false);
                        reject(error);
                    }
                );
        });
    },

    getQuestionItemsForSearch({commit}, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsQuestionForSearchBusy', true);

        const filter: any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort: any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination: any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            questionApi.getQuestionItems(filter, sort, pagination, data)
                .then(
                    (message: any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setQuestionForSearchItems', items);
                        commit('setIsQuestionForSearchBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setIsQuestionForSearchBusy', false);
                        reject(error);
                    }
                );
        });
    },

    getQuestionChildItems({commit}, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsQuestionChildBusy', true);

        const filter: any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort: any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination: any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            questionApi.getQuestionChildItems(filter, sort, pagination, data)
                .then(
                    (message: any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setQuestionChildItems', items);
                        commit('setIsQuestionChildBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setIsQuestionChildBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Получить элемент
    getQuestionItem({commit}, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsQuestionBusy', true);

        const filter: any = data !== undefined && data.filter !== undefined ? data.filter : [];

        return new Promise((resolve, reject) => {

            // Получаем элемент
            questionApi.getQuestionItem(filter)
                .then(
                    (response: any) => {
                        commit('setQuestionItem', response);
                        commit('setIsQuestionBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsQuestionBusy', false);
                        reject(error);
                    }
                );
        });
    },

    reduceQuestion({commit}, data: any) {
        return new Promise((resolve, reject) => {
            questionApi
                .reduceQuestion(data)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    separateQuestion({commit}, data: any) {
        return new Promise((resolve, reject) => {
            questionApi
                .separateQuestion(data)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
};

// Создаем модуль
const QuestionModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default QuestionModule;
