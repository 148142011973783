
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ReferenceList',

  data() {
    return {
      addonList: [
        {
          title: 'Справочник проектов',
          url: 'reference/project-directory',
        },
        // {
        //   title: 'Справочник параметров настройки модели',
        //   url: 'reference/set-model',
        // },
        // {
        //   title: "Просмотр профиля выбранного пользователя",
        //   url: "aehr",
        // },
        // {
        //   title: "Прогнозирование аудитории",
        //   url: "dky",
        // },
        // {
        //   title: "Оповещение аудитории",
        //   url: "sh",
        // },
      ],
    }
  },
})
