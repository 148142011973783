/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: update_info.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.proto = require('./update_info_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.UpdateInfoServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.UpdateInfoServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListUpdateInfoRequest,
 *   !proto.proto.ListUpdateInfoResponse>}
 */
const methodDescriptor_UpdateInfoService_ListUpdateInfo = new grpc.web.MethodDescriptor(
  '/proto.UpdateInfoService/ListUpdateInfo',
  grpc.web.MethodType.UNARY,
  proto.proto.ListUpdateInfoRequest,
  proto.proto.ListUpdateInfoResponse,
  /**
   * @param {!proto.proto.ListUpdateInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListUpdateInfoResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListUpdateInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListUpdateInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListUpdateInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UpdateInfoServiceClient.prototype.listUpdateInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UpdateInfoService/ListUpdateInfo',
      request,
      metadata || {},
      methodDescriptor_UpdateInfoService_ListUpdateInfo,
      callback);
};


/**
 * @param {!proto.proto.ListUpdateInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListUpdateInfoResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UpdateInfoServicePromiseClient.prototype.listUpdateInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UpdateInfoService/ListUpdateInfo',
      request,
      metadata || {},
      methodDescriptor_UpdateInfoService_ListUpdateInfo);
};


module.exports = proto.proto;

