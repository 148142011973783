import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { SocialNetworkMessageGrpApi } from '../services/api/SocialNetworkMessageGrpApi';

class State {
    isSocialNetworkMessageGrpBusy: boolean = false;
    isSocialNetworkMessageGrpResultBusy: boolean = false;
    isSocialNetworkMessageGrpChildsBusy: boolean = false;
    isSocialNetworkMessageGrpWorkedBusy: boolean = false;
    socialNetworkMessageGrpResultItems: unknown [];
    socialNetworkMessageGrpChildsItems: unknown [];
    socialNetworkMessageGrpWorkedItems: unknown [];
    socialNetworkMessageGrpItem: unknown;
    socialNetworkMessageGrpWorkedItem: unknown;
    socialNetworkCurrentMessageResult: any = null;
}

// Создаем объект
const socialNetworkMessageGrpApi = new SocialNetworkMessageGrpApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

    isSocialNetworkMessageGrpBusy(state): boolean {
        return state.isSocialNetworkMessageGrpBusy;
    },

    isSocialNetworkMessageGrpResultBusy(state): boolean {
        return state.isSocialNetworkMessageGrpResultBusy;
    },

    isSocialNetworkMessageGrpChildsBusy(state): boolean {
        return state.isSocialNetworkMessageGrpChildsBusy;
    },

    isSocialNetworkMessageGrpWorkedBusy(state): boolean {
        return state.isSocialNetworkMessageGrpWorkedBusy;
    },

    getSocialNetworkMessageGrpResultItems(state): unknown[] {
        return state.socialNetworkMessageGrpResultItems;
    },

    getSocialNetworkMessageGrpChildsItems(state): unknown[] {
        return state.socialNetworkMessageGrpChildsItems;
    },

    getSocialNetworkMessageGrpWorkedItems(state): unknown[] {
        return state.socialNetworkMessageGrpWorkedItems;
    },

    getSocialNetworkMessageGrpItem(state): unknown {
        return state.socialNetworkMessageGrpItem;
    },

    getSocialNetworkMessageGrpWorkedItem(state): unknown {
        return state.socialNetworkMessageGrpWorkedItem;
    },

    getSocialNetworkCurrentMessageResult(state): any {
        return state.socialNetworkCurrentMessageResult;
    }
};

// Создаем мутации
const mutations = <MutationTree<State>>{
    setIsSocialNetworkMessageGrpBusy(state, payload: boolean) {
        state.isSocialNetworkMessageGrpBusy = payload
    },

    setIsSocialNetworkMessageGrpResultBusy(state, payload: boolean) {
        state.isSocialNetworkMessageGrpResultBusy = payload
    },

    setIsSocialNetworkMessageGrpChildsBusy(state, payload: boolean) {
        state.isSocialNetworkMessageGrpChildsBusy = payload
    },

    setIsSocialNetworkMessageGrpWorkedBusy(state, payload: boolean) {
        state.isSocialNetworkMessageGrpWorkedBusy = payload
    },

    setSocialNetworkMessageGrpResultItems(state, payload: unknown[]) {
        state.socialNetworkMessageGrpResultItems = payload
    },

    setSocialNetworkMessageGrpChildsItems(state, payload: unknown[]) {
        state.socialNetworkMessageGrpChildsItems = payload
    },

    setSocialNetworkMessageGrpWorkedItems(state, payload: unknown[]) {
        state.socialNetworkMessageGrpWorkedItems = payload
    },

    setSocialNetworkMessageGrpItem(state, payload: unknown) {
        state.socialNetworkMessageGrpItem = payload
    },

    setSocialNetworkMessageGrpWorkedItem(state, payload: unknown) {
        state.socialNetworkMessageGrpWorkedItem = payload
    },

    setSocialNetworkCurrentMessageResult(state, payload: any) {
        state.socialNetworkCurrentMessageResult = payload
    }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

    // Устанавливаем элемент
    setSocialNetworkMessageGrpItem({ commit }, data: any) {
        commit('setSocialNetworkMessageGrpItem', data);
    },

    // Устанавливаем элемент рабочий
    setSocialNetworkMessageGrpWorkedItem({ commit }, data: any) {
        commit('setSocialNetworkMessageGrpWorkedItem', data);
    },

    // Устанавливаем текущий ответ в левой панели
    setSocialNetworkCurrentMessageResult({ commit }, data: any) {
        commit('setSocialNetworkCurrentMessageResult', data);
    },
    // Список элементов (результирующая таблица, вместе с реальным hasChilds)
    getSocialNetworkMessageGrpResultItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageGrpBusy', true);
        commit('setIsSocialNetworkMessageGrpResultBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const question_id:any = data !== undefined && data.question_id !== undefined ? data.question_id : 0;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            socialNetworkMessageGrpApi.getSocialNetworkMessageGrpResultItems(filter, sort, pagination, question_id)
                .then(
                    (message:any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setSocialNetworkMessageGrpResultItems', items);
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        commit('setIsSocialNetworkMessageGrpResultBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setSocialNetworkMessageGrpResultItems', []);
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        commit('setIsSocialNetworkMessageGrpResultBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Список элементов (результирующая таблица, вместе с реальным hasChilds)
    // с поиском страницы для заданного ответа
    getSocialNetworkMessageGrpWithFindAnswerResultItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageGrpBusy', true);
        commit('setIsSocialNetworkMessageGrpResultBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const question_id:any = data !== undefined && data.question_id !== undefined ? data.question_id : 0;
        const find_id:any = data !== undefined && data.find_id !== undefined ? data.find_id : 0;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            socialNetworkMessageGrpApi.getSocialNetworkMessageGrpWithFindAnswerResultItems(filter, sort, pagination, question_id, find_id)
                .then(
                    (message:any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setSocialNetworkMessageGrpResultItems', items);
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        commit('setIsSocialNetworkMessageGrpResultBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setSocialNetworkMessageGrpResultItems', []);
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        commit('setIsSocialNetworkMessageGrpResultBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Список дочерних элементов (часть результирующей таблицы, с hasChilds = false)
    getSocialNetworkMessageGrpChildsItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageGrpBusy', true);
        commit('setIsSocialNetworkMessageGrpChildsBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const parent_id:any = data !== undefined && data.parent_id !== undefined ? data.parent_id : 0;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            socialNetworkMessageGrpApi.getSocialNetworkMessageGrpChildsItems(filter, sort, pagination, parent_id)
                .then(
                    (message:any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setSocialNetworkMessageGrpChildsItems', items);
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        commit('setIsSocialNetworkMessageGrpChildsBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setSocialNetworkMessageGrpChildsItems', []);
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        commit('setIsSocialNetworkMessageGrpChildsBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Список элементов (таблица для обработки, с hasChilds = false)
    getSocialNetworkMessageGrpWorkedItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageGrpBusy', true);
        commit('setIsSocialNetworkMessageGrpWorkedBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const question_id:any = data !== undefined && data.question_id !== undefined ? data.question_id : 0;

        return new Promise((resolve, reject) => {

            // Получаем список элементов
            socialNetworkMessageGrpApi.getSocialNetworkMessageGrpWorkedItems(filter, sort, pagination, question_id)
                .then(
                    (message:any) => {
                        const items = message.getItemsList();
                        const sort = message.getParams().getSort();
                        const pagination = message.getParams().getPagination();
                        commit('setSocialNetworkMessageGrpWorkedItems', items);
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        commit('setIsSocialNetworkMessageGrpWorkedBusy', false);
                        resolve({
                            items: items,
                            sort: sort,
                            pagination: pagination
                        });
                    },
                    (error) => {
                        commit('setSocialNetworkMessageGrpWorkedItems', []);
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        commit('setIsSocialNetworkMessageGrpWorkedBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Создать элемент
    createSocialNetworkMessageGrpItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageGrpBusy', true);

        return new Promise((resolve, reject) => {

            // Создаем элемент
            socialNetworkMessageGrpApi.createSocialNetworkMessageGrpItem(data)
                .then(
                    (response) => {
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Обновить элемент
    updateSocialNetworkMessageGrpItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageGrpBusy', true);

        return new Promise((resolve, reject) => {

            // Обновляем элемент
            socialNetworkMessageGrpApi.updateSocialNetworkMessageGrpItem(data)
                .then(
                    (response) => {
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Обновить текст элемента
    updateSocialNetworkTextMessageGrpItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageGrpBusy', true);

        return new Promise((resolve, reject) => {

            // Обновляем элемент
            socialNetworkMessageGrpApi.updateSocialNetworkTextMessageGrpItem(data)
                .then(
                    (response) => {
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Удалить элемент
    deleteSocialNetworkMessageGrpItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageGrpBusy', true);

        return new Promise((resolve, reject) => {

            // Удаляем элемент
            socialNetworkMessageGrpApi.deleteSocialNetworkMessageGrpItem(data)
                .then(
                    (response) => {
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Сведение ответов к одному (reduce)
    reduceSocialNetworkMessageGrpItems({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageGrpBusy', true);
        commit('setIsSocialNetworkMessageGrpWorkedBusy', true);

        return new Promise((resolve, reject) => {

            // Создаем элемент
            socialNetworkMessageGrpApi.reduceSocialNetworkMessageGrpItems(data)
                .then(
                    (response) => {
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        commit('setIsSocialNetworkMessageGrpWorkedBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        commit('setIsSocialNetworkMessageGrpWorkedBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Разбиение ответов по разделителю (split)
    splitSocialNetworkMessageGrpItems({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsSocialNetworkMessageGrpBusy', true);
        commit('setIsSocialNetworkMessageGrpWorkedBusy', true);

        return new Promise((resolve, reject) => {

            // Создаем элемент
            socialNetworkMessageGrpApi.splitSocialNetworkMessageGrpItems(data)
                .then(
                    (response) => {
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        commit('setIsSocialNetworkMessageGrpWorkedBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsSocialNetworkMessageGrpBusy', false);
                        commit('setIsSocialNetworkMessageGrpWorkedBusy', false);
                        reject(error);
                    }
                );
        });
    },

    separateSocialNetworkMessageGrpItems({ commit }, data) {

        return new Promise((resolve, reject) => {

            // Создаем элемент
            socialNetworkMessageGrpApi.separateSocialNetworkMessageGrpItems(data)
                .then(
                    (response) => {
                        resolve(response);
                    },
                    (error) => {
                        console.log('SEPARATE ANSWERS ERROR', error)
                        reject(error);
                    }
                );
        });
    }
};

// Создаем модуль
const SocialNetworkMessageGrpModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default SocialNetworkMessageGrpModule;
