import {
    MessageGrpServiceClient
} from "../../proto/pb/Message_grpServiceClientPb";
import {
    MessageGrp,
    ListMessageGrpRequest,
    ListMessageGrpWithFindMessageRequest,
    ListMessageGrpChildsRequest,
    MessageGrpFilter,
    MessageGrpFilterItem,
    MessageGrpSort,
    MessageGrpPagination,
    CreateMessageGrpRequest,
    UpdateMessageGrpRequest,
    UpdateTextMessageGrpRequest,
    DeleteMessageGrpRequest,
    ReduceMessageGrpRequest,
    SplitMessageGrpRequest,
    SeparateMessageGrpRequest,
} from "../../proto/pb/message_grp_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 1000;

export class MessageGrpApi {
    items: Array<MessageGrp> = [];
    client: MessageGrpServiceClient;
    metadata: any;

    constructor() {
        const host:string = (window as any).VUE_APP_API_URL ?? '';
        this.client = new MessageGrpServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    // Установка и получение фильтров
    createFilter(data: any){

        const filter = new MessageGrpFilter();

        // Устанавливаем фильтры
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && ('' !== data[i].field_name)) {
                    const fItem = new MessageGrpFilterItem();
                    fItem.setFieldName(data[i].field_name.toString());
                    fItem.setOperand(data[i].operand !== undefined ? data[i].operand : '=');
                    fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                    fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                    filter.addItems(fItem);
                }
            }
        }

        return filter;
    }

    // Установка и получение сортировки
    createSort(data: any){

        const sort = new MessageGrpSort();
        const name:string = data.name !== undefined ? data.name.toString() : 'ag.answer_grp_text';
        const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    // Установка и получение пагинации
    createPagination(data: any){

        const pagination = new MessageGrpPagination();
        const page:number = data.page !== undefined ? Number(data.page) : 1;
        const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
        const pages:number = data.pages !== undefined ? Number(data.pages) : 0;
        const cnt:number = data.cnt !== undefined ? Number(data.cnt) : 0;

        // Устанавливаем пагинацию
        pagination.setPage(page);
        pagination.setLimit(limit);
        pagination.setPages(pages);

        return pagination;
    }

    // Список элементов (результирующая таблица, вместе с реальным hasChilds)
    getMessageGrpResultItems(filter: any, sort: any, pagination: any, question_id: number){

        const request = new ListMessageGrpRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));
        request.setIsWorked(false);

        return this.client.listMessageGrp(request, this.metadata);
    }

    // Список элементов (результирующая таблица, вместе с реальным hasChilds)
    // с поиском страницы для заданного ответа
    getMessageGrpWithFindAnswerResultItems(filter: any, sort: any, pagination: number, question_id: number, find_id: number){

        const request = new ListMessageGrpWithFindMessageRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));
        request.setMessageGrpId(find_id);

        return this.client.listMessageGrpWithFindMessage(request, this.metadata);
    }

    // Список дочерних элементов (часть результирующей таблицы, с hasChilds = false)
    getMessageGrpChildsItems(filter: any, sort: any, pagination: any, parent_id: number){

        const request = new ListMessageGrpChildsRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));
        request.setMessageGrpId(parent_id);

        return this.client.listMessageGrpChilds(request, this.metadata);
    }

    // Список элементов (таблица для обработки, с hasChilds = false)
    getMessageGrpWorkedItems(filter: any, sort: any, pagination: any, question_id: number){

        const request = new ListMessageGrpRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));
        request.setIsWorked(true);

        return this.client.listMessageGrp(request, this.metadata);
    }

    // Создание элемента
    createMessageGrpItem(data: any){

        const request = new CreateMessageGrpRequest();
        request.setMessageGrpParentId(data.parent_id);
        request.setMessageId(data.answer_id);
        request.setMessageGrpText(data.text);
        request.setMessageGrpCount(data.count);
        request.setMessageGrpProcessed(data.processed);

        return this.client.createMessageGrp(request, this.metadata);
    }

    // Редактирование элемента
    updateMessageGrpItem(data: any){

        const request = new UpdateMessageGrpRequest();
        request.setMessageGrpId(data.id);
        request.setMessageGrpParentId(data.parent_id);
        request.setMessageId(data.answer_id);
        request.setMessageGrpText(data.text);
        request.setMessageGrpCount(data.count);
        request.setMessageGrpProcessed(data.processed);

        return this.client.updateMessageGrp(request, this.metadata);
    }

    // Редактирование текста элемента
    updateTextMessageGrpItem(data: any){

        const request = new UpdateTextMessageGrpRequest();
        request.setMessageGrpId(data.id);
        request.setMessageGrpText(data.text);

        return this.client.updateTextMessageGrp(request, this.metadata);
    }

    // Удаление элемента
    deleteMessageGrpItem(data: any){

        const request = new DeleteMessageGrpRequest();
        request.setMessageGrpId(data.id);

        return this.client.deleteMessageGrp(request, this.metadata);
    }

    // Сведение ответов к одному (reduce)
    reduceMessageGrpItems(data: any){

        const request = new ReduceMessageGrpRequest();
        request.setMessageGrpId(data.id);
        request.setReducedIdsList(data.reduced_ids);

        return this.client.reduceMessageGrp(request, this.metadata);
    }

    // Разбиение ответов по разделителю (split)
    splitMessageGrpItems(data: any){

        const request = new SplitMessageGrpRequest();
        request.setSplitString(data.split_string);
        request.setSplittedIdsList(data.splitted_ids);

        return this.client.splitMessageGrp(request, this.metadata);
    }

    separateMessageGrpItems(data: any) {
        const request = new SeparateMessageGrpRequest()

        request
            .setSeparatedIdsList(data.separated_ids_list)

        console.log('SEPARATE MESSAGE REQ', request)

        return this.client.separateMessageGrp(request, this.metadata)
    }
}
