import { GetterTree, MutationTree, ActionTree } from "vuex";
import {DynamicApi} from "@/components/modules/intersection/services/api/DynamicApi";

const dynamicApi = new DynamicApi()

class State {
    dynamicList: any
    isDynamicListBusy: boolean
}

const getters = <GetterTree<State, any>> {
    getDynamicList(state) {
        return state.dynamicList
    },

    isDynamicListBusy(state) {
        return state.isDynamicListBusy
    },
}

const mutations = <MutationTree<State>> {
    setDynamicList(state, payload) {
        state.dynamicList = payload
    },

    setIsDynamicListBusy(state, payload) {
        state.isDynamicListBusy = payload
    },
}

const actions = <ActionTree<State, any>> {
    getDynamicList({ commit }, data) {
        commit('setIsDynamicListBusy', true)

        return new Promise((resolve, reject) => {
            dynamicApi.getDynamicChart(data).then(
                (message: any) => {
                    const items = message.getItemsList()

                    console.log('DYNAMIC ITEMS', items, data)

                    commit('setDynamicList', items)
                    commit('setIsDynamicListBusy', false)
                    resolve(items)
                },

                (error: any) => {
                    commit('setDynamicList', [])
                    commit('setIsDynamicListBusy', false)
                    reject(error)
                    console.log('ERROR ', error)
                }
            )
        })
    }
}

const dynamicRegModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters,
}
export default dynamicRegModule