
import store from '@/store'
import { defineComponent } from 'vue'
import WordPanel from './WordPanel.vue'
import WordAdd from './WordAdd.vue'
import WordEdit from './WordEdit.vue'
import WordDelete from './WordDelete.vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'NsiDictionary',

  components: {
    WordPanel,
    WordAdd,
    WordEdit,
    WordDelete,
    DataLoading,
  },

  data() {
    return {
      filter: '',
      search: '',
      oldSearch: '',
      searchTimeout: setTimeout(() => {}, 500) as ReturnType<typeof setTimeout>,
      minSearchSymbol: 3,
      isActiveModal: false,
      isActiveModalAdd: false,
      isActiveModalEdit: false,
      isActiveModalDelete: false,
      splitterSize: 50,
      selectedWordsNode: null as any,
    }
  },

  computed: {
    isWordsBusy() {
      return store.getters.getIsWordsBusy
    },

    isWordsTreeBusy() {
      return store.getters.getIsWordsTreeBusy
    },

    getWordsTreeItems() {
      let nodes = store.getters.getWordsTreeItems
      return nodes ? nodes : []
    },

    getCurrentWordsNode() {
      return store.getters.getCurrentWordsNode
    },

    userRole() {
      return store.getters.getUserAccess
    },
  },

  methods: {
    hideModal() {
      this.isActiveModal = false
      this.isActiveModalAdd = false
      this.isActiveModalEdit = false
      this.isActiveModalDelete = false
    },

    addWord() {
      this.isActiveModalAdd = true
      console.log('addWord')
      this.isActiveModal = true
    },

    editWord() {
      this.isActiveModalEdit = true
      console.log('editWord')
      this.isActiveModal = true
    },

    deleteWord() {
      this.isActiveModalDelete = true
      console.log('deleteWord')
      this.isActiveModal = true
    },

    afterAction(setNode: any) {
      console.log('afterAction')
      this.reopenedTree(setNode, true) // true - перегружаем дерево с сервера
      this.hideModal()
    },

    selectNode() {
      let currentWordsNode: any = null
      console.log('selectNode this.selectedWordsNode', this.selectedWordsNode)
      if (this.selectedWordsNode !== null) {
        const tree: any = this.$refs.wordsTree
        const nodeData: any =
          tree !== undefined
            ? JSON.parse(
                JSON.stringify(tree.getNodeByKey(this.selectedWordsNode))
              )
            : null
        console.log('selectNode nodeData', nodeData)
        if (nodeData) {
          const parentNode: any =
            nodeData.parent_id || nodeData.parent_id == 0
              ? JSON.parse(
                  JSON.stringify(tree.getNodeByKey(nodeData.parent_id))
                )
              : null
          currentWordsNode = {
            id: nodeData.id,
            parent_id: nodeData.parent_id ? nodeData.parent_id : 0,
            text: nodeData.text,
            is_linked: nodeData.is_linked,
            hasChilds:
              nodeData.children !== undefined && nodeData.children.length > 0,
            parentText: parentNode ? parentNode.text : '',
          }
        }
      }
      // Устанавливаем текущий узел
      store.dispatch('setCurrentWordsNode', currentWordsNode).then(() => {
        if (currentWordsNode) {
          console.log('selectNode currentWordsNode', currentWordsNode)
        }
      })
    },

    // Перегружаем дерево классификатора
    reopenedTree(setNode: any, isReloadTree: any) {
      if (isReloadTree) {
        this.loadWordsTreeItems(setNode)
      } else {
        this.setTreeNode(setNode)
      }
    },

    setTreeNode(setNode: any) {
      if (setNode !== null) {
        console.log('setTreeNode setNode', setNode)
        this.selectedWordsNode = setNode
        this.selectNode()
        const tree: any = this.$refs.wordsTree
        let curNode: any =
          tree !== undefined
            ? JSON.parse(
                JSON.stringify(tree.getNodeByKey(this.selectedWordsNode))
              )
            : null
        while (curNode && curNode.parent_id !== undefined) {
          curNode = JSON.parse(
            JSON.stringify(tree.getNodeByKey(curNode.parent_id))
          )
          if (curNode) {
            tree.setExpanded(curNode.id, true)
          }
        }
      }
    },

    initSearch() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      this.filter = ''
      this.search = ''
      this.oldSearch = ''
      this.searchTimeout = setTimeout(() => {}, 500)
    },

    onInputSearchControl(event: any) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      console.log('onInputSearchControl event', event)
      this.searchTimeout = setTimeout(() => {
        const searchStr: string = event.target.value

        if (searchStr.length >= this.minSearchSymbol) {
          // Устанавливаем строку поиска
          this.search = searchStr.toLowerCase()
          //this.loadWordsTreeItems(null) // Временно убран серверный поиск !!!
        } else {
          this.clearSearch(this.oldSearch.length >= this.minSearchSymbol)
        }
        this.oldSearch = searchStr
      }, 500)
    },

    clearSearch(isReload = true) {
      this.search = ''
      if (isReload) {
        //this.loadWordsTreeItems(null)// Временно убран серверный поиск !!!
      }
    },

    getFilters() {
      return [
        /*{ // Временно убран серверный поиск !!!
            field_name: 'word_text',
            operand: 'ilike',
            value: this.search
        }*/
      ]
    },

    loadWordsTreeItems(setNode: any) {
      store.dispatch('setCurrentWordsNode', null)
      store
        .dispatch('getWordsTreeItems', {
          filter: this.getFilters(),
        })
        .then(
          (resolve) => {
            console.log('getWordsTreeItems resolve', resolve)
            this.setTreeNode(setNode)
          },
          (reject) => {
            console.log('getWordsTreeItems reject', reject)
          }
        )
    },

    loadData() {
      this.selectedWordsNode = null
      this.loadWordsTreeItems(null)
    },
  },

  created() {
    this.loadData()
  },
})
