import { GetterTree, MutationTree, ActionTree } from "vuex";

class State {
    currentProject: string;
}

// Создаем геттеры
const getters = <GetterTree<State, any>>{
  
  getCurrentProject(state): string {
    return state.currentProject;
  },
};

// Создаем мутации
const mutations = <MutationTree<State>>{
  
  setCurrentProject(state, payload: string) {
    state.currentProject = payload
  },
};

// Создаем действия
const actions = <ActionTree<State, any>>{
    
  setCurrentProject({commit}, data:any) {
    commit('setCurrentProject', data);
  }

};

// Создаем модуль
const CurrentProjectModule:any = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};
  
export default CurrentProjectModule;