<template>
  <div class="q-pa-md">
    <div class="text-h4 q-pb-md">Справка</div>
    <ReferenceList />
  </div>
</template>

<script>
import ReferenceList from "@/components/ReferenceList.vue";

export default {
  name: "ReferencePage",
  components: {ReferenceList},
}
</script>

<style scoped>

</style>