import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 2,
  style: {"height":"28px","font-size":"24px"}
}
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "self-center full-width no-outline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_DataLoading = _resolveComponent("DataLoading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_q_card, {
      style: {"width":"1024px","max-width":"80vw","height":"1024px","max-height":"80vh"},
      class: "q-pa-md q-gutter-md"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card_section, {
          horizontal: "",
          style: {"height":"80px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card, {
              class: "flat bordered",
              style: {"width":"60%"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_card_section, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_label, { class: "text-h6" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.item.guid), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_q_separator, { vertical: "" }),
            _createVNode(_component_q_card, {
              class: "flat bordered",
              style: {"width":"40%"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_card_section, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_label, { class: "text-h6" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Баллы:     "),
                            (_ctx.item.points > 0)
                              ? (_openBlock(), _createBlock(_component_q_badge, {
                                  key: 0,
                                  color: "green",
                                  label: _ctx.item.points,
                                  style: {"height":"28px","font-size":"24px"}
                                }, null, 8, ["label"]))
                              : (_ctx.item.points < 0)
                                ? (_openBlock(), _createBlock(_component_q_badge, {
                                    key: 1,
                                    color: "red",
                                    label: _ctx.item.points,
                                    style: {"height":"28px","font-size":"24px"}
                                  }, null, 8, ["label"]))
                                : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.item.points), 1))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_q_separator),
        _createVNode(_component_q_card_section, { style: {"height":"calc(100% - 224px)","overflow":"hidden"} }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card, { style: {"height":"100%","overflow":"auto"} }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getHeaderIds, (hId, index) => {
                    return (_openBlock(), _createBlock(_component_q_card_section, { key: index }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_field, {
                          "model-value": _ctx.item[_ctx.getFieldIndex(hId)],
                          label: _ctx.item[_ctx.getFieldIndex(hId)].label
                        }, {
                          control: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item[_ctx.getFieldIndex(hId)].value), 1)
                          ]),
                          _: 2
                        }, 1032, ["model-value", "label"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_q_separator),
        _createVNode(_component_q_card_actions, {
          align: "right",
          style: {"height":"60px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_btn, {
              color: "primary",
              onClick: _ctx.calculateUserPoints
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Расчитать ")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_q_btn, {
              color: "primary",
              onClick: _ctx.closeUserInfo
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Закрыть ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512), [
      [_vShow, !_ctx.isUserBusy]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_DataLoading)
    ], 512), [
      [_vShow, _ctx.isUserBusy]
    ])
  ], 64))
}