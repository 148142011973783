import { ActivityValueServiceClient } from '../../../proto/pb/Activity_valueServiceClientPb'
import { ItemShareActivityValueRequest } from '../../../proto/pb/activity_value_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class ActivityValueApi {
  client: ActivityValueServiceClient
  metadata: any

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? ''
    this.client = new ActivityValueServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  getActiveCoreValue(filter: any) {
    const request = new ItemShareActivityValueRequest()

    request
      .setDateFrom(filter.date_from)
      .setDateTo(filter.date_to)
      .setProjectIdsList(filter.project_ids)
      .setValue(filter.value)
      .setFromMetricAlias(filter.from_name)
      .setToMetricAlias(filter.to_name)
      .setIsAdditionalParams(filter.add_param)
      .setFromMetricValueIdsList(filter.from_ids)
      .setToMetricValueIdsList(filter.to_ids)
    console.log(
      '🚀 ~ file: ActivityValueApi.ts:26 ~ ActivityValueApi ~ getActiveCoreValue ~ request:',
      request
    )

    return this.client.itemShareActivityValue(request, this.metadata)
  }
}
