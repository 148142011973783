/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: question.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as question_pb from './question_pb';


export class QuestionServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListQuestion = new grpcWeb.MethodDescriptor(
    '/proto.QuestionService/ListQuestion',
    grpcWeb.MethodType.UNARY,
    question_pb.ListQuestionRequest,
    question_pb.ListQuestionResponse,
    (request: question_pb.ListQuestionRequest) => {
      return request.serializeBinary();
    },
    question_pb.ListQuestionResponse.deserializeBinary
  );

  listQuestion(
    request: question_pb.ListQuestionRequest,
    metadata: grpcWeb.Metadata | null): Promise<question_pb.ListQuestionResponse>;

  listQuestion(
    request: question_pb.ListQuestionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: question_pb.ListQuestionResponse) => void): grpcWeb.ClientReadableStream<question_pb.ListQuestionResponse>;

  listQuestion(
    request: question_pb.ListQuestionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: question_pb.ListQuestionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.QuestionService/ListQuestion',
        request,
        metadata || {},
        this.methodDescriptorListQuestion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.QuestionService/ListQuestion',
    request,
    metadata || {},
    this.methodDescriptorListQuestion);
  }

  methodDescriptorListQuestionChilds = new grpcWeb.MethodDescriptor(
    '/proto.QuestionService/ListQuestionChilds',
    grpcWeb.MethodType.UNARY,
    question_pb.ListQuestionChildsRequest,
    question_pb.ListQuestionChildsResponse,
    (request: question_pb.ListQuestionChildsRequest) => {
      return request.serializeBinary();
    },
    question_pb.ListQuestionChildsResponse.deserializeBinary
  );

  listQuestionChilds(
    request: question_pb.ListQuestionChildsRequest,
    metadata: grpcWeb.Metadata | null): Promise<question_pb.ListQuestionChildsResponse>;

  listQuestionChilds(
    request: question_pb.ListQuestionChildsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: question_pb.ListQuestionChildsResponse) => void): grpcWeb.ClientReadableStream<question_pb.ListQuestionChildsResponse>;

  listQuestionChilds(
    request: question_pb.ListQuestionChildsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: question_pb.ListQuestionChildsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.QuestionService/ListQuestionChilds',
        request,
        metadata || {},
        this.methodDescriptorListQuestionChilds,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.QuestionService/ListQuestionChilds',
    request,
    metadata || {},
    this.methodDescriptorListQuestionChilds);
  }

  methodDescriptorReduceQuestion = new grpcWeb.MethodDescriptor(
    '/proto.QuestionService/ReduceQuestion',
    grpcWeb.MethodType.UNARY,
    question_pb.ReduceQuestionRequest,
    question_pb.ReduceQuestionResponse,
    (request: question_pb.ReduceQuestionRequest) => {
      return request.serializeBinary();
    },
    question_pb.ReduceQuestionResponse.deserializeBinary
  );

  reduceQuestion(
    request: question_pb.ReduceQuestionRequest,
    metadata: grpcWeb.Metadata | null): Promise<question_pb.ReduceQuestionResponse>;

  reduceQuestion(
    request: question_pb.ReduceQuestionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: question_pb.ReduceQuestionResponse) => void): grpcWeb.ClientReadableStream<question_pb.ReduceQuestionResponse>;

  reduceQuestion(
    request: question_pb.ReduceQuestionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: question_pb.ReduceQuestionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.QuestionService/ReduceQuestion',
        request,
        metadata || {},
        this.methodDescriptorReduceQuestion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.QuestionService/ReduceQuestion',
    request,
    metadata || {},
    this.methodDescriptorReduceQuestion);
  }

  methodDescriptorSeparateQuestion = new grpcWeb.MethodDescriptor(
    '/proto.QuestionService/SeparateQuestion',
    grpcWeb.MethodType.UNARY,
    question_pb.SeparateQuestionRequest,
    question_pb.SeparateQuestionResponse,
    (request: question_pb.SeparateQuestionRequest) => {
      return request.serializeBinary();
    },
    question_pb.SeparateQuestionResponse.deserializeBinary
  );

  separateQuestion(
    request: question_pb.SeparateQuestionRequest,
    metadata: grpcWeb.Metadata | null): Promise<question_pb.SeparateQuestionResponse>;

  separateQuestion(
    request: question_pb.SeparateQuestionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: question_pb.SeparateQuestionResponse) => void): grpcWeb.ClientReadableStream<question_pb.SeparateQuestionResponse>;

  separateQuestion(
    request: question_pb.SeparateQuestionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: question_pb.SeparateQuestionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.QuestionService/SeparateQuestion',
        request,
        metadata || {},
        this.methodDescriptorSeparateQuestion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.QuestionService/SeparateQuestion',
    request,
    metadata || {},
    this.methodDescriptorSeparateQuestion);
  }

}

