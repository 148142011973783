
import { defineComponent } from "vue";

export default defineComponent({
  name: "ChaptersList",

  data() {
    return {
      chapterItems: [
        {
          title: "Цифровой профиль пользователя",
          url: "user-projects",
        },
        {
          title: "Цифровой профиль проекта ",
          url: "user-digital-project",
        },
        {
          title: "Сводная аналитика",
          url: "intersection",
        },
        {
          title: "Сегментация аудитории",
          url: "user-loyalty",
        },

        // {
        //   title: "Просмотр профиля выбранного пользователя",
        //   url: "aehr",
        // },
        // {
        //   title: "Прогнозирование аудитории",
        //   url: "dky",
        // },
        // {
        //   title: "Оповещение аудитории",
        //   url: "sh",
        // },
      ],
    };
  },
});
