import { GetterTree, MutationTree, ActionTree } from 'vuex'

class State {
  checkedProjects: any[]
  checkedMetrics: any = {}
  checkedPeriods: any = []
  checkedMetricsNames: any = []
}

// Создаем геттеры
const getters = <GetterTree<State, any>>{
  getCheckedProjects(state): any[] {
    return state.checkedProjects
  },

  getCheckedMetrics(state): any[] {
    return state.checkedMetrics
  },

  getCheckedMetricsNames(state) {
    return state.checkedMetricsNames
  },

  getCheckedPeriods(state): any[] {
    return state.checkedPeriods
  },
}

// Создаем мутации
const mutations = <MutationTree<State>>{
  setCheckedProjects(state, payload: any[]) {
    state.checkedProjects = payload
  },

  setCheckedMetrics(state, payload: any[]) {
    console.log('PAYLOAD', payload)

    const checkedKeys = Object.keys(payload)

    if (checkedKeys.length < 2) {
      state.checkedMetrics.from = payload[checkedKeys[0] as any]?.ids

      state.checkedMetrics.fromName = payload[checkedKeys[0] as any]?.name
    }
    state.checkedMetrics.from = payload[checkedKeys[0] as any]?.ids

    state.checkedMetrics.fromName = payload[checkedKeys[0] as any]?.name

    state.checkedMetrics.to = payload[checkedKeys[1] as any]?.ids

    state.checkedMetrics.toName = payload[checkedKeys[1] as any]?.name

    console.log('STATE METRICS ', state.checkedMetrics)
  },

  setCheckedMetricsNames(state, payload) {
    state.checkedMetricsNames = payload
  },

  setCheckedPeriods(state, payload: any[]) {
    state.checkedPeriods = payload
  },
}

// Создаем действия
const actions = <ActionTree<State, any>>{
  setCheckedProjects({ commit }, data: any) {
    commit('setCheckedProjects', data)
  },

  setCheckedMetrics({ commit }, data: any) {
    commit('setCheckedMetrics', data)
  },

  setCheckedMetricsNames({ commit }, data) {
    commit('setCheckedMetricsNames', data)
  },

  setCheckedPeriods({ commit }, data: any) {
    commit('setCheckedPeriods', data)
  },
}

// Создаем модуль
const CheckedProjectsModule: any = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default CheckedProjectsModule
