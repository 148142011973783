import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { DistrictRegionApi } from '../services/api/ReginoDistrictApi'

const regDistrApi = new DistrictRegionApi()

class State {
  isRegionBusy: boolean = false
  isDistrictBusy: boolean = false
  regionList: any = []
  districtList: any = []
}

const getters = <GetterTree<State, any>>{
  isRegionBusy(state): boolean {
    return state.isRegionBusy
  },
  isDistrictBusy(state): boolean {
    return state.isDistrictBusy
  },

  getRegionList(state) {
    return state.regionList
  },

  getDistrictList(state) {
    return state.districtList
  },
}

const mutations = <MutationTree<State>>{
  setIsRegionBusy(state, payload) {
    state.isRegionBusy = payload
  },

  setIsDistrictBusy(state, payload) {
    state.isDistrictBusy = payload
  },

  setRegionList(state, payload) {
    state.regionList = payload
  },

  setDistrictList(state, payload) {
    state.districtList = payload
  },
}

const actions = <ActionTree<State, any>>{
  getRegionList({ commit }, filter: any) {
    commit('setIsRegionBusy', true)

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      regDistrApi.ListRegion(filter).then(
        (message: any) => {
          const items = message.getItemsList()

          commit('setRegionList', items)
          commit('setIsRegionBusy', false)

          resolve(items)
        },
        (error) => {
          commit('setRegionList', [])
          commit('setIsRegionBusy', false)
          reject(error)
        }
      )
    })
  },
  getDistrictList({ commit }, filter: any) {
    commit('setIsDistrictBusy', true)

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      regDistrApi.ListDistrict(filter).then(
        (message: any) => {
          const items = message.getItemsList()

          commit('setDistrictList', items)
          commit('setIsDistrictBusy', false)

          resolve(items)
        },
        (error) => {
          commit('setDistrictList', [])
          commit('setIsDistrictBusy', false)
          reject(error)
        }
      )
    })
  },
}

const RegDistrModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default RegDistrModule
