
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'EstimationList',

    data() {
        return {
            addonList: [
                {
                    title: 'Настройка модели',
                    url: 'estimation/set-model',
                },
                {
                    title: 'Оценка',
                    url: 'estimation/analysis',
                },

                // {
                //   title: "Просмотр профиля выбранного пользователя",
                //   url: "aehr",
                // },
                // {
                //   title: "Прогнозирование аудитории",
                //   url: "dky",
                // },
                // {
                //   title: "Оповещение аудитории",
                //   url: "sh",
                // },
            ],
        }
    },
})
