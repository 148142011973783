/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v5.27.1
// source: metric_value.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as metric_value_pb from './metric_value_pb';


export class ActivityMetricValueServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateActivityMetricValue = new grpcWeb.MethodDescriptor(
    '/proto.ActivityMetricValueService/CreateActivityMetricValue',
    grpcWeb.MethodType.UNARY,
    metric_value_pb.CreateActivityMetricValueRequest,
    metric_value_pb.CreateActivityMetricValueResponse,
    (request: metric_value_pb.CreateActivityMetricValueRequest) => {
      return request.serializeBinary();
    },
    metric_value_pb.CreateActivityMetricValueResponse.deserializeBinary
  );

  createActivityMetricValue(
    request: metric_value_pb.CreateActivityMetricValueRequest,
    metadata: grpcWeb.Metadata | null): Promise<metric_value_pb.CreateActivityMetricValueResponse>;

  createActivityMetricValue(
    request: metric_value_pb.CreateActivityMetricValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metric_value_pb.CreateActivityMetricValueResponse) => void): grpcWeb.ClientReadableStream<metric_value_pb.CreateActivityMetricValueResponse>;

  createActivityMetricValue(
    request: metric_value_pb.CreateActivityMetricValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metric_value_pb.CreateActivityMetricValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityMetricValueService/CreateActivityMetricValue',
        request,
        metadata || {},
        this.methodDescriptorCreateActivityMetricValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityMetricValueService/CreateActivityMetricValue',
    request,
    metadata || {},
    this.methodDescriptorCreateActivityMetricValue);
  }

  methodDescriptorUpdateActivityMetricValue = new grpcWeb.MethodDescriptor(
    '/proto.ActivityMetricValueService/UpdateActivityMetricValue',
    grpcWeb.MethodType.UNARY,
    metric_value_pb.UpdateActivityMetricValueRequest,
    metric_value_pb.UpdateActivityMetricValueResponse,
    (request: metric_value_pb.UpdateActivityMetricValueRequest) => {
      return request.serializeBinary();
    },
    metric_value_pb.UpdateActivityMetricValueResponse.deserializeBinary
  );

  updateActivityMetricValue(
    request: metric_value_pb.UpdateActivityMetricValueRequest,
    metadata: grpcWeb.Metadata | null): Promise<metric_value_pb.UpdateActivityMetricValueResponse>;

  updateActivityMetricValue(
    request: metric_value_pb.UpdateActivityMetricValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metric_value_pb.UpdateActivityMetricValueResponse) => void): grpcWeb.ClientReadableStream<metric_value_pb.UpdateActivityMetricValueResponse>;

  updateActivityMetricValue(
    request: metric_value_pb.UpdateActivityMetricValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metric_value_pb.UpdateActivityMetricValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityMetricValueService/UpdateActivityMetricValue',
        request,
        metadata || {},
        this.methodDescriptorUpdateActivityMetricValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityMetricValueService/UpdateActivityMetricValue',
    request,
    metadata || {},
    this.methodDescriptorUpdateActivityMetricValue);
  }

  methodDescriptorDeleteActivityMetricValue = new grpcWeb.MethodDescriptor(
    '/proto.ActivityMetricValueService/DeleteActivityMetricValue',
    grpcWeb.MethodType.UNARY,
    metric_value_pb.DeleteActivityMetricValueRequest,
    metric_value_pb.DeleteActivityMetricValueResponse,
    (request: metric_value_pb.DeleteActivityMetricValueRequest) => {
      return request.serializeBinary();
    },
    metric_value_pb.DeleteActivityMetricValueResponse.deserializeBinary
  );

  deleteActivityMetricValue(
    request: metric_value_pb.DeleteActivityMetricValueRequest,
    metadata: grpcWeb.Metadata | null): Promise<metric_value_pb.DeleteActivityMetricValueResponse>;

  deleteActivityMetricValue(
    request: metric_value_pb.DeleteActivityMetricValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metric_value_pb.DeleteActivityMetricValueResponse) => void): grpcWeb.ClientReadableStream<metric_value_pb.DeleteActivityMetricValueResponse>;

  deleteActivityMetricValue(
    request: metric_value_pb.DeleteActivityMetricValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metric_value_pb.DeleteActivityMetricValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityMetricValueService/DeleteActivityMetricValue',
        request,
        metadata || {},
        this.methodDescriptorDeleteActivityMetricValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityMetricValueService/DeleteActivityMetricValue',
    request,
    metadata || {},
    this.methodDescriptorDeleteActivityMetricValue);
  }

  methodDescriptorListActivityMetricValue = new grpcWeb.MethodDescriptor(
    '/proto.ActivityMetricValueService/ListActivityMetricValue',
    grpcWeb.MethodType.UNARY,
    metric_value_pb.ListActivityMetricValueRequest,
    metric_value_pb.ListActivityMetricValueResponse,
    (request: metric_value_pb.ListActivityMetricValueRequest) => {
      return request.serializeBinary();
    },
    metric_value_pb.ListActivityMetricValueResponse.deserializeBinary
  );

  listActivityMetricValue(
    request: metric_value_pb.ListActivityMetricValueRequest,
    metadata: grpcWeb.Metadata | null): Promise<metric_value_pb.ListActivityMetricValueResponse>;

  listActivityMetricValue(
    request: metric_value_pb.ListActivityMetricValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metric_value_pb.ListActivityMetricValueResponse) => void): grpcWeb.ClientReadableStream<metric_value_pb.ListActivityMetricValueResponse>;

  listActivityMetricValue(
    request: metric_value_pb.ListActivityMetricValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metric_value_pb.ListActivityMetricValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityMetricValueService/ListActivityMetricValue',
        request,
        metadata || {},
        this.methodDescriptorListActivityMetricValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityMetricValueService/ListActivityMetricValue',
    request,
    metadata || {},
    this.methodDescriptorListActivityMetricValue);
  }

}

