import { GetterTree, MutationTree, ActionTree } from 'vuex'

class State {
  settings = {
    minNeighbourCnt: 40,
    maxNeighbourLen: 20,
    addParameters: false,
    pcr1: 0,
    pcr2: 0,
    pcr3: 0,
    cntMaxActivity: 300,
    sumActivity: 0.8,
    lowEdgePoints: 500,
    orPrc: 40,
  }
}

const getters = <GetterTree<State, any>>{
  getModalSettings(state) {
    return state.settings
  },
}

const mutations = <MutationTree<State>>{
  setSettings(state, payload) {
    // const tempSettingModal = {
    //   minNeighbourCnt: payload.minNeighbourCnt,
    //   maxNeighbourLen: payload.maxNeighbourLen,
    //   addParameters: payload.addParameters,
    //   pcr1: payload.pcr1,
    //   pcr2: payload.pcr2,
    //   pcr3: payload.pcr3,
    //   cntMaxActivity: payload.cntMaxActivity,
    //   sumActivity: payload.sumActivity,
    //   lowEdgePoints: payload.lowEdgePoints,
    // }

    // state.segmentationSpent = tmpSettingsSegmSpent
    // state.segmentationSpent = { ...payload }
    // state.settings = tempSettingModal
    state.settings = { ...state.settings, ...payload }
  },
}

const actions = <ActionTree<State, any>>{
  setModalSettings({ commit }, payload) {
    commit('setSettings', payload)
  },
}

const settingsModalModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default settingsModalModule
