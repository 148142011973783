import script from "./UserLoyalty.vue?vue&type=script&setup=true&lang=ts"
export * from "./UserLoyalty.vue?vue&type=script&setup=true&lang=ts"

import "./UserLoyalty.vue?vue&type=style&index=0&id=3dbd2bcb&lang=scss"

const __exports__ = script;

export default __exports__
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpace,QBtn,QTooltip});
