import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { EventsApi } from '../services/api/EventsApi'
import Loading from 'quasar/src/plugins/loading/Loading.js';

class State {
  isEventsBusy: boolean = false
  isLastEventBusy: boolean = false
  eventsList: Array<any> = []
  selectedEventsList: Array<string> = []
  date: {
    dateFrom: any
    dateTo: any
  }
  selectedProjectsForEvent: any
  eventCnt: number
  eventListPagination: any
  isExportEventsBusy: boolean = false
}

const eventsApi = new EventsApi()

const getters = <GetterTree<State, any>>{
  isEventsBusy(state): boolean {
    return state.isEventsBusy
  },

  isLastEventBusy(state) {
    return state.isLastEventBusy
  },

  getEventsList(state) {
    return state.eventsList
  },

  getSelectedEvents(state) {
    return state.selectedEventsList
  },

  getDateForEventList(state) {
    return state.date
  },

  getSelectedProjectsForEvent(state) {
    return state.selectedProjectsForEvent
  },

  getEventListCnt(state) {
    return state.eventCnt
  },

  getEventListPagination(state) {
    return state.eventListPagination
  },

  isExportEventsBusy(state) {
    return state.isExportEventsBusy
  },
}

const mutations = <MutationTree<State>>{
  setIsEventsBusy(state, payload: boolean) {
    state.isEventsBusy = payload
    // if (payload) {
    //   Loading.show()
    // } else {
    //   Loading.hide()
    // }
  },

  setIsLastEventBusy(state, payload: boolean) {
    state.isLastEventBusy = payload
  },

  setEventsList(state, payload) {
    state.eventsList = payload
  },

  setSelectedEvents(state, payload) {
    state.selectedEventsList = payload
  },

  setDateForEvent(state, payload) {
    state.date = payload
  },

  setSelectedProjectsForEvent(state, payload) {
    state.selectedProjectsForEvent = payload
  },

  setEventListCnt(state, payload) {
    state.eventCnt = payload
  },

  setPaginationForEventList(state, payload) {
    state.eventListPagination = payload
  },

  setIsExportEventsBusy(state, payload) {
    state.isExportEventsBusy = payload
  },
}

const actions = <ActionTree<State, any>>{
  setProjectsForEvent({ commit }, data) {
    commit('setSelectedProjectsForEvent', data)
  },

  setDateForEvent({ commit }, data) {
    commit('setDateForEvent', data)
  },

  getEventsList({ commit }, data: any) {

    commit('setIsEventsBusy', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []
    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : {}
    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      eventsApi.getEventsList(filter, pagination, sort).then(
        (message: any) => {
          const items = message.getItemsList()
          const pagination = message.getParams().getPagination()

          commit('setEventListCnt', pagination.getCnt())
          commit('setEventsList', items)
          commit('setIsEventsBusy', false)

          resolve({ items, pagination })
        },
        (error) => {
          commit('setEventsList', [])
          commit('setIsEventsBusy', false)
          reject(error)
        }
      )
    })
  },

  getEventsListForExport({ commit }, data: any) {
    commit('setIsExportEventsBusy', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []
    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : {}
    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      eventsApi.getEventsList(filter, pagination, sort).then(
        (message: any) => {
          const items = message.getItemsList()

          commit('setIsExportEventsBusy', false)

          resolve(items)
        },
        (error) => {
          commit('setIsExportEventsBusy', false)
          reject(error)
        }
      )
    })
  },

  setSelectedEvents({ commit }, data: Array<String>) {
    commit('setSelectedEvents', data)
  },

  getLastEvent({ commit }, id) {
    commit('setIsLastEventBusy', true)
    return new Promise((resolve, reject) => {
      // Получаем список элементов
      eventsApi.getLastActivity(id).then(
        (message: any) => {
          const item = message.getItem()
          commit('setIsLastEventBusy', false)

          resolve(item)
        },
        (error) => {
          commit('setIsLastEventBusy', false)
          reject(error)
        }
      )
    })
  },

  getEventsCounts({ commit }, data) {
    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []
    const pagination: any =
      data !== undefined && data.pagination !== undefined ? data.pagination : {}
    const sort: any =
      data !== undefined && data.sort !== undefined ? data.sort : {}

    return new Promise((resolve, reject) => {
      eventsApi.getEventsCount(filter, pagination, sort).then(
        (message: any) => {
          const cnt = message.getCnt()

          resolve(cnt)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
}

const AccountEventsModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default AccountEventsModule
