import { ActionServiceClient } from '../../proto/pb/ActionsServiceClientPb'
import { Action, ListActionRequest, ListActionPointRequest } from '../../proto/pb/actions_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class ActionsApi {
  Actions: Array<Action>
  client: ActionServiceClient
  metadata: any

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? ''
    this.client = new ActionServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  getActionList(filter: any) {
    const request = new ListActionRequest()

    // Устанавливаем параметры запроса
    request.setProjectIdsList(filter.project_ids).setSsoId(filter.sso_id)

    return this.client.listAction(request, { Authorization: localStorage.getItem('access-key') } as any)
  }

  getActionPointList(filter: any) {
    const request = new ListActionPointRequest()

    // Устанавливаем параметры запроса
    request
        .setSsoId(filter.sso_id)
        .setProjectIdsList(filter.project_ids)

    console.log('actionpoint req', request)

    return this.client.listActionPoint(request, { Authorization: localStorage.getItem('access-key') } as any)
  }
}
