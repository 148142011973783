import ProjectCrudList from "../views/ProjectCrudList.vue";

const routes = [
  {
    path: "/reference/project-directory",
    name: "ProjectCrudList",
    component: ProjectCrudList,
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        { text: "Справка", to: { name: "ReferencePage" } },
        { text: "Справочник проектов" },
      ],
    },
  },
];

export default {
  routes,
};
