/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: projects.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.proto = require('./projects_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ProjectsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ProjectsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateProjectsRequest,
 *   !proto.proto.CreateProjectsResponse>}
 */
const methodDescriptor_ProjectsService_CreateProjects = new grpc.web.MethodDescriptor(
  '/proto.ProjectsService/CreateProjects',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateProjectsRequest,
  proto.proto.CreateProjectsResponse,
  /**
   * @param {!proto.proto.CreateProjectsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreateProjectsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CreateProjectsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreateProjectsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ProjectsServiceClient.prototype.createProjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ProjectsService/CreateProjects',
      request,
      metadata || {},
      methodDescriptor_ProjectsService_CreateProjects,
      callback);
};


/**
 * @param {!proto.proto.CreateProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreateProjectsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ProjectsServicePromiseClient.prototype.createProjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ProjectsService/CreateProjects',
      request,
      metadata || {},
      methodDescriptor_ProjectsService_CreateProjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateProjectsRequest,
 *   !proto.proto.UpdateProjectsResponse>}
 */
const methodDescriptor_ProjectsService_UpdateProjects = new grpc.web.MethodDescriptor(
  '/proto.ProjectsService/UpdateProjects',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateProjectsRequest,
  proto.proto.UpdateProjectsResponse,
  /**
   * @param {!proto.proto.UpdateProjectsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpdateProjectsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpdateProjectsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpdateProjectsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ProjectsServiceClient.prototype.updateProjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ProjectsService/UpdateProjects',
      request,
      metadata || {},
      methodDescriptor_ProjectsService_UpdateProjects,
      callback);
};


/**
 * @param {!proto.proto.UpdateProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpdateProjectsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ProjectsServicePromiseClient.prototype.updateProjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ProjectsService/UpdateProjects',
      request,
      metadata || {},
      methodDescriptor_ProjectsService_UpdateProjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteProjectsRequest,
 *   !proto.proto.DeleteProjectsResponse>}
 */
const methodDescriptor_ProjectsService_DeleteProjects = new grpc.web.MethodDescriptor(
  '/proto.ProjectsService/DeleteProjects',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteProjectsRequest,
  proto.proto.DeleteProjectsResponse,
  /**
   * @param {!proto.proto.DeleteProjectsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.DeleteProjectsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.DeleteProjectsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.DeleteProjectsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ProjectsServiceClient.prototype.deleteProjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ProjectsService/DeleteProjects',
      request,
      metadata || {},
      methodDescriptor_ProjectsService_DeleteProjects,
      callback);
};


/**
 * @param {!proto.proto.DeleteProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.DeleteProjectsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ProjectsServicePromiseClient.prototype.deleteProjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ProjectsService/DeleteProjects',
      request,
      metadata || {},
      methodDescriptor_ProjectsService_DeleteProjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListProjectsRequest,
 *   !proto.proto.ListProjectsResponse>}
 */
const methodDescriptor_ProjectsService_ListProjects = new grpc.web.MethodDescriptor(
  '/proto.ProjectsService/ListProjects',
  grpc.web.MethodType.UNARY,
  proto.proto.ListProjectsRequest,
  proto.proto.ListProjectsResponse,
  /**
   * @param {!proto.proto.ListProjectsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListProjectsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListProjectsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListProjectsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ProjectsServiceClient.prototype.listProjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ProjectsService/ListProjects',
      request,
      metadata || {},
      methodDescriptor_ProjectsService_ListProjects,
      callback);
};


/**
 * @param {!proto.proto.ListProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListProjectsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ProjectsServicePromiseClient.prototype.listProjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ProjectsService/ListProjects',
      request,
      metadata || {},
      methodDescriptor_ProjectsService_ListProjects);
};


module.exports = proto.proto;

