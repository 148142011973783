import { GetterTree, MutationTree, ActionTree } from "vuex";
import { AnswerApi } from "../services/api/AnswerApi";
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
  isAnswerBusy: boolean = false;
  answerItems: unknown [];
  answerItem: unknown;
}

// Создаем объект
const answerApi = new AnswerApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

  isAnswerBusy(state): boolean {
    return state.isAnswerBusy;
  },

  getAnswerItems(state): unknown[] {
    return state.answerItems;
  },

  getAnswerItem(state): unknown {
    return state.answerItem;
  }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

  setIsAnswerBusy(state, payload: boolean) {
    state.isAnswerBusy = payload
  },

  setAnswerItems(state, payload: unknown[]) {
    state.answerItems = payload
  },

  setAnswerItem(state, payload: unknown) {
    state.answerItem = payload
  }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

  // Устанавливаем элемент
  setAnswerItem({ commit }, data: any) {
    commit('setAnswerItem', data);
  },
  
  // Список элементов
  getAnswerItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      answerApi.getAnswerItems(filter, sort, pagination)
        .then(
          (message:any) => {
            const items = message.getItemsList();
            const sort = message.getParams().getSort();
            const pagination = message.getParams().getPagination();
            commit('setAnswerItems', items);
            commit('setIsAnswerBusy', false);
            resolve({
                items: items,
                sort: sort,
                pagination: pagination
            });
          },
          (error) => {
            commit('setIsAnswerBusy', false);
            reject(error);
          }
        );
    });
  },

  // Получить элемент
  getAnswerItem({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];

    return new Promise((resolve, reject) => {

      // Получаем элемент
      answerApi.getAnswerItem(filter)
        .then(
          (response:any) => {
            commit('setAnswerItem', response);
            commit('setIsAnswerBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsAnswerBusy', false);
            reject(error);
          }
        );
    });
  }
};

// Создаем модуль
const AnswerModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default AnswerModule;
