import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { AnswerGrpClsFormApi } from '../services/api/AnswerGrpClsFormApi';
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
  isAnswerGrpClsFormBusy: boolean = false;
  answerGrpClsFormItems: unknown [];
}

// Создаем объект
const answerGrpClsFormApi = new AnswerGrpClsFormApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

  isAnswerGrpClsFormBusy(state): boolean {
    return state.isAnswerGrpClsFormBusy;
  },

  getAnswerGrpClsFormItems(state): unknown[] {
    return state.answerGrpClsFormItems;
  }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

  setIsAnswerGrpClsFormBusy(state, payload: boolean) {
    state.isAnswerGrpClsFormBusy = payload
  },

  setAnswerGrpClsFormItems(state, payload: unknown[]) {
    state.answerGrpClsFormItems = payload
  }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

    // Список ответов для выходной формы (для текущего вопроса)
  getAnswerGrpClsQuestionFormItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerGrpClsFormBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const classifier_id:any = data !== undefined && data.classifier_id !== undefined ? data.classifier_id : 0;
    const question_id:any = data !== undefined && data.question_id !== undefined ? data.question_id : 0;

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      answerGrpClsFormApi.getAnswerGrpClsQuestionFormItems(filter, classifier_id, question_id)
        .then(
          (response:any) => {
            commit('setAnswerGrpClsFormItems', response);
            commit('setIsAnswerGrpClsFormBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setAnswerGrpClsFormItems', []);
            commit('setIsAnswerGrpClsFormBusy', false);
            reject(error);
          }
        );
    });
  },

    // Список ответов для выходной формы (для опроса полностью)
  getAnswerGrpClsPollFormItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerGrpClsFormBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const classifier_id:any = data !== undefined && data.classifier_id !== undefined ? data.classifier_id : 0;
    const poll_id:any = data !== undefined && data.poll_id !== undefined ? data.poll_id : 0;

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      answerGrpClsFormApi.getAnswerGrpClsPollFormItems(filter, classifier_id, poll_id)
        .then(
          (response:any) => {
            commit('setAnswerGrpClsFormItems', response);
            commit('setIsAnswerGrpClsFormBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setAnswerGrpClsFormItems', []);
            commit('setIsAnswerGrpClsFormBusy', false);
            reject(error);
          }
        );
    });
  }
};

// Создаем модуль
const AnswerGrpClsFormModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default AnswerGrpClsFormModule;
