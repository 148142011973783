import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { UserAnswers } from "@/components/modules/accounts/services/api/UserAnswers";

const userAnswers = new UserAnswers()

class State {
    userAnswers: any
    isUserAnswers: boolean
    userGroups: any
}

const getters = <GetterTree<State, any>>{
    getUserAnswers(state) {
        return state.userAnswers
    },

    getIsUserAnswers(state) {
        return state.isUserAnswers
    },

    getUserGroups(state) {
        return state.userGroups
    },
}

const mutations = <MutationTree<State>>{
    setUserAnswers(state, payload) {
        state.userAnswers = payload
    },

    setIsUserAnswers(state, payload) {
        state.isUserAnswers = payload
    },

    setUserGroups(state, payload) {
        state.userGroups = payload
    },
}

const actions = <ActionTree<State, any>>{
    getUserAnswers({ commit }, data) {
        commit('setIsUserAnswers', true)
        const filter: any =
            data !== undefined && data.filter !== undefined ? data.filter : {}

        const sort: any =
            data !== undefined && data.sort !== undefined ? data.sort : {}

        const pagination: any =
            data !== undefined && data.pagination !== undefined ? data.pagination : {}

        return new Promise((resolve, reject) => {
            userAnswers.getUserAnswers(filter, sort, pagination, data).then(
                (message: any) => {
                    commit('setUserAnswers', message.getItemsList())
                    commit('setIsUserAnswers', false)
                    resolve(message)
                },

                (error: any) => {
                    commit('setIsUserAnswers', false)
                    reject(error)
                    console.log('ERROR ', error)
                }
            )
        })
    },

    getUserGroups({ commit }, data) {
        return new Promise((resolve, reject) => {
            userAnswers.getUserGroups(data).then(
                (message: any) => {
                    const items = message.getGroupsList()

                    commit('setUserGroups', items)
                    resolve(items)
                },

                (error: any) => {
                    commit('setUserGroups', false)
                    reject(error)
                    console.log('ERROR ', error)
                }
            )
        })
    }
}

const userAnswersModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters,
}

export default userAnswersModule