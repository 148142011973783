import {
    DashboardProjectInfoServicePromiseClient
} from '../../proto/pb/project_info_grpc_web_pb';

import {
    DashboardProjectInfo,
    DashboardProjectInfoFilter,
    DashboardProjectInfoFilterItem,
    DashboardProjectInfoPagination, 
    DashboardProjectInfoSort,
    ListDashboardProjectInfoRequest, 
    ListDashboardProjectInfoResponse, 
} from '../../proto/pb/project_info_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 100;

export class ProjectInfoApi {
    items: Array<DashboardProjectInfo> = [];
    client: DashboardProjectInfoServicePromiseClient;
    metadata: any;

    constructor() {
        const host:string = (window as any).VUE_APP_API_URL ?? '';
        this.client = new DashboardProjectInfoServicePromiseClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

     // Установка и получение фильтров
    createFilter(data: any) {

        const filter = new DashboardProjectInfoFilter();
        // Устанавливаем фильтры
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && ('' !== data[i].field_name)) {
                    const fItem = new DashboardProjectInfoFilterItem();
                    fItem.setFieldName(data[i].field_name.toString());
                    fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                    fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                    fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                    filter.addItems(fItem);
                }
            }
        }

        return filter;
    }

    // Установка и получение сортировки
    createSort(data: any){

        const sort = new DashboardProjectInfoSort();
        const name:string = data.name !== undefined ? data.name.toString() : 'cnt';
        const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    // Установка и получение пагинации
    createPagination(data: any){

        const pagination = new DashboardProjectInfoPagination();
        const page:number = data.page !== undefined ? Number(data.page) : 1;
        const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
        const pages:number = data.pages !== undefined ? Number(data.pages) : 0;

        // Устанавливаем пагинацию
        pagination.setPage(page);
        pagination.setLimit(limit);
        pagination.setPages(pages);

        return pagination;
    }

    // Список элементов
    getProjectInfoItems(filter: any, sort: any, pagination: any){

        const request = new ListDashboardProjectInfoRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));

        return this.client.listDashboardProjectInfo(request, this.metadata)
            .then((message) => {
                return message.getItemsList()
            })
    }
}