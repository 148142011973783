import { GetterTree, MutationTree, ActionTree } from "vuex";
import { AnswerClassApi } from "../services/api/AnswerClassApi";
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
  isAnswerClassBusy: boolean = false;
  answerClassItems: unknown [];
  answerClassItem: unknown;
}

// Создаем объект
const answerClassApi = new AnswerClassApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

  isAnswerClassBusy(state): boolean {
    return state.isAnswerClassBusy;
  },

  getAnswerClassItems(state): unknown[] {
    return state.answerClassItems;
  },

  getAnswerClassItem(state): unknown {
    return state.answerClassItem;
  }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

  setIsAnswerClassBusy(state, payload: boolean) {
    state.isAnswerClassBusy = payload
  },

  setAnswerClassItems(state, payload: unknown[]) {
    state.answerClassItems = payload
  },

  setAnswerClassItem(state, payload: unknown) {
    state.answerClassItem = payload
  }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

  // Устанавливаем элемент
  setAnswerClassItem({ commit }, data: any) {
    commit('setAnswerClassItem', data);
  },
  
  // Список элементов (привязанные ответы)
  getAnswerClassItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerClassBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      answerClassApi.getAnswerClassItems(filter, sort, pagination)
        .then(
          (response:any) => {
            commit('setAnswerClassItems', response);
            commit('setIsAnswerClassBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setAnswerClassItems', []);
            commit('setIsAnswerClassBusy', false);
            reject(error);
          }
        );
    });
  },

  // Создать элемент
  createAnswerClassItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerClassBusy', true);

    return new Promise((resolve, reject) => {

      // Создаем элемент
      answerClassApi.createAnswerClassItem(data)
        .then(
          (response) => {
            commit('setIsAnswerClassBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsAnswerClassBusy', false);
            reject(error);
          }
        );
    });
  },

  // Обновить элемент
  updateAnswerClassItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerClassBusy', true);

    return new Promise((resolve, reject) => {

      // Обновляем элемент
      answerClassApi.updateAnswerClassItem(data)
        .then(
          (response) => {
            commit('setIsAnswerClassBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsAnswerClassBusy', false);
            reject(error);
          }
        );
    });
  },

  // Удалить элемент
  deleteAnswerClassItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerClassBusy', true);

    return new Promise((resolve, reject) => {

      // Удаляем элемент
      answerClassApi.deleteAnswerClassItem(data)
        .then(
          (response) => {
            commit('setIsAnswerClassBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsAnswerClassBusy', false);
            reject(error);
          }
        );
    });
  },

  saveAnswerClass({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerClassBusy', true);

    return new Promise((resolve, reject) => {

      // Сохраняем несколько элементов
      answerClassApi.saveAnswerClass(data)
        .then(
          (response:any) => {
            commit('setIsAnswerClassBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsAnswerClassBusy', false);
            reject(error);
          }
        );
    });
  },

  removeAnswerClass({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsAnswerClassBusy', true);

    return new Promise((resolve, reject) => {

      // Сохраняем несколько элементов
      answerClassApi.removeAnswerClass(data)
        .then(
          (response:any) => {
            commit('setIsAnswerClassBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsAnswerClassBusy', false);
            reject(error);
          }
        );
    });
  }
};

// Создаем модуль
const AnswerClassModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default AnswerClassModule;
