/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: social_network_message_grp_cls.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as social_network_message_grp_cls_pb from './social_network_message_grp_cls_pb';


export class SocialNetworkMessageGrpClsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSocialNetworkListMessageGrpClsIn = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageGrpClsService/SocialNetworkListMessageGrpClsIn',
    grpcWeb.MethodType.UNARY,
    social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsInRequest,
    social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsInResponse,
    (request: social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsInRequest) => {
      return request.serializeBinary();
    },
    social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsInResponse.deserializeBinary
  );

  socialNetworkListMessageGrpClsIn(
    request: social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsInRequest,
    metadata: grpcWeb.Metadata | null): Promise<social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsInResponse>;

  socialNetworkListMessageGrpClsIn(
    request: social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsInRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsInResponse) => void): grpcWeb.ClientReadableStream<social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsInResponse>;

  socialNetworkListMessageGrpClsIn(
    request: social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsInRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsInResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageGrpClsService/SocialNetworkListMessageGrpClsIn',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkListMessageGrpClsIn,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageGrpClsService/SocialNetworkListMessageGrpClsIn',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkListMessageGrpClsIn);
  }

  methodDescriptorSocialNetworkListMessageGrpClsOut = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageGrpClsService/SocialNetworkListMessageGrpClsOut',
    grpcWeb.MethodType.UNARY,
    social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsOutRequest,
    social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsOutResponse,
    (request: social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsOutRequest) => {
      return request.serializeBinary();
    },
    social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsOutResponse.deserializeBinary
  );

  socialNetworkListMessageGrpClsOut(
    request: social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsOutRequest,
    metadata: grpcWeb.Metadata | null): Promise<social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsOutResponse>;

  socialNetworkListMessageGrpClsOut(
    request: social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsOutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsOutResponse) => void): grpcWeb.ClientReadableStream<social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsOutResponse>;

  socialNetworkListMessageGrpClsOut(
    request: social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsOutRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_grp_cls_pb.SocialNetworkListMessageGrpClsOutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageGrpClsService/SocialNetworkListMessageGrpClsOut',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkListMessageGrpClsOut,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageGrpClsService/SocialNetworkListMessageGrpClsOut',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkListMessageGrpClsOut);
  }

}

