import { GetterTree, MutationTree, ActionTree } from "vuex";
import { ClassTagApi } from "../services/api/ClassTagApi";
import Loading from 'quasar/src/plugins/loading/Loading.js';
//import MainMixin from "@/mixins/main";

class State {
  isClassTagBusy: boolean = false;
  classTagItems: unknown [];
  classTagItem: unknown;
}

// Создаем объект
const classTagApi = new ClassTagApi();

// Создаем геттеры
const getters = <GetterTree<State, any>>{

  isClassTagBusy(state): boolean {
    return state.isClassTagBusy;
  },

  getClassTagItems(state): unknown[] {
    return state.classTagItems;
  },

  getClassTagItem(state): unknown {
    return state.classTagItem;
  }
};

// Создаем мутации
const mutations = <MutationTree<State>>{

  setIsClassTagBusy(state, payload: boolean) {
    state.isClassTagBusy = payload
  },

  setClassTagItems(state, payload: unknown[]) {
    state.classTagItems = payload
  },

  setClassTagItem(state, payload: unknown) {
    state.classTagItem = payload
  }
};

// Создаем действия
const actions = <ActionTree<State, any>>{

  // Устанавливаем элемент
  setClassTagItem({ commit }, data: any) {
    commit('setClassTagItem', data);
  },
  
  // Список элементов
  getClassTagItems({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsClassTagBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
    const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
    const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

    return new Promise((resolve, reject) => {

      // Получаем список элементов
      classTagApi.getClassTagItems(filter, sort, pagination)
        .then(
          (response:any) => {
            commit('setClassTagItems', response);
            commit('setIsClassTagBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsClassTagBusy', false);
            reject(error);
          }
        );
    });
  },
/*
  // Получить элемент
  getClassTagItem({ commit }, data: any) {

    // Устанавливаем событие загрузки
    commit('setIsClassTagBusy', true);

    const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];

    return new Promise((resolve, reject) => {

      // Получаем элемент
      classTagApi.getClassTagItem(filter)
        .then(
          (response:any) => {
            commit('setClassTagItem', response);
            commit('setIsClassTagBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsClassTagBusy', false);
            reject(error);
          }
        );
    });
  },
*/

  saveClassTag({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsClassTagBusy', true);

    return new Promise((resolve, reject) => {

      // Сохраняем несколько элементов
      classTagApi.saveClassTag(data)
        .then(
          (response:any) => {
            commit('setIsClassTagBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsClassTagBusy', false);
            reject(error);
          }
        );
    });
  },

  // Создать элемент
  createClassTagItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsClassTagBusy', true);

    return new Promise((resolve, reject) => {

      // Создаем элемент
      classTagApi.createClassTagItem(data)
        .then(
          (response) => {
            commit('setIsClassTagBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsClassTagBusy', false);
            reject(error);
          }
        );
    });
  },

  // Обновить элемент
  updateClassTagItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsClassTagBusy', true);

    return new Promise((resolve, reject) => {

      // Обновляем элемент
      classTagApi.updateClassTagItem(data)
        .then(
          (response) => {
            commit('setIsClassTagBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsClassTagBusy', false);
            reject(error);
          }
        );
    });
  },

  // Удалить элемент
  deleteClassTagItem({ commit }, data) {

    // Устанавливаем событие загрузки
    commit('setIsClassTagBusy', true);

    return new Promise((resolve, reject) => {

      // Удаляем элемент
      classTagApi.deleteClassTagItem(data)
        .then(
          (response) => {
            commit('setIsClassTagBusy', false);
            resolve(response);
          },
          (error) => {
            commit('setIsClassTagBusy', false);
            reject(error);
          }
        );
    });
  }
};

// Создаем модуль
const ClassTagModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default ClassTagModule;
