import {
  ScorecardValueAccountServicePromiseClient
} from "../../proto/pb/scorecard_value_account_grpc_web_pb";
import {
  ScorecardValueAccount, 
  ListScorecardValueAccountRequest,
  //ItemScorecardValueAccountRequest,
  ScorecardValueAccountFilter,
  ScorecardValueAccountFilterItem,
  ScorecardValueAccountSort,
  ScorecardValueAccountPagination,
  CreateScorecardValueAccountRequest,  
  DeleteScorecardValueAccountRequest,
  CalculateScorecardValueAccountRequest
} from "../../proto/pb/scorecard_value_account_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 1000000;

export class ScorecardValueAccountApi {
  items: Array<ScorecardValueAccount> = [];
  client: ScorecardValueAccountServicePromiseClient;
  metadata: any;

  constructor() {
    const host:string = (window as any).VUE_APP_API_URL ?? '';
    this.client = new ScorecardValueAccountServicePromiseClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  // Установка и получение фильтров
  createFilter(data: any){

    const filter = new ScorecardValueAccountFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
        for (const i in data) {
            if (data[i].field_name && ('' !== data[i].field_name)) {
                const fItem = new ScorecardValueAccountFilterItem();
                fItem.setFieldName(data[i].field_name.toString());
                fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                filter.addItems(fItem);
            }
        }
    }

    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any){

    const sort = new ScorecardValueAccountSort();
    const name:string = data.name !== undefined ? data.name.toString() : 'account_guid';
    const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any){

    const pagination = new ScorecardValueAccountPagination();
    const page:number = data.page !== undefined ? Number(data.page) : 1;
    const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages:number = data.pages !== undefined ? Number(data.pages) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  // Список элементов
  getScorecardValueAccountItems(filter: any, sort: any, pagination: any){

    const request = new ListScorecardValueAccountRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));

    return this.client.listScorecardValueAccount(request, this.metadata)
        .then((message) => {
            return message.getItemsList()
        })
  }

  // Получение элемента
  getScorecardValueAccountItem(filter: any){

    const request = new ListScorecardValueAccountRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort({}));
    request.setPagination(this.createPagination({}));

    return this.client.listScorecardValueAccount(request, this.metadata)
        .then((message) => {
            const itemList = message.getItemsList()

            if (itemList && (itemList.length > 0)) {
                return itemList[0]
            }
        })
  }

  // Создание элемента
  createScorecardValueAccountItem(data: any){

    const request = new CreateScorecardValueAccountRequest();
    request.setAccountGuid(data.guid);
    request.setProjectId(data.project_id);
    request.setPeriodId(data.period_id);
    request.setScoreValueId(data.score_values_id);

    return this.client.createScorecardValueAccount(request, this.metadata);
  }

  // Удаление элемента
  deleteScorecardValueAccountItem(data: any){

    const request = new DeleteScorecardValueAccountRequest();
    request.setAccountGuid(data.guid);
    request.setProjectId(data.project_id);
    request.setPeriodId(data.period_id);
    request.setScoreValueId(data.score_value_id);

    return this.client.deleteScorecardValueAccount(request, this.metadata);
  }

  // Расчет общего количества баллов
  calculateScorecardValueAccount(filter: any){

    const request = new CalculateScorecardValueAccountRequest();
    request.setFilter(this.createFilter(filter));

    return this.client.calculateScorecardValueAccount(request, this.metadata);
  }
}
