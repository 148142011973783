// import accounts from '@/components/modules/accounts/store/index'
// import cluster from '@/components/modules/cluster/store/index'
import activity from "@/components/modules/activity/store/index";
import mainPage from "@/components/modules/main-page/store/index";
import polls from "@/components/modules/polls/store/index";
import projects from "@/components/modules/projects/store/index";
import scoring from "@/components/modules/scoring/store/index";
import users from "@/components/modules/users/store/index";
import AccountsModule from "@/components/modules/accounts/store/index";
import intersectionModule from "@/components/modules/intersection/store/index";

import checkedProjects from "@/store/checked-projects";
import currentProject from "@/store/current-project";

// Список модулей
const importModules: any = [
    activity,
    mainPage,
    polls,
    projects,
    scoring,
    users,
    AccountsModule,
    intersectionModule,
  ],
  importStores: any = [checkedProjects, currentProject],
  items = [];
const itemObj: any = {};

for (const i in importModules) {
  for (const j in importModules[i]) {
    itemObj[j] = importModules[i][j];
    items.push(importModules[i][j]);
  }
}

itemObj["checkedProjects"] = importStores[0];
itemObj["currentProject"] = importStores[1];

for (const i in importStores) {
  items.push(importStores[i]);
}
// Список модулей хранилищ
export default {
  itemObj,
};
