import script from "./SettingsModalNew.vue?vue&type=script&setup=true&lang=ts"
export * from "./SettingsModalNew.vue?vue&type=script&setup=true&lang=ts"

import "./SettingsModalNew.vue?vue&type=style&index=0&id=d05e8fe4&lang=scss"

const __exports__ = script;

export default __exports__
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon,QDialog,QTabs,QTab,QBtn,QTabPanels,QTabPanel,QInput,QSelect});
