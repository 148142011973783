import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { scorecardAccountValue } from '../services/api/ScorecardAVApi'
import Loading from 'quasar/src/plugins/loading/Loading.js';

class State {
  isScoreCardAccValBusy: boolean = false
  scorecardAccValList: Array<any> = []
  currentScorecardProjectId: number
}

const scorecardAV = new scorecardAccountValue()

const getters = <GetterTree<State, any>>{
  isScoreCardAccValBusy(state): boolean {
    return state.isScoreCardAccValBusy
  },

  getScorecardAccValList(state) {
    return state.scorecardAccValList
  },

  getCurrentScorecardProject(state) {
    return state.currentScorecardProjectId
  },
}

const mutations = <MutationTree<State>>{
  setIsScoreCardAccValBusy(state, payload: boolean) {
    state.isScoreCardAccValBusy = payload
    // if (payload) {
    //   Loading.show()
    // } else {
    //   Loading.hide()
    // }
  },

  setScorecardAccValList(state, payload) {
    state.scorecardAccValList = payload
  },

  setCurrentScorecardProjectId(state, payload) {
    state.currentScorecardProjectId = payload
  },
}

const actions = <ActionTree<State, any>>{
  getScorecardAccValList({ commit }, data: any) {
    console.log('GET SCORECARD ACC VALUE LIST')
    commit('setIsScoreCardAccValBusy', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      scorecardAV.getScorecardAccValList(filter).then(
        (message: any) => {
          const items = message.getItemsList()

          commit('setScorecardAccValList', items)
          commit('setIsScoreCardAccValBusy', false)

          resolve(items)
        },
        (error) => {
          commit('setScorecardAccValList', [])
          commit('setIsScoreCardAccValBusy', false)
          reject(error)
        }
      )
    })
  },

  setScorecardAccValList({ commit }, data: any) {
    commit('setScorecardAccValList', data)
  },
}

const ScorecardAccountValueModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default ScorecardAccountValueModule
