/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: metric_account_value.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.proto = require('./metric_account_value_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ActivityMetricAccountValueServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ActivityMetricAccountValueServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListActivityMetricAccountValueRequest,
 *   !proto.proto.ListActivityMetricAccountValueResponse>}
 */
const methodDescriptor_ActivityMetricAccountValueService_ListActivityMetricAccountValue = new grpc.web.MethodDescriptor(
  '/proto.ActivityMetricAccountValueService/ListActivityMetricAccountValue',
  grpc.web.MethodType.UNARY,
  proto.proto.ListActivityMetricAccountValueRequest,
  proto.proto.ListActivityMetricAccountValueResponse,
  /**
   * @param {!proto.proto.ListActivityMetricAccountValueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListActivityMetricAccountValueResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListActivityMetricAccountValueRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListActivityMetricAccountValueResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListActivityMetricAccountValueResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ActivityMetricAccountValueServiceClient.prototype.listActivityMetricAccountValue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ActivityMetricAccountValueService/ListActivityMetricAccountValue',
      request,
      metadata || {},
      methodDescriptor_ActivityMetricAccountValueService_ListActivityMetricAccountValue,
      callback);
};


/**
 * @param {!proto.proto.ListActivityMetricAccountValueRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListActivityMetricAccountValueResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ActivityMetricAccountValueServicePromiseClient.prototype.listActivityMetricAccountValue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ActivityMetricAccountValueService/ListActivityMetricAccountValue',
      request,
      metadata || {},
      methodDescriptor_ActivityMetricAccountValueService_ListActivityMetricAccountValue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListIntersectionActivityMetricAccountValueRequest,
 *   !proto.proto.ListIntersectionActivityMetricAccountValueResponse>}
 */
const methodDescriptor_ActivityMetricAccountValueService_ListIntersectionActivityMetricAccountValue = new grpc.web.MethodDescriptor(
  '/proto.ActivityMetricAccountValueService/ListIntersectionActivityMetricAccountValue',
  grpc.web.MethodType.UNARY,
  proto.proto.ListIntersectionActivityMetricAccountValueRequest,
  proto.proto.ListIntersectionActivityMetricAccountValueResponse,
  /**
   * @param {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListIntersectionActivityMetricAccountValueResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListIntersectionActivityMetricAccountValueResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListIntersectionActivityMetricAccountValueResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ActivityMetricAccountValueServiceClient.prototype.listIntersectionActivityMetricAccountValue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ActivityMetricAccountValueService/ListIntersectionActivityMetricAccountValue',
      request,
      metadata || {},
      methodDescriptor_ActivityMetricAccountValueService_ListIntersectionActivityMetricAccountValue,
      callback);
};


/**
 * @param {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListIntersectionActivityMetricAccountValueResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ActivityMetricAccountValueServicePromiseClient.prototype.listIntersectionActivityMetricAccountValue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ActivityMetricAccountValueService/ListIntersectionActivityMetricAccountValue',
      request,
      metadata || {},
      methodDescriptor_ActivityMetricAccountValueService_ListIntersectionActivityMetricAccountValue);
};


module.exports = proto.proto;

