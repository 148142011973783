import {
    MessageClassServiceClient
} from "../../proto/pb/Message_classServiceClientPb";
import {
    MessageClass,
    ListMessageClassRequest,
    //ItemMessageClassRequest,
    MessageClassFilter,
    MessageClassFilterItem,
    MessageClassSort,
    MessageClassPagination,
    CreateMessageClassRequest,
    UpdateMessageClassRequest,
    DeleteMessageClassRequest,
    SaveMessageClassRequest,
    RemoveMessageClassRequest
} from "../../proto/pb/message_class_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 1000;

export class MessageClassApi {
    items: Array<MessageClass> = [];
    client: MessageClassServiceClient;
    metadata: any;

    constructor() {
        const host:string = (window as any).VUE_APP_API_URL ?? '';
        this.client = new MessageClassServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    // Установка и получение фильтров
    createFilter(data: any){

        const filter = new MessageClassFilter();

        // Устанавливаем фильтры
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && ('' !== data[i].field_name)) {
                    const fItem = new MessageClassFilterItem();
                    fItem.setFieldName(data[i].field_name.toString());
                    fItem.setOperand(data[i].operand !== undefined ? data[i].operand : '=');
                    fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                    fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                    filter.addItems(fItem);
                }
            }
        }

        return filter;
    }

    // Установка и получение сортировки
    createSort(data: any){

        const sort = new MessageClassSort();
        const name:string = data.name !== undefined ? data.name.toString() : 'message_grp_id';
        const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    // Установка и получение пагинации
    createPagination(data: any){

        const pagination = new MessageClassPagination();
        const page:number = data.page !== undefined ? Number(data.page) : 1;
        const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
        const pages:number = data.pages !== undefined ? Number(data.pages) : 0;

        // Устанавливаем пагинацию
        pagination.setPage(page);
        pagination.setLimit(limit);
        pagination.setPages(pages);

        return pagination;
    }

    // Список элементов
    getMessageClassItems(filter: any, sort: any, pagination: any){

        const request = new ListMessageClassRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));

        return this.client.listMessageClass(request, this.metadata)
            .then((message) => {
                return message.getItemsList()
            })
    }

    // Создание элемента
    createMessageClassItem(data: any){

        const request = new CreateMessageClassRequest();
        request.setClassId(data.class_id);
        request.setMessageGrpId(data.message_grp_id);
        request.setNotes(data.Notes);

        return this.client.createMessageClass(request, this.metadata);
    }

    // Редактирование элемента
    updateMessageClassItem(data: any){

        const request = new UpdateMessageClassRequest();
        request.setClassId(data.class_id);
        request.setMessageGrpId(data.message_grp_id);
        request.setNotes(data.Notes);

        return this.client.updateMessageClass(request, this.metadata);
    }

    // Удаление элемента
    deleteMessageClassItem(data: any){

        const request = new DeleteMessageClassRequest();
        request.setClassId(data.class_id);

        return this.client.deleteMessageClass(request, this.metadata);
    }

    // Сохранение нескольких элементов
    saveMessageClass(data: any){

        const request = new SaveMessageClassRequest();
        const aItems: Array<MessageClass> = [];

        request.setClassId(data.class_id);

        if (data.items && (data.items.length > 0)) {
            for (const i in data.items) {
                const aItem = new MessageClass();
                aItem.setClassId(data.class_id);
                aItem.setMessageGrpId(data.items[i].message_grp_id);
                aItem.setNotes(data.items[i].notes.toString());
                aItems.push(aItem);
            }
        }

        request.setItemsList(aItems);

        return this.client.saveMessageClass(request, this.metadata);
    }

    // Удаление нескольких элементов
    removeMessageClass(data: any){

        const request = new RemoveMessageClassRequest();
        const aItems: Array<MessageClass> = [];

        request.setClassId(data.class_id);

        if (data.items && (data.items.length > 0)) {
            for (const i in data.items) {
                const aItem = new MessageClass();
                aItem.setClassId(data.class_id);
                aItem.setMessageGrpId(data.items[i].message_grp_id);
                aItem.setNotes(data.items[i].notes.toString());
                aItems.push(aItem);
            }
        }

        request.setItemsList(aItems);

        return this.client.removeMessageClass(request, this.metadata);
    }
}
