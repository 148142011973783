import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { scorecardAccountProject } from '../services/api/ScorecardAPApi'
import Loading from 'quasar/src/plugins/loading/Loading.js';

class State {
  isScoreCardAccPrjBusy: boolean = false
  scorecardAccPrjList: Array<any> = []
  scorecardAccPrjValues: Object[]
}

const scorecardAP = new scorecardAccountProject()

const getters = <GetterTree<State, any>>{
  isScoreCardAccPrjBusy(state): boolean {
    return state.isScoreCardAccPrjBusy
  },

  getScorecardAccPrjList(state) {
    return state.scorecardAccPrjList
  },

  getScorecardAccPrjValues(state) {
    return state.scorecardAccPrjValues
  },
}

const mutations = <MutationTree<State>>{
  setIsScoreCardAccPrjBusy(state, payload: boolean) {
    state.isScoreCardAccPrjBusy = payload
    // if (payload) {
    //   Loading.show()
    // } else {
    //   Loading.hide()
    // }
  },

  setScorecardAccPrjList(state, payload) {
    state.scorecardAccPrjList = payload
  },

  setScorecardAccPrjValues(state, payload) {
    console.log(
      '🚀 ~ file: scorecardAccProj.ts:42 ~ setScorecardAccPrjValues ~ payload:',
      payload
    )
    state.scorecardAccPrjValues = payload
  },
}

const actions = <ActionTree<State, any>>{
  getScorecardAccPrjList({ commit }, data: any) {
    console.log('STORE EVENT LIST ', data)
    commit('setIsScoreCardAccPrjBusy', true)

    const filter: any =
      data !== undefined && data.filter !== undefined ? data.filter : []

    return new Promise((resolve, reject) => {
      // Получаем список элементов
      scorecardAP.getScorecardAccPrjList(filter).then(
        (message: any) => {
          const items = message.getItemsList()
          const scoreCardMap: any = {}

          for (const i in items) {
            scoreCardMap[items[i].getProjectId()] = {}
            scoreCardMap[items[i].getProjectId()].name =
              items[i].getProjectName()
            scoreCardMap[items[i].getProjectId()].value =
              items[i].getProjectValue()
          }
          commit('setScorecardAccPrjValues', scoreCardMap)

          commit('setScorecardAccPrjList', items)
          commit('setIsScoreCardAccPrjBusy', false)

          resolve(items)
        },
        (error) => {
          commit('setScorecardAccPrjList', [])
          commit('setIsScoreCardAccPrjBusy', false)
          reject(error)
        }
      )
    })
  },
}

const ScorecardAccountProjectModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default ScorecardAccountProjectModule
