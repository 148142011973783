import {
  AnswerServiceClient
} from "../../proto/pb/AnswerServiceClientPb";
import {
  Answer, 
  ListAnswerRequest,
  //ItemAnswerRequest,
  AnswerFilter,
  AnswerFilterItem,
  AnswerSort,
  AnswerPagination
} from "../../proto/pb/answer_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 10000;

export class AnswerApi {
  items: Array<Answer> = [];
  client: AnswerServiceClient;
  metadata: any

  constructor() {
    const host:string = (window as any).VUE_APP_API_URL ?? '';
    this.client = new AnswerServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  // Установка и получение фильтров
  createFilter(data: any){

    const filter = new AnswerFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
        for (const i in data) {
            if (data[i].field_name && ('' !== data[i].field_name)) {
                const fItem = new AnswerFilterItem();
                fItem.setFieldName(data[i].field_name.toString());
                fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                filter.addItems(fItem);
            }
        }
    }

    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any){

    const sort = new AnswerSort();
    const name:string = data.name !== undefined ? data.name.toString() : 'answer_title';
    const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any){

    const pagination = new AnswerPagination();
    const page:number = data.page !== undefined ? Number(data.page) : 1;
    const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages:number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt:number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  // Список элементов
  getAnswerItems(filter: any, sort: any, pagination: any){

    const request = new ListAnswerRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));

    return this.client.listAnswer(request, this.metadata)
  }

  // Получение элемента
  getAnswerItem(filter: any){

    const request = new ListAnswerRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort({}));
    request.setPagination(this.createPagination({}));

    return this.client.listAnswer(request, this.metadata)
        .then((message) => {
            const itemList = message.getItemsList()

            if (itemList && (itemList.length > 0)) {
                return itemList[0]
            }
        })
  }
}
