// source: loyalty.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var interval_pb = require('./interval_pb.js');
goog.object.extend(proto, interval_pb);
goog.exportSymbol('proto.proto.DashboardLoyaltyChart', null, global);
goog.exportSymbol('proto.proto.DashboardLoyaltyChartPoint', null, global);
goog.exportSymbol('proto.proto.DashboardLoyaltyEventType', null, global);
goog.exportSymbol('proto.proto.DashboardLoyaltyStatusChart', null, global);
goog.exportSymbol('proto.proto.DashboardLoyaltyTransactionByProject', null, global);
goog.exportSymbol('proto.proto.DashboardLoyaltyTransactionByType', null, global);
goog.exportSymbol('proto.proto.DashboardMainDifference', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyAverageVotesChartRequest', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyAverageVotesChartResponse', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyMainDifferenceRequest', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyMainDifferenceResponse', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyMainValuesRequest', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyMainValuesResponse', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyRatingChartRequest', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyRatingChartResponse', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyRegistrationChartRequest', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyRegistrationChartResponse', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyStatusChartRequest', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyStatusChartResponse', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyTransactionsChartRequest', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyTransactionsChartResponse', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyUniqUsersChartRequest', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyUniqUsersChartResponse', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyUniqVotesChartRequest', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyUniqVotesChartResponse', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyUserRatingChartRequest', null, global);
goog.exportSymbol('proto.proto.GetDashboardLoyaltyUserRatingChartResponse', null, global);
goog.exportSymbol('proto.proto.ListDashboardLoyaltyEventTypesRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardLoyaltyEventTypesResponse', null, global);
goog.exportSymbol('proto.proto.ListDashboardLoyaltyRatingByProjectRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardLoyaltyRatingByProjectResponse', null, global);
goog.exportSymbol('proto.proto.ListDashboardLoyaltyTransactionByProjectRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardLoyaltyTransactionByProjectResponse', null, global);
goog.exportSymbol('proto.proto.ListDashboardLoyaltyTransactionByTypeRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardLoyaltyTransactionByTypeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardLoyaltyStatusChart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardLoyaltyStatusChart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardLoyaltyStatusChart.displayName = 'proto.proto.DashboardLoyaltyStatusChart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardLoyaltyEventType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardLoyaltyEventType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardLoyaltyEventType.displayName = 'proto.proto.DashboardLoyaltyEventType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardLoyaltyTransactionByProject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardLoyaltyTransactionByProject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardLoyaltyTransactionByProject.displayName = 'proto.proto.DashboardLoyaltyTransactionByProject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardLoyaltyTransactionByType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardLoyaltyTransactionByType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardLoyaltyTransactionByType.displayName = 'proto.proto.DashboardLoyaltyTransactionByType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardLoyaltyChart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DashboardLoyaltyChart.repeatedFields_, null);
};
goog.inherits(proto.proto.DashboardLoyaltyChart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardLoyaltyChart.displayName = 'proto.proto.DashboardLoyaltyChart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardLoyaltyChartPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardLoyaltyChartPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardLoyaltyChartPoint.displayName = 'proto.proto.DashboardLoyaltyChartPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyStatusChartRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyStatusChartRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyStatusChartRequest.displayName = 'proto.proto.GetDashboardLoyaltyStatusChartRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyStatusChartResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardLoyaltyStatusChartResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyStatusChartResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyStatusChartResponse.displayName = 'proto.proto.GetDashboardLoyaltyStatusChartResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardLoyaltyEventTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardLoyaltyEventTypesRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardLoyaltyEventTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardLoyaltyEventTypesRequest.displayName = 'proto.proto.ListDashboardLoyaltyEventTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardLoyaltyEventTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardLoyaltyEventTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardLoyaltyEventTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardLoyaltyEventTypesResponse.displayName = 'proto.proto.ListDashboardLoyaltyEventTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyRegistrationChartRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyRegistrationChartRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyRegistrationChartRequest.displayName = 'proto.proto.GetDashboardLoyaltyRegistrationChartRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyRegistrationChartResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardLoyaltyRegistrationChartResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyRegistrationChartResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyRegistrationChartResponse.displayName = 'proto.proto.GetDashboardLoyaltyRegistrationChartResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardLoyaltyTransactionByProjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.displayName = 'proto.proto.ListDashboardLoyaltyTransactionByProjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardLoyaltyTransactionByProjectResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardLoyaltyTransactionByProjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardLoyaltyTransactionByProjectResponse.displayName = 'proto.proto.ListDashboardLoyaltyTransactionByProjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardLoyaltyTransactionsChartRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyTransactionsChartRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyTransactionsChartRequest.displayName = 'proto.proto.GetDashboardLoyaltyTransactionsChartRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyTransactionsChartResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardLoyaltyTransactionsChartResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyTransactionsChartResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyTransactionsChartResponse.displayName = 'proto.proto.GetDashboardLoyaltyTransactionsChartResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyUniqUsersChartRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.displayName = 'proto.proto.GetDashboardLoyaltyUniqUsersChartRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardLoyaltyUniqUsersChartResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyUniqUsersChartResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyUniqUsersChartResponse.displayName = 'proto.proto.GetDashboardLoyaltyUniqUsersChartResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardLoyaltyTransactionByTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.displayName = 'proto.proto.ListDashboardLoyaltyTransactionByTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardLoyaltyTransactionByTypeResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardLoyaltyTransactionByTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardLoyaltyTransactionByTypeResponse.displayName = 'proto.proto.ListDashboardLoyaltyTransactionByTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardLoyaltyUserRatingChartRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyUserRatingChartRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyUserRatingChartRequest.displayName = 'proto.proto.GetDashboardLoyaltyUserRatingChartRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyUserRatingChartResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardLoyaltyUserRatingChartResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyUserRatingChartResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyUserRatingChartResponse.displayName = 'proto.proto.GetDashboardLoyaltyUserRatingChartResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyMainValuesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyMainValuesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyMainValuesRequest.displayName = 'proto.proto.GetDashboardLoyaltyMainValuesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyMainValuesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyMainValuesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyMainValuesResponse.displayName = 'proto.proto.GetDashboardLoyaltyMainValuesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardMainDifference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardMainDifference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardMainDifference.displayName = 'proto.proto.DashboardMainDifference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyMainDifferenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyMainDifferenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyMainDifferenceRequest.displayName = 'proto.proto.GetDashboardLoyaltyMainDifferenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyMainDifferenceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardLoyaltyMainDifferenceResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyMainDifferenceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyMainDifferenceResponse.displayName = 'proto.proto.GetDashboardLoyaltyMainDifferenceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardLoyaltyRatingByProjectRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardLoyaltyRatingByProjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardLoyaltyRatingByProjectRequest.displayName = 'proto.proto.ListDashboardLoyaltyRatingByProjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardLoyaltyRatingByProjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardLoyaltyRatingByProjectResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardLoyaltyRatingByProjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardLoyaltyRatingByProjectResponse.displayName = 'proto.proto.ListDashboardLoyaltyRatingByProjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardLoyaltyRatingChartRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyRatingChartRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyRatingChartRequest.displayName = 'proto.proto.GetDashboardLoyaltyRatingChartRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyRatingChartResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardLoyaltyRatingChartResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyRatingChartResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyRatingChartResponse.displayName = 'proto.proto.GetDashboardLoyaltyRatingChartResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyAverageVotesChartRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyAverageVotesChartRequest.displayName = 'proto.proto.GetDashboardLoyaltyAverageVotesChartRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardLoyaltyAverageVotesChartResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyAverageVotesChartResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyAverageVotesChartResponse.displayName = 'proto.proto.GetDashboardLoyaltyAverageVotesChartResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyUniqVotesChartRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyUniqVotesChartRequest.displayName = 'proto.proto.GetDashboardLoyaltyUniqVotesChartRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardLoyaltyUniqVotesChartResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardLoyaltyUniqVotesChartResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardLoyaltyUniqVotesChartResponse.displayName = 'proto.proto.GetDashboardLoyaltyUniqVotesChartResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardLoyaltyStatusChart.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardLoyaltyStatusChart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardLoyaltyStatusChart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardLoyaltyStatusChart.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    percent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardLoyaltyStatusChart}
 */
proto.proto.DashboardLoyaltyStatusChart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardLoyaltyStatusChart;
  return proto.proto.DashboardLoyaltyStatusChart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardLoyaltyStatusChart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardLoyaltyStatusChart}
 */
proto.proto.DashboardLoyaltyStatusChart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardLoyaltyStatusChart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardLoyaltyStatusChart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardLoyaltyStatusChart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardLoyaltyStatusChart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPercent();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.proto.DashboardLoyaltyStatusChart.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardLoyaltyStatusChart} returns this
 */
proto.proto.DashboardLoyaltyStatusChart.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.proto.DashboardLoyaltyStatusChart.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardLoyaltyStatusChart} returns this
 */
proto.proto.DashboardLoyaltyStatusChart.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float percent = 3;
 * @return {number}
 */
proto.proto.DashboardLoyaltyStatusChart.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardLoyaltyStatusChart} returns this
 */
proto.proto.DashboardLoyaltyStatusChart.prototype.setPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardLoyaltyEventType.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardLoyaltyEventType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardLoyaltyEventType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardLoyaltyEventType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardLoyaltyEventType}
 */
proto.proto.DashboardLoyaltyEventType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardLoyaltyEventType;
  return proto.proto.DashboardLoyaltyEventType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardLoyaltyEventType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardLoyaltyEventType}
 */
proto.proto.DashboardLoyaltyEventType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardLoyaltyEventType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardLoyaltyEventType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardLoyaltyEventType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardLoyaltyEventType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.proto.DashboardLoyaltyEventType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardLoyaltyEventType} returns this
 */
proto.proto.DashboardLoyaltyEventType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.proto.DashboardLoyaltyEventType.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardLoyaltyEventType} returns this
 */
proto.proto.DashboardLoyaltyEventType.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 project_id = 3;
 * @return {number}
 */
proto.proto.DashboardLoyaltyEventType.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardLoyaltyEventType} returns this
 */
proto.proto.DashboardLoyaltyEventType.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardLoyaltyTransactionByProject.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardLoyaltyTransactionByProject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardLoyaltyTransactionByProject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardLoyaltyTransactionByProject.toObject = function(includeInstance, msg) {
  var f, obj = {
    project: jspb.Message.getFieldWithDefault(msg, 1, ""),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardLoyaltyTransactionByProject}
 */
proto.proto.DashboardLoyaltyTransactionByProject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardLoyaltyTransactionByProject;
  return proto.proto.DashboardLoyaltyTransactionByProject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardLoyaltyTransactionByProject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardLoyaltyTransactionByProject}
 */
proto.proto.DashboardLoyaltyTransactionByProject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProject(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardLoyaltyTransactionByProject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardLoyaltyTransactionByProject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardLoyaltyTransactionByProject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardLoyaltyTransactionByProject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProject();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string project = 1;
 * @return {string}
 */
proto.proto.DashboardLoyaltyTransactionByProject.prototype.getProject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardLoyaltyTransactionByProject} returns this
 */
proto.proto.DashboardLoyaltyTransactionByProject.prototype.setProject = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.proto.DashboardLoyaltyTransactionByProject.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardLoyaltyTransactionByProject} returns this
 */
proto.proto.DashboardLoyaltyTransactionByProject.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardLoyaltyTransactionByType.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardLoyaltyTransactionByType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardLoyaltyTransactionByType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardLoyaltyTransactionByType.toObject = function(includeInstance, msg) {
  var f, obj = {
    project: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text: jspb.Message.getFieldWithDefault(msg, 3, ""),
    count: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardLoyaltyTransactionByType}
 */
proto.proto.DashboardLoyaltyTransactionByType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardLoyaltyTransactionByType;
  return proto.proto.DashboardLoyaltyTransactionByType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardLoyaltyTransactionByType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardLoyaltyTransactionByType}
 */
proto.proto.DashboardLoyaltyTransactionByType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProject(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardLoyaltyTransactionByType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardLoyaltyTransactionByType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardLoyaltyTransactionByType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardLoyaltyTransactionByType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProject();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string project = 1;
 * @return {string}
 */
proto.proto.DashboardLoyaltyTransactionByType.prototype.getProject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardLoyaltyTransactionByType} returns this
 */
proto.proto.DashboardLoyaltyTransactionByType.prototype.setProject = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.proto.DashboardLoyaltyTransactionByType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardLoyaltyTransactionByType} returns this
 */
proto.proto.DashboardLoyaltyTransactionByType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text = 3;
 * @return {string}
 */
proto.proto.DashboardLoyaltyTransactionByType.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardLoyaltyTransactionByType} returns this
 */
proto.proto.DashboardLoyaltyTransactionByType.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 count = 4;
 * @return {number}
 */
proto.proto.DashboardLoyaltyTransactionByType.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardLoyaltyTransactionByType} returns this
 */
proto.proto.DashboardLoyaltyTransactionByType.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DashboardLoyaltyChart.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardLoyaltyChart.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardLoyaltyChart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardLoyaltyChart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardLoyaltyChart.toObject = function(includeInstance, msg) {
  var f, obj = {
    project: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.proto.DashboardLoyaltyChartPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardLoyaltyChart}
 */
proto.proto.DashboardLoyaltyChart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardLoyaltyChart;
  return proto.proto.DashboardLoyaltyChart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardLoyaltyChart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardLoyaltyChart}
 */
proto.proto.DashboardLoyaltyChart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProject(value);
      break;
    case 2:
      var value = new proto.proto.DashboardLoyaltyChartPoint;
      reader.readMessage(value,proto.proto.DashboardLoyaltyChartPoint.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardLoyaltyChart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardLoyaltyChart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardLoyaltyChart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardLoyaltyChart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProject();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.DashboardLoyaltyChartPoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string project = 1;
 * @return {string}
 */
proto.proto.DashboardLoyaltyChart.prototype.getProject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardLoyaltyChart} returns this
 */
proto.proto.DashboardLoyaltyChart.prototype.setProject = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DashboardLoyaltyChartPoint points = 2;
 * @return {!Array<!proto.proto.DashboardLoyaltyChartPoint>}
 */
proto.proto.DashboardLoyaltyChart.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.proto.DashboardLoyaltyChartPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardLoyaltyChartPoint, 2));
};


/**
 * @param {!Array<!proto.proto.DashboardLoyaltyChartPoint>} value
 * @return {!proto.proto.DashboardLoyaltyChart} returns this
*/
proto.proto.DashboardLoyaltyChart.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.DashboardLoyaltyChartPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardLoyaltyChartPoint}
 */
proto.proto.DashboardLoyaltyChart.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.DashboardLoyaltyChartPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DashboardLoyaltyChart} returns this
 */
proto.proto.DashboardLoyaltyChart.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardLoyaltyChartPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardLoyaltyChartPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardLoyaltyChartPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardLoyaltyChartPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardLoyaltyChartPoint}
 */
proto.proto.DashboardLoyaltyChartPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardLoyaltyChartPoint;
  return proto.proto.DashboardLoyaltyChartPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardLoyaltyChartPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardLoyaltyChartPoint}
 */
proto.proto.DashboardLoyaltyChartPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardLoyaltyChartPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardLoyaltyChartPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardLoyaltyChartPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardLoyaltyChartPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.proto.DashboardLoyaltyChartPoint.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardLoyaltyChartPoint} returns this
 */
proto.proto.DashboardLoyaltyChartPoint.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 value = 2;
 * @return {number}
 */
proto.proto.DashboardLoyaltyChartPoint.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardLoyaltyChartPoint} returns this
 */
proto.proto.DashboardLoyaltyChartPoint.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyStatusChartRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyStatusChartRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyStatusChartRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyStatusChartRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyStatusChartRequest}
 */
proto.proto.GetDashboardLoyaltyStatusChartRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyStatusChartRequest;
  return proto.proto.GetDashboardLoyaltyStatusChartRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyStatusChartRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyStatusChartRequest}
 */
proto.proto.GetDashboardLoyaltyStatusChartRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyStatusChartRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyStatusChartRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyStatusChartRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyStatusChartRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardLoyaltyStatusChartResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyStatusChartResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyStatusChartResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyStatusChartResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyStatusChartResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentList: jspb.Message.toObjectList(msg.getCurrentList(),
    proto.proto.DashboardLoyaltyStatusChart.toObject, includeInstance),
    potentialList: jspb.Message.toObjectList(msg.getPotentialList(),
    proto.proto.DashboardLoyaltyStatusChart.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyStatusChartResponse}
 */
proto.proto.GetDashboardLoyaltyStatusChartResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyStatusChartResponse;
  return proto.proto.GetDashboardLoyaltyStatusChartResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyStatusChartResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyStatusChartResponse}
 */
proto.proto.GetDashboardLoyaltyStatusChartResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardLoyaltyStatusChart;
      reader.readMessage(value,proto.proto.DashboardLoyaltyStatusChart.deserializeBinaryFromReader);
      msg.addCurrent(value);
      break;
    case 2:
      var value = new proto.proto.DashboardLoyaltyStatusChart;
      reader.readMessage(value,proto.proto.DashboardLoyaltyStatusChart.deserializeBinaryFromReader);
      msg.addPotential(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyStatusChartResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyStatusChartResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyStatusChartResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyStatusChartResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardLoyaltyStatusChart.serializeBinaryToWriter
    );
  }
  f = message.getPotentialList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.DashboardLoyaltyStatusChart.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardLoyaltyStatusChart current = 1;
 * @return {!Array<!proto.proto.DashboardLoyaltyStatusChart>}
 */
proto.proto.GetDashboardLoyaltyStatusChartResponse.prototype.getCurrentList = function() {
  return /** @type{!Array<!proto.proto.DashboardLoyaltyStatusChart>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardLoyaltyStatusChart, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardLoyaltyStatusChart>} value
 * @return {!proto.proto.GetDashboardLoyaltyStatusChartResponse} returns this
*/
proto.proto.GetDashboardLoyaltyStatusChartResponse.prototype.setCurrentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardLoyaltyStatusChart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardLoyaltyStatusChart}
 */
proto.proto.GetDashboardLoyaltyStatusChartResponse.prototype.addCurrent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardLoyaltyStatusChart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyStatusChartResponse} returns this
 */
proto.proto.GetDashboardLoyaltyStatusChartResponse.prototype.clearCurrentList = function() {
  return this.setCurrentList([]);
};


/**
 * repeated DashboardLoyaltyStatusChart potential = 2;
 * @return {!Array<!proto.proto.DashboardLoyaltyStatusChart>}
 */
proto.proto.GetDashboardLoyaltyStatusChartResponse.prototype.getPotentialList = function() {
  return /** @type{!Array<!proto.proto.DashboardLoyaltyStatusChart>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardLoyaltyStatusChart, 2));
};


/**
 * @param {!Array<!proto.proto.DashboardLoyaltyStatusChart>} value
 * @return {!proto.proto.GetDashboardLoyaltyStatusChartResponse} returns this
*/
proto.proto.GetDashboardLoyaltyStatusChartResponse.prototype.setPotentialList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.DashboardLoyaltyStatusChart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardLoyaltyStatusChart}
 */
proto.proto.GetDashboardLoyaltyStatusChartResponse.prototype.addPotential = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.DashboardLoyaltyStatusChart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyStatusChartResponse} returns this
 */
proto.proto.GetDashboardLoyaltyStatusChartResponse.prototype.clearPotentialList = function() {
  return this.setPotentialList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardLoyaltyEventTypesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardLoyaltyEventTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardLoyaltyEventTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardLoyaltyEventTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardLoyaltyEventTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardLoyaltyEventTypesRequest}
 */
proto.proto.ListDashboardLoyaltyEventTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardLoyaltyEventTypesRequest;
  return proto.proto.ListDashboardLoyaltyEventTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardLoyaltyEventTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardLoyaltyEventTypesRequest}
 */
proto.proto.ListDashboardLoyaltyEventTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardLoyaltyEventTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardLoyaltyEventTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardLoyaltyEventTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardLoyaltyEventTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 project_id = 1;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardLoyaltyEventTypesRequest.prototype.getProjectIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardLoyaltyEventTypesRequest} returns this
 */
proto.proto.ListDashboardLoyaltyEventTypesRequest.prototype.setProjectIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardLoyaltyEventTypesRequest} returns this
 */
proto.proto.ListDashboardLoyaltyEventTypesRequest.prototype.addProjectId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardLoyaltyEventTypesRequest} returns this
 */
proto.proto.ListDashboardLoyaltyEventTypesRequest.prototype.clearProjectIdList = function() {
  return this.setProjectIdList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardLoyaltyEventTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardLoyaltyEventTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardLoyaltyEventTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardLoyaltyEventTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardLoyaltyEventTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardLoyaltyEventType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardLoyaltyEventTypesResponse}
 */
proto.proto.ListDashboardLoyaltyEventTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardLoyaltyEventTypesResponse;
  return proto.proto.ListDashboardLoyaltyEventTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardLoyaltyEventTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardLoyaltyEventTypesResponse}
 */
proto.proto.ListDashboardLoyaltyEventTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardLoyaltyEventType;
      reader.readMessage(value,proto.proto.DashboardLoyaltyEventType.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardLoyaltyEventTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardLoyaltyEventTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardLoyaltyEventTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardLoyaltyEventTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardLoyaltyEventType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardLoyaltyEventType items = 1;
 * @return {!Array<!proto.proto.DashboardLoyaltyEventType>}
 */
proto.proto.ListDashboardLoyaltyEventTypesResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardLoyaltyEventType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardLoyaltyEventType, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardLoyaltyEventType>} value
 * @return {!proto.proto.ListDashboardLoyaltyEventTypesResponse} returns this
*/
proto.proto.ListDashboardLoyaltyEventTypesResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardLoyaltyEventType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardLoyaltyEventType}
 */
proto.proto.ListDashboardLoyaltyEventTypesResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardLoyaltyEventType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardLoyaltyEventTypesResponse} returns this
 */
proto.proto.ListDashboardLoyaltyEventTypesResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyRegistrationChartRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyRegistrationChartRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyRegistrationChartRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyRegistrationChartRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateFrom: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateTo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    interval: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyRegistrationChartRequest}
 */
proto.proto.GetDashboardLoyaltyRegistrationChartRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyRegistrationChartRequest;
  return proto.proto.GetDashboardLoyaltyRegistrationChartRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyRegistrationChartRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyRegistrationChartRequest}
 */
proto.proto.GetDashboardLoyaltyRegistrationChartRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateFrom(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateTo(value);
      break;
    case 3:
      var value = /** @type {!proto.proto.ChartInterval} */ (reader.readEnum());
      msg.setInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyRegistrationChartRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyRegistrationChartRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyRegistrationChartRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyRegistrationChartRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateFrom();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInterval();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string date_from = 1;
 * @return {string}
 */
proto.proto.GetDashboardLoyaltyRegistrationChartRequest.prototype.getDateFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardLoyaltyRegistrationChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyRegistrationChartRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date_to = 2;
 * @return {string}
 */
proto.proto.GetDashboardLoyaltyRegistrationChartRequest.prototype.getDateTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardLoyaltyRegistrationChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyRegistrationChartRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ChartInterval interval = 3;
 * @return {!proto.proto.ChartInterval}
 */
proto.proto.GetDashboardLoyaltyRegistrationChartRequest.prototype.getInterval = function() {
  return /** @type {!proto.proto.ChartInterval} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.proto.ChartInterval} value
 * @return {!proto.proto.GetDashboardLoyaltyRegistrationChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyRegistrationChartRequest.prototype.setInterval = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardLoyaltyRegistrationChartResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyRegistrationChartResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyRegistrationChartResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyRegistrationChartResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyRegistrationChartResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardLoyaltyChart.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyRegistrationChartResponse}
 */
proto.proto.GetDashboardLoyaltyRegistrationChartResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyRegistrationChartResponse;
  return proto.proto.GetDashboardLoyaltyRegistrationChartResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyRegistrationChartResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyRegistrationChartResponse}
 */
proto.proto.GetDashboardLoyaltyRegistrationChartResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardLoyaltyChart;
      reader.readMessage(value,proto.proto.DashboardLoyaltyChart.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyRegistrationChartResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyRegistrationChartResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyRegistrationChartResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyRegistrationChartResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardLoyaltyChart.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardLoyaltyChart items = 1;
 * @return {!Array<!proto.proto.DashboardLoyaltyChart>}
 */
proto.proto.GetDashboardLoyaltyRegistrationChartResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardLoyaltyChart>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardLoyaltyChart, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardLoyaltyChart>} value
 * @return {!proto.proto.GetDashboardLoyaltyRegistrationChartResponse} returns this
*/
proto.proto.GetDashboardLoyaltyRegistrationChartResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardLoyaltyChart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardLoyaltyChart}
 */
proto.proto.GetDashboardLoyaltyRegistrationChartResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardLoyaltyChart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyRegistrationChartResponse} returns this
 */
proto.proto.GetDashboardLoyaltyRegistrationChartResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardLoyaltyTransactionByProjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    eventTypeIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByProjectRequest}
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardLoyaltyTransactionByProjectRequest;
  return proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardLoyaltyTransactionByProjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByProjectRequest}
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectId(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEventTypeId(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardLoyaltyTransactionByProjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getEventTypeIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * repeated int64 project_id = 1;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.prototype.getProjectIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByProjectRequest} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.prototype.setProjectIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByProjectRequest} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.prototype.addProjectId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByProjectRequest} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.prototype.clearProjectIdList = function() {
  return this.setProjectIdList([]);
};


/**
 * repeated int64 event_type_id = 2;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.prototype.getEventTypeIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByProjectRequest} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.prototype.setEventTypeIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByProjectRequest} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.prototype.addEventTypeId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByProjectRequest} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.prototype.clearEventTypeIdList = function() {
  return this.setEventTypeIdList([]);
};


/**
 * optional string date_begin = 3;
 * @return {string}
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByProjectRequest} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date_end = 4;
 * @return {string}
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByProjectRequest} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardLoyaltyTransactionByProjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardLoyaltyTransactionByProjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardLoyaltyTransactionByProject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByProjectResponse}
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardLoyaltyTransactionByProjectResponse;
  return proto.proto.ListDashboardLoyaltyTransactionByProjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardLoyaltyTransactionByProjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByProjectResponse}
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardLoyaltyTransactionByProject;
      reader.readMessage(value,proto.proto.DashboardLoyaltyTransactionByProject.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardLoyaltyTransactionByProjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardLoyaltyTransactionByProjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardLoyaltyTransactionByProject.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardLoyaltyTransactionByProject items = 1;
 * @return {!Array<!proto.proto.DashboardLoyaltyTransactionByProject>}
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardLoyaltyTransactionByProject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardLoyaltyTransactionByProject, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardLoyaltyTransactionByProject>} value
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByProjectResponse} returns this
*/
proto.proto.ListDashboardLoyaltyTransactionByProjectResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardLoyaltyTransactionByProject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardLoyaltyTransactionByProject}
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardLoyaltyTransactionByProject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByProjectResponse} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByProjectResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyTransactionsChartRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyTransactionsChartRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    eventTypeIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 4, ""),
    interval: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyTransactionsChartRequest}
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyTransactionsChartRequest;
  return proto.proto.GetDashboardLoyaltyTransactionsChartRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyTransactionsChartRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyTransactionsChartRequest}
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectId(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEventTypeId(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    case 5:
      var value = /** @type {!proto.proto.ChartInterval} */ (reader.readEnum());
      msg.setInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyTransactionsChartRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyTransactionsChartRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getEventTypeIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInterval();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * repeated int64 project_id = 1;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.prototype.getProjectIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardLoyaltyTransactionsChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.prototype.setProjectIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardLoyaltyTransactionsChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.prototype.addProjectId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyTransactionsChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.prototype.clearProjectIdList = function() {
  return this.setProjectIdList([]);
};


/**
 * repeated int64 event_type_id = 2;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.prototype.getEventTypeIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardLoyaltyTransactionsChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.prototype.setEventTypeIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardLoyaltyTransactionsChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.prototype.addEventTypeId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyTransactionsChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.prototype.clearEventTypeIdList = function() {
  return this.setEventTypeIdList([]);
};


/**
 * optional string date_begin = 3;
 * @return {string}
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardLoyaltyTransactionsChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date_end = 4;
 * @return {string}
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardLoyaltyTransactionsChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ChartInterval interval = 5;
 * @return {!proto.proto.ChartInterval}
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.prototype.getInterval = function() {
  return /** @type {!proto.proto.ChartInterval} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.proto.ChartInterval} value
 * @return {!proto.proto.GetDashboardLoyaltyTransactionsChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyTransactionsChartRequest.prototype.setInterval = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardLoyaltyTransactionsChartResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyTransactionsChartResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyTransactionsChartResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyTransactionsChartResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyTransactionsChartResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardLoyaltyChart.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyTransactionsChartResponse}
 */
proto.proto.GetDashboardLoyaltyTransactionsChartResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyTransactionsChartResponse;
  return proto.proto.GetDashboardLoyaltyTransactionsChartResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyTransactionsChartResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyTransactionsChartResponse}
 */
proto.proto.GetDashboardLoyaltyTransactionsChartResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardLoyaltyChart;
      reader.readMessage(value,proto.proto.DashboardLoyaltyChart.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyTransactionsChartResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyTransactionsChartResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyTransactionsChartResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyTransactionsChartResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardLoyaltyChart.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardLoyaltyChart items = 1;
 * @return {!Array<!proto.proto.DashboardLoyaltyChart>}
 */
proto.proto.GetDashboardLoyaltyTransactionsChartResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardLoyaltyChart>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardLoyaltyChart, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardLoyaltyChart>} value
 * @return {!proto.proto.GetDashboardLoyaltyTransactionsChartResponse} returns this
*/
proto.proto.GetDashboardLoyaltyTransactionsChartResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardLoyaltyChart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardLoyaltyChart}
 */
proto.proto.GetDashboardLoyaltyTransactionsChartResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardLoyaltyChart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyTransactionsChartResponse} returns this
 */
proto.proto.GetDashboardLoyaltyTransactionsChartResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyUniqUsersChartRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    eventTypeIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 4, ""),
    interval: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyUniqUsersChartRequest}
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyUniqUsersChartRequest;
  return proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyUniqUsersChartRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyUniqUsersChartRequest}
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectId(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEventTypeId(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    case 5:
      var value = /** @type {!proto.proto.ChartInterval} */ (reader.readEnum());
      msg.setInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyUniqUsersChartRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getEventTypeIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInterval();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * repeated int64 project_id = 1;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.prototype.getProjectIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardLoyaltyUniqUsersChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.prototype.setProjectIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardLoyaltyUniqUsersChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.prototype.addProjectId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyUniqUsersChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.prototype.clearProjectIdList = function() {
  return this.setProjectIdList([]);
};


/**
 * repeated int64 event_type_id = 2;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.prototype.getEventTypeIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardLoyaltyUniqUsersChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.prototype.setEventTypeIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardLoyaltyUniqUsersChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.prototype.addEventTypeId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyUniqUsersChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.prototype.clearEventTypeIdList = function() {
  return this.setEventTypeIdList([]);
};


/**
 * optional string date_begin = 3;
 * @return {string}
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardLoyaltyUniqUsersChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date_end = 4;
 * @return {string}
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardLoyaltyUniqUsersChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ChartInterval interval = 5;
 * @return {!proto.proto.ChartInterval}
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.prototype.getInterval = function() {
  return /** @type {!proto.proto.ChartInterval} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.proto.ChartInterval} value
 * @return {!proto.proto.GetDashboardLoyaltyUniqUsersChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartRequest.prototype.setInterval = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyUniqUsersChartResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyUniqUsersChartResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardLoyaltyChart.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyUniqUsersChartResponse}
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyUniqUsersChartResponse;
  return proto.proto.GetDashboardLoyaltyUniqUsersChartResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyUniqUsersChartResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyUniqUsersChartResponse}
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardLoyaltyChart;
      reader.readMessage(value,proto.proto.DashboardLoyaltyChart.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyUniqUsersChartResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyUniqUsersChartResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardLoyaltyChart.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardLoyaltyChart items = 1;
 * @return {!Array<!proto.proto.DashboardLoyaltyChart>}
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardLoyaltyChart>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardLoyaltyChart, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardLoyaltyChart>} value
 * @return {!proto.proto.GetDashboardLoyaltyUniqUsersChartResponse} returns this
*/
proto.proto.GetDashboardLoyaltyUniqUsersChartResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardLoyaltyChart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardLoyaltyChart}
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardLoyaltyChart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyUniqUsersChartResponse} returns this
 */
proto.proto.GetDashboardLoyaltyUniqUsersChartResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardLoyaltyTransactionByTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    eventTypeIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByTypeRequest}
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardLoyaltyTransactionByTypeRequest;
  return proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardLoyaltyTransactionByTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByTypeRequest}
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectId(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEventTypeId(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardLoyaltyTransactionByTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getEventTypeIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * repeated int64 project_id = 1;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.prototype.getProjectIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByTypeRequest} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.prototype.setProjectIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByTypeRequest} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.prototype.addProjectId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByTypeRequest} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.prototype.clearProjectIdList = function() {
  return this.setProjectIdList([]);
};


/**
 * repeated int64 event_type_id = 2;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.prototype.getEventTypeIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByTypeRequest} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.prototype.setEventTypeIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByTypeRequest} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.prototype.addEventTypeId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByTypeRequest} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.prototype.clearEventTypeIdList = function() {
  return this.setEventTypeIdList([]);
};


/**
 * optional string date_begin = 3;
 * @return {string}
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByTypeRequest} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date_end = 4;
 * @return {string}
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByTypeRequest} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardLoyaltyTransactionByTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardLoyaltyTransactionByTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardLoyaltyTransactionByType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByTypeResponse}
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardLoyaltyTransactionByTypeResponse;
  return proto.proto.ListDashboardLoyaltyTransactionByTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardLoyaltyTransactionByTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByTypeResponse}
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardLoyaltyTransactionByType;
      reader.readMessage(value,proto.proto.DashboardLoyaltyTransactionByType.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardLoyaltyTransactionByTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardLoyaltyTransactionByTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardLoyaltyTransactionByType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardLoyaltyTransactionByType items = 1;
 * @return {!Array<!proto.proto.DashboardLoyaltyTransactionByType>}
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardLoyaltyTransactionByType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardLoyaltyTransactionByType, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardLoyaltyTransactionByType>} value
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByTypeResponse} returns this
*/
proto.proto.ListDashboardLoyaltyTransactionByTypeResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardLoyaltyTransactionByType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardLoyaltyTransactionByType}
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardLoyaltyTransactionByType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardLoyaltyTransactionByTypeResponse} returns this
 */
proto.proto.ListDashboardLoyaltyTransactionByTypeResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyUserRatingChartRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyUserRatingChartRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    eventTypeIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 4, ""),
    interval: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyUserRatingChartRequest}
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyUserRatingChartRequest;
  return proto.proto.GetDashboardLoyaltyUserRatingChartRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyUserRatingChartRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyUserRatingChartRequest}
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectId(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEventTypeId(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    case 5:
      var value = /** @type {!proto.proto.ChartInterval} */ (reader.readEnum());
      msg.setInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyUserRatingChartRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyUserRatingChartRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getEventTypeIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInterval();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * repeated int64 project_id = 1;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.prototype.getProjectIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardLoyaltyUserRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.prototype.setProjectIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardLoyaltyUserRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.prototype.addProjectId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyUserRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.prototype.clearProjectIdList = function() {
  return this.setProjectIdList([]);
};


/**
 * repeated int64 event_type_id = 2;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.prototype.getEventTypeIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardLoyaltyUserRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.prototype.setEventTypeIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardLoyaltyUserRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.prototype.addEventTypeId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyUserRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.prototype.clearEventTypeIdList = function() {
  return this.setEventTypeIdList([]);
};


/**
 * optional string date_begin = 3;
 * @return {string}
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardLoyaltyUserRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date_end = 4;
 * @return {string}
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardLoyaltyUserRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ChartInterval interval = 5;
 * @return {!proto.proto.ChartInterval}
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.prototype.getInterval = function() {
  return /** @type {!proto.proto.ChartInterval} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.proto.ChartInterval} value
 * @return {!proto.proto.GetDashboardLoyaltyUserRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUserRatingChartRequest.prototype.setInterval = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardLoyaltyUserRatingChartResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyUserRatingChartResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyUserRatingChartResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyUserRatingChartResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyUserRatingChartResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardLoyaltyChart.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyUserRatingChartResponse}
 */
proto.proto.GetDashboardLoyaltyUserRatingChartResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyUserRatingChartResponse;
  return proto.proto.GetDashboardLoyaltyUserRatingChartResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyUserRatingChartResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyUserRatingChartResponse}
 */
proto.proto.GetDashboardLoyaltyUserRatingChartResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardLoyaltyChart;
      reader.readMessage(value,proto.proto.DashboardLoyaltyChart.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyUserRatingChartResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyUserRatingChartResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyUserRatingChartResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyUserRatingChartResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardLoyaltyChart.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardLoyaltyChart items = 1;
 * @return {!Array<!proto.proto.DashboardLoyaltyChart>}
 */
proto.proto.GetDashboardLoyaltyUserRatingChartResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardLoyaltyChart>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardLoyaltyChart, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardLoyaltyChart>} value
 * @return {!proto.proto.GetDashboardLoyaltyUserRatingChartResponse} returns this
*/
proto.proto.GetDashboardLoyaltyUserRatingChartResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardLoyaltyChart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardLoyaltyChart}
 */
proto.proto.GetDashboardLoyaltyUserRatingChartResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardLoyaltyChart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyUserRatingChartResponse} returns this
 */
proto.proto.GetDashboardLoyaltyUserRatingChartResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyMainValuesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyMainValuesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyMainValuesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyMainValuesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyMainValuesRequest}
 */
proto.proto.GetDashboardLoyaltyMainValuesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyMainValuesRequest;
  return proto.proto.GetDashboardLoyaltyMainValuesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyMainValuesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyMainValuesRequest}
 */
proto.proto.GetDashboardLoyaltyMainValuesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyMainValuesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyMainValuesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyMainValuesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyMainValuesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyMainValuesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyMainValuesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyMainValuesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyMainValuesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesMap: (f = msg.getValuesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyMainValuesResponse}
 */
proto.proto.GetDashboardLoyaltyMainValuesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyMainValuesResponse;
  return proto.proto.GetDashboardLoyaltyMainValuesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyMainValuesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyMainValuesResponse}
 */
proto.proto.GetDashboardLoyaltyMainValuesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getValuesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readDouble, null, "", 0.0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyMainValuesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyMainValuesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyMainValuesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyMainValuesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeDouble);
  }
};


/**
 * map<string, double> values = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.proto.GetDashboardLoyaltyMainValuesResponse.prototype.getValuesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.proto.GetDashboardLoyaltyMainValuesResponse} returns this
 */
proto.proto.GetDashboardLoyaltyMainValuesResponse.prototype.clearValuesMap = function() {
  this.getValuesMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardMainDifference.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardMainDifference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardMainDifference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardMainDifference.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    weekValue: jspb.Message.getFieldWithDefault(msg, 2, 0),
    weekPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    monthValue: jspb.Message.getFieldWithDefault(msg, 4, 0),
    monthPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardMainDifference}
 */
proto.proto.DashboardMainDifference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardMainDifference;
  return proto.proto.DashboardMainDifference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardMainDifference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardMainDifference}
 */
proto.proto.DashboardMainDifference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWeekValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWeekPercent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMonthPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardMainDifference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardMainDifference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardMainDifference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardMainDifference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWeekValue();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getWeekPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMonthValue();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMonthPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.DashboardMainDifference.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardMainDifference} returns this
 */
proto.proto.DashboardMainDifference.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 week_value = 2;
 * @return {number}
 */
proto.proto.DashboardMainDifference.prototype.getWeekValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardMainDifference} returns this
 */
proto.proto.DashboardMainDifference.prototype.setWeekValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double week_percent = 3;
 * @return {number}
 */
proto.proto.DashboardMainDifference.prototype.getWeekPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardMainDifference} returns this
 */
proto.proto.DashboardMainDifference.prototype.setWeekPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int64 month_value = 4;
 * @return {number}
 */
proto.proto.DashboardMainDifference.prototype.getMonthValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardMainDifference} returns this
 */
proto.proto.DashboardMainDifference.prototype.setMonthValue = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double month_percent = 5;
 * @return {number}
 */
proto.proto.DashboardMainDifference.prototype.getMonthPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardMainDifference} returns this
 */
proto.proto.DashboardMainDifference.prototype.setMonthPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyMainDifferenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyMainDifferenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyMainDifferenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyMainDifferenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyMainDifferenceRequest}
 */
proto.proto.GetDashboardLoyaltyMainDifferenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyMainDifferenceRequest;
  return proto.proto.GetDashboardLoyaltyMainDifferenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyMainDifferenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyMainDifferenceRequest}
 */
proto.proto.GetDashboardLoyaltyMainDifferenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyMainDifferenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyMainDifferenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyMainDifferenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyMainDifferenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardLoyaltyMainDifferenceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyMainDifferenceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyMainDifferenceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyMainDifferenceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyMainDifferenceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardMainDifference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyMainDifferenceResponse}
 */
proto.proto.GetDashboardLoyaltyMainDifferenceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyMainDifferenceResponse;
  return proto.proto.GetDashboardLoyaltyMainDifferenceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyMainDifferenceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyMainDifferenceResponse}
 */
proto.proto.GetDashboardLoyaltyMainDifferenceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardMainDifference;
      reader.readMessage(value,proto.proto.DashboardMainDifference.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyMainDifferenceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyMainDifferenceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyMainDifferenceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyMainDifferenceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardMainDifference.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardMainDifference items = 1;
 * @return {!Array<!proto.proto.DashboardMainDifference>}
 */
proto.proto.GetDashboardLoyaltyMainDifferenceResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardMainDifference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardMainDifference, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardMainDifference>} value
 * @return {!proto.proto.GetDashboardLoyaltyMainDifferenceResponse} returns this
*/
proto.proto.GetDashboardLoyaltyMainDifferenceResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardMainDifference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardMainDifference}
 */
proto.proto.GetDashboardLoyaltyMainDifferenceResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardMainDifference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyMainDifferenceResponse} returns this
 */
proto.proto.GetDashboardLoyaltyMainDifferenceResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardLoyaltyRatingByProjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardLoyaltyRatingByProjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    eventTypeIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardLoyaltyRatingByProjectRequest}
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardLoyaltyRatingByProjectRequest;
  return proto.proto.ListDashboardLoyaltyRatingByProjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardLoyaltyRatingByProjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardLoyaltyRatingByProjectRequest}
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectId(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEventTypeId(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardLoyaltyRatingByProjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardLoyaltyRatingByProjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getEventTypeIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * repeated int64 project_id = 1;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.prototype.getProjectIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardLoyaltyRatingByProjectRequest} returns this
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.prototype.setProjectIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardLoyaltyRatingByProjectRequest} returns this
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.prototype.addProjectId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardLoyaltyRatingByProjectRequest} returns this
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.prototype.clearProjectIdList = function() {
  return this.setProjectIdList([]);
};


/**
 * repeated int64 event_type_id = 2;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.prototype.getEventTypeIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardLoyaltyRatingByProjectRequest} returns this
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.prototype.setEventTypeIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardLoyaltyRatingByProjectRequest} returns this
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.prototype.addEventTypeId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardLoyaltyRatingByProjectRequest} returns this
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.prototype.clearEventTypeIdList = function() {
  return this.setEventTypeIdList([]);
};


/**
 * optional string date_begin = 3;
 * @return {string}
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardLoyaltyRatingByProjectRequest} returns this
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date_end = 4;
 * @return {string}
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardLoyaltyRatingByProjectRequest} returns this
 */
proto.proto.ListDashboardLoyaltyRatingByProjectRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardLoyaltyRatingByProjectResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardLoyaltyRatingByProjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardLoyaltyRatingByProjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardLoyaltyRatingByProjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardLoyaltyRatingByProjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardLoyaltyTransactionByProject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardLoyaltyRatingByProjectResponse}
 */
proto.proto.ListDashboardLoyaltyRatingByProjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardLoyaltyRatingByProjectResponse;
  return proto.proto.ListDashboardLoyaltyRatingByProjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardLoyaltyRatingByProjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardLoyaltyRatingByProjectResponse}
 */
proto.proto.ListDashboardLoyaltyRatingByProjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardLoyaltyTransactionByProject;
      reader.readMessage(value,proto.proto.DashboardLoyaltyTransactionByProject.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardLoyaltyRatingByProjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardLoyaltyRatingByProjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardLoyaltyRatingByProjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardLoyaltyRatingByProjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardLoyaltyTransactionByProject.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardLoyaltyTransactionByProject items = 1;
 * @return {!Array<!proto.proto.DashboardLoyaltyTransactionByProject>}
 */
proto.proto.ListDashboardLoyaltyRatingByProjectResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardLoyaltyTransactionByProject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardLoyaltyTransactionByProject, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardLoyaltyTransactionByProject>} value
 * @return {!proto.proto.ListDashboardLoyaltyRatingByProjectResponse} returns this
*/
proto.proto.ListDashboardLoyaltyRatingByProjectResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardLoyaltyTransactionByProject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardLoyaltyTransactionByProject}
 */
proto.proto.ListDashboardLoyaltyRatingByProjectResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardLoyaltyTransactionByProject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardLoyaltyRatingByProjectResponse} returns this
 */
proto.proto.ListDashboardLoyaltyRatingByProjectResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyRatingChartRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyRatingChartRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    eventTypeIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 4, ""),
    interval: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyRatingChartRequest}
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyRatingChartRequest;
  return proto.proto.GetDashboardLoyaltyRatingChartRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyRatingChartRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyRatingChartRequest}
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectId(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEventTypeId(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    case 5:
      var value = /** @type {!proto.proto.ChartInterval} */ (reader.readEnum());
      msg.setInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyRatingChartRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyRatingChartRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getEventTypeIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInterval();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * repeated int64 project_id = 1;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.prototype.getProjectIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardLoyaltyRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.prototype.setProjectIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardLoyaltyRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.prototype.addProjectId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.prototype.clearProjectIdList = function() {
  return this.setProjectIdList([]);
};


/**
 * repeated int64 event_type_id = 2;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.prototype.getEventTypeIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardLoyaltyRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.prototype.setEventTypeIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardLoyaltyRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.prototype.addEventTypeId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.prototype.clearEventTypeIdList = function() {
  return this.setEventTypeIdList([]);
};


/**
 * optional string date_begin = 3;
 * @return {string}
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardLoyaltyRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date_end = 4;
 * @return {string}
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardLoyaltyRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ChartInterval interval = 5;
 * @return {!proto.proto.ChartInterval}
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.prototype.getInterval = function() {
  return /** @type {!proto.proto.ChartInterval} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.proto.ChartInterval} value
 * @return {!proto.proto.GetDashboardLoyaltyRatingChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyRatingChartRequest.prototype.setInterval = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardLoyaltyRatingChartResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyRatingChartResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyRatingChartResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyRatingChartResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyRatingChartResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardLoyaltyChart.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyRatingChartResponse}
 */
proto.proto.GetDashboardLoyaltyRatingChartResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyRatingChartResponse;
  return proto.proto.GetDashboardLoyaltyRatingChartResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyRatingChartResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyRatingChartResponse}
 */
proto.proto.GetDashboardLoyaltyRatingChartResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardLoyaltyChart;
      reader.readMessage(value,proto.proto.DashboardLoyaltyChart.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyRatingChartResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyRatingChartResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyRatingChartResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyRatingChartResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardLoyaltyChart.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardLoyaltyChart items = 1;
 * @return {!Array<!proto.proto.DashboardLoyaltyChart>}
 */
proto.proto.GetDashboardLoyaltyRatingChartResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardLoyaltyChart>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardLoyaltyChart, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardLoyaltyChart>} value
 * @return {!proto.proto.GetDashboardLoyaltyRatingChartResponse} returns this
*/
proto.proto.GetDashboardLoyaltyRatingChartResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardLoyaltyChart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardLoyaltyChart}
 */
proto.proto.GetDashboardLoyaltyRatingChartResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardLoyaltyChart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyRatingChartResponse} returns this
 */
proto.proto.GetDashboardLoyaltyRatingChartResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyAverageVotesChartRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyAverageVotesChartRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateBegin: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyAverageVotesChartRequest}
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyAverageVotesChartRequest;
  return proto.proto.GetDashboardLoyaltyAverageVotesChartRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyAverageVotesChartRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyAverageVotesChartRequest}
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyAverageVotesChartRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyAverageVotesChartRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string date_begin = 1;
 * @return {string}
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardLoyaltyAverageVotesChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date_end = 2;
 * @return {string}
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardLoyaltyAverageVotesChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyAverageVotesChartResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyAverageVotesChartResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardLoyaltyChart.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyAverageVotesChartResponse}
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyAverageVotesChartResponse;
  return proto.proto.GetDashboardLoyaltyAverageVotesChartResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyAverageVotesChartResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyAverageVotesChartResponse}
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardLoyaltyChart;
      reader.readMessage(value,proto.proto.DashboardLoyaltyChart.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyAverageVotesChartResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyAverageVotesChartResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardLoyaltyChart.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardLoyaltyChart items = 1;
 * @return {!Array<!proto.proto.DashboardLoyaltyChart>}
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardLoyaltyChart>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardLoyaltyChart, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardLoyaltyChart>} value
 * @return {!proto.proto.GetDashboardLoyaltyAverageVotesChartResponse} returns this
*/
proto.proto.GetDashboardLoyaltyAverageVotesChartResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardLoyaltyChart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardLoyaltyChart}
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardLoyaltyChart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyAverageVotesChartResponse} returns this
 */
proto.proto.GetDashboardLoyaltyAverageVotesChartResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyUniqVotesChartRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyUniqVotesChartRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateBegin: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 2, ""),
    interval: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyUniqVotesChartRequest}
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyUniqVotesChartRequest;
  return proto.proto.GetDashboardLoyaltyUniqVotesChartRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyUniqVotesChartRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyUniqVotesChartRequest}
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    case 3:
      var value = /** @type {!proto.proto.ChartInterval} */ (reader.readEnum());
      msg.setInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyUniqVotesChartRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyUniqVotesChartRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInterval();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string date_begin = 1;
 * @return {string}
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardLoyaltyUniqVotesChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date_end = 2;
 * @return {string}
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardLoyaltyUniqVotesChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ChartInterval interval = 3;
 * @return {!proto.proto.ChartInterval}
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartRequest.prototype.getInterval = function() {
  return /** @type {!proto.proto.ChartInterval} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.proto.ChartInterval} value
 * @return {!proto.proto.GetDashboardLoyaltyUniqVotesChartRequest} returns this
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartRequest.prototype.setInterval = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardLoyaltyUniqVotesChartResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardLoyaltyUniqVotesChartResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardLoyaltyChart.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardLoyaltyUniqVotesChartResponse}
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardLoyaltyUniqVotesChartResponse;
  return proto.proto.GetDashboardLoyaltyUniqVotesChartResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardLoyaltyUniqVotesChartResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardLoyaltyUniqVotesChartResponse}
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardLoyaltyChart;
      reader.readMessage(value,proto.proto.DashboardLoyaltyChart.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardLoyaltyUniqVotesChartResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardLoyaltyUniqVotesChartResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardLoyaltyChart.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardLoyaltyChart items = 1;
 * @return {!Array<!proto.proto.DashboardLoyaltyChart>}
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardLoyaltyChart>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardLoyaltyChart, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardLoyaltyChart>} value
 * @return {!proto.proto.GetDashboardLoyaltyUniqVotesChartResponse} returns this
*/
proto.proto.GetDashboardLoyaltyUniqVotesChartResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardLoyaltyChart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardLoyaltyChart}
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardLoyaltyChart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardLoyaltyUniqVotesChartResponse} returns this
 */
proto.proto.GetDashboardLoyaltyUniqVotesChartResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
