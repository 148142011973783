/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v5.27.1
// source: source.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as source_pb from './source_pb';


export class SourceServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateSource = new grpcWeb.MethodDescriptor(
    '/proto.SourceService/CreateSource',
    grpcWeb.MethodType.UNARY,
    source_pb.CreateSourceRequest,
    source_pb.CreateSourceResponse,
    (request: source_pb.CreateSourceRequest) => {
      return request.serializeBinary();
    },
    source_pb.CreateSourceResponse.deserializeBinary
  );

  createSource(
    request: source_pb.CreateSourceRequest,
    metadata: grpcWeb.Metadata | null): Promise<source_pb.CreateSourceResponse>;

  createSource(
    request: source_pb.CreateSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: source_pb.CreateSourceResponse) => void): grpcWeb.ClientReadableStream<source_pb.CreateSourceResponse>;

  createSource(
    request: source_pb.CreateSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: source_pb.CreateSourceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SourceService/CreateSource',
        request,
        metadata || {},
        this.methodDescriptorCreateSource,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SourceService/CreateSource',
    request,
    metadata || {},
    this.methodDescriptorCreateSource);
  }

  methodDescriptorCreateSourceFromQuery = new grpcWeb.MethodDescriptor(
    '/proto.SourceService/CreateSourceFromQuery',
    grpcWeb.MethodType.UNARY,
    source_pb.CreateSourceFromQueryRequest,
    source_pb.CreateSourceFromQueryResponse,
    (request: source_pb.CreateSourceFromQueryRequest) => {
      return request.serializeBinary();
    },
    source_pb.CreateSourceFromQueryResponse.deserializeBinary
  );

  createSourceFromQuery(
    request: source_pb.CreateSourceFromQueryRequest,
    metadata: grpcWeb.Metadata | null): Promise<source_pb.CreateSourceFromQueryResponse>;

  createSourceFromQuery(
    request: source_pb.CreateSourceFromQueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: source_pb.CreateSourceFromQueryResponse) => void): grpcWeb.ClientReadableStream<source_pb.CreateSourceFromQueryResponse>;

  createSourceFromQuery(
    request: source_pb.CreateSourceFromQueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: source_pb.CreateSourceFromQueryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SourceService/CreateSourceFromQuery',
        request,
        metadata || {},
        this.methodDescriptorCreateSourceFromQuery,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SourceService/CreateSourceFromQuery',
    request,
    metadata || {},
    this.methodDescriptorCreateSourceFromQuery);
  }

  methodDescriptorUpdateSource = new grpcWeb.MethodDescriptor(
    '/proto.SourceService/UpdateSource',
    grpcWeb.MethodType.UNARY,
    source_pb.UpdateSourceRequest,
    source_pb.UpdateSourceResponse,
    (request: source_pb.UpdateSourceRequest) => {
      return request.serializeBinary();
    },
    source_pb.UpdateSourceResponse.deserializeBinary
  );

  updateSource(
    request: source_pb.UpdateSourceRequest,
    metadata: grpcWeb.Metadata | null): Promise<source_pb.UpdateSourceResponse>;

  updateSource(
    request: source_pb.UpdateSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: source_pb.UpdateSourceResponse) => void): grpcWeb.ClientReadableStream<source_pb.UpdateSourceResponse>;

  updateSource(
    request: source_pb.UpdateSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: source_pb.UpdateSourceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SourceService/UpdateSource',
        request,
        metadata || {},
        this.methodDescriptorUpdateSource,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SourceService/UpdateSource',
    request,
    metadata || {},
    this.methodDescriptorUpdateSource);
  }

  methodDescriptorDeleteSource = new grpcWeb.MethodDescriptor(
    '/proto.SourceService/DeleteSource',
    grpcWeb.MethodType.UNARY,
    source_pb.DeleteSourceRequest,
    source_pb.DeleteSourceResponse,
    (request: source_pb.DeleteSourceRequest) => {
      return request.serializeBinary();
    },
    source_pb.DeleteSourceResponse.deserializeBinary
  );

  deleteSource(
    request: source_pb.DeleteSourceRequest,
    metadata: grpcWeb.Metadata | null): Promise<source_pb.DeleteSourceResponse>;

  deleteSource(
    request: source_pb.DeleteSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: source_pb.DeleteSourceResponse) => void): grpcWeb.ClientReadableStream<source_pb.DeleteSourceResponse>;

  deleteSource(
    request: source_pb.DeleteSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: source_pb.DeleteSourceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SourceService/DeleteSource',
        request,
        metadata || {},
        this.methodDescriptorDeleteSource,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SourceService/DeleteSource',
    request,
    metadata || {},
    this.methodDescriptorDeleteSource);
  }

  methodDescriptorListSource = new grpcWeb.MethodDescriptor(
    '/proto.SourceService/ListSource',
    grpcWeb.MethodType.UNARY,
    source_pb.ListSourceRequest,
    source_pb.ListSourceResponse,
    (request: source_pb.ListSourceRequest) => {
      return request.serializeBinary();
    },
    source_pb.ListSourceResponse.deserializeBinary
  );

  listSource(
    request: source_pb.ListSourceRequest,
    metadata: grpcWeb.Metadata | null): Promise<source_pb.ListSourceResponse>;

  listSource(
    request: source_pb.ListSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: source_pb.ListSourceResponse) => void): grpcWeb.ClientReadableStream<source_pb.ListSourceResponse>;

  listSource(
    request: source_pb.ListSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: source_pb.ListSourceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SourceService/ListSource',
        request,
        metadata || {},
        this.methodDescriptorListSource,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SourceService/ListSource',
    request,
    metadata || {},
    this.methodDescriptorListSource);
  }

  methodDescriptorListSourceFields = new grpcWeb.MethodDescriptor(
    '/proto.SourceService/ListSourceFields',
    grpcWeb.MethodType.UNARY,
    source_pb.ListSourceFieldRequest,
    source_pb.ListSourceFieldResponse,
    (request: source_pb.ListSourceFieldRequest) => {
      return request.serializeBinary();
    },
    source_pb.ListSourceFieldResponse.deserializeBinary
  );

  listSourceFields(
    request: source_pb.ListSourceFieldRequest,
    metadata: grpcWeb.Metadata | null): Promise<source_pb.ListSourceFieldResponse>;

  listSourceFields(
    request: source_pb.ListSourceFieldRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: source_pb.ListSourceFieldResponse) => void): grpcWeb.ClientReadableStream<source_pb.ListSourceFieldResponse>;

  listSourceFields(
    request: source_pb.ListSourceFieldRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: source_pb.ListSourceFieldResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SourceService/ListSourceFields',
        request,
        metadata || {},
        this.methodDescriptorListSourceFields,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SourceService/ListSourceFields',
    request,
    metadata || {},
    this.methodDescriptorListSourceFields);
  }

}

