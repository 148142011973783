<template>
    <div style="width: 100%; position: relative">
        <highcharts class="hc" :options="chartOptions" ref="chart" style="height: 700px"/>
        <q-inner-loading
            :showing="isDynamicListBusy"
            color="grey"
        />
    </div>
</template>

<script>
import store from "@/store";

export default {
    name: "ActionChart",

    props: {
        sortType: String,
    },

    data() {
        return {
            chartOptions: {
                chart: {
                    type: 'column',
                    zoomType: 'x'
                },
                title: {
                    text: 'График активности пользователей по выбранным действиям',
                },
                lang: {
                  noData: 'Выберите действия.'
                },
                xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        year: '%Y'
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Количество пользователей',
                    },
                },
                plotOptions: {
                  column: {
                    stacking: 'normal',
                    dataLabels: {
                      enabled: true
                    }
                  }
                },
                tooltip: {
                    headerFormat:
                        '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat:
                        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y}</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true,
                },
                series: [

                ],
            },

            betw: 0
        }
    },

    computed: {
        activeChart() {
            return store.getters.getActiveChart
        },

        isDynamicListBusy() {
            return store.getters.isActiveChart
        },

        getMinMaxDate() {
            return store.getters.getMinMaxDate
        },

        activeList() {
            return store.getters.getSelectedActive
        }
    },

    watch: {
        activeChart() {
            const filteredList = []

            for (let i of this.activeChart) {
                let tmpObj = {
                    name: i.getActivTextTape() + ' (' + i.getProjectShortName() + ')',
                    data: []
                }

                if (this.sortType === 'day') {
                    for (let j of i.getItemsList()) {
                        let date = j.getActivityInterval().split('-')

                        tmpObj.data.sort().push([Date.UTC(date[0], date[1] - 1, date[2]), j.getActivityCount()])
                    }
                }

                if (this.sortType === 'month') {
                    for (let j of i.getItemsList()) {
                        let date = j.getActivityInterval().split('-')

                        tmpObj.data.sort().push([Date.UTC(date[0], date[1] - 1), j.getActivityCount()])
                    }
                }

                if (this.sortType === 'year') {
                    for (let j of i.getItemsList()) {
                        let date = j.getActivityInterval().split('-')

                        tmpObj.data.sort().push([Date.UTC(date[0]), j.getActivityCount()])
                    }
                }

                filteredList.push(tmpObj)
            }

            let uniqObj = {
                name: 'Уникальных пользователей',
                type: 'line',
                data: []
            }

            filteredList[0].data.forEach(el => {
                uniqObj.data.push(el)
            })

            filteredList.splice(0, 1)
            filteredList.push(uniqObj)

            this.chartOptions.series = filteredList
        },
    }
}
</script>


<style scoped>

</style>