import {
    MessageSrcServiceClient
} from "../../proto/pb/Message_srcServiceClientPb";
import {
    MessageSrc,
    MessageSrcSource,
    ListMessageSrcRequest,
    ListMessageSrcSourceRequest,
    PersonalListMessageSrcSourceRequest,
    MessageSrcFilter,
    MessageSrcFilterItem,
    MessageSrcSort,
    MessageSrcPagination,
} from "../../proto/pb/message_src_pb";
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit:number = 1000;

export class MessageSrcApi {
    items: Array<MessageSrc> = [];
    sitems: Array<MessageSrcSource> = [];
    client: MessageSrcServiceClient;
    metadata: any;

    constructor() {
        const host:string = (window as any).VUE_APP_API_URL ?? '';
        this.client = new MessageSrcServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    // Установка и получение фильтров
    createFilter(data: any){

        const filter = new MessageSrcFilter();

        // Устанавливаем фильтры
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && ('' !== data[i].field_name)) {
                    const fItem = new MessageSrcFilterItem();
                    fItem.setFieldName(data[i].field_name.toString());
                    fItem.setOperand(data[i].operand !== undefined ? data[i].operand : '=');
                    fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                    fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                    filter.addItems(fItem);
                }
            }
        }

        return filter;
    }

    // Установка и получение сортировки
    createSort(data: any){

        const sort = new MessageSrcSort();
        const name:string = data.name !== undefined ? data.name.toString() : 'asrc.message_src_text';
        const exp:string = data.exp !== undefined ? data.exp.toString() : 'ASC';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    // Установка и получение пагинации
    createPagination(data: any){

        const pagination = new MessageSrcPagination();
        const page:number = data.page !== undefined ? Number(data.page) : 1;
        const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
        const pages:number = data.pages !== undefined ? Number(data.pages) : 0;
        const cnt:number = data.cnt !== undefined ? Number(data.cnt) : 0;

        // Устанавливаем пагинацию
        pagination.setPage(page);
        pagination.setLimit(limit);
        pagination.setPages(pages);

        return pagination;
    }

    // Список элементов
    getMessageSrcItems(filter: any, sort: any, pagination: any){

        const request = new ListMessageSrcRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));

        return this.client.listMessageSrc(request, this.metadata);
    }

    // Список элементов для модалки
    getMessageSrcSourceItems(filter: any, sort: any, pagination: any, message_grp_id: number, is_full: boolean){

        const request = new ListMessageSrcSourceRequest();

        console.log('message request', request)

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));
        request.setMessageGrpId(message_grp_id);
        request.setHasFull(is_full);

        return this.client.listMessageSrcSource(request, this.metadata);
    }

    getPersonalMessageSrcSourceItems(filter: any, message_grp_id: number, is_full: boolean){

        const request = new PersonalListMessageSrcSourceRequest();

        console.log('message request', request)

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setMessageGrpId(message_grp_id);
        request.setHasFull(is_full);

        return this.client.personalListMessageSrcSource(request, this.metadata);
    }
}
