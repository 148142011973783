import { AccountAnswersServiceClient } from '../../proto/pb/Account_answersServiceClientPb'
import {
    AccountAnswers,
    AccountAnswersFilter,
    AccountAnswersFilterItem,
    AccountAnswersSort,
    AccountAnswersPagination,
    ListAccountAnswersRequest,
    ListAccountClassGroupsRequest,
} from '../../proto/pb/account_answers_pb'
import {RefreshTokenInterceptor} from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class UserAnswers {
    client: AccountAnswersServiceClient
    metadata: any

    constructor() {
        const host: string = (window as any).VUE_APP_API_URL ?? ''
        this.client = new AccountAnswersServiceClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]})
        this.metadata = {
            Authorization: localStorage.getItem('access-key')
        }
    }

    createFilter(data: any) {
        const filter = new AccountAnswersFilter()
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && '' !== data[i].field_name) {
                    const fItem = new AccountAnswersFilterItem()
                    fItem.setFieldName(data[i].field_name.toString())
                    fItem.setOperand(
                        data[i].operand !== undefined ? data[i].operand : '='
                    )
                    fItem.setValue(
                        data[i].value !== undefined ? data[i].value.toString() : ''
                    )
                    fItem.setOr(this.createFilter(data[i].or)) // Рекурсия !!!
                    filter.addItems(fItem)
                }
            }
        }
        return filter
    }

    createSort(data: any) {
        const sort = new AccountAnswersSort()
        const name: string =
            data.name !== undefined ? data.name.toString() : 'answer_time'
        const exp: string = data.exp !== undefined ? data.exp.toString() : 'desc'

        // Устанавливаем параметры сортировки
        sort.setName(name)
        sort.setExp(exp)

        return sort
    }

    createPagination(data: any) {
        const pagination = new AccountAnswersPagination()
        const page: number = data.page !== undefined ? Number(data.page) : 1
        const limit: number =
            data.limit !== undefined ? Number(data.limit) : 10000
        const pages: number = data.pages !== undefined ? Number(data.pages) : 0
        const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0

        // Устанавливаем пагинацию
        pagination.setPage(page)
        pagination.setLimit(limit)
        pagination.setPages(pages).setCnt(cnt)

        return pagination
    }

    getUserAnswers(filter: any, sort: any, pagination: any, data: any) {
        const request = new ListAccountAnswersRequest

        request
            .setFilter(this.createFilter(filter))
            .setSort(this.createSort(sort))
            .setPagination(this.createPagination(pagination))
            .setSsoId(data.ssoId)


        return this.client.listAccountAnswers(request, { Authorization: localStorage.getItem('access-key') } as any)
    }

    getUserGroups(data: any) {
        const request = new ListAccountClassGroupsRequest

        request
            .setSsoId(data.ssoId)


        return this.client.listAccountClassGroups(request, { Authorization: localStorage.getItem('access-key') } as any)
    }
}
